import PropTypes from 'prop-types'

import React from 'react'
import { connect } from 'react-redux'
import { VelocityTransitionGroup } from 'velocity-react'
import { routerActions } from 'react-router-redux'

import {
  meetingInProgress,
  timeUntilMeetingStarts
} from 'src/services/event_helpers.js'
import { showRsvpModal, updateChangeRsvpStatus } from 'src/actions/frontend'
import {
  requestAcceptInvitation,
  requestDeclineInvitation,
  acceptRsvpInitiated
} from 'src/actions/invitations'

import styles from './floating-rsvp-btn-container.scss'
import Button from 'src/components/common/Button'
import RSVPButtons from '../details/RSVPButtons'
import TicketAttending from '../details/TicketAttending'
import cn from 'classnames'

import {
  eventSelector,
  hasPaidTicketsSelector,
  invitationSelector,
  showTotalAttendingSelector,
  userHasTicketsSelector
} from 'src/selectors'

class FloatingRSVPBtnContainer extends React.Component {
  componentDidMount () {
    const { event } = this.props
    const timeRemaining = timeUntilMeetingStarts(event)

    if (timeRemaining > 0) {
      setTimeout(() => {
        this.forceUpdate()
      }, timeRemaining * 1000 + 30)
    }
  }

  _onAcceptInvitation = () => {
    const { onAcceptInvitation, event, onAcceptRsvpInitiated, userHasTickets } =
      this.props
    if (event.ticketing_enabled_at) {
      if (userHasTickets) {
        onAcceptInvitation()
      } else {
        onAcceptRsvpInitiated()
      }
    } else {
      onAcceptInvitation()
    }
  };

  _showUserTicketCounts () {
    const { hasPaidTickets, invitation, showTotalAttending, userHasTickets } =
      this.props

    const paidEventWithTickets = hasPaidTickets && userHasTickets

    return (
      invitation &&
      ((invitation.rsvp_state === 'accepted' && showTotalAttending) ||
        invitation.rsvp_state === 'declined' ||
        paidEventWithTickets)
    )
  }

  _renderMeetingLink () {
    return (
      <div className={styles['join-meeting-container']}>
        <Button onClick={null} purple className={styles['button']}>
          Join Meeting
        </Button>
      </div>
    )
  }

  _renderContents () {
    const { event } = this.props
    if (meetingInProgress(event)) {
      return this._renderMeetingLink()
    } else {
      return this._renderButtons()
    }
  }

  _renderButtons () {
    const {
      onDeclineInvitation,
      onChangeRsvp,
      children,
      disabled = false,
      event
    } = this.props
    let { invitation } = this.props

    return (
      <div className={styles['rsvp-wrapper']}>
        <RSVPButtons
          invitation={invitation}
          onAcceptInvitation={this._onAcceptInvitation}
          onDeclineInvitation={onDeclineInvitation}
          onChangeRsvp={onChangeRsvp}
          disabled={disabled}
          event={event}
        />

        {this._showUserTicketCounts() ? (
          <TicketAttending invitation={invitation} onClick={onChangeRsvp} />
        ) : null}
        {children}
      </div>
    )
  }

  render () {
    const { style, customClassName } = this.props

    return (
      <div className={cn(styles['container'], customClassName)} style={style}>
        <VelocityTransitionGroup
          enter='transition.fadeIn'
          leave='transition.fadeOut'
        >
          {this._renderContents()}
        </VelocityTransitionGroup>
      </div>
    )
  }
}

FloatingRSVPBtnContainer.propTypes = {
  event: PropTypes.object,
  hasPaidTickets: PropTypes.bool,
  invitation: PropTypes.object,
  onAcceptInvitation: PropTypes.func,
  onAcceptRsvpInitiated: PropTypes.func,
  onChangeRsvp: PropTypes.func,
  onDeclineInvitation: PropTypes.func,
  showTotalAttending: PropTypes.bool,
  userHasTickets: PropTypes.bool,
  disabled: PropTypes.bool,

  // Passed in
  children: PropTypes.any,
  style: PropTypes.object,
  customClassName: PropTypes.string
}

function mapStateToProps (state) {
  const invitation = invitationSelector(state)
  const showTotalAttending = showTotalAttendingSelector(state)

  return {
    event: eventSelector(state),
    hasPaidTickets: hasPaidTicketsSelector(state),
    invitation,
    showTotalAttending,
    userHasTickets: userHasTicketsSelector(state)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    onAcceptInvitation: () => dispatch(requestAcceptInvitation()),
    onDeclineInvitation: () => dispatch(requestDeclineInvitation()),
    onChangeRsvp: () => {
      dispatch(routerActions.push('rsvp'))
      dispatch(showRsvpModal())
      dispatch(updateChangeRsvpStatus({ changeRsvp: true }))
    },
    onAcceptRsvpInitiated: () => {
      dispatch(showRsvpModal())
      dispatch(acceptRsvpInitiated())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FloatingRSVPBtnContainer)
