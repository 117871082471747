import React, { Component } from 'react'

import Switch from 'react-switch'
import Button from 'components/common/Button'
import Option from './Option/Option'
import { connect } from 'react-redux'
import AddIcon from 'assets/icons/add-icon-theme.png'
import styles from './third-step.scss'
import { changeSignUpForm } from 'src/actions/timepoll'
import PropTypes from 'prop-types'
import { message } from 'antd'

const initialOption = {
  item: '',
  item_rank: 0,
  count_limit: 9999,
  description: ''
}

class ThirdStep extends Component {
  state = {
    openSwitch: false,
    invalidList: new Set()
  }

  _addOption = () => {
    const {
      signUpForm: { list_options_params },
      onChangeSignUpForm
    } = this.props
    list_options_params.push({ ...initialOption, item_rank: list_options_params.length })
    onChangeSignUpForm({ list_options_params })
  }

  _removeOption = index => {
    let {
      signUpForm: { list_options_params },
      onChangeSignUpForm
    } = this.props
    if (list_options_params.length === 1) {
      message.warning('You must have at least one option')
      return
    }

    list_options_params.splice(index, 1)

    list_options_params = list_options_params.map((item, index) => ({ ...item, item_rank: index }))
    onChangeSignUpForm({ list_options_params })
  }

  _handleChange = params => {
    const { value, type, index } = params
    const {
      signUpForm: { list_options_params },
      onChangeSignUpForm
    } = this.props
    switch (type) {
      case 'item':
        list_options_params[index] = { ...list_options_params[index], item: value }
        break
      case 'count_limit':
        list_options_params[index] = { ...list_options_params[index], count_limit: value }
        break
      case 'description':
        list_options_params[index] = { ...list_options_params[index], description: value }
        break
    }
    onChangeSignUpForm({ list_options_params })
  }

  handleClick = () => {
    const {
      onButtonClick,
      signUpForm: { list_options_params },
      userId
    } = this.props
    const { invalidList } = this.state

    window.mixpanel.track('SAVE AND CONTINUE Clicked', { user_id: userId })

    for (const index in list_options_params) {
      const item = list_options_params[index]
      if (item.item) {
        invalidList.delete(item.item_rank)
      } else {
        invalidList.add(item.item_rank)
      }

      if (!this.state.openSwitch) {
        this._handleChange({ type: 'count_limit', value: 9999, index })
      }
    }

    if (invalidList.size > 0) {
      this.setState({ invalidList })
      message.warning('Please input all option names')
      return
    }

    onButtonClick()
  }

  handleChangeQuantity = value => {
    this.setState({ openSwitch: value })
  }

  render() {
    const { openSwitch, invalidList } = this.state
    const {
      signUpForm: { list_options_params }
    } = this.props

    return (
      <div className={styles['third-step-container']}>
        <div className={styles['option-wrapper']}>
          <ul>
            {list_options_params.map((item, index) => (
              <Option
                onChange={this._handleChange}
                onRemove={this._removeOption}
                optionInformation={item}
                hasQuantity={openSwitch}
                key={index}
                index={index}
                invalid={invalidList.has(item.item_rank)}
              />
            ))}
          </ul>
        </div>

        <div className={styles['add-button-wrapper']} onClick={this._addOption}>
          <img src={AddIcon} alt="" width="16" />
          <span>Add</span>
        </div>
        <div className={styles['quantity-switch-wrapper']}>
          <span className={styles['quantity-label']}>select quantity</span>
          <Switch
            onChange={this.handleChangeQuantity}
            checked={openSwitch}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor="#2cd4ae"
            width={50}
            height={30}
          />
        </div>
        <div className={styles['button-wrapper']} onClick={this.handleClick}>
          <Button className={styles['button-inner']}>SAVE AND CONTINUE</Button>
        </div>
      </div>
    )
  }
}

ThirdStep.propTypes = {
  userId: PropTypes.string,
  signUpForm: PropTypes.object,
  onChangeSignUpForm: PropTypes.func,
  onButtonClick: PropTypes.func
}

function mapStateToProps(state) {
  return {
    signUpForm: state.frontend.signUpForm,
    userId: state.userId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeSignUpForm: params => dispatch(changeSignUpForm(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdStep)
