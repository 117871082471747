import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import InputElement from 'react-input-mask'

const phoneMask = '999-999-9999'

export default class PhoneInputElement extends React.Component {
  render () {
    const {
      className,
      errorClassName,
      showError,
      value,
      ...otherProps
    } = this.props

    return (
      <InputElement
        autoFocus
        type='tel'
        mask={phoneMask}
        autoComplete='off'
        placeholder='Phone Number'
        value={value}
        className={classnames(className, { [errorClassName]: showError })}
        {...otherProps}
      />
    )
  }
}

PhoneInputElement.propTypes = {
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  showError: PropTypes.bool,
  value: PropTypes.string.isRequired
}
