// Impact Radius's stat.js handles affiliatizing links, however in our testing
// they don't handle the homepage of the retailer, so redirect all retailer
// links except for the homepage (which is handled by the redirect service)
export function impactRadiusHandlesLink (url) {
  const regex = /target.com|bestbuy.com|walmart.com/
  const baseDomainRegex = /^https?:\/\/(www.)?(target.com|bestbuy.com|walmart.com)$/
  return regex.test(url) && !baseDomainRegex.test(url)
}

// This needs to be called after a link is rendered
export function affiliatizeLinks () {
  if (window.impactStat) {
    // after this call links to target/walmart/etc will be replaced with
    // goto.target.com links (probably in a unique way per retailer)
    window.impactStat('transformLinks')
  }
}
