import React, { Component } from 'react'
import { fetchPollList } from 'src/actions/timepoll'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styles from './sul-panel.scss'
import SULCard from './SULCard/SULCard'
import moment from 'moment'
import LoadingComponent from 'components/common/LoadingComponent/LoadingComponent'
import { userIdSelector } from 'src/selectors'
import EmptyPanel from '../EmptyPanel/EmptyPanel'

class SULPanel extends Component {
  state = {
    sulList: [],
    loading: true
  }

  handleSortPolls = polls => {
    const pollsDetailsStatusSort = polls.sort((a, b) => {
      if (a.status === 'active' && (b.status === 'closed' || !b.status)) {
        return -1
      }
      if ((a.status === 'closed' || !a.status) && b.status === 'active') {
        return 1
      }
      return 0
    })

    let result = pollsDetailsStatusSort.sort((a, b) => {
      if (a.status === 'active' && b.status === 'active') {
        if (moment(a.updated_at).isBefore(b.updated_at)) {
          return -1
        }
        return 1
      }
      if ((a.status === 'closed' || !a.status) && (b.status === 'closed' || !b.status)) {
        if (moment(a.updated_at).isBefore(b.updated_at)) {
          return 1
        }
        return -1
      }
      return 0
    })

    if (this.props.inHomePage && result.length > 6) {
      result = result.slice(0, 6)
    }

    this.setState({
      sulList: result,
      loading: false
    })
  }

  handleDeleteSUL = sulId => {
    const { sulList } = this.state
    const index = sulList.findIndex(item => item.id === sulId)

    sulList.splice(index, 1)

    this.setState({ sulList })
  }

  componentDidUpdate(prevProps) {
    const { userId } = this.props
    const { sulList } = this.state

    if (prevProps.userId !== userId && userId && sulList.length === 0 && this._isMounted) {
      this.onLoadSulList()
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.onLoadSulList()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onLoadSulList = () => {
    const { onFetchPollList } = this.props
    onFetchPollList({
      status: 'all',
      pollType: 'item',
      onSuccessCallback: data => {
        if (data.data.poll_list?.result && this._isMounted) {
          this.handleSortPolls(data.data.poll_list.result)
        }
      }
    })
  }

  render() {
    const { sulList, loading } = this.state

    if (loading) {
      return (
        <div style={{ width: '100%', height: '100%' }}>
          <LoadingComponent type="partial" loading light />
        </div>
      )
    }

    if (sulList.length === 0) {
      return <EmptyPanel type="sul" />
    }

    return (
      <div className={styles['SUL-panel-container']}>
        {sulList.map(sul => (
          <SULCard
            key={sul.id}
            sul={sul}
            userId={this.props.userId}
            onDeleteSULCallback={this.handleDeleteSUL}
          />
        ))}
      </div>
    )
  }
}

SULPanel.propTypes = {
  userId: PropTypes.string,
  onFetchPollList: PropTypes.func,
  inHomePage: PropTypes.bool
}

function mapDispatchToProps(dispatch) {
  return {
    onFetchPollList: params => dispatch(fetchPollList(params))
  }
}

function mapStateToProps(state) {
  return {
    userId: userIdSelector(state)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SULPanel)
