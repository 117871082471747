import { combineReducers } from 'redux'

import {
  CREATE_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD_SUCCESS,
  CREATE_PAYMENT_METHOD_ERROR,
  EVENT_TICKETS_COUNTS_ERROR,
  LIST_MY_ORDERS_FOR_EVENT_REQUEST,
  LIST_MY_ORDERS_FOR_EVENT_REQUEST_ERROR,
  LIST_MY_ORDERS_FOR_EVENT_REQUEST_SUCCESS,
  PURCHASE_TICKETS,
  PURCHASE_TICKETS_ERROR,
  PURCHASE_TICKETS_SUCCESS,
  REVOKE_TICKETS_MUTATION,
  REVOKE_TICKETS_MUTATION_ERROR,
  REVOKE_TICKETS_MUTATION_SUCCESS,
  SET_CURRENT_ORDER_ID,
  SET_EVENT_TICKETS_COUNTS,
  SET_HOST_CAN_ACCEPT_PAYMENTS,
  SET_PAYMENT_RECEIPT_EMAIL,
  SET_INITIAL_LOADING_PAYMENT_MODAL,
  SET_LIST_ORDERS_FOR_EVENT,
  SET_ORDER_LINE_ITEMS,
  SET_PAYMENT_METHODS_LIST,
  SET_SHOW_GUESTS_CHANGE_RSVP_MODAL,
  SET_TEMP_TICKET_TYPES_COUNTS,
  SET_TICKET_TYPES,
  SET_TICKET_TYPES_COUNTS,
  SHOW_CREATE_NEW_CARD_MODAL,
  SHOW_INVITATION_REPLY_FORM_MODAL,
  SHOW_TICKET_COUNT_CONTROL_MODAL,
  SHOW_TOTAL_TICKETS_COUNTS_MODAL,
  TICKET_TYPES_COUNTS_LOADED,
  TICKET_TYPES_COUNTS_LOADED_RESET
} from '../../actions/paymentGraphql'

import { HIDE_RSVP_MODAL } from '../../actions/frontend'

const hostCanAcceptPayment = (state = null, action) => {
  switch (action.type) {
    case SET_HOST_CAN_ACCEPT_PAYMENTS:
      return action.canAcceptPayments
    default:
      return state
  }
}

const receiptEmail = (state = null, action) => {
  switch (action.type) {
    case SET_PAYMENT_RECEIPT_EMAIL:
      return action.email
    default:
      return state
  }
}

// main used in event details page
const ticketTypes = (state = [], action) => {
  switch (action.type) {
    case SET_TICKET_TYPES:
      return action.ticketTypes
    default:
      return state
  }
}

const eventTicketCounts = (state = {}, action) => {
  switch (action.type) {
    case SET_EVENT_TICKETS_COUNTS:
      return action.eventTicketCounts
    default:
      return state
  }
}

const paymentMethodsList = (state = [], action) => {
  switch (action.type) {
    case SET_PAYMENT_METHODS_LIST:
      return action.paymentList
    default:
      return state
  }
}

const showTotalTicketsCountsModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_TOTAL_TICKETS_COUNTS_MODAL:
      return true
    case SHOW_TICKET_COUNT_CONTROL_MODAL:
    case SHOW_CREATE_NEW_CARD_MODAL:
    case SHOW_INVITATION_REPLY_FORM_MODAL:
    case HIDE_RSVP_MODAL:
      return false
    default:
      return state
  }
}

const showTicketCountControlModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_TICKET_COUNT_CONTROL_MODAL:
      return true
    case SHOW_TOTAL_TICKETS_COUNTS_MODAL:
    case SHOW_CREATE_NEW_CARD_MODAL:
    case SHOW_INVITATION_REPLY_FORM_MODAL:
    case HIDE_RSVP_MODAL:
      return false
    default:
      return state
  }
}

const showCreateNewCardModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_CREATE_NEW_CARD_MODAL:
      return true
    case SHOW_TOTAL_TICKETS_COUNTS_MODAL:
    case SHOW_TICKET_COUNT_CONTROL_MODAL:
    case SHOW_INVITATION_REPLY_FORM_MODAL:
    case HIDE_RSVP_MODAL:
      return false
    default:
      return state
  }
}

const showInvitationReplyFormModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_INVITATION_REPLY_FORM_MODAL:
      return true
    case SHOW_TOTAL_TICKETS_COUNTS_MODAL:
    case SHOW_TICKET_COUNT_CONTROL_MODAL:
    case SHOW_CREATE_NEW_CARD_MODAL:
    case SET_SHOW_GUESTS_CHANGE_RSVP_MODAL:
    case HIDE_RSVP_MODAL:
      return false
    default:
      return state
  }
}

const orderLineItems = (state = [], action) => {
  switch (action.type) {
    case SET_ORDER_LINE_ITEMS:
      return action.orderLineItems
    default:
      return state
  }
}

// main used in total tickets, rsvp modal
const ticketTypesCounts = (state = [], action) => {
  switch (action.type) {
    case SET_TICKET_TYPES_COUNTS:
      return action.ticketTypesCounts
    default:
      return state
  }
}

const tempTicketTypesCounts = (state = [], action) => {
  switch (action.type) {
    case SET_TEMP_TICKET_TYPES_COUNTS:
      return action.tempTicketTypesCounts
    default:
      return state
  }
}

const purchaseTicketsLoading = (state = false, action) => {
  switch (action.type) {
    case PURCHASE_TICKETS:
    case REVOKE_TICKETS_MUTATION:
      return true
    case PURCHASE_TICKETS_SUCCESS:
    case PURCHASE_TICKETS_ERROR:
    case REVOKE_TICKETS_MUTATION_SUCCESS:
    case REVOKE_TICKETS_MUTATION_ERROR:
      return false
    default:
      return state
  }
}

const createPaymentMethodLoading = (state = false, action) => {
  switch (action.type) {
    case CREATE_PAYMENT_METHOD:
    case PURCHASE_TICKETS:
      return true
    case CREATE_PAYMENT_METHOD_SUCCESS:
    case CREATE_PAYMENT_METHOD_ERROR:
    case PURCHASE_TICKETS_SUCCESS:
    case PURCHASE_TICKETS_ERROR:
      return false
    default:
      return state
  }
}

// start revoke free tickets
const listMyOrdersForEvent = (state = [], action) => {
  switch (action.type) {
    case SET_LIST_ORDERS_FOR_EVENT:
      return action.listOrdersForEvent
    default:
      return state
  }
}
// end revoke free tickets
const initialLoadingPaymentModal = (state = true, action) => {
  switch (action.type) {
    case SET_INITIAL_LOADING_PAYMENT_MODAL:
      return action.initialLoadingPaymentModal
    default:
      return state
  }
}

// Free tickets
const showGuestsChangeRsvpModal = (state = false, action) => {
  switch (action.type) {
    case SET_SHOW_GUESTS_CHANGE_RSVP_MODAL:
      return true
    case SHOW_INVITATION_REPLY_FORM_MODAL:
    case HIDE_RSVP_MODAL:
      return false
    default:
      return state
  }
}

const listMyOrdersForEventLoading = (state = false, action) => {
  switch (action.type) {
    case LIST_MY_ORDERS_FOR_EVENT_REQUEST:
      return true
    case LIST_MY_ORDERS_FOR_EVENT_REQUEST_SUCCESS:
    case LIST_MY_ORDERS_FOR_EVENT_REQUEST_ERROR:
      return false
    default:
      return state
  }
}

const currentOrderId = (state = {}, action) => {
  switch (action.type) {
    case SET_CURRENT_ORDER_ID:
      return action.orderId
    default:
      return state
  }
}

const ticketTypesCountsLoadingStatus = (state = 'loading', action) => {
  switch (action.type) {
    case SET_TICKET_TYPES_COUNTS:
    case TICKET_TYPES_COUNTS_LOADED:
      return 'success'
    case TICKET_TYPES_COUNTS_LOADED_RESET:
      return 'loading'
    case EVENT_TICKETS_COUNTS_ERROR:
      return 'error'
    default:
      return state
  }
}

export default combineReducers({
  hostCanAcceptPayment,
  receiptEmail,
  ticketTypes,
  paymentMethodsList,
  showTotalTicketsCountsModal,
  showCreateNewCardModal,
  showTicketCountControlModal,
  showInvitationReplyFormModal,
  orderLineItems,
  ticketTypesCounts,
  tempTicketTypesCounts,
  eventTicketCounts,
  purchaseTicketsLoading,
  createPaymentMethodLoading,
  listMyOrdersForEvent,
  initialLoadingPaymentModal,
  showGuestsChangeRsvpModal,
  listMyOrdersForEventLoading,
  currentOrderId,
  ticketTypesCountsLoadingStatus
})
