import { select } from 'redux-saga/effects'

import {
  ACCEPT_INVITATION,
  DECLINE_INVITATION,
  VIEW_INVITATION,
  retryAcceptOrDeclineInvitation,
  retryViewInvitation
} from 'src/actions/invitations'

import { isInvalidTokenError } from 'services/access_tokens'

import { AccessTokenType, refreshAccessToken } from './access_tokens'

export function * autoRetrySaga (oldAction, apiPayload, response) {
  if (oldAction.autoRetry && oldAction.autoRetry > 0) {
    let retryAction = {
      ...oldAction,
      autoRetry: oldAction.autoRetry - 1
    }

    const { wwwAuthenticateHeader } = apiPayload
    const isInvalidToken = wwwAuthenticateHeader && isInvalidTokenError(wwwAuthenticateHeader)
    const is409 = response.status === 409

    if (isInvalidToken && apiPayload.tokenType === AccessTokenType.USER) {
      yield refreshAccessToken()
      return retryAction
    } else if (is409) {
      return yield retry409Conflicts(retryAction)
    } else {
      return retryAction
    }
  }
}

function * retry409Conflicts (retryAction) {
  const state = yield select(s => s)
  switch (retryAction.requestType) {
    case ACCEPT_INVITATION:
    case DECLINE_INVITATION:
      return retryAcceptOrDeclineInvitation(retryAction, state)
    case VIEW_INVITATION:
      return retryViewInvitation(retryAction, state)
    default:
      return false
  }
}
