import { combineReducers } from 'redux'

import { SET_PREVIEW_GALLERIES, SET_PREVIEW_INDEX } from 'src/actions/frontend'

const galleries = (state = [], action) => {
  switch (action.type) {
    case SET_PREVIEW_GALLERIES:
      return action.galleries
    case SET_PREVIEW_INDEX:
      if (action.index === -1) {
        return []
      }
      return state
    default:
      return state
  }
}

const index = (state = -1, action) => {
  switch (action.type) {
    case SET_PREVIEW_INDEX:
      return action.index
    default:
      return state
  }
}

export default combineReducers({
  galleries,
  index
})
