import React, { useEffect, useState, useRef, useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import { fullName } from 'src/services/user_helpers'
import cn from 'classnames'
import InputMessageContext from '../InputMessageContext'
import { chatSelector, usersSelector, userIdSelector } from 'src/selectors'

import styles from './mention-box.scss'

const MentionBox = ({ onSetTextMessage, onSetTagList, tagList, onSetIsInputTagVisible }) => {
  const { groupList, memberList, chatUser } = useSelector(chatSelector)
  const users = useSelector(usersSelector)
  const userId = useSelector(userIdSelector)
  const { inputRef, textMessage } = useContext(InputMessageContext)

  const [currentInputValue, setCurrentTagValue] = useState('')
  const [tagOptions, setOptions] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0)

  const mentionBoxRef = useRef(null)
  const ulRef = useRef(null)

  const initialTags = () => {
    const memberTags = memberList
      .map(member => {
        const user = users[member.user_id]
        if (member.destroyed_at || !user || userId === user.id) {
          return null
        }
        return user
      })
      .filter(
        user =>
          user &&
          !user.destroyed_at &&
          (user.first_name || user.last_name) &&
          fullName(user).toLowerCase().includes(currentInputValue.toLowerCase())
      )

    const groupTags =
      chatUser === 'allUsers'
        ? groupList.filter(group => group.name.toLowerCase().includes(currentInputValue))
        : []

    const options = groupTags.concat(memberTags)

    setSelectedIndex(0)
    setOptions(options)

    if (options.length === 0) {
      onSetIsInputTagVisible(false)
    }
  }

  const handleUlScrollView = () => {
    const ulElement = ulRef.current
    const selectedLiElement = ulElement.children[selectedIndex]
    const ulRect = ulElement.getBoundingClientRect()
    const liRect = selectedLiElement.getBoundingClientRect()
    if (liRect.top < ulRect.top) {
      ulElement.scrollTop -= ulRect.top - liRect.top
    } else if (liRect.bottom > ulRect.bottom) {
      ulElement.scrollTop += liRect.bottom - ulRect.bottom
    }
  }

  const listenerKeyDown = e => {
    if (e.key === 'ArrowDown') {
      e.preventDefault()
      const index = selectedIndex + 1

      if (index >= 0 && index < tagOptions.length) {
        setSelectedIndex(index)
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      const index = selectedIndex - 1
      if (index >= 0 && index < tagOptions.length) {
        setSelectedIndex(index)
      }
    }

    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        e.preventDefault()
        const count = e.key === 'ArrowDown' ? 1 : -1
        const index = selectedIndex + count
        if (index >= 0 && index < tagOptions.length) {
          setSelectedIndex(index)
        }
        break
      case 'Enter':
        e.preventDefault()
        handleSelectTag(tagOptions[selectedIndex])
        break
    }
  }

  const listenerContainer = e => {
    const isClickInsideElement = mentionBoxRef.current.contains(e.target)
    if (!isClickInsideElement) {
      onSetIsInputTagVisible(false)
    }
  }

  useEffect(() => {
    if (ulRef.current && tagOptions.length > 0) {
      handleUlScrollView()
    }

    window.addEventListener('keydown', listenerKeyDown)
    document.addEventListener('click', listenerContainer)

    return () => {
      window.removeEventListener('keydown', listenerKeyDown)
      document.removeEventListener('click', listenerContainer)
    }
  }, [selectedIndex, tagOptions])

  useEffect(() => {
    initialTags()
  }, [currentInputValue])

  useEffect(() => {
    const tagValue = textMessage.substring(textMessage.lastIndexOf('@') + 1)

    setCurrentTagValue(tagValue)
  }, [textMessage])

  const handleSelectTag = option => {
    const { groupName, id, name } = option
    let str = ''
    let displayName = ''

    if (groupName) {
      displayName = name
      str = `<${groupName}:${id}|@${displayName}>`
    } else {
      displayName = fullName(option)
      str = `<user:${id}|@${displayName}>`
    }
    const formatTextMessage = textMessage.substring(0, textMessage.lastIndexOf('@') + 1)

    onSetTagList({ ...tagList, [`@${displayName}`]: str })
    onSetTextMessage(formatTextMessage + `${displayName} `)
    onSetIsInputTagVisible(false)
    inputRef.current.focus()
  }

  const renderGroupTag = (group, index) => {
    return (
      <li
        key={group.id}
        className={cn(
          styles['mention-li'],
          selectedIndex === index && styles['mention-li-selected']
        )}
        onClick={() => handleSelectTag(group)}
      >
        <div className={styles['group-name']}>@{group.name}</div>
        <div className={styles['group-info']}>
          {group.count} people · {group.info_text}
        </div>
      </li>
    )
  }

  const renderUserTag = (user, index) => {
    return (
      <li
        key={user.id}
        className={cn(
          styles['mention-li'],
          selectedIndex === index && styles['mention-li-selected']
        )}
        onClick={() => handleSelectTag(user)}
      >
        <UserAvatarContainer user={user} size={30} />
        <div>{fullName(user)}</div>
      </li>
    )
  }

  return (
    <div className={styles['mention-box']} ref={mentionBoxRef}>
      <ul className={styles['mention-ul']} ref={ulRef}>
        {tagOptions.map((tag, index) =>
          tag.groupName ? renderGroupTag(tag, index) : renderUserTag(tag, index)
        )}
      </ul>
    </div>
  )
}

MentionBox.propTypes = {
  onSetTagList: PropTypes.func,
  tagList: PropTypes.object,
  onSetTextMessage: PropTypes.func,
  onSetIsInputTagVisible: PropTypes.func
}

export default MentionBox
