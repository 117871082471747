import React, { Component } from 'react'

import OptionCard from 'src/containers/time-poll/TimePollContainer/SignUp/SULDetail/SULOptions/OptionCard/OptionCard'
import { connect } from 'react-redux'
import { updateSignUpUserSelections } from 'src/actions/timepoll'
import Button from 'src/components/common/Button'
import HobnobModal from 'components/common/HobnobModal'

import CloseIcon from 'assets/icons/close-icon.png'
import BackIcon from 'assets/icons/back_button.png'

import { signUpDetailSelector } from 'src/selectors'
import { pluralize } from 'services/utils'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'

import PropTypes from 'prop-types'
import styles from './option.scss'

class Option extends Component {
  state = {
    showVoted: false,
    signUpOptionList: [],
    checkedSUL: null,
    showRSVPToast: false
  }

  componentDidMount() {
    this.getSignUpList()
  }

  getSignUpList = () => {
    const { signUpDetail, invitation } = this.props
    if (!signUpDetail) {
      return
    }

    const signUpOptionList = signUpDetail.poll_options.map(item => {
      const userSelection = item.poll_user_selections.find(i => {
        return i.user_id === invitation?.guest
      })

      const params = {
        item: item.item,
        poll_option_id: item.id,
        available_count: item.available_count,
        initial_available_count: item.available_count,
        count_limit: item.count_limit,
        description: item.description,
        poll_user_selections: item.poll_user_selections
      }

      if (userSelection) {
        params.id = userSelection.id
        params.count = userSelection.count
        params.initial_count = userSelection.count
        params.isChecked = true
        params.note = userSelection.note
      } else {
        params.count = 0
        params.initial_count = 0
        params.isChecked = false
        params.note = ''
      }

      return params
    })

    this.setState({ signUpOptionList })
  }

  handleChangeOption = ({ type, poll_option_id, value }) => {
    const { signUpOptionList } = this.state
    const index = signUpOptionList.findIndex(item => item.poll_option_id === poll_option_id)
    const signUpOption = signUpOptionList[index]

    if (type === 'checked') {
      const changeChecked = !signUpOption.isChecked
      let count = 0
      let available_count = 0

      if (changeChecked) {
        if (signUpOption.initial_count === 0) {
          count = 1
          available_count = signUpOption.initial_available_count - 1
        } else {
          count = signUpOption.initial_count
          available_count = signUpOption.initial_available_count
        }
      } else {
        count = 0
        available_count = signUpOption.available_count + signUpOption.count
      }

      signUpOptionList[index] = {
        ...signUpOption,
        count,
        available_count,
        isChecked: changeChecked
      }
    } else if (type === 'minus') {
      if (signUpOption.count <= 0) {
        return
      }
      const isChecked = !(signUpOption.count - 1 === 0)
      signUpOptionList[index] = {
        ...signUpOption,
        isChecked,
        count: signUpOption.count - 1,
        available_count: signUpOption.available_count + 1
      }
    } else if (type === 'add') {
      if (signUpOption.available_count <= 0) {
        return
      }
      signUpOptionList[index] = {
        ...signUpOption,
        count: signUpOption.count + 1,
        isChecked: true,
        available_count: signUpOption.available_count - 1
      }
    } else if (type === 'note') {
      signUpOptionList[index] = {
        ...signUpOption,
        note: value
      }
    }

    this.setState({ signUpOptionList })
  }

  handleSubmit = () => {
    const { signUpOptionList } = this.state
    const { signUpDetail, onUpdateSignUpUserSelections, onClose, invitation } = this.props

    if (!invitation) {
      this.setState({ showRSVPToast: true })
      return
    }

    const checkedList = signUpOptionList.filter(item => item.isChecked)
    const list_user_selections_params = checkedList.map(item => {
      const params = {
        poll_option_id: item.poll_option_id,
        count: item.count,
        user_id: invitation.guest,
        custom_user_name: `${invitation.first_name} ${invitation.last_name}`,
        note: item.note
      }
      if (item.id) {
        params.id = item.id
      }

      return params
    })

    const requestParams = {
      poll_id: signUpDetail.id,
      list_user_selections_params,
      onSuccessCallback: data => {
        if (data.data.list_user_selections_update.successful) {
          onClose()
        }
      },
      onFailCallback: data => {
        if (!data.data.list_user_selections_update.successful) {
          alert(data.data.list_user_selections_update.messages[0])
        }
      }
    }

    onUpdateSignUpUserSelections(requestParams)
  }

  openDetail = item => {
    this.setState({
      checkedSUL: item,
      showVoted: true
    })
  }

  closeDetail = () => {
    this.setState({
      checkedSUL: null,
      showVoted: false
    })
  }

  renderUser = item => {
    const { checkedSUL } = this.state
    if (item.user) {
      return (
        <>
          <div className={styles['user-info']}>
            <UserAvatarContainer user={item.user} size={20} />
            <span>
              {item.custom_user_name || `${item.user?.first_name} ${item.user?.last_name}`}
            </span>
            <div className={styles['line']} />
            {checkedSUL.count_limit !== 9999 && <span>count: {item.count}</span>}
          </div>
          {item.note && <div className={styles['note']}>{item.note}</div>}
        </>
      )
    } else if (item.anonymous_user_id) {
      return (
        <>
          <div className={styles['user-info']}>
            <UserAvatarContainer user={{}} size={20} />
            <span>{item.custom_user_name}</span>
            <div className={styles['line']} />
            {checkedSUL.count_limit !== 9999 && <span>count: {item.count}</span>}
          </div>
          {item.note && <div className={styles['note']}>{item.note}</div>}
        </>
      )
    }
  }

  renderVoterNumber = () => {
    const { checkedSUL } = this.state
    const number = checkedSUL.poll_user_selections.filter(item => !item.destroyed_at).length
    return `${pluralize(number, 'signUp')}(${number})`
  }

  render() {
    const { signUpDetail, onClose } = this.props
    const { showVoted, signUpOptionList, checkedSUL } = this.state

    return (
      <div className={styles['option-wrapper']}>
        <div className={styles['option-header']}>
          {showVoted ? (
            <img src={BackIcon} width="18" onClick={this.closeDetail} />
          ) : (
            <div style={{ width: '18px' }} />
          )}
          <span className={styles['title']}>{signUpDetail.name}</span>
          <img src={CloseIcon} width="20" onClick={onClose} />
        </div>

        {showVoted ? (
          <div className={styles['common-wrapper']}>
            {checkedSUL.count_limit !== 9999 && (
              <div className={styles['option-voted']}>
                <span>Total: {checkedSUL.count_limit}</span>
                <span>Available: {checkedSUL.available_count}</span>
              </div>
            )}

            <div className={styles['option-notes']}>{checkedSUL.description}</div>

            <div className={styles['option-label']}>{this.renderVoterNumber()}</div>

            <ul className={styles['option-ul']}>
              {checkedSUL.poll_user_selections.map((item, index) => {
                if (!item.destroyed_at) {
                  return (
                    <li key={index} className={styles['option-li']}>
                      {this.renderUser(item)}
                    </li>
                  )
                }
              })}
            </ul>
          </div>
        ) : (
          <div className={styles['common-wrapper']}>
            <div className={styles['option-notes']}>{signUpDetail?.notes}</div>

            <div className={styles['option-label']}>slot/item</div>

            <ul className={styles['option-ul']}>
              {signUpOptionList.map((item, index) => (
                <OptionCard
                  optionInformation={item}
                  onChangeOption={this.handleChangeOption}
                  key={index}
                  customStyle={{ padding: '10px 20px' }}
                  onHandleDetail={() => this.openDetail(item)}
                />
              ))}
            </ul>

            <Button className={styles['button']} onClick={this.handleSubmit}>
              submit
            </Button>
          </div>
        )}

        <HobnobModal
          show={this.state.showRSVPToast}
          onClose={() => this.setState({ showRSVPToast: false })}
        >
          <p>Please RSVP before signing up on this list</p>
          <Button onClick={() => this.setState({ showRSVPToast: false })}>OK</Button>
        </HobnobModal>
      </div>
    )
  }
}

Option.propTypes = {
  onClose: PropTypes.func,
  signUpDetail: PropTypes.object,
  onUpdateSignUpUserSelections: PropTypes.func,
  invitation: PropTypes.object
}

function mapStateToProps(state) {
  return {
    signUpDetail: signUpDetailSelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateSignUpUserSelections: params => dispatch(updateSignUpUserSelections(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Option)
