import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { VelocityComponent } from 'velocity-react'

import { animations } from 'services/velocity_animations'
import { fullName } from 'src/services/user_helpers'

import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import OverlayLoadingIndicator from 'src/components/common/OverlayLoadingIndicator'
import SvgIcon from 'src/components/common/SvgIcon'

import HeartOutline from 'src/assets/icons/heart_outline.svg'
import IconShare from 'src/assets/icons/icon_share.svg'
import IconMore from 'src/assets/icons/more_icon.svg'
import styles from './medium-photo.scss'

export default class MediumPhoto extends Component {
  state = {
    imageLoaded: false
  }

  _heartIconRef = (ref) => {
    this._heartIcon = ref
  }
  _saveImageRef = (ref) => {
    this._imageRef = ref
  }

  componentDidMount () {
    this._imageRef.onload = () => {
      this.setState({ imageLoaded: true })
    }
  }

  _onLikeTapped = () => {
    const result = this.props.onLikeTapped()
    if (result && result.runAnimation) {
      this._heartIcon.runAnimation()
    }
  }

  _onPhotoTapped = () => {
    const result = this.props.onPhotoTapped()
    if (result && result.runAnimation) {
      this._heartIcon.runAnimation()
    }
  }

  _renderShareIcon () {
    const {
      onShareTapped
    } = this.props

    return (
      <div className={styles['share-icon-container']}>
        <SvgIcon
          className={styles['share-icon']}
          icon={IconShare}
          onClick={onShareTapped}
          padding='13px'
        />
      </div>
    )
  }

  _renderMoreIcon () {
    const {
      onMoreTapped
    } = this.props

    return (
      <div className={styles['more-icon-container']}>
        <SvgIcon
          className={styles['more-icon']}
          icon={IconMore}
          onClick={onMoreTapped}
          padding='13px'
        />
      </div>
    )
  }

  render () {
    const {
      namesByUserId,
      liked,
      medium,
      mediumUser,
      user
    } = this.props
    const { imageLoaded } = this.state

    return (
      <div className={styles['container']}>
        <div className={styles['top-row']}>
          {this._renderShareIcon()}
          {user ? this._renderMoreIcon() : null}
        </div>

        <OverlayLoadingIndicator dark loading={medium.loading || !imageLoaded} />
        <img
          className={styles['image']}
          height={imageLoaded || !medium.height ? 'auto' : medium.height}
          width={imageLoaded || !medium.width ? 'auto' : medium.width}
          onClick={this._onPhotoTapped}
          ref={this._saveImageRef}
          src={medium.file.url_2x}
        />

        <div className={styles['bottom-row']}>
          <UserAvatarContainer
            user={mediumUser}
            namesByUserId={namesByUserId}
            size={30}
            includeBorder={false}
          />

          <div className={styles['user-name']}>{fullName(mediumUser, namesByUserId)}</div>
          <VelocityComponent ref={this._heartIconRef} animation={animations.pulse}>
            <SvgIcon
              icon={HeartOutline}
              className={styles[liked ? 'heart-icon-full' : 'heart-icon-empty']}
              onClick={this._onLikeTapped}
              padding='4px'
            />
          </VelocityComponent>
        </div>
      </div>
    )
  }
}

MediumPhoto.propTypes = {
  namesByUserId: PropTypes.object,
  liked: PropTypes.bool,
  medium: PropTypes.object,
  mediumUser: PropTypes.object.isRequired,
  onLikeTapped: PropTypes.func.isRequired,
  onMoreTapped: PropTypes.func.isRequired,
  onPhotoTapped: PropTypes.func.isRequired,
  onShareTapped: PropTypes.func.isRequired,
  user: PropTypes.object
}
