import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import CloneHostIcon from 'assets/icons/ico-clone-host.png'
// import SettingHostIcon from 'assets/icons/ico-setting-host.png'
import DeleteHostIcon from 'assets/icons/ico-delete-host.png'
import EditHostIcon from 'assets/icons/ico-edit-host.png'
import AppleIconWhite from 'assets/icons/apple-icon-white.png'
import LogoRectangle from 'assets/icons/logo-rectangle.png'
import AirPlaneHostIcon from 'assets/icons/ico-airplane-host.png'
import { eventSelector, eventTokenSelector } from 'src/selectors'
import PopConfirm from 'components/common/PopConfirm/PopConfirm'
import HobnobModal from 'components/common/HobnobModalV2/HobnobModal'
import Button from 'components/common/Button'
import SendUpdate from './SendUpdate/SendUpdate'
import { appUrl } from 'src/containers/time-poll/constants'
import { deleteEvent } from 'src/actions/event'
import { flyerDesignLayoutConfig } from 'src/assets/frontend-json/flyer-position'
import { routerActions } from 'react-router-redux'
import { changeVisible, setContent } from 'src/actions/globalLayer'
import { UPGRADE_PLAN_SOURCE } from 'src/constants'
import { LockOutlined } from '@ant-design/icons'
import cn from 'classnames'

import styles from './host-options.scss'

const HostOptions = () => {
  const dispatch = useDispatch()
  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false)
  const [isDownloadVisible, setIsDownloadVisible] = useState(false)
  const [isSendUpdateVisible, setIsSendUpdateVisible] = useState(false)

  const event = useSelector(eventSelector)
  const eventToken = useSelector(eventTokenSelector)

  const handleDeleteEvent = () => {
    dispatch(
      deleteEvent({
        eventId: event.id,
        onSuccessCallback: () => {
          location.href = '/e/list'
        }
      })
    )
  }

  const navigateToEdit = () => {
    const designNumbers = flyerDesignLayoutConfig
      .map(design => design.designIds)
      .reduce((a, b) => a.concat(b), [])

    const design_number = event.flyer?.design_number || event.pending_flyer?.design_number

    if (designNumbers.indexOf(Number(design_number)) > -1) {
      dispatch(routerActions.push(`/e/edit/${eventToken}`))
    } else {
      setIsDownloadVisible(true)
    }
  }

  const handleClickSendUpdate = () => {
    if (event.grandfathered || event.feature_level >= 1) {
      setIsSendUpdateVisible(true)
      return
    }

    if (event.feature_level < 1) {
      dispatch(setContent(UPGRADE_PLAN_SOURCE.sendUpdate))
      dispatch(changeVisible(true))
    }
  }

  return (
    <div className={styles['host-option-container']}>
      <h3 className={styles['host-option-title']}>host options</h3>
      <div className={styles['icon-container']}>
        <div
          className={cn(styles['icon-wrapper'], {
            [styles['icon-wrapper-hidden']]: event.state === 'draft'
          })}
          onClick={handleClickSendUpdate}
        >
          <img src={AirPlaneHostIcon} width="30" />
          <div className={styles['icon-label']}>
            Send Update{event.feature_level < 1 && <LockOutlined style={{ marginLeft: 5 }} />}
          </div>
        </div>
        <div className={styles['icon-wrapper']} onClick={navigateToEdit}>
          <img src={EditHostIcon} width="30" />
          <div className={styles['icon-label']}>Edit Event</div>
        </div>

        <div className={styles['icon-wrapper']} onClick={() => setIsRemoveModalVisible(true)}>
          <img src={DeleteHostIcon} width="30" />
          <div style={{ color: '#d0021b' }} className={styles['icon-label']}>
            delete event
          </div>
        </div>
      </div>

      <PopConfirm
        visible={isRemoveModalVisible}
        confirmText="DELETE"
        type="delete"
        onClose={() => setIsRemoveModalVisible(false)}
        onConfirm={handleDeleteEvent}
      >
        Are you sure you want to delete this event?
        <br /> This action cannot be undone.
      </PopConfirm>

      <SendUpdate
        isSendUpdateVisible={isSendUpdateVisible}
        onSetIsSendUpdateVisible={setIsSendUpdateVisible}
      />

      <HobnobModal
        onCancel={() => setIsDownloadVisible(false)}
        visible={isDownloadVisible}
        closable={false}
        fullModal
      >
        <div className={styles['modal-wrapper']}>
          <div className={styles['img-inner']}>
            <img src={LogoRectangle} alt="" />
          </div>
          <div className={styles['content-container']}>
            <div className={styles['text']}>
              Your Event was created on the app, go back to Hobnob app to edit it.
            </div>

            <Button wrapperElement={'a'} className={styles['button-inner']} href={appUrl}>
              <img src={AppleIconWhite} alt="" width="30" />
              <span>Open in App</span>
            </Button>

            <div className={styles['thx-text']} onClick={() => setIsDownloadVisible(false)}>
              no, thanks
            </div>
          </div>
        </div>
      </HobnobModal>
    </div>
  )
}

export default HostOptions
