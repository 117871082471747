/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react'
import { DateRangePicker } from 'react-date-range'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import {
  isIncludeDate,
  isDateAlreadySelected,
  findSelectedDate,
  reformatSelectedDates
} from '../../utils/dates'

import styles from './day-picker.scss'

const DayPicker = props => {
  const {
    selectTimesMobile = false,
    selectedDates,
    setSelectedDates,
    activeDay,
    setActiveDay,
    onDeleteTime
  } = props
  const [currentAddKey] = useState(null)

  const rangeList = useMemo(() => {
    const ranges = Object.keys(selectedDates).map(key => {
      return selectedDates[key]
    })
    ranges.unshift({ key: 'selection', startDate: null, endDate: new Date(null) })
    return ranges
  }, [selectedDates])

  const customStyles = {
    dateRangePickerWrapper: styles['date-range-picker-wrapper'],
    dateRangeWrapper: styles['date-range-wrapper'],
    monthAndYearWrapper: styles['monthAndYear-wrapper'],
    weekDays: styles['week-days'],
    day: styles['day'],
    month: styles['month'],
    calendarWrapper: styles['calendar-wrapper'],
    infiniteMonths: styles['infinite-months']
  }

  const customDayContent = day => {
    const handleDelete = () => {
      handleDeleteDate(day)
      onDeleteTime(activeDay.id)
      setActiveDay(null)
    }

    if (activeDay && moment(activeDay.day).isSame(day, 'day')) {
      return (
        <>
          <label onClick={handleDelete} className={styles['delete-label']}>
            Delete
          </label>
          <span onClick={() => setActiveDay(null)}>{moment(day).format('D')}</span>
        </>
      )
    }

    if (currentAddKey && moment(selectedDates[currentAddKey].startDate).isSame(day, 'day')) {
      return (
        <>
          <label className={styles['start-label']}>Start</label>
          <span>{moment(day).format('D')}</span>
        </>
      )
    }
  }

  const validateActiveDay = date => {
    if (!activeDay || (activeDay && !moment(activeDay.day).isSame(date, 'day'))) {
      const { key, id } = findSelectedDate(date, selectedDates)
      setActiveDay({ day: date, key, id })
    }
  }

  const handleDeleteDate = date => {
    const copySelectedDates = { ...selectedDates }
    const deletedKey = findSelectedDate(date, copySelectedDates).key
    delete copySelectedDates[deletedKey]
    setSelectedDates(reformatSelectedDates(copySelectedDates))
  }

  const handleChangeOfWeb = item => {
    const { startDate, endDate } = item[Object.keys(item)[0]]

    if (moment(startDate).isSame(endDate) && isDateAlreadySelected(startDate, selectedDates)) {
      const { id } = findSelectedDate(startDate, selectedDates)
      handleDeleteDate(startDate)
      onDeleteTime(id)
      return
    }

    const newKey = `selection${Object.keys(selectedDates).length + 1}`
    const newDate = {
      startDate,
      endDate,
      color: '#2CD4AE',
      key: newKey,
      id: uuid()
    }

    if (isIncludeDate(newDate, selectedDates)) {
      alert('This option has collapsed with other options, please try other dates.')
      return
    }

    setSelectedDates(prevDates => {
      return { ...prevDates, [newKey]: newDate }
    })
  }

  const handleChangeOfMobile = item => {
    const currentSelectedDate = item[Object.keys(item)[0]].startDate
    if (isDateAlreadySelected(currentSelectedDate, selectedDates)) {
      validateActiveDay(currentSelectedDate)
      return
    }
    const newKey = `selection${Object.keys(selectedDates).length + 1}`
    const newDate = {
      startDate: currentSelectedDate,
      endDate: currentSelectedDate,
      color: '#2CD4AE',
      key: newKey,
      id: uuid()
    }
    setSelectedDates(prevDates => {
      return { ...prevDates, [newKey]: newDate }
    })
    setActiveDay({ day: currentSelectedDate, key: newDate.key, id: newDate.id })
  }

  // const handleChangeOfMobile = item => {
  //   const currentSelectedDate = item[Object.keys(item)[0]].startDate

  //   if (currentAddKey) {
  //     const { startDate } = selectedDates[currentAddKey]
  //     const IsStartDateBeforeEndDate = moment(startDate).isBefore(currentSelectedDate)
  //     const newDate = {
  //       startDate: IsStartDateBeforeEndDate ? startDate : currentSelectedDate,
  //       endDate: IsStartDateBeforeEndDate ? currentSelectedDate : startDate,
  //       color: '#2CD4AE',
  //       key: currentAddKey,
  //       id: uuid()
  //     }

  //     if (isIncludeDate(newDate, selectedDates)) {
  //       alert('This option has collapsed with other options, please try other dates.')
  //       return
  //     }

  //     setCurrentAddKey(null)
  //     setSelectedDates(prevDates => {
  //       return { ...prevDates, [currentAddKey]: newDate }
  //     })
  //     setActiveDay({ day: currentSelectedDate, key: newDate.key, id: newDate.id })
  //   } else {
  //     if (isDateAlreadySelected(currentSelectedDate, selectedDates)) {
  //       validateActiveDay(currentSelectedDate)
  //       return
  //     }

  //     const newKey = `selection${Object.keys(selectedDates).length + 1}`
  //     const newDate = {
  //       startDate: currentSelectedDate,
  //       endDate: currentSelectedDate,
  //       color: '#2CD4AE',
  //       key: newKey
  //     }
  //     setCurrentAddKey(newKey)
  //     setSelectedDates(prevDates => {
  //       return { ...prevDates, [newKey]: newDate }
  //     })
  //     setActiveDay(null)
  //   }
  // }

  return (
    <DateRangePicker
      onChange={selectTimesMobile ? handleChangeOfMobile : handleChangeOfWeb}
      classNames={customStyles}
      showDateDisplay={false}
      ranges={rangeList}
      rangeColors={['#2CD4AE']}
      months={selectTimesMobile ? 3 : 1}
      minDate={moment().toDate()}
      maxDate={moment().add(900, 'day').toDate()}
      direction="vertical"
      scroll={{ enabled: true }}
      showMonthAndYearPickers={false}
      showMonthArrow={false}
      showPreview={false}
      dragSelectionEnabled
      focusedRange={[0, 0]}
      dayContentRenderer={customDayContent}
    />
  )
}

export default DayPicker
