import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BalloonIcon from 'assets/icons/balloon-icon.png'
import ShareIcon from 'assets/icons/share-icon.png'
import moment from 'moment'
import styles from './sul-information.scss'
import UserAvatarContainer from 'components/common/UserAvatarContainer'
import { showShareModal } from 'src/actions/frontend'
import { connect } from 'react-redux'
import Button from 'components/common/Button'
import SvgIcon from 'components/common/SvgIcon'
import ShareSVG from 'assets/icons/ico-share.svg'

class SULInformation extends Component {
  handleClickShare = () => {
    const { signUpDetail, onShowShareModal } = this.props
    const url = window.location.href

    const params = {
      title: 'Share Your Sign Up List',
      subtitle: 'Guests can view the list and make their selections!',
      shareUrl: url.substring(8),
      email: {
        sul: signUpDetail,
        url
      },
      facebook: {
        href: url,
        redirect_uri: url
      },
      twitter: {
        subject: `You're invited to ${signUpDetail.name}!`,
        url
      }
    }

    onShowShareModal(params)
  }

  render() {
    const { signUpDetail, type } = this.props

    return (
      <div className={styles['sul-wrapper']}>
        <div className={styles['sul-image-wrapper']}>
          <img className={styles['sul-image']} src={signUpDetail.image} />
          {type === 'detail' && (
            <div className={styles['share-button']} onClick={this.handleClickShare}>
              <Button className={styles['button-inner']}>
                <SvgIcon className={styles['icon']} icon={ShareSVG} />
                <span>share</span>
              </Button>
            </div>
          )}
        </div>
        <div className={styles['header']}>
          <div className={styles['avatar']}>
            <UserAvatarContainer user={signUpDetail.creator || {}} size={60} />
          </div>

          {type === 'detail' && (
            <div className={styles['share']} onClick={this.handleClickShare}>
              <img src={ShareIcon} width="12" />
              <span>share</span>
            </div>
          )}
        </div>

        <div className={styles['sul-info']}>
          <span className={styles['name']}>{signUpDetail.name}</span>

          {signUpDetail.eventName && (
            <div className={styles['event']}>
              <img src={BalloonIcon} width={18} />
              <span>{signUpDetail.eventName}</span>
            </div>
          )}

          {signUpDetail.ends_at && (
            <div className={styles['endDate']}>
              <label>End Date: </label>
              <span>{moment(signUpDetail.ends_at).format('ddd,MMM DD,YYYY,h:mm A')}</span>
            </div>
          )}

          <div className={styles['line']} />

          <div>
            <div className={styles['host']}>
              <label>Host: </label>
              <span>{signUpDetail.createName}</span>
            </div>
            <div className={styles['createDate']}>
              <label>Created: </label>
              <span>{moment(signUpDetail.created_at).format('MMM DD')}</span>
            </div>
          </div>

          {signUpDetail.notes && (
            <div className={styles['notes']}>
              <label>Notes: </label>
              <p>{signUpDetail.notes}</p>
            </div>
          )}
        </div>

        <div className={styles['sul-info-mobile']}>
          <span className={styles['name']}>{signUpDetail.name}</span>

          {signUpDetail.eventName && (
            <div className={styles['event']}>
              <img src={BalloonIcon} width={18} />
              <span>{signUpDetail.eventName}</span>
            </div>
          )}

          {signUpDetail.ends_at && (
            <div className={styles['endDate']}>
              <label>End Date: </label>
              <span>{moment(signUpDetail.ends_at).format('ddd,MMM DD,YYYY,h:mm A')}</span>
            </div>
          )}

          <div className={styles['creator']}>
            <UserAvatarContainer user={signUpDetail.creator || {}} size={34} />
            <div className={styles['name']}>{signUpDetail.createName}</div>
            <div className={styles['createDate']}>
              <label>Created: </label>
              <span>{moment(signUpDetail.created_at).format('MMM DD')}</span>
            </div>
          </div>

          <div className={styles['notes']}>{signUpDetail.notes}</div>
        </div>
      </div>
    )
  }
}

SULInformation.defaultProps = {
  type: 'detail'
}

SULInformation.propTypes = {
  signUpDetail: PropTypes.object,
  type: PropTypes.string,
  onShowShareModal: PropTypes.func
}

const mapDispatchToProps = dispatch => {
  return {
    onShowShareModal: params => dispatch(showShareModal(params))
  }
}

export default connect(null, mapDispatchToProps)(SULInformation)
