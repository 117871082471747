import {
  getMediaCount,
  setMediaCount,
  getCommentsCount,
  setCommentsCount,
  getInvitationViewed,
  setInvitationViewed
} from 'services/cookies'

export const INIT_MEDIA_COOKIE = '@@hobnob/INIT_MEDIA_COOKIE'
export const SET_MEDIA_COOKIE = '@@hobnob/SET_MEDIA_COOKIE'
export const INIT_EVENT_COMMENTS_COOKIE = '@@hobnob/INIT_EVENT_COMMENTS_COOKIE'
export const SET_EVENT_COMMENTS_COOKIE = '@@hobnob/SET_EVENT_COMMENTS_COOKIE'
export const INIT_INVITATION_VIEWED_COOKIE = '@@hobnob/INIT_INVITATION_VIEWED_COOKIE'
export const SET_INVITATION_VIEWED_COOKIE = '@@hobnob/SET_INVITATION_VIEWED_COOKIE'

export function initMediaCookie () {
  return { type: INIT_MEDIA_COOKIE }
}

export function setMediaCookie (value) {
  return { type: SET_MEDIA_COOKIE, value }
}

export function initEventCommentsCookie () {
  return { type: INIT_EVENT_COMMENTS_COOKIE }
}

export function setEventCommentsCookie (value) {
  return { type: SET_EVENT_COMMENTS_COOKIE, value }
}

export function initInvitationViewedCookie () {
  return { type: INIT_INVITATION_VIEWED_COOKIE }
}

export function setInvitationViewedCookie () {
  return { type: SET_INVITATION_VIEWED_COOKIE, value: true }
}

export function cookieReducer (state = {}, action) {
  switch (action.type) {
    case INIT_MEDIA_COOKIE:
    case SET_MEDIA_COOKIE:
      return {
        ...state,
        mediaCount: action.value
      }
    case INIT_EVENT_COMMENTS_COOKIE:
    case SET_EVENT_COMMENTS_COOKIE:
      return {
        ...state,
        eventCommentsCount: action.value
      }
    case INIT_INVITATION_VIEWED_COOKIE:
    case SET_INVITATION_VIEWED_COOKIE:
      return {
        ...state,
        invitationViewed: action.value
      }
    default:
      return state
  }
}

export function createCookieMiddleware () {
  return () => (next) => (action) => {
    switch (action.type) {
      case INIT_MEDIA_COOKIE: {
        const value = getMediaCount()
        next({ ...action, value })
        break
      }
      case SET_MEDIA_COOKIE:
        setMediaCount(action.value)
        next(action)
        break
      case INIT_EVENT_COMMENTS_COOKIE: {
        const value = getCommentsCount()
        next({ ...action, value })
        break
      }
      case SET_EVENT_COMMENTS_COOKIE:
        setCommentsCount(action.value)
        next(action)
        break
      case INIT_INVITATION_VIEWED_COOKIE: {
        const value = getInvitationViewed()
        next({ ...action, value })
        break
      }
      case SET_INVITATION_VIEWED_COOKIE: {
        setInvitationViewed()
        next(action)
        break
      }
      default:
        next(action)
    }
  }
}
