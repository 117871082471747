import { createSelector } from 'reselect'

import { hostFirstName, hostFullName } from 'src/services/event_helpers'
import { firstName as userFirstName, fullName as userFullName } from 'src/services/user_helpers'
import { sortedProcessedMedia, visibleMedium } from 'services/media_helpers'
import { commentsSort, visibleComment } from 'services/comment_helpers'
import { hasPaidTickets, ticketTypeHasDescription } from 'services/payment_utils'

export const eventIdSelector = (state) => state.eventId
export const eventSelector = (state) => state.entities.events[state.eventId]
export const eventsSelector = (state) => state.entities.events
export const timePollSelector = (state) => state.entities.polls[state.eventToken]
export const userIdSelector = (state) => state.userId
export const invitationIdSelector = (state) => state.invitationId
export const destinationTypeSelector = (state) => state.destinationType
export const eventTokenSelector = (state) => state.eventToken
export const invitationTokenSelector = (state) => state.invitationToken
export const loginFormSelector = (state) => state.loginForm
export const hudSelector = (state) => state.hud
export const eventModalSelector = (state) => state.frontend.eventModal
export const routeSelector = (state) => {
  if (state.routing.locationBeforeTransitions) {
    return state.routing.locationBeforeTransitions.pathname
  } else {
    return ''
  }
}
export const branchFloatingButtonLinkSelector = (state) => state.branch.floatingButtonLink
export const branchEmailHostMessageAttemptLinkSelector = (state) => state.branch.emailHostMessageAttempt
export const branchMessageIconLinkSelector = (state) => state.branch.messageIconLink
export const branchGatedChatLinkSelector = (state) => state.branch.gatedChatLink
export const branchOpenInAppModalLinkSelector = (state) => state.branch.openInAppModalLink
export const branchOpenInAppModalPollLinkSelector = (state) => state.branch.openInAppModalPollLink

// Entities
export const eventUpdatesSelector = (state) => state.entities.event_updates
export const eventUpdatesCountSelector = (state) => Object.values(state.entities.event_updates).length
export const eventCommentsSelector = (state) => state.entities.event_comments
export const mediaSelector = (state) => state.entities.media
export const mediaCommentsSelector = (state) => state.entities.media_comments
export const invitationsSelector = (state) => state.entities.invitations
export const likesSelector = (state) => state.entities.likes
export const usersSelector = (state) => state.entities.users
export const flagsSelector = (state) => state.entities.flags
export const linksSelector = (state) => state.entities.links
export const namesByUserIdSelector = (state) => state.entities.namesByUserId

// Cookies
export const eventCommentsCountSelector = (state) => state.cookies.eventCommentsCount
export const mediaCountSelector = (state) => state.cookies.mediaCount
export const invitationViewedSelector = (state) => state.cookies.invitationViewed

// Frontend
export const showDetailsPanelOnRootRouteSelector = (state) => state.frontend.showDetailsPanelOnRootRoute
export const imageModalUrlSelector = (state) => state.frontend.imageModal.url
export const imageModalOpenSelector = (state) => state.frontend.imageModal.open
export const previewGalleriesSelector = (state) => state.frontend.previewGalleries
export const chatSelector = (state) => state.frontend.chat
export const topBannerSelector = (state) => state.frontend.topBanner
export const signUpFormSelector = (state) => state.frontend.signUpForm
export const eventFormSelector = (state) => state.frontend.eventForm
export const invitationsPusherSelector = (state) => state.frontend.invitationsPusher
// export const commentsLoadingStatusSelector = (state) => state.frontend.chat.commentsLoadingStatus
export const shareModalVisibleSelector = (state) => state.frontend.shareModal.visible
export const shareModalContentSelector = (state) => state.frontend.shareModal.content
export const showMenuPanelSelector = (state) => state.frontend.menu.isMenuPanelVisible
export const activeMenuPanelSelector = (state) => state.frontend.menu.activeMenuPanel
export const requestedRsvpSelector = (state) => state.frontend.requestedRsvp
export const invitationReplyMessageSelector = (state) => state.frontend.invitationReplyMessage
export const eventCommentSelector = (state) => state.frontend.eventComment
export const pollsSelector = (state) => state.frontend.pollByIdStatus
export const contactMethodsSelector = (state) => state.frontend.contactMethods
export const showLoadingScreenSelector = (state) => state.frontend.showLoadingScreen
export const isLoadingVisibleSelector = (state) => state.frontend.isLoadingVisible
export const signUpDetailSelector = (state) => state.frontend.signUpDetail
export const loginModalVisibleSelector = (state) => state.frontend.showLogin
export const isRefreshModalVisibleSelector = (state) => state.frontend.isRefreshModalVisible
export const flyerSelector = (state) => state.frontend.flyer
export const activityFeedSelector = (state) => state.frontend.activityFeed

export const rsvpModalVisibleSelector = (state) => state.frontend.rsvpModal.visible
export const rsvpModalChangeRsvpSelector = (state) => state.frontend.rsvpModal.changeRsvp
export const rsvpModalHasChangedRsvpStatusSelector = (state) => state.frontend.rsvpModal.hasChangedRsvpStatus
export const rsvpModalShowBringGuestSelector = (state) => state.frontend.rsvpModal.showBringGuestModal
export const rsvpModalShowGuestCountControlsSelector = (state) => state.frontend.rsvpModal.showGuestCountControlsModal
export const rsvpModalShowInvitationReplyFormSelector = (state) => state.frontend.rsvpModal.showInvitationReplyFormModal
export const rsvpModalShowDefaultDeclinedPageSelector = (state) => state.frontend.rsvpModal.showDefaultDeclinedPage
export const showTotalAttendingSelector = (state) => state.frontend.rsvpModal.showTotalAttending

export const modalLoginPollVisibleSelector = (state) => state.frontend.loginPollModal.visible
export const showPhoneCodeFieldSelector = (state) => state.frontend.loginPoll.showPhoneCodeField
export const showEmailFieldSelector = (state) => state.frontend.loginPoll.showEmailField
export const showPhoneFieldSelector = (state) => state.frontend.loginPoll.showPhoneField
export const hidePhoneFieldSelector = (state) => state.frontend.loginPoll.hidePhoneField

export const isSubscribeContainerSelector = (state) => state.frontend.loginPoll.isSubscribeContainer
export const isLoginContainerSelector = (state) => state.frontend.loginPoll.isLoginContainer
export const isUpdateSelectionsSelector = (state) => state.frontend.loginPoll.isUpdateSelections

export const chatSettingsModalVisibleSelector = (state) => state.frontend.chatSettingsModal.visible
export const chatSettingsModalUpdatingInvitationSelector = (state) => state.frontend.chatSettingsModal.updatingInvitation

export const likersModalVisibleSelector = (state) => state.frontend.likersModal.visible
export const likersModalMediumIdSelector = (state) => state.frontend.likersModal.mediumId

export const messageModalVisibleSelector = (state) => state.frontend.messageModal.visible
export const messageModalMessageHostSelector = (state) => state.frontend.messageModal.messageHost
export const messageModalTitleSelector = (state) => state.frontend.messageModal.messageTitle
export const messageModalSubTitleSelector = (state) => state.frontend.messageModal.messageSubTitle
export const showMessageSentSelector = (state) => state.frontend.messageModal.showMessageSent

export const mediumMoreTooltipVisibleSelector = (state) => state.frontend.mediumMoreTooltip.visible
export const mediumMoreTooltipMediumIdSelector = (state) => state.frontend.mediumMoreTooltip.mediumId
export const mediumMoreTooltipClickCoordinatesSelector = (state) => state.frontend.mediumMoreTooltip.clickCoordinates

export const mediumShareTooltipVisibleSelector = (state) => state.frontend.mediumShareTooltip.visible
export const mediumShareTooltipMediumIdSelector = (state) => state.frontend.mediumShareTooltip.mediumId
export const mediumShareTooltipClickCoordinatesSelector = (state) => state.frontend.mediumShareTooltip.clickCoordinates

export const mapModalVisibleSelector = (state) => state.frontend.mapModalVisible
export const floatingButtonVisibleSelector = (state) => state.frontend.floatingButtonVisible
export const openInAppModalVisibleSelector = (state) => state.frontend.openInAppModal.visible
export const tooltipVisibleSelector = (state) => state.frontend.tooltipVisible
export const mediaLoadingStatusSelector = (state) => state.frontend.mediaLoadingStatus
export const mediaCommentsLoadingStatusesSelector = (state) => state.frontend.mediaCommentsLoadingStatuses
export const mediaLikersLoadingStatusesSelector = (state) => state.frontend.mediaLikersLoadingStatuses

// Toast messages
export const toastMessagesMessagesSelector = (state) => state.toastMessages.messages
export const toastMessagesVisibleSelector = (state) => state.toastMessages.visible

// message count
export const messageCountSelector = (state) => state.messageCount
export const processedMediaCountSelector = (state) => state.processedMediaCount
export const processedPhotoCountSelector = (state) => state.processedPhotoCount
export const processedVideoCountSelector = (state) => state.processedVideoCount
export const invitationsCountSelector = (state) => state.invitationsCount
export const invitationsAcceptedCountSelector = (state) => state.invitationsAcceptedCount

// Credentials
export const clientCredentialsSelector = (state) => state.credentials.clientCredentials
export const invitationCredentialsSelector = (state) => state.credentials.invitationCredentials

// payment
export const ticketTypesSelector = (state) => state.frontend.payment.ticketTypes
export const paymentMethodsListSelector = (state) => state.frontend.payment.paymentMethodsList
export const showCreateNewCardModalSelector = (state) => state.frontend.payment.showCreateNewCardModal
export const showTicketCountControlModalSelector = (state) => state.frontend.payment.showTicketCountControlModal
export const showInvitationReplyFormModalSelector = (state) => state.frontend.payment.showInvitationReplyFormModal
export const orderLineItemsSelector = (state) => state.frontend.payment.orderLineItems
export const ticketTypesCountsSelector = (state) => state.frontend.payment.ticketTypesCounts
export const tempTicketTypesCountsSelector = (state) => state.frontend.payment.tempTicketTypesCounts
export const eventTicketCountsSelector = (state) => state.frontend.payment.eventTicketCounts
export const purchaseTicketsLoadingSelector = (state) => state.frontend.payment.purchaseTicketsLoading
export const createPaymentMethodLoadingSelector = (state) => state.frontend.payment.createPaymentMethodLoading
export const hostCanAcceptPaymentSelector = (state) => state.frontend.payment.hostCanAcceptPayment
export const listMyOrdersForEventSelector = (state) => state.frontend.payment.listMyOrdersForEvent
export const initialLoadingPaymentModalSelector = (state) => state.frontend.payment.initialLoadingPaymentModal
export const showGuestsChangeRsvpModalSelector = (state) => state.frontend.payment.showGuestsChangeRsvpModal
export const showTotalTicketsCountsModalSelector = (state) => state.frontend.payment.showTotalTicketsCountsModal
export const listMyOrdersForEventLoadingSelector = (state) => state.frontend.payment.listMyOrdersForEventLoading
export const ticketTypesCountsLoadingStatusSelector = (state) => state.frontend.payment.ticketTypesCountsLoadingStatus
export const eventTicketCountsLoadingStatusSelector = (state) => state.frontend.eventTicketCountsLoadingStatus
export const receiptEmailSelector = (state) => state.frontend.payment.receiptEmail
export const stripeCheckoutSelector = (state) => state.frontend.stripeCheckout

export const communityListSelector = (state) => state.frontend.communityList
export const planSelector = (state) => state.frontend.plan
export const globalLayerSelector = (state) => state.frontend.globalLayer

export const userHasTicketsSelector = createSelector(
  ticketTypesCountsSelector,
  (ticketTypesCounts) => {
    return ticketTypesCounts.some(ticketTypeCount => ticketTypeCount.count)
  }
)

export const hasPaidTicketsSelector = createSelector(
  ticketTypesSelector,
  (ticketTypes) => hasPaidTickets(ticketTypes)
)

export const showTicketTypesSelector = createSelector(
  ticketTypesSelector,
  hasPaidTicketsSelector,
  (ticketTypes, hasPaidTickets) => hasPaidTickets && ticketTypeHasDescription(ticketTypes)
)

export const currentOrderSelector = (state) => {
  const orderId = state.frontend.payment.currentOrderId
  const orders = state.frontend.payment.listMyOrdersForEvent

  return orders.find((order) => order.id === orderId)
}

export const isSubscribedSelector = (state) => {
  if (state.invitationId !== '') return true
  return !!currentUsersInvitationSelector(state)
}

export const isLoggedInSelector = createSelector(
  userIdSelector,
  (userId) => !!userId
)

export const isInvitationCredentialUserSelector = createSelector(
  invitationCredentialsSelector,
  (invitationCredentials) => !!invitationCredentials.accessToken && invitationCredentials.accessToken.length > 0
)

export const hudShownSelector = createSelector(
  hudSelector,
  (hud) => hud.show
)

export const userSelector = createSelector(
  userIdSelector,
  usersSelector,
  (userId, users) => users[userId]
)

export const userLoadingSelector = state => state.frontend.userLoading

export const activeInvitationsSelector = createSelector(
  invitationsSelector,
  (invitations) => {
    return Object.values(invitations).filter((invitation) => {
      return invitation.destroyed_at === null
    })
  }
)

export const activeLinksSelector = createSelector(
  linksSelector,
  (links) => {
    return Object.values(links).filter(link => !link.destroyed_at)
  }
)

export const invitationSelector = createSelector(
  invitationIdSelector,
  invitationsSelector,
  (invitationId, invitations) => invitations[invitationId]
)

export const contactMethodSelector = createSelector(
  invitationSelector,
  (invitation) => invitation ? invitation.contact_method : null
)

export const createUserForInvitationSelector = (invitation) => {
  return createSelector(
    usersSelector,
    (users) => invitation ? users[invitation.guest] : null
  )
}

export const invitationUserSelector = createSelector(
  invitationSelector,
  usersSelector,
  (invitation, users) => invitation ? users[invitation.guest] : null
)

export const hostSelector = createSelector(
  eventSelector,
  usersSelector,
  (event, users) => {
    if (event) {
      return users[event.host]
    } else {
      return null
    }
  }
)

// Get the host of this invitation. Either the co-host that sent the invitation or the host
export const hostForInvitationSelector = createSelector(
  invitationSelector,
  usersSelector,
  userIdSelector,
  hostSelector,
  (invitation, users, userId, host) => {
    if (!invitation) return host

    const hostForInvitation = users[invitation.host_for_invitation]

    if (hostForInvitation) {
      return hostForInvitation
    } else {
      return host
    }
  }
)

export const hostForInvitationNamesSelector = createSelector(
  hostSelector,
  hostForInvitationSelector,
  eventSelector,
  namesByUserIdSelector,
  (host, hostForInvitation, event, namesByUserId) => {
    if (host.id === hostForInvitation.id) {
      return {
        firstName: hostFirstName(event, host),
        fullName: hostFullName(event, host)
      }
    } else {
      return {
        firstName: userFirstName(hostForInvitation, namesByUserId),
        fullName: userFullName(hostForInvitation, namesByUserId)
      }
    }
  }
)

export const isGuestSelector = createSelector(
  userSelector,
  hostSelector,
  (user, host) => {
    const isHost = user && (user.id === host.id)
    return !isHost
  }
)

export const isHostSelector = createSelector(
  userSelector,
  hostSelector,
  (user, host) => user && (user.id === host?.id)
)

export const isHostOrCohostSelector = createSelector(
  isHostSelector,
  invitationSelector,
  (isHost, invitation) => isHost || invitation?.cohost
)

export const visibleMediaSelector = createSelector(
  mediaSelector,
  (media) => {
    return Object.values(media).filter(visibleMedium)
  }
)

export const sortedMediaSelector = createSelector(
  mediaSelector,
  sortedProcessedMedia
)

export const visibleEventCommentsSelector = createSelector(
  eventCommentsSelector,
  (comments) => {
    return Object.values(comments).filter(visibleComment)
  }
)

export const eventCommentsWithEventUpdatesSelector = createSelector(
  eventUpdatesSelector,
  visibleEventCommentsSelector,
  (eventUpdates, eventComments) => {
    let updates = Object.values(eventUpdates)
    updates = updates
      .map((update) => {
        return { ...update, content: 'Event Update: ' + update.content }
      })

    const comments = eventComments
      .concat(updates)
      .sort(commentsSort)

    return comments
  }
)

export const mediaCommentsByMediumIdSelector = createSelector(
  mediaCommentsSelector,
  (mediaComments) => {
    return Object.values(mediaComments).reduce((groupedComments, comment) => {
      groupedComments[comment.commentable_id] = groupedComments[comment.commentable_id] || []
      groupedComments[comment.commentable_id].push(comment)

      return groupedComments
    }, {})
  }
)

export function makeMediaCommentsSelector (mediumId) {
  return createSelector(
    mediaCommentsByMediumIdSelector,
    (mediaCommentsByMediumId) => {
      return mediaCommentsByMediumId[mediumId]
    }
  )
}

export const mediaLikesByMediumIdSelector = createSelector(
  likesSelector,
  (likes) => {
    return Object.values(likes).reduce((groupedLikes, comment) => {
      groupedLikes[comment.likeable_id] = groupedLikes[comment.likeable_id] || []
      groupedLikes[comment.likeable_id].push(comment)

      return groupedLikes
    }, {})
  }
)

export function makeMediaLikesSelector (mediumId) {
  return createSelector(
    mediaLikesByMediumIdSelector,
    (mediaLikesByMediumId) => {
      return mediaLikesByMediumId[mediumId]
    }
  )
}

export const likersByUserIdSelector = createSelector(
  likesSelector,
  (likes) => {
    return Object.values(likes).reduce((groupedLikes, comment) => {
      groupedLikes[comment.likeable_id] = groupedLikes[comment.likeable_id] || []
      groupedLikes[comment.likeable_id].push(comment)

      return groupedLikes
    }, {})
  }
)

export const currentUsersInvitationSelector = createSelector(
  userIdSelector,
  invitationsSelector,
  (userId, invitations) => {
    const invitationsArray = Object.values(invitations)
    const userInvitations = invitationsArray.filter((invitation) => invitation.guest === userId)
    let currentUsersInvitation = userInvitations[userInvitations.length - 1]
    const activeInvitations = userInvitations.filter((item) => !item.destroyed_at)

    if (activeInvitations.length) {
      currentUsersInvitation = activeInvitations[0]
    }
    return currentUsersInvitation
  }
)

export const tabRoutesSelector = createSelector(
  eventSelector,
  isHostSelector,
  (event, isHost) => {
    let tabRoutes = ['details', 'media', 'orders', 'orderDetail', 'requestRefund']
    if (!event) return tabRoutes

    if (event.commenting_enabled) tabRoutes.push('comments')
    if (event.guest_list_enabled || isHost) tabRoutes.push('guests')

    return tabRoutes
  }
)

export const timePollRoutesSelector = createSelector(
  eventSelector,
  () => {
    let timePollRoutes = ['login', 'successful', 'closed', 'deleted', 'updated']
    return timePollRoutes
  }
)
