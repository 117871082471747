import {
  CHANGE_POLL_TAB,
  FETCH_SIGN_UP_SUCCESS,
  CHANGE_SIGN_UP_FORM,
  RESET_SIGN_UP_FORM
} from 'src/actions/timepoll'

export const pollTab = (state = 'tp', action) => {
  switch (action.type) {
    case CHANGE_POLL_TAB:
      return action.value
    default:
      return state
  }
}

export const signUpDetail = (state = null, action) => {
  switch (action.type) {
    case FETCH_SIGN_UP_SUCCESS:
      if (action.response.data.list_via_token) {
        state = action.response.data.list_via_token
      }
      return state
    default:
      return state
  }
}

const initialSignUpForm = {
  email_require: false,
  name: '',
  custom_creator_name: '',
  notes: '',
  event_id: '',
  image_upload_id: '',
  poll_type: 'item',
  list_options_params: [
    {
      item: '',
      item_rank: 0,
      count_limit: 9999,
      description: ''
    }
  ],
  checkedImage: null,
  checkedEvent: null,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const signUpForm = (state = initialSignUpForm, action) => {
  switch (action.type) {
    case CHANGE_SIGN_UP_FORM:
      return { ...state, ...action.params }
    case RESET_SIGN_UP_FORM:
      return initialSignUpForm
    default:
      return state
  }
}
