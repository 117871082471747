import PropTypes from 'prop-types'
import React from 'react'

import Button from 'components/common/Button'
import HobnobModal from 'components/common/HobnobModal'
import EventVenue from './EventVenue'

import { mapUrl, directionsUrl, venueMapImageUrl } from 'services/map_helpers'

import styles from './map-modal.scss'

export default class MapModal extends React.Component {
  render () {
    const { customVenueName, onClose, show, venue } = this.props
    if (!venue) return null

    return (
      <HobnobModal
        onClose={onClose}
        show={show}
        title='Location'
      >
        <EventVenue customVenueName={customVenueName} venue={venue} className={styles['event-venue-container']} />
        <a href={mapUrl(venue.lat, venue.lng)}>
          <img src={venueMapImageUrl(venue)} className={styles['map-image']} />
        </a>
        <Button wrapperElement='a' className={styles['button']} href={directionsUrl(venue.lat, venue.lng)}>
          Get Directions
        </Button>
      </HobnobModal>
    )
  }
}

MapModal.propTypes = {
  customVenueName: PropTypes.string,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  venue: PropTypes.object
}
