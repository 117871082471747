import { fetchApi } from './api'
export const CREATE_PLACE = 'CREATE_PLACE'
export const CREATE_PLACE_SUCCESS = 'CREATE_PLACE_SUCCESS'
export const CREATE_PLACE_ERROR = 'CREATE_PLACE_ERROR'

export const createApplePlace = ({ placeData, onSuccessCallback }) => {
  return fetchApi({
    requestType: CREATE_PLACE,
    successType: CREATE_PLACE_SUCCESS,
    errorType: CREATE_PLACE_ERROR,
    route: '/graphql',
    onSuccessCallback,
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `mutation {
            apple_place_create(
              apple_place_params: {
                name: "${placeData.name ? placeData.name : ''}",
                latitude: "${placeData.latitude ? placeData.latitude : ''}",
                longitude: "${placeData.longitude ? placeData.longitude : ''}",
                formatted_address: "${
                  placeData.formatted_address ? placeData.formatted_address : ''
                }"
                country_code: "${placeData.country_code ? placeData.country_code : ''}",
                country: "${placeData.country ? placeData.country : ''}",
                administrative_area: "${
                  placeData.administrative_area ? placeData.administrative_area : ''
                }",
                locality: "${placeData.locality ? placeData.locality : ''}",
                sub_locality: "${placeData.sub_locality ? placeData.sub_locality : ''}",
                thoroughfare: "${placeData.thoroughfare ? placeData.thoroughfare : ''}",
                full_thoroughfare: "${
                  placeData.full_thoroughfare ? placeData.full_thoroughfare : ''
                }",
                post_code: "${placeData.post_code ? placeData.post_code : ''}",
              }
            ) {
              messages
              successful
              result {
                id
                name
                post_code
                full_thoroughfare
                locality
                administrative_area
                formatted_address
              }
          }
        }`
      })
    }
  })
}

const applePlaceToGooglePlaceKeyMapping = [
  ['locality', 'city'],
  ['post_code', 'postal_code'],
  ['administrative_area', 'state'],
  ['thoroughfare', 'street']
]

export const mapLocationDataBetweenApplePlaceAndGooglePlace = (placeData, from = 'apple') => {
  const result = {}
  const keys = Object.keys(placeData)
  let source, target
  if (from === 'apple') {
    source = 0
    target = 1
  } else {
    source = 1
    target = 0
  }
  keys.forEach(key => {
    const found = applePlaceToGooglePlaceKeyMapping.find(k => {
      return k[source] === key
    })
    if (found) {
      result[found[target]] = placeData[found[source]]
    } else {
      result[key] = placeData[key]
    }
  })
  return result
}
