import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

// import LoginPanel from 'components/LoginPanel/LoginPanel'
import LoginPanel from 'components/LoginModal/LoginPanel/LoginPanel'
import { updateLoginForm } from 'src/actions/login'

const NoInvitationRSVPView = props => {
  const { requestedRsvp, guestName } = props
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      updateLoginForm({
        loginModalTitle: 'Enter Your Number',
        loginModalSubtitle: 'In order to add you to the guest list, we need to confirm your number'
      })
    )
  }, [])

  let rsvpText = 'Can you make it?'
  if (guestName) {
    rsvpText = `Can you make it, ${guestName}?`
  }
  console.log(rsvpText, requestedRsvp)
  // return <div>{requestedRsvp !== '' ? <LoginPanel /> : rsvpText}</div>
  return <LoginPanel />
}

NoInvitationRSVPView.propTypes = {
  guestName: PropTypes.string,
  requestedRsvp: PropTypes.string.isRequired
}

export default NoInvitationRSVPView
