import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { sortedProcessedMedia } from 'services/media_helpers'

import {
  mediaSelector
} from 'src/selectors'

import Medium from './Medium'

export class MediaListContainer extends Component {
  _renderMedium = (medium) => {
    return (
      <Medium
        key={medium.id}
        medium={medium}
      />
    )
  }

  render () {
    const { media } = this.props
    return (
      <div>
        {media.map(this._renderMedium)}
      </div>
    )
  }
}

MediaListContainer.propTypes = {
  media: PropTypes.array
}

function mapStateToProps (state) {
  return {
    media: sortedProcessedMedia(mediaSelector(state))
  }
}

export default connect(mapStateToProps)(MediaListContainer)
