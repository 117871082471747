import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GetMorePollsListsBg from 'assets/icons/get-more-polls-lists.png'
import CustomMessageBg from 'assets/icons/custom-message-bg.png'
import SendUpdateBg from 'assets/icons/send-update-bg.png'
import LockEventBg from 'assets/icons/lock-event-bg.png'
import CommonFrame from '../CommonFrame/CommonFrame'
import Button from 'src/components/common/Button'
import { changeVisible } from 'src/actions/globalLayer'
import { changePlanModalVisible, setSpecifyPlan } from 'src/actions/plan'
import { globalLayerSelector, userSelector, isHostSelector } from 'src/selectors'
import { UPGRADE_PLAN_SOURCE } from 'src/constants'
import { verifySubscriptionPlatform } from 'src/services/frontend_tips'

import styles from './upgrade-plan.scss'

const getMorePollsAndLists = {
  title: 'Get More Polls & Lists',
  description: (
    <p>
      Congrats! You’ll make everyone’s life easier using Hobnob’s Polls & Lists! Since you’ve
      exceeded your available Free active polls or lists, you’ll need to upgrade to save & send.{' '}
      <b>Your friends will thank you!</b> 🗓 ✅ 🙌
    </p>
  ),
  buttonText: 'SEE UPGRADE OPTIONS',
  closeText: 'No, Thanks',
  img: GetMorePollsListsBg
}

const customMessage = {
  title: 'Premium Feature',
  description: (
    <p>
      <b>Including a custom message</b> in the text or email that is sent with your invite is a
      premium feature. <b>Upgrade to customize your message!</b> 💬 📝 🎉
    </p>
  ),
  buttonText: 'SEE UPGRADE OPTIONS',
  closeText: 'No, Thanks',
  img: CustomMessageBg
}

const sendUpdate = {
  title: 'Upgrade to Send Updates',
  description: (
    <p>
      Get access to <b>Instant Text Event Updates</b> and unlock <b>ALL Premium Designs</b> by
      upgrading your membership!
    </p>
  ),
  buttonText: 'UPGRADE',
  closeText: 'No, Thanks',
  img: SendUpdateBg
}

const UpgradePlan = () => {
  const dispatch = useDispatch()
  const { content } = useSelector(globalLayerSelector)
  const isHost = useSelector(isHostSelector)
  const { current_subscription } = useSelector(userSelector)
  const { subscription_type } = current_subscription

  const renderLockedEvent = () => {
    let title = 'Upgrade to Unlock'
    let description = (
      <p>
        Looks like this event was created on a free trial. Get unlimited events, designs, polls, and
        lists to unlock this event and keep the party going.
      </p>
    )
    let buttonText = 'UNLOCK ALL THE GOOD TIMES!'
    let closeText = 'No, Thanks'

    if (!isHost) {
      title = 'Event Expired'
      description = (
        <p>
          Looks like this Event has expired. <br />
          To unlock the Event, please contact your Host.
        </p>
      )
      buttonText = 'OK'
      closeText = null
    }

    return {
      title,
      description,
      buttonText,
      closeText,
      img: LockEventBg
    }
  }

  const getBusinessType = () => {
    switch (content) {
      case UPGRADE_PLAN_SOURCE.getMorePollsAndLists:
        return getMorePollsAndLists
      case UPGRADE_PLAN_SOURCE.customMessage:
        return customMessage
      case UPGRADE_PLAN_SOURCE.sendUpdate:
        return sendUpdate
      case UPGRADE_PLAN_SOURCE.lockedEvent:
        return renderLockedEvent()
      default:
        return {}
    }
  }

  const handleClickButton = () => {
    if (content === UPGRADE_PLAN_SOURCE.lockedEvent && !isHost) {
      dispatch(changeVisible(false))
      return
    }

    if (!verifySubscriptionPlatform(subscription_type)) {
      return
    }

    if (content === UPGRADE_PLAN_SOURCE.sendUpdate) {
      dispatch(setSpecifyPlan('PartyHost'))
    } else if (content === UPGRADE_PLAN_SOURCE.customMessage) {
      dispatch(setSpecifyPlan('InvitationMaker'))
    }

    dispatch(changePlanModalVisible(true))
    dispatch(changeVisible(false))
  }

  const { title, description, buttonText, closeText, img } = getBusinessType()

  const actionLayer = (
    <div className={styles['action-layer']}>
      <h1>{title}</h1>
      {description}

      <Button className={styles['upgrade-button']} onClick={handleClickButton}>
        {buttonText}
      </Button>

      {closeText && (
        <span className={styles['close-button']} onClick={() => dispatch(changeVisible(false))}>
          {closeText}
        </span>
      )}
    </div>
  )

  const descLayer = (
    <div className={styles['desc-layer']}>
      <img src={img} className={styles['img-inner']} alt="" />
      <div className={styles['right-triangle']} />
    </div>
  )

  return <CommonFrame actionLayer={actionLayer} descLayer={descLayer} />
}

export default UpgradePlan
