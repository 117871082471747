/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import SvgIcon from 'components/common/SvgIcon'
import deleteIcon from '../../../assets/icons/delete-icon.svg'
import leftArrow from '../../../assets/icons/left-arrow.svg'

import HobnobModal from '../../../components/common/modal/Modal'
import DayPicker from '../../../components/day-picker/DayPicker'
import Picker from '../../../components/common/picker'
import Button from '../../../components/common/button/Button'

import { compareTime, conversionTime, get24HourTime } from '../../../utils/dates'
import styles from './create-poll-mobile.scss'

const optionGroups = {
  hour: ['12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
  minute: ['00'],
  type: ['AM', 'PM']
}

const initialSelectedTime = {
  hour: '12',
  minute: '00',
  type: 'AM'
}

const CreatePollMobile = props => {
  const { selectedDateRange, handleSelectedDone } = props
  const [isOpenTimeModal, setIsOpenTimeModal] = useState(false)
  const [selectedDates, setSelectedDates] = useState(selectedDateRange.dates)
  const [selectedTimeList, setSelectedTimeList] = useState(selectedDateRange.timeList)
  const [activeDay, setActiveDay] = useState(null)
  const [activeTime, setActiveTime] = useState(null)
  const [selectedTime, setSelectedTime] = useState(initialSelectedTime)

  const timeList = useMemo(() => {
    if (!activeDay) {
      return []
    }
    return selectedTimeList.filter(item => item.dateId === activeDay.id)
  }, [selectedTimeList, activeDay])

  const showAddTime = Boolean(activeDay)

  const handleAddTime = () => {
    setSelectedTimeList(
      selectedTimeList.concat([
        {
          start: { hour: '12', minute: '00', type: 'AM' },
          end: { hour: '11', minute: '59', type: 'PM' },
          id: uuid(),
          dateId: activeDay.id
        }
      ])
    )
  }

  const handleInputClick = (step, time) => {
    let initialInputTime = { ...time[step] }

    if (step === 'end' && conversionTime(initialInputTime)) {
      initialInputTime = conversionTime(initialInputTime)
    }

    setSelectedTime(initialInputTime)
    setActiveTime({ id: time.id, step })
    setIsOpenTimeModal(true)
  }

  const handleTimeModalClose = () => {
    const copyTimeList = [...selectedTimeList]
    const { id, step } = activeTime
    const activeTimeIndex = copyTimeList.findIndex(item => item.id === id)
    const activeTimeItem = copyTimeList[activeTimeIndex]
    let copySelectedTime = { ...selectedTime }

    if (step === 'end' && conversionTime(selectedTime)) {
      copySelectedTime = conversionTime(selectedTime)
    }
    activeTimeItem[step] = copySelectedTime

    const { startTime, endTime } = compareTime(activeTimeItem.start, activeTimeItem.end)

    copyTimeList[activeTimeIndex] = {
      ...activeTimeItem,
      start: startTime,
      end: endTime
    }

    setSelectedTimeList(copyTimeList)
    setIsOpenTimeModal(false)
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  })

  return (
    <div className={styles['calendar-mobile-wrapper']}>
      <div className={styles['calendar-title']}>
        <SvgIcon style={{ width: 13 }} icon={leftArrow} onClick={() => handleSelectedDone(null)} />
        <span className={styles['calendar-title-text']}>dates</span>
        <span
          className={styles['calendar-title-done']}
          onClick={() => {
            handleSelectedDone({
              dates: selectedDates,
              timeList: selectedTimeList
            })
          }}
        >
          done
        </span>
      </div>

      {showAddTime && (
        <div className={styles['calendar-time-wrapper']}>
          <div className={styles['calendar-time-button']}>
            <span onClick={handleAddTime}>&#43; add time</span>
          </div>

          {timeList.length > 0 &&
            timeList.map(time => (
              <div key={time.id} className={styles['calendar-input-wrapper']}>
                <input
                  type="text"
                  className={styles['calendar-input-time']}
                  placeholder="00:00"
                  readOnly
                  value={get24HourTime(time.start)}
                  onClick={() => handleInputClick('start', time)}
                />
                <span>&nbsp;&#45;&nbsp;</span>
                <input
                  type="text"
                  className={styles['calendar-input-time']}
                  placeholder="00:00"
                  readOnly
                  value={get24HourTime(time.end)}
                  onClick={() => handleInputClick('end', time)}
                />
                <SvgIcon
                  icon={deleteIcon}
                  style={{ width: 14 }}
                  onClick={() =>
                    setSelectedTimeList(selectedTimeList.filter(item => item.id !== time.id))
                  }
                />
              </div>
            ))}
          <HobnobModal isOpen={isOpenTimeModal} onClose={() => setIsOpenTimeModal(false)}>
            <>
              <Picker
                onChange={(name, value) => setSelectedTime({ ...selectedTime, [name]: value })}
                optionGroups={optionGroups}
                valueGroups={selectedTime}
                customStyle={{ width: '300px' }}
              />
              <div style={{ padding: '20px' }}>
                <Button onClick={handleTimeModalClose}>save</Button>
              </div>
            </>
          </HobnobModal>
        </div>
      )}
      <DayPicker
        selectTimesMobile
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        activeDay={activeDay}
        setActiveDay={setActiveDay}
        onDeleteTime={dateId =>
          setSelectedTimeList(selectedTimeList.filter(time => time.dateId !== dateId))
        }
      />
    </div>
  )
}

export default CreatePollMobile
