import React from 'react'
import Proptypes from 'prop-types'

import { StripeProvider } from 'react-stripe-elements'

export default class Stripe extends React.Component {
  constructor (props) {
    super(props)
    this.state = { stripe: null }
  }

  componentWillMount () {
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(process.env.STRIPE_API_KEY) })
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(process.env.STRIPE_API_KEY) })
      })
    }
  }

  render () {
    // this.state.stripe will either be null or a Stripe instance
    // depending on whether Stripe.js has loaded.
    const { children } = this.props
    if (this.state.stripe) {
      return (
        <StripeProvider stripe={this.state.stripe}>
          {children}
        </StripeProvider>
      )
    }
    return children
  }
}

Stripe.propTypes = {
  children: Proptypes.any
}
