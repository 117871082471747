export const CONTACT_METHOD = 'ContactMethod'
export const EMAIL_CONTACT_METHOD = 'EmailContactMethod'
export const PHONE_NUMBER_CONTACT_METHOD = 'PhoneNumberContactMethod'
export const HOBNOB_USER_CONTACT_METHOD = 'HobnobUserContactMethod'
export const VALID_COUNTRY_CODES = [
  { code: 'US', value: '1', country: 'United States' },
  { code: 'CA', value: '1', country: 'Canada' }
]

// Approach based on http://stackoverflow.com/questions/673905/best-way-to-determine-users-locale-within-browser
// May not be 100% reliable, so only use as a default that can be changed.
// Example language codes: http://www.localeplanet.com/icu/iso639.html
export function detectCountryCode () {
  const value = typeof navigator !== 'undefined' && (navigator.userLanguage || navigator.language)

  if (!value) {
    return 'US'
  }

  switch (value.toLowerCase()) {
    case 'en-ca':
    case 'fr-ca':
      return 'CA'
    default:
      return 'US'
  }
}

export function buildPhoneNumberContactMethod (phoneNumber, countryCode) {
  return {
    type: PHONE_NUMBER_CONTACT_METHOD,
    phone_number: phoneNumber,
    country_code: countryCode
  }
}

export function buildEmailContactMethod (emailAddress) {
  return {
    type: EMAIL_CONTACT_METHOD,
    email_address: emailAddress
  }
}
