import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { chatSelector, userIdSelector } from 'src/selectors'
import { ROOM_CHANNEL_TYPES_CONSTANTS } from 'src/constants'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import ReactionIcon from 'assets/icons/reaction.png'
import { Popover } from 'antd'
import { pushChannel } from 'src/services/websocket'
import styles from './input-emoji.scss'

const InputEmoji = props => {
  const { messageId, reactions } = props
  const { channelName } = useSelector(chatSelector)
  const userId = useSelector(userIdSelector)
  const [isEmojiPopoverVisible, setIsEmojiPopoverVisible] = useState(false)

  const handleSelect = emoji => {
    setIsEmojiPopoverVisible(false)

    // If the user selects repeatedly.
    const isSelected = reactions.find(reaction => {
      if (reaction.slug === emoji.id) {
        return reaction.user_ids.includes(userId)
      }
    })
    if (isSelected) {
      return
    }

    pushChannel({
      channelName,
      action: ROOM_CHANNEL_TYPES_CONSTANTS.reaction_add,
      payload: {
        message_id: messageId,
        slug: emoji.id
      }
    })
  }

  const renderEmojiPicker = () => {
    return <Picker previewPosition="none" data={data} onEmojiSelect={handleSelect} />
  }

  return (
    <Popover
      open={isEmojiPopoverVisible}
      trigger="click"
      content={renderEmojiPicker()}
      onOpenChange={visible => setIsEmojiPopoverVisible(visible)}
    >
      <div className={styles['input-emoji-container']}>
        <img src={ReactionIcon} width={20} />
      </div>
    </Popover>
  )
}

InputEmoji.propTypes = {
  reactions: PropTypes.array,
  messageId: PropTypes.string
}

export default InputEmoji
