import PropTypes from 'prop-types'
import React from 'react'

export default class SvgIcon extends React.PureComponent {
  render() {
    const { divClassName, icon, padding, onClick, style, divStyle: ds = {}, ...rest } = this.props
    let svgStyle = { pointerEvents: 'none', ...style }
    let divStyle = { ...ds, padding }
    if (onClick) {
      divStyle.cursor = 'pointer'
    }

    return (
      <div className={divClassName} style={divStyle} onClick={onClick}>
        <svg viewBox={icon.viewBox} style={svgStyle} {...rest} >
          <use xlinkHref={`#${icon.id}`} />
        </svg>
      </div>
    )
  }
}

SvgIcon.propTypes = {
  divClassName: PropTypes.string,
  icon: PropTypes.object,
  onClick: PropTypes.func,
  padding: PropTypes.string,
  style: PropTypes.object,
  divStyle: PropTypes.object
}

SvgIcon.defaultProps = {
  padding: '0'
}
