import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ClassNames from 'classnames'
import { connect } from 'react-redux'
import { userSelector } from 'src/selectors'

import Button from 'src/components/common/Button'

import styles from './rsvp-buttons.scss'

class RSVPButtons extends Component {
  _renderRsvp () {
    const { invitation, onChangeRsvp } = this.props
    const message = invitation.rsvp_state === 'accepted' ? `Yes I'm going` : `No, Can't Go`

    return (
      <div className={ClassNames(invitation.rsvp_state === 'declined' ? styles['rsvp-declined-container'] : styles['rsvp-accepted-container'])}>
        My RSVP: <a className={styles['rsvp-message']} onClick={onChangeRsvp}>{message}</a>
      </div>
    )
  }

  isDisable = () => {
    const { invitation, user, event } = this.props

    const isDraft = event.state === 'draft'

    if ((user && !invitation) || isDraft) return true
  }

  _renderButtons () {
    const { onAcceptInvitation, onDeclineInvitation, disabled = false } = this.props

    return (
      <div className={styles['buttons-container']}>
        <Button disabled={disabled} onClick={onAcceptInvitation} className={ClassNames(styles['button'], { [styles['button-disable']]: this.isDisable() })}>Yes, I'm going</Button>
        <Button disabled={disabled} onClick={onDeclineInvitation} className={ClassNames(styles['button'], { [styles['button-disable']]: this.isDisable() })}>No, can't go</Button>
      </div>
    )
  }

  render () {
    const { invitation } = this.props
    const showButtons = !invitation || invitation.rsvp_state === 'undecided'

    if (showButtons) {
      return this._renderButtons()
    } else {
      return this._renderRsvp()
    }
  }
}

RSVPButtons.propTypes = {
  invitation: PropTypes.object,
  onAcceptInvitation: PropTypes.func,
  onDeclineInvitation: PropTypes.func,
  onChangeRsvp: PropTypes.func,
  disabled: PropTypes.bool,
  user: PropTypes.object,
  event: PropTypes.object
}

function mapStateToProps (state) {
  return {
    user: userSelector(state)
  }
}

export default connect(mapStateToProps)(RSVPButtons)
