import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import AvatarBorder from './AvatarBorder'

import GenericAvatar from 'src/assets/avatar_generic@2x.png'
import styles from './avatar.scss'

export default class Avatar extends React.Component {
  _borderSize () {
    const { size } = this.props
    switch (size) {
      case 100:
      case 80:
        return 3
      case 60:
      case 50:
        return 2
      case 43:
      case 34:
      case 30:
      case 26:
      case 20:
        return 1
      default:
        console.error('Unable to find border-size for avatar size: ', size)
        return 1
    }
  }

  _fontSize () {
    const { size } = this.props
    switch (size) {
      // For profile photo (not used yet)
      case 100:
      case 80:
        return '33px'
      // Details tab host avatar
      case 60:
      case 50:
        return '24px'
      case 43:
        return '18px'
      case 34:
        return '15px'
      case 30:
      case 26:
      case 20:
        // Might want to check if this should be 15px
        return '13px'
      default:
        console.error('Unable to find font-size for avatar size: ', size)
        return '13px'
    }
  }

  _styles () {
    const { onClick, style, size, url, isMasked } = this.props
    const sizeInPx = `${size}px`

    const sharedStyles = {
      borderRadius: sizeInPx,
      height: sizeInPx,
      minWidth: sizeInPx,
      width: sizeInPx
    }

    if (onClick) sharedStyles.cursor = 'pointer'

    if (url || isMasked) {
      return {
        ...sharedStyles,
        ...style
      }
    } else {
      return {
        ...sharedStyles,
        backgroundColor: '#C2C4C7',
        lineHeight: sizeInPx,
        fontSize: this._fontSize(),
        ...style
      }
    }
  }

  _renderInitials () {
    let { firstName, lastName, textOverride } = this.props
    if (textOverride) return textOverride
    let firstInitial = firstName ? firstName[0] : ''
    let lastInitial = lastName ? lastName[0] : ''

    if (firstInitial.charCodeAt(0) > 255) {
      firstInitial += firstName[1]
    }

    if (lastInitial.charCodeAt(0) > 255) {
      lastInitial += lastName[1]
    }

    return (firstInitial + lastInitial).toUpperCase()
  }

  _renderAvatar () {
    const { className, onClick, url, isMasked } = this.props

    if (url) {
      const classes = [className, styles.avatar]
      return (
        <img
          className={classnames(classes)}
          onClick={onClick}
          src={url}
          style={this._styles()}
        />
      )
    } else if (isMasked) {
      const classes = [className, styles.avatar]
      return (
        <img
          className={classnames(classes)}
          onClick={onClick}
          src={GenericAvatar}
          style={this._styles()}
        />
      )
    } else {
      return (
        <div
          className={classnames(className, styles.fauxAvatar)}
          onClick={onClick}
          style={this._styles()}
        >
          {this._renderInitials()}
        </div>
      )
    }
  }

  render () {
    const { appUser, includeBorder, size, user, inProfile } = this.props

    return <AvatarBorder
      appUser={appUser}
      user={user}
      borderWidth={this._borderSize()}
      includeBorder={includeBorder}
      size={size}
      inProfile={inProfile}
    >
      {this._renderAvatar()}
    </AvatarBorder>
  }
}

Avatar.propTypes = {
  appUser: PropTypes.bool,
  user: PropTypes.object,
  includeBorder: PropTypes.bool,
  className: PropTypes.string,
  url: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  isMasked: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf([100, 80, 60, 50, 43, 34, 30, 26, 20]).isRequired,
  style: PropTypes.object,
  // If provided, always show a faux avatar with the given text
  textOverride: PropTypes.string,
  inProfile: PropTypes.bool
}

Avatar.defaultProps = {
  appUser: false,
  includeBorder: true
}
