import PropTypes from 'prop-types'
import React from 'react'
import queryString from 'query-string'
// import { useDispatch, useSelector } from 'react-redux'
import { useSelector } from 'react-redux'
import { topBannerSelector } from 'src/selectors'
// import { hideBanner } from 'src/actions/frontend'

// import { isAndroid } from 'src/services/browser_detection'

import Button from 'components/common/Button'
// import SvgIcon from 'components/common/SvgIcon'

import styles from './download-app-banner.scss'
// import iosIcon from 'src/assets/icons/hobnob_iOS_icon.svg'
// import androidIcon from 'src/assets/icons/app-icon-android-small.png'
// import CloseIconBg from 'src/assets/icons/close-icon-bg.png'
import cn from 'classnames'

export default function DownloadAppBanner(props) {
  const { containerClassName } = props
  const { title, text } = useSelector(topBannerSelector)
  // const dispatch = useDispatch()
  // const onHideBannerIcon = () => {
  //   dispatch(hideBanner())
  //   sessionStorage.setItem('hideBanner', true)
  // }

  const downloadLink = () => {
    const { eventToken, invitationToken } = props

    const base = process.env.BRANCH_INSTALL_BANNER_LINK

    const params = {}
    if (eventToken) {
      params.event_token = eventToken
    }
    if (invitationToken) {
      params.invitation_token = invitationToken
    }

    return `${base}?${queryString.stringify(params)}`
  }

  // const renderAppIcon = () => {
  //   if (isAndroid()) {
  //     return <img src={androidIcon} />
  //   } else {
  //     return <SvgIcon icon={iosIcon} />
  //   }
  // }

  return (
    <div className={cn(styles['banner-container'], containerClassName)}>
      <div className={styles['container']}>
        {/* <a href={downloadUrl} className={styles['icon']}>
            {renderAppIcon()}
          </a> */}
        <div className={styles['text']}>
          <div className={styles['app-name']}>{title}</div>
          <div className={styles['description']}>{text}</div>
        </div>
        <Button wrapperElement={'a'} className={styles['download-btn']} href={downloadLink()}>
          GET APP
        </Button>
        {/* <div className={styles['close-icon']} onClick={onHideBannerIcon}>
          <img src={CloseIconBg} width={20} />
        </div> */}
      </div>
    </div>
  )
}

DownloadAppBanner.propTypes = {
  eventToken: PropTypes.string,
  invitationToken: PropTypes.string,
  containerClassName: PropTypes.string
}
