import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { chatSelector, usersSelector } from 'src/selectors'
import UserMessage from '../UserMessage/UserMessage'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { sortBy } from 'lodash'
import { setReplyMessage } from 'src/actions/eventChat'
import styles from './reply-message.scss'

const ReplyMessage = props => {
  const dispatch = useDispatch()
  const { message, inReply } = props
  const { replies } = message
  const users = useSelector(state => usersSelector(state))
  const { chatMessages } = useSelector(state => chatSelector(state))
  const seeAllVisible = !inReply && replies.filter(reply => !reply.destroyed_at).length > 1

  const replyMessageList = sortBy(
    replies
      .map(reply => chatMessages[reply.id])
      .filter(reply => !reply.destroyed_at && message.id !== reply.id),
    ['timestamp']
  )

  return (
    <div
      className={cn(
        styles['reply-message-container'],
        !inReply && styles['reply-message-container-not-in-reply']
      )}
    >
      {replyMessageList.map((message, index) => {
        const user = users[message.user_id]

        if (!inReply) {
          if (index === 0) {
            return <UserMessage inReply={inReply} key={message.id} message={message} user={user} />
          }
          return
        }

        return <UserMessage inReply={inReply} key={message.id} message={message} user={user} />
      })}

      {seeAllVisible && (
        <div
          className={styles['see-all-button']}
          onClick={() => dispatch(setReplyMessage(message))}
        >
          See All Replies
        </div>
      )}
    </div>
  )
}

ReplyMessage.propTypes = {
  inReply: PropTypes.bool,
  message: PropTypes.object.isRequired
}

export default ReplyMessage
