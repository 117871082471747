import React from 'react'
import { PHONE_NUMBER_CONTACT_METHOD } from 'services/contact_methods'
import PropTypes from 'prop-types'
import { isValidPhoneOrEmail } from 'services/utils'
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js'

function withAddGuestEditModal(WrappedComponent) {
  class WithAddGuestEditModal extends React.Component {
    state = {
      isOpenEditModal: false,
      guestInfo: null,
      visibleErrorText: false,
      first_name: '',
      last_name: '',
      phoneOrEmail: ''
    }

    closeModal = () => {
      this.setState({
        isOpenEditModal: false,
        guestInfo: null,
        visibleErrorText: false,
        first_name: '',
        last_name: '',
        phoneOrEmail: ''
      })
    }

    openModal = guest => {
      const { first_name, last_name, contact_method_display_name, contact_method_type } = guest

      let phoneOrEmail = ''

      if (contact_method_type === PHONE_NUMBER_CONTACT_METHOD) {
        const phoneNumber = parsePhoneNumber(contact_method_display_name, 'US')
        phoneOrEmail = phoneNumber.formatNational()
      } else {
        phoneOrEmail = contact_method_display_name
      }

      this.setState({
        isOpenEditModal: true,
        first_name,
        last_name,
        phoneOrEmail,
        guestInfo: guest
      })
    }

    handleSave = () => {
      const { guestInfo, first_name, last_name, phoneOrEmail } = this.state

      const { onEditGuest } = this.props

      let guestInformation = {
        ...guestInfo,
        first_name,
        last_name
      }

      if (first_name === '' && last_name === '') {
        guestInformation.first_name = 'Guest'
        guestInformation.last_name = 'User'
      }

      if (!guestInfo.userFromHobnobContacts) {
        const contact_method_type = isValidPhoneOrEmail(phoneOrEmail)

        if (!contact_method_type) {
          this.setState({ visibleErrorText: true })
          return
        }

        guestInformation.contact_method_type = contact_method_type

        if (contact_method_type === PHONE_NUMBER_CONTACT_METHOD) {
          const phoneNumber = parsePhoneNumber(phoneOrEmail, 'US')
          guestInformation.contact_method_display_name = phoneNumber
            .formatInternational()
            .replace(/\s|\+/g, '')
        } else {
          guestInformation.contact_method_display_name = phoneOrEmail
        }
      }
      onEditGuest(guestInformation)
      this.closeModal()
    }

    handleInputPhoneOrEmail = value => {
      const numberReg = new RegExp('^[0-9]{10}$').test(value)
      let phoneOrEmail = ''

      if (numberReg) {
        phoneOrEmail = new AsYouType('US').input(value)
      } else {
        phoneOrEmail = value.replace(/\(|\)|-|\s/g, '')
      }

      this.setState({
        phoneOrEmail,
        visibleErrorText: false
      })
    }

    handleInputChange = (name, value) => {
      if (name === 'phoneOrEmail') {
        this.handleInputPhoneOrEmail(value)
        return
      }
      this.setState({
        [name]: value
      })
    }

    render() {
      const { guestInfo } = this.state
      const visibleContactInput = !guestInfo?.userFromHobnobContacts

      return (
        <WrappedComponent
          {...this.state}
          onClose={this.closeModal}
          onInputChange={this.handleInputChange}
          onSave={this.handleSave}
          visibleContactInput={visibleContactInput}
        />
      )
    }
  }

  WithAddGuestEditModal.displayName = `WithAddGuestEditModal(${WrappedComponent.displayName})`
  WithAddGuestEditModal.propTypes = {
    onEditGuest: PropTypes.func
  }

  return WithAddGuestEditModal
}

export default withAddGuestEditModal
