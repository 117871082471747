import jsCookie from 'js-cookie'

let cookieOptions = {
  expires: 365
}
if (typeof location !== 'undefined') {
  cookieOptions.path = location.pathname
}

export function getCommentsCount () {
  return parseInt(jsCookie.get('comments-shortcut-count')) || 0
}

export function setCommentsCount (count) {
  if (count > getCommentsCount()) {
    jsCookie.set('comments-shortcut-count', count, cookieOptions)
  }
}

export function getMediaCount () {
  return parseInt(jsCookie.get('media-shortcut-count')) || null
}

export function setMediaCount (count) {
  if (count > getMediaCount()) {
    jsCookie.set('media-shortcut-count', count, cookieOptions)
  }
}

export function getInvitationViewed () {
  return (jsCookie.get('invitation-viewed') || false)
}

export function setInvitationViewed () {
  jsCookie.set('invitation-viewed', true, cookieOptions)
}

export function getOpenInAppModalClosed () {
  return jsCookie.get('in-app-modal-closed') || false
}

export function setOpenInAppModalClosed () {
  jsCookie.set('in-app-modal-closed', true, cookieOptions)
}
