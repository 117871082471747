import React, { useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PlanTypes from './PlanTypes/PlanTypes'
import cn from 'classnames'
import CloseWhite from 'assets/icons/close_white.png'
import PageContainerContext from 'services/contexts'
import { planSelector, userSelector } from 'src/selectors'
import { changePlanModalVisible } from 'src/actions/plan'
import { setContent } from 'src/actions/globalLayer'
import { PlanType, getPlanBySlug } from 'src/services/stripe_checkout_configs'
import { GLOBAL_LAYER_CONTENT } from 'src/constants'

import styles from './plan-container.scss'

const PlanContainer = () => {
  const dispatch = useDispatch()
  const { visible: isPlanModalVisible, downgrade } = useSelector(planSelector)
  const context = useContext(PageContainerContext)
  const user = useSelector(userSelector)
  const planType = getPlanBySlug(user.current_subscription.plan.slug, 'planType')
  const isFreePlan = planType === PlanType.Free

  useEffect(() => {
    const metaTag = document.createElement('meta')
    metaTag.setAttribute('name', 'theme-color')
    metaTag.setAttribute('content', '#2cd4ae')
    document.head.appendChild(metaTag)

    return () => {
      document.head.removeChild(metaTag)
    }
  }, [])

  useEffect(() => {
    if (isPlanModalVisible) {
      const handleStorageCallback = data => {
        if (data?.hasError) {
          alert('Payment failed.')
        } else if (data && !data?.hasError && (data.planUpdated || data.creditsUpdated)) {
          dispatch(changePlanModalVisible(false))
          dispatch(setContent(GLOBAL_LAYER_CONTENT.subscribeSuccess))
        }
      }

      context.onSetStorageCallback(handleStorageCallback)
    }

    return () => {
      context.onSetStorageCallback(null)
    }
  }, [isPlanModalVisible])

  return (
    <div className={styles['plans-container']}>
      <div className={cn(styles['header-text'], 'web-view')}>
        <img src={CloseWhite} width={18} onClick={() => dispatch(changePlanModalVisible(false))} />
        <h1>
          {isFreePlan
            ? 'Plan Events & Organize Groups like a Pro'
            : downgrade
            ? 'A Lower-Cost Plan '
            : 'Upgrade Plan'}
        </h1>
        <div style={{ width: '18px' }} />
      </div>
      <PlanTypes />
    </div>
  )
}

export default PlanContainer
