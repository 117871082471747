import Compressor from 'compressorjs'

export function tooltipClickCoordinates(e) {
  return {
    x: e.nativeEvent.x,
    y: e.nativeEvent.y,
    clientX: e.nativeEvent.clientX,
    clientY: e.nativeEvent.clientY,
    screenX: e.nativeEvent.screenX,
    screenY: e.nativeEvent.screenY
  }
}

export function formatDoubleQuotes(data) {
  let itemData = {}
  for (let key in data) {
    // if (data[key] !== null && data[key] !== undefined) {
    itemData[key] = data[key]
    // }
  }

  let a = JSON.stringify(itemData).split(',')
  let b = a.map(item => {
    let i = item.split(':')
    let copyI = JSON.parse(JSON.stringify(i))
    const key = copyI.shift()
    let p = key.replace(/"/g, '')
    return p + ':' + copyI.join(':')
  })
  return b.join(',')
}

export const isSmallScreen = () => {
  const smallScreenBreakpoint = 1080
  return window.innerWidth < smallScreenBreakpoint
}

export const compressImage = (dataURL, options) => {
  return new Promise(resolve => {
    fetch(dataURL)
      .then(res => res.blob())
      .then(blob => {
        // doc: https://github.com/fengyuanchen/compressorjs
        // eslint-disable-next-line no-new
        new Compressor(blob, {
          success(compressedBlob) {
            const reader = new FileReader()
            reader.onload = e => {
              resolve(e.target.result)
            }
            reader.readAsDataURL(compressedBlob)
          },
          quality: 0.6,
          error(error) {
            console.warn('failed to compress image, fallback to original size')
            console.warn('with CompressorJS error', error)
            resolve(dataURL)
          },
          ...options
        })
      })
  })
}

export const isProdReactEnvironment = () => {
  return process.env.REACT_ENVIRONMENT === 'production'
}

export const isStagingReactEnvironment = () => {
  return process.env.REACT_ENVIRONMENT === 'staging'
}
