export const DOWNLOAD_APP_LINK = process.env.DEFAULT_BRANCH_LINK

export const DESTINATION_TYPES_CONSTANTS = {
  announcement: 'a',
  event: 'e',
  timepoll: 'tp',
  signUp: 'su'
}

export const POPUP_BOX_TYPES_CONSTANTS = {
  addGuestsList: 'addGuestsListPopupBox',
  addGuests: 'addGuestsPopupBox',
  inputGuests: 'inputGuestsPopupBox',
  guestsList: 'guestsListPopupBox',
  eventComments: 'eventCommentsPopupBox',
  dmChat: 'dmChatPopupBox'
}

export const ROOM_CHANNEL_TYPES_CONSTANTS = {
  create_new_message: 'message:new',
  listen_new_message: 'message:new',
  message_history: 'message:history',
  message_destroy: 'message:destroy',
  message_update: 'message:update',
  reaction_add: 'reaction:add',
  reaction_destroy: 'reaction:destroy',
  message_read: 'message:read',
  room_mention_groups: 'room:mention_groups',
  room_members: 'room:members',
  mini_badge_created: 'mini_badge:created'
}
export const GLOBAL_LAYER_CONTENT = {
  freeMembership: 'freeMembership',
  pendingPaymentResult: 'pendingPaymentResult',
  subscribeSuccess: 'subscribeSuccess',
  cancelMembership: 'cancelMembership',
  purchaseCredit: 'purchaseCredit',
  unsentInvites: 'unsentInvites'
}

export const UPGRADE_PLAN_SOURCE = {
  getMorePollsAndLists: 'upgrade-getMorePollsAndLists',
  customMessage: 'upgrade-customMessage',
  sendUpdate: 'upgrade-sendUpdate',
  lockedEvent: 'upgrade-lockedEvent'
}
