import { combineReducers } from 'redux'

import { detectCountryCode } from 'src/services/contact_methods'
import { CREATE_USER_SUCCESS } from 'src/actions/user'

import { FETCH_INVITATION_SUCCESS } from 'src/actions/invitations'

import {
  SHOW_LOGIN,
  HIDE_LOGIN,
  BACK_TO_CONTACT_METHOD_FIELDS_FORM_PAGE,
  UPDATE_LOGIN_FORM,
  SHOW_CONFIRMATION_CODE_FORM,
  SUBMIT_LOGIN_CONFIRMATION_SUCCESS,
  SUBMIT_LOGIN_CONFIRMATION_ERROR,
  RESET_LOGIN_FORM,
  SET_USE_MASKED_EMAIL,
  SHOW_CONTACT_METHOD_FIELDS_FORM,
  SHOW_UPLOAD_IMAGE_CROPPER,
  HIDE_UPLOAD_IMAGE_CROPPER,
  BACK_TO_LOGIN_FORM_PAGE
} from 'src/actions/login'

import { createLoadingDuck } from './utils'

function loginFormReducer(key, defaultValue, reducer) {
  return (state = defaultValue, action) => {
    switch (action.type) {
      case UPDATE_LOGIN_FORM: {
        const value = action.loginForm[key]

        if (value !== undefined) {
          return value
        } else {
          return state
        }
      }
      case HIDE_LOGIN:
      case RESET_LOGIN_FORM:
        return defaultValue
      default:
        return reducer(state, action)
    }
  }
}

const loginMessageDefault = 'Login'
const loginMessage = loginFormReducer('loginMessage', loginMessageDefault, (state, action) => {
  switch (action.type) {
    case SHOW_LOGIN:
      return action.loginMessage || state
    case HIDE_LOGIN:
      return loginMessageDefault
    default:
      return state
  }
})

const subscribeMessageDefault = 'Subscribe'
const subscribeMessage = loginFormReducer(
  'subscribeMessage',
  subscribeMessageDefault,
  (state, action) => {
    switch (action.type) {
      case SHOW_LOGIN:
        return action.subscribeMessage || state
      case HIDE_LOGIN:
        return subscribeMessageDefault
      default:
        return state
    }
  }
)

const loginModalTitleDefault = 'Sign Up/Sign In'
const loginModalTitle = loginFormReducer(
  'loginModalTitle',
  loginModalTitleDefault,
  (state, action) => {
    switch (action.type) {
      case SHOW_LOGIN:
        return action.loginModalTitle || state
      case HIDE_LOGIN:
        return loginModalTitleDefault
      default:
        return state
    }
  }
)

const loginModalSubtitleDefault =
  'Your phone number is your Hobnob ID and helps us link all your events & polls'
const loginModalSubtitle = loginFormReducer(
  'loginModalSubtitle',
  loginModalSubtitleDefault,
  (state, action) => {
    switch (action.type) {
      case SHOW_LOGIN:
        return action.loginModalSubtitle || state
      case HIDE_LOGIN:
        return loginModalSubtitleDefault
      default:
        return state
    }
  }
)

const fullNameTitle = loginFormReducer('fullNameTitle', 'Set Your User Name', state => state)

const fullNameSubtitle = loginFormReducer(
  'fullNameSubtitle',
  'How do you want to be called by your Hosts & Guests?',
  state => state
)

const loginModalFirstPageSubtitleDefault = 'How do you want your name to appear on the guest list?'
const loginModalFirstPageSubtitle = loginFormReducer(
  'loginModalSubtitle',
  loginModalFirstPageSubtitleDefault,
  (state, action) => {
    switch (action.type) {
      case SHOW_LOGIN:
        return action.loginModalFirstPageSubtitle || state
      case HIDE_LOGIN:
        return loginModalFirstPageSubtitleDefault
      default:
        return state
    }
  }
)

const confirmationCode = loginFormReducer('confirmationCode', '', (state, action) => {
  switch (action.type) {
    case SHOW_LOGIN:
      return ''
    default:
      return state
  }
})

const showConfirmationCodeForm = loginFormReducer(
  'showConfirmationCodeForm',
  false,
  (state, action) => {
    switch (action.type) {
      case SHOW_LOGIN:
      case HIDE_LOGIN:
      case BACK_TO_LOGIN_FORM_PAGE:
      case BACK_TO_CONTACT_METHOD_FIELDS_FORM_PAGE:
        return false
      case SHOW_CONFIRMATION_CODE_FORM:
        return true
      default:
        return state
    }
  }
)

const showContactMethodFieldsForm = loginFormReducer(
  'showContactMethodFieldsForm',
  false,
  (state, action) => {
    switch (action.type) {
      // case SHOW_LOGIN:
      case HIDE_LOGIN:
      case BACK_TO_LOGIN_FORM_PAGE:
      case SHOW_CONFIRMATION_CODE_FORM:
        return false
      case BACK_TO_CONTACT_METHOD_FIELDS_FORM_PAGE:
      case SHOW_CONTACT_METHOD_FIELDS_FORM:
        return true
      default:
        return state
    }
  }
)

const uploadImageCropper = loginFormReducer('showUploadCopper', false, (state, action) => {
  switch (action.type) {
    case SHOW_LOGIN:
    case HIDE_LOGIN:
    case BACK_TO_LOGIN_FORM_PAGE:
    case SHOW_CONFIRMATION_CODE_FORM:
    case SHOW_CONTACT_METHOD_FIELDS_FORM:
    case HIDE_UPLOAD_IMAGE_CROPPER:
      return false
    case SHOW_UPLOAD_IMAGE_CROPPER:
      return true
    default:
      return state
  }
})

const newUserId = loginFormReducer('newUserId', '', (state, action) => {
  switch (action.type) {
    case CREATE_USER_SUCCESS:
      return action.response.result.user || ''
    default:
      return state
  }
})

const confirmationCodeValidDefault = 'unknown'
const confirmationCodeValid = loginFormReducer(
  'confirmationCodeValid',
  confirmationCodeValidDefault,
  (state, action) => {
    switch (action.type) {
      case SUBMIT_LOGIN_CONFIRMATION_SUCCESS:
        return true
      // TODO: We should prbobably check what KIND of error this is...
      case SUBMIT_LOGIN_CONFIRMATION_ERROR:
        return false
      default:
        return state
    }
  }
)

const useMaskedEmail = loginFormReducer('useMaskedEmail', true, (state, action) => {
  switch (action.type) {
    case SET_USE_MASKED_EMAIL:
      return action.value
    default:
      return state
  }
})

const maskedEmailAddress = loginFormReducer('maskedEmailAddress', '', (state, action) => {
  switch (action.type) {
    case FETCH_INVITATION_SUCCESS:
      if (action.response.result.meta) {
        return action.response.result.meta.masked_email_address
      } else {
        return state
      }
    default:
      return state
  }
})

const loginInRSVP = loginFormReducer('loginInRSVP', false, (state = false, action) => {
  switch (action.type) {
    case SHOW_LOGIN:
      return action.loginInRSVP || false
    default:
      return state
  }
})

// contactMethod confirmationCode fullName

const loginStep = loginFormReducer('loginStep', 'contactMethod', (state, action) => {
  switch (action.type) {
    case SHOW_LOGIN:
      return 'contactMethod'
    default:
      return state
  }
})

// Create a redux duck module and mount it inside this reducer
export const confirmationCodeLoadingDuck = createLoadingDuck('confirmation_code_loading')

const firstName = loginFormReducer('firstName', '', state => state)
const lastName = loginFormReducer('lastName', '', state => state)
const imgPath = loginFormReducer('imgPath', '', state => state)
const emailAddress = loginFormReducer('emailAddress', '', state => state)
const phoneNumber = loginFormReducer('phoneNumber', '', state => state)
const formattedPhoneNumber = loginFormReducer('formattedPhoneNumber', '', state => state)
const countryCode = loginFormReducer('countryCode', detectCountryCode(), state => state)

export default combineReducers({
  firstName,
  lastName,
  imgPath,
  phoneNumber,
  formattedPhoneNumber,
  countryCode,
  emailAddress,
  confirmationCode,
  loginMessage,
  subscribeMessage,
  loginModalTitle,
  loginModalSubtitle,
  loginModalFirstPageSubtitle,
  showConfirmationCodeForm,
  showContactMethodFieldsForm,
  uploadImageCropper,
  newUserId,
  confirmationCodeValid,
  useMaskedEmail,
  maskedEmailAddress,
  confirmationCodeLoading: confirmationCodeLoadingDuck.reducer,
  loginStep,
  loginInRSVP,
  fullNameTitle,
  fullNameSubtitle
})
