import React, { Component } from 'react'
import { connect } from 'react-redux'
import OptionCard from './OptionCard/OptionCard'
import FullList from '../FullList/FullList'
import Button from 'src/components/common/Button'

import PropTypes from 'prop-types'
import cn from 'classnames'

import unionIcon from 'assets/icons/union-icon.png'
import warningIcon from 'assets/icons/warning-icon.png'

import { fetchSignUp, updateSignUpUserSelections, deleteSUL } from 'src/actions/timepoll'
import { signUpDetailSelector, userSelector } from 'src/selectors'
import { routerActions } from 'react-router-redux'
import { deepCopyArray, pluralize } from 'services/utils'
import { message } from 'antd'

import styles from './sul-options.scss'

class SULOptions extends Component {
  state = {
    isOpenFullModal: false,
    signUpOptionList: [],
    showToast: false,
    errorText: '',
    initialSignUpOptionList: []
  }

  calcVoterNumber = () => {
    const {
      signUpDetail: { poll_options }
    } = this.props
    const setIds = new Set()

    for (const options of poll_options) {
      for (const selection of options.poll_user_selections) {
        if (!selection.destroyed_at && !setIds.has(selection.user_id)) {
          setIds.add(selection.user_id)
        }
      }
    }

    return setIds.size
  }

  getUserIdOrAnonymousId = user => {
    return user ? user.id : localStorage.getItem('anonymousId')
  }

  getSignUpList = (user, data) => {
    const signUpDetail = data || this.props.signUpDetail
    if (!signUpDetail) {
      return
    }

    const userId = this.getUserIdOrAnonymousId(user)

    const signUpOptionList = signUpDetail.poll_options.map(item => {
      const userSelection = item.poll_user_selections.find(i => {
        if (user) {
          return i.user_id === userId
        } else {
          return i.anonymous_user_id === userId
        }
      })

      const params = {
        item: item.item,
        poll_option_id: item.id,
        available_count: item.available_count,
        initial_available_count: item.available_count,
        count_limit: item.count_limit,
        description: item.description,
        poll_user_selections: item.poll_user_selections
      }

      if (userSelection) {
        params.id = userSelection.id
        params.count = userSelection.count
        params.initial_count = userSelection.count
        params.isChecked = true
        params.note = userSelection.note
      } else {
        params.count = 0
        params.initial_count = 0
        params.isChecked = false
        params.note = ''
      }

      return params
    })

    this.setState({ signUpOptionList, initialSignUpOptionList: deepCopyArray(signUpOptionList) })
  }

  handleSubmit = () => {
    const { signUpOptionList, showToast } = this.state
    const { user, signUpDetail, onUpdateSignUpUserSelections, anonymousName, onUpdateSuccess } =
      this.props

    const validText = this._validButton()
    if (validText !== true) {
      if (!showToast) {
        this.setState({ showToast: true, errorText: validText.errorText }, () => {
          setTimeout(() => {
            this.setState({ showToast: false })
          }, 2000)
        })
      }
      return
    }

    const checkedList = signUpOptionList.filter(item => item.isChecked)
    const list_user_selections_params = checkedList.map(item => {
      const params = {
        poll_option_id: item.poll_option_id,
        count: item.count,
        note: item.note
      }

      if (user) {
        params.user_id = user.id
        params.custom_user_name = `${user.first_name} ${user.last_name}`
      } else {
        params.anonymous_user_id = localStorage.getItem('anonymousId')
        params.custom_user_name = anonymousName
      }

      if (item.id) {
        params.id = item.id
      }

      return params
    })

    const requestParams = {
      poll_id: signUpDetail.id,
      list_user_selections_params,
      onSuccessCallback: data => {
        if (data.data.list_user_selections_update.successful) {
          onUpdateSuccess()
        }
      },
      onFailCallback: data => {
        if (!data.data.list_user_selections_update.successful) {
          if (data.data.list_user_selections_update.messages[0] === 'Poll is end') {
            message.info('Sign Up List is End')
          }
        }
      }
    }

    if (!user) {
      requestParams.anonymous_user_id = localStorage.getItem('anonymousId')
    }

    onUpdateSignUpUserSelections(requestParams)
  }

  handleDelete = () => {
    const { onDeleteSignUp, signUpDetail, goBack } = this.props
    onDeleteSignUp({
      id: signUpDetail.id,
      onSuccessCallback: () => {
        message.success('Delete successful')
        goBack()
      }
    })
  }

  handleNoteChange = ({ value, poll_option_id }) => {
    const { signUpOptionList } = this.state
    const index = signUpOptionList.findIndex(item => item.poll_option_id === poll_option_id)
    const signUpOption = signUpOptionList[index]

    signUpOptionList[index] = {
      ...signUpOption,
      note: value
    }

    this.setState({ signUpOptionList })
  }

  handleChangeOption = ({ type, poll_option_id, value }) => {
    const { signUpOptionList } = this.state
    const index = signUpOptionList.findIndex(item => item.poll_option_id === poll_option_id)
    const signUpOption = signUpOptionList[index]
    if (type === 'checked') {
      const changeChecked = !signUpOption.isChecked
      let count = 0
      let available_count = 0

      if (changeChecked) {
        if (signUpOption.initial_count === 0) {
          count = 1
          available_count = signUpOption.initial_available_count - 1
        } else {
          count = signUpOption.initial_count
          available_count = signUpOption.initial_available_count
        }
      } else {
        count = 0
        available_count = signUpOption.available_count + signUpOption.count
      }

      signUpOptionList[index] = {
        ...signUpOption,
        count,
        available_count,
        isChecked: changeChecked
      }
    } else if (type === 'minus') {
      if (signUpOption.count <= 0) {
        return
      }
      const isChecked = !(signUpOption.count - 1 === 0)
      signUpOptionList[index] = {
        ...signUpOption,
        isChecked,
        count: signUpOption.count - 1,
        available_count: signUpOption.available_count + 1
      }
    } else if (type === 'add') {
      if (signUpOption.available_count <= 0) {
        return
      }
      signUpOptionList[index] = {
        ...signUpOption,
        count: signUpOption.count + 1,
        isChecked: true,
        available_count: signUpOption.available_count - 1
      }
    } else if (type === 'note') {
      signUpOptionList[index] = {
        ...signUpOption,
        note: value
      }
    }

    this.setState({ signUpOptionList })
  }

  _validButton = () => {
    const { user, anonymousName, signUpDetail, eventInvitations = [] } = this.props
    // const checkedList = signUpOptionList.filter(item => item.isChecked)
    if (signUpDetail.event_id) {
      if (user) {
        let isExist = eventInvitations?.some(item => item.user?.id === user.id)
        if (!isExist) {
          return { errorText: 'You must be invited to this event in to vote' }
        }
        return true
      }
      return { errorText: 'You must be invited to this event and logged in to vote' }
    }

    if (!user && !anonymousName) {
      return { errorText: 'You must be logged in or enter a name to vote' }
    }

    return true
  }

  validDataChange = () => {
    const { initialSignUpOptionList, signUpOptionList } = this.state
    const hasChanged = JSON.stringify(initialSignUpOptionList) !== JSON.stringify(signUpOptionList)
    return hasChanged
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.anonymousName !== this.props.anonymousName) {
      return
    }

    this.getSignUpList(nextProps.user, nextProps.signUpDetail)
  }

  componentDidMount() {
    this.getSignUpList(this.props.user)
  }

  render() {
    const { signUpOptionList, showToast, errorText } = this.state
    const { user, signUpDetail, renderButton } = this.props
    const isClosed = signUpDetail.status === 'closed'
    const voterNumber = this.calcVoterNumber()

    return (
      <div className={styles['container']}>
        <div className={styles['title-wrapper']}>
          <span className={styles['title']}>Slot/Item</span>
          <div
            className={styles['view-button']}
            onClick={() => this.setState({ isOpenFullModal: true })}
          >
            <img src={unionIcon} width="20px" alt="" />
            <span>All Responses</span>
          </div>
        </div>

        <div className={styles['voters-wrapper']}>
          <span className={styles['number']}>{voterNumber}</span>
          {pluralize(voterNumber, 'Participant')}
        </div>

        <div className={styles['option-wrapper']}>
          <ul className={styles['option-ul']}>
            {signUpOptionList.map((item, index) => (
              <OptionCard
                optionInformation={item}
                onChangeOption={this.handleChangeOption}
                key={index}
              />
            ))}
          </ul>
        </div>

        <footer className={styles['mobile-styles']}>
          {user && renderButton('mobile')}

          <div className={styles['button-wrapper']}>
            {isClosed ? (
              <Button
                className={cn(styles['button-inner'], styles['button-inner-delete'])}
                onClick={this.handleDelete}
              >
                delete
              </Button>
            ) : (
              <Button
                className={cn(styles['button-inner'], {
                  [styles['button-inner-disabled']]: this._validButton() !== true
                })}
                disabled={!this.validDataChange()}
                onClick={this.handleSubmit}
              >
                submit
              </Button>
            )}
          </div>
        </footer>

        <FullList
          optionList={signUpOptionList}
          isFullListVisible={this.state.isOpenFullModal}
          onClose={() => this.setState({ isOpenFullModal: false })}
        />

        {showToast && (
          <div className={styles['toast-container']}>
            <img src={warningIcon} alt="" width="20" />
            <span>{errorText}</span>
          </div>
        )}
      </div>
    )
  }
}

SULOptions.propTypes = {
  signUpDetail: PropTypes.object,
  user: PropTypes.object,
  onUpdateSignUpUserSelections: PropTypes.func,
  anonymousName: PropTypes.string,
  onUpdateSuccess: PropTypes.func,
  onDeleteSignUp: PropTypes.func,
  goBack: PropTypes.func,
  eventInvitations: PropTypes.array,
  renderButton: PropTypes.func
}

function mapStateToProps(state) {
  return {
    user: userSelector(state),
    signUpDetail: signUpDetailSelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onGetSignUpDetails: params => dispatch(fetchSignUp(params)),
    onUpdateSignUpUserSelections: params => dispatch(updateSignUpUserSelections(params)),
    onDeleteSignUp: params => dispatch(deleteSUL(params)),
    goBack: () => dispatch(routerActions.goBack())
  }
}

const SULOptionsWithRef = React.forwardRef((props, ref) => <SULOptions {...props} ref={ref} />)

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  SULOptionsWithRef
)
