import { call, fork, put, select, take } from 'redux-saga/effects'

import {
  eventSelector,
  userIdSelector
} from 'src/selectors'

import { showHud } from 'src/actions/frontend'

import {
  CREATE_REMOTE_XMPP_UPLOAD_SUCCESS,
  CREATE_REMOTE_XMPP_UPLOAD_ERROR,
  REQUEST_XMPP_UPLOAD,
  XMPP_UPLOAD_CALLBACK_SUCCESS,
  XMPP_UPLOAD_CALLBACK_ERROR,
  addTempChatComment,
  createRemoteXmppUpload,
  removeTempChatComment,
  xmppUploadCallback
} from 'src/actions/chat'

import loadImageInfo from 'services/image_loader'
import { uploadToS3ByUrl } from 'src/services/s3_upload_client'
import {
  chatSendImage,
  createTempImageUploadComment
} from 'src/services/chat_helpers'

import { uploadFileToS3Worker } from '../s3'
import { multiPut } from '../utils'

export default function * chatUploadRootSaga () {
  yield fork(watchRequestXmppUploadWatcherSaga)
}

function * watchRequestXmppUploadWatcherSaga () {
  while (true) {
    const action = yield take(REQUEST_XMPP_UPLOAD)
    yield fork(xmppUploadWorker, action.file)
  }
}

function * xmppUploadWorker (file) {
  const mimeType = file.type
  const fileName = file.name
  const event = yield select(eventSelector)
  const userId = yield select(userIdSelector)
  const imageInfo = yield loadImageInfo(file)
  const tempComment = createTempImageUploadComment(imageInfo, userId)

  const onErrorActions = [showHud('error', 'Unable to upload, please try again'), removeTempChatComment(tempComment.id)]
  yield put(addTempChatComment(tempComment))

  yield put(createRemoteXmppUpload(fileName, mimeType, event.id))

  const createRemoteXmppUploadAction = yield take([CREATE_REMOTE_XMPP_UPLOAD_SUCCESS, CREATE_REMOTE_XMPP_UPLOAD_ERROR])
  if (createRemoteXmppUploadAction.type === CREATE_REMOTE_XMPP_UPLOAD_ERROR) {
    yield multiPut(onErrorActions)
    return
  }

  const callbackUrl = createRemoteXmppUploadAction.response.upload.callback_url
  const uploadUrl = createRemoteXmppUploadAction.response.upload.upload_url

  const onProgressActionCreator = null
  const onSuccessActions = []
  // Upload to S3
  const xhrRequest = uploadToS3ByUrl(uploadUrl, file, file.type, callbackUrl)
  yield call(uploadFileToS3Worker, xhrRequest, onProgressActionCreator, onSuccessActions, onErrorActions)

  // Upload callback
  yield put(xmppUploadCallback(callbackUrl))
  const xmppUploadCallbackAction = yield take([XMPP_UPLOAD_CALLBACK_SUCCESS, XMPP_UPLOAD_CALLBACK_ERROR])
  if (xmppUploadCallbackAction.type === XMPP_UPLOAD_CALLBACK_ERROR) {
    yield multiPut(onErrorActions)
    return
  }

  yield put(chatSendImage(tempComment, xmppUploadCallbackAction.response.upload, event.id))
}
