import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { chatSelector, usersSelector } from 'src/selectors'
import MessageInner from './MessageInner/MessageInner'
import SvgIcon from 'src/components/common/SvgIcon'
import LeftChevron from 'src/assets/icons/left_chevron.svg'
// import debounce from 'lodash/debounce'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import { fullName } from 'src/services/user_helpers'
import ThreadDrawer from './ThreadDrawer/ThreadDrawer'

import styles from './view-message.scss'

const ViewMessage = () => {
  const users = useSelector(state => usersSelector(state))
  const { messageList, chatUser } = useSelector(chatSelector)

  const messagesRef = useRef(null)
  const messagesCountRef = useRef(0)
  const chatParams = localStorage.getItem('chatParams')

  useEffect(() => {
    if (messagesRef.current && messageList.length > messagesCountRef.current && !chatParams) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight
    }

    messagesCountRef.current = messageList.length
  }, [messageList.length])

  useEffect(() => {
    if (chatParams && messagesRef.current) {
      const { messageId, threadId } = JSON.parse(chatParams)
      const index = messageList.findIndex(message => message.id === messageId)
      if (!threadId && index > -1) {
        messagesRef.current.children[index].scrollIntoView({ behavior: 'smooth' })
        localStorage.removeItem('chatParams')
      }
    }
  }, [messagesRef.current?.children.length])

  const renderTitle = () => {
    if (chatUser && chatUser !== 'allUsers') {
      return (
        <>
          <UserAvatarContainer user={chatUser} size={34} />
          {fullName(chatUser)}
        </>
      )
    }
    return '# Group Chat'
  }

  return (
    <div className={styles['view-message-container']}>
      <div className={styles['header']}>
        <SvgIcon
          icon={LeftChevron}
          className={styles['left-icon']}
          onClick={() => history.back()}
        />
        <div className={styles['title']}>{renderTitle()}</div>
      </div>

      <div className={styles['message-content']} ref={messagesRef}>
        {messageList.map(message => {
          const user = users[message.user_id]
          return <MessageInner key={message.id} message={message} user={user} />
        })}
      </div>

      <ThreadDrawer />
    </div>
  )
}

export default ViewMessage
