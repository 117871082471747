import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './invitation-reply-form-container.scss'
import TicketAttending from '../details/TicketAttending'

export default class InvitationReplyFormContainer extends Component {
  _renderFormText () {
    const { changeRsvp, guestCountEnabled, hasChangedRsvpStatus } = this.props

    if (changeRsvp) {
      if (!guestCountEnabled || hasChangedRsvpStatus) {
        return <span>Great! You RSVP’d yes</span>
      }
      return <div>Great! Your RSVP is updated</div>
    } else {
      return <div>Great! You RSVP'd yes</div>
    }
  }

  render () {
    const {
      changeRsvp,
      invitation,
      guestCountEnabled,
      hasChangedRsvpStatus,
      showInvitationReplyFormModal,
      invitationReplyForm,
      onClickAttending
    } = this.props
    return (
      <div className={styles['invitation-reply-form-wrapper']}>
        <h3 className={styles['title']}>
          {changeRsvp && !hasChangedRsvpStatus
            ? 'RSVP Changed'
            : `Yes, I'm Going`}
        </h3>
        {this._renderFormText()}
        {invitation && guestCountEnabled ? (
          <TicketAttending
            invitation={invitation}
            className={styles['total-attending']}
            onClick={onClickAttending}
            inReplyForm
          />
        ) : null}
        {!changeRsvp || hasChangedRsvpStatus || showInvitationReplyFormModal
          ? invitationReplyForm
          : null}
      </div>
    )
  }
}

InvitationReplyFormContainer.propTypes = {
  changeRsvp: PropTypes.bool,
  guestCountEnabled: PropTypes.bool,
  invitation: PropTypes.object,
  invitationReplyForm: PropTypes.element,
  hasChangedRsvpStatus: PropTypes.bool,
  showInvitationReplyFormModal: PropTypes.bool,
  onClickAttending: PropTypes.func
}
