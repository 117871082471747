import { combineReducers } from 'redux'

import {
  CHANGE_VISIBLE,
  SET_CONTENT,
  CHANGE_LOADING,
  SET_NEEDED_CREDITS
} from 'src/actions/globalLayer'

const visible = (state = false, action) => {
  switch (action.type) {
    case CHANGE_VISIBLE:
      return action.visible
    default:
      return state
  }
}

const loading = (state = false, action) => {
  switch (action.type) {
    case CHANGE_LOADING:
      return action.loading
    case CHANGE_VISIBLE:
      return action.visible ? state : false
    default:
      return state
  }
}

const content = (state = null, action) => {
  switch (action.type) {
    case SET_CONTENT:
      return action.content
    case CHANGE_VISIBLE:
      return action.visible ? state : null
    default:
      return state
  }
}

const neededCredits = (state = 0, action) => {
  switch (action.type) {
    case SET_NEEDED_CREDITS:
      return action.count
    default:
      return state
  }
}

export default combineReducers({
  visible,
  loading,
  content,
  neededCredits
})
