import { combineReducers } from 'redux'

import { HIDE_TOOLTIP } from 'src/actions/frontend'

import {
  SHOW_MEDIUM_MORE_TOOLTIP,
  HIDE_MEDIUM_MORE_TOOLTIP
} from 'src/actions/media'

const visible = (state = false, action) => {
  switch (action.type) {
    case SHOW_MEDIUM_MORE_TOOLTIP:
      return true
    case HIDE_MEDIUM_MORE_TOOLTIP:
      return false
    case HIDE_TOOLTIP:
      return false
    default:
      return state
  }
}

const mediumId = (state = '', action) => {
  switch (action.type) {
    case SHOW_MEDIUM_MORE_TOOLTIP:
      return action.mediumId
    default:
      return state
  }
}

const clickCoordinates = (state = {}, action) => {
  switch (action.type) {
    case SHOW_MEDIUM_MORE_TOOLTIP:
      return action.clickCoordinates
    case HIDE_MEDIUM_MORE_TOOLTIP:
      return {}
    case HIDE_TOOLTIP:
      return {}
    default:
      return state
  }
}

export default combineReducers({
  visible,
  mediumId,
  clickCoordinates
})
