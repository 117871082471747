import React, { Component } from 'react'
import SearchInput from '../../../../Components/SearchInput/SearchInput'
import styles from './search-panel.scss'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import CheckBoxInput from 'components/forms/CheckboxInput'
import cn from 'classnames'
import RightIcon from 'assets/icons/right-icon.png'

class SearchPanel extends Component {
  state = {
    searchValue: '',
    searchGroupsList: [],
    expandGroupIds: []
  }

  isAllSelected = members => {
    return members.every(item => item.isSelected)
  }

  handleExpand = (group, index) => {
    const { expandGroupIds, searchGroupsList } = this.state

    if (group.isExpand) {
      const index = expandGroupIds.indexOf(group.id)
      expandGroupIds.splice(index, 1)
    } else {
      expandGroupIds.push(group.id)
    }

    searchGroupsList[index].isExpand = !searchGroupsList[index].isExpand

    this.setState({ searchGroupsList, expandGroupIds })
  }

  getGroupsList = () => {
    const { communityList, eventList } = this.props

    return JSON.parse(JSON.stringify([...communityList, ...eventList]))
  }

  searchMembersDebounce = debounce(value => {
    const searchGroupsWithName = this.getGroupsList().filter(({ name }) => {
      return name?.toLowerCase()?.includes(value.toLowerCase())
    })

    const searchGroupsList = searchGroupsWithName.map(item => {
      if (this.state.expandGroupIds.includes(item.id)) {
        item.isExpand = true
      } else {
        item.isExpand = false
      }

      return item
    })

    this.setState({ searchGroupsList })
  }, 300)

  handleInputChange = (value = this.state.searchValue) => {
    this.setState({ searchValue: value })

    if (!value) {
      this.setState({ searchGroupsList: [], expandGroupIds: [] })
      this.props.onDisableScroll(false)
      return
    }
    this.props.onDisableScroll(true)
    this.searchMembersDebounce(value)
  }

  render() {
    const { searchValue, searchGroupsList } = this.state
    const { onMemberChecked, onGroupChecked } = this.props

    return (
      <div className={styles['search-panel-container']}>
        <SearchInput value={searchValue} onChange={this.handleInputChange} />
        {searchValue && (
          <div className={styles['content-container']}>
            {searchGroupsList.map((item, index) => (
              <div className={styles['community-wrapper']} key={item.id}>
                <div className={styles['list-item']}>
                  <img src={item.thumbnail_url} alt="" width="44" className={styles['avatar']} />
                  <div className={styles['content']}>
                    <div className={styles['name']}>
                      <span>{item.name}</span>
                      <img
                        onClick={() => this.handleExpand(item, index)}
                        className={item.isExpand ? styles['group-expand'] : ''}
                        src={RightIcon}
                        width="16"
                      />
                    </div>
                    <div className={styles['text']}>{item.member_count} members</div>
                  </div>
                  <CheckBoxInput
                    size={22}
                    checked={this.isAllSelected(item.members)}
                    onChange={() => onGroupChecked(item.members, this.handleInputChange)}
                  />
                </div>
                {item.isExpand && (
                  <ul className={styles['member-wrapper']}>
                    {item.members.map(member => (
                      <li
                        key={member.key}
                        className={cn(styles['member-item'], {
                          [styles['member-item-added']]: member.isAddedInEvent
                        })}
                      >
                        <UserAvatarContainer
                          user={{ app_user: member.app_user }}
                          url={member.avatar_url}
                          size={34}
                        />
                        <div className={styles['name']}>
                          {`${member.first_name} ${member.last_name}`}
                        </div>
                        <CheckBoxInput
                          size={22}
                          checked={member.isSelected}
                          disabled={member.isAddedInEvent}
                          onChange={() => onMemberChecked(member, this.handleInputChange)}
                        />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}

            {searchGroupsList.length === 0 && (
              <div className={styles['empty-content']}>No Data</div>
            )}
          </div>
        )}
      </div>
    )
  }
}

SearchPanel.propTypes = {
  communityList: PropTypes.array,
  eventList: PropTypes.array,
  onMemberChecked: PropTypes.func,
  onDisableScroll: PropTypes.func,
  onGroupChecked: PropTypes.func
}

export default SearchPanel
