import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { activeMenuPanelSelector } from 'src/selectors'
import ProfilePanel from './ProfilePanel/ProfilePanel'
import ChatPanel from './ChatPanel/ChatPanel'
import LoginPanel from 'components/LoginModal/LoginPanel/LoginPanel'
import MembershipDetail from './MembershipDetail/MembershipDetail'
import ActivityFeedPanel from './ActivityFeedPanel/ActivityFeedPanel'
import { Drawer } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

import styles from './panel-container.scss'

export const PanelSlug = {
  Membership: 'membership',
  Profile: 'profile',
  Chat: 'chat',
  Login: 'login',
  ActivityFeed: 'ActivityFeed'
}

const PanelContainer = ({ isMenuSliderVisible, onResetMenuState }) => {
  const activeMenuPanel = useSelector(activeMenuPanelSelector)

  const renderPanel = () => {
    switch (activeMenuPanel) {
      case PanelSlug.Profile:
        return <ProfilePanel onResetMenuState={onResetMenuState} />
      case PanelSlug.Chat:
        return <ChatPanel />
      case PanelSlug.Login:
        return (
          <div className={styles['login-panel-container']}>
            <LoginPanel />
            <CloseOutlined className={styles['close-icon']} onClick={onResetMenuState} />
          </div>
        )
      case PanelSlug.Membership:
        return <MembershipDetail onResetMenuState={onResetMenuState} />
      case PanelSlug.ActivityFeed:
        return <ActivityFeedPanel onResetMenuState={onResetMenuState} />
      default:
        return null
    }
  }

  return (
    <Drawer
      open={isMenuSliderVisible}
      placement="left"
      closable={false}
      mask={false}
      getContainer={false}
      style={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
      bodyStyle={{ padding: 0 }}
      width={520}
      destroyOnClose
    >
      <main className={styles['panel-container-main']}>{renderPanel()}</main>
    </Drawer>
  )
}

PanelContainer.propTypes = {
  isMenuSliderVisible: PropTypes.bool,
  onResetMenuState: PropTypes.func
}

export default PanelContainer
