import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import EventBusiness from 'assets/event-business.png'
import AnnBusiness from 'assets/ann-business.png'
import TpBusiness from 'assets/tp-business.png'
import SulBusiness from 'assets/sul-business.png'
import styles from './business-type.scss'
import Button from 'components/common/Button'
import HobnobModal from 'components/common/HobnobModalV2/HobnobModal'
import { DOWNLOAD_APP_LINK } from 'src/constants'

const BusinessType = () => {
  const [visibleDownloadModal, setVisibleDownloadModal] = useState(false)
  const userId = useSelector(state => state.userId)

  const updateUrl = url => {
    window.location.href = window.location.origin + url
  }

  const handleClickCreate = type => {
    let types = {
      e: {
        text: 'Create Event Clicked',
        route: '/e/create'
      },
      sul: {
        text: 'Create Sign Up List Clicked',
        route: '/su/create'
      },
      tp: {
        text: 'Create Time Poll Clicked',
        route: '/tp/create'
      }
    }

    const createType = types[type]

    window.mixpanel.track(createType.text, {
      source: 'landing_page',
      user_id: userId
    })
    updateUrl(createType.route)
  }

  return (
    <div className={styles['container']}>
      <div className={styles['card-wrapper-event']} onClick={() => handleClickCreate('e')}>
        <div className={styles['title']}>Events</div>
        <img src={EventBusiness} alt="" />
      </div>
      <div
        className={styles['card-wrapper-ann']}
        onClick={() => {
          setVisibleDownloadModal(true)
          window.mixpanel.track('Create Announcement Clicked', {
            user_id: userId,
            source: 'landing_page'
          })
        }}
      >
        <div className={styles['title']}>Announcements</div>
        <img src={AnnBusiness} alt="" />
      </div>
      <div className={styles['card-wrapper-tp']} onClick={() => handleClickCreate('tp')}>
        <div className={styles['title']}>Time Polls</div>
        <img src={TpBusiness} alt="" />
      </div>
      <div className={styles['card-wrapper-sul']} onClick={() => handleClickCreate('sul')}>
        <div className={styles['title']}>Sign Up Lists</div>
        <img src={SulBusiness} alt="" />
      </div>

      <HobnobModal visible={visibleDownloadModal} onCancel={() => setVisibleDownloadModal(false)}>
        <div className={styles['download-container']}>
          <div>Announcements is not available on Web yet, get Hobnob app to create one!</div>
          <Button
            wrapperElement={'a'}
            href={DOWNLOAD_APP_LINK}
            target="_blank"
            className={styles['button-inner']}
            onClick={() => window.mixpanel.track('GET HOBNOB APP Clicked', { user_id: userId })}
          >
            GET HOBNOB APP
          </Button>
        </div>
      </HobnobModal>
    </div>
  )
}

export default BusinessType
