import React, { useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { createTimePoll } from '../../graphQl/createPoll'

import SvgIcon from 'components/common/SvgIcon'
import labelIcon from '../../assets/icons/label-icon.svg'
import notesIcon from '../../assets/icons/notes-icon.svg'
import locationIcon from '../../assets/icons/location-icon.svg'
import checkIcon from '../../assets/icons/check-icon.svg'
import calendarIcon from '../../assets/icons/calendar-icon.svg'
import rightArrow from 'assets/icons/right-arrow.svg'

import TextInputElement from '../../components/common/text-input/TextInputElement'
import TextAreaElement from '../../components/common/text-input/TextAreaElement'
import DatePicker from '../../components/date-picker/DatePicker'
import CreatePollMobile from './Mobile/CreatePollMobile'
import HobnobModal from '../../components/common/modal/Modal'
import Button from '../../components/common/button/Button'
import Card from '../../components/card/Card'
import OpenAppModal from '../../components/open-app-modal/open-app-modal'
import { changeVisible, setContent } from 'src/actions/globalLayer'
import { UPGRADE_PLAN_SOURCE } from 'src/constants'

import { formatTimePollData } from '../../utils/dates'
import styles from './create-poll.scss'
import cn from 'classnames'

const initialDateRange = {
  dates: {},
  timeList: []
}

const CreatePoll = () => {
  const dispatch = useDispatch()
  const isDesktop = window.innerWidth >= 1080
  const [name, setName] = useState('')
  const [location, setLocation] = useState('')
  const [notes, setNotes] = useState('')
  const [isOpenDateModal, setIsOpenDateModal] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [pollToken, setPollToken] = useState('')
  const [isOpenMobileDateModal, setIsOpenMobileDateModal] = useState(false)
  const [isOpenAppModal, setIsOpenAppModal] = useState(false)
  const [selectedDateRange, setSelectedDateRange] = useState(initialDateRange)

  const reset = () => {
    setIsCreate(false)
    setSelectedDateRange(initialDateRange)
  }

  const handleOpenDateModal = () => {
    if (isDesktop) setIsOpenDateModal(true)
    if (!isDesktop) {
      setIsOpenMobileDateModal(true)
      window.scrollTo(0, 0)
    }
  }

  const timePollList = useMemo(() => {
    if (selectedDateRange.dates) {
      if (Object.keys(selectedDateRange.dates).length > 0) {
        return formatTimePollData(selectedDateRange)
      }
    }

    return []
  }, [selectedDateRange])

  const handleCreate = () => {
    const payload = {
      name: name,
      pollType: 'time',
      status: 'active',
      notes: notes,
      options: timePollList
    }
    try {
      dispatch(
        createTimePoll({
          variables: payload,
          onSuccessCallback({ data }) {
            if (data.poll_create.successful) {
              localStorage.setItem('pollToken', data.poll_create.result.token)
              setPollToken(data.poll_create.result.token)
              reset()
              const path = window.location.href
              const newPath = path.replace(/create/g, `${data.poll_create.result.token}`)
              window.location.href = newPath
            } else {
              dispatch(changeVisible(true))
              dispatch(setContent(UPGRADE_PLAN_SOURCE.getMorePollsAndLists))
            }
          }
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  const redirectToTimePollContainer = () => {
    if (pollToken) {
      const path = window.location.href
      const newPath = path.replace(/create/g, `${pollToken}`)
      return newPath
    }
  }

  const handleSelectedDone = (dateRange = null) => {
    setIsOpenMobileDateModal(false)
    setIsOpenDateModal(false)

    if (dateRange) {
      setSelectedDateRange(dateRange)
      setIsCreate(true)
    }
  }

  if (isOpenMobileDateModal) {
    return (
      <CreatePollMobile
        handleSelectedDone={handleSelectedDone}
        selectedDateRange={selectedDateRange}
      />
    )
  }

  return (
    <div className={styles['create-poll-wrapper']}>
      <h2 className={styles['create-poll-title']}>create time poll</h2>

      <div className={styles['create-poll-input-wrapper']}>
        <SvgIcon icon={labelIcon} style={{ width: 24 }} />
        <TextInputElement
          className={styles['input-inner']}
          placeholder="Poll Name"
          onChange={e => setName(e.target.value)}
          valid={!name}
          value={name}
        />
        {name && <SvgIcon icon={checkIcon} style={{ width: 24 }} />}
      </div>

      <div className={styles['date-list-wrapper']}>
        <div onClick={handleOpenDateModal} className={styles['date-list-title']}>
          <SvgIcon icon={calendarIcon} style={{ width: 22 }} />
          {timePollList.length ? (
            <span className={styles['title-text']}>{timePollList.length} Options</span>
          ) : (
            <span className={styles['title-text']}>select dates</span>
          )}
        </div>

        <div className={styles['date-list']}>
          {timePollList.map((time, index) => (
            <Card key={index} date={time} />
          ))}
        </div>
      </div>

      <h3 className={styles['optional-title']}>optional</h3>

      <div className={styles['create-poll-input-wrapper']}>
        <SvgIcon icon={locationIcon} style={{ width: 24 }} />
        <TextInputElement
          className={cn(styles['input-inner'])}
          onChange={e => setLocation(e.target.value)}
          placeholder="Location"
          value={location}
        />
        <SvgIcon icon={rightArrow} style={{ width: 8, height: 15 }} />
      </div>

      <div
        className={cn(styles['create-poll-textArea-wrapper'], styles['create-poll-input-wrapper'])}
      >
        <SvgIcon icon={notesIcon} style={{ width: 24 }} />
        <TextAreaElement
          className={styles['textarea-inner']}
          placeholder="Notes"
          onChange={e => setNotes(e.target.value)}
          valid={!notes}
          value={notes}
        />
      </div>

      <a className={styles['create-poll-create-button']} href={redirectToTimePollContainer()}>
        <Button className={styles['button-inner']} onClick={handleCreate} disabled={!isCreate}>
          Create
        </Button>
      </a>

      <HobnobModal isOpen={isOpenDateModal} onClose={() => setIsOpenDateModal(false)}>
        <DatePicker handleSelectedDone={handleSelectedDone} selectedDateRange={selectedDateRange} />
      </HobnobModal>

      <HobnobModal
        modalClassName={styles['open-app-modal']}
        isOpen={isOpenAppModal}
        onClose={() => setIsOpenAppModal(false)}
      >
        <OpenAppModal onClose={() => setIsOpenAppModal(false)} />
      </HobnobModal>
    </div>
  )
}

export default CreatePoll
