import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'

import { visibleMedium } from 'services/media_helpers'

import { requireLogin } from 'src/actions/login'

import { createMedium, requestFetchMoreMedia } from 'src/actions/media'

import FloatingButtonSpacer from 'components/common/FloatingButtonSpacer'
import FloatingButtonContainer from 'components/common/FloatingButtonContainer'
import EmptySection from 'components/common/EmptySection'
import LikersModalContainer from 'components/modals/LikersModalContainer'
import MediaListContainer from 'components/media/MediaListContainer'
import PhotoUpload from 'components/media/PhotoUpload'
import MediumShareTooltipModalContainer from 'components/media/MediumShareTooltipModalContainer'
import MediumMoreTooltipModalContainer from 'components/media/MediumMoreTooltipModalContainer'
import HobnobNavBar from 'components/common/HobnobNavBar'
import SvgIcon from 'src/components/common/SvgIcon'
import Button from 'components/common/Button'
import InlineLoadingIndicator from 'components/common/InlineLoadingIndicator'

import MediaEmptyState from 'src/assets/photos_empty_state.jpg'
import PlusRoundIcon from 'src/assets/icons/plus_round_icon.svg'

import {
  eventSelector,
  isLoggedInSelector,
  isSubscribedSelector,
  mediaLoadingStatusSelector,
  mediaSelector
} from 'src/selectors'

import styles from './media-page.scss'

class MediaPage extends React.Component {
  _loadMedia = () => {
    // Check that this tab is visible: http://stackoverflow.com/a/21696585/175830
    if (
      (this._containerRef && this._containerRef.offsetParent) ||
      this.props.mediaLoadingStatus === 'loading'
    ) {
      this.props.dispatch(requestFetchMoreMedia())
    }
  }

  _saveContainerRef = ref => {
    this._containerRef = ref
  }

  _renderEmptyMediaGallery() {
    const { dispatch, isLoggedIn, isSubscribed } = this.props

    const onCreateMedium = file => dispatch(createMedium(file))
    const onRequireLogin = () => {
      const loginModalTitle = 'Sign In'
      const loginModalSubtitle =
        'To add photos, enter your number to confirm you are on the guest list'
      const loginModalFirstPageSubtitle = 'You need to login in order to upload a photo'
      const loginMessage = "To upload photos, please confirm you're on the guest list"
      const subscribeMessage = 'To continue, add yourself to the guest list'
      dispatch(
        requireLogin({
          loginMessage,
          subscribeMessage,
          loginModalTitle,
          loginModalFirstPageSubtitle,
          loginModalSubtitle
        })
      )
    }

    return (
      <EmptySection
        header="No Photos Yet"
        description="Be the first to add photos from the event!"
        imageUrl={MediaEmptyState}
        className={styles['media-empty-icon']}
      >
        <PhotoUpload
          onCreateMedium={onCreateMedium}
          onRequireLogin={onRequireLogin}
          requiresLogin={!isLoggedIn || !isSubscribed}
        >
          <Button>Add photos</Button>
        </PhotoUpload>
      </EmptySection>
    )
  }

  _renderGallery() {
    const { mediaLoadingStatus } = this.props
    return (
      <div className={styles['gallery']} ref={this._saveContainerRef}>
        <InfiniteScroll
          pageStart={0}
          loadMore={this._loadMedia}
          hasMore={mediaLoadingStatus === 'loading'}
          loader={<InlineLoadingIndicator key={0} loading />}
          useWindow={false}
          threshold={750}
        >
          <MediaListContainer />
        </InfiniteScroll>
      </div>
    )
  }

  render() {
    const { event, dispatch, hasMedia, mediaLoadingStatus, isLoggedIn, isSubscribed } = this.props

    const onCreateMedium = file => dispatch(createMedium(file))
    const onRequireLogin = () => {
      const loginModalTitle = 'Sign In'
      const loginModalSubtitle =
        'To add photos, enter your number to confirm you are on the guest list'
      const loginModalFirstPageSubtitle = 'You need to login in order to upload a photo'
      const loginMessage = "To upload photos, please confirm you're on the guest list"
      const subscribeMessage = 'To continue, add yourself to the guest list'
      dispatch(
        requireLogin({
          loginMessage,
          subscribeMessage,
          loginModalTitle,
          loginModalFirstPageSubtitle,
          loginModalSubtitle
        })
      )
    }

    return (
      <div className={styles['media-container']}>
        {/* <div className={styles['banner-container']}>
          <a
            href={downloadUrl}
            className={styles['icon']}
        >
            {this._renderAppIcon()}
          </a>
          <div className={styles['text']}>
            <b>Download Hobnob app</b>
            <span>to share event videos</span>
          </div>
          <Button
            wrapperElement={'a'}
            className={styles['download-btn']}
            href={downloadUrl}>
            GET APP
          </Button>
        </div> */}
        <HobnobNavBar title="Media Album" subTitle={event.name} backPathName="details">
          <PhotoUpload
            style={{ padding: 0 }}
            onCreateMedium={onCreateMedium}
            onRequireLogin={onRequireLogin}
            requiresLogin={!isLoggedIn || !isSubscribed}
          >
            <SvgIcon icon={PlusRoundIcon} className={styles['plus-round-icon']} />
          </PhotoUpload>
        </HobnobNavBar>
        <div className={styles['container']}>
          {hasMedia ? this._renderGallery() : this._renderEmptyMediaGallery()}
          {mediaLoadingStatus === 'error' ? 'Unable to load media, please refresh the page' : null}
          <FloatingButtonSpacer />
          <FloatingButtonContainer pageName="Photos Page" />
          <LikersModalContainer />
          <MediumShareTooltipModalContainer />
          <MediumMoreTooltipModalContainer />
        </div>
      </div>
    )
  }
}

MediaPage.propTypes = {
  event: PropTypes.object,
  dispatch: PropTypes.func,
  hasMedia: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isSubscribed: PropTypes.bool,
  mediaLoadingStatus: PropTypes.string
}

function mapStateToProps(state) {
  const media = mediaSelector(state)
  const mediaLoadingStatus = mediaLoadingStatusSelector(state)
  const hasMedia =
    Object.values(media).filter(visibleMedium).length > 0 || mediaLoadingStatus !== 'success'

  return {
    event: eventSelector(state),
    hasMedia,
    isLoggedIn: isLoggedInSelector(state),
    isSubscribed: isSubscribedSelector(state),
    mediaLoadingStatus,

    // Need to add numMedia to the props so that one of the props changes+}
    // directly after a successful call to _loadMedia+}
    // otherwise MediaPage doesn't update and the scrollhandler for+}
    // InfiniteScroll does not get re-attached+}
    numMedia: Object.values(media).length
  }
}

export default connect(mapStateToProps)(MediaPage)
