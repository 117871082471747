import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Table, Tooltip } from 'antd'
import EditIcon from 'assets/icons/table-edit.png'
import RemoveIcon from 'assets/icons/table-remove.png'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import moment from 'moment'
import { usersSelector } from 'src/selectors'
import styles from './guest-table.scss'
import CohostIcon from 'assets/icons/ico-cohost.png'
import { deepCopyObject, isEmptyString } from 'services/utils'

const GuestTable = ({ event, isHostOrCohost, isHost, tableList, onClickOption }) => {
  const users = useSelector(usersSelector)

  const getUser = record => {
    const { first_name, last_name, guest, display_name } = record

    const hobnobUser = deepCopyObject(users[guest])
    const isHost = event.host === guest

    const user = hobnobUser || record

    if (isHost && !isEmptyString(event.custom_host_name)) {
      user.displayName = event.custom_host_name
    } else if (user.verified) {
      user.displayName = `${user.first_name || ''} ${user.last_name || ''}`
    } else if (!isEmptyString(first_name + last_name)) {
      user.displayName = `${first_name || ''} ${last_name || ''}`
    } else {
      user.displayName = display_name
    }

    return user
  }

  const renderTableTextWithName = record => {
    const user = getUser(record)

    return (
      <div className={styles['table-name-wrapper']}>
        {record.cohost && <img src={CohostIcon} width="14" title="cohost" />}
        <UserAvatarContainer user={user} url={user.avatar_url} size={20} />
        <div className={styles['table-text']}>
          <Tooltip title={user.displayName}>{user.displayName}</Tooltip>
        </div>
      </div>
    )
  }

  const renderTableTextWithEmail = record => {
    if (record.contact_method_display_name?.includes('@')) {
      let str = record.contact_method_display_name

      return <Tooltip title={str}>{str}</Tooltip>
    }
  }

  const renderTableTextWithPhone = record => {
    if (!record.contact_method_display_name?.includes('@')) {
      let str = record.contact_method_display_name
      return <Tooltip title={str}>{str}</Tooltip>
    }
  }

  const renderTableTextWithStatus = record => {
    const { guest_count, child_count, rsvp_state, delivery_state, viewed_at, delivered_at } = record

    if (event.host === record.guest) return

    if (rsvp_state === 'accepted') {
      return (
        <div className={styles['accepted-text']}>
          <div className={styles['text']}>Yes</div>
          <div className={styles['guest-text']}>
            {guest_count > 0 && (
              <div className={styles['guest-count']}>
                {guest_count} Adult{guest_count > 1 ? 's' : ''}
              </div>
            )}
            {child_count > 0 && (
              <div className={styles['child-count']}>
                {child_count} {child_count > 1 ? 'Children' : 'Child'}
              </div>
            )}
          </div>
        </div>
      )
    } else if (rsvp_state === 'declined') {
      return <div className={styles['declined-text']}>No</div>
    } else if (rsvp_state === 'undecided' && delivery_state === 'delivered') {
      return (
        <div className={styles['delivered-text']}>
          <div className={styles['text']}>Delivered</div>
          <div>{moment(delivered_at).format('MM.DD.YYYY')}</div>
        </div>
      )
    } else if (rsvp_state === 'undecided' && delivery_state === 'viewed') {
      return (
        <div className={styles['delivered-text']}>
          <div className={styles['text']}>Viewed</div>
          <div>{moment(viewed_at).format('MM.DD.YYYY')}</div>
        </div>
      )
    } else if (delivery_state === 'delivery_failed' || delivery_state === 'delivery_aborted') {
      return <div className={styles['declined-text']}>Failed</div>
    } else if (delivery_state === 'queued') {
      return (
        <div className={styles['delivered-text']}>
          <div className={styles['text']}>Sending</div>
        </div>
      )
    } else if (delivery_state === 'pending') {
      return (
        <div className={styles['delivered-text']}>
          <div className={styles['unsent']}>Unsent</div>
        </div>
      )
    }
  }

  const visibleEditButton = guestInfo => {
    const user = users[guestInfo?.guest]

    const isCanEdit =
      guestInfo?.delivery_state === 'delivered' || guestInfo?.delivery_state === 'viewed'
    if ((isHost && isCanEdit) || !user?.verified) {
      return true
    }

    return false
  }

  const renderTableTextWithAction = record => {
    if (record.delivery_state !== 'queued' && event.host !== record.guest) {
      return (
        <div className={styles['action-wrapper']}>
          {visibleEditButton(record) && (
            <img src={EditIcon} onClick={() => onClickOption('edit', record)} width="20" />
          )}
          <img
            src={RemoveIcon}
            onClick={() =>
              onClickOption('remove', {
                ...record,
                displayName: getUser(record).displayName
              })
            }
            width="20"
          />
        </div>
      )
    }
  }

  const renderTableHeader = () => {
    if (isHostOrCohost) {
      return [
        {
          title: 'Full Name',
          ellipsis: { showTitle: false },
          render: (_, record) => renderTableTextWithName(record)
        },
        {
          title: 'Email',
          ellipsis: { showTitle: false },
          render: (_, record) => renderTableTextWithEmail(record)
        },
        {
          title: 'Phone',
          ellipsis: { showTitle: false },
          render: (_, record) => renderTableTextWithPhone(record)
        },
        {
          title: 'Status',
          ellipsis: { showTitle: false },
          className: styles['status-column'],
          render: (_, record) => renderTableTextWithStatus(record)
        },
        {
          title: 'Actions',
          width: 100,
          render: (_, record) => renderTableTextWithAction(record)
        }
      ]
    } else {
      return [
        {
          title: 'Full Name',
          ellipsis: { showTitle: false },
          render: (_, record) => renderTableTextWithName(record)
        },
        {
          title: 'Status',
          ellipsis: { showTitle: false },
          render: (_, record) => renderTableTextWithStatus(record)
        }
      ]
    }
  }

  const handleRowClassName = record => {
    if (record.delivery_state === 'delivery_failed') {
      return styles['row-error']
    }

    if (record.rsvp_state === 'undecided' && !isHostOrCohost) {
      return styles['row-undecided']
    }

    if (record.delivery_state === 'queued') {
      return styles['row-disabled']
    }
  }

  const sortGuestsList = useCallback(() => {
    const sortedGuests = tableList.sort((a, b) => {
      const isHost_A = event.host === a.guest
      const isHost_B = event.host === b.guest

      const isCohost_A = a.cohost
      const isCohost_B = b.cohost

      const isAccepted_A = a.rsvp_state === 'accepted'
      const isAccepted_B = b.rsvp_state === 'accepted'

      const isUndecided_A = a.rsvp_state === 'undecided'
      const isUndecided_B = b.rsvp_state === 'undecided'

      const isDeclined_A = a.rsvp_state === 'declined'
      const isDeclined_B = b.rsvp_state === 'declined'

      const displayName_A = getUser(a).displayName
      const displayNameB = getUser(b).displayName

      if (isHost_A === isHost_B) {
        if (isCohost_A === isCohost_B) {
          if (isAccepted_A === isAccepted_B) {
            if (isUndecided_A === isUndecided_B) {
              if (isDeclined_A === isDeclined_B) {
                return displayName_A.localeCompare(displayNameB)
              } else {
                return isDeclined_A ? -1 : 1
              }
            } else {
              return isUndecided_A ? -1 : 1
            }
          } else {
            return isAccepted_A ? -1 : 1
          }
        } else {
          return isCohost_A ? -1 : 1
        }
      } else {
        return isHost_A ? -1 : 1
      }
    })

    return sortedGuests
  }, [tableList])

  return (
    <div className={styles['table-container']}>
      <Table
        rowKey={record => record.id}
        className={styles['table-wrapper']}
        rowClassName={record => handleRowClassName(record)}
        bordered
        columns={renderTableHeader()}
        dataSource={sortGuestsList()}
        pagination={false}
      />
    </div>
  )
}

GuestTable.propTypes = {
  isHostOrCohost: PropTypes.bool,
  isHost: PropTypes.bool,
  event: PropTypes.object,
  tableList: PropTypes.array,
  onClickOption: PropTypes.func
}

export default GuestTable
