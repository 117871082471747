import React from 'react'
import PropTypes from 'prop-types'

const GifMessage = props => {
  const { gifContent } = props

  const height = 210 / (gifContent.metadata.width / gifContent.metadata.height)

  return (
    <div style={{ height: height + 'px' }}>
      <img src={gifContent.metadata.url_large} width={210} alt="" />
    </div>
  )
}

GifMessage.propTypes = {
  gifContent: PropTypes.object.isRequired
}

export default GifMessage
