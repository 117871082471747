import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CheckBoxInput from 'components/forms/CheckboxInput'
import addIconChecked from 'assets/icons/add-icon-checked.png'
import minusIconChecked from 'assets/icons/minus-icon-checked.png'
import cn from 'classnames'
import RightIcon from 'assets/icons/right-icon.png'
import { Input, Tooltip } from 'antd'

import styles from './option-card.scss'

class OptionCard extends Component {
  render() {
    const { optionInformation, onChangeOption, customStyle, onHandleDetail } = this.props

    const totalCount = optionInformation.count + optionInformation.available_count

    return (
      <li
        className={cn(styles['sign-up-card-wrapper'], {
          [styles['sign-up-card-disabled']]: totalCount === 0,
          [styles['sign-up-card-checked']]: optionInformation.isChecked
        })}
        style={customStyle}
      >
        <div className={styles['card-top']}>
          <CheckBoxInput
            className={styles['check-box-button']}
            checked={optionInformation.isChecked}
            disabled={totalCount === 0}
            onChange={() =>
              onChangeOption({ type: 'checked', poll_option_id: optionInformation.poll_option_id })
            }
          />
          <div className={styles['card-info']}>
            <div className={styles['card-name']}>{optionInformation.item}</div>
            {optionInformation.description && (
              <Tooltip placement="topLeft" title={optionInformation.description}>
                <div className={styles['card-description']}>{optionInformation.description}</div>
              </Tooltip>
            )}
          </div>
          {onHandleDetail && (
            <img
              src={RightIcon}
              className={styles['right-icon']}
              onClick={onHandleDetail}
              width="20"
            />
          )}
        </div>
        {optionInformation.count_limit === 9999 ? (
          <div className={styles['unlimited-text']}>unlimited</div>
        ) : (
          <div className={styles['card-bottom']}>
            <span>How many?</span>
            {totalCount === 0 ? (
              <span className={styles['completed-text']}>Completed</span>
            ) : (
              <div className={styles['button-wrapper']}>
                <div
                  className={styles['icon-wrapper']}
                  onClick={() =>
                    onChangeOption({
                      type: 'minus',
                      poll_option_id: optionInformation.poll_option_id
                    })
                  }
                >
                  <img src={minusIconChecked} width="10" />
                </div>
                <div className={styles['count-wrapper']}>
                  {optionInformation.count}&nbsp;of&nbsp;
                  {optionInformation.count + optionInformation.available_count}
                </div>
                <div
                  className={styles['icon-wrapper']}
                  onClick={() =>
                    onChangeOption({
                      type: 'add',
                      poll_option_id: optionInformation.poll_option_id
                    })
                  }
                >
                  <img src={addIconChecked} width="10" />
                </div>
              </div>
            )}
          </div>
        )}
        {optionInformation.isChecked && (
          <Input.TextArea
            autoSize={{ minRows: 1, maxRows: 2 }}
            maxLength={255}
            size="middle"
            bordered={false}
            placeholder="Add a note..."
            onChange={e =>
              onChangeOption({
                type: 'note',
                value: e.target.value,
                poll_option_id: optionInformation.poll_option_id
              })
            }
            style={{
              backgroundColor: '#f9f9f9',
              color: '#535353',
              padding: '10px',
              marginTop: 10,
              marginLeft: 45,
              width: 'inherit'
            }}
            value={optionInformation.note}
          />
        )}
      </li>
    )
  }
}

OptionCard.propTypes = {
  optionInformation: PropTypes.object,
  onChangeOption: PropTypes.func,
  customStyle: PropTypes.object,
  onHandleDetail: PropTypes.func
}

export default OptionCard
