import React, { useState, useRef } from 'react'
import ActivityFeedPanel from 'src/components/MenuBar/panels/ActivityFeedPanel/ActivityFeedPanel'
import HeaderBar from 'src/components/HeaderBar/HeaderBar'
import { ClearOutlined } from '@ant-design/icons'
import ActionSheet from 'components/common/ActionSheet/ActionSheet'

import styles from './activity-feed.scss'

const ActivityFeed = () => {
  const [isActionSheetVisible, setIsActionSheetVisible] = useState(false)
  const activityFeedPanelRef = useRef(null)

  const renderRightIcon = () => {
    return <ClearOutlined style={{ fontSize: 20 }} onClick={() => setIsActionSheetVisible(true)} />
  }

  return (
    <div className={styles['activity-feed-container']}>
      <HeaderBar menuIcon right={renderRightIcon()} />
      <ActivityFeedPanel ref={activityFeedPanelRef} />
      <ActionSheet visible={isActionSheetVisible} onClose={() => setIsActionSheetVisible(false)}>
        <ul>
          <li
            onClick={() => {
              activityFeedPanelRef.current.onClearRead()
              setIsActionSheetVisible(false)
            }}
          >
            Mark All as Read
          </li>
        </ul>
      </ActionSheet>
    </div>
  )
}

export default ActivityFeed
