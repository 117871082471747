/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react'

import HobnobModal from 'components/common/HobnobModalV2/HobnobModal'
import { Calendar } from 'react-date-range'
import Picker from './picker'
import Button from 'components/common/Button'
import PropTypes from 'prop-types'
import moment from 'moment'

import styles from './date-selector.scss'

const optionGroups = {
  hour: ['12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
  minute: ['00', '15', '30', '45'],
  type: ['AM', 'PM']
}

const initialSelectedTime = {
  hour: '12',
  minute: '00',
  type: 'AM'
}

class DateSelector extends Component {
  state = {
    selectedDate: new Date(),
    showTimePicker: false,
    selectTime: initialSelectedTime,
    selectedTime: initialSelectedTime
  }

  handleSave = () => {
    this.setState({ showTimePicker: false, selectedTime: this.state.selectTime })
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible && this.props.initialInputTime) {
      const momentDate = moment(this.props.initialInputTime)
      const selectedTime = {
        hour: momentDate.format('h'),
        minute: momentDate.format('mm'),
        type: momentDate.format('A')
      }

      // TODO: Compatible with IOS, the minute interval is 1, and the minute interval in web is 15.
      if (optionGroups.minute.indexOf(String(selectedTime.minute)) < 0) {
        selectedTime.minute = '00'
      }

      this.setState({ selectedDate: momentDate.toDate(), selectedTime })
    }
  }

  render() {
    const { selectedDate, selectTime, selectedTime } = this.state
    const { visible, onCancel } = this.props
    return (
      <>
        <HobnobModal visible={visible} onCancel={onCancel} maskClosable={false}>
          <div className={styles['date-selector-container']}>
            <div
              className={styles['time-wrapper']}
              onClick={() => this.setState({ showTimePicker: true, selectTime: selectedTime })}
            >
              {`${selectedTime.hour}:${selectedTime.minute} ${selectedTime.type}`}
            </div>
            <Calendar
              date={selectedDate}
              onChange={date => this.setState({ selectedDate: date })}
            />

            <div style={{ padding: '0 10px' }}>
              <Button onClick={() => this.props.onSave(selectedDate, selectedTime)}>save</Button>
            </div>
          </div>
        </HobnobModal>

        <HobnobModal
          visible={this.state.showTimePicker}
          onCancel={() => this.setState({ showTimePicker: false })}
        >
          <div>
            <Picker
              onChange={(name, value) =>
                this.setState({
                  selectTime: { ...selectTime, [name]: value }
                })
              }
              optionGroups={optionGroups}
              valueGroups={selectTime}
              customStyle={{ width: '300px' }}
            />
            <div style={{ padding: '0 10px' }}>
              <Button onClick={this.handleSave}>save</Button>
            </div>
          </div>
        </HobnobModal>
      </>
    )
  }
}

DateSelector.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  initialInputTime: PropTypes.string
}

export default DateSelector
