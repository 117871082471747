import dateformat from 'dateformat'

const hoursFormat = 'h:MMTT'
const dayFormat = `dddd, mmm d, ${hoursFormat}`

export function renderDay (date) {
  return dateformat(date, dayFormat)
}

export function renderTime (date) {
  return dateformat(date, hoursFormat)
}

export function renderTimezoneAbbreviation (timezone) {
  switch (timezone) {
    case 'Pacific/Honolulu':
      return 'HST'
    case 'America/Juneau':
      return 'AKST'
    case 'America/Los_Angeles':
      return 'PST'
    case 'America/Phoenix':
      return 'MST'
    case 'America/Denver':
      return 'MST'
    case 'America/Chicago':
      return 'CST'
    case 'America/New_York':
      return 'EST'
    case 'America/Indiana/Indianapolis':
      return 'EST'
    case 'America/Puerto_Rico':
      return 'AST'
    case 'America/Halifax':
      return 'AST'
    case 'America/Blanc-Sablon':
      return 'AST'
    case 'America/St_Johns':
      return 'NST'
    default:
      console.error(`Warning: Unable to determine correct timezone abbreviation for: ${timezone}`)
      return ''
  }
}
