import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import {
  PlanType,
  storeUTMDataByKey,
  buildCheckoutURLForPlans,
  getPlanTypeBySlug,
  BillPeriod
} from 'src/services/stripe_checkout_configs'
import GreenCrown from 'src/assets/icons/crown-ico-green.svg'
import PurpleCrown from 'src/assets/icons/crown-ico-purple.svg'
import BlueCrown from 'src/assets/icons/crown-ico-blue.svg'
import OrangeCrown from 'src/assets/icons/crown-ico-orange.svg'
import SvgIcon from 'src/components/common/SvgIcon'
import { Segmented } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { userSelector } from 'src/selectors'
import Button from 'src/components/common/Button'
import PlanDescription from './PlanDescription/PlanDescription'
import { setOpenedTabReference } from 'src/actions/stripeCheckout'
import { changeVisible, setContent } from 'src/actions/globalLayer'
import { GLOBAL_LAYER_CONTENT } from 'src/constants'
import uuid from 'uuid'
import cn from 'classnames'

import styles from './plan-inner.scss'

const planTypeParams = {
  [PlanType.InvitationMaker]: {
    title: 'Invitation Maker',
    crownIcon: BlueCrown,
    themeColor: styles['invitation-maker-theme-color']
  },
  [PlanType.PartyHost]: {
    title: 'Host',
    crownIcon: GreenCrown,
    themeColor: styles['party-host-theme-color']
  },
  [PlanType.EventPlanner]: {
    title: 'Planner',
    crownIcon: PurpleCrown,
    themeColor: styles['event-planner-theme-color']
  },
  [PlanType.GroupOrganizer]: {
    title: 'Organizer',
    crownIcon: OrangeCrown,
    themeColor: styles['group-organizer-theme-color']
  }
}

const planPeriodOptions = [
  {
    label: <span>Month-to-Month</span>,
    value: BillPeriod.Monthly
  },
  {
    label: <span>Annual</span>,
    value: BillPeriod.Annually
  }
]

const PlanInner = forwardRef(({ plan, selectedPlanPeriod, onSetSelectedPlanPeriod }, ref) => {
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const userPlanType = getPlanTypeBySlug(user.current_subscription.plan.slug)
  const userPlanPeriod = user.current_subscription.plan.subscription_period

  const planInfo = plan[0]
  const planParams = planTypeParams[planInfo.planType]
  const currentSelectedPlan = plan.find(p => p.billPeriod === selectedPlanPeriod)
  const checkoutInfo = currentSelectedPlan.checkoutInfo

  const openStripeCheckoutTab = () => {
    const utmCode = uuid.v4()
    storeUTMDataByKey(user.current_subscription, utmCode)
    const href = buildCheckoutURLForPlans(
      currentSelectedPlan,
      user.is_trial_available,
      user.id,
      utmCode
    )
    const newTabRef = window.open(href, '_blank')
    dispatch(setOpenedTabReference(newTabRef))
  }

  const handleClickSubscribe = () => {
    openStripeCheckoutTab()
    dispatch(setContent(GLOBAL_LAYER_CONTENT.pendingPaymentResult))
    dispatch(changeVisible(true))
  }

  const renderSubscribeButton = () => {
    if (user) {
      const isDisabled = userPlanType === planInfo.planType && userPlanPeriod === selectedPlanPeriod
      const backgroundColor = isDisabled ? '#d3d3d3' : planParams.themeColor
      const borderColor = isDisabled ? '#d3d3d3' : planParams.themeColor

      return (
        <Button
          style={{ backgroundColor, borderColor }}
          className={cn(styles['subscribe-button'])}
          disabled={isDisabled}
          onClick={handleClickSubscribe}
        >
          {isDisabled
            ? 'YOU ARE ON THIS PLAN'
            : user.is_trial_available && planInfo.planType !== PlanType.InvitationMaker
            ? 'REDEEM YOUR FREE WEEK'
            : 'SUBSCRIBE'}
        </Button>
      )
    }
  }

  return (
    <li className={styles['plan-inner-container']} ref={ref}>
      <h1 className={styles['plan-type-name']}>
        <SvgIcon icon={planParams.crownIcon} width={24} height={24} />
        {planParams.title}
      </h1>

      <div className={styles['price']}>
        <div className={styles['number-wrapper']}>
          <span className={styles['number']}>{checkoutInfo.price}</span>
          <span className={styles['unit']}>/mo</span>
        </div>
        {checkoutInfo.discount ? (
          <>
            <div className={styles['discount']}>SAVE {checkoutInfo.discount}</div>
            <div style={{ color: planParams.themeColor }} className={styles['price-desc']}>
              {checkoutInfo.priceDesc}
            </div>
          </>
        ) : (
          <div className={styles['price-desc']} />
        )}

        <Segmented
          value={selectedPlanPeriod}
          onChange={value => onSetSelectedPlanPeriod(value)}
          className={styles['segmented-wrapper']}
          block
          options={planPeriodOptions}
        />
      </div>

      {renderSubscribeButton()}
      <PlanDescription planType={planInfo.planType} selectedPlanPeriod={selectedPlanPeriod} />
    </li>
  )
})

PlanInner.propTypes = {
  plan: PropTypes.array.isRequired,
  selectedPlanPeriod: PropTypes.string,
  onSetSelectedPlanPeriod: PropTypes.func
}

export default PlanInner
