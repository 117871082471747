import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Tooltip } from 'antd'
import TextInputElement from 'components/forms/TextInputElement'
import SearchIcon from 'assets/icons/search_icon.svg'
import SvgIcon from 'components/common/SvgIcon'
import EditIcon from 'assets/icons/table-edit.png'
import EmptyIcon from 'assets/icons/table-empty.png'
import RemoveIcon from 'assets/icons/table-remove.png'
import EditModal from './EditModal/EditModal'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import styles from './add-guests-list.scss'
import PopConfirm from 'components/common/PopConfirm/PopConfirm'
import compWithAddGuestEditModal from '../../HOC/WithAddGuestEditModal'
import { isEmptyString } from 'services/utils'
import parsePhoneNumber from 'libphonenumber-js'

const EditModalHOC = compWithAddGuestEditModal(EditModal)

class GuestTable extends Component {
  state = {
    isOpenRemoveModal: false
  }

  editModalRef = React.createRef()

  renderTableTextWithName = record => {
    const { avatar_url, first_name, last_name, display_name } = record

    let displayName = ''

    if (!isEmptyString(first_name + last_name)) {
      displayName = `${first_name || ''} ${last_name || ''}`
    } else {
      displayName = display_name
    }

    return (
      <div className={styles['table-name-wrapper']}>
        <UserAvatarContainer user={record} url={avatar_url} size={20} />
        <div className={styles['table-text']}>
          <Tooltip title={displayName}>{displayName}</Tooltip>
        </div>
      </div>
    )
  }

  renderTableTextWithEmail = record => {
    const { contact_method_type, contact_method_display_name } = record
    if (contact_method_type === 'EmailContactMethod') {
      return <Tooltip title={contact_method_display_name}>{contact_method_display_name}</Tooltip>
    }
  }

  renderTableTextWithPhone = record => {
    const { contact_method_type, contact_method_display_name } = record
    if (contact_method_type === 'PhoneNumberContactMethod') {
      let str = contact_method_display_name

      if (contact_method_display_name?.startsWith('1')) {
        const phoneNumber = parsePhoneNumber(contact_method_display_name, 'US')
        str = phoneNumber.formatNational()
      }
      return <Tooltip title={str}>{str}</Tooltip>
    }
  }

  renderTableTextWithAction = record => {
    const { onRemoveGuest } = this.props
    return (
      <div className={styles['action-wrapper']}>
        {!record.verified && (
          <img src={EditIcon} onClick={() => this.editModalRef.current.openModal(record)} />
        )}
        <img src={RemoveIcon} onClick={() => onRemoveGuest(record)} />
      </div>
    )
  }

  renderTableHeader = () => {
    const { addGuestsList } = this.props
    const columns = [
      {
        title: `Full Name(${addGuestsList.length})`,
        ellipsis: { showTitle: false },
        render: (_, record) => this.renderTableTextWithName(record)
      },
      {
        title: 'Email',
        ellipsis: { showTitle: false },
        render: (_, record) => this.renderTableTextWithEmail(record)
      },
      {
        title: 'Phone',
        ellipsis: { showTitle: false },
        render: (_, record) => this.renderTableTextWithPhone(record)
      },
      {
        title: 'Actions',
        width: 100,
        render: (_, record) => this.renderTableTextWithAction(record)
      }
    ]
    return columns
  }

  renderRemoveAllButton = () => {
    const { addGuestsList, searchValue } = this.props
    if (addGuestsList.length > 0 && !searchValue) {
      return (
        <div
          className={styles['remove-all-button']}
          onClick={() => this.setState({ isOpenRemoveModal: true })}
        >
          remove all
        </div>
      )
    }
  }

  renderEmpTyPanel = () => {
    return (
      <div className={styles['table-wrapper-empty']}>
        <div className={styles['empty-title']}>Your Guest List is Empty...</div>
        <div className={styles['empty-subtitle']}>
          <p>You can add guests by entering an email or phone number in the form field above.</p>
          <p>To invite guests from your phone contacts, download the Hobnob app</p>
        </div>
        <img src={EmptyIcon} alt="" width="90" />
      </div>
    )
  }

  handleRemoveAllGuests = () => {
    this.props.onRemoveAllGuests()
    this.setState({ isOpenRemoveModal: false })
  }

  render() {
    const { addGuestsList, searchValue, searchGuestsList, onInputChange } = this.props
    const renderGuestsList = searchValue ? searchGuestsList : addGuestsList
    const isEmpty = addGuestsList.length === 0

    return (
      <div className={styles['guest-table-container']}>
        <div className={styles['guest-table-header']}>
          <div className={styles['guest-table-title']}>Unsent Invites</div>
          {this.renderRemoveAllButton()}
        </div>

        {isEmpty ? (
          this.renderEmpTyPanel()
        ) : (
          <div className={styles['table-wrapper']}>
            <div className={styles['input-wrapper']}>
              <SvgIcon className={styles['search-icon']} icon={SearchIcon} />
              <TextInputElement
                className={styles['input-inner']}
                valid
                placeholder="Search guests"
                onChange={e => onInputChange(e.target.value)}
                value={searchValue}
              />
            </div>

            <Table
              className={styles['table-box']}
              rowKey={record => record.key}
              bordered
              columns={this.renderTableHeader()}
              dataSource={renderGuestsList}
              pagination={false}
            />
          </div>
        )}

        <PopConfirm
          visible={this.state.isOpenRemoveModal}
          onClose={() => this.setState({ isOpenRemoveModal: false })}
          onConfirm={this.handleRemoveAllGuests}
          confirmText="remove all"
          type="delete"
        >
          Are you sure you want to remove all the guests from this event?
        </PopConfirm>

        <EditModalHOC
          ref={this.editModalRef}
          onEditGuest={this.props.onEditGuest}
          onEditGuestWithKey={this.props.onEditGuestWithKey}
        />
      </div>
    )
  }
}

GuestTable.propTypes = {
  /** HOC props */
  addGuestsList: PropTypes.array,
  onRemoveGuest: PropTypes.func,
  onRemoveAllGuests: PropTypes.func,
  onInputChange: PropTypes.func,
  searchValue: PropTypes.string,
  searchGuestsList: PropTypes.array,
  onEditGuest: PropTypes.func,
  onEditGuestWithKey: PropTypes.func
}

export default GuestTable
