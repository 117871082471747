import React from 'react'
import PropTypes from 'prop-types'

import HobnobModal from './HobnobModal'
import ClassNames from 'classnames'
import styles from './hobnob-picker.scss'
import Picker from 'rmc-picker/lib/Picker'

const containerStyle = {
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  top: 'auto',
  width: '100%',
  margin: '0',
  padding: '0',
  borderRadius: 0,
  minHeight: 'auto',
  maxWidth: 'auto',
  transform: 'none'
}

export default class HobnobPicker extends React.Component {
  render () {
    const { show, value, options, onPickerChange, ok, onClose, children, cancel, closeOnOuterClick } = this.props
    return (
      <HobnobModal
        show={show}
        onClose={onClose}
        showCancelIcon={false}
        containerStyles={containerStyle}
        closeOnOuterClick={closeOnOuterClick}
      >
        <li className={ClassNames(styles['button-wrapper'])}>
          <a
            className={styles['button']}
            onClick={cancel}
          >
            Cancel
          </a>
          <a
            className={styles['button']}
            onClick={ok}
          >
            Save
          </a>
        </li>
        <Picker selectedValue={value} onValueChange={onPickerChange}>
          {options.map((item, index) => {
            return (
              <Picker.Item value={item.value} key={index}>
                {item.text}
              </Picker.Item>
            )
          })}
        </Picker>
        {children}
      </HobnobModal>
    )
  }
}

HobnobPicker.defaultProps = {
  closeOnOuterClick: false
}

HobnobPicker.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array,
  onPickerChange: PropTypes.func,
  ok: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any,
  show: PropTypes.bool,
  cancel: PropTypes.func,
  closeOnOuterClick: PropTypes.bool
}
