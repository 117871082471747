import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

import SvgIcon from 'src/components/common/SvgIcon'
import LeftChevron from 'src/assets/icons/left_chevron.svg'
import CancelIcon from '../../assets/icons/cancel-icon.svg'
import styles from './hobnob-navbar.scss'
import { changeEventsModal } from 'src/actions/event'

class HobnobNavBar extends React.Component {
  onClickLeft = () => {
    const {
      onRouterChange,
      goBack,
      backPathName,
      onRouterReplace,
      destinationType,
      eventToken,
      invitationToken,
      onChangeEventsModal
    } = this.props

    if (typeof backPathName !== 'undefined' && backPathName !== '') {
      if (backPathName === 'details') {
        let url = `/${destinationType}/${eventToken}/`

        if (invitationToken) {
          url += `${invitationToken}/`
        }

        onRouterReplace(url)
        onChangeEventsModal(backPathName)
      } else {
        onRouterChange(backPathName)
      }
    } else {
      goBack()
    }
  }

  render() {
    const { title, subTitle, children, border, close } = this.props
    return (
      <div
        className={styles['nav-bar']}
        style={{ borderBottom: border ? '1px solid #E5E5E5' : 'none' }}
      >
        <div className={styles['icon-wrapper']}>
          <SvgIcon
            icon={close ? CancelIcon : LeftChevron}
            className={styles['nav-bar-icon']}
            onClick={this.onClickLeft}
          />
        </div>
        {!!title && (
          <div className={styles['title-wrapper']}>
            <h3 className={styles['nav-bar-title']}>{title}</h3>
            <div className={styles['nav-bar-subtitle']}>{subTitle}</div>
          </div>
        )}
        <div className={styles['nav-bar-right']}>{children}</div>
      </div>
    )
  }
}

HobnobNavBar.defaultProps = {
  border: true,
  close: false
}

HobnobNavBar.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  backPathName: PropTypes.string,
  children: PropTypes.any,
  onRouterChange: PropTypes.func,
  goBack: PropTypes.func,
  border: PropTypes.bool,
  close: PropTypes.bool,
  destinationType: PropTypes.string,
  eventToken: PropTypes.string,
  invitationToken: PropTypes.string,
  onRouterReplace: PropTypes.func,
  onChangeEventsModal: PropTypes.func
}

function mapDispatchToProps(dispatch) {
  return {
    onRouterChange: route => dispatch(routerActions.push(route)),
    goBack: () => dispatch(routerActions.goBack()),
    onChangeEventsModal: tab => dispatch(changeEventsModal(tab)),
    onRouterReplace: route => dispatch(routerActions.replace(route))
  }
}

function mapStateToProps(state) {
  return {
    destinationType: state.destinationType,
    eventToken: state.eventToken,
    invitationToken: state.invitationToken
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HobnobNavBar)
