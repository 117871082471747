import PropTypes from 'prop-types'
import React from 'react'
import ClassNames from 'classnames'

import SvgIcon from 'src/components/common/SvgIcon'

import FlagIcon from 'src/assets/icons/icon_flag_photo.svg'
import DeleteIcon from 'src/assets/icons/icon_delete_photo.svg'

import styles from './medium-more-tooltip.scss'

export default class MediumMoreTooltip extends React.Component {
  _onDestroyMedium = () => {
    const { onDestroyMedium, onHideTooltip } = this.props
    onDestroyMedium()
    onHideTooltip()
  }
  _onToggleFlag = () => {
    const { onToggleFlag, onHideTooltip } = this.props
    onToggleFlag()
    onHideTooltip()
  }

  _renderDestroyButton () {
    return <div className={ClassNames(styles['row'])} onClick={this._onDestroyMedium}>
      <SvgIcon icon={DeleteIcon} className={styles['delete-icon']} />
      Delete Photo
    </div>
  }

  _renderFlagButton () {
    return <div className={styles['row']} onClick={this._onToggleFlag}>
      <SvgIcon icon={FlagIcon} className={styles['flag-icon']} />
      Mark as inappropriate
    </div>
  }

  render () {
    const { canRemoveMedium } = this.props

    return (
      <div className={styles['container']}>
        {canRemoveMedium ? this._renderDestroyButton() : this._renderFlagButton()}
      </div>
    )
  }
}

MediumMoreTooltip.propTypes = {
  canRemoveMedium: PropTypes.bool,
  onDestroyMedium: PropTypes.func,
  onHideTooltip: PropTypes.func,
  onToggleFlag: PropTypes.func
}
