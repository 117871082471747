import { fetchApi } from './api'
import { User } from 'src/schemas'

export const FETCH_USER_GQ = 'FETCH_USER_GQ'
export const FETCH_USER_GQ_SUCCESS = 'FETCH_USER_GQ_SUCCESS'
export const FETCH_USER_GQ_ERROR = 'FETCH_USER_GQ_ERROR'

export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER'
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS'
export const FETCH_CURRENT_USER_ERROR = 'FETCH_CURRENT_USER_ERROR'

export const FETCH_ME_COMMUNITY = 'FETCH_ME_COMMUNITY'
export const FETCH_ME_COMMUNITY_SUCCESS = 'FETCH_ME_COMMUNITY_SUCCESS'
export const FETCH_ME_COMMUNITY_ERROR = 'FETCH_ME_COMMUNITY_ERROR'

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

export const UPDATE_AVATAR = 'UPDATE_AVATAR'

export const CANCEL_STRIPE_SUBSCRIPTION = 'CANCEL_STRIPE_SUBSCRIPTION'
export const CANCEL_STRIPE_SUBSCRIPTION_SUCCESS =
  'CANCEL_STRIPE_SUBSCRIPTION_SUCCESS'
export const CANCEL_STRIPE_SUBSCRIPTION_ERROR =
  'CANCEL_STRIPE_SUBSCRIPTION_ERROR'

export function createUser(user) {
  return fetchApi({
    requestType: CREATE_USER,
    successType: CREATE_USER_SUCCESS,
    errorType: CREATE_USER_ERROR,
    route: '/users',
    schema: {
      user: User
    },
    options: {
      method: 'POST',
      body: JSON.stringify({ user })
    },
    onErrorCallback: () => {}
  })
}

export function updateAvatar({ file, onSuccessCallback }) {
  return {
    type: UPDATE_AVATAR,
    file,
    onSuccessCallback
  }
}

export function updateUser({ user, onSuccessCallback }) {
  user.last_updated_at = user.updated_at
  return fetchApi({
    requestType: UPDATE_USER,
    successType: UPDATE_USER_SUCCESS,
    errorType: UPDATE_USER_ERROR,
    route: '/user',
    schema: {
      user: User
    },
    params: {
      // TODO: May need to add this back in to get multiple photo upload support working again.
      //   // Add a random key to force iOS webkit to not combine requests
      //   rk: Math.floor(Math.random() * 1000)
      redirect_to_callback_after_upload: false
    },
    options: {
      method: 'PATCH',
      body: JSON.stringify({ user })
    },
    onSuccessCallback
  })
}

export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR'

export function fetchUser(userId) {
  return fetchApi({
    requestType: FETCH_USER,
    successType: FETCH_USER_SUCCESS,
    errorType: FETCH_USER_ERROR,
    route: `/users/${userId}`,
    schema: {
      user: User
    }
  })
}

// !!! should not use this API anymore, use src/actions/user.js:fetchUserGQ instead
export function fetchCurrentUser() {
  return fetchApi({
    requestType: FETCH_CURRENT_USER,
    successType: FETCH_CURRENT_USER_SUCCESS,
    errorType: FETCH_CURRENT_USER_ERROR,
    route: '/user',
    params: {
      // TODO: May need to add this back in to get multiple photo upload support working again.
      //   // Add a random key to force iOS webkit to not combine requests
      //   rk: Math.floor(Math.random() * 1000)
      redirect_to_callback_after_upload: false
    },
    schema: {
      user: User
    }
  })
}

export function cancelSubscription({ onSuccessCallback, onErrorCallback }) {
  return fetchApi({
    requestType: CANCEL_STRIPE_SUBSCRIPTION,
    successType: CANCEL_STRIPE_SUBSCRIPTION_SUCCESS,
    errorType: CANCEL_STRIPE_SUBSCRIPTION_ERROR,
    route: '/purchases/stripe/subscription/cancel',
    options: {
      method: 'PUT'
    },
    onSuccessCallback,
    onErrorCallback
  })
}

export function fetchUserGQ(onSuccessCallback) {
  return fetchApi({
    requestType: FETCH_USER_GQ,
    successType: FETCH_USER_GQ_SUCCESS,
    errorType: FETCH_USER_GQ_ERROR,
    route: '/graphql',
    schema: {
      data: {
        me: User
      }
    },
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `{
          me {
            id
            app_user
            is_trial_available
            current_subscription {
              available_credit
              created_at
              expires_at
              next_credit_renewal_at
              plan {
                feature_level
                invitations_per_month
                name
                slug
                subscription_period
              }
              renews_at
              status
              subscription_type
              cancel_at_period_end
            }
            avatar {
              medium_url_2x
            }
            avatar_state
            created_at
            destroyed_at
            first_name
            last_name
            joined_at
            primary_contact_method {
              type
              hash
              masked_display_name
            }
            referred_by_token
            unlocked_galleries
            updated_at
            verified
            zoom_install_url
            zoom_user {
              first_name
              last_name
              linked_to_zoom
              zoom_identifier
            }
          }
        }`
      })
    },
    onSuccessCallback
  })
}

export const FETCH_COMMUNITIES_BY_ID = 'FETCH_COMMUNITIES_BY_ID'
export const FETCH_COMMUNITIES_BY_ID_SUCCESS = 'FETCH_COMMUNITIES_BY_ID_SUCCESS'
export const FETCH_COMMUNITIES_BY_ID_ERROR = 'FETCH_COMMUNITIES_BY_ID_ERROR'

export function fetchCommunitiesById(id, onSuccessCallback) {
  return fetchApi({
    requestType: FETCH_COMMUNITIES_BY_ID,
    successType: FETCH_COMMUNITIES_BY_ID_SUCCESS,
    errorType: FETCH_COMMUNITIES_BY_ID_ERROR,
    route: `/communities/${id}`,
    method: 'GET',
    onSuccessCallback
  })
}

export function fetchMeWithCommunity({ onSuccessCallback } = {}) {
  return fetchApi({
    requestType: FETCH_ME_COMMUNITY,
    successType: FETCH_ME_COMMUNITY_SUCCESS,
    errorType: FETCH_ME_COMMUNITY_ERROR,
    route: '/graphql2',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `{
          me {
            id
            communities {
              avatar_url
              community_color {
                color_value
                custom_color_hexcode
              }
              community_channel
              community_initials
              cover_image_upload_id
              cover_image_url
              description
              destroyed_at
              id
              indexable_landing_page
              inserted_at
              legacy_topic_count
              member_count
              name
              open_in_app_url
              post_count
              share_url
              slug
              social_link_facebook
              social_link_instagram
              social_link_twitter
              topic_count
              updated_at
              community_members {
                avatar_url
                bio
                community_id
                community_initials
                destroyed_at
                dm_chat_thread_reply_notifications_enabled
                first_name
                id
                inserted_at
                joined_at
                last_name
                name
                title
                updated_at
                user_id
                community_id
              }
              my_community_member {
                avatar_url
                bio
                community_color {
                  color_value
                  custom_color_hexcode
                }
                community_id
                community_initials
                destroyed_at
                dm_chat_notification_preference
                dm_chat_thread_reply_notifications_enabled
                first_name
                id
                inserted_at
                joined_at
                last_name
                name
                post_default_tracking_state
                role
                title
                updated_at
                user_id
              }
              topics {
                community_id
                destroyed_at
                id
                name
                mentionables {
                  id
                  label
                  description
                  type
                }
                topic_user_settings {
                  tracking_state
                }
              }
            }
          }
        }`
      })
    },
    onSuccessCallback
  })
}
