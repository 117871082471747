import queryString from 'query-string'
import { isIOS, iOSVersion } from './browser_detection'

export function mailtoLink ({ subject, body }) {
  return 'mailto:?' + queryString.stringify({
    subject,
    body
  })
}

export function twitterLink ({ subject, url }) {
  const params = {
    text: subject,
    url,
    via: 'hobnobinvites'
  }

  return `https://twitter.com/share?${queryString.stringify(params)}`
}

export function facebookLink ({ href, redirect_uri }) {
  const params = {
    app_id: process.env.FACEBOOK_APP_ID,
    display: 'popup',
    href,
    redirect_uri
  }

  return `https://www.facebook.com/dialog/share?${queryString.stringify(params)}`
}

export function smsLink ({ subject, fallbackSubject, body }) {
  const useFallback = subject.indexOf('%') >= 0
  const content = `${useFallback ? fallbackSubject : subject}\n\n${body}`

  return `${smsPrefix()}${queryString.stringify({ body: content })}`
}

function smsPrefix () {
  if (isIOS()) {
    const version = iOSVersion()[0]

    if ([6, 7].indexOf(version) >= 0) {
      return 'sms:;'
    } else if ([8, 9, 10].indexOf(version) >= 0) {
      return 'sms:&'
    }

    return 'sms:&'
  } else {
    return 'sms:?'
  }
}
