import { combineReducers } from 'redux'

import {
  SHOW_RSVP_MODAL,
  HIDE_RSVP_MODAL,
  CHANGE_RSVP_STATUS,
  HAS_CHANGED_RSVP_STATUS,
  SHOW_BRING_GUEST_MODAL,
  SHOW_GUEST_COUNT_CONTROLS_MODAL,
  SHOW_ACCEPTED_SEND_MESSAGE_MODAL,
  SHOW_DECLINED_SEND_MESSAGE_MODAL,
  SET_TOTAL_ATTENDING
} from 'src/actions/frontend'

const visible = (state = false, action) => {
  switch (action.type) {
    case SHOW_RSVP_MODAL:
      return true
    case HIDE_RSVP_MODAL:
      return false
    default:
      return state
  }
}

const changeRsvp = (state = false, action) => {
  switch (action.type) {
    case CHANGE_RSVP_STATUS:
      return action.changeRsvp
    default:
      return state
  }
}

const showBringGuestModal = (state = true, action) => {
  switch (action.type) {
    case CHANGE_RSVP_STATUS:
      return !action.changeRsvp
    case SHOW_BRING_GUEST_MODAL:
      return true
    case SHOW_GUEST_COUNT_CONTROLS_MODAL:
    case SHOW_ACCEPTED_SEND_MESSAGE_MODAL:
      return false
    default:
      return state
  }
}

const showGuestCountControlsModal = (state = false, action) => {
  switch (action.type) {
    case CHANGE_RSVP_STATUS:
      return action.changeRsvp
    case SHOW_GUEST_COUNT_CONTROLS_MODAL:
      return true
    case SHOW_BRING_GUEST_MODAL:
    case SHOW_ACCEPTED_SEND_MESSAGE_MODAL:
      return false
    default:
      return state
  }
}

const showInvitationReplyFormModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_GUEST_COUNT_CONTROLS_MODAL:
    case SHOW_BRING_GUEST_MODAL:
      return false
    case SHOW_ACCEPTED_SEND_MESSAGE_MODAL:
      return true
    default:
      return state
  }
}

const showDefaultDeclinedPage = (state = false, action) => {
  switch (action.type) {
    case CHANGE_RSVP_STATUS:
      return action.changeRsvp
    case SHOW_DECLINED_SEND_MESSAGE_MODAL:
      return false
    default:
      return state
  }
}

const hasChangedRsvpStatus = (state = false, action) => {
  switch (action.type) {
    case HIDE_RSVP_MODAL:
      return false
    case HAS_CHANGED_RSVP_STATUS:
      return true
    default:
      return state
  }
}

const showTotalAttending = (state = false, action) => {
  switch (action.type) {
    case HIDE_RSVP_MODAL:
      return true
    case SET_TOTAL_ATTENDING:
      return action.params
    default:
      return state
  }
}

export default combineReducers({
  visible,
  changeRsvp,
  hasChangedRsvpStatus,
  showBringGuestModal,
  showGuestCountControlsModal,
  showInvitationReplyFormModal,
  showDefaultDeclinedPage,
  showTotalAttending
})
