import React from 'react'
import { connect } from 'react-redux'
import { changeAddGuestsList } from 'src/actions/event'
import { updateInvitation } from 'src/actions/invitations'
import debounce from 'lodash/debounce'
import { arrayDeduplicationWithObject } from 'services/utils'
import PropTypes from 'prop-types'

function withAddGuestsList(WrappedComponent) {
  class WithAddGuestsList extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        searchValue: '',
        searchGuestsList: []
      }
    }

    searchGuestsDebounce = debounce(value => {
      const { addGuestsList } = this.props

      const searchGuestsWithName = addGuestsList.filter(({ first_name, last_name }) => {
        const fullName = first_name + ' ' + last_name
        return fullName?.toLowerCase()?.includes(value.toLowerCase())
      })

      const searchGuestsWithContactMethod = addGuestsList.filter(
        ({ contact_method_display_name }) => {
          return contact_method_display_name?.toLowerCase()?.includes(value.toLowerCase())
        }
      )

      const searchGuestsList = arrayDeduplicationWithObject(
        searchGuestsWithName.concat(searchGuestsWithContactMethod),
        'key'
      )

      this.setState({ searchGuestsList })
    }, 300)

    searchGuests = (value = this.state.searchValue) => {
      this.setState({ searchValue: value })

      if (!value) {
        this.setState({ searchGuestsList: [] })
        return
      }

      this.searchGuestsDebounce(value)
    }

    handleRemoveGuest = guest => {
      const { addGuestsList, onChangeAddGuestsList } = this.props
      const cloneAddGuestsList = [...addGuestsList]
      const index = cloneAddGuestsList.findIndex(item => item.key === guest.key)
      cloneAddGuestsList.splice(index, 1)

      onChangeAddGuestsList(cloneAddGuestsList)

      this.searchGuests()
    }

    handleRemoveAllGuests = () => {
      const { onChangeAddGuestsList } = this.props

      onChangeAddGuestsList([])

      this.setState({ searchValue: '', searchGuestsList: [] })
    }

    handleEditGuest = guest => {
      const { addGuestsList, onChangeAddGuestsList } = this.props
      const cloneAddGuestsList = [...addGuestsList]
      const index = addGuestsList.findIndex(item => item.key === guest.key)

      cloneAddGuestsList[index] = guest
      onChangeAddGuestsList(cloneAddGuestsList)
      this.searchGuests()
    }

    render() {
      const { searchValue, searchGuestsList } = this.state
      return (
        <WrappedComponent
          {...this.props}
          searchValue={searchValue}
          searchGuestsList={searchGuestsList}
          onRemoveGuest={this.handleRemoveGuest}
          onRemoveAllGuests={this.handleRemoveAllGuests}
          onEditGuest={this.handleEditGuest}
          onInputChange={this.searchGuests}
        />
      )
    }
  }

  WithAddGuestsList.displayName = `WithAddGuestsList(${WrappedComponent.displayName})`
  WithAddGuestsList.propTypes = {
    eventId: PropTypes.string,
    addGuestsList: PropTypes.array,
    onChangeAddGuestsList: PropTypes.func,
    onUpdateInvitation: PropTypes.func
  }
  return connect(mapStateToProps, mapDispatchToProps)(WithAddGuestsList)
}

function mapStateToProps(state) {
  return {
    addGuestsList: state.frontend.addGuestsList,
    eventId: state.eventId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeAddGuestsList: params => dispatch(changeAddGuestsList(params)),
    onUpdateInvitation: params =>
      dispatch(updateInvitation(params.invitation, params.invitationId, params.eventId, {}, params))
  }
}

export default withAddGuestsList
