import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { VelocityTransitionGroup } from 'velocity-react'

import { pluralize } from 'src/services/utils'
import { fullName } from 'src/services/user_helpers'
import { NUM_INITIAL_COMMENTS } from 'src/services/media_helpers'
import { commentTimestamp } from 'src/services/comment_helpers'

import SvgIcon from 'src/components/common/SvgIcon'
import InlineLoadingIndicator from 'src/components/common/InlineLoadingIndicator'

import MediumCommentForm from './MediumCommentForm'

import HeartOutline from 'src/assets/icons/heart_outline.svg'

import styles from './medium-comments.scss'

export default class MediumComments extends Component {
  _likesCountLabel () {
    const { likes, medium } = this.props
    let likesCount = medium.likes_count
    if (likes && medium.has_fetched_likes) {
      const visibleLikes = likes.filter((like) => !like.destroyed_at)
      likesCount = visibleLikes.length
    }

    return `${likesCount} ${pluralize(likesCount, 'Like')}`
  }

  renderCommentText = (comment) => {
    if (comment.indexOf('@') > -1) {
      return '@' + comment.split('@')[1].replace('>', '')
    }

    return comment
  }

  _renderComment = (comment) => {
    const { namesByUserId, users } = this.props
    const commentUser = users[comment.user]

    return (
      <div className={styles['comment']} key={comment.id}>
        <span className={styles['user-name']}>
          {fullName(commentUser, namesByUserId)}&nbsp;
        </span>
        {this.renderCommentText(comment.content)}
        <div className={styles['comment-meta']}>
          {commentTimestamp(comment.created_at)}
        </div>
      </div>
    )
  }

  _renderComments () {
    const { comments } = this.props

    return (
      <VelocityTransitionGroup enter={{ animation: 'slideDown' }} leave={{ animation: 'slideUp' }}>
        {comments.map(this._renderComment)}
      </VelocityTransitionGroup>
    )
  }

  render () {
    const {
      comments,
      medium,
      commentLoadingStatus,
      onFetchAllComments,
      onLikesTapped,
      onSubmitComment
    } = this.props

    const commentText = comments.length > 0 ? 'Comment...' : 'Be the first to comment...'
    const hasMoreComments = medium.comments_count > NUM_INITIAL_COMMENTS && medium.comments_count > comments.length

    return (
      <div className={styles['container']}>
        <div className={styles['comments-container']}>
          <div className={styles['likes']} onClick={onLikesTapped}>
            <SvgIcon icon={HeartOutline} className={styles['heart-icon']} />
            {this._likesCountLabel()}
          </div>
          {hasMoreComments && commentLoadingStatus !== 'loading'
            ? <a onClick={onFetchAllComments}>
              View all {medium.comments_count} comments
            </a>
            : null}
          <InlineLoadingIndicator
            className={styles['inline-loading-indicator']}
            loading={commentLoadingStatus === 'loading'}
          />

          {comments && comments.length > 0
            ? <div className={styles['comments-list']}>{this._renderComments()}</div>
            : null}
        </div>

        <MediumCommentForm
          onSubmitComment={onSubmitComment}
          placeholder={commentText}
        />
      </div>
    )
  }
}

MediumComments.propTypes = {
  comments: PropTypes.array,
  commentLoadingStatus: PropTypes.string,
  likes: PropTypes.array,
  medium: PropTypes.object.isRequired,
  namesByUserId: PropTypes.object.isRequired,
  onFetchAllComments: PropTypes.func.isRequired,
  onLikesTapped: PropTypes.func.isRequired,
  onSubmitComment: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired
}
MediumComments.defaultProps = {
  comments: []
}
