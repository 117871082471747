import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchGalleryImage, saveGalleries } from 'src/actions/eventChat'
import { Image } from 'antd'
import { chatSelector } from 'src/selectors'
import { setPreviewGalleries, setPreviewIndex } from 'src/actions/frontend'

import styles from './gallery-message.scss'

const GalleryMessage = props => {
  const dispatch = useDispatch()
  const { galleryContent } = props
  const [galleries, setGallery] = useState({ gallery_image_uploads: [] })
  const { chatGalleries } = useSelector(chatSelector)

  useEffect(() => {
    let isMounted = true
    const gallery = chatGalleries[galleryContent.metadata.gallery_id]
    if (gallery) {
      setGallery(gallery)
    } else {
      dispatch(
        fetchGalleryImage({
          galleryId: galleryContent.metadata.gallery_id,
          onSuccessCallback: res => {
            if (isMounted) {
              const gallery = res.data.galleries[0]
              setGallery(gallery)
              dispatch(saveGalleries(gallery))
            }
          }
        })
      )
    }

    return () => {
      isMounted = false
    }
  }, [])

  const handleOpenPreview = (index, gallery_images) => {
    const previewGalleries = gallery_images.map(img => ({
      id: img.id,
      url: img.image_upload.medium_url
    }))
    dispatch(setPreviewIndex(index))
    dispatch(setPreviewGalleries(previewGalleries))
  }

  const gallery_images = galleries.gallery_image_uploads
  const isOnePhoto = gallery_images.length === 1

  const calcImageHeight = () => {
    if (isOnePhoto) {
      const intrinsicWidth = gallery_images[0].image_upload.metadata.width
      const intrinsicHeight = gallery_images[0].image_upload.metadata.height
      const height = 200 / (intrinsicWidth / intrinsicHeight)

      return height
    } else {
      return 200
    }
  }

  const galleryHeight = calcImageHeight()

  return (
    <div className={styles['gallery-container']} style={{ height: galleryHeight + 'px' }}>
      {gallery_images.map((img, index) => {
        const image_upload = img.image_upload
        if (index > 3) {
          return
        }
        if (index === 3 && gallery_images.length > 4) {
          return (
            <div className={styles['img-wrapper']} key={img.id}>
              <img className={styles['img-inner']} src={image_upload.medium_url} />
              <div
                className={styles['float-layer']}
                onClick={() => handleOpenPreview(index, gallery_images)}
              >
                All {gallery_images.length} Photos
              </div>
            </div>
          )
        }

        return (
          <Image
            key={img.id}
            width={200}
            height={galleryHeight}
            rootClassName={styles['img-inner']}
            src={image_upload.medium_url}
            onClick={() => handleOpenPreview(index, gallery_images)}
            preview={{ visible: false }}
          />
        )
      })}
    </div>
  )
}

GalleryMessage.propTypes = {
  galleryContent: PropTypes.object.isRequired
}

export default GalleryMessage
