import PropTypes from 'prop-types'
import React from 'react'

import styles from './checkbox-input.scss'

export default function CheckBoxInput (props) {
  const {
    onChange,
    checked,
    className,
    disabled,
    readOnly,
    size = 30
  } = props

  return (
    <div className={className || styles['container']} style={{ width: size + 'px', height: size + 'px' }}>
      <label className={styles['label']}>
        <input
          type='checkbox'
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
        />
        <span className={styles['checkmark']} style={{ width: size + 'px', height: size + 'px' }} />
      </label>
    </div>
  )
}

CheckBoxInput.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.number,
  readOnly: PropTypes.bool
}
