import React, { Component } from 'react'
import PropTypes from 'prop-types'

function withGuestEditModal(WrapperComponent) {
  class WithGuestEditModal extends Component {
    state = {
      isOpenEditModal: false,
      first_name: '',
      last_name: '',
      cohost: false,
      guestInfo: null
    }

    closeModal = () => {
      this.setState({
        isOpenEditModal: false,
        first_name: '',
        last_name: '',
        cohost: false,
        guestInfo: null
      })
    }

    openModal = guest => {
      const { first_name, last_name, cohost } = guest

      this.setState({
        isOpenEditModal: true,
        first_name,
        last_name,
        cohost,
        guestInfo: guest
      })
    }

    handleSave = () => {
      const { first_name, last_name, cohost } = this.state
      this.props.onEditGuest({ first_name, last_name, cohost })

      this.closeModal()
    }

    handleInputChange = (name, value) => {
      this.setState({
        [name]: value
      })
    }

    render() {
      return (
        <WrapperComponent
          {...this.state}
          isHost={this.props.isHost}
          event={this.props.event}
          isHostOrCohost={this.props.isHostOrCohost}
          onClose={this.closeModal}
          onInputChange={this.handleInputChange}
          onSave={this.handleSave}
          visibleContactInput={false}
        />
      )
    }
  }
  WithGuestEditModal.displayName = `WithGuestEditModal(${WrapperComponent.displayName})`
  WithGuestEditModal.propTypes = {
    isHost: PropTypes.bool,
    isHostOrCohost: PropTypes.bool,
    event: PropTypes.object,
    onEditGuest: PropTypes.func
  }

  return WithGuestEditModal
}

export default withGuestEditModal
