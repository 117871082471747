import { combineReducers } from 'redux'

import {
  SET_ACTIVITY_FEED_LIST,
  SET_AF_OLDEST_TIMESTAMP,
  SET_AF_NEWEST_TIMESTAMP,
  SET_AF_SELECTED_TAG,
  SET_AF_LOADING
} from 'src/actions/activityFeed'

const activityFeedList = (state = [], action) => {
  switch (action.type) {
    case SET_ACTIVITY_FEED_LIST:
      return action.activityFeedList
    default:
      return state
  }
}

// const unReadActivityFeedList = (state = [], action) => {
//   switch (action.type) {
//     case SET_ACTIVITY_FEED_LIST:
//       return action.activityFeedList.filter(item =>
//         item.activity_items.some(activity => !activity.read)
//       )
//     default:
//       return state
//   }
// }

const oldestTimestamp = (state = null, action) => {
  switch (action.type) {
    case SET_AF_OLDEST_TIMESTAMP:
      return action.oldestTimestamp
    default:
      return state
  }
}

const newestTimestamp = (state = null, action) => {
  switch (action.type) {
    case SET_AF_NEWEST_TIMESTAMP:
      return action.newestTimestamp
    default:
      return state
  }
}

const isHasUnread = (state = false, action) => {
  switch (action.type) {
    case SET_ACTIVITY_FEED_LIST:
      return action.activityFeedList.some(item => {
        return item.activity_items.some(activity => !activity.read)
      })
    default:
      return state
  }
}

const selectedTag = (state = 'All', action) => {
  switch (action.type) {
    case SET_AF_SELECTED_TAG:
      return action.selectedTag
    default:
      return state
  }
}

const isLoading = (state = false, action) => {
  switch (action.type) {
    case SET_AF_LOADING:
      return action.isLoading
    default:
      return state
  }
}

export default combineReducers({
  activityFeedList,
  oldestTimestamp,
  newestTimestamp,
  isHasUnread,
  selectedTag,
  isLoading
})
