import React, { Component } from 'react'
import styles from './time-poll-card.scss'
import PropTypes from 'prop-types'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import moment from 'moment'
import SvgIcon from 'src/components/common/SvgIcon'
import IconDate from 'assets/icons/icon_date.svg'
import IconSelectedDate from 'assets/icons/icon_selected_date.svg'
import IconEmptyDate from 'assets/icons/icon_empty_date.svg'
import cn from 'classnames'
import { fullName } from 'services/user_helpers'

class TimePollCard extends Component {
  renderActiveText = options => {
    let dateStr = []
    for (const option of options) {
      if (!option.starts_at) {
        continue
      }

      const startAt = moment(option.starts_at)
      if (startAt.isSame(option.ends_at, 'day')) {
        dateStr.push(startAt.format('MMM Do'))
      } else {
        const endAt = moment(option.ends_at)
        dateStr.push(`${startAt.format('MMM Do')} - ${endAt.format('MMM Do')}`)
      }
    }

    return dateStr.join(', ')
  }

  renderClosedText = options => {
    let dateStr = []

    for (const option of options) {
      if (!option.starts_at) {
        continue
      }

      if (option.is_selected_by_creator) {
        const startAt = moment(option.starts_at)
        if (startAt.isSame(option.ends_at, 'day')) {
          dateStr.push(startAt.format('MMM Do'))
        } else {
          const endAt = moment(option.ends_at)
          dateStr.push(`${startAt.format('MMM Do')} - ${endAt.format('MMM Do')}`)
        }
      }
    }

    return dateStr
  }

  renderOption = () => {
    const { poll } = this.props

    if (poll.status === 'active') {
      return (
        <div className={styles['option-date']}>
          <SvgIcon className={styles['icon']} icon={IconDate} />
          <span>{this.renderActiveText(poll.poll_options)}</span>
        </div>
      )
    }

    if (poll.status === 'closed') {
      const dateStr = this.renderClosedText(poll.poll_options)
      const isEmpty = dateStr.length === 0

      return (
        <div className={styles['option-date']}>
          <SvgIcon className={styles['icon']} icon={isEmpty ? IconEmptyDate : IconSelectedDate} />
          {isEmpty ? (
            <span className={styles['empty-date']}>Without final selection</span>
          ) : (
            <span className={styles['selected-date']}>{dateStr.join(', ')}</span>
          )}
        </div>
      )
    }
  }

  goTPDetail = () => {
    window.location.href = `/tp/${this.props.poll.token}`
  }

  render() {
    const { poll, userId } = this.props
    const displayDate = moment(poll.created_at).format('MMM DD')
    const isCreator = poll.creator.id === userId
    const isClosed = poll.status === 'closed'

    return (
      <div
        className={cn(styles['time-poll-inner'], isClosed && styles['time-poll-inner-closed'])}
        onClick={this.goTPDetail}
      >
        <div className={styles['title']}>{poll.name}</div>
        <div className={styles['content']}>
          {poll.notes && (
            <div className={styles['note']}>
              <b>Notes:</b> {poll.notes}
            </div>
          )}
          <>{this.renderOption()}</>
        </div>

        <div className={styles['creator-info']}>
          <UserAvatarContainer user={poll.creator} size={34} />
          <div className={styles['creator']}>
            <div className={styles['name']}>
              {isCreator ? 'Me' : `Creator: ${fullName(poll.creator)}`}
            </div>
            <div className={styles['date']}>Created on {displayDate}</div>
          </div>
        </div>

        <div
          className={cn(
            styles['card-tag'],
            isClosed ? styles['card-tag-closed'] : styles['card-tag-active']
          )}
        >
          {isClosed ? 'Closed' : 'Open'}
        </div>
      </div>
    )
  }
}

TimePollCard.propTypes = {
  userId: PropTypes.string,
  poll: PropTypes.object
}

export default TimePollCard
