import { maxBy } from 'lodash'
import { pluralize } from './utils'

// Note: This should usually be used via the selector
export function hasPaidTickets(ticketTypes) {
  return ticketTypes.filter(item => !item.archived_at).some(item => item.price !== 0)
}

export function ticketTypeHasDescription(ticketTypes) {
  return ticketTypes.some(item => !!item.description)
}

export function renderOrderStatus(refund_requests, defaultText = '') {
  let text = defaultText

  if (refund_requests && refund_requests.length) {
    const lastRefundRequest = maxBy(refund_requests, function (refund) {
      return new Date(refund.updated_at)
    })
    if (lastRefundRequest.state === 'pending') {
      text = 'pending'
    }

    if (lastRefundRequest.state === 'processed') {
      text = 'processed'
    }
  }

  return text
}

export function getDisplayOrderTickets(listMyOrdersForEvent, ticketTypes, type = 'all') {
  const activeOrders = listMyOrdersForEvent.filter(order => order.tickets && order.tickets.length)
  let ordersWithCounts = []

  activeOrders.forEach((order) => {
    let displayOrderTickets = []
    switch (type) {
      case 'all':
        displayOrderTickets = order.tickets
        break
      case 'active':
        displayOrderTickets = order.tickets.filter((item) => item.state === 'active')
        break
    }

    if (displayOrderTickets.length) {
      const tempTickets = displayOrderTickets.map((ticket) => {
        const ticketType = ticketTypes.find(item => item.id === ticket.ticket_type_id)
        return {
          ...ticket,
          label: ticketType.label,
          price: ticketType.price
        }
      })
      const adultTicketCounts = tempTickets.filter((item) => item.label === 'adult')
      const childTicketCounts = tempTickets.filter((item) => item.label === 'child')
      ordersWithCounts.push({
        ...order,
        tickets: tempTickets,
        adultTicketCounts,
        childTicketCounts
      })
    }
  })

  return ordersWithCounts
}

export function renderLabel(adultTicketCounts, childTicketCounts) {
  if (adultTicketCounts.length && childTicketCounts.length) {
    return `${adultTicketCounts.length} Adult, ${childTicketCounts.length} Child`
  } else {
    const totalCounts = adultTicketCounts.length + childTicketCounts.length
    return `${totalCounts} ${pluralize((totalCounts), 'Ticket')}`
  }
}
