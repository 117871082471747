import PropTypes from 'prop-types'
import React from 'react'
import cn from 'classnames'
import InputElement from 'react-input-mask'
import styles from './phone-input-inner.scss'
import inputStyles from 'src/styles/inputs.scss'

const phoneMask = '999-999-9999'

const PhoneInputInner = ({ invalid, value, ...otherProps }) => {
  return (
    <InputElement
      autoFocus
      type="tel"
      mask={phoneMask}
      autoComplete="off"
      placeholder="Phone Number"
      value={value}
      className={cn(inputStyles['text-input'], styles['phone-number'], invalid && styles['error'])}
      {...otherProps}
    />
  )
}

PhoneInputInner.propTypes = {
  className: PropTypes.string,
  invalid: PropTypes.bool,
  value: PropTypes.string.isRequired
}

export default PhoneInputInner
