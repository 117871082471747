import React, { Component } from 'react'
import TextInputElement from 'components/forms/TextInputElement'
import Button from 'components/common/Button'
import DateModal from './DateModal/DateModal'
import HobnobSelect from 'components/common/HobnobSelect'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import LabelIcon from 'assets/icons/label-icon.png'
import LocationIcon from 'assets/icons/location-icon.png'
import DateIcon from 'assets/icons/date-icon.png'
import HobnobSpaceIcon from 'assets/icons/hobnob-space-icon.png'
import RightIconWhite from 'assets/icons/right-icon-white.png'
import CheckMarkIcon from 'assets/icons/check-mark-icon.png'
import { fetchCreateEvent, changeEventForm } from 'src/actions/event'
import moment from 'moment'
import timezoneJSON from 'assets/frontend-json/timezone.json'
import cn from 'classnames'
import { renderTimezoneAbbreviation } from 'services/date_helpers'
import { capitalized } from 'services/utils'
import styles from './first-step.scss'
import LocationAutoCompleteInput from 'components/map/LocationAutoCompleteInput'
import {
  createApplePlace,
  mapLocationDataBetweenApplePlaceAndGooglePlace
} from '../../../../actions/places'
import HobnobModal from 'components/common/HobnobModal'
import { Select } from 'antd'

const optionGroups = timezoneJSON.timezone

class FirstStep extends Component {
  state = {
    isOpenDateModal: false,
    nameInputSelectionStart: 0,
    isLocationSearchModalShown: false,
    isLocationSearchPanelShown: false,
    nameInputSelectionEnd: 0,
    selectedAppleLocation: undefined
  }

  nameInputRef = null

  handleChange = (type, value) => {
    const { onChangeEventForm } = this.props

    switch (type) {
      case 'name':
        if (!this.nameInputRef) {
          this.nameInputRef = value.target
        }

        const nameStr = capitalized(this.nameInputRef.value)
        onChangeEventForm({ name: nameStr })
        this.setState({
          nameInputSelectionStart: this.nameInputRef.selectionStart,
          nameInputSelectionEnd: this.nameInputRef.selectionEnd
        })

        break
      case 'community':
        onChangeEventForm({ external_community_id: value || null })
        break
      default:
        onChangeEventForm({ [type]: value })
    }
  }

  submitForm = () => {
    const { onCreateEvent, eventForm } = this.props
    onCreateEvent(eventForm)
  }

  componentDidUpdate(prevProp) {
    if (prevProp.eventForm.name !== this.props.eventForm.name) {
      this.nameInputRef?.setSelectionRange(
        this.state.nameInputSelectionStart,
        this.state.nameInputSelectionEnd
      )
    }
  }

  renderText = type => {
    const { eventForm } = this.props
    switch (type) {
      case 'timezone':
        const timezone = optionGroups.find(item => item.value === eventForm?.timezone)
        return timezone?.text
    }
  }

  getCommunitiesList = () => {
    const { communityMemberList } = this.props
    const ValidList = ['admin', 'member', 'moderator']

    const lists = communityMemberList.filter(item => {
      if (ValidList.indexOf(item.my_community_member?.role) > -1) {
        return { value: item.id, text: item.name }
      }
    })

    const communitiesList = lists.map(item => ({ value: item.id, text: item.name }))
    communitiesList.unshift({ value: '', text: 'None' })
    return communitiesList
  }

  handleNextStep = () => {
    const { onButtonClick, createApplePlace, userId } = this.props
    window.mixpanel.track('SELECT DESIGN Clicked', {
      user_id: userId
    })
    if (!this.state.selectedAppleLocation) {
      onButtonClick()
      return
    }
    const place = this.state.selectedAppleLocation
    createApplePlace({
      placeData: mapLocationDataBetweenApplePlaceAndGooglePlace(place, 'google'),
      onSuccessCallback: response => {
        if (response.data?.apple_place_create?.successful) {
          const createdPlace = response.data.apple_place_create.result
          this.handleChange('apple_place_id', createdPlace.id)
          if (response.data.apple_place_create.messages?.length) {
            console.log('create place successful with messages')
            console.log(response.data.apple_place_create.messages)
          }
          onButtonClick()
        } else if (response.errors) {
          console.warn('failed to create apple place:')
          console.warn(response.errors)
        } else {
          console.error(response)
        }
      }
    })
  }

  onLocationSelected(location) {
    if (location.latitude) {
      this.setState({ isLocationSearchModalShown: false, selectedAppleLocation: location })
      this.handleChange('venue', location)
      this.handleChange('custom_venue_name', null)
    } else {
      this.setState({ isLocationSearchModalShown: false, selectedAppleLocation: undefined })
      this.handleChange('apple_place_id', null)
      this.handleChange('venue', null)
      this.handleChange('custom_venue_name', location.name ? location.name : null)
    }
  }

  render() {
    const { eventForm, userId } = this.props

    let displayLocation = ''
    if (eventForm.venue) {
      displayLocation = eventForm.venue.name ? eventForm.venue.name : eventForm.venue.street
    } else {
      displayLocation = eventForm.custom_venue_name
    }

    return (
      <div className={styles['first-step-container']}>
        <section className={styles['input-wrapper']}>
          <img src={LabelIcon} alt="" width="18" />
          <TextInputElement
            className={styles['input-inner']}
            placeholder="Your Event Name"
            onChange={event => this.handleChange('name', event)}
            valid
            value={eventForm.name}
            maxLength={50}
          />
          <img
            className={cn({ [styles['icon-hidden']]: !eventForm.name })}
            src={CheckMarkIcon}
            width="18"
          />
        </section>

        <section className={styles['optional-text']}>optional</section>

        <section
          className={styles['input-wrapper']}
          onClick={() => this.setState({ isOpenDateModal: true })}
        >
          <img src={DateIcon} alt="" width="18" />
          <section className={styles['input-box']}>
            <TextInputElement
              className={styles['input-inner']}
              placeholder="Date & Time"
              onChange={() => {}}
              valid
              value={
                eventForm.starts_at
                  ? `${moment(eventForm.starts_at).format(
                      'ddd, MMM DD, YYYY, h:mm A'
                    )} - ${renderTimezoneAbbreviation(eventForm.timezone)}`
                  : ''
              }
              readOnly
            />
            {eventForm.ends_at && (
              <TextInputElement
                className={styles['input-inner']}
                placeholder="Date & Time"
                onChange={() => {}}
                valid
                value={`${moment(eventForm.ends_at).format(
                  'ddd, MMM DD, YYYY, h:mm A'
                )} - ${renderTimezoneAbbreviation(eventForm.timezone)}`}
                readOnly
              />
            )}
          </section>
        </section>
        {/* trigger for desktop view */}
        <section
          className={cn(styles['input-wrapper'], styles['desktop'])}
          onClick={() => this.setState({ isLocationSearchModalShown: true })}
        >
          <img src={LocationIcon} alt="" width="18" />
          <div className={styles['input-box']}>
            <TextInputElement
              className={styles['input-inner']}
              placeholder="Location"
              valid
              readOnly
              value={displayLocation}
            />
          </div>
        </section>
        {/* trigger for mobile web view */}
        <section
          className={cn(styles['input-wrapper'], styles['mobile-web'])}
          onClick={() => this.setState({ isLocationSearchPanelShown: true })}
        >
          <img src={LocationIcon} alt="" width="18" />
          <div className={styles['input-box']}>
            <TextInputElement
              className={styles['input-inner']}
              placeholder="Location"
              valid
              readOnly
              value={displayLocation}
            />
          </div>
        </section>

        {userId && (
          <>
            <section className={cn(styles['input-wrapper'], styles['mobile-web'])}>
              <img src={HobnobSpaceIcon} alt="" width="18" />
              <HobnobSelect
                value={eventForm.external_community_id}
                options={this.getCommunitiesList()}
                onChange={value => this.handleChange('community', value)}
                inputClassName={styles['input-inner']}
                textBlank
                placeholder="Hobnob Space"
              />
            </section>

            <section className={cn(styles['input-wrapper'], 'web-view')}>
              <img src={HobnobSpaceIcon} alt="" width="18" />
              <Select
                bordered={false}
                size="small"
                className={styles['input-inner']}
                fieldNames={{ label: 'text' }}
                defaultValue={
                  eventForm.external_community_id === null ? '' : eventForm.external_community_id
                }
                options={this.getCommunitiesList()}
                onChange={value => this.handleChange('community', value)}
              />
            </section>
          </>
        )}

        <section className={styles['button-wrapper']}>
          <Button
            disabled={!eventForm.name}
            className={styles['button-inner']}
            onClick={this.handleNextStep}
          >
            <span>select design</span>
            <img src={RightIconWhite} alt="" width="24" />
          </Button>
        </section>

        <DateModal
          visible={this.state.isOpenDateModal}
          onClose={() => this.setState({ isOpenDateModal: false })}
        />

        <HobnobModal
          show={this.state.isLocationSearchModalShown}
          onClose={() => this.setState({ isLocationSearchModalShown: false })}
        >
          <LocationAutoCompleteInput
            onLocationSelected={result => {
              this.onLocationSelected(result)
            }}
          />
        </HobnobModal>

        {this.state.isLocationSearchPanelShown && (
          <section className={cn(styles['location-search-panel'])}>
            <LocationAutoCompleteInput
              onLocationSelected={result => {
                this.setState({ isLocationSearchPanelShown: false })
                this.onLocationSelected(result)
              }}
              onCancelClicked={() => {
                this.setState({ isLocationSearchPanelShown: false })
              }}
            />
          </section>
        )}
      </div>
    )
  }
}

FirstStep.propTypes = {
  userId: PropTypes.string,
  onButtonClick: PropTypes.func,
  onChangeEventForm: PropTypes.func,
  eventForm: PropTypes.object,
  onCreateEvent: PropTypes.func,
  createApplePlace: PropTypes.func,
  communityMemberList: PropTypes.array
}

function mapStateToProps(state) {
  return {
    userId: state.userId,
    eventForm: state.frontend.eventForm,
    communityMemberList: state.frontend.communityMemberList
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeEventForm: params => dispatch(changeEventForm(params)),
    onCreateEvent: params => dispatch(fetchCreateEvent(params)),
    createApplePlace: data => dispatch(createApplePlace(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstStep)
