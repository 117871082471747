import React, { Component } from 'react'
import GuestsListPageMobile from './GuestsListPageMobile/GuestsListPageMobileV2'
import compWithGuestsList from './HOC/WithGuestsListV2'
import GuestsListPageWeb from './GuestsListPageWeb/GuestsListPageWebV2'
import { isMobile } from 'services/browser_detection'

const GuestsListPageMobileHOC = compWithGuestsList(GuestsListPageMobile)
const GuestsListPageWebHOC = compWithGuestsList(GuestsListPageWeb)

class GuestsListPage extends Component {
  render() {
    if (isMobile()) {
      return (
        <div className="mobile-view">
          <GuestsListPageMobileHOC />
        </div>
      )
    }

    return <GuestsListPageWebHOC />
  }
}

export default GuestsListPage
