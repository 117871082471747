import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styles from './orders-for-evnet-container.scss'
import dateformat from 'dateformat'
import { displayCurrency } from '../../services/utils'
import SvgIcon from '../common/SvgIcon'
import RightChevron from '../../assets/icons/right_chevron_small.svg'
import TotalTicketsIcon from '../../assets/icons/total_tickets_small_icon.svg'
import { orderColors, orderStates } from '../../services/payment_statuses'
import { renderLabel, renderOrderStatus } from '../../services/payment_utils'

export default class OrdersForEventContainer extends Component {
  _renderPayment = (order, orderStatus) => {
    const compedTicket = order.tickets.every((item) => item.comped_ticket_line_item_id)
    if (!order.payment || !order.payment.amount) {
      if (compedTicket) {
        return 'Comp'
      }
      return 'Free'
    } else if (orderStatus === 'processed') {
      return displayCurrency(order.payment.amount - order.payment.platform_fee - order.payment.stripe_fee)
    } else {
      return displayCurrency(order.payment.amount)
    }
  }

  render () {
    const { ordersWithCounts, onClickItem } = this.props

    return (
      <ul>
        {
          ordersWithCounts.map((order) => {
            const orderStatus = renderOrderStatus(order.refund_requests, '')
            return (
              <li className={styles['order-list']} key={order.id}
                onClick={() => onClickItem(order)}>
                <div className={styles['order-counts-wrapper']}>
                  <SvgIcon icon={TotalTicketsIcon} className={styles['total-tickets-icon']}
                    divClassName={styles['total-tickets-icon-box']} />
                  <div>
                    <div
                      className={styles['ticket-label']}>{renderLabel(order.adultTicketCounts, order.childTicketCounts)}</div>
                    <div className={styles['order-inserted-at']}>{dateformat(order.inserted_at, 'mmm. dd, yyyy')}</div>

                    <div className={styles['payment-status']}
                      style={{ color: orderColors[orderStatus] }}>{orderStates[orderStatus]}</div>
                  </div>

                </div>
                <div className={styles['price-wrapper']}>
                  {this._renderPayment(order, orderStatus)}

                  <SvgIcon icon={RightChevron} className={styles['right-chevron-icon']}
                    divClassName={styles['right-chevron-icon-box']} />
                </div>
              </li>
            )
          })
        }
      </ul>
    )
  }
}

OrdersForEventContainer.propTypes = {
  ordersWithCounts: PropTypes.array,
  onClickItem: PropTypes.func
}
