import React from 'react'
import { useSelector } from 'react-redux'
import { eventSelector, eventModalSelector, chatSelector } from 'src/selectors'

import EventInformation from './EventInformation'
import EventChat from './EventChat/EventChat'
import GuestsListPage from './GuestsListPage/GuestsListPage'
import MediaPage from '../../../../MediaPage'
import OrdersPage from '../../../../OrdersPage'
import OrderDetailPage from '../../../../OrderDetailPage'
import RequestRefundPage from '../../../../RequestRefundPage'
import AddGuestsPage from './AddGuestsPage/AddGuestsPageContainer'
import OpenInAppModal from 'src/components/OpenInAppModal'

const EventSection = () => {
  const event = useSelector(eventSelector)
  const eventModal = useSelector(eventModalSelector)
  const { socketInstance } = useSelector(chatSelector)

  const getCurComponent = () => {
    if (eventModal === 'chat' && !socketInstance) {
      return <EventInformation />
    }

    const components = {
      details: <EventInformation />,
      guests: <GuestsListPage />,
      media: <MediaPage />,
      chat: <EventChat />,
      orders: <OrdersPage />,
      orderDetail: <OrderDetailPage />,
      requestRefund: <RequestRefundPage />,
      addGuests: <AddGuestsPage />
    }
    let curComponent = components[eventModal]

    return curComponent || components['details']
  }

  return (
    <div style={{ height: '100%', overflow: 'hidden' }}>
      {getCurComponent()}
      <OpenInAppModal event={event} />
    </div>
  )
}

export default EventSection
