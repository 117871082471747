import { delay } from 'redux-saga'
import { call, fork, take, put, race, select, spawn } from 'redux-saga/effects'

import {
  invitationSelector,
  userSelector
} from 'src/selectors'

import {
  SHOW_RSVP_MODAL,
  HIDE_RSVP_MODAL
} from 'src/actions/frontend'

import {
  REQUEST_ACCEPT_INVITATION,
  REQUEST_DECLINE_INVITATION
} from 'src/actions/invitations'

import {
  SHOW_TOAST_MESSAGE,
  showToastMessage,
  hideToastMessages
} from 'src/actions/toastMessages'

import { createWatcher } from '../utils'
import pusherRootSaga from './pusher'

export default function * toastMessagesRootSaga () {
  yield spawn(pusherRootSaga)

  yield fork(createWatcher(SHOW_TOAST_MESSAGE, hideToastMessageWorker))
  yield fork(watchRsvpSaga)
}

// Hides the toast message worker after it is done being seen by the user
function * hideToastMessageWorker (action) {
  const { duration } = action

  const { finished } = yield race({
    finished: call(delay, duration),
    newToastMessage: take(SHOW_TOAST_MESSAGE)
  })

  if (finished) {
    yield put(hideToastMessages())
  }
  // if it's not finished than another worker will hide the toast messages
}

// Show the user their RSVP after they finish RSVP'ing
function * watchRsvpSaga () {
  while (true) {
    const rsvpAction = yield take(SHOW_RSVP_MODAL)
    let accepted = null
    if (rsvpAction.changeRsvp) {
      const requestAction = yield take([REQUEST_ACCEPT_INVITATION, REQUEST_DECLINE_INVITATION, HIDE_RSVP_MODAL])
      switch (requestAction.type) {
        case HIDE_RSVP_MODAL:
          continue
        case REQUEST_ACCEPT_INVITATION:
          accepted = true
          break
        case REQUEST_DECLINE_INVITATION:
          accepted = false
          break
      }

      const hideRsvpAction = yield take(HIDE_RSVP_MODAL)
      if (!hideRsvpAction.canceled) {
        yield call(showRsvpToastMessage, accepted)
      }
    } else {
      const hideRsvpAction = yield take(HIDE_RSVP_MODAL)
      const invitation = yield select(invitationSelector)
      if (!hideRsvpAction.canceled) {
        yield call(showRsvpToastMessage, invitation.rsvp_state === 'accepted')
      }
    }
  }
}

function * showRsvpToastMessage (accepted) {
  const user = yield select(userSelector)
  if (user) {
    const messageType = accepted ? 'self-rsvp-yes' : 'self-rsvp-no'
    yield put(showToastMessage({ user, messageType }))
  }
}
