import { take, put } from 'redux-saga/effects'

import { flatten } from 'services/utils'

// Take an object that maps types to arrays of actions. Wait for one of each action array to finish, then return results mapped to their types
// takeAllByType({invitation: [SUCCESS, ERROR], event: [FETCH_EVENT_SUCCESS]})
// returns: {invitation: invitation_result, event: event_result}
export function * takeAll (actionsByType) {
  let unfoundActionsByType = { ...actionsByType }
  let foundResultsByType = {}

  while (Object.keys(unfoundActionsByType).length > 0) {
    const action = yield take(flatten(Object.values(unfoundActionsByType)))
    const keys = Object.keys(unfoundActionsByType)
    const key = keys.filter((key) => {
      return unfoundActionsByType[key].indexOf(action.type) > -1
    })[0]

    foundResultsByType[key] = action
    delete unfoundActionsByType[key]
  }

  return foundResultsByType
}

export function * putAll (actions) {
  if (!actions) return
  for (let i = 0; i < actions.length; i++) {
    yield put(actions[i])
  }
}
