import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'
import styles from './redirect.scss'
import { StripeCheckoutBroadcastChannelKey } from '../../../actions/stripeCheckout'
import { fetchUserGQ } from '../../../actions/user'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getUTMDataByKey } from '../../../services/stripe_checkout_configs'
import Button from '../../common/Button'

const POLLING_INTERVAL_IN_MS = 2000
const MAX_POLLING_COUNT = 10
let pollingCount = 0
let isRequesting = false

const StripeRedirect = props => {
  let pollingIntervalHandle

  const { loadUserGQ } = props

  const [hasError, setHasError] = useState(false)
  const [timeout, setTimeout] = useState(false)
  const [count, setCount] = useState(0)

  const stopPolling = () => {
    clearInterval(pollingIntervalHandle)
    pollingIntervalHandle = null
  }

  const getUTMData = () => {
    const currentLocation = new URL(window.location)
    const utmCode = currentLocation.searchParams.get('utm_content')
    if (utmCode) {
      return getUTMDataByKey(utmCode)
    } else {
      return null
    }
  }

  const postMessageToMasterTab = data => {
    localStorage.setItem(StripeCheckoutBroadcastChannelKey, JSON.stringify(data))

    stopPolling()
  }

  const loadUserGQCallback = resp => {
    isRequesting = false
    const utmData = getUTMData()
    const user = resp?.data?.me
    const message = {
      planUpdated: false,
      creditsUpdated: false,
      hasError: false
    }

    if (user && utmData) {
      const currentSub = user.current_subscription
      if (currentSub) {
        message.planUpdated = currentSub.plan.slug !== utmData?.planSlug
        message.creditsUpdated =
          currentSub.available_credit?.toString() !== utmData?.credits?.toString()
      }
      if (message.planUpdated || message.creditsUpdated) {
        postMessageToMasterTab(message)
      }
    } else {
      setHasError(true)
      message.hasError = true
      postMessageToMasterTab(message)
    }
  }

  const checkUpdate = () => {
    isRequesting = true
    loadUserGQ(loadUserGQCallback)
  }
  const setupPollingForUpdate = () => {
    if (!pollingIntervalHandle) {
      pollingIntervalHandle = setInterval(() => {
        setCount(count + 1)
        if (isRequesting) return
        if (pollingCount >= MAX_POLLING_COUNT) {
          setTimeout(true)
          const message = {
            planUpdated: false,
            creditsUpdated: false,
            hasError: true
          }
          postMessageToMasterTab(message)
        } else {
          if (!hasError) {
            pollingCount++
            checkUpdate()
          }
        }
      }, POLLING_INTERVAL_IN_MS)
    }
  }

  useEffect(() => {
    setupPollingForUpdate()
    return function cleanup() {
      clearInterval(pollingIntervalHandle)
    }
  }, [])

  const canRenderLoading = !hasError && !timeout

  return (
    <>
      {!canRenderLoading && (
        <main className={styles['main-container']}>
          <p>Still waiting? You can Refresh the page or Email us.</p>
          <Button
            onClick={() => {
              window.location.reload()
            }}
          >
            Refresh
          </Button>
          <a href="mailto:help@hobnob.io">Email Us</a>
        </main>
      )}
      {canRenderLoading && (
        <main className={styles['main-container']}>
          <p>We're processing your payment. Please wait a moment.</p>
          <Spin size="large" />
        </main>
      )}
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    loadUserGQ: callback => {
      dispatch(fetchUserGQ(callback))
    }
  }
}

StripeRedirect.propTypes = {
  loadUserGQ: PropTypes.func
}

export default connect(null, mapDispatchToProps)(StripeRedirect)
