import { combineReducers } from 'redux'

import {
  LOGOUT,
  EVENT_LOGOUT
} from 'src/actions/login'

import {
  GET_CLIENT_CREDENTIALS_SUCCESS,
  GET_INVITATION_CREDENTIALS_SUCCESS,
  PUT_CLIENT_CREDENTIALS
} from 'src/actions/accessTokens'

const clientCredentialsDefaultState = {
  accessToken: '',
  expiresAt: null
}
const clientCredentials = (state = clientCredentialsDefaultState, action) => {
  switch (action.type) {
    case GET_CLIENT_CREDENTIALS_SUCCESS: {
      const now = new Date()
      const expiresMs = now.getTime() + action.response.expires_in * 1000
      return {
        accessToken: action.response.access_token,
        expiresAt: new Date(expiresMs).toString()
      }
    }
    case PUT_CLIENT_CREDENTIALS: {
      return {
        accessToken: action.accessToken,
        expiresAt: action.expiresAt
      }
    }
    default:
      return state
  }
}

const invitationCredentialsDefaultState = {
  accessToken: '',
  expiresAt: null
}
const invitationCredentials = (state = invitationCredentialsDefaultState, action) => {
  switch (action.type) {
    case GET_INVITATION_CREDENTIALS_SUCCESS: {
      const now = new Date()
      const expiresMs = now.getTime() + action.response.expires_in * 1000
      return {
        accessToken: action.response.access_token,
        refreshToken: action.response.refresh_token,
        scope: action.response.scope,
        expiresAt: new Date(expiresMs).toString()
      }
    }
    case LOGOUT:
    case EVENT_LOGOUT:
      return invitationCredentialsDefaultState
      // NOTE: If we add a way for invitation credential users to login then we should clear the credentials here

    default:
      return state
  }
}

export default combineReducers({
  clientCredentials,
  invitationCredentials
})
