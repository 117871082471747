import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { routerActions } from 'react-router-redux'

import {
  activeLinksSelector,
  eventSelector,
  eventTokenSelector,
  hasPaidTicketsSelector,
  hostSelector,
  invitationTokenSelector,
  isHostOrCohostSelector,
  mapModalVisibleSelector,
  routeSelector,
  ticketTypesSelector,
  destinationTypeSelector
} from 'src/selectors'
import { hasUserAccessToken } from 'services/access_tokens'

import { hideMapModal, showHostMessageModal } from 'src/actions/frontend'

import { hostFullName } from 'src/services/event_helpers'

import { androidObject, isHobnobAndroidClient } from 'src/services/utils'
import { ticketTypeHasDescription } from 'src/services/payment_utils'

// import InvitationDetailsContainer from 'src/components/details/InvitationDetailsContainer'
import EventRSVPSection from './EventRSVPSection/EventRSVPSection'
import EventDetails from 'src/components/details/EventDetails'
import JoinMeetingSection from 'src/components/details/JoinMeetingSection'
import EventUpdates from './EventUpdates/EventUpdates'
import EventLinksContainer from 'src/components/details/EventLinksContainer'
import MapModal from 'src/components/details/MapModal'
import GuestChatPhotoCell from './GuestChatPhotoCell/GuestChatPhotoCell'
import LegalNotice from 'src/components/details/LegalNotice'
import Flier from 'components/details/Flier'
import FloatingButtonContainer from 'components/common/FloatingButtonContainer'
import HostOptions from './HostOptions/HostOptions'
import SignUpListContainer from 'components/details/SignUpListContainer/SignUpListContainer'
import styles from './event-information.scss'
import EventPayment from 'components/details/EventPayment'
import { messageContainerRequest } from 'src/actions/graphql2'
import { fetchEvent } from 'src/actions/event'

const EventInformation = () => {
  const dispatch = useDispatch()
  const event = useSelector(eventSelector)
  const eventToken = useSelector(eventTokenSelector)
  const isAnnouncement = event.event_type === 'announcement'
  const host = useSelector(hostSelector)
  const invitationToken = useSelector(invitationTokenSelector)
  const isHostOrCohost = useSelector(isHostOrCohostSelector)
  const mapModalVisible = useSelector(mapModalVisibleSelector)
  const ticketTypes = useSelector(ticketTypesSelector) || []
  const destinationType = useSelector(destinationTypeSelector)
  const route = useSelector(routeSelector)
  const isPaidEvent = useSelector(hasPaidTicketsSelector)
  const links = useSelector(activeLinksSelector)

  useEffect(() => {
    dispatch(messageContainerRequest('Event', event.id))
    dispatch(fetchEvent(eventToken))
  }, [])

  const _onCloseMapModal = useCallback(() => {
    let url = `/${destinationType}/${eventToken}/`

    if (invitationToken) {
      url += `${invitationToken}/`
    }
    dispatch(routerActions.replace(url))
    dispatch(hideMapModal())
  }, [destinationType, eventToken, invitationToken, dispatch])

  const _onMessageHostClick = useCallback(() => {
    const data = {
      title: 'To Chat, Get the App',
      subTitle: `Join ${hostFullName(event, host)} on the Hobnob app to send a message.`
    }

    if (isHobnobAndroidClient()) {
      androidObject().launchChatActivity(host.id)
    } else {
      dispatch(showHostMessageModal(data))
    }
  }, [dispatch, event, host])

  const _showMessageHostButton = () => {
    return !isHostOrCohost
  }

  const _scrollTo = ref => {
    if (route === '/event_links' || route === '/join_meeting') {
      window.scrollTo(0, ref.offsetTop)
    }
  }

  const _renderEventPayments = () => {
    const showTicketTypes = isPaidEvent || ticketTypeHasDescription(ticketTypes)
    if (!showTicketTypes) return null

    return (
      <div>
        <EventPayment ticketTypes={ticketTypes} event={event} />
      </div>
    )
  }

  return (
    <div className={styles['container']}>
      <div className={styles['event-detail-flier']}>
        <Flier event={event} />
      </div>
      <EventRSVPSection />
      <JoinMeetingSection event={event} scrollTo={_scrollTo} />

      {event.ticketing_enabled_at &&
        !!ticketTypes &&
        !!ticketTypes.length &&
        !isAnnouncement &&
        _renderEventPayments()}

      {event.lists?.length > 0 && isHostOrCohost && <SignUpListContainer />}

      {event.description || _showMessageHostButton() ? (
        <EventDetails
          event={event}
          isAnnouncement={isAnnouncement}
          onMessageHostClick={_onMessageHostClick}
          showMessageHostButton={!isHostOrCohost}
        />
      ) : null}

      {!isAnnouncement && <GuestChatPhotoCell />}

      <EventUpdates />

      {links.length > 0 && <EventLinksContainer scrollTo={_scrollTo} />}

      {isHostOrCohost && hasUserAccessToken() && <HostOptions />}

      <div className={styles['legal-notice-container']}>
        {!isHobnobAndroidClient() && <LegalNotice />}
      </div>
      {!isAnnouncement && (
        <FloatingButtonContainer pageName="Details Page" style={{ bottom: '105px' }} />
      )}
      <MapModal
        customVenueName={event.custom_venue_name}
        venue={event.venue}
        onClose={_onCloseMapModal}
        show={mapModalVisible}
      />
    </div>
  )
}

export default EventInformation
