import { flyerDesignLayoutConfig } from '../assets/frontend-json/flyer-position'

export class Design {
  layout
  background

  constructor(flyerDesign, eventData) {
    this.designNumber = flyerDesign.design_number
    this.designSlug = flyerDesign.design_slug
    const LayoutClass = this.getLayoutClassBySlug(flyerDesign.layout_slug)
    if (LayoutClass) {
      this.layout = new LayoutClass(flyerDesign, eventData)
    }
  }

  getLayoutClassBySlug(slug) {
    const found = flyerDesignLayoutConfig.find(layout => layout.slug === slug)
    if (found) {
      return found.layout
    } else {
      return null
    }
  }
}
