import React, { Component } from 'react'
import { fetchPollList } from 'src/actions/timepoll'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// import CheckBoxInput from 'components/forms/CheckboxInput'
import { userSelector } from 'src/selectors'
// import Button from 'components/common/Button'
import LoadingComponent from 'components/common/LoadingComponent/LoadingComponent'

import styles from './sul-list.scss'

class SULList extends Component {
  state = {
    loading: true,
    sulList: [],
    addSulList: []
  }

  componentDidMount() {
    if (!this.props.user) return
    this.props.onGetSULList({ status: 'all', pollType: 'item', onSuccessCallback: this.getSULList })
  }

  handleAddSUL = () => {
    this.props.onChange('sul', this.state.addSulList)
  }

  handleChecked = index => {
    let { sulList, addSulList } = this.state
    const sul = sulList[index]
    sulList[index].isChecked = !sul.isChecked
    if (sulList[index].isChecked) {
      addSulList.push(sulList[index])
    } else {
      addSulList = addSulList.filter(item => item.id !== sul.id)
    }

    this.setState({ sulList, addSulList })
  }

  getSULList = data => {
    const { user } = this.props
    const list = data.data.poll_list.result

    const sulList = list
      .filter(item => {
        if (item.creator.id === user.id && item.status === 'active' && !item.event_id) {
          return true
        }
        return false
      })
      .map(item => {
        return { ...item, isChecked: false }
      })

    this.setState({
      sulList,
      loading: false
    })
  }

  render() {
    const { loading, sulList } = this.state
    const { onChange } = this.props

    if (loading) {
      return (
        <div className={styles['sul-container']}>
          <LoadingComponent loading light type="partial" />
        </div>
      )
    }

    return (
      <div className={styles['sul-container']}>
        <div className={styles['list-title']}>Existing Sign Up Lists</div>
        <ul className={styles['ul-container']}>
          {sulList.map((item, index) => (
            <li className={styles['li-container']} key={index} onClick={() => onChange(item)}>
              <div className={styles['sul-name']}>
                <div className={styles['name']}>{item.name}</div>
                <div className={styles['notes']}>{item.notes}</div>
              </div>

              {/* <CheckBoxInput
                size={24}
                checked={item.isChecked}
                onChange={() => this.handleChecked(index)}
              /> */}
            </li>
          ))}
        </ul>
        {/* <div className={styles['button-wrapper']}>
          <Button className={styles['button-inner']} onClick={this.handleAddSUL}>
            <span>save</span>
          </Button>
        </div> */}
      </div>
    )
  }
}

SULList.propTypes = {
  onGetSULList: PropTypes.func,
  user: PropTypes.object,
  onChange: PropTypes.func
}

function mapDispatchToProps(dispatch) {
  return {
    onGetSULList: params => dispatch(fetchPollList(params))
  }
}

function mapStateToProps(state) {
  return {
    user: userSelector(state)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SULList)
