import React, { Component } from 'react'
import DashboardContainer from 'containers/DashboardContainer'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import FirstStep from './FirstStep/FirstStep'
import SecondStep from './SecondStep/SecondStep'
import ThirdStep from './ThirdStep/ThirdStep'
import DraftStep from './DraftStep/DraftStepV2'
import SettingWrapper from './SettingWrapper/SettingWrapper'
import { changeSignUpForm, resetSignUpForm } from 'src/actions/timepoll'

import BackIcon from 'assets/icons/back_button.png'
import SettingIcon from 'assets/icons/setting-icon.png'

import styles from './create-sign-up.scss'

const stepList = [
  { title: 'create sign up list', step: 1 },
  { title: 'select an image', step: 2 },
  { title: 'slot/item', step: 3 }
]

class CreateSignUp extends Component {
  constructor(props) {
    super(props)

    let currentStep = 1
    if (sessionStorage.getItem('signUpForm')) {
      props.onChangeSignUpForm(JSON.parse(sessionStorage.getItem('signUpForm')))
      currentStep = 4
    } else {
      props.onResetSignUpForm()
    }
    this.state = {
      currentStep,
      isOpenSetting: false
    }
  }

  handleClickBack = () => {
    const { currentStep } = this.state

    if (currentStep === 1) {
      this.props.goBack()
    }
    if (currentStep === 2) {
      this.setState({ currentStep: 1, isOpenSetting: false })
    } else if (currentStep === 3) {
      this.setState({ currentStep: 2, isOpenSetting: false })
    } else if (currentStep === 4) {
      this.setState({ currentStep: 3, isOpenSetting: false })
    }
  }

  handleClickNext = () => {
    const { currentStep } = this.state
    if (currentStep === 1) {
      this.setState({ currentStep: 2 })
    } else if (currentStep === 2) {
      this.setState({ currentStep: 3 })
    } else if (currentStep === 3) {
      this.setState({ currentStep: 4, isOpenSetting: false })
    }
  }

  renderRightButton = () => {
    const { currentStep } = this.state
    const { goSignUpEdit } = this.props

    if (currentStep === 3) {
      return (
        <img src={SettingIcon} width="20" onClick={() => this.setState({ isOpenSetting: true })} />
      )
    } else if (currentStep === 4) {
      return (
        <span style={{ color: '#2cd4ae' }} onClick={goSignUpEdit}>
          Edit
        </span>
      )
    }
  }

  render() {
    const { currentStep, isOpenSetting } = this.state
    const step = stepList.find(item => item.step === currentStep)
    const isDraftStep = currentStep === 4

    return (
      <DashboardContainer
        title={isDraftStep ? 'Draft Sign Up' : step?.title}
        left={
          <img
            src={BackIcon}
            onClick={this.handleClickBack}
            className={styles['back-icon']}
            alt="back button"
            width="14"
          />
        }
        right={this.renderRightButton()}
      >
        <div className={styles['create-sign-up-container']}>
          {!isDraftStep && (
            <div className={styles['back-button']} onClick={this.handleClickBack}>
              <img src={BackIcon} alt="back button" width="12" />
              <span>back</span>
            </div>
          )}

          <div
            className={cn(styles['create-progress-wrapper'], {
              [styles['create-step-hidden']]: currentStep >= 4
            })}
          >
            <div className={styles['create-header-wrapper']}>
              <h1>{step?.title}</h1>
              {currentStep === 3 && (
                <div
                  className={styles['setting-label-wrapper']}
                  onClick={() => this.setState({ isOpenSetting: true })}
                >
                  <img src={SettingIcon} width="20" />
                  <span>settings</span>
                </div>
              )}
            </div>

            <ul className={styles['progress-wrapper']}>
              {stepList.map(item => (
                <li
                  key={item.step}
                  className={cn(styles['progress-item'], {
                    [styles['progress-item-checked']]: currentStep >= item.step
                  })}
                />
              ))}
            </ul>

            <div
              className={cn(styles['create-step-wrapper'], {
                [styles['create-step-hidden']]: currentStep !== 1
              })}
            >
              <FirstStep onButtonClick={this.handleClickNext} />
            </div>

            {currentStep === 2 && (
              <div className={styles['create-step-wrapper']}>
                <SecondStep onButtonClick={this.handleClickNext} />
              </div>
            )}

            <div
              className={cn(styles['create-step-wrapper'], {
                [styles['create-step-hidden']]: currentStep !== 3
              })}
            >
              <ThirdStep onButtonClick={this.handleClickNext} />
            </div>
          </div>

          {currentStep === 4 && <DraftStep onClickBack={this.handleClickBack} />}
          {isOpenSetting && (
            <div className={styles['setting-wrapper']}>
              <SettingWrapper onClose={() => this.setState({ isOpenSetting: false })} />
            </div>
          )}
        </div>
      </DashboardContainer>
    )
  }
}

CreateSignUp.propTypes = {
  goBack: PropTypes.func,
  onChangeSignUpForm: PropTypes.func,
  onResetSignUpForm: PropTypes.func,
  goSignUpEdit: PropTypes.func
}

function mapDispatchToProps(dispatch) {
  return {
    goBack: () => dispatch(routerActions.goBack()),
    onChangeSignUpForm: params => dispatch(changeSignUpForm(params)),
    onResetSignUpForm: () => dispatch(resetSignUpForm()),
    goSignUpEdit: () => dispatch(routerActions.push('/su/edit'))
  }
}

export default connect(null, mapDispatchToProps)(CreateSignUp)
