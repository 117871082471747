import React, { Component } from 'react'
import styles from './pop-confirm.scss'
import HobnobModal from 'components/common/HobnobModal'
import PropTypes from 'prop-types'

class PopConfirm extends Component {
  render() {
    const { visible, onClose, onConfirm, confirmText, cancelText, type, cancelType } = this.props

    return (
      <HobnobModal
        visibleButton
        closeOnOuterClick={false}
        show={visible}
        onClose={onClose}
        buttonContainerClassName={styles['button-wrapper']}
        onConfirm={onConfirm}
        cancelText={cancelText}
        confirmText={confirmText}
        cancelClassName={styles[`${cancelType}-button`]}
        confirmClassName={styles[`${type}-button`]}
      >
        <div className={styles['content-container']}>{this.props.children}</div>
      </HobnobModal>
    )
  }
}

PopConfirm.defaultProps = {
  cancelText: 'Cancel',
  confirmText: 'Confirm',
  type: 'normal',
  cancelType: 'cancel'
}

PopConfirm.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  children: PropTypes.node,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  type: PropTypes.oneOf(['delete', 'normal', 'cancel']),
  cancelType: PropTypes.oneOf(['delete', 'normal', 'cancel'])
}

export default PopConfirm
