import { fetchApi } from './api'

import {
  EventComment
} from 'src/schemas'

export const CHAT_RECEIVED = 'CHAT_RECEIVED'

export const ADD_TEMP_CHAT_COMMENT = 'ADD_TEMP_CHAT_COMMENT'
export const UPDATE_TEMP_CHAT_COMMENT_STATUS = 'UPDATE_TEMP_CHAT_COMMENT_STATUS'
export const REMOVE_TEMP_CHAT_COMMENT = 'REMOVE_TEMP_CHAT_COMMENT'

export const REQUEST_SHARE_LOCATION_IN_CHAT = 'REQUEST_SHARE_LOCATION_IN_CHAT'
export const SHARE_LOCATION_RETRIEVED = 'SHARE_LOCATION_RETRIEVED'
export const SHARE_LOCATION_DENIED = 'SHARE_LOCATION_DENIED'
export const GEOLOCATION_PERMISSION_CHANGED = 'GEOLOCATION_PERMISSION_CHANGED'

export const CHAT_REQUEST_SEND_MESSAGE = 'CHAT_REQUEST_SEND_MESSAGE'
export const CHAT_CREATE_COMMENT = 'CHAT_CREATE_COMMENT'
export const CHAT_CREATE_COMMENT_SUCCESS = 'CHAT_CREATE_COMMENT_SUCCESS'
export const CHAT_CREATE_COMMENT_ERROR = 'CHAT_CREATE_COMMENT_ERROR'

export const CHAT_REQUEST_DELETE_COMMENT = 'CHAT_REQUEST_DELETE_COMMENT'
export const CHAT_DELETE_COMMENT = 'CHAT_DELETE_COMMENT'
export const CHAT_DELETE_COMMENT_SUCCESS = 'CHAT_DELETE_COMMENT_SUCCESS'
export const CHAT_DELETE_COMMENT_ERROR = 'CHAT_DELETE_COMMENT_ERROR'

export const REQUEST_XMPP_UPLOAD = 'REQUEST_XMPP_UPLOAD'

export const CREATE_REMOTE_XMPP_UPLOAD = 'CREATE_REMOTE_XMPP_UPLOAD'
export const CREATE_REMOTE_XMPP_UPLOAD_SUCCESS = 'CREATE_REMOTE_XMPP_UPLOAD_SUCCESS'
export const CREATE_REMOTE_XMPP_UPLOAD_ERROR = 'CREATE_REMOTE_XMPP_UPLOAD_ERROR'

export const XMPP_UPLOAD_CALLBACK = 'XMPP_UPLOAD_CALLBACK '
export const XMPP_UPLOAD_CALLBACK_SUCCESS = 'XMPP_UPLOAD_CALLBACK_SUCCESS'
export const XMPP_UPLOAD_CALLBACK_ERROR = 'XMPP_UPLOAD_CALLBACK_ERROR'

export const SHOW_CHAT_SETTINGS_MODAL = 'SHOW_CHAT_SETTINGS_MODAL'
export const HIDE_CHAT_SETTINGS_MODAL = 'HIDE_CHAT_SETTINGS_MODAL'

export function addTempChatComment (comment) {
  return {
    type: ADD_TEMP_CHAT_COMMENT,
    comment
  }
}

export function updateTempChatCommentStatus (id, status) {
  return {
    type: UPDATE_TEMP_CHAT_COMMENT_STATUS,
    id,
    status
  }
}

export function removeTempChatComment (id) {
  return {
    type: REMOVE_TEMP_CHAT_COMMENT,
    id
  }
}

export function requestShareLocationInChat () {
  return {
    type: REQUEST_SHARE_LOCATION_IN_CHAT
  }
}

export function shareLocationRetrieved () {
  return {
    type: SHARE_LOCATION_RETRIEVED
  }
}

export function shareLocationDenied () {
  return {
    type: SHARE_LOCATION_DENIED
  }
}

export function geolocationPermissionChanged (permission_state) {
  return {
    type: GEOLOCATION_PERMISSION_CHANGED,
    permission_state
  }
}

// Tell the saga to create the message
export function chatRequestSendMessage (content, metadata = {}) {
  return {
    type: CHAT_REQUEST_SEND_MESSAGE,
    content,
    metadata
  }
}

export function chatCreateComment (comment, eventId, method = 'POST') {
  return fetchApi({
    requestType: CHAT_CREATE_COMMENT,
    successType: CHAT_CREATE_COMMENT_SUCCESS,
    errorType: CHAT_CREATE_COMMENT_ERROR,
    payload: { comment },
    route: `/events/${eventId}/comments${method === 'PUT' ? `/${comment.id}` : ''}`,
    schema: {
      comment: EventComment
    },
    options: {
      method,
      body: JSON.stringify({
        comment: {
          content: comment.content,
          metadata: comment.metadata
        }
      })
    }
  })
}

export function chatRequestDeleteComment (comment) {
  return {
    type: CHAT_REQUEST_DELETE_COMMENT,
    comment
  }
}

export function chatDeleteComment (eventId, comment) {
  return fetchApi({
    requestType: CHAT_DELETE_COMMENT,
    successType: CHAT_DELETE_COMMENT_SUCCESS,
    errorType: CHAT_DELETE_COMMENT_ERROR,
    route: `/events/${eventId}/comments/${comment.id}`,
    payload: { comment },
    options: {
      method: 'DELETE',
      body: JSON.stringify({
        comment: {
          last_updated_at: comment.updated_at
        }
      })
    }
  })
}

export function requestXmppUpload (file) {
  return {
    type: REQUEST_XMPP_UPLOAD,
    file
  }
}

export function createRemoteXmppUpload (fileName, mimeType, peerId) {
  return fetchApi({
    requestType: CREATE_REMOTE_XMPP_UPLOAD,
    successType: CREATE_REMOTE_XMPP_UPLOAD_SUCCESS,
    errorType: CREATE_REMOTE_XMPP_UPLOAD_ERROR,
    route: '/xmpp/uploads',
    options: {
      method: 'POST',
      body: JSON.stringify({
        upload: {
          mime_type: mimeType,
          file_name: fileName,
          peer_id: peerId
        }
      })
    }
  })
}

export function xmppUploadCallback (url) {
  return fetchApi({
    requestType: XMPP_UPLOAD_CALLBACK,
    successType: XMPP_UPLOAD_CALLBACK_SUCCESS,
    errorType: XMPP_UPLOAD_CALLBACK_ERROR,
    route: url
  })
}

export function showChatSettingsModal () {
  return {
    type: SHOW_CHAT_SETTINGS_MODAL
  }
}
export function hideChatSettingsModal () {
  return {
    type: HIDE_CHAT_SETTINGS_MODAL
  }
}
