import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import {
  mediaSelector,
  mediumShareTooltipClickCoordinatesSelector,
  mediumShareTooltipMediumIdSelector,
  mediumShareTooltipVisibleSelector
} from 'src/selectors'

import {
  hideTooltip
} from 'src/actions/frontend'

import TooltipModal from 'src/components/common/TooltipModal'
import MediumShareTooltip from './MediumShareTooltip'

export class MediumShareTooltipModalContainer extends React.Component {
  _hideTooltip = () => this.props.dispatch(hideTooltip())

  render () {
    const { clickCoordinates, medium, visible } = this.props

    return (
      <TooltipModal
        clickCoordinates={clickCoordinates}
        onClose={this._hideTooltip}
        visible={visible}
      >
        {visible
          ? <MediumShareTooltip medium={medium} />
          : null}
      </TooltipModal>
    )
  }
}

MediumShareTooltipModalContainer.propTypes = {
  clickCoordinates: PropTypes.object,
  dispatch: PropTypes.func,
  medium: PropTypes.object,
  visible: PropTypes.bool
}

function mapStateToProps (state) {
  const media = mediaSelector(state)

  return {
    clickCoordinates: mediumShareTooltipClickCoordinatesSelector(state),
    medium: media[mediumShareTooltipMediumIdSelector(state)],
    visible: mediumShareTooltipVisibleSelector(state)
  }
}

export default connect(mapStateToProps)(MediumShareTooltipModalContainer)
