import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import LoadingIndicator from './LoadingIndicator'

import styles from './inline-loading-indicator.scss'

/*
  Inline loading indicator. Displays a centered loading indicator
*/
export default function InlineLoadingIndicator (props) {
  const { className, loading, size } = props

  return (
    <div className={classnames(className, styles['container'])}>
      {loading
        ? <LoadingIndicator size={size} />
        : null}
    </div>
  )
}

InlineLoadingIndicator.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  size: PropTypes.string
}
InlineLoadingIndicator.defaultProps = {
  size: '30px'
}
