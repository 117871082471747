import PropTypes from 'prop-types'
import classnames from 'classnames'
import React from 'react'

import styles from './button.scss'

const Button = (props) => {
  const { children, className, disabled, filled, href, purple, onClick } = props
  const buttonClass = disabled ? styles['button-disabled'] : (filled ? styles['button-filled'] : styles['button-outline'])
  const cnames = classnames(buttonClass, className, { [styles['purple']]: purple })

  return (
    <button
      className={classnames(cnames, className)}
      disabled={disabled}
      href={href}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  filled: PropTypes.bool,
  href: PropTypes.string,
  purple: PropTypes.bool,
  onClick: PropTypes.func
}

Button.defaultProps = {
  disabled: false,
  filled: true,
  purple: false
}

export default Button
