import React, { Component } from 'react'
import TextInputElement from 'components/forms/TextInputElement'
import TextAreaElement from 'components/common/ResizeableTextarea'
import Button from 'components/common/Button'
// import Switch from 'react-switch'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { connect } from 'react-redux'

import { changeEventForm } from 'src/actions/event'

// import AddIcon from 'assets/icons/add-icon.png'
// import LinkIcon from 'assets/icons/link-icon.png'
// import CloseIconBg from 'assets/icons/close-icon-bg.png'
// import AmazonLogo from 'assets/icons/amazon-logo.png'
// import BestBuyLog from 'assets/icons/bestBuy-logo.png'
// import BuyBuyBaby from 'assets/icons/buyBuyBaby-logo.png'
// import MacysLogo from 'assets/icons/macys-logo.png'
// import TargetLogo from 'assets/icons/target-logo.png'
import RightIconWhite from 'assets/icons/right-icon-white.png'

import styles from './third-step.scss'
class ThirdStep extends Component {
  state = {
    openSwitch: false,
    placeholderText: 'TODO...'
  }

  handleChange = (type, value) => {
    const { onChangeEventForm } = this.props

    switch (type) {
      case 'custom_host_name':
        if (value.length > 255) {
          return
        }
        onChangeEventForm({ custom_host_name: value })
        break
      default:
        onChangeEventForm({ [type]: value })
    }
  }

  render() {
    const { eventForm, onCreateEvent, userId } = this.props

    return (
      <div className={styles['third-step-container']}>
        <div className={styles['information-scroll']}>
          <div className={styles['information-view']}>
            <div className={styles['input-wrapper']}>
              <TextInputElement
                className={styles['input-inner']}
                onChange={event => this.handleChange('custom_host_name', event.target.value)}
                valid
                value={eventForm.custom_host_name}
                placeholder="Optional Custom Host Name"
              />
            </div>
            <div className={cn(styles['input-wrapper'], styles['textArea-wrapper'])}>
              <TextAreaElement
                className={styles['textArea-inner']}
                placeholder="Help your guests plan for your event! Tell them what to bring, where to park and anything else… 🍾🎈🎉🍰"
                onChangeComment={value => this.handleChange('description', value)}
                comment={eventForm.description}
              />
            </div>

            {/* <div className={styles['row-wrapper']}>
              <div className={styles['column-wrapper']}>
                <div className={styles['label-title']}>Collect Money From Guests</div>
                <div>Turn this on to sell tickets for your event</div>
              </div>
              <Switch
                onChange={checked => this.setState({ openSwitch: checked })}
                checked={this.state.openSwitch}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#2cd4ae"
                width={50}
                height={30}
              />
            </div>
            <div className={styles['add-link-wrapper']}>
              <img src={AddIcon} width="18" />
              <span>Add Link</span>
            </div>
            <div className={styles['row-wrapper']}>
              <div className={styles['input-wrapper']}>
                <img src={LinkIcon} width="18" />
                <TextInputElement
                  className={styles['input-inner']}
                  onChange={event => this.handleChange('eventName', event.target.value)}
                  valid
                  value={placeholderText}
                  placeholder="Optional Custom Host Name"
                />
              </div>
              <img src={CloseIconBg} width="22" />
            </div>
            <div className={styles['label-title']}>Create a new registry or wish list</div>
            <div className={styles['logo-container']}>
              <div className={styles['logo-wrapper']}>
                <img src={AmazonLogo} />
              </div>
              <div className={styles['logo-wrapper']}>
                <img src={BestBuyLog} />
              </div>
              <div className={styles['logo-wrapper']}>
                <img src={BuyBuyBaby} />
              </div>
              <div className={styles['logo-wrapper']}>
                <img src={MacysLogo} />
              </div>
              <div className={styles['logo-wrapper']}>
                <img src={TargetLogo} />
              </div>
            </div> */}
          </div>
        </div>
        <div className={styles['button-wrapper']}>
          <Button
            className={styles['button-inner']}
            onClick={() => {
              window.mixpanel.track('ADD EVENT DETAILS Clicked', {
                'flyer#': eventForm.flyer_design_number,
                user_id: userId
              })
              onCreateEvent()
            }}
          >
            <span>add event details</span>
            <img src={RightIconWhite} alt="" width="24" />
          </Button>
        </div>
      </div>
    )
  }
}

ThirdStep.propTypes = {
  userId: PropTypes.string,
  eventForm: PropTypes.object,
  onChangeEventForm: PropTypes.func,
  onCreateEvent: PropTypes.func
}

function mapStateToProps(state) {
  return {
    userId: state.userId,
    eventForm: state.frontend.eventForm
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeEventForm: params => dispatch(changeEventForm(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdStep)
