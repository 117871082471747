import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import { deleteEvent, archiveEvent } from 'src/actions/event'
import { userIdSelector } from 'src/selectors'
import MeatballLinedIcon from 'assets/icons/meatball-lined-icon.png'
import { redirectDomain } from 'services/url_helpers'
import moment from 'moment-timezone'
import { Popover } from 'antd'
import PopConfirm from 'components/common/PopConfirm/PopConfirm'

import styles from './event-card.scss'

const EventCard = props => {
  const { event, onShowDownLoad, onDeleteEventCallback } = props
  const dispatch = useDispatch()
  const userId = useSelector(userIdSelector)
  const flyerRef = useRef(null)
  let fileObserver = null

  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false)
  const [isOperatePopoverVisible, setIsOperatePopoverVisible] = useState(false)

  const handleDeleteEvent = () => {
    setIsRemoveModalVisible(false)
    dispatch(
      deleteEvent({
        eventId: event.id,
        onSuccessCallback: () => {
          onDeleteEventCallback(event.id)
        }
      })
    )
  }

  const handleArchiveEvent = () => {
    dispatch(
      archiveEvent({
        eventId: event.id,
        onSuccessCallback: () => {
          onDeleteEventCallback(event.id)
        }
      })
    )
  }

  const onLoadFlyer = () => {
    const { flyer } = event

    fileObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (flyer?.type === 'VideoFlyer') {
            const video = entry.target
            const videoSrc = video.getAttribute('data-src')
            video.setAttribute('src', videoSrc)
          } else {
            const IMG = new Image()
            let img = entry.target
            let imgSrc = img.getAttribute('data-src')
            IMG.onload = () => {
              img.setAttribute('src', imgSrc)
              observer.unobserve(entry.target)
            }
            IMG.src = imgSrc
          }
        }
      })
    })

    fileObserver.observe(flyerRef.current)
  }

  const goEventDetail = () => {
    redirectDomain(event.url)
  }

  const renderOptions = () => {
    const isCreator = userId === event.user_id
    const isActive = event.state === 'active'

    return (
      <ul className={styles['operate-list']} onClick={() => setIsOperatePopoverVisible(false)}>
        {isCreator && isActive && (
          <li
            onClick={e => {
              e.stopPropagation()
              onShowDownLoad()
            }}
          >
            Clone Event
          </li>
        )}
        {isActive && (
          <li
            onClick={e => {
              e.stopPropagation()
              handleArchiveEvent()
            }}
          >
            Archive Event
          </li>
        )}
        {isCreator && (
          <li
            className={styles['delete-text']}
            onClick={e => {
              e.stopPropagation()
              setIsRemoveModalVisible(true)
            }}
          >
            Delete Event
          </li>
        )}
      </ul>
    )
  }

  const renderFlyer = () => {
    const { flyer } = event

    if (flyer?.type === 'VideoFlyer') {
      return (
        <video
          data-src={flyer.video_upload.video_url}
          ref={flyerRef}
          loop
          muted
          playsInline
          autoPlay
        />
      )
    }

    return <img data-src={flyer?.watermarked_url} ref={flyerRef} />
  }

  const renderStatus = () => {
    const isCreator = userId === event.user_id

    if (event.state === 'draft') {
      return <div style={{ color: '#d0021b' }}>DRAFT</div>
    }

    const invitation = event.invitations.find(invitation => !invitation.destroyed_at)

    if (!isCreator && invitation) {
      if (invitation.rsvp_state !== 'undecided') {
        const isAccepted = invitation.rsvp_state === 'accepted'

        return (
          <div
            className={styles['rsvp-text']}
            style={{ color: isAccepted ? '#2cd4ae' : '#d0021b' }}
          >
            {isAccepted ? "Yes, I'm Going" : "No, Can't Go"}
          </div>
        )
      }

      return (
        <div className={styles['rsvp-button']} onClick={goEventDetail}>
          RSVP
        </div>
      )
    }
  }

  useEffect(() => {
    onLoadFlyer()

    return () => {
      if (fileObserver) {
        fileObserver.disconnect()
      }
    }
  }, [])

  return (
    <>
      <li className={styles['event-inner']} onClick={goEventDetail}>
        <div className={styles['flyer-box']}>{renderFlyer()}</div>
        <div className={styles['event-information']}>
          <div className={styles['avatar-box']}>
            <UserAvatarContainer user={event.user} size={50} />
            {renderStatus()}
          </div>
          <div className={styles['name']}>{event.name}</div>
          <div className={styles['operate-wrapper']}>
            <div className={styles['host']}>
              <div className={styles['host-name']}>
                {userId === event.user_id
                  ? 'Host: Me'
                  : `Creator: ${
                      event.custom_host_name || `${event.user.first_name} ${event.user.last_name}`
                    }`}
              </div>
              {event.starts_at && (
                <div className={styles['date']}>
                  {moment.tz(event.starts_at, event.timezone).format('MMMM D, YYYY ∙ h:mm A')}
                </div>
              )}
            </div>

            <Popover
              open={isOperatePopoverVisible}
              trigger="click"
              content={renderOptions()}
              overlayClassName={styles['operate-container']}
              onOpenChange={visible => setIsOperatePopoverVisible(visible)}
            >
              <img
                onClick={e => e.stopPropagation()}
                className={styles['operate-icon']}
                src={MeatballLinedIcon}
              />
            </Popover>
          </div>
        </div>
      </li>

      <PopConfirm
        visible={isRemoveModalVisible}
        confirmText="DELETE"
        type="delete"
        onClose={() => setIsRemoveModalVisible(false)}
        onConfirm={handleDeleteEvent}
      >
        Are you sure you want to delete this event?
        <br /> This action cannot be undone.
      </PopConfirm>
    </>
  )
}

EventCard.propTypes = {
  event: PropTypes.object,
  onDeleteEventCallback: PropTypes.func,
  onShowDownLoad: PropTypes.func
}

export default EventCard
