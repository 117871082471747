import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { changeSignUpForm } from 'src/actions/timepoll'
import moment from 'moment-timezone'
import cn from 'classnames'
import HobnobModal from 'components/common/HobnobModalV2/HobnobModal'
import { fetchEventList } from 'src/actions/event'
import EmptyPanel from 'containers/HomePage/BusinessPanel/EmptyPanel/EmptyPanel'

import styles from './event-list.scss'
class EventList extends Component {
  state = {
    eventList: []
  }

  onLoadEventList = () => {
    this.props.onFetchEventList({
      types: 'hosted_by,draft',
      onSuccessCallback: data => {
        if (data?.data?.me?.events_connection?.events && this._isMounted) {
          this.setState({
            eventList: data.data.me.events_connection.events
          })
        }
      }
    })
  }

  componentDidMount() {
    this._isMounted = true
    this.onLoadEventList()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getDate = item => {
    const { starts_at, ends_at, timezone } = item
    if (!starts_at || !ends_at) {
      return ''
    }
    if (!ends_at) {
      return moment.tz(starts_at, timezone).format('dddd, MMM Do, h:mm A')
    }

    return (
      moment.tz(starts_at, timezone).format('dddd, MMM Do, h:mm A') +
      ' - ' +
      moment.tz(ends_at, timezone).format('dddd, MMM Do, h:mm A')
    )
  }

  handleChangeEvent = item => {
    const { onChangeSignUpForm, onClose } = this.props

    onChangeSignUpForm({ checkedEvent: item })
    onClose()
  }

  renderEmpty = () => {
    return <div className={styles['empty']}>You have no events.</div>
  }

  render() {
    const { eventList } = this.state
    const { isEventModalVisible, onClose, selectedEventId } = this.props

    return (
      <HobnobModal visible={isEventModalVisible} onCancel={onClose}>
        {eventList.length === 0 ? (
          <div className={styles['event-wrapper']}>
            <EmptyPanel type="event" />
          </div>
        ) : (
          <ul className={styles['event-wrapper']}>
            <li className={styles['event-header']}>Events I'm Hosting</li>
            {eventList.map((item, index) => {
              const eventTime = this.getDate(item)
              return (
                <li
                  className={cn(styles['event-item'], {
                    [styles['event-item-checked']]: item.id === selectedEventId
                  })}
                  key={index}
                  onClick={() => this.handleChangeEvent(item)}
                >
                  <div className={styles['event-name']}>{item.name}</div>
                  {eventTime && <div className={styles['event-time']}>{eventTime}</div>}
                </li>
              )
            })}
          </ul>
        )}
      </HobnobModal>
    )
  }
}

EventList.defaultProps = {
  type: 'create'
}

EventList.propTypes = {
  selectedEventId: PropTypes.string,
  isEventModalVisible: PropTypes.bool,
  onChangeSignUpForm: PropTypes.func,
  onClose: PropTypes.func,
  onFetchEventList: PropTypes.func
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeSignUpForm: params => dispatch(changeSignUpForm(params)),
    onFetchEventList: params => dispatch(fetchEventList(params))
  }
}

export default connect(null, mapDispatchToProps)(EventList)
