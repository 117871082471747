import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router'
import { updateMenuPanel } from 'src/actions/frontend'
import {
  activeMenuPanelSelector,
  userSelector,
  planSelector,
  activityFeedSelector
} from 'src/selectors'

import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import SvgIcon from 'src/components/common/SvgIcon'
import ChatIcon from 'src/assets/icons/chat.svg'
import iosIcon from 'src/assets/icons/logo.svg'
import TPIcon from 'src/assets/icons/poll-list-icon.png'
import TPThemeIcon from 'src/assets/icons/poll-list-icon-theme.png'
import EventIcon from 'src/assets/icons/balloon-icon-gary.png'
import EventThemeIcon from 'src/assets/icons/balloon-icon.png'
import ActivityIcon from 'src/assets/icons/ico-notification.png'
import ActivityThemeIcon from 'src/assets/icons/ico-notification-theme.png'
import BetaIcon from 'src/assets/icons/beta-icon.png'
import { parseEventUrl } from 'src/services/url_helpers'
import styles from './menu-bar.scss'
import PanelContainer, { PanelSlug } from './panels/PanelContainer'
import { resetLoginForm } from 'src/actions/login'
import { hasUserAccessToken } from 'src/services/access_tokens'

import cn from 'classnames'

const MenuBar = forwardRef((_, ref) => {
  const dispatch = useDispatch()
  const [isMenuSliderVisible, setIsMenuSliderVisible] = useState(false)
  const activeMenuPanel = useSelector(activeMenuPanelSelector)
  const { isHasUnread } = useSelector(activityFeedSelector)
  const user = useSelector(userSelector)

  const { visible: isPlanModalVisible } = useSelector(planSelector)
  const { destinationType } = parseEventUrl(window.location.pathname)
  const hasAccessToken = hasUserAccessToken()

  useImperativeHandle(ref, () => ({
    onSetIsMenuSliderVisible: setIsMenuSliderVisible
  }))

  const resetMenuState = () => {
    setIsMenuSliderVisible(false)
    dispatch(updateMenuPanel(null))
    dispatch(resetLoginForm())
  }

  const handleMenuItemClick = selectedPanel => {
    if (selectedPanel === activeMenuPanel && isMenuSliderVisible) {
      resetMenuState()
    } else {
      dispatch(updateMenuPanel(selectedPanel))
      setIsMenuSliderVisible(true)
    }
  }

  const isPinTopMenu = () => {
    return isMenuSliderVisible || isPlanModalVisible
  }

  const isEventIconActive = destinationType === 'e' && !isMenuSliderVisible
  const isTimePollIconActive = destinationType === 'tp' && !isMenuSliderVisible
  const isActivityFeedIconActive = activeMenuPanel === PanelSlug.ActivityFeed && isMenuSliderVisible

  return (
    <div className={styles['menu-bar-container']}>
      <div className={cn(styles['menu-bar'], isPinTopMenu() && styles['menu-bar-zIndex'])}>
        <div className={styles['logo-center']}>
          <Link to="/home">
            <SvgIcon icon={iosIcon} className={styles['logo']} />
          </Link>
        </div>

        <Link to="/tp/timePoll" onClick={() => setIsMenuSliderVisible(false)}>
          <div className={styles['menu-bar-item']}>
            <img src={isTimePollIconActive ? TPThemeIcon : TPIcon} className={styles['icon']} />
          </div>
        </Link>
        <Link to="/e/list" onClick={() => setIsMenuSliderVisible(false)}>
          <div className={styles['menu-bar-item']}>
            <img src={isEventIconActive ? EventThemeIcon : EventIcon} className={styles['icon']} />
            <img src={BetaIcon} className={styles['beta-icon']} width="25" alt="" />
          </div>
        </Link>
        <div className={styles['menu-bar-item']}>
          <SvgIcon
            icon={ChatIcon}
            className={styles['icon']}
            padding="10px"
            onClick={() => handleMenuItemClick(PanelSlug.Chat)}
          />
        </div>
        <div
          className={styles['menu-bar-item']}
          onClick={() =>
            handleMenuItemClick(hasAccessToken ? PanelSlug.ActivityFeed : PanelSlug.Login)
          }
        >
          <img src={isActivityFeedIconActive ? ActivityThemeIcon : ActivityIcon} width={34} />
          {isHasUnread && <div className={styles['unread-dot']} />}
        </div>
        <div
          className={styles['menu-bar-item']}
          onClick={() => handleMenuItemClick(hasAccessToken ? PanelSlug.Profile : PanelSlug.Login)}
        >
          <UserAvatarContainer user={user || {}} size={34} inProfile />
        </div>
      </div>

      <PanelContainer isMenuSliderVisible={isMenuSliderVisible} onResetMenuState={resetMenuState} />
    </div>
  )
})

export default MenuBar
