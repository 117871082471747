import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import LoadingComponent from 'components/common/LoadingComponent/LoadingComponent'
import cn from 'classnames'
import styles from './lazy-loaded-image.scss'

const LazyLoadedImage = ({ src }) => {
  const [imageSrc, setImageSrc] = useState(null)
  const [isLoadedImg, setIsLoadedImg] = useState(false)
  const imgRef = useRef(null)

  useEffect(() => {
    let observer
    let didCancel = false

    if (IntersectionObserver) {
      observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
              setImageSrc(src)
              observer.unobserve(entry.target)
            }
          })
        },
        {
          threshold: 0.01,
          rootMargin: '75%'
        }
      )
      if (src) {
        observer.observe(imgRef.current)
      }
    } else {
      // Fallback for older browsers
      setImageSrc(src)
    }

    return () => {
      didCancel = true
      if (observer) {
        observer.disconnect()
      }
    }
  }, [src])

  const renderLoadingComponent = () => {
    return (
      <div className={styles['loading-container']}>
        <LoadingComponent loading type="partial" dark />
      </div>
    )
  }

  return (
    <div
      className={cn(
        styles['lazy-loaded-image-container'],
        isLoadedImg && styles['lazy-loaded-image-container-loaded']
      )}
    >
      <img
        src={imageSrc}
        alt="alt"
        ref={imgRef}
        className={styles['img-inner']}
        onLoad={() => setIsLoadedImg(true)}
      />
      {!isLoadedImg && renderLoadingComponent()}
    </div>
  )
}

LazyLoadedImage.propTypes = {
  src: PropTypes.string.isRequired
}

export default LazyLoadedImage
