import PropTypes from 'prop-types'
import React from 'react'

import { firstName } from 'src/services/user_helpers'
import { truncate } from 'src/services/utils'

import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import SvgIcon from 'src/components/common/SvgIcon'

import CancelIcon from 'src/assets/icons/cancel-icon.svg'

import styles from './toast-message.scss'

export default class ToastMessage extends React.Component {
  _firstName () {
    const { namesByUserId, message } = this.props
    return firstName(message.user, namesByUserId)
  }

  _renderAvatar () {
    const { user } = this.props.message

    return <div style={{ marginRight: '16px' }}>
      <UserAvatarContainer
        user={user}
        size={34}
        includeBorder={false}
      />
    </div>
  }

  _renderEventComment () {
    const { messageContent } = this.props.message
    const lettersToShow = 60

    return <div>
      <strong>{this._firstName()}</strong>
      &nbsp;says &ldquo;{truncate(messageContent, lettersToShow - this._firstName().length)}&rdquo;
    </div>
  }

  _renderEventUpdate () {
    const { messageContent } = this.props.message

    return <div>
      <div><strong>{this._firstName()}</strong> sent an event update</div>
      <div>
        &ldquo;{truncate(messageContent, 35)}&rdquo;
      </div>
    </div>
  }

  _renderContent () {
    const { messageContent, messageType } = this.props.message

    switch (messageType) {
      case 'event-comment':
        return this._renderEventComment()
      case 'event-update':
        return this._renderEventUpdate()
      case 'media-comment':
        return <div>
          <strong>{truncate(this._firstName(), 15)}</strong> commented on your photo!
          <br />&ldquo;{truncate(messageContent, 35)}&rdquo;
        </div>
      case 'media-like':
        return <div>
          <strong>{this._firstName()}</strong> liked your photo!
        </div>
      case 'media-upload':
        return <div>
          <strong>{this._firstName()}</strong> added a photo!
        </div>
      case 'rsvp-yes':
        return <div>
          <strong>{this._firstName()}</strong> is going!
        </div>
      case 'self-rsvp-no':
        return <div>
          <strong>Sorry you can't go!</strong>
        </div>
      case 'self-rsvp-yes':
        return <div>
          <strong>Great!</strong> You're going!
        </div>
      default:
        return ''
    }
  }

  render () {
    const { onClose } = this.props
    const { user } = this.props.message

    return (
      <div className={styles['container']}>
        {user ? this._renderAvatar() : null}
        <div className={styles['content']}>
          {this._renderContent()}
        </div>
        <SvgIcon
          className={styles['cancel-icon']}
          icon={CancelIcon}
          onClick={onClose}
          padding='16px'
        />
      </div>
    )
  }
}

ToastMessage.propTypes = {
  message: PropTypes.object.isRequired,
  namesByUserId: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
}
