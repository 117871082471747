/* global ga:false */
import PropTypes from 'prop-types'

import React from 'react'
import { connect } from 'react-redux'
import { VelocityTransitionGroup } from 'velocity-react'
import queryString from 'query-string'

import {
  branchFloatingButtonLinkSelector,
  floatingButtonVisibleSelector,
  invitationTokenSelector,
  eventTokenSelector
} from 'src/selectors'

import { hideFloatingButton } from 'src/actions/frontend'

import styles from './floating-button-container.scss'
import { isHobnobAndroidClient, isHobnobIosClient } from '../../services/utils'

export class FloatingButtonContainer extends React.Component {
  _onClose = () => this.props.dispatch(hideFloatingButton())
  _onDownloadClicked = () => {
    const { pageName } = this.props
    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'User', 'Install Button Click', pageName)
    }
  }

  _renderButton () {
    const { branchLink, children, eventToken, invitationToken } = this.props
    const params = {}
    if (eventToken) {
      params.event_token = eventToken
    }
    if (invitationToken) {
      params.invitation_token = invitationToken
    }

    return (
      <div className={styles['button-container']}>
        <span className='fb-cta'>
          <a className={styles['link']} href={`${branchLink}?${queryString.stringify(params)}`} onClick={this._onDownloadClicked}>Open in App</a>
        </span>
        {children || <span className={styles['close-button']} onClick={this._onClose}>&times;</span>}
      </div>
    )
  }

  render () {
    const { style, visible } = this.props
    if (isHobnobAndroidClient() || isHobnobIosClient()) {
      return null
    }
    return (
      <div className={styles['container']} style={style}>
        <VelocityTransitionGroup enter='transition.fadeIn' leave='transition.fadeOut'>
          { visible ? this._renderButton() : null }
        </VelocityTransitionGroup>
      </div>
    )
  }
}

FloatingButtonContainer.propTypes = {
  branchLink: PropTypes.string,
  children: PropTypes.any,
  dispatch: PropTypes.func,
  visible: PropTypes.bool,

  // Passed in
  pageName: PropTypes.string.isRequired,
  style: PropTypes.object,
  invitationToken: PropTypes.string,
  eventToken: PropTypes.string
}

function mapStateToProps (state, ownProps) {
  return {
    branchLink: ownProps.branchLink || branchFloatingButtonLinkSelector(state),
    visible: floatingButtonVisibleSelector(state),
    invitationToken: invitationTokenSelector(state),
    eventToken: eventTokenSelector(state)
  }
}

export default connect(mapStateToProps)(FloatingButtonContainer)
