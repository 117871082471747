import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import HobnobNavBar from 'components/common/HobnobNavBar'
import {
  currentOrderSelector,
  eventSelector,
  hostSelector,
  listMyOrdersForEventSelector,
  ticketTypesCountsSelector,
  ticketTypesSelector
} from '../selectors'

import styles from './order-detail-page.scss'
import UserAvatarContainer from '../components/common/UserAvatarContainer'
import { hostFullName } from '../services/event_helpers'
import Button from '../components/common/Button'
import { displayCurrency, isEmptyObject } from '../services/utils'
import { getDisplayOrderTickets, renderLabel, renderOrderStatus } from '../services/payment_utils'
import { orderColors, orderStates } from '../services/payment_statuses'
import dateformat from 'dateformat'
import { routerActions } from 'react-router-redux'
import { cancelRefundRequestByGuest, revokeTicketsMutation, setTempTicketTypesCounts } from '../actions/paymentGraphql'
import { showHud } from '../actions/frontend'
import { countBy, hasIn, maxBy } from 'lodash'

class OrderDetailPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      backPathName: '',
      cancelRefundButtonDisabled: false,
      revokeButtonDisabled: false
    }
  }

  componentWillMount() {
    const { order, onChangeRoute, listMyOrdersForEvent, ticketTypesCounts } = this.props
    if (!order || isEmptyObject(order)) {
      onChangeRoute('details')
    }
    const ordersWithCounts = getDisplayOrderTickets(listMyOrdersForEvent, ticketTypesCounts)

    const backPathName = ordersWithCounts.length > 1 ? 'orders' : 'details'

    this.setState({
      backPathName
    })
  }

  _renderAmount = (orderStatus, priceInCents) => {
    const { order } = this.props
    const compedTicket = order.tickets.every((item) => item.comped_ticket_line_item_id)
    if (!order.payment || !order.payment.amount) {
      if (compedTicket) {
        return <div className={styles['currency']}>Comp</div>
      }
      return <div className={styles['currency']}>Free</div>
    } else {
      let replaceValue = orderStatus === 'processed' ? '+' : '-'
      let currency = displayCurrency(priceInCents)
      currency = currency.replace('$', replaceValue)
      return <div>
        <span className={styles['sign']}>$</span>
        <span className={styles['currency']}>{currency}</span>
      </div>
    }
  }

  _renderTicketCountsLabel = () => {
    const { order, ticketTypes } = this.props

    const tempTickets = order.tickets.map((ticket) => {
      const ticketType = ticketTypes.find(item => item.id === ticket.ticket_type_id)
      return {
        ...ticket,
        label: ticketType.label,
        price: ticketType.price
      }
    })

    const adultTicketCounts = tempTickets.filter((item) => item.label === 'adult')
    const childTicketCounts = tempTickets.filter((item) => item.label === 'child')

    return renderLabel(adultTicketCounts, childTicketCounts)
  }

  _cancelTicket = () => {
    const { onRevokeFreeTickets, order, onShowHud, ticketTypesCounts, onSetTempTicketTypesCounts } = this.props

    const activeOrderTickets = order.tickets.filter(item => item.state === 'active')
    const ids = activeOrderTickets.map(ticket => ticket.id)

    const countByTicketTypeId = countBy(activeOrderTickets, 'ticket_type_id')

    const tempTicketTypesCounts = ticketTypesCounts.map(item => {
      const hi = hasIn(countByTicketTypeId, item.id)
      const reduceTicketTypeCount = hi ? countByTicketTypeId[item.id] : 0
      return {
        ...item,
        count: item.count - reduceTicketTypeCount
      }
    })

    onSetTempTicketTypesCounts(tempTicketTypesCounts)

    const successActions = [onShowHud('success', 'Cancel Ticket', this._hudCallBackAction), this._setRevokeButtonDisabled]

    onRevokeFreeTickets(ids, { successActions })
  }

  _requestRefund = () => {
    const { onRequestRefund } = this.props
    onRequestRefund()
  }

  _hudCallBackAction = () => {
    const { onChangeRoute } = this.props
    const { backPathName } = this.state
    onChangeRoute(backPathName)
  }

  _setRevokeButtonDisabled = () => {
    this.setState({
      revokeButtonDisabled: true
    })
  }

  _setCancelRefundButtonDisabled = () => {
    this.setState({
      cancelRefundButtonDisabled: true
    })
  }

  _cancelRefund = () => {
    const {
      onCancelRefund,
      order,
      onShowHud
    } = this.props

    const successActions = [onShowHud('success', 'Cancel Refund Requested', this._hudCallBackAction), this._setCancelRefundButtonDisabled]

    const lastRefundRequest = maxBy(order.refund_requests, function (refund) {
      return new Date(refund.updated_at)
    })
    onCancelRefund(lastRefundRequest.id, { successActions })
  }

  render() {
    const {
      host,
      event,
      order
    } = this.props
    if (!order || isEmptyObject(order)) {
      return null
    }

    const orderStatus = renderOrderStatus(order.refund_requests, 'paid')
    const {
      backPathName, revokeButtonDisabled,
      cancelRefundButtonDisabled
    } = this.state

    const priceInCents = order.payment ? (orderStatus === 'processed'
      ? order.payment.amount - order.payment.platform_fee - order.payment.stripe_fee
      : order.payment.amount) : 0
    return (
      <div>
        <HobnobNavBar backPathName={backPathName} border={false} />
        <div className={styles['container']}>
          <div className={styles['host-information-box']}>
            <UserAvatarContainer
              user={host}
              size={60}
            />
            <div className={styles['host-name']}>{hostFullName(event, host)}</div>
            <div className={styles['event-tickets']}>Event Tickets</div>
          </div>

          <div className={styles['amount-box']}>
            {this._renderAmount(orderStatus, priceInCents)}
            <div className={styles['event-name']}>For {event.name}</div>
            <div className={styles['event-name']}>{this._renderTicketCountsLabel()}</div>
            <div
              className={styles['order-inserted-at']}>{dateformat(order.inserted_at, 'mmm. dd · h:MM TT')}</div>
          </div>

          <div className={styles['button-box']}>
            {
              !order.payment &&
              <Button disabled={revokeButtonDisabled} onClick={this._cancelTicket}>cancel tickets</Button>
            }
            {
              !!order.payment && orderStatus === 'paid' &&
              <Button onClick={this._requestRefund}>request refund</Button>
            }

            {
              !!order.payment && orderStatus === 'pending' &&
              <Button disabled={cancelRefundButtonDisabled} onClick={this._cancelRefund}>cancel refund</Button>
            }

          </div>

          <div className={styles['payment-box']}>
            {
              !!order.payment && <div>
                <div className={styles['payment-status']}
                  style={{ color: orderColors[orderStatus] }}>{orderStates[orderStatus]}</div>
                <div className={styles['tip']}>Payment to {hostFullName(event, host)}</div>
                <div className={styles['tip']}>Paid from: {order.payment.payment_method_description}</div>
                <div className={styles['tip']}>Amount Paid: {displayCurrency(priceInCents)}</div>
              </div>
            }
          </div>

        </div>
      </div>
    )
  }
}

OrderDetailPage.propTypes = {
  host: PropTypes.object,
  event: PropTypes.object,
  order: PropTypes.object,
  ticketTypes: PropTypes.array,
  onRequestRefund: PropTypes.func,
  onCancelRefund: PropTypes.func,
  onRevokeFreeTickets: PropTypes.func,
  onShowHud: PropTypes.func,
  onSetTempTicketTypesCounts: PropTypes.func,
  ticketTypesCounts: PropTypes.array,
  onChangeRoute: PropTypes.func,
  listMyOrdersForEvent: PropTypes.array
}

function mapStateToProps(state) {
  return {
    order: currentOrderSelector(state),
    event: eventSelector(state),
    host: hostSelector(state),
    ticketTypes: ticketTypesSelector(state),
    ticketTypesCounts: ticketTypesCountsSelector(state),
    listMyOrdersForEvent: listMyOrdersForEventSelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onRequestRefund: () => {
      dispatch(routerActions.push('requestRefund'))
      window.scrollTo(0, 0)
    },
    onCancelRefund: (variables) =>
      dispatch(cancelRefundRequestByGuest(variables)),
    onRevokeFreeTickets: (variables) =>
      dispatch(revokeTicketsMutation(variables)),
    onShowHud: (hudType, message, callback) => dispatch(showHud(hudType, message, callback)),
    onSetTempTicketTypesCounts: (ticketCounts) => dispatch(setTempTicketTypesCounts(ticketCounts)),
    onChangeRoute: (routerPath) => {
      dispatch(routerActions.push(routerPath))
      window.scrollTo(0, 0)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailPage)
