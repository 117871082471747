import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import dateformat from 'dateformat'

import {
  renderDay,
  renderTime,
  renderTimezoneAbbreviation
} from 'src/services/date_helpers'
import { isSameDay } from 'src/services/utils'

function differenceInMinutes (dateStr) {
  const eventOffset = dateStr.match(/([+-]\d\d):(\d\d)/)
  const eventUtcOffsetHours = parseInt(eventOffset[1])
  // Negative or positive
  const sign = eventUtcOffsetHours / Math.abs(eventUtcOffsetHours)
  const eventUtcOffsetMinutes = sign * parseInt(eventOffset[2])
  const eventUtcOffsetMinutesTotal = eventUtcOffsetHours * 60 + eventUtcOffsetMinutes
  // If there is a DST change between the event time and the current time (in the user's local time zone)
  // then we need to apply the offset to the displayed event time
  const dstOffset = new Date(dateStr).getTimezoneOffset() - new Date().getTimezoneOffset()

  // getTimezoneOffset returns minutes from current locale utc (opposite of utc offset)
  const ourOffsetMinutes = -(new Date().getTimezoneOffset() + dstOffset)
  return eventUtcOffsetMinutesTotal - ourOffsetMinutes
}

export default class EventDateTime extends React.PureComponent {
  _inDifferentTimezone () {
    const { startsAt } = this.props
    if (!startsAt) return false
    return differenceInMinutes(startsAt) !== 0
  }

  _renderTime () {
    const { startsAt, endsAt } = this.props

    let startsAtDate = new Date(startsAt)
    let endsAtDate = new Date(endsAt)
    if (this._inDifferentTimezone()) {
      startsAtDate = moment(startsAtDate).add(differenceInMinutes(startsAt), 'm').toDate()
      endsAtDate = moment(endsAtDate).add(differenceInMinutes(startsAt), 'm').toDate()
    }

    if (endsAt) {
      const endsSameDay = isSameDay(startsAtDate, new Date(endsAtDate))
      if (endsSameDay) {
        return renderDay(startsAtDate) + ` - ${renderTime(endsAtDate)}`
      } else {
        return <div>
          {renderDay(startsAtDate)}
          <br />
          till {renderDay(endsAtDate)}
        </div>
      }
    } else {
      return renderDay(startsAtDate)
    }
  }

  _renderYear () {
    const { startsAt } = this.props
    let startsAtDate = new Date(startsAt)

    if (startsAtDate.getYear() !== new Date().getYear()) {
      return dateformat(startsAtDate, ' yyyy')
    } else {
      return null
    }
  }

  render () {
    const { startsAt, timezone } = this.props
    if (!startsAt) return null

    return <div>
      {this._renderTime()}
      {this._renderYear()}
      {this._inDifferentTimezone() ? ` ${renderTimezoneAbbreviation(timezone)}` : null}
    </div>
  }
}

EventDateTime.propTypes = {
  endsAt: PropTypes.string,
  startsAt: PropTypes.string,
  timezone: PropTypes.string
}
