import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import DashboardContainer from 'containers/DashboardContainer'
import Panel from 'containers/time-poll/components/Panel'
// import LoginPoll from '../components/LoginPoll'
import { PHONE_NUMBER_CONTACT_METHOD, VALID_COUNTRY_CODES } from 'src/services/contact_methods'
import { pollForm } from 'containers/time-poll/constants'

import { DESTINATION_TYPES_CONSTANTS } from 'src/constants'
import { loginFormSelector, signUpDetailSelector } from 'src/selectors'
import { parseEventUrl } from 'services/url_helpers'
import ContactMethodInputInner from 'components/LoginModal/ContactMethodInput/ContactMethodInputInner/ContactMethodInputInner'
import ConfirmationCodeInput from 'components/LoginModal/ConfirmationCodeInput/ConfirmationCodeInput'
import FullNameInput from 'components/LoginModal/FullNameInput/FullNameInput'
import Button from 'src/components/common/Button'
import LoadingComponent from 'components/common/LoadingComponent/LoadingComponent'
import { requestSubmitLogin } from 'src/actions/login'
import styles from './subscribe-container.scss'

const SubscribeContainer = ({ handleBackClick }) => {
  const dispatch = useDispatch()
  const poll = useSelector(state => state.frontend.timePoll)
  const loginForm = useSelector(loginFormSelector)
  const signUpDetail = useSelector(signUpDetailSelector)

  const getPollDetail = () => {
    const { destinationType } = parseEventUrl(window.location.pathname)
    switch (destinationType) {
      case DESTINATION_TYPES_CONSTANTS.signUp:
        return signUpDetail || {}
      case DESTINATION_TYPES_CONSTANTS.timepoll:
        return poll || {}
      default:
        return {}
    }
  }

  const formatPhoneNumber = phoneNumber => {
    const areaCode = phoneNumber.substr(1, 3)
    const prefix = phoneNumber.substr(4, 3)
    const suffix = phoneNumber.substr(7, 4)

    return `(${areaCode}) ${prefix}-${suffix}`
  }

  const getTitle = () => {
    const { destinationType } = parseEventUrl(window.location.pathname)
    const data = getPollDetail()

    const titleOption = {
      pollLogin: {
        title: 'Thank you for participating!',
        subtitle:
          'Create an account to get updates on ' +
          data?.name +
          ' and to create your own polls for free'
      },
      confirmNumber: {
        title: 'Confirm Number',
        subtitle:
          'A code was sent to ' +
          formatPhoneNumber(loginForm.phoneNumber) +
          '.' +
          ' Enter it here to verify your number'
      },
      enterEmail: {
        title: 'Enter an Email',
        subtitle: 'Enter the email where you want to receive updates about this poll'
      },
      signUpLogin: {
        title: 'Thank you for participating!',
        subtitle: 'Sign up for updates about "' + data.name + '".'
      },
      fullName: {
        title: loginForm.fullNameTitle
      }
    }

    if (loginForm.loginStep === 'contactMethod') {
      if (destinationType === DESTINATION_TYPES_CONSTANTS.signUp) {
        return titleOption.signUpLogin
      }
      return titleOption.pollLogin
    }
    if (loginForm.loginStep === 'confirmationCode') {
      return titleOption.confirmNumber
    }

    if (loginForm.loginStep === 'fullName') {
      return titleOption.fullName
    }

    return titleOption.enterEmail
  }

  const isContactMethodValid = () => {
    const phoneNumberValid = loginForm.phoneNumber.length === 11
    const countryCodeValid = VALID_COUNTRY_CODES.find(c => c.code === loginForm.countryCode)

    return phoneNumberValid && Boolean(countryCodeValid)
  }

  const handleRequestSubmitLogin = () => {
    dispatch(requestSubmitLogin(loginForm.phoneNumber, PHONE_NUMBER_CONTACT_METHOD))
  }

  const renderContactMethodStep = () => (
    <div className={styles['content-wrapper']}>
      <div className={styles['input-wrapper']}>
        <ContactMethodInputInner inputClassName={styles['input-inner']} valid />
      </div>

      <div className={styles['button-wrapper']}>
        <div className={styles['text']}>
          <a href="https://hobnob.app/privacy/">*Privacy Policy and </a>
          <a href="https://hobnob.app/terms/">Terms</a>
          <p>{pollForm.subtitle}</p>
        </div>

        <Button
          disabled={!isContactMethodValid()}
          className={styles['button-inner']}
          onClick={handleRequestSubmitLogin}
        >
          NEXT
        </Button>
      </div>
    </div>
  )

  const renderConfirmationCodeStep = () => (
    <div className={styles['content-wrapper']}>
      <ConfirmationCodeInput onResendCode={handleRequestSubmitLogin} onlyInput />
    </div>
  )

  const renderFullNameStep = () => (
    <div className={styles['content-wrapper']}>
      <FullNameInput />
    </div>
  )

  return (
    <DashboardContainer>
      <Panel
        isSecondButton
        notification={getTitle()}
        handleBackClick={handleBackClick}
        showCreateButton
      >
        {loginForm.loginStep === 'contactMethod' && renderContactMethodStep()}
        {loginForm.loginStep === 'confirmationCode' && renderConfirmationCodeStep()}
        {loginForm.loginStep === 'fullName' && renderFullNameStep()}
      </Panel>

      {loginForm.confirmationCodeLoading && (
        <div className={styles['loading-container']}>
          <LoadingComponent loading type="partial" light />
        </div>
      )}
    </DashboardContainer>
  )
}

SubscribeContainer.propTypes = {
  handleBackClick: PropTypes.func
}

export default SubscribeContainer
