import { Modal } from 'antd'
import {
  UpgradableSubscriptionPlatform,
  SubscriptionPlatform
} from 'src/services/stripe_checkout_configs'

export const verifySubscriptionPlatform = subscription_type => {
  if (!UpgradableSubscriptionPlatform.includes(subscription_type)) {
    let client = 'Web'

    if (subscription_type === SubscriptionPlatform.AppStore) {
      client = 'IOS'
    } else if (subscription_type === SubscriptionPlatform.GooglePlay) {
      client = 'Android'
    }
    Modal.info({
      content: `You subscribed on our ${client} app. Open Hobnob app on ${client} to manage your subscription and come back here.`,
      centered: true,
      zIndex: 1009
    })

    return false
  }

  return true
}
