import React, { Component } from 'react'
import { fetchEventList } from 'src/actions/event'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'

import styles from './archived-event-modal.scss'

class ArchivedEventModal extends Component {
  state = {
    eventList: [],
    pageInfo: null
  }
  componentDidMount() {
    this.onLoadEventList()
  }

  handleScroll = e => {
    const { scrollTop, clientHeight, scrollHeight } = e.target
    const {
      pageInfo: { has_next_page, end_cursor }
    } = this.state
    if (scrollTop + clientHeight === scrollHeight && has_next_page) {
      this.onLoadEventList(end_cursor.slice(0, -3))
    }
  }

  onLoadEventList = (after = null) => {
    this.props.getEventList({
      after,
      limit: 30,
      types: 'hosted_by,invited_to,community_discoverable',
      onSuccessCallback: data => {
        const events_connection = data.data.me?.events_connection
        if (events_connection) {
          const activeEvents = events_connection.events.filter(item => item.archived_at)

          const new_eventList = this.state.eventList.concat(activeEvents)

          this.setState(
            {
              eventList: new_eventList,
              pageInfo: events_connection.page_info
            },
            () => {
              if (new_eventList.length < 9 && events_connection.page_info.has_next_page) {
                this.onLoadEventList(events_connection.page_info.end_cursor.slice(0, -3))
              }
            }
          )
        }
      }
    })
  }
  render() {
    const { eventList } = this.state
    return (
      <div className={styles['archived-event-panel']} onScroll={this.handleScroll}>
        <ul className={styles['archived-event-wrapper']}>
          {eventList.map(event => (
            <a href={event.url} className={styles['event-inner']} key={event.id}>
              <div className={styles['event-flyer']}>
                <img src={event.flyer?.watermarked_url_2x} alt="" />
              </div>
              <div className={styles['event-information']}>
                <div className={styles['event-name']}>{event.name}</div>
                {event.starts_at && (
                  <div className={styles['event-date']}>
                    {moment(event.starts_at).format('dddd, MMM Do, H:mm')}
                  </div>
                )}
                <div className={styles['event-host']}>
                  Host: {event.user.first_name} {event.user.last_name}
                </div>
              </div>
            </a>
          ))}
        </ul>
      </div>
    )
  }
}

ArchivedEventModal.propTypes = {
  getEventList: PropTypes.func
}

function mapDispatchToProps(dispatch) {
  return {
    getEventList: params => dispatch(fetchEventList(params))
  }
}

export default connect(null, mapDispatchToProps)(ArchivedEventModal)
