import PropTypes from 'prop-types'
import React, { Component } from 'react'

import HobnobSimpleFormat from 'components/common/HobnobSimpleFormat'

import EventDetailsButton from './EventDetailsButtons.jsx'

import styles from './event-details.scss'

import { connect } from 'react-redux'

import { eventModalSelector } from 'src/selectors'

class EventDetails extends Component {
  eventDetailRef = React.createRef()

  scrollView = () => {
    if (this.props.eventModal === 'description') {
      setTimeout(() => {
        this.eventDetailRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      })
    }
  }

  componentDidMount () {
    this.scrollView()
  }
  render () {
    const { event, showMessageHostButton, onMessageHostClick, isAnnouncement } = this.props
    // const showSendGiftButton = !!event.send_gift_url
    // Temporarily hidden
    const showSendGiftButton = false

    const headerAndDetails = <div>
      <h3 className={styles['header']}>{isAnnouncement ? 'Details' : 'Event Details'}</h3>
      {event.description
        ? <HobnobSimpleFormat text={event.description} paragraphClass={styles['paragraph']} />
        : null}
    </div>

    return (
      <div className={styles['container']} ref={this.eventDetailRef}>
        {event.description
          ? headerAndDetails
          : null}

        {!isAnnouncement && <EventDetailsButton
          onMessageHostClick={onMessageHostClick}
          showMessageHostButton={showMessageHostButton}
          showSendGiftButton={showSendGiftButton}
          sendGiftUrl={event.send_gift_url}
        />}

      </div>
    )
  }
}

EventDetails.propTypes = {
  event: PropTypes.object,
  isAnnouncement: PropTypes.bool,
  onMessageHostClick: PropTypes.func.isRequired,
  showMessageHostButton: PropTypes.bool,
  eventModal: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    eventModal: eventModalSelector(state)
  }
}

export default connect(mapStateToProps)(EventDetails)
