import PropTypes from 'prop-types'
import React from 'react'

import { fullName } from 'src/services/invitation_helpers'

import styles from './private-invitation-info.scss'

export default function PrivateInvitationInfo (props) {
  const {
    isInvitationCredentialUser,
    invitation,
    invitationUser,
    onNotYou,
    user
  } = props
  const name = fullName(invitation, invitationUser)

  return (
    <div className={styles['private-invitation']}>
      Private invite for {name}
      {!user || isInvitationCredentialUser
        ? <span>
          ,
          &nbsp;<a onClick={onNotYou}>Not you?</a>
        </span>
        : null}
    </div>
  )
}

PrivateInvitationInfo.propTypes = {
  invitation: PropTypes.object.isRequired,
  user: PropTypes.object,
  isInvitationCredentialUser: PropTypes.bool.isRequired,
  invitationUser: PropTypes.object,
  onNotYou: PropTypes.func.isRequired
}
