import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'

import { pollForm } from '../constants'
import {
  EMAIL_CONTACT_METHOD,
  PHONE_NUMBER_CONTACT_METHOD,
  VALID_COUNTRY_CODES,
  CONTACT_METHOD
} from 'src/services/contact_methods'

import ContactMethodInputInner from 'components/LoginPanel/ContactMethodInput/ContactMethodInputInner/ContactMethodInputInner'
import Button from 'src/components/common/Button'

import {
  contactMethodSelector,
  eventTokenSelector,
  userSelector,
  showEmailFieldSelector,
  showPhoneFieldSelector,
  showPhoneCodeFieldSelector
} from 'src/selectors'
import {
  showPhoneCodeField,
  showEmailField,
  showPhoneField,
  notInSubscribePollContainer,
  hidePhoneField,
  isLoginContainer,
  hidePhoneCodeField
} from 'src/actions/timepoll'

import {
  updateLoginForm,
  setUseMaskedEmail,
  wrongPhoneNumber,
  showContactMethodFieldsForm,
  submitConfirmation,
  requestSubmitLogin
} from 'src/actions/login'

import { updateMenuPanel, showLoadingIcon } from 'src/actions/frontend'
import ConfirmationCodeInput from 'components/LoginModal/ConfirmationCodeInput/ConfirmationCodeInput'
import styles from './login-poll.scss'
import { message } from 'antd'

class SubscribePoll extends React.Component {
  state = {
    showErrors: false
  }

  _contactMethodType = () => {
    const { contactMethod } = this.props
    return contactMethod ? contactMethod.type : PHONE_NUMBER_CONTACT_METHOD
  }

  _phoneNumberValid() {
    const { loginForm } = this.props
    return loginForm.phoneNumber.length === 11
  }

  _countryCodeValid() {
    const { loginForm } = this.props
    const validCodes = VALID_COUNTRY_CODES.map(c => c.code)
    return validCodes.indexOf(loginForm.countryCode) >= 0
  }

  _contactMethodIsValid() {
    switch (this._contactMethodType()) {
      case EMAIL_CONTACT_METHOD:
        return true
      case PHONE_NUMBER_CONTACT_METHOD:
        return this._phoneNumberValid() && this._countryCodeValid()
      default:
        return this._phoneNumberValid() && this._countryCodeValid()
    }
  }

  _requestContactMethod = () => {
    const { requestContactMethod } = this.props
    requestContactMethod()
  }

  _requestSubmitLogin = () => {
    const { sendData, loginForm } = this.props
    const username = this._oauthUsername()
    let contactMethodType = this._contactMethodType()
    if (contactMethodType === EMAIL_CONTACT_METHOD && loginForm.useMaskedEmail) {
      contactMethodType = CONTACT_METHOD
    }
    sendData(username, contactMethodType)
  }
  _oauthUsername() {
    const { invitation, loginForm } = this.props
    switch (this._contactMethodType()) {
      case EMAIL_CONTACT_METHOD:
        if (loginForm.useMaskedEmail) {
          return invitation.contact_method.hash
        } else {
          return loginForm.emailAddress
        }
      case PHONE_NUMBER_CONTACT_METHOD:
        return loginForm.phoneNumber
      default:
        return loginForm.phoneNumber
    }
  }

  _formIsValid() {
    return this._contactMethodIsValid()
  }

  _onSubmitLogin = e => {
    e.preventDefault()
    if (this._formIsValid()) {
      this._requestSubmitLogin()
    } else {
      this.setState({ showErrors: true })
    }
  }

  _submitConfirmation = confirmationCode => {
    const { loginForm, onSubmitConfirmation, onNextStep, onShowLoadingIcon } = this.props
    const { firstName, lastName } = loginForm
    const username = this._oauthUsername()
    let contactMethodType = this._contactMethodType()
    if (contactMethodType === EMAIL_CONTACT_METHOD && loginForm.useMaskedEmail) {
      contactMethodType = CONTACT_METHOD
    }

    onSubmitConfirmation({
      firstName,
      lastName,
      username,
      confirmationCode,
      contactMethodType,
      onSuccessCallback: () => {
        onShowLoadingIcon()
        onNextStep?.()
      },
      onErrorCallback: data => {
        if (data.error === 'invalid_grant') {
          message.error('Invalid code. Please try again.')
        }
      }
    })
  }

  _handleClick = () => {
    const {
      loginForm: { confirmationCode }
    } = this.props
    this._submitConfirmation(confirmationCode)
  }

  _handleClickNext = e => {
    const { onShowPhoneCodeField, onHidePhoneField, onIsLoginContainer } = this.props
    e.preventDefault()
    if (this._formIsValid()) {
      this._requestSubmitLogin()
      onHidePhoneField()
      onShowPhoneCodeField()
      onIsLoginContainer()
    } else {
      this.setState({ showErrors: true })
    }
  }

  render() {
    const {
      loginForm,
      setUseMaskedEmailFalse,
      updateForm,
      showPhoneField,
      showPhoneCodeField,
      className,
      isSubscribePoll
    } = this.props

    const { showErrors } = this.state
    return (
      <div className={classnames(className, styles['form'])}>
        <div className={isSubscribePoll ? styles['form__subscribe'] : styles['form__container']}>
          {showPhoneField && (
            <ContactMethodInputInner
              contactMethodType={this._contactMethodType()}
              currView={this._requestContactMethod}
              fields={loginForm}
              setUseMaskedEmailFalse={setUseMaskedEmailFalse(true)}
              updateForm={updateForm}
              valid={!showErrors || this._contactMethodIsValid()}
              isLogin
            />
          )}

          {showPhoneCodeField && (
            <ConfirmationCodeInput onResendCode={this._requestSubmitLogin} onlyInput />
          )}
          <div className={styles['form__text']}>
            {!showPhoneCodeField && (
              <div>
                <a href="https://hobnob.app/privacy/">*Privacy Policy and </a>
                <a href="https://hobnob.app/terms/">Terms</a>
                <p>{pollForm.subtitle}</p>
              </div>
            )}
            {showPhoneField && (
              <Button
                disabled={!this._formIsValid()}
                onClick={this._handleClickNext}
                className={styles['button']}
              >
                <p>NEXT</p>
              </Button>
            )}
          </div>
        </div>
      </div>
    )
  }
}

SubscribePoll.propTypes = {
  loginForm: PropTypes.object,
  contactMethod: PropTypes.object,
  setUseMaskedEmailFalse: PropTypes.func,
  updateForm: PropTypes.func,
  sendData: PropTypes.func,
  invitation: PropTypes.object,
  requestContactMethod: PropTypes.func,
  onSubmitConfirmation: PropTypes.func,
  showPhoneField: PropTypes.bool,
  showPhoneCodeField: PropTypes.bool,
  onShowPhoneCodeField: PropTypes.func,
  className: PropTypes.string,
  isSubscribePoll: PropTypes.bool,
  onHidePhoneField: PropTypes.func,
  onIsLoginContainer: PropTypes.func,
  onNextStep: PropTypes.func,
  onShowLoadingIcon: PropTypes.func
}

function mapStateToProps(state) {
  const { loginForm, confirmationCodeValid } = state
  const user = userSelector(state)
  const contactMethod = contactMethodSelector(state)
  const showPhoneCodeField = showPhoneCodeFieldSelector(state)
  const showEmailField = showEmailFieldSelector(state)
  const showPhoneField = showPhoneFieldSelector(state)
  return {
    contactMethod,
    loginForm,
    confirmationCodeValid,
    eventToken: eventTokenSelector(state),
    poll: state.frontend.timePoll,
    showPhoneCodeField,
    showEmailField,
    showPhoneField,
    user
  }
}
function mapDispatchToProps(dispatch) {
  const setUseMaskedEmailFalse = params => dispatch(setUseMaskedEmail(params))
  const updateForm = params => dispatch(updateLoginForm(params))
  const backToContactMethodFieldsFormPage = () => dispatch(wrongPhoneNumber())
  const sendData = params => dispatch(requestSubmitLogin(params))
  const requestContactMethod = () => dispatch(showContactMethodFieldsForm())
  const onUpdateMenuPanel = () => dispatch(updateMenuPanel())
  const onShowPhoneCodeField = param => dispatch(showPhoneCodeField(param))
  const onShowEmailField = param => dispatch(showEmailField(param))
  const onShowPhoneField = param => dispatch(showPhoneField(param))
  const onHidePhoneField = param => dispatch(hidePhoneField(param))
  const onHideConfirmationCodeForm = () => dispatch(hidePhoneCodeField())
  const notInSubscribeContainer = () => dispatch(notInSubscribePollContainer())
  const onIsLoginContainer = () => dispatch(isLoginContainer())
  return {
    setUseMaskedEmailFalse,
    updateForm,
    backToContactMethodFieldsFormPage,
    sendData,
    onSubmitConfirmation: params => dispatch(submitConfirmation(params)),
    requestContactMethod,
    onUpdateMenuPanel,
    onShowPhoneCodeField,
    onShowEmailField,
    onShowPhoneField,
    onHideConfirmationCodeForm,
    notInSubscribeContainer,
    onHidePhoneField,
    onIsLoginContainer,
    onShowLoadingIcon: () => dispatch(showLoadingIcon())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscribePoll)
