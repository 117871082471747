import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  floatingButtonVisibleSelector
} from 'src/selectors'

import styles from './floating-button-spacer.scss'

export class FloatingButtonSpacer extends Component {
  _className () {
    const { alabasterBackground, floatingButtonVisible, alwaysShow } = this.props
    if (alwaysShow) {
      if (alabasterBackground) {
        return 'container-active-alabaster'
      } else {
        return 'container-active'
      }
    }

    if (floatingButtonVisible) {
      if (alabasterBackground) {
        return 'container-active-alabaster'
      } else {
        return 'container-active'
      }
    } else {
      return 'container'
    }
  }

  render () {
    return <div className={styles[this._className()]} />
  }
}

FloatingButtonSpacer.propTypes = {
  alabasterBackground: PropTypes.bool,
  floatingButtonVisible: PropTypes.bool,
  alwaysShow: PropTypes.bool
}
FloatingButtonSpacer.defaultProps = {
  alabasterBackground: false,
  alwaysShow: false
}

function mapStateToProps (state) {
  return {
    floatingButtonVisible: floatingButtonVisibleSelector(state)
  }
}

export default connect(mapStateToProps)(FloatingButtonSpacer)
