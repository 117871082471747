import React from 'react'
import PropTypes from 'prop-types'

import OverlayLoadingIndicator from 'components/common/OverlayLoadingIndicator'

import styles from './loading-screen.scss'

function flyerUrl (flyer) {
  if (flyer) {
    return flyer.web_client_loading_url || flyer.url
  } else {
    return ''
  }
}

export default function LoadingScreen (props) {
  const flyer = props.event && props.event.flyer
  const url = flyerUrl(flyer)

  return (
    <div className={styles['container']}>
      <OverlayLoadingIndicator loading dark />
      <div className={styles['flyer-container']}>
        {url
          ? <img src={url} />
          : null
        }
      </div>
    </div>
  )
}

LoadingScreen.propTypes = {
  event: PropTypes.object
}
