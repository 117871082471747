import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import EventEmpty from 'assets/icons/event-empty.png'
import { changeAddGuestsList, fetchEventList } from 'src/actions/event'
import { fetchMeWithCommunity } from 'src/actions/user'
import { fetchUsers } from 'src/actions'
import { activeInvitationsSelector } from 'src/selectors'

function formatUserIds(communities) {
  const filteredCommunities = communities.filter(
    community =>
      !community.destroyed_at &&
      community.community_members &&
      community.community_members.length > 0
  )
  const mapCommunities = filteredCommunities.map(community => {
    const filteredMembers = community.community_members.filter(member => !member.destroyed_at)
    const mapMembers = filteredMembers.map(member => member.user_id)
    return mapMembers
  })
  return Array.from(new Set(mapCommunities.flat(1)))
}

function formatCommunity(communities, users) {
  const filteredCommunities = communities.filter(
    community =>
      !community.destroyed_at &&
      community.community_members &&
      community.community_members.length > 0
  )
  const mapCommunities = filteredCommunities.map(community => {
    return {
      id: community.id,
      thumbnail_url: community.avatar_url,
      member_count: community.member_count,
      name: community.name,
      isExpand: false,
      members: (() => {
        const filteredMembers = community.community_members.filter(member => !member.destroyed_at)
        const mapMembers = filteredMembers.map(member => {
          const user = users[member.user_id]
          return {
            key: member.id,
            avatar_url: member.avatar_url,
            first_name: member.first_name,
            last_name: member.last_name,
            userFromHobnobContacts: true,
            contact_method_type: user.primary_contact_method.type,
            contact_method_hash: user.primary_contact_method.hash,
            contact_method_display_name: user.primary_contact_method.masked_display_name,
            user_id: user.id,
            app_user: user.app_user,
            verified: user.verified
          }
        })

        return mapMembers
      })()
    }
  })

  return mapCommunities
}

function formatEvent(events, currentEventId) {
  const filteredEvents = events.filter(event => {
    return (
      event.invitations.length > 0 &&
      event.event_type === 'event' &&
      event.flyer &&
      currentEventId !== event.id
    )
  })

  const sortEvents = filteredEvents.sort((a, b) => {
    if (
      (a.state === 'active' && b.state !== 'active') ||
      (a.state === 'active' && b.destroyed_at) ||
      (a.state !== 'active' && b.destroyed_at)
    ) {
      return -1
    }
  })

  const mapEvents = sortEvents.map(event => {
    return {
      id: event.id,
      thumbnail_url: event.flyer.thumbnail_url_2x || EventEmpty,
      member_count: event.invitations_count,
      name: event.name,
      isExpand: false,
      members: (() => {
        const filteredMembers = event.invitations.filter(member => !member.destroyed_at)
        const mapMembers = filteredMembers.map(member => {
          const userInformation = member.user
          let first_name = ''
          let last_name = ''
          if (userInformation.verified) {
            first_name = userInformation.first_name || ''
            last_name = userInformation.last_name || ''
          } else {
            first_name = member.first_name || ''
            last_name = member.last_name || ''
          }
          return {
            key: member.id,
            avatar_url: userInformation.avatar.medium_url_2x,
            first_name,
            last_name,
            user_id: userInformation.id,
            display_name: member.display_name,
            contact_method_hash: userInformation.primary_contact_method.hash,
            contact_method_type: userInformation.primary_contact_method.type,
            contact_method_display_name: userInformation.primary_contact_method.masked_display_name,
            userFromHobnobContacts: true,
            app_user: userInformation.app_user,
            verified: userInformation.verified
          }
        })

        return mapMembers
      })()
    }
  })

  return mapEvents
}

function withCommunityGuests(WrapperComponent) {
  class WithCommunityGuests extends Component {
    state = {
      communityList: [],
      eventList: [],
      addedMembers: [],
      eventPageInfo: null,
      loadingCompleted: false
    }

    handleGroupChecked = (members, searchFn = null) => {
      const { addedMembers } = this.state
      const isAllSelected = members.every(item => item.isSelected)

      for (let item of members) {
        if (item.isAddedInEvent) continue
        if (isAllSelected) {
          const index = addedMembers.findIndex(member => member.user_id === item.user_id)
          if (index > -1) {
            addedMembers.splice(index, 1)
          }
        } else {
          const isFind = addedMembers.find(member => member.user_id === item.user_id)
          if (!isFind) {
            addedMembers.push(item)
          }
        }
      }

      this.setState({ addedMembers }, () => {
        this.handleGroupList(searchFn)
      })
    }

    handleMemberChecked = (member, searchFn = null) => {
      const { addedMembers } = this.state

      if (!member.isSelected) {
        addedMembers.push(member)
      } else {
        const index = addedMembers.findIndex(item => item.user_id === member.user_id)
        addedMembers.splice(index, 1)
      }

      this.setState({ addedMembers }, () => {
        this.handleGroupList(searchFn)
      })
    }

    handleExpand = ({ index, key }) => {
      const groupList = this.state[key]
      groupList[index].isExpand = !groupList[index].isExpand
      this.setState({ [key]: groupList })
    }

    handleSaveGuests = () => {
      const { onChangeAddGuestsList, addGuestsList } = this.props
      const filterAddGuestsList = addGuestsList.filter(item => !item.userFromHobnobContacts)
      onChangeAddGuestsList([...this.state.addedMembers, ...filterAddGuestsList])
    }

    async componentDidMount() {
      const dataResponse = await Promise.all([this.onLoadCommunity(), this.onLoadEvent()])

      if (dataResponse.includes(false)) {
        return
      }

      const membersInLists = this.props.addGuestsList.filter(item => item.userFromHobnobContacts)
      this.setState({ loadingCompleted: true, addedMembers: membersInLists }, () =>
        this.handleGroupList()
      )
      console.log('dataResponse', dataResponse)
    }

    initialGroupList = () => {
      const addedMembersFromTable = this.props.addGuestsList.filter(
        item => item.userFromHobnobContacts
      )

      this.setState({ addedMembers: addedMembersFromTable }, () => this.handleGroupList())
    }

    handleGroupList = searchFn => {
      const { addedMembers } = this.state
      const keys = ['communityList', 'eventList']
      const dataObject = {}

      for (const key of keys) {
        const lists = this.state[key]

        dataObject[key] = lists.map(item => {
          const memberLists = item.members.map(member => {
            const isAddedInEvent = this.props.activeInvitations.some(
              guest => guest.guest === member.user_id
            )

            const isSelected = addedMembers.some(hobnobUser => {
              return hobnobUser.user_id === member.user_id
            })

            return {
              ...member,
              isSelected: isAddedInEvent || isSelected,
              isAddedInEvent
            }
          })

          item.members = memberLists

          return item
        })
      }

      this.setState(
        {
          ...JSON.parse(JSON.stringify(dataObject))
        },
        () => {
          if (searchFn) {
            searchFn()
          }
        }
      )
    }

    onLoadCommunity = () => {
      const { onFetchMeWithCommunity, onFetchUsers } = this.props

      return new Promise(resolve => {
        onFetchMeWithCommunity({
          onSuccessCallback: data => {
            const communities = data.data.me?.communities

            if (communities) {
              onFetchUsers({
                ids: formatUserIds(communities),
                onSuccessCallback: data => {
                  const users = {}
                  for (let item of data.data.users) {
                    users[item.id] = item
                  }

                  this.setState({ communityList: formatCommunity(communities, users) }, () => {
                    resolve(true)
                  })
                }
              })
            } else {
              resolve(true)
            }
          }
        })
      })
    }

    onLoadEvent = () => {
      const { onFetchEventList, eventId } = this.props
      const { eventPageInfo, eventList } = this.state

      return new Promise(resolve => {
        onFetchEventList({
          types: 'hosted_by',
          hasInvitations: true,
          limit: eventPageInfo ? 5 : 3,
          after: eventPageInfo ? eventPageInfo.end_cursor : null,
          onSuccessCallback: data => {
            const events_connection = data.data.me?.events_connection
            if (events_connection) {
              this.setState(
                {
                  eventList: [...eventList, ...formatEvent(events_connection.events, eventId)],
                  eventPageInfo: events_connection.page_info
                },
                () => {
                  if (eventPageInfo) {
                    this.handleGroupList()
                  }
                  resolve(true)
                }
              )
            }
            resolve(true)
          },
          onErrorCallback: () => {
            resolve(false)
          }
        })
      })
    }

    render() {
      return (
        <WrapperComponent
          {...this.state}
          onInitialGroupList={this.initialGroupList}
          onGroupChecked={this.handleGroupChecked}
          onMemberChecked={this.handleMemberChecked}
          onExpand={this.handleExpand}
          onLoadEvent={this.onLoadEvent}
          onSaveGuests={this.handleSaveGuests}
        />
      )
    }
  }

  WithCommunityGuests.displayName = `WithCommunityGuests(${WrapperComponent.displayName})`
  WithCommunityGuests.propTypes = {
    eventId: PropTypes.string,
    addGuestsList: PropTypes.array,
    onChangeAddGuestsList: PropTypes.func,
    onFetchMeWithCommunity: PropTypes.func,
    onFetchEventList: PropTypes.func,
    onFetchUsers: PropTypes.func,
    activeInvitations: PropTypes.array
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithCommunityGuests)
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeAddGuestsList: params => dispatch(changeAddGuestsList(params)),
    onFetchMeWithCommunity: params => dispatch(fetchMeWithCommunity(params)),
    onFetchEventList: params => dispatch(fetchEventList(params)),
    onFetchUsers: params => dispatch(fetchUsers(params))
  }
}

function mapStateToProps(state) {
  return {
    eventId: state.eventId,
    addGuestsList: state.frontend.addGuestsList,
    activeInvitations: activeInvitationsSelector(state)
  }
}

export default withCommunityGuests
