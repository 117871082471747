import { normalize } from 'normalizr'

export const FETCH_API = 'FETCH_API'
export const FETCH_API_DEBOUNCED = 'FETCH_API_DEBOUNCED'

export function fetchApiDebounced (key, fetchApiOptions) {
  return {
    type: FETCH_API_DEBOUNCED,
    key,
    fetchApiOptions
  }
}

export function fetchApi (options) {
  return {
    type: FETCH_API,
    ...options
  }
}

export function apiRequestStarted (action, apiPayload) {
  return {
    type: action.requestType,
    payload: action.payload,
    paginationParams: action.paginationParams,
    apiPayload
  }
}

export function apiResponseReceived ({ type, response, payload, schema, originalAction, apiPayload }) {
  if (schema && response) {
    response = normalize(response, schema)
  }

  return {
    type,
    payload,
    originalAction,
    response,
    apiPayload
  }
}

export function apiSuccessResponse (action, response, apiPayload) {
  return apiResponseReceived({
    type: action.successType,
    response,
    originalAction: action,
    schema: action.schema,
    apiPayload,
    payload: action.payload
  })
}

export function apiErrorResponse (action, apiPayload) {
  return apiResponseReceived({
    type: action.errorType,
    originalAction: action,
    schema: action.schema,
    apiPayload
  })
}
