import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { eventSelector, hostSelector, userIdSelector } from 'src/selectors'
import { fetchEvent } from 'src/actions/event'
import { parseEventUrl } from 'src/services/url_helpers'
import { setEventId, setEventToken } from 'src/actions'
import LoadingComponent from 'components/common/LoadingComponent/LoadingComponent'
import FlyerContainer from 'containers/event/EventDetail/GuestEvent/FlyerContainer/FlyerContainer'
import SvgIcon from 'components/common/SvgIcon'
import HobnobLogoWhite from 'assets/hobnob-logo-white.svg'
import HobnobLogo from 'assets/Icon-Small-40@2x.png'
import UserAvatarContainer from 'components/common/UserAvatarContainer'
import { hostFullName } from 'src/services/event_helpers'
import EventDateTime from 'components/details/EventDateTime'
import QRCode from 'qrcode'
import DoubleArrow from 'assets/icons/double-arrow.png'
import {
  getAccessToken,
  hasUserAccessToken,
  getRefreshToken,
  getTokenExpirationDate
} from 'src/services/access_tokens'
import { showLogin } from 'src/actions/login'
import { isIOS, isAndroid } from 'services/browser_detection'
import queryString from 'query-string'
import { fetchCommunitiesById } from 'src/actions/user'

import styles from './app-clip.scss'

const AppClip = () => {
  const dispatch = useDispatch()
  let { eventToken } = parseEventUrl(window.location.pathname)
  eventToken = eventToken.split(':')[0]
  const host = useSelector(hostSelector)
  const userId = useSelector(userIdSelector)
  const [qrCodeUrl, setQrCodeUrl] = useState('')
  const [spaceDetail, setSpaceDetail] = useState(null)
  const isHasUserAccessToken = hasUserAccessToken()

  const event = useSelector(eventSelector)
  const urlParams = queryString.parse(location.search)
  const isInAppClip = urlParams?.isAppClip === 'true'

  const postMessage = () => {
    const accessToken = getAccessToken()
    const refreshToken = getRefreshToken()
    const expirationDate = getTokenExpirationDate()

    if (isIOS()) {
      window.webkit?.messageHandlers?.getAccessToken?.postMessage({
        accessToken,
        refreshToken,
        expirationDate
      })
    }

    if (isAndroid()) {
      window.AndroidInterface?.getAccessToken({
        accessToken,
        refreshToken,
        expirationDate
      })
    }
  }

  useEffect(() => {
    dispatch(
      fetchEvent(
        eventToken,
        data => {
          const event = data.event
          dispatch(setEventToken(eventToken))
          dispatch(setEventId(event.id))

          if (event.external_community_id) {
            dispatch(
              fetchCommunitiesById(event.external_community_id, res => {
                console.log(222, res)
                setSpaceDetail(res.community)
              })
            )
          }
        },
        true,
        () => {
          location.href = '/home'
        }
      )
    )

    const qrParams = {
      width: 150,
      margin: 0
    }

    QRCode.toDataURL(window.location.href, qrParams, (_, url) => {
      setQrCodeUrl(url)
    })
  }, [])

  useEffect(() => {
    if (eventToken && userId) {
      postMessage()
    }

    // updateEventUrl('app_clip', eventToken)

    if (isInAppClip && !isHasUserAccessToken) {
      dispatch(
        showLogin({
          loginModalSubtitle:
            'Confirm your number to submit photos to the group'
        })
      )
    }
  }, [userId])

  if (isInAppClip && !userId) {
    return null
  }

  if (!event) {
    return <LoadingComponent dark />
  }

  console.log(event)

  return (
    <div className={styles['app-clip-container']}>
      <FlyerContainer />
      <div className={styles['flyer-container']}>
        <img src={event.flyer.watermarked_url_2x} />
      </div>
      <div className={styles['content']}>
        <div className={styles['logo']}>
          <img src={HobnobLogo} />
          <SvgIcon className={styles['logo-text']} icon={HobnobLogoWhite} />
        </div>

        <div className={styles['information']}>
          <h1>{event.name}</h1>
          <UserAvatarContainer user={host} size={60} />
          <p className={styles['name']}>{hostFullName(event, host)}</p>
          <div className={styles['date']}>
            {spaceDetail && <p>Space: {spaceDetail.name}</p>}
            <EventDateTime
              endsAt={event.ends_at}
              startsAt={event.starts_at}
              timezone={event.timezone}
            />
          </div>
          <div className={styles['qr-code-border']}>
            <img src={qrCodeUrl} alt="" />
          </div>

          <img src={DoubleArrow} width={30} />
          <p className={styles['text']}>Scan to participate</p>
        </div>
      </div>

      <div className={styles['content-mobile']}>
        <div className={styles['avatar']}>
          <UserAvatarContainer user={host} size={60} />
        </div>
        <h1>{event.name}</h1>
        <p className={styles['host-mobile']}>
          Host: {hostFullName(event, host)}
          <i />
          {spaceDetail && <span>Space: {spaceDetail.name}</span>}
        </p>
        <EventDateTime
          endsAt={event.ends_at}
          startsAt={event.starts_at}
          timezone={event.timezone}
        />
      </div>
    </div>
  )
}

export default AppClip
