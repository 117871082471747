import { FlyerLayoutAbstract } from './flyer-layout.abstract'
import moment from 'moment/moment'
import n2w from 'number-to-words'

export default class Flyer_layout_right_aligned_column_015LayoutModel extends FlyerLayoutAbstract {
  calcLocationGap() {
    const { starts_at, ends_at } = this.eventData
    if (!starts_at && !ends_at) {
      return 220
    }

    return 0
  }

  textLayouts = {
    event_name_1: {
      top: 70,
      right: 15,
      size: 60,
      width: 130,
      height: 100,
      align: 'right',
      widthAlign: 'center'
    },
    location_1: {
      top: 420 - this.calcLocationGap(),
      right: 20,
      size: 40,
      width: 120,
      height: 40,
      align: 'right',
      widthAlign: 'center'
    },
    location_2: {
      top: 458 - this.calcLocationGap(),
      right: 20,
      size: 20,
      width: 120,
      align: 'right',
      widthAlign: 'center',
      nowrap: true
    }
  }

  getLocationText1() {
    const { custom_venue_name, venue } = this.eventData

    if (venue) {
      let text = ''
      text = `${venue.name}, ${venue.street}`
      const names = venue.name.split(' ')
      text = `${names[0]}\n${names.slice(1).join(' ')}`
      return text
    } else {
      return custom_venue_name
    }
  }

  getLocationText2() {
    const { venue } = this.eventData

    if (venue) {
      return venue.street
    }
  }

  constructor(layout, eventData) {
    super(layout, eventData)
    this.init()
  }

  getDateTexts() {
    const startDate = this.eventData.starts_at
    const endDate = this.eventData.ends_at
    let dates = []
    const isSameDay = moment(startDate).isSame(endDate, 'day')
    const { date1Props, date2Props } = this.dateProps

    const textArea1 = {
      top: 180,
      right: 15,
      size: 50,
      width: 130,
      color: date1Props.color.replace('0x', '#'),
      font: date1Props.font.replace(/\s+/g, '-'),
      align: 'right',
      widthAlign: 'center',
      nowrap: true
    }

    const textArea2 = {
      top: 225,
      right: 15,
      size: 50,
      width: 130,
      color: date1Props.color.replace('0x', '#'),
      font: date1Props.font.replace(/\s+/g, '-'),
      align: 'right',
      widthAlign: 'center',
      nowrap: true
    }

    const textArea3 = {
      top: 270,
      right: 15,
      size: 50,
      width: 130,
      color: date1Props.color.replace('0x', '#'),
      font: date1Props.font.replace(/\s+/g, '-'),
      align: 'right',
      widthAlign: 'center',
      nowrap: true
    }

    const textArea4 = {
      top: 350,
      right: 15,
      size: 30,
      width: 130,
      color: date1Props.color.replace('0x', '#'),
      font: date1Props.font.replace(/\s+/g, '-'),
      align: 'right',
      widthAlign: 'center',
      nowrap: true
    }

    const startMoment = moment(startDate)
    const endMoment = moment(endDate)

    if (startDate && !endDate) {
      const text1 = startMoment.format('dddd')
      textArea1.text = date1Props.all_caps ? text1.toUpperCase() : text1
      dates.push(textArea1)

      const text2 = startMoment.format('MMMMM').slice(0, 4)
      textArea2.text = date1Props.all_caps ? text2.toUpperCase() : text2
      dates.push(textArea2)

      const text3 = n2w.toWordsOrdinal(startMoment.format('D'))
      textArea3.text = date1Props.all_caps ? text3.toUpperCase() : text3
      dates.push(textArea3)

      const s_h = startMoment.format('h')
      const s_m = startMoment.format('mm')
      const s_A = startMoment.format('A')
      const text4 = n2w.toWords(s_h) + ' ' + n2w.toWords(s_m) + ' ' + s_A
      textArea4.text = date1Props.all_caps ? text4.toUpperCase() : text4
      dates.push(textArea4)
    }

    if (startDate && endDate) {
      if (isSameDay) {
        const text1 = startMoment.format('dddd')
        textArea1.text = date1Props.all_caps ? text1.toUpperCase() : text1
        dates.push(textArea1)

        const text2 = startMoment.format('MMMMM').slice(0, 4)
        textArea2.text = date1Props.all_caps ? text2.toUpperCase() : text2
        dates.push(textArea2)

        const text3 = n2w.toWordsOrdinal(startMoment.format('Do'))
        textArea3.text = date1Props.all_caps ? text3.toUpperCase() : text3
        dates.push(textArea3)

        const s_h = startMoment.format('h')
        const s_m = startMoment.format('mm')
        const s_A = startMoment.format('A')
        const e_h = endMoment.format('h')
        const e_m = endMoment.format('mm')
        const e_A = endMoment.format('A')

        const text4 = `${s_h}:${s_m} ${s_A} - ${e_h}:${e_m} ${e_A}`
        textArea4.text = date1Props.all_caps ? text4.toUpperCase() : text4
        dates.push(textArea4)
      } else {
        const s_w = startMoment.format('ddd')
        const s_M = startMoment.format('MMM')
        const s_D = startMoment.format('D')
        const text1 = `${s_w} ${s_M} ${s_D}`
        textArea1.text = date1Props.all_caps ? text1.toUpperCase() : text1
        dates.push(textArea1)

        textArea2.text = 'TILL'
        textArea2.size = 70

        dates.push(textArea2)

        const e_w = endMoment.format('ddd')
        const e_M = endMoment.format('MMM')
        const e_D = endMoment.format('D')
        const text3 = `${e_w} ${e_M} ${e_D}`
        textArea3.text = date2Props.all_caps ? text3.toUpperCase() : text3
        textArea3.top = 295
        dates.push(textArea3)
      }
    }

    return { type: 'date', dates }
  }
}
