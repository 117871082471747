import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './input-guests.scss'
import TextInputElement from 'components/forms/TextInputElement'
import Button from 'components/common/Button'
import cn from 'classnames'
import { changeAddGuestsList } from 'src/actions/event'
import { connect } from 'react-redux'

class InputGuests extends Component {
  handleAddGuest = () => {
    const { addGuestsList, onChangeAddGuestsList, onGetGuestInfo } = this.props
    const guestInformation = onGetGuestInfo()

    if (guestInformation) {
      onChangeAddGuestsList([guestInformation, ...addGuestsList])
    }
  }

  render() {
    const { first_name, last_name, phoneOrEmail, visibleErrorText, onInputChange } = this.props

    return (
      <div className={styles['input-guests-container']}>
        <div className={styles['input-container']}>
          <div className={styles['input-label']}>full name</div>
          <div className={styles['input-wrapper']}>
            <TextInputElement
              className={styles['input-inner']}
              placeholder="First Name"
              onChange={e => onInputChange('first_name', e.target.value)}
              valid
              value={first_name}
            />
            <TextInputElement
              className={styles['input-inner']}
              placeholder="Last Name"
              onChange={e => onInputChange('last_name', e.target.value)}
              valid
              value={last_name}
            />
          </div>
        </div>

        <div className={styles['input-container']}>
          <div className={styles['input-label']}>
            Phone Number or Email<span className={styles['required-text']}>*</span>
          </div>
          <div className={styles['input-wrapper']}>
            <TextInputElement
              className={cn(styles['input-inner'], {
                [styles['input-inner-error']]: visibleErrorText
              })}
              placeholder="e.g. 9876543210 (no country code)  or  example@hobnob.io"
              onChange={e => onInputChange('phoneOrEmail', e.target.value)}
              valid={!visibleErrorText || phoneOrEmail === ''}
              value={phoneOrEmail}
            />
          </div>
          <div
            className={cn(styles['error-text'], {
              [styles['error-text-hidden']]: !visibleErrorText || phoneOrEmail === ''
            })}
          >
            Invalid phone number or email!
          </div>
        </div>

        <div className={styles['button-wrapper']}>
          <Button className={styles['button-inner']} onClick={this.handleAddGuest}>
            ADD
          </Button>
        </div>
      </div>
    )
  }
}

InputGuests.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  phoneOrEmail: PropTypes.string,
  visibleErrorText: PropTypes.bool,
  addGuestsList: PropTypes.array,
  onInputChange: PropTypes.func,
  onChangeAddGuestsList: PropTypes.func,
  onGetGuestInfo: PropTypes.func
}

function mapStateToProps(state) {
  return {
    addGuestsList: state.frontend.addGuestsList
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeAddGuestsList: params => dispatch(changeAddGuestsList(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputGuests)
