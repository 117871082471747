import PropTypes from 'prop-types'
import React from 'react'

import SvgIcon from 'src/components/common/SvgIcon'

import styles from './empty-section.scss'

export default class EmptySection extends React.Component {
  _renderIconOrImage () {
    const { icon, imageUrl, className } = this.props

    if (icon) {
      return <SvgIcon icon={icon} className={className} />
    } else if (imageUrl) {
      return <img src={imageUrl} className={className} />
    } else {
      return null
    }
  }

  render () {
    const { description, header, children } = this.props

    return (
      <div className={styles['container']}>
        <h3 className={styles['header']}>{header}</h3>
        {this._renderIconOrImage()}
        <p className={styles['description']}>{description}</p>
        {children}
      </div>
    )
  }
}

EmptySection.propTypes = {
  description: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  icon: PropTypes.string,
  imageUrl: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any
}
