import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import styles from './flyer-text-element.scss'

const FlyerTextElement = ({ flyerTexts, ratio }) => {
  let textEls = []

  useEffect(() => {
    for (let el of textEls) {
      adjustSize(el)
    }
  })

  const adjustSize = async el => {
    const adjustWidth = await new Promise(resolve => {
      adjustSizeWithWidth(el, resolve)
    })

    adjustSizeWithHeight(el, adjustWidth.ratio)
  }

  const adjustSizeWithWidth = (el, resolve) => {
    const parentEl = el.parentNode
    const parentWidth = parentEl.offsetWidth
    const width = el.offsetWidth
    if (width > parentWidth) {
      const size = Number(getComputedStyle(parentEl)['font-size'].replace('px', ''))
      if (size <= 12) {
        const ratio = 1 - (width - parentWidth) / width
        el.style.transformOrigin = 'left top'
        el.style.transform = `scale(${ratio})`

        resolve({ status: true, ratio })
        return
      } else {
        parentEl.style.fontSize = size - 1
      }
      adjustSizeWithWidth(el, resolve)
    } else {
      resolve({ status: true })
    }
  }

  const adjustSizeWithHeight = (el, widthRatio) => {
    const parentEl = el.parentNode
    const parentHeight = parentEl.offsetHeight
    const ratio = widthRatio || 1
    const height = el.offsetHeight
    const currentHeight = height * ratio

    if (currentHeight > parentHeight) {
      const size = Number(getComputedStyle(parentEl)['font-size'].replace('px', ''))
      if (size <= 12) {
        const align = getComputedStyle(parentEl)['text-align']

        const heightRatio = ratio - (currentHeight - parentHeight) / height
        el.style.transform = `scale(${heightRatio})`
        // default
        el.style.transformOrigin = 'left top'

        if (align === 'center') {
          const currentWidth = el.offsetWidth * heightRatio
          const translateX = (parentEl.offsetWidth - currentWidth) / 2
          el.style.transform = `translateX(${translateX}px) scale(${heightRatio})`
        }

        if (align === 'right') {
          if (widthRatio) {
            const currentWidth = el.offsetWidth * heightRatio
            const translateX = parentEl.offsetWidth - currentWidth
            el.style.transform = `translateX(${translateX}px) scale(${heightRatio})`
          } else {
            el.style.transformOrigin = 'right top'
          }
        }

        return
      } else {
        parentEl.style.fontSize = size - 1
      }

      adjustSizeWithHeight(el)
    }
  }

  const renderTextEl = (item, index) => {
    let fontSize = item.size * ratio
    let scaleRatio = 1

    let textStyle = {
      fontFamily: item.font,
      fontSize: item.size * ratio,
      top: item.top * ratio,
      color: item.color,
      width: item.width * ratio
    }

    if (item.transformOrigin) {
      scaleRatio = fontSize / 12
      textStyle.transform = `scale(${fontSize / 12})`
      textStyle.transformOrigin = item.transformOrigin
    }

    if (item.height) {
      textStyle.height = item.height * ratio
    }

    if (item.nowrap) {
      textStyle.whiteSpace = 'nowrap'
    }

    if (item.align === 'right') {
      textStyle.right = item.right ? item.right * ratio : 0
      if (item.widthAlign === 'center') {
        textStyle.textAlign = 'center'
      } else {
        textStyle.textAlign = 'right'
      }
    } else if (item.align === 'center') {
      textStyle.left = '50%'
      textStyle.transform = `translateX(-50%) scale(${scaleRatio})`
      textStyle.textAlign = 'center'
    } else {
      if (item.widthAlign === 'center') {
        textStyle.textAlign = 'center'
      } else {
        textStyle.textAlign = 'left'
      }
      textStyle.left = item.left * ratio
    }

    if (item.verticalAlign) {
      textStyle.display = 'flex'

      if (textStyle.textAlign === 'center') {
        textStyle.justifyContent = 'center'
      } else if (textStyle.textAlign === 'right') {
        textStyle.justifyContent = 'end'
      }

      if (item.verticalAlign === 'bottom') {
        textStyle.alignItems = 'end'
      } else if (item.verticalAlign === 'center') {
        textStyle.alignItems = 'center'
      }
    }

    return (
      <div className={styles['text-box']} style={textStyle} key={index}>
        <div className={styles['text']} ref={ref => ref && textEls.push(ref)}>
          {item.text}
        </div>
      </div>
    )
  }

  return (
    <div className={styles['flyer-text']}>
      {flyerTexts.map((item, index) => {
        if (item.type === 'date') {
          return item.dates.map((date, i) => {
            return renderTextEl(date, i)
          })
        }
        return renderTextEl(item, index)
      })}
    </div>
  )
}

FlyerTextElement.propTypes = {
  ratio: PropTypes.number,
  flyerTexts: PropTypes.array
}

export default FlyerTextElement
