import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CloseIconGray from 'assets/icons/close-icon-gray.png'
import LogoutIcon from 'assets/icons/logout-icon.svg'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import { userSelector, userIdSelector } from 'src/selectors'
import IcoPoll from 'assets/icons/ico-poll.png'
import IcoCrown from 'assets/icons/ico-crown.png'
import IcoChat from 'assets/icons/ico-chat.png'
import BalloonIconWhite from 'assets/icons/balloon-icon-white.png'
import PropTypes from 'prop-types'
import { logout } from 'src/actions/login'
import { routerActions } from 'react-router-redux'
import { fullName as userFullName } from 'src/services/user_helpers'
import MembershipInfo from 'components/MenuBar/MembershipInfo/MembershipInfo'
import {
  PlanType,
  getPlanBySlug,
  PlanSlugs,
  SubscriptionPlatform
} from 'src/services/stripe_checkout_configs'
import Button from 'src/components/common/Button'
import { verifySubscriptionPlatform } from 'src/services/frontend_tips'
import { Modal } from 'antd'

import HobnobModal from 'src/components/common/HobnobModalV2/HobnobModal'
import { DOWNLOAD_APP_LINK, GLOBAL_LAYER_CONTENT } from 'src/constants'
import SvgIcon from 'src/components/common/SvgIcon'
import PopConfirm from 'components/common/PopConfirm/PopConfirm'
import cn from 'classnames'
import { changeVisible, setContent } from 'src/actions/globalLayer'
import { changePlanModalVisible } from 'src/actions/plan'

import styles from './profile-panel.scss'

const hiddenPlans = [PlanType.Standard, PlanType.Pro, PlanType.Premium1, PlanType.Plus]

const ProfilePanel = props => {
  const { onClose } = props
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const userId = useSelector(userIdSelector)
  const { plan, subscription_type } = user?.current_subscription || {}
  const planType = getPlanBySlug(plan?.slug, 'planType') || PlanType.Free

  const [downloadModalVisible, setDownloadModalVisible] = useState(false)
  const [logoutConfirmVisible, setLogoutConfirmVisible] = useState(false)

  const handleLogOut = () => {
    if (user) {
      dispatch(logout())
    } else {
      dispatch(routerActions.push('/login'))
    }
    onClose()
  }

  const navigationPage = url => {
    dispatch(routerActions.push(url))
    onClose()
  }

  const handleClickSubscribe = () => {
    if (verifySubscriptionPlatform(subscription_type)) {
      dispatch(changePlanModalVisible(true))
      onClose()
    }
  }

  const handleClickMembership = () => {
    if (hiddenPlans.includes(planType)) {
      let client = 'Web'

      if (subscription_type === SubscriptionPlatform.AppStore) {
        client = 'IOS'
      } else if (subscription_type === SubscriptionPlatform.GooglePlay) {
        client = 'Android'
      }

      Modal.info({
        content: `You subscribed on our ${client} app. Open Hobnob app on ${client} to manage your subscription and come back here.`,
        centered: true,
        zIndex: 1009
      })
      return
    }

    if (planType === PlanType.Free) {
      dispatch(changePlanModalVisible(true))
      onClose()
    } else {
      navigationPage('/membership')
    }
  }

  const renderSubscriptionButton = () => {
    if (plan.slug === PlanSlugs.GroupOrganizerAnnually) {
      return
    }

    if (planType === PlanType.Free) {
      return (
        <>
          <Button className={styles['purchase-button']} onClick={handleClickSubscribe}>
            {user.is_trial_available ? 'REDEEM YOUR FREE WEEK' : 'Subscribe'}
          </Button>

          <div
            className={styles['free-plan-link']}
            onClick={() => {
              dispatch(setContent(GLOBAL_LAYER_CONTENT.freeMembership))
              dispatch(changeVisible(true))
            }}
          >
            What do I get on Free Plan?
          </div>
        </>
      )
    }

    return (
      <Button
        className={cn(styles['purchase-button'], styles[planType])}
        onClick={handleClickSubscribe}
      >
        SEE UPGRADE OPTIONS
      </Button>
    )
  }

  return (
    <div className={styles['profile-panel-container']}>
      <div className={styles['header-container']}>
        <div className={styles['icon-wrapper']}>
          <img src={CloseIconGray} width="24" alt="Close" onClick={onClose} />
          <SvgIcon
            icon={LogoutIcon}
            width={24}
            height={24}
            onClick={() => setLogoutConfirmVisible(true)}
          />
        </div>
        <div className={styles['avatar']}>
          <UserAvatarContainer user={user} size={50} inProfile />
          <div className={styles['name']}>{userFullName(user)}</div>
        </div>
        <div className={styles['plan']}>
          <MembershipInfo mobileProfilePanel />

          {renderSubscriptionButton()}
        </div>
      </div>

      <div className={styles['content-container']}>
        <div className={styles['group-container']}>
          <div className={styles['group-name']}>HOBNOB</div>
          <ul className={styles['list-container']}>
            <li className={styles['list-item']} onClick={() => navigationPage('/tp/timepoll')}>
              <img src={IcoPoll} width="28" alt="" />
              <span>Polls & Lists</span>
            </li>
            <li className={styles['list-item']} onClick={() => navigationPage('/e/list')}>
              <img src={BalloonIconWhite} width="28" alt="" />
              <span>Events & Announcements</span>
            </li>
            <li
              className={styles['list-item']}
              onClick={() => {
                setDownloadModalVisible(true)
                window.mixpanel.track('Chat Clicked', { user_id: userId })
              }}
            >
              <img src={IcoChat} width="28" alt="" />
              <span>Chat</span>
            </li>
            <li className={styles['list-item']} onClick={handleClickMembership}>
              <img src={IcoCrown} width="28" alt="" />
              <span>Membership</span>
            </li>
          </ul>
        </div>
      </div>

      <HobnobModal visible={downloadModalVisible} onCancel={() => setDownloadModalVisible(false)}>
        <div className={styles['download-container']}>
          <div className={styles['title']}>To Chat, Get the App</div>
          <div>
            Hobnob makes group chats pain-free and private! Download Hobnob to direct message any
            guest on the guest list.
          </div>
          <Button
            wrapperElement={'a'}
            href={DOWNLOAD_APP_LINK}
            target="_blank"
            className={styles['button-inner']}
            onClick={() => window.mixpanel.track('GET HOBNOB TO CHAT Clicked', { user_id: userId })}
          >
            GET HOBNOB TO CHAT
          </Button>
        </div>
      </HobnobModal>

      <PopConfirm
        visible={logoutConfirmVisible}
        onClose={() => setLogoutConfirmVisible(false)}
        cancelText="No"
        confirmText="Yes"
        type="cancel"
        cancelType="normal"
        onConfirm={handleLogOut}
      >
        Are you sure you want to log out?
      </PopConfirm>
    </div>
  )
}

ProfilePanel.propTypes = {
  onClose: PropTypes.func
}

export default ProfilePanel
