export const SHOW_TOAST_MESSAGE = 'SHOW_TOAST_MESSAGE'
export const HIDE_TOAST_MESSAGES = 'HIDE_TOAST_MESSAGES'

export function showToastMessage ({ user, messageType, messageContent, duration = 7000 } = {}) {
  return {
    type: SHOW_TOAST_MESSAGE,
    // The user that took the action
    user,
    // The type of message that this toast message represents
    messageType,
    // Content of the message, such as for comments and updates
    messageContent,
    // Duration of the message in ms
    duration
  }
}

export function hideToastMessages () {
  return {
    type: HIDE_TOAST_MESSAGES
  }
}
