import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { routerActions } from 'react-router-redux'
import BackButton from 'assets/icons/back_button.png'
import Button from 'src/components/common/Button'
import TextInputElement from 'components/forms/TextInputElement'
import TextAreaElement from 'components/common/ResizeableTextarea'
import SettingIcon from 'assets/icons/setting-icon.png'
import LabelIcon from 'assets/icons/label-icon.png'
import DateIcon from 'assets/icons/date-icon.png'
import LocationIcon from 'assets/icons/location-icon.png'
import HobnobSpaceIcon from 'assets/icons/hobnob-space-icon.png'
import CloseIconBg from 'assets/icons/close-icon-bg.png'
import HobnobSelect from 'components/common/HobnobSelect'
import { renderTimezoneAbbreviation } from 'services/date_helpers'
import LocationAutoCompleteInput from 'components/map/LocationAutoCompleteInput'
import HobnobModal from 'components/common/HobnobModal'
import { Select } from 'antd'
import DateModal from 'containers/event/CreateEvent/FirstStep/DateModal/DateModal'
import moment from 'moment-timezone'
import { capitalized } from 'services/utils'
import { changeEventForm } from 'src/actions/event'
import cn from 'classnames'
import EventSetting from 'containers/event/CreateEvent/EventSetting/EventSetting'
import {
  createApplePlace,
  mapLocationDataBetweenApplePlaceAndGooglePlace
} from 'src/actions/places'
import { planSelector, communityListSelector, eventFormSelector } from 'src/selectors'

import styles from './input-options.scss'

const timeFilter = (date, timezone) => {
  if (!date) {
    return ''
  }

  return `${moment(date).format('ddd, MMM DD, YYYY, h:mm A')} - ${renderTimezoneAbbreviation(
    timezone
  )}`
}

const getCommunitiesList = communityMemberList => {
  const ValidList = ['admin', 'member', 'moderator']

  const lists = communityMemberList.filter(item => {
    if (ValidList.indexOf(item.my_community_member?.role) > -1) {
      return { value: item.id, text: item.name }
    }
  })

  const communitiesList = lists.map(item => ({ value: item.id, text: item.name }))
  communitiesList.unshift({ value: '', text: 'None' })
  return communitiesList
}

const InputOptions = ({ onSubmit }) => {
  const dispatch = useDispatch()

  const { visible: isPlanModalVisible } = useSelector(planSelector)
  const communityList = useSelector(communityListSelector)
  const eventForm = useSelector(eventFormSelector)
  let nameInputRef = null
  const [isLocationSearchModalVisible, setIsLocationSearchModalVisible] = useState(false)
  const [isDateModalVisible, setIsDateModalVisible] = useState(false)
  const [isSettingModalVisible, setIsSettingModalVisible] = useState(false)
  const [nameInputSelectionStart, setNameInputSelectionStart] = useState(0)
  const [nameInputSelectionEnd, setNameInputSelectionEnd] = useState(0)
  const communitiesList = getCommunitiesList(communityList)
  const isEdit = Boolean(eventForm.id)
  const { venue, custom_venue_name } = eventForm
  const displayLocation = venue ? venue.name || venue.street : custom_venue_name

  useEffect(() => {
    if (nameInputRef) {
      nameInputRef.selectionStart = nameInputSelectionStart
      nameInputRef.selectionEnd = nameInputSelectionEnd
    }
  }, [eventForm.name])

  useEffect(() => {
    if (isPlanModalVisible) {
      setIsSettingModalVisible(false)
    }
  }, [isPlanModalVisible])

  const handleLocationSelected = location => {
    if (location.latitude) {
      setIsLocationSearchModalVisible(false)
      handleChange('venue', location)
      handleChange('custom_venue_name', null)
    } else {
      setIsLocationSearchModalVisible(false)
      handleChange('apple_place_id', null)
      handleChange('venue', null)
      handleChange('custom_venue_name', location.name ? location.name : null)
    }
  }

  const handleChange = (type, value) => {
    switch (type) {
      case 'name':
        if (!nameInputRef) {
          nameInputRef = value.target
        }

        const nameStr = capitalized(nameInputRef.value)
        dispatch(changeEventForm({ name: nameStr }))
        setNameInputSelectionStart(nameInputRef.selectionStart)
        setNameInputSelectionEnd(nameInputRef.selectionEnd)

        break
      case 'custom_host_name':
        if (value.length > 255) {
          return
        }

        dispatch(changeEventForm({ custom_host_name: value }))
        break
      case 'community':
        dispatch(changeEventForm({ external_community_id: value || null }))
        break
      case 'clearLocation':
        dispatch(changeEventForm({ venue: null, custom_venue_name: null }))
        break
      default:
        dispatch(changeEventForm({ [type]: value }))
    }
  }

  const handleSubmit = () => {
    if (!eventForm.venue) {
      onSubmit()
      return
    }

    const place = eventForm.venue
    dispatch(
      createApplePlace({
        placeData: mapLocationDataBetweenApplePlaceAndGooglePlace(place, 'google'),
        onSuccessCallback: response => {
          if (response.data?.apple_place_create?.successful) {
            const createdPlace = response.data.apple_place_create.result
            handleChange('apple_place_id', createdPlace.id)
            if (response.data.apple_place_create.messages?.length) {
              console.log('create place successful with messages')
              console.log(response.data.apple_place_create.messages)
            }
            onSubmit({ apple_place_id: createdPlace.id })
          } else if (response.errors) {
            console.warn('failed to create apple place:')
            console.warn(response.errors)
          } else {
            console.error(response)
          }
        }
      })
    )
  }

  return (
    <>
      <div className={styles['input-options-container']}>
        <div className={styles['options-header']}>
          <img
            className={styles['back-icon']}
            src={BackButton}
            width="18"
            onClick={() => dispatch(routerActions.goBack())}
          />
          <span className={styles['title']}>{isEdit ? 'Edit Event' : 'Create Event'}</span>
          <img src={SettingIcon} width="18" onClick={() => setIsSettingModalVisible(true)} />
          <div className={styles['save-button']}>
            <Button
              disabled={!eventForm.name}
              className={styles['button-inner']}
              onClick={handleSubmit}
            >
              SAVE CHANGES
            </Button>
          </div>
        </div>

        <div className={styles['section-wrapper']}>
          <h3>BASIC INFORMATION</h3>

          <div className={styles['input-wrapper']}>
            <img src={LabelIcon} alt="" width="18" />
            <TextInputElement
              className={cn(styles['input-inner'], styles['input-inner-name'])}
              placeholder="Your Event Name"
              onChange={event => handleChange('name', event)}
              valid
              value={eventForm.name}
              maxLength="50"
            />
          </div>

          <div className={styles['subtitle']}>OPTIONAL</div>

          <div className={styles['input-wrapper']} onClick={() => setIsDateModalVisible(true)}>
            <img src={DateIcon} alt="" width="18" />
            <div className={styles['input-box']}>
              <TextInputElement
                className={styles['input-inner']}
                placeholder="Optional Date & Time"
                onChange={() => {}}
                valid
                value={timeFilter(eventForm.starts_at, eventForm.timezone)}
                readOnly
              />
              {eventForm.ends_at && (
                <TextInputElement
                  className={styles['input-inner']}
                  placeholder="Optional Date & Time"
                  onChange={() => {}}
                  valid
                  value={timeFilter(eventForm.ends_at, eventForm.timezone)}
                  readOnly
                />
              )}
            </div>
          </div>

          <div
            className={styles['input-wrapper']}
            onClick={() => setIsLocationSearchModalVisible(true)}
          >
            <img src={LocationIcon} alt="" width="18" />
            <div className={styles['input-box']}>
              <TextInputElement
                className={styles['input-inner']}
                placeholder="Location"
                valid
                readOnly
                value={displayLocation}
              />
            </div>
            {displayLocation && (
              <img
                src={CloseIconBg}
                onClick={e => {
                  e.stopPropagation()
                  handleChange('clearLocation')
                }}
                width="18"
                className={styles['icon']}
              />
            )}
          </div>

          {communitiesList.length > 1 && (
            <>
              <div className={cn(styles['input-wrapper'], styles['mobile-view'])}>
                <img src={HobnobSpaceIcon} alt="" width="18" />
                <HobnobSelect
                  value={eventForm.external_community_id}
                  options={communitiesList}
                  onChange={value => handleChange('community', value)}
                  textBlank
                  placeholder="Hobnob Space"
                />
              </div>

              <div className={cn(styles['input-wrapper'], 'web-view')}>
                <img src={HobnobSpaceIcon} alt="" width="18" />
                <Select
                  bordered={false}
                  size="small"
                  className={styles['input-inner']}
                  fieldNames={{ label: 'text' }}
                  defaultValue={
                    eventForm.external_community_id === null ? '' : eventForm.external_community_id
                  }
                  options={communitiesList}
                  onChange={value => handleChange('community', value)}
                />
              </div>
            </>
          )}
        </div>

        <div className={styles['section-wrapper']}>
          <h3>EVENT DETAILS</h3>

          <div className={styles['input-wrapper']}>
            <TextInputElement
              className={styles['input-inner']}
              onChange={event => handleChange('custom_host_name', event.target.value)}
              valid
              value={eventForm.custom_host_name}
              placeholder="Optional Custom Host Name"
            />
          </div>

          <div className={cn(styles['input-wrapper'], styles['textArea-wrapper'])}>
            <TextAreaElement
              className={styles['textArea-inner']}
              placeholder="Help your guests plan for your event! Tell them what to bring, where to park and anything else… 🍾🎈🎉🍰"
              onChangeComment={value => handleChange('description', value)}
              comment={eventForm.description || ''}
            />
          </div>
        </div>
      </div>

      <HobnobModal
        show={isLocationSearchModalVisible}
        onClose={() => setIsLocationSearchModalVisible(false)}
      >
        <LocationAutoCompleteInput
          onLocationSelected={result => {
            handleLocationSelected(result)
          }}
        />
      </HobnobModal>

      <DateModal visible={isDateModalVisible} onClose={() => setIsDateModalVisible(false)} />

      {isSettingModalVisible && <EventSetting onClose={() => setIsSettingModalVisible(false)} />}
    </>
  )
}

InputOptions.propTypes = {
  onSubmit: PropTypes.func
}

export default InputOptions
