import React from 'react'
import PropTypes from 'prop-types'

export default class VideoFlyer extends React.Component {
  _saveVideoRef = (ref) => { this._videoRef = ref }

  componentDidMount () {
    const { cancelHideFlyerTimer, unZoomFlyer } = this.props
    if (this._videoRef) {
      unZoomAfterVideoPlayed(this._videoRef, cancelHideFlyerTimer, unZoomFlyer)
    }
  }

  render () {
    const { className, flyer } = this.props

    // NOTE: muted should be sufficient, but it will get removed from the
    // element by React.
    // see: https://github.com/facebook/react/issues/6544
    return <video
      className={className}
      src={flyer.video_upload.video_url}
      ref={this._saveVideoRef}
      autoPlay
      loop
      muted
      playsInline
    />
  }
}

VideoFlyer.propTypes = {
  className: PropTypes.string.isRequired,
  flyer: PropTypes.object.isRequired,
  cancelHideFlyerTimer: PropTypes.func.isRequired,
  unZoomFlyer: PropTypes.func.isRequired
}

function unZoomAfterVideoPlayed (videoRef, cancelHideFlyerTimer, unZoomFlyer) {
  let alreadyPlayed = false
  const onPlaying = () => {
    cancelHideFlyerTimer()
    if (alreadyPlayed) {
      // We've looped
      unZoom()
    } else {
      // Play for a max of 5 seconds
      setTimeout(() => { unZoom() }, 5000)
    }
    alreadyPlayed = true
  }

  const unZoom = () => {
    unZoomFlyer()
    if (videoRef) {
      videoRef.removeEventListener('playing', onPlaying)
    }
  }

  videoRef.addEventListener('playing', onPlaying)
}
