import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SvgIcon from 'src/components/common/SvgIcon'
import LeftChevron from 'src/assets/icons/left_chevron.svg'
import NoticeRed from 'assets/icons/ico-notice-red.png'
import { message } from 'antd'
import { connect } from 'react-redux'
import Button from 'components/common/Button'
import AddGuestsList from './AddGuestsList/AddGuestsList'
import styles from './add-guests-page-web.scss'
import ActivateModal from '../../Components/ActivateModal/ActivateModal'
import PopConfirm from 'components/common/PopConfirm/PopConfirm'
import compWithAddGuestsList from '../HOC/WithAddGuestList'
import AddGuests from './AddGuests/AddGuests'
import PageContainerContext from 'services/contexts'
import { POPUP_BOX_TYPES_CONSTANTS } from 'src/constants'

const AddGuestsListHOC = compWithAddGuestsList(AddGuestsList)

class AddGuestsWeb extends Component {
  static contextType = PageContainerContext
  static displayName = 'AddGuestsWeb'

  state = {
    isOpenActivateModal: false,
    isOpenSaveChangeModal: false
  }

  addGuestsRef = React.createRef()

  handleBack = () => {
    const { addGuestsList, onChangeEventsModal } = this.props

    if (addGuestsList.length > 0) {
      this.setState({ isOpenSaveChangeModal: true })
    } else {
      onChangeEventsModal('details')
    }
  }

  isHaveValue = () => {
    const guestFormState = this.addGuestsRef.current.state
    if (guestFormState.first_name || guestFormState.last_name || guestFormState.contact_method) {
      message.error({
        content: 'Please add you guest to the Unsent Invites list first',
        icon: <img src={NoticeRed} width="12" />,
        className: styles['error-message']
      })
      return true
    }

    return false
  }

  handleSaveGuests = () => {
    if (this.isHaveValue()) {
      return
    }

    this.props.onSaveGuests()

    // if (this.props.event.state === 'draft') {
    //   this.setState({ isOpenActivateModal: false })
    // }
  }

  componentDidMount() {
    history.pushState(null, null, location.href)
    this.context.onChangePopupBox(POPUP_BOX_TYPES_CONSTANTS.addGuests)
    this.context.onChangePopupBoxCallBack(this.handleBack)
  }

  render() {
    const { addGuestsList, onRemoveNewGuests, onChangeEventsModal } = this.props
    const isEmpty = addGuestsList.length === 0

    return (
      <div className={styles['add-guests-container']}>
        <div className={styles['nav-bar-wrapper']}>
          <div className={styles['icon-wrapper']}>
            <SvgIcon
              icon={LeftChevron}
              className={styles['nav-bar-icon']}
              onClick={() => history.back()}
            />
          </div>
          <div className={styles['title-wrapper']}>Unsent</div>
          <div className={styles['button-wrapper']}>
            <Button
              disabled={isEmpty}
              className={styles['button-inner']}
              onClick={() => this.handleSaveGuests()}
            >
              save & send later
            </Button>
            {/* <Button
              disabled={isEmpty}
              className={styles['button-inner']}
              onClick={() => {
                if (event.state === 'draft') {
                  this.setState({
                    isOpenActivateModal: true
                  })
                } else {
                  this.handleSaveGuests(true)
                }
              }}
            >
              finish & send
            </Button> */}
          </div>
        </div>

        <AddGuests ref={this.addGuestsRef} />
        <AddGuestsListHOC />

        <ActivateModal
          visible={this.state.isOpenActivateModal}
          onClose={() => this.setState({ isOpenActivateModal: false })}
          onSaveGuests={this.handleSaveGuests}
        />

        <PopConfirm
          visible={this.state.isOpenSaveChangeModal}
          onClose={() => {
            this.setState({ isOpenSaveChangeModal: false })
            history.pushState(null, null, location.href)
          }}
          onConfirm={() => {
            onRemoveNewGuests()
            onChangeEventsModal('details')
          }}
          confirmText="yes"
        >
          All unsaved changes will be lost, do you want to proceed?
        </PopConfirm>
      </div>
    )
  }
}

AddGuestsWeb.propTypes = {
  addGuestsList: PropTypes.array,
  onSaveGuests: PropTypes.func,
  onRemoveNewGuests: PropTypes.func,
  onChangeEventsModal: PropTypes.func
}

function mapStateToProps(state) {
  return {
    addGuestsList: state.frontend.addGuestsList
  }
}

export default connect(mapStateToProps)(AddGuestsWeb)
