import PropTypes from 'prop-types'
import React from 'react'
import SimpleReactModal from 'simple-react-modal'
import classNames from 'classnames'

import SvgIcon from 'components/common/SvgIcon'

import styles from './hobnob-modal.scss'
import CancelIcon from 'src/assets/icons/cancel-icon.svg'
import Button from 'src/components/common/Button'

export default class HobnobModal extends React.Component {
  _onClose = e => {
    e.preventDefault()
    this.props.onClose(e)
  }

  handleConfirm = () => {
    this.props.onConfirm?.()
  }

  render() {
    const {
      cancelButtonInContainer,
      cancelButtonClassName,
      containerClassName,
      show,
      style,
      onClose,
      onCancel,
      title,
      subTitle,
      contentClassName,
      showCancelIcon,
      closeOnOuterClick,
      visibleButton,
      cancelText,
      confirmText,
      cancelClassName,
      confirmClassName,
      buttonContainerClassName
    } = this.props
    const cancelButtonContainerStyles = cancelButtonInContainer ? 'cancel-button-container' : ''
    const defaultContainerStyles = {
      margin: 0,
      padding: '10px 10px 30px',
      borderRadius: '10px',
      width: '90vw',
      maxWidth: '500px'
    }

    // Keep in sync with HobnobHud
    const modalStyle = {
      transition: 'opacity 0.25s ease-in-out',
      background: 'rgba(0, 0, 0, 0.4)',
      fontFamily: null,
      ...style
    }

    const containerStyles = {
      ...defaultContainerStyles,
      ...this.props.containerStyles
    }

    return (
      <SimpleReactModal
        show={show}
        onClose={onClose}
        style={modalStyle}
        containerClassName={classNames(styles['container'], containerClassName)}
        containerStyle={containerStyles}
        // transitionSpeed={250}
        closeOnOuterClick={closeOnOuterClick}
      >
        {showCancelIcon ? (
          <div
            className={classNames(
              styles[cancelButtonContainerStyles],
              styles['cancel-icon-wrapper'],
              cancelButtonClassName
            )}
          >
            <SvgIcon
              className={styles['cancel-icon']}
              icon={CancelIcon}
              padding="5px"
              onClick={this._onClose}
            />
          </div>
        ) : null}

        <div className={classNames(styles['content'], contentClassName)}>
          {title ? <h2 className={styles['title']}>{title}</h2> : null}
          {subTitle ? <div>{subTitle}</div> : null}
          {this.props.children}
        </div>

        {visibleButton && (
          <div className={classNames(styles['button-wrapper'], buttonContainerClassName)}>
            <Button
              onClick={onCancel || onClose}
              className={classNames(
                styles['button-inner'],
                styles['button-inner-cancel'],
                cancelClassName
              )}
            >
              {cancelText}
            </Button>
            <Button
              onClick={this.handleConfirm}
              className={classNames(styles['button-inner'], confirmClassName)}
            >
              {confirmText}
            </Button>
          </div>
        )}
      </SimpleReactModal>
    )
  }
}

HobnobModal.propTypes = {
  cancelButtonInContainer: PropTypes.bool,
  containerClassName: PropTypes.string,
  containerStyles: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  style: PropTypes.object,
  subTitle: PropTypes.oneOfType([PropTypes.element, PropTypes.any]),
  contentClassName: PropTypes.string,
  showCancelIcon: PropTypes.bool,
  closeOnOuterClick: PropTypes.bool,
  cancelButtonClassName: PropTypes.string,
  visibleButton: PropTypes.bool,
  cancelText: PropTypes.string || PropTypes.node,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string || PropTypes.node,
  onConfirm: PropTypes.func,
  confirmClassName: PropTypes.string,
  cancelClassName: PropTypes.string,
  buttonContainerClassName: PropTypes.string
}
HobnobModal.defaultProps = {
  cancelButtonInContainer: false,
  visibleButton: false,
  showCancelIcon: true,
  closeOnOuterClick: true,
  cancelText: 'cancel',
  confirmText: 'confirm'
}
