import React, { Component } from 'react'
import styles from './add-guests-list.scss'
import HeaderBar from 'src/components/HeaderBar/HeaderBar'
import addIcon from 'assets/icons/add-icon.png'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import { hostSelector } from 'src/selectors'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ActionSheet from 'components/common/ActionSheet/ActionSheet'
import EditModal from '../../../Components/EditModal/EditModal'
import Button from 'components/common/Button'
import { PHONE_NUMBER_CONTACT_METHOD, EMAIL_CONTACT_METHOD } from 'src/services/contact_methods'
import ActivateModal from '../../../Components/ActivateModal/ActivateModal'
import PopConfirm from 'components/common/PopConfirm/PopConfirm'
import compWithAddGuestEditModal from '../../HOC/WithAddGuestEditModal'
import addGuestsContext from '../addGuestsContext'
import { isEmptyString } from 'services/utils'
import SearchInput from '../../../Components/SearchInput/SearchInput'
import cn from 'classnames'
import { fullName } from 'services/user_helpers'
import { POPUP_BOX_TYPES_CONSTANTS } from 'src/constants'
import parsePhoneNumber from 'libphonenumber-js'

const EditModalHOC = compWithAddGuestEditModal(EditModal)

class AddGuestsList extends Component {
  static contextType = addGuestsContext
  static displayName = 'AddGuestsList'

  state = {
    selectedGuest: null,
    isOpenActionSheet: false,
    isOpenRemoveModal: false,
    isOpenActivateModal: false,
    isOpenSaveChangeModal: false
  }

  editModalRef = React.createRef()

  handleClickGuest = guest => {
    this.setState({ isOpenActionSheet: true, selectedGuest: guest })
  }

  handleOpenEditModal = () => {
    this.editModalRef.current.openModal(this.state.selectedGuest)
    this.setState({ isOpenActionSheet: false, selectedGuest: null })
  }

  handleRemoveGuest = () => {
    this.props.onRemoveGuest(this.state.selectedGuest)
    this.setState({ isOpenActionSheet: false, selectedGuest: null })
  }

  handleRemoveAllGuests = () => {
    this.props.onRemoveAllGuests()
    this.setState({ isOpenRemoveModal: false })
  }

  handleSaveGuests = () => {
    this.props.onSaveGuests()

    // if (this.context.event.state === 'draft') {
    //   this.setState({ isOpenActivateModal: false })
    // }
  }

  getContactText = item => {
    const { contact_method_type, contact_method_display_name } = item
    switch (contact_method_type) {
      case PHONE_NUMBER_CONTACT_METHOD:
        let str = contact_method_display_name

        if (contact_method_display_name?.startsWith('1')) {
          const phoneNumber = parsePhoneNumber(contact_method_display_name, 'US')
          str = phoneNumber.formatNational()
        }

        return str
      case EMAIL_CONTACT_METHOD:
        return contact_method_display_name
    }
  }

  handleBack = () => {
    const { addGuestsList, onChangeEventsModal } = this.props
    if (addGuestsList.length > 0) {
      this.setState({ isOpenSaveChangeModal: true })
    } else {
      onChangeEventsModal('details')
    }
  }

  componentDidMount() {
    this.context.onChangePopupBox(POPUP_BOX_TYPES_CONSTANTS.addGuestsList)
    this.context.onChangePopupBoxCallBack(this.handleBack)
  }

  renderDisplayName = item => {
    const { first_name, last_name, display_name = '' } = item

    let displayName = display_name

    if (!isEmptyString(first_name + last_name)) {
      displayName = `${first_name || ''} ${last_name || ''}`
    }

    return displayName
  }

  render() {
    const {
      host,
      addGuestsList,
      onInputChange,
      searchValue,
      searchGuestsList,
      onRemoveNewGuests,
      onChangeEventsModal
    } = this.props
    const { selectedGuest, isOpenActionSheet } = this.state
    const renderGuestsList = searchValue ? searchGuestsList : addGuestsList
    const isEmpty = addGuestsList.length === 0
    const event = this.context.event

    return (
      <div className={styles['add-guests-list-container']}>
        <HeaderBar
          width={80}
          backIcon
          right={
            <div
              onClick={() => this.setState({ isOpenRemoveModal: true })}
              className={cn(styles['right-text'], { [styles['right-text-hidden']]: isEmpty })}
            >
              remove all
            </div>
          }
        >
          <div className={styles['header-title']}>
            <span className={styles['title']}>Unsent Invites</span>
            <span className={styles['subtitle']}>{event.name}</span>
          </div>
        </HeaderBar>

        <div className={styles['content-container']}>
          <SearchInput value={searchValue} onChange={onInputChange} />

          <div
            className={styles['add-button']}
            onClick={() => this.context.onChangeComponent('AddGuests')}
          >
            <img src={addIcon} width="14" />
            <span>add guests</span>
          </div>

          <div className={styles['group-wrapper']}>
            <div className={styles['group-title']}>host</div>
            <div className={styles['list-item']}>
              <UserAvatarContainer user={host} size={34} />
              <div className={styles['info-wrapper']}>
                <span className={styles['name']}>{event.custom_host_name || fullName(host)}</span>
                <span className={styles['contact-text']}>Event Host</span>
              </div>
            </div>
          </div>

          {!isEmpty && (
            <div className={styles['group-wrapper']}>
              <div className={styles['group-title']}>guests</div>
              <ul className={styles['group-content']}>
                {renderGuestsList.map(item => {
                  return (
                    <li
                      className={styles['list-item']}
                      key={item.key}
                      onClick={() => this.handleClickGuest(item)}
                    >
                      <UserAvatarContainer user={item} url={item.avatar_url} size={34} />
                      <div className={styles['info-wrapper']}>
                        <span className={styles['name']}>{this.renderDisplayName(item)}</span>
                        <span className={styles['contact-text']}>{this.getContactText(item)}</span>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
        </div>

        <div className={styles['button-wrapper']}>
          <Button
            disabled={isEmpty}
            className={styles['button-inner']}
            onClick={() => this.handleSaveGuests()}
          >
            save & send later
          </Button>
          {/* <Button
            disabled={isEmpty}
            className={styles['button-inner']}
            onClick={() => {
              if (event.state === 'draft') {
                this.setState({
                  isOpenActivateModal: true
                })
              } else {
                this.handleSaveGuests(true)
              }
            }}
          >
            finish & send
          </Button> */}
        </div>

        <EditModalHOC
          ref={this.editModalRef}
          onEditGuest={this.props.onEditGuest}
          onEditGuestWithKey={this.props.onEditGuestWithKey}
        />

        <ActionSheet
          visible={isOpenActionSheet}
          onClose={() => this.setState({ isOpenActionSheet: false })}
          headerNode={
            <div className={styles['action-sheet-header']}>
              <span className={styles['title']}>{this.renderDisplayName(selectedGuest || {})}</span>
              <span className={styles['subtitle']}>
                {selectedGuest?.contact_method_display_name}
              </span>
            </div>
          }
        >
          <ul className="action-sheet-ul">
            {!selectedGuest?.verified && (
              <li onClick={this.handleOpenEditModal} className="action-sheet-li">
                edit contact
              </li>
            )}
            <li
              onClick={this.handleRemoveGuest}
              className="action-sheet-li"
              style={{ color: '#d00218' }}
            >
              remove guest
            </li>
          </ul>
        </ActionSheet>

        <ActivateModal
          visible={this.state.isOpenActivateModal}
          onClose={() => this.setState({ isOpenActivateModal: false })}
          onSaveGuests={this.handleSaveGuests}
        />

        <PopConfirm
          visible={this.state.isOpenRemoveModal}
          onClose={() => this.setState({ isOpenRemoveModal: false })}
          onConfirm={this.handleRemoveAllGuests}
          confirmText="remove all"
          type="delete"
        >
          Are you sure you want to remove all the guests from this event?
        </PopConfirm>

        <PopConfirm
          visible={this.state.isOpenSaveChangeModal}
          onClose={() => {
            this.setState({ isOpenSaveChangeModal: false })
            history.pushState(null, null, location.href)
          }}
          onConfirm={() => {
            onRemoveNewGuests()
            onChangeEventsModal('details')
          }}
          confirmText="yes"
          type="cancel"
          cancelType="normal"
        >
          All unsaved changes will be lost, do you want to proceed?
        </PopConfirm>
      </div>
    )
  }
}

AddGuestsList.propTypes = {
  host: PropTypes.object,

  /** HOC props */
  addGuestsList: PropTypes.array,
  onRemoveGuest: PropTypes.func,
  onRemoveAllGuests: PropTypes.func,
  onInputChange: PropTypes.func,
  searchValue: PropTypes.string,
  searchGuestsList: PropTypes.array,
  onEditGuest: PropTypes.func,
  onEditGuestWithKey: PropTypes.func,

  /** father props */
  onSaveGuests: PropTypes.func,
  onRemoveNewGuests: PropTypes.func,
  onChangeEventsModal: PropTypes.func
}

function mapStateToProps(state) {
  return {
    host: hostSelector(state)
  }
}

export default connect(mapStateToProps)(AddGuestsList)
