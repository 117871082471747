import React, { Component } from 'react'
import PropTypes from 'prop-types'
import HobnobModal from 'components/common/HobnobModal'
import TextInputElement from 'components/forms/TextInputElement'
import styles from './edit-modal.scss'
import CheckBoxInput from 'components/forms/CheckboxInput'
import WarnIcon from 'assets/icons/warn-icon.svg'
import SvgIcon from 'src/components/common/SvgIcon'
import { Tooltip } from 'antd'
import { EMAIL_CONTACT_METHOD } from 'services/contact_methods'
import cn from 'classnames'
import { usersSelector } from 'src/selectors'
import { connect } from 'react-redux'

class EditModal extends Component {
  visibleCohost = () => {
    const { guestInfo, isHost, event } = this.props

    if (
      isHost &&
      (guestInfo?.delivery_state === 'delivered' || guestInfo?.delivery_state === 'viewed') &&
      event.host !== guestInfo?.guest
    ) {
      return true
    }

    return false
  }

  visibleFullName = () => {
    const { users, guestInfo } = this.props
    const user = users[guestInfo?.guest]

    if (user?.verified) {
      return false
    }

    return true
  }

  render() {
    const {
      first_name,
      last_name,
      isOpenEditModal,
      onClose,
      onInputChange,
      onSave,
      cohost,
      guestInfo
    } = this.props

    const disableCohost = guestInfo?.contact_method.type === EMAIL_CONTACT_METHOD

    return (
      <HobnobModal
        closeOnOuterClick={false}
        title="Edit Contact"
        show={isOpenEditModal}
        onClose={onClose}
        visibleButton
        confirmText="save"
        onConfirm={onSave}
        buttonContainerClassName={styles['button-container']}
        cancelClassName={styles['cancel-button']}
      >
        {this.visibleFullName() && (
          <div className={styles['input-wrapper']}>
            <TextInputElement
              className={styles['input-inner']}
              placeholder="First Name"
              onChange={e => onInputChange('first_name', e.target.value)}
              valid
              value={first_name}
            />
            <TextInputElement
              className={styles['input-inner']}
              placeholder="Last Name"
              onChange={e => onInputChange('last_name', e.target.value)}
              valid
              value={last_name}
            />
          </div>
        )}

        {this.visibleCohost() && (
          <div className={styles['cohost-wrapper']}>
            <div
              className={cn(styles['cohost-inner'], {
                [styles['cohost-inner-disabled']]: disableCohost
              })}
            >
              <CheckBoxInput
                disabled={disableCohost}
                className={styles['check-inner']}
                onChange={() => onInputChange('cohost', !cohost)}
                checked={cohost}
                size={20}
              />
              <span>Add as Cohost</span>
            </div>

            <Tooltip title="Cohost must have a phone number" zIndex="100000">
              <SvgIcon icon={WarnIcon} className={styles['warn-icon']} />
            </Tooltip>
          </div>
        )}
      </HobnobModal>
    )
  }
}

EditModal.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  cohost: PropTypes.bool,
  isOpenEditModal: PropTypes.bool,
  isHost: PropTypes.bool,
  guestInfo: PropTypes.object,
  event: PropTypes.object,
  users: PropTypes.object,
  onClose: PropTypes.func,
  onInputChange: PropTypes.func,
  onSave: PropTypes.func
}

function mapStateToProps(state) {
  return {
    users: usersSelector(state)
  }
}

export default connect(mapStateToProps)(EditModal)
