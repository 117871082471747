/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react'
import styles from './community-group.scss'
import PropTypes from 'prop-types'
import RightIcon from 'assets/icons/right-icon.png'
import EmptyLogo from 'assets/icons/empty_state_millenials_at_festival.png'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import CheckBoxInput from 'components/forms/CheckboxInput'
import cn from 'classnames'

class CommunityGroup extends Component {
  state = {
    showAllCommunity: false,
    showAllEvent: false
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.eventPageInfo?.start_cursor !== this.props.eventPageInfo?.start_cursor &&
      !this.props.eventPageInfo?.has_next_page
    ) {
      this.setState({ showAllEvent: true })
    }
  }
  componentDidMount() {
    if (this.props.communityList.length <= 3) {
      this.setState({ showAllCommunity: true })
    }

    if (!this.props.eventPageInfo?.has_next_page) {
      this.setState({ showAllEvent: true })
    }
  }
  isAllSelected = members => {
    return members.every(item => item.isSelected)
  }

  render() {
    const { showAllCommunity, showAllEvent } = this.state
    const { communityList, eventList, onGroupChecked, onMemberChecked, onExpand, onLoadEvent } =
      this.props
    const visibleCommunity = communityList.length > 0
    const visibleEvent = eventList.length > 0
    const visibleContent = visibleCommunity || visibleEvent

    if (!visibleContent) {
      return (
        <div className={styles['empty-content']}>
          <img src={EmptyLogo} alt="" width="243" />
          <div className={styles['title']}>no hobnob contacts</div>
          <div className={styles['subtitle']}>
            You can add guests by entering an email or phone number in the form field above.
          </div>
        </div>
      )
    }

    return (
      <>
        {visibleCommunity && (
          <div className={styles['group-wrapper']}>
            <div className={styles['group-title']}>spaces</div>
            <div className={styles['group-content']}>
              {communityList.map((item, index) => {
                if (!showAllCommunity && index > 2) return null
                return (
                  <div className={styles['community-wrapper']} key={item.id}>
                    <div className={styles['list-item']}>
                      <img
                        src={item.thumbnail_url}
                        alt=""
                        width="44"
                        className={styles['avatar']}
                      />
                      <div className={styles['content']}>
                        <div className={styles['name']}>
                          <span>{item.name}</span>
                          <img
                            src={RightIcon}
                            width="16"
                            className={item.isExpand ? styles['group-expand'] : ''}
                            onClick={() => onExpand({ index, key: 'communityList' })}
                          />
                        </div>
                        <div className={styles['text']}>{item.member_count} members</div>
                      </div>
                      <CheckBoxInput
                        size={22}
                        checked={this.isAllSelected(item.members)}
                        onChange={() => onGroupChecked(item.members)}
                      />
                    </div>
                    {item.isExpand && (
                      <ul className={styles['member-wrapper']}>
                        {item.members.map(member => (
                          <li
                            key={member.key}
                            className={cn(styles['member-item'], {
                              [styles['member-item-added']]: member.isAddedInEvent
                            })}
                          >
                            <UserAvatarContainer
                              user={{ app_user: member.app_user }}
                              url={member.avatar_url}
                              size={34}
                            />
                            <div className={styles['name']}>
                              {`${member.first_name} ${member.last_name}`}
                            </div>
                            <CheckBoxInput
                              size={22}
                              checked={member.isSelected}
                              disabled={member.isAddedInEvent}
                              onChange={() => onMemberChecked(member)}
                            />
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )
              })}
            </div>
            {!showAllCommunity && (
              <div
                className={styles['more-button']}
                onClick={() => this.setState({ showAllCommunity: true })}
              >
                see more
              </div>
            )}
          </div>
        )}

        {visibleEvent && (
          <div className={styles['group-wrapper']}>
            <div className={styles['group-title']}>previous guest lists</div>
            <div className={styles['group-content']}>
              {eventList.map((item, index) => (
                <div className={styles['community-wrapper']} key={item.id}>
                  <div className={styles['list-item']}>
                    <img src={item.thumbnail_url} alt="" width="44" className={styles['avatar']} />
                    <div className={styles['content']}>
                      <div className={styles['name']}>
                        <span>{item.name}</span>
                        <img
                          onClick={() => onExpand({ index, key: 'eventList' })}
                          className={item.isExpand ? styles['group-expand'] : ''}
                          src={RightIcon}
                          width="16"
                        />
                      </div>
                      <div className={styles['text']}>{item.member_count} members</div>
                    </div>
                    <CheckBoxInput
                      size={22}
                      checked={this.isAllSelected(item.members)}
                      onChange={() => onGroupChecked(item.members)}
                    />
                  </div>
                  {item.isExpand && (
                    <ul className={styles['member-wrapper']}>
                      {item.members.map(member => (
                        <li
                          key={member.key}
                          className={cn(styles['member-item'], {
                            [styles['member-item-added']]: member.isAddedInEvent
                          })}
                        >
                          <UserAvatarContainer
                            user={{ app_user: member.app_user }}
                            url={member.avatar_url}
                            size={34}
                          />
                          <div className={styles['name']}>
                            {`${member.first_name} ${member.last_name}`}
                          </div>
                          <CheckBoxInput
                            size={22}
                            checked={member.isSelected}
                            disabled={member.isAddedInEvent}
                            onChange={() => onMemberChecked(member)}
                          />
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
            {!showAllEvent && (
              <div className={styles['more-button']} onClick={onLoadEvent}>
                see more
              </div>
            )}
          </div>
        )}
      </>
    )
  }
}

CommunityGroup.propTypes = {
  communityList: PropTypes.array,
  eventList: PropTypes.array,
  eventPageInfo: PropTypes.object,
  onGroupChecked: PropTypes.func,
  onMemberChecked: PropTypes.func,
  onExpand: PropTypes.func,
  onLoadEvent: PropTypes.func
}

export default CommunityGroup
