import { FlyerLayoutAbstract } from './flyer-layout.abstract'

export default class Flyer_layout_center_boxed_laurel_037LayoutModel extends FlyerLayoutAbstract {
  flourishes = [
    {
      name: 'laurel_box',
      left: 33.5,
      top: 149.5,
      width: 253,
      height: 269
    },
    {
      name: 'laurel_laurel',
      left: 144.5,
      top: 436,
      width: 31,
      height: 17
    },
    {
      name: 'laurel_laurel',
      left: 144.5,
      top: 114,
      width: 31,
      height: 17
    },
    {
      name: 'laurel_squiggle',
      left: 148.5,
      top: 260,
      width: 23,
      height: 13
    }
  ]

  textLayouts = {
    event_name_1: {
      top: 170,
      size: 40,
      width: 180,
      height: 85,
      align: 'center',
      rowGap: 30
    },
    location_1: {
      top: 344,
      size: 14,
      width: 230,
      align: 'center',
      height: 18
    },
    location_2: {
      top: 362,
      size: 14,
      width: 230,
      align: 'center',
      height: 18
    },
    location_3: {
      top: 380,
      size: 14,
      width: 230,
      align: 'center',
      height: 18
    }
  }

  dateLayouts = {
    date_1: {
      top: 285,
      daySize: 14,
      width: 230,
      lineHeight: 8,
      align: 'center'
    }
  }

  constructor(layout, eventData) {
    super(layout, eventData)
    this.init()
  }

  getDateTexts() {
    const { daySize, top, align, lineHeight, width } = this.dateLayouts.date_1

    const dateInformation = this.formatDates({
      day: 'ddd, MMM D, h:mma',
      time: 'h:mma'
    })

    const { formattedStart, formattedEnd, isSameDay } = dateInformation
    let dates = []
    let textTop = top

    // 开始时间
    dates.push({
      text: formattedStart,
      width,
      align,
      top: textTop,
      color: this.dateProps.date1Props.color.replace('0x', '#'),
      font: this.dateProps.date1Props.font.replace(/\s+/g, '-'),
      size: daySize,
      height: 20
    })

    textTop += daySize + lineHeight
    if (!this.eventData.ends_at) {
      return { type: 'date', dates }
    }

    // 时间间隔
    dates.push({
      text: 'till',
      left: isSameDay ? 100 : 50,
      size: daySize,
      width: 30,
      top: textTop,
      color: this.dateProps.dateSeparatorProps.color.replace('0x', '#'),
      font: this.dateProps.dateSeparatorProps.font.replace(/\s+/g, '-')
    })

    // 结束时间
    dates.push({
      text: formattedEnd,
      top: textTop,
      width,
      align,
      color: this.dateProps.date2Props.color.replace('0x', '#'),
      font: this.dateProps.date2Props.font.replace(/\s+/g, '-'),
      size: daySize,
      height: 20
    })
    return { type: 'date', dates }
  }
}
