import React, { useState, useEffect, useRef } from 'react'
import DashboardContainer from 'containers/DashboardContainer'
import { useDispatch, useSelector } from 'react-redux'
import FirstStep from './FirstStep/FirstStep'
import SelectFlyerStep from './SelectFlyerStep/SelectFlyerStep'
import ThirdStep from './ThirdStep/ThirdStep'
import DraftStep from './DraftStep/DraftStep'
import EventSetting from './EventSetting/EventSetting'
import HobnobModal from 'components/common/HobnobModal'
import cn from 'classnames'
import styles from './create-event.scss'
import {
  fetchCreateEvent,
  fetchUploadFlyer,
  fetchMeWithCommunities,
  resetEventForm
} from 'src/actions/event'
import { fetchFlyerDesigns } from 'src/actions/flyer'
import FlyerFonts from 'assets/frontend-json/flyer-fonts.json'
import LoadingComponent from 'components/common/LoadingComponent/LoadingComponent'
import { routerActions } from 'react-router-redux'
import uuid from 'uuid'
import moment from 'moment'
import 'moment-timezone'

import BackIcon from 'src/assets/icons/back_button.png'
import SettingIcon from 'src/assets/icons/setting-icon.png'
import CloseIcon from 'src/assets/icons/close-icon.png'
import ImagesIcon from 'src/assets/icons/icon_images.png'
import { redirectDomain } from 'services/url_helpers'
import CustomFlyerPicker from 'src/components/common/CustomFlyerPicker'
import { message } from 'antd'
import { showLogin } from 'src/actions/login'
import { planSelector, flyerSelector } from 'src/selectors'

const stepList = [
  { title: 'Create Event', step: 1 },
  { title: 'Design', step: 2 },
  { title: 'Event Details', step: 3 }
]

const CreateEventContainer = () => {
  const dispatch = useDispatch()
  const eventForm = useSelector(state => state.frontend.eventForm)
  const changeEventFormStatus = useSelector(state => state.frontend.changeEventFormStatus)
  const { customFlyerURL } = useSelector(flyerSelector)
  const userId = useSelector(state => state.userId)
  const { visible: isPlanModalVisible } = useSelector(planSelector)

  const onNavigationList = () => dispatch(routerActions.push({ pathname: '/e/list' }))
  const onResetEventForm = () => dispatch(resetEventForm())
  const onRequireLogin = params => dispatch(showLogin(params))

  const [currentStep, setCurrentStep] = useState(1)
  const [isOpenSetting, setIsOpenSetting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenToolTip, setIsOpenToolTip] = useState(false)

  const secondStepRef = useRef(null)

  useEffect(() => {
    dispatch(fetchFlyerDesigns())
    dispatch(fetchMeWithCommunities())
    onLoadFonts()
  }, [])

  useEffect(() => {
    if (isPlanModalVisible) {
      setIsOpenSetting(false)
    }
  }, [isPlanModalVisible])

  const onLoadFonts = () => {
    const fonts = FlyerFonts.fonts
    for (let font of fonts) {
      let myFonts = new FontFace(font.slice(0, -4), `url(../../../assets/Fonts/${font})`)
      myFonts.load().then(loadFace => {
        document.fonts.add(loadFace)
      })
    }
  }

  const handleClickBack = () => {
    if (currentStep === 1) {
      changeEventFormStatus === 'updated' ? setIsOpenToolTip(true) : onNavigationList()
    } else if (currentStep === 2) {
      setCurrentStep(1)
      setIsOpenSetting(false)
    } else if (currentStep === 3) {
      setCurrentStep(2)
      setIsOpenSetting(false)
    } else if (currentStep === 4) {
      setCurrentStep(3)
      setIsOpenSetting(false)
    }
  }

  const handleRightButton = () => {
    if (eventForm.flyer_design_number) {
      onResetEventForm()
      onNavigationList()
    } else {
      setCurrentStep(2)
    }
    setIsOpenToolTip(false)
  }

  const handleLeftButton = () => {
    setIsOpenToolTip(false)
    if (eventForm.flyer_design_number) {
      secondStepRef.current?.selectedFlyerRef?.canvasToImage()
      handleCreateEvent('save')
    } else {
      onResetEventForm()
      onNavigationList()
    }
  }

  const handleCreateEvent = type => {
    if (userId) {
      handleCreateEventV2(type)
    } else {
      const params = {
        loginModalSubtitle: 'In order for you to create an Event, we need to confirm your number'
      }
      onRequireLogin(params)
    }
  }

  const handleCreateEventV2 = type => {
    const eventParams = { ...eventForm }

    delete eventParams.checkedFlyer

    if (eventParams.starts_at) {
      eventParams.starts_at = moment.tz(eventParams.starts_at, eventParams.timezone).format()
      if (eventParams.ends_at) {
        eventParams.ends_at = moment.tz(eventParams.ends_at, eventParams.timezone).format()
      }
    }

    if (eventForm.child_count_enabled) {
      eventParams.ticket_types_attributes = [
        {
          id: uuid.v4(),
          label: 'adult',
          price: 0
        },
        {
          id: uuid.v4(),
          label: 'child',
          price: 0
        }
      ]
    } else {
      eventParams.ticket_types_attributes = [
        {
          id: uuid.v4(),
          label: 'adult',
          price: 0
        }
      ]
    }

    setIsLoading(true)
    dispatch(
      fetchCreateEvent({
        eventForm: eventParams,
        onSuccessCallback: data => {
          handleUploadFlyer(data.event.id, data.event.url, type)
        },
        onErrorCallback: () => {
          setIsLoading(false)
          message.error('Creation failed.')
        }
      })
    )
  }

  const handleUploadFlyer = (eventId, eventUrl, type) => {
    dispatch(
      fetchUploadFlyer({
        eventId,
        design_number: eventForm.flyer_design_number,
        customBgDataURL: customFlyerURL,
        onSuccessCallback: async data => {
          const requests = []
          if (data?.image_flyer?.image_upload?.presigned_url) {
            const flyerImage = await fetch(eventForm.checkedFlyer)
            const flyerImageBlob = await flyerImage.blob()
            requests.push(
              fetch(data.image_flyer.image_upload.presigned_url, {
                headers: {
                  'Content-Type': 'image/png'
                },
                method: 'PUT',
                body: flyerImageBlob
              })
            )
          }

          if (data?.image_flyer?.image_bg_upload?.presigned_url) {
            const flyerBgImage = await fetch(customFlyerURL)
            const flyerBgImageBlob = await flyerBgImage.blob()
            requests.push(
              fetch(data.image_flyer.image_bg_upload.presigned_url, {
                headers: {
                  'Content-Type': 'image/png'
                },
                method: 'PUT',
                body: flyerBgImageBlob
              })
            )
          }

          if (requests.length === 0) {
            setIsLoading(false)
            alert('Upload Error.')
            return
          }

          Promise.all(requests).then(responses => {
            if (responses.every(response => response.status === 200)) {
              onResetEventForm()
              setTimeout(() => {
                if (type === 'save') {
                  location.href = window.location.origin + '/e/list'
                } else {
                  redirectDomain(eventUrl + '/')
                }
              }, 5000)
            } else {
              setIsLoading(false)
              alert('Upload Error.')
            }
          })
        },
        onErrorCallback: () => setIsLoading(false)
      })
    )
  }

  const renderHeaderLeft = () => {
    return (
      <img src={currentStep === 1 ? CloseIcon : BackIcon} onClick={handleClickBack} width="12" />
    )
  }

  const renderRightButtons = () => {
    if (currentStep === 2) {
      return (
        <section className={styles['action-container']}>
          <div className={styles['action-item']}>
            <CustomFlyerPicker>
              <img src={ImagesIcon} width={'24px'} />
            </CustomFlyerPicker>
          </div>
        </section>
      )
    } else if (currentStep === 3) {
      return <img src={SettingIcon} onClick={() => setIsOpenSetting(true)} width="20" />
    }
  }

  const renderToolTipText = () => {
    if (eventForm.flyer_design_number) {
      return {
        title: 'would you like to save your invite for later?',
        leftButton: 'Save Draft',
        rightButton: 'Delete Draft'
      }
    } else {
      return {
        title: 'Must Select Flyer to Save Draft',
        leftButton: "Don't Save",
        rightButton: 'Select flyer'
      }
    }
  }

  const tooltipText = renderToolTipText()
  const step = stepList.find(item => item.step === currentStep)

  return (
    <DashboardContainer
      title={stepList.find(item => item.step === currentStep)?.title}
      left={renderHeaderLeft()}
      right={renderRightButtons()}
    >
      <div className={styles['create-event-container']}>
        <div className={cn(styles['back-button'])} onClick={handleClickBack}>
          <img src={currentStep === 1 ? CloseIcon : BackIcon} width="12" />
        </div>

        <div className={styles['create-wrapper']}>
          <header className={styles['create-header-wrapper']}>
            <h1>{step?.title}</h1>
            {currentStep === 3 && (
              <div
                className={styles['setting-label-wrapper']}
                onClick={() => setIsOpenSetting(true)}
              >
                <img src={SettingIcon} width="20" />
                <span>settings</span>
              </div>
            )}
          </header>

          <ul className={styles['progress-wrapper']}>
            {stepList.map(item => (
              <li
                key={item.step}
                className={cn(styles['progress-item'], {
                  [styles['progress-item-checked']]: currentStep >= item.step
                })}
              />
            ))}
          </ul>

          <div
            className={cn(styles['create-step-wrapper'], {
              [styles['create-step-hidden']]: currentStep !== 1
            })}
          >
            <FirstStep onButtonClick={() => setCurrentStep(2)} />
          </div>

          {currentStep === 2 && (
            <div className={styles['create-step-wrapper']}>
              <SelectFlyerStep
                onSecondStepRef={ref => (secondStepRef.current = ref)}
                onButtonClick={() => setCurrentStep(3)}
              />
            </div>
          )}

          <div
            className={cn(styles['create-step-wrapper'], {
              [styles['create-step-hidden']]: currentStep !== 3
            })}
          >
            <ThirdStep onCreateEvent={handleCreateEvent} />
          </div>
        </div>

        {currentStep === 4 && <DraftStep onClickBack={handleClickBack} />}

        {isOpenSetting && currentStep === 3 && (
          <EventSetting onClose={() => setIsOpenSetting(false)} />
        )}

        <HobnobModal
          style={{ display: 'flex', alignItem: 'center' }}
          containerStyles={{ margin: 'auto' }}
          show={isOpenToolTip}
          visibleButton
          cancelText={tooltipText.leftButton}
          confirmText={tooltipText.rightButton}
          onCancel={handleLeftButton}
          onConfirm={handleRightButton}
          onClose={() => setIsOpenToolTip(false)}
        >
          <div className={styles['tooltip-container']}>
            <div className={styles['title']}>Unsaved Changes</div>
            <div className={styles['subTitle']}>{tooltipText.title}</div>
          </div>
        </HobnobModal>
      </div>
      {isLoading && <LoadingComponent dark />}
    </DashboardContainer>
  )
}

export default CreateEventContainer
