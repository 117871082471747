import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SubscribeSuccessImg from 'assets/icons/subscribe-success-img.png'
import FiveStarIcon from 'assets/icons/five-star.svg'
import SvgIcon from 'src/components/common/SvgIcon'
import CommonFrame from '../CommonFrame/CommonFrame'
import Button from 'src/components/common/Button'
import { changeVisible } from 'src/actions/globalLayer'
import { userSelector } from 'src/selectors'

import styles from './subscribe-success.scss'

const SubscribeSuccess = () => {
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const { plan } = user.current_subscription

  const actionLayer = () => {
    return (
      <div className={styles['action-container']}>
        <h1>"Absolutely worth it!"</h1>
        <SvgIcon icon={FiveStarIcon} width="161px" />
        <p><b>{plan.name.replace('Party ', '')}</b> is loved by casual and professional event and group organizers alike! </p>
        <p className={styles['p-section']}>
          Ready to save everyone time and look great in the process?
        </p>

        <Button className={styles['button-inner']} onClick={() => dispatch(changeVisible(false))}>
          Let’s go
        </Button>
      </div>
    )
  }

  const descLayer = () => {
    return <img src={SubscribeSuccessImg} width="100%" alt="" />
  }

  return <CommonFrame actionLayer={actionLayer()} descLayer={descLayer()} />
}

export default SubscribeSuccess
