import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { eventSelector, hostSelector, communityListSelector } from 'src/selectors'

import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import { hostFullName } from 'src/services/event_helpers'
import EventDateTime from 'components/details/EventDateTime'
// import { sortedProcessedMedia } from 'services/media_helpers'
// import { Image } from 'antd'
// import { setPreviewGalleries, setPreviewIndex } from 'src/actions/frontend'

import EventVenue from 'src/components/details/EventVenue'
import ChatUsers from './ChatUsers/ChatUsers'
import LoadingComponent from 'src/components/common/LoadingComponent/LoadingComponent'

import styles from './chat-section.scss'

const ChatSection = () => {
  // const dispatch = useDispatch()
  const host = useSelector(hostSelector)
  const event = useSelector(eventSelector)
  // const media = useSelector(mediaSelector)
  // const [mediaList, setMediaList] = useState([])
  const [isUnreadLoading, setIsUnreadLoading] = useState(false)
  const communityList = useSelector(communityListSelector)
  const communityInformation = communityList.find(item => item.id === event.external_community_id)
  const chatSectionRef = useRef(null)

  // useEffect(() => {
  //   const mediaLists = sortedProcessedMedia(media).filter(item => item.type === 'Photo')

  //   setMediaList(mediaLists)
  // }, [media])

  // const handleOpenPreviewGalleries = index => {
  //   const previewGalleries = mediaList.map(img => ({ id: img.id, url: img.file.url_2x }))
  //   dispatch(setPreviewIndex(index))
  //   dispatch(setPreviewGalleries(previewGalleries))
  // }

  const isAnnouncement = event.event_type === 'announcement'

  return (
    <div className={styles['chat-section-container']}>
      <div className={styles['avatar-box']}>
        <UserAvatarContainer user={host} size={50} />
      </div>
      <div className={styles['content-container']} ref={chatSectionRef}>
        <div className={styles['information']}>
          <div className={styles['name']}>{event.name}</div>
          <div className={styles['host-name']}>
            {isAnnouncement ? 'From' : 'Host'}: {hostFullName(event, host)}
          </div>
          <div className={styles['event-date']}>
            <EventDateTime
              endsAt={event.ends_at}
              startsAt={event.starts_at}
              timezone={event.timezone}
            />
          </div>
          {communityInformation && (
            <span className={styles['community-name']}>Space: {communityInformation.name}</span>
          )}
          <div className={styles['event-venue']}>
            <EventVenue customVenueName={event.custom_venue_name} venue={event.venue} />
          </div>
        </div>
        {/* <div className={styles['media-list']}>
          {mediaList.map((media, index) => {
            if (index > 3) return

            if (index === 3 && mediaList.length > 4) {
              return (
                <div
                  key={media.id}
                  className={styles['img-inner']}
                  onClick={() => handleOpenPreviewGalleries(index)}
                >
                  <img src={media.file.url_2x} />
                  <div className={styles['float-layer']}>{mediaList.length} Photos</div>
                </div>
              )
            }

            return (
              <Image
                key={media.id}
                rootClassName={styles['img-inner']}
                src={media.file.url_2x}
                preview={{ visible: false }}
                onClick={() => handleOpenPreviewGalleries(index)}
              />
            )
          })}
        </div> */}

        <ChatUsers onSetIsUnreadLoading={setIsUnreadLoading} chatSectionRef={chatSectionRef} />
      </div>

      {isUnreadLoading && (
        <div className={styles['loading-container']}>
          <LoadingComponent type="partial" loading light />
        </div>
      )}
    </div>
  )
}

export default ChatSection
