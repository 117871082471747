import { FlyerLayoutAbstract } from './flyer-layout.abstract'

export default class Flyer_layout_split_ellipse_036LayoutModel extends FlyerLayoutAbstract {
  flourishes = [
    {
      name: 'ellipse_shape_overlay',
      left: 0,
      top: 0,
      width: 320,
      height: 568
    },
    {
      name: 'ellipse_line',
      left: 118,
      top: 91,
      width: 182,
      height: 2
    },
    {
      name: 'ellipse_line',
      left: 20,
      top: 490,
      width: 182,
      height: 2
    }
  ]

  textLayouts = {
    event_name_1: {
      top: 320,
      left: 20,
      size: 40,
      width: 260,
      height: 100,
      verticalAlign: 'bottom'
    },
    location_1: {
      top: 440,
      left: 20,
      width: 280,
      height: 50,
      size: 16,
      verticalAlign: 'bottom'
    },
    location_2: {
      top: 495,
      left: 20,
      width: 280,
      height: 20,
      size: 14
    },
    location_3: {
      top: 515,
      left: 20,
      width: 280,
      height: 20,
      size: 14
    }
  }

  dateLayouts = {
    date_1: {
      top: 70,
      right: 18,
      daySize: 16,
      width: 260,
      lineHeight: 8,
      align: 'right'
    }
  }

  constructor(layout, eventData) {
    super(layout, eventData)
    this.init()
  }

  getDateTexts() {
    const { daySize, top, align, lineHeight, right, width } = this.dateLayouts.date_1

    const dateInformation = this.formatDates({
      day: 'D MMM, h:mmA',
      time: 'h:mmA'
    })

    const { formattedStart, formattedEnd, isSameDay } = dateInformation

    let dates = []

    let textTop = top

    // 开始时间
    dates.push({
      text: formattedStart,
      right,
      align,
      width,
      top: textTop,
      color: this.dateProps.date1Props.color.replace('0x', '#'),
      font: this.dateProps.date1Props.font.replace(/\s+/g, '-'),
      size: daySize
    })

    textTop += daySize + lineHeight
    if (!this.eventData.ends_at) {
      return { type: 'date', dates }
    }

    // 时间间隔
    dates.push({
      text: 'TILL',
      right: isSameDay ? 95 : 170,
      width: 50,
      size: 8,
      top: textTop + 10,
      align,
      color: this.dateProps.dateSeparatorProps.color.replace('0x', '#'),
      font: this.dateProps.dateSeparatorProps.font.replace(/\s+/g, '-'),
      transformOrigin: 'right top'
    })

    // 结束时间
    dates.push({
      text: formattedEnd,
      right,
      width,
      align,
      top: textTop + 5,
      color: this.dateProps.date2Props.color.replace('0x', '#'),
      font: this.dateProps.date2Props.font.replace(/\s+/g, '-'),
      size: daySize
    })
    return { type: 'date', dates }
  }
}
