import { getAccessToken } from './access_tokens'
import { Socket } from 'phoenix'
import { createWebSocket, saveHistoryMessage, changeChatStatus } from 'src/actions/eventChat'
import { ROOM_CHANNEL_TYPES_CONSTANTS } from 'src/constants'
let socket = null
let time = null
let channels = {}
let subscribers = []
const heartbeatInterval = 15000
let heartbeatCount = 0
let isConnectError = false

export function resetSocket(dispatch) {
  dispatch(createWebSocket(null))
  channels = {}
  subscribers = []
  socket = null
  isConnectError = false
  console.log('websocket reset')
}

export function socketHeartbeat() {
  if (!socket) return

  time = setInterval(() => {
    heartbeatCount = heartbeatCount + 1
    console.log('heartbeat count: ', heartbeatCount)
    socket.push({
      topic: 'phoenix',
      event: 'heartbeat',
      ref: String(heartbeatCount),
      payload: {}
    })
  }, heartbeatInterval)
}

export const fetchHistoryMessage = (channelName, dispatch) => {
  pushChannel({
    channelName,
    action: ROOM_CHANNEL_TYPES_CONSTANTS.message_history,
    successCallback: ({ messages }) => {
      dispatch(saveHistoryMessage(messages))
      dispatch(changeChatStatus('connected'))
    }
  })
}

export function createSocket({ dispatch }) {
  if (socket) return

  const access_token = getAccessToken()

  socket = new Socket(process.env.HOBNOB_WEBSOCKET_URL, {
    params: {
      token: access_token,
      client: process.env.HOBNOB_WEBSOCKET_CLIENT
    }
  })

  socket.onError(e => {
    resetSocket(dispatch)
    dispatch(changeChatStatus('connection_error'))
    clearInterval(time)
    time = null
    isConnectError = true
    console.error('there was an error with the connection', e)
  })

  socket.onOpen(() => {
    if (window.eventChatTimeout) {
      clearTimeout(window.eventChatTimeout)
      window.eventChatTimeout = null
    }

    socketHeartbeat()
    dispatch(createWebSocket(socket))

    console.log('websocket connected')
  })

  socket.onClose(() => {
    if (!isConnectError) {
      dispatch(changeChatStatus('disconnected'))
    }
    clearInterval(time)
    time = null
    console.log('websocket disconnected')
  })
  socket.connect()
}

export function disconnectSocket() {
  if (!socket) return
  socket.disconnect()
}

export function joinChannel(channelName, dispatch) {
  if (channels[channelName]) {
    return channels[channelName]
  } else {
    const newChannel = new Promise((resolve, reject) => {
      const channel = socket?.channel(channelName)
      if (!channel) {
        window.location.reload()
        return
      }
      channel
        .join()
        .receive('ok', () => {
          // After joining successfully, get historical messages.
          if (!channelName.includes('user:')) {
            fetchHistoryMessage(channelName, dispatch)
          }

          console.log(`joind channel ${channelName} successfully`)
          resolve(channel)
        })
        .receive('error', error => {
          console.error(`joined channel ${channelName} is failed. `, error)
          reject(error)
        })
    })
    channels[channelName] = newChannel
    return newChannel
  }
}

export async function leaveChannel(channelName) {
  if (channels[channelName]) {
    const channel = await channels[channelName]
    delete channels[channelName]

    channel
      .leave()
      .receive('ok', () => {
        console.log(`left channel ${channelName} successfully`)
      })
      .receive('error', error => {
        console.error(`left channel ${channelName} is failed. `, error)
      })
  }
}

export async function subscribeChannel(params) {
  const { channelName, subscriberList = [], dispatch } = params
  const channel = await joinChannel(channelName, dispatch)

  subscriberList.forEach(({ action, onReceived }) => {
    console.log('subscribe: ', channelName, ' action: ', action)
    channel.on(action, onReceived)
    subscribers.push(channelName + action)
  })
}

export async function pushChannel(params) {
  const { channelName, action, payload, successCallback, errorCallback } = params
  const channel = await joinChannel(channelName)

  channel
    .push(action, payload || {}, 10000)
    .receive('ok', res => {
      if (successCallback) {
        heartbeatCount++
        successCallback(res)
      }
    })
    .receive('error', error => {
      console.error(`push ${action} to ${channelName} failed: `, error)
      if (errorCallback) {
        errorCallback(error)
      }
    })
    .receive('timeout', () => console.error('timed out pushing'))
}
