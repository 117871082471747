import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SvgIcon from 'components/common/SvgIcon'
import OrangeCrown from 'src/assets/icons/crown-ico-orange.svg'
import { RightOutlined } from '@ant-design/icons'
import { userSelector } from 'src/selectors'
import PropTypes from 'prop-types'
import { updateMenuPanel } from 'src/actions/frontend'
import moment from 'moment'
import { BillPeriod, getPlanBySlug, PlanType } from 'src/services/stripe_checkout_configs'
import { PanelSlug } from '../panels/PanelContainer'
import cn from 'classnames'
import { isMobile } from 'services/browser_detection'
import styles from './membership-info.scss'
const BillPeriodText = {
  [BillPeriod.Annually]: 'Annual',
  [BillPeriod.Monthly]: 'Month-to-Month'
}

const PlanTypeText = {
  [PlanType.Premium]: 'Hobnob Premium',
  [PlanType.Essentials]: 'Hobnob Essentials',
  [PlanType.Free]: 'Hobnob Free',
  [PlanType.Standard]: 'Hobnob Standard',
  [PlanType.Pro]: 'Hobnob Pro',
  [PlanType.Basic]: 'Hobnob Basic',
  [PlanType.Plus]: 'Hobnob Plus',
  [PlanType.InvitationMaker]: 'Hobnob Invitation Maker',
  [PlanType.PartyHost]: 'Hobnob Host',
  [PlanType.EventPlanner]: 'Hobnob Planner',
  [PlanType.GroupOrganizer]: 'Hobnob Organizer'
}

const hiddenPlans = [PlanType.Standard, PlanType.Pro, PlanType.Premium1, PlanType.Plus]

const MembershipInfo = props => {
  const { isInDetail = false, mobileProfilePanel = false } = props
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const { plan, available_credit, next_credit_renewal_at, cancel_at_period_end, expires_at } =
    user.current_subscription

  const planType = getPlanBySlug(plan.slug, 'planType')
  const isFreePlan = planType === PlanType.Free
  const userPlanLabel = PlanTypeText[planType] || ''
  const isHiddenPlan = hiddenPlans.includes(planType)

  const handleClickTitle = () => {
    if (isMobile() || isInDetail || isFreePlan || isHiddenPlan) {
      return
    }

    dispatch(updateMenuPanel(PanelSlug.Membership))
  }

  const renderTitle = () => {
    return (
      <div
        className={cn(styles['title'], isInDetail && styles['title-inDetail'])}
        onClick={handleClickTitle}
      >
        {!isFreePlan && <SvgIcon icon={OrangeCrown} className={styles['icon']} />}
        {userPlanLabel}
        {!isFreePlan && !isHiddenPlan && (
          <div className={cn(styles['right-icon'])}>
            <RightOutlined />
          </div>
        )}
      </div>
    )
  }

  const renderPeriod = () => {
    if (isFreePlan) {
      return
    }
    const billPeriod = BillPeriodText[plan.subscription_period] || ''

    return (
      <div
        className={cn(styles['period'], mobileProfilePanel && styles['period-mobileProfilePanel'])}
      >
        {billPeriod}
      </div>
    )
  }

  const renderCredits = () => {
    return (
      <div
        className={cn(
          styles['credits'],
          mobileProfilePanel && styles['credits-mobileProfilePanel']
        )}
      >
        {Number(available_credit)} Credits Remaining
      </div>
    )
  }

  const renderRenewal = () => {
    if (isFreePlan || !next_credit_renewal_at) {
      return
    }

    if (cancel_at_period_end) {
      return (
        <div className={cn(styles['renew'], styles['expires'])}>
          Membership expires on {moment(expires_at).format('MMMM D, YYYY')}
        </div>
      )
    }

    return (
      <div className={styles['renew']}>
        Membership auto-renews on {moment(next_credit_renewal_at).format('MMMM D, YYYY')}
      </div>
    )
  }

  return (
    <main className={cn(styles['membership-info'])}>
      {renderTitle()}
      {renderPeriod()}
      {renderCredits()}

      {!mobileProfilePanel && (
        <div className={cn(styles['email'])}>Includes free email invites</div>
      )}

      {renderRenewal()}
    </main>
  )
}

MembershipInfo.propTypes = {
  isInDetail: PropTypes.bool,
  mobileProfilePanel: PropTypes.bool
}

export default MembershipInfo
