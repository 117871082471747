/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import RightIcon from 'assets/icons/right-icon.png'
import AccountIcon from 'assets/icons/account-icon.png'
import HobnobModal from 'components/common/HobnobModalV2/HobnobModal'
import TextShowOneLine from '../../../../../../components/common/TextShowOneLine'
import { pluralize } from 'services/utils'

import styles from './full-list.scss'

class FullList extends Component {
  state = {
    cardStatus: this.props.optionList.map(() => false)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.optionList.length === 0 && this.props.optionList.length > 0) {
      this.setState({
        cardStatus: this.props.optionList.map(() => false)
      })
    }
  }

  getVoteUserName = data => {
    let name = data.map(item => {
      return item.custom_user_name
    })

    return name.join(',')
  }

  handleClickIcon = index => {
    const cardStatus = this.state.cardStatus.map((item, n) => {
      if (index === n) {
        return !item
      } else {
        return item
      }
    })

    this.setState({ cardStatus })
  }

  render() {
    const { optionList, isFullListVisible, onClose } = this.props
    const { cardStatus } = this.state

    return (
      <HobnobModal title="All Responses" visible={isFullListVisible} onCancel={onClose}>
        <ul className={styles['option-list-wrapper']}>
          {optionList.map((item, index) => {
            const validUserSections = item.poll_user_selections.filter(s => !s.destroyed_at)
            const showVoteList = validUserSections.length > 0

            return (
              <li
                key={index}
                className={cn(styles['option-wrapper'], {
                  [styles['option-wrapper-checked']]: item.isChecked
                })}
              >
                {item.count_limit === 9999 ? (
                  <div className={styles['option-name']}>{item.item}</div>
                ) : (
                  <div className={styles['option-top']}>
                    <div className={styles['option-info']}>
                      <div className={styles['name']}>{item.item}</div>
                      <div className={styles['number']}>
                        <div className={styles['option-label']}>
                          <label>Total:&nbsp;</label>
                          <span>{item.count_limit}</span>
                        </div>
                        <div className={styles['option-label']}>
                          <label>Available:&nbsp;</label>
                          <span>{item.available_count}</span>
                        </div>
                      </div>
                    </div>
                    <div className={styles['option-number']}>
                      <label>My Count:&nbsp;</label>
                      <span>{item.count}</span>
                    </div>
                  </div>
                )}
                {item.description && (
                  <div className={styles['option-bottom']}>{item.description}</div>
                )}

                {showVoteList && <div className={styles['option-line']} />}

                {showVoteList &&
                  (cardStatus[index] ? (
                    <ul className={styles['vote-list']}>
                      <li
                        className={styles['vote-list-title']}
                        onClick={() => this.handleClickIcon(index)}
                      >
                        <span>{validUserSections.length} {pluralize(validUserSections.length, 'SignUp')}</span>
                        <img src={RightIcon} className={styles['open-icon']} width="20" />
                      </li>
                      {validUserSections.map((i, n) => {
                        const user = i.user
                        return (
                          <div key={n}>
                            <li className={styles['vote-user']}>
                              <img src={user ? user.avatar.small_url_2x : AccountIcon} />
                              <div className={styles['vote-user-name']}>{i.custom_user_name}</div>
                              <div>Count: {i.count}</div>
                            </li>
                            {i.note.trim() && <TextShowOneLine text={i.note} />}
                          </div>
                        )
                      })}
                    </ul>
                  ) : (
                    <div className={styles['vote-wrapper']}>
                      <div className={styles['vote-avatar']}>
                        {validUserSections.map((i, n) => {
                          const user = i.user
                          return (
                            <div key={n} className={styles['avatar-wrapper']}>
                              {user ? (
                                <img src={user.avatar.small_url_2x} />
                              ) : (
                                <img src={AccountIcon} />
                              )}
                            </div>
                          )
                        })}
                      </div>
                      <div className={styles['vote-name']}>
                        {this.getVoteUserName(validUserSections)}
                      </div>
                      <div
                        className={styles['vote-label']}
                        onClick={() => this.handleClickIcon(index)}
                      >
                        <span>{validUserSections.length} {pluralize(validUserSections.length, 'SignUp')}</span>
                        <img src={RightIcon} className={styles['noOpen-icon']} width="20" />
                      </div>
                    </div>
                  ))}
              </li>
            )
          })}
        </ul>
      </HobnobModal>
    )
  }
}

FullList.propTypes = {
  optionList: PropTypes.array,
  isFullListVisible: PropTypes.bool,
  onClose: PropTypes.func
}

export default FullList
