import React, { useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/common/Button'
import { Input } from 'antd'
import GifIcon from 'assets/icons/gif-icon.png'
import PhotoIcon from 'assets/icons/photo-icon.png'
import MentionIcon from 'assets/icons/mention-icon.png'
import InputMessageContext from '../InputMessageContext'
import { getOS } from 'services/browser_detection'

import styles from './input-inner-desktop.scss'

const InputInnerDesktop = ({
  onSetIsInputGifVisible,
  isInputGifVisible,
  onSendMessage,
  onSelectImage,
  onInputChange,
  onKeyDown
}) => {
  const { inputRef, textMessage, mediaMessage } = useContext(InputMessageContext)
  const inputFileRef = useRef(null)
  const OS = getOS()

  const getAcceptType = () => {
    if (OS === 'iOS' || OS === 'MacOS') {
      return 'image/*,.HEIC'
    }

    return 'image/*'
  }

  const isDisabled = !textMessage && mediaMessage.length === 0

  return (
    <div className={styles['input-inner-desktop']}>
      <div className={styles['input-wrapper']}>
        <Input.TextArea
          ref={inputRef}
          className={styles['input-inner']}
          value={textMessage}
          placeholder="Message..."
          autoSize={{ minRows: 1, maxRows: 2 }}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          bordered={false}
        />

        <div className={styles['icon-wrapper']}>
          <img
            src={MentionIcon}
            alt="mention"
            onClick={() => {
              onInputChange('@')
              inputRef.current.focus()
            }}
          />
          <img src={PhotoIcon} alt="photo" onClick={() => inputFileRef.current.click()} />

          <input
            ref={inputFileRef}
            type="file"
            style={{ display: 'none' }}
            multiple
            accept={getAcceptType()}
            onChange={onSelectImage}
          />
          <img src={GifIcon} alt="gif" onClick={() => onSetIsInputGifVisible(!isInputGifVisible)} />
        </div>
      </div>

      <Button disabled={isDisabled} className={styles['send-button']} onClick={onSendMessage}>
        SEND
      </Button>
    </div>
  )
}

InputInnerDesktop.propTypes = {
  isInputGifVisible: PropTypes.bool,
  onSetIsInputGifVisible: PropTypes.func,
  onSendMessage: PropTypes.func,
  onSelectImage: PropTypes.func,
  onInputChange: PropTypes.func,
  onKeyDown: PropTypes.func
}

export default InputInnerDesktop
