import { fetchApi } from './api'

export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_FAILED = 'UPLOAD_FILE_FAILED'

export function uploadFiles({ mimetype, onSuccessCallback }) {
  return fetchApi({
    requestType: UPLOAD_FILE,
    successType: UPLOAD_FILE_SUCCESS,
    errorType: UPLOAD_FILE_FAILED,
    route: '/graphql',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `
            mutation {
              image_upload_update(image_upload_params: { mimetype: "${mimetype}" }) {
                result {
                  id
                  image_url
                  presigned_url
                  user_id
                }
              }
            }`
      })
    },
    onSuccessCallback
  })
}

export function requestUploadFile(fileUrl, presigned_url) {
  return new Promise(async resolve => {
    const res = await fetch(fileUrl)
    const blob = await res.blob()
    await fetch(presigned_url, {
      headers: {
        'Content-Type': 'image/jpeg'
      },
      method: 'PUT',
      body: blob
    })
    resolve()
  })
}
