import React from 'react'
import PropTypes from 'prop-types'

const showError = (error) => {
  console.error('Captured error: ', error)
}

window.onunhandledrejection = function (evt) {
  showError(evt.reason)
}

window.onerror = function (errorMessage) {
  if (errorMessage && typeof errorMessage === 'string') {
    showError(new Error(errorMessage))
  }
}

export class ErrorBoundary extends React.Component {
  componentDidCatch (error) {
    showError(error)
  }

  render () {
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node
}

export const ErrorManager = ({ children }) => {
  return <ErrorBoundary>{children}</ErrorBoundary>
}

ErrorManager.propTypes = {
  children: PropTypes.node
}
