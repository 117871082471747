import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { timeSince } from 'src/services/utils'

import { pageLoaded } from 'src/actions'
import { routerActions } from 'react-router-redux'

import uuid from 'uuid'
import DashboardContainer from 'containers/DashboardContainer'
import Card from 'containers/time-poll/components/Card'
import TextInputElement from 'components/forms/TextInputElement'
import SvgIcon from 'components/common/SvgIcon'
import ListIcon from 'assets/icons/icons8-list-16px.svg'
import PersonGreen from 'assets/icons/icon_green_person.svg'
import ClockIcon from 'assets/icons/icons8-clock-16px.svg'
import PersonIcon from 'assets/icons/icons8-person.svg'
import CongratulationsIcon from 'assets/icons/congrat-icon.png'
import EditIcon from 'assets/icons/edit_icon_green.svg'
import CheckIcon from 'assets/icons/icon_check.svg'
import CheckBoxInput from 'components/forms/CheckboxInput'
import Button from 'src/components/common/Button'
import PickerZoneTime from 'containers/time-poll/components/PickerZoneTime'
import HobnobModal from 'components/common/HobnobModalV2/HobnobModal'
import back_button from 'assets/icons/back_button.png'
import { Tooltip } from 'antd'
import ShareIcon from 'assets/icons/share-icon.png'
import { showShareModal } from 'src/actions/frontend'

import { eventTokenSelector, userSelector } from 'src/selectors'

import { updatePollUserSelection, isUpdateSelections } from 'src/actions/timepoll'

import styles from './time-poll-information.scss'

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

const initialState = {
  name: '',
  selectedTimes: [],
  isPrevSelection: false,
  votes: [],
  isEditable: false,
  isUpdate: false,
  timeZoneSelected: tz,
  participants: 0,
  openModal: false,
  openModalLogin: false,
  openModalCreate: false,
  userId: '',
  textToCopy: ''
}

class TimePoll extends React.Component {
  constructor(props) {
    super(props)

    this.state = initialState
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      const anonUser = localStorage.getItem('anonymousId')
      localStorage.removeItem('polls')
      let prevOptions
      if (anonUser) {
        prevOptions = this._getOptionsByUserId(anonUser, 'anonymous_user_id')
      } else {
        prevOptions = this._getOptionsByUserId(nextProps.user.id, 'user_id')
      }
      const votes = prevOptions.map(option => option.votes)
      const selections = prevOptions.map(option => option.selection)
      if (prevOptions.length) {
        const { onIsUpdateSelections } = this.props
        this.setState({
          isPrevSelection: true,
          isEditable: true,
          selectedTimes: selections,
          votes: votes
        })
        onIsUpdateSelections()
      }
      this.setState({
        name: nextProps.user.first_name + ' ' + nextProps.user.last_name,
        userId: nextProps.user.id
      })
    }
  }

  componentDidMount() {
    const { eventToken, poll, onPageLoaded } = this.props
    this.setState({ timeZone: poll ? poll.timezone : '' })
    this.setState({ textToCopy: window.location.href })
    const pollToken = localStorage.getItem('pollToken')
    if (pollToken) this.setState({ openModalCreate: true })
    onPageLoaded()
    const prevSelections = JSON.parse(localStorage.getItem('polls') || '{}')
    const prevSelection = prevSelections[eventToken]
    if (prevSelection) {
      const { onIsUpdateSelections } = this.props
      this.setState({
        isPrevSelection: true,
        isEditable: true,
        name: prevSelection.name,
        selectedTimes: prevSelection.options,
        votes: prevSelection.votes
      })
      onIsUpdateSelections()
    }
    const participants = this._totalParticipants(poll.poll_options)
    this.setState({ participants })
  }

  _setName = event => {
    const name = event.target.value
    this.setState({ name })
    if (this.state.isPrevSelection) {
      const isDiff = this._validateChangesName(name)
      this.setState({ isUpdate: isDiff })
    }
  }

  _handleEdit = () => {
    this.setState({
      isEditable: false
    })
  }

  _validateChangesSelectedPoll = currOptions => {
    const { eventToken } = this.props
    const prevOptions = JSON.parse(localStorage.getItem('polls') || '{}')[eventToken]
    if (prevOptions) {
      const isDiff = currOptions.sort().join('') !== prevOptions.options.sort().join('')
      return isDiff
    }
    return true
  }

  _validateChangesName = name => {
    const { eventToken } = this.props
    const prevName = JSON.parse(localStorage.getItem('polls'))[eventToken].name
    const isDiff = name !== prevName
    return isDiff
  }

  _validateOptionAlreadySaved = id => {
    const { eventToken } = this.props
    const prevOptions = JSON.parse(localStorage.getItem('polls') || '{}')[eventToken]
    if (prevOptions) {
      const isOptionAlreadySaved = prevOptions.options.includes(id)
      return isOptionAlreadySaved
    }
    return false
  }

  _validateOptionAlreadySavedBe = id => {
    const prevOptions = this.state.selectedTimes
    if (prevOptions) {
      const isOptionAlreadySaved = prevOptions.options.includes(id)
      return isOptionAlreadySaved
    }
    return false
  }

  _setSelectedTime = opt => {
    let selectedTimes = []
    const index = this.state.selectedTimes.indexOf(opt.id)
    if (index > -1) {
      selectedTimes = this.state.selectedTimes
        .slice(0, index)
        .concat(this.state.selectedTimes.slice(index + 1))
    } else {
      const noOptionItem = this.props.poll.poll_options.find(item => item.starts_at === null)
      const noOptionIndex = this.state.selectedTimes.indexOf(noOptionItem?.id)
      if (noOptionIndex > -1) {
        selectedTimes = [opt.id]
      } else {
        if (opt.starts_at === null) {
          selectedTimes = [opt.id]
        } else {
          selectedTimes = [...this.state.selectedTimes, opt.id]
        }
      }
    }
    this.setState({ selectedTimes })
    if (this.state.isPrevSelection) {
      const isDiff = this._validateChangesSelectedPoll(selectedTimes)
      this.setState({ isUpdate: isDiff })
    }
  }
  _printPage = () => {
    window.print()
  }

  _validateUpdate = () => {
    if (this.state.isPrevSelection) return !this.state.isUpdate
    return false
  }

  _sortElements = polls => {
    const pollsSorted = polls.sort((a, b) => {
      if (b.starts_at === null) return -1
      if (a.starts_at > b.starts_at) return 1
      if (a.starts_at < b.starts_at) return -1
      return 0
    })
    return pollsSorted
  }

  _totalParticipants = pollOptions => {
    const total = pollOptions.reduce((acc, opt) => {
      opt.poll_user_selections.forEach(user => {
        if (!acc.includes(user.custom_user_name)) {
          acc.push(user.custom_user_name)
        }
      })
      return acc
    }, [])
    return total.length
  }

  _handleClick = () => {
    if (this.state.isEditable) {
      this.setState({ openModal: true })
    }
  }

  _onChangeTab = () => {
    const { onChangeRouteToLogin, poll } = this.props
    onChangeRouteToLogin({ pathname: '/login', state: { prevRoute: `/tp/${poll.token}` } })
  }

  _onCloseModal = () => {
    const { loginForm } = this.props
    if (loginForm.confirmationCodeValid) {
      this.setState({ openModal: false })
    }
  }

  _onCloseModalCreate = () => {
    localStorage.removeItem('pollToken')
    this.setState({ openModalCreate: false })
  }

  _showUserName = (user, isEditable) => {
    if (user && isEditable) Boolean(user.first_name)
    return false
  }

  _getAnonymousId = () => {
    if (this.state.isPrevSelection) {
      return localStorage.getItem('anonymousId')
    } else {
      const anonymousId = uuid.v4()
      localStorage.setItem('anonymousId', anonymousId)
      return anonymousId
    }
  }

  _handleSend = () => {
    const {
      onUpdatePollUserSelection,
      poll: { id: pollId }
    } = this.props
    const pollUserSelection = {
      timePollToken: pollId,
      name: this.state.name,
      options: this.state.selectedTimes,
      deleteIds: this.state.votes,
      anonUser: ''
    }
    if (this.state.userId) {
      pollUserSelection.user = this.state.userId
      localStorage.removeItem('anonymousId')
    } else {
      pollUserSelection.anonUser = this._getAnonymousId()
    }
    // return
    onUpdatePollUserSelection(pollUserSelection)
  }

  _getOptionsByUserId = (id, key) => {
    if (!id) return
    const {
      poll: { poll_options }
    } = this.props
    const prevOptions = poll_options.reduce((acc, opt) => {
      opt.poll_user_selections.forEach(selection => {
        if (selection[key] === id && selection.poll_option_id !== null) {
          acc.push({ selection: selection.poll_option_id, votes: selection.id })
        }
      })
      return acc
    }, [])
    return prevOptions
  }

  _validateOptionAlreadyVoted = votes => {
    if (!this.state.userId) return false
    return votes.some(vote => vote.user_id === this.state.userId)
  }

  _onButtonClick = () => {
    navigator.clipboard.writeText(this.state.textToCopy)
    localStorage.removeItem('pollToken')
    setTimeout(() => {
      this.setState({ openModalCreate: false })
    }, 500)
  }

  handleClickShare = () => {
    const { poll, onShowShareModal } = this.props
    const url = window.location.href

    const params = {
      title: 'Share Your Time Poll',
      subtitle: 'Guests can view all the available time slots and vote!',
      shareUrl: url.substring(8),
      email: {
        tp: poll,
        url
      },
      facebook: {
        href: url,
        redirect_uri: url
      },
      twitter: {
        subject: `You're invited to ${poll.name}!`,
        url
      }
    }

    onShowShareModal(params)
  }

  render() {
    const {
      isEditable,
      name,
      selectedTimes,
      timeZoneSelected,
      participants,
      openModal,
      openModalCreate
    } = this.state
    const { poll, updatingTimePollStatus, user, onChangeRouteToPolls } = this.props
    const submitDisabled =
      updatingTimePollStatus === 'loading' ||
      !name ||
      !selectedTimes.length ||
      this._validateUpdate()

    return (
      <DashboardContainer
        menuIcon
        documentTitle={poll.name}
        right={
          isEditable && (
            <SvgIcon onClick={this._handleEdit} icon={EditIcon} className={styles['icon']} />
          )
        }
      >
        <div className={styles['tp-container']}>
          <div className={styles['back-container']}>
            <div className={styles['back-wrapper']} onClick={onChangeRouteToPolls}>
              <img src={back_button} alt="back icon" />
              <span>polls</span>
            </div>
          </div>

          <div className={styles['time-poll']}>
            <div className={styles['header']}>
              <div className={styles['op-area']}>
                <div className={styles['share']} onClick={this.handleClickShare}>
                  <img src={ShareIcon} width="12" />
                  <span>share</span>
                </div>
                {isEditable && (
                  <div onClick={this._handleEdit} className={styles['header__edit']}>
                    <SvgIcon icon={EditIcon} className={styles['icon']} />
                    <span className={styles['text']}>edit response</span>
                  </div>
                )}
              </div>
              <h1>{poll.name}</h1>
              <p>
                by {poll.custom_creator_name} ·{' '}
                {poll.created_at && timeSince(new Date(poll.created_at))} ·{' '}
                <span className={styles['header__print']} onClick={() => this._printPage()}>
                  Print
                </span>
              </p>
            </div>

            <div className={styles['information']}>
              <div className={styles['question-time']}>
                {poll.notes && (
                  <div className={styles['notes']}>
                    <SvgIcon icon={ListIcon} className={styles['icon']} />
                    <Tooltip placement="topLeft" title={poll.notes}>
                      <span>{poll.notes}</span>
                    </Tooltip>
                  </div>
                )}
                {poll.timezone && (
                  <div className={styles['timezone']}>
                    <SvgIcon icon={ClockIcon} className={styles['icon']} />
                    <span>All times displayed in</span>
                    <PickerZoneTime
                      poll={this.props.poll}
                      timeZone={timeZoneSelected}
                      setTimeZone={timeZone => this.setState({ timeZoneSelected: timeZone })}
                    />
                  </div>
                )}
              </div>

              <div className={styles['user-container']}>
                {isEditable || user ? (
                  <div className={styles['name']} onClick={this._handleClick}>
                    <SvgIcon icon={PersonGreen} className={styles['icon']} />
                    <Tooltip placement="topLeft" title={name}>
                      <span>{name}</span>
                    </Tooltip>
                  </div>
                ) : (
                  <div className={styles['name-input-container']}>
                    <SvgIcon icon={PersonIcon} className={styles['icon']} />
                    <TextInputElement
                      className={styles['input']}
                      onChange={this._setName}
                      placeholder="Enter your name"
                      valid={true || name !== ''}
                      value={name}
                    />
                  </div>
                )}

                {user ? (
                  <div className={styles['login-text']}>
                    <a onClick={this._onChangeTab}>Login as a different user</a>
                  </div>
                ) : (
                  <div className={styles['login-text']}>
                    Have an account? <a onClick={this._onChangeTab}>Log in here</a>
                  </div>
                )}
              </div>
            </div>

            <div className={styles['time-picker-container']}>
              <div className={styles['time-picker-container__text']}>
                <p>
                  <strong>{participants}</strong> Participants
                </p>
              </div>
              <div className={styles['time-picker-container__picker']}>
                {poll.poll_options &&
                  this._sortElements(poll.poll_options).map(opt => {
                    const isSelected = selectedTimes.includes(opt.id)
                    // const noOption = opt.starts_at === null
                    // const noOptionItem = poll.poll_options.find(item => item.starts_at === null)
                    // const optionDisable = this.state.selectedTimes.includes(noOptionItem?.id)
                    const isDisabled = isEditable
                    opt.date = new Date()
                    const filteredUserSelections = opt.poll_user_selections.filter(
                      option => option.poll_option_id !== null
                    )
                    let voteCount = filteredUserSelections.length
                    if (isSelected) {
                      if (
                        !this._validateOptionAlreadySaved(opt.id) &&
                        this._validateChangesSelectedPoll(selectedTimes) &&
                        !this._validateOptionAlreadyVoted(filteredUserSelections)
                      ) {
                        voteCount = voteCount + 1
                      }
                    } else if (
                      this._validateOptionAlreadySaved(opt.id) ||
                      this._validateOptionAlreadyVoted(filteredUserSelections)
                    ) {
                      voteCount = voteCount - 1
                    }
                    return (
                      <Card
                        key={opt.id}
                        opt={opt}
                        style={isSelected ? styles['card--selected'] : styles['card']}
                        isTimePoll
                        timeZoneSelected={timeZoneSelected}
                        isDisabled={isDisabled}
                      >
                        <div className={styles['check-button']} onClick={() => this._handleClick()}>
                          <CheckBoxInput
                            className={styles['radio-button']}
                            checked={isSelected}
                            onChange={() => this._setSelectedTime(opt)}
                            disabled={isDisabled}
                          />
                        </div>
                        <div className={styles['card__votes']}>
                          <SvgIcon icon={CheckIcon} className={styles['icon']} />
                          <span>{voteCount}</span>
                        </div>
                      </Card>
                    )
                  })}
              </div>
            </div>
            <div className={styles['submit-container']}>
              <Button
                onClick={() => this._handleSend(name)}
                className={styles['button']}
                disabled={submitDisabled}
              >
                <div>
                  <p>
                    <strong>SEND</strong>
                  </p>
                  {!name && <p>Enter your name first</p>}
                </div>
              </Button>
            </div>
          </div>
        </div>

        <HobnobModal
          visible={openModal}
          onCancel={() => this.setState({ openModal: false })}
          closable={false}
        >
          <div className={styles['hobnob-container']}>
            <p className={styles['modal']}>
              To change your votes or name, please tap the "EDIT RESPONSE" button above.{' '}
            </p>
            <Button
              className={styles['modal__button']}
              onClick={() => this.setState({ openModal: false })}
            >
              <p>
                <strong>ok</strong>
              </p>
            </Button>
          </div>
        </HobnobModal>

        <HobnobModal visible={openModalCreate} onCancel={this._onCloseModalCreate}>
          <div className={styles['hobnob-container']}>
            <img src={CongratulationsIcon} className={styles['icon-congratulations']} />
            <h2 className={styles['modal__title']}>Congratulations!</h2>
            <p className={styles['modal']}>
              Your poll has been created! The next step is to invite your guests
            </p>
            <Button
              className={styles['modal__button']}
              onClick={this._onButtonClick}
              aria-label={'link copied'}
              data-cooltipz-dir={'top'}
              data-cooltipz-size="small"
            >
              <p>
                <strong>copy link</strong>
              </p>
            </Button>
          </div>
        </HobnobModal>
      </DashboardContainer>
    )
  }
}

TimePoll.propTypes = {
  poll: PropTypes.object,
  updatingTimePollStatus: PropTypes.string,
  onUpdatePollUserSelection: PropTypes.func,
  eventToken: PropTypes.string,
  user: PropTypes.object,
  loginForm: PropTypes.object,
  onPageLoaded: PropTypes.func,
  onChangeRouteToLogin: PropTypes.func,
  onIsUpdateSelections: PropTypes.func,
  onChangeRouteToPolls: PropTypes.func,
  onShowShareModal: PropTypes.func
}

function mapStateToProps(state) {
  const user = userSelector(state)
  const { loginForm } = state
  return {
    poll: state.frontend.timePoll,
    eventToken: eventTokenSelector(state),
    user,
    loginForm,
    route: state.routing.locationBeforeTransitions
  }
}
function mapDispatchToProps(dispatch) {
  const onPageLoaded = () => dispatch(pageLoaded())
  const onIsUpdateSelections = params => dispatch(isUpdateSelections(params))
  return {
    onChangeRouteToLogin: params => {
      dispatch(routerActions.push(params))
      window.scrollTo(0, 0)
    },
    onPageLoaded,
    onIsUpdateSelections,
    onUpdatePollUserSelection: params => dispatch(updatePollUserSelection(params)),
    onChangeRouteToPolls: () => dispatch(routerActions.replace('/tp/timePoll/')),
    onShowShareModal: params => dispatch(showShareModal(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimePoll)
