/**
 * This component is an updated version of the Hobnob component.
 */

import React, { Component } from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'
import styles from './hobnob-modal.scss'
import cn from 'classnames'

class HobnobModal extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.visible && !prevProps.visible) {
      document.body.style.overflow = 'hidden'
    } else if (!this.props.visible && prevProps.visible) {
      document.body.style.overflow = 'visible'
    }
  }

  render() {
    const {
      visible,
      onCancel,
      destroyOnClose,
      maskClosable,
      closeIcon,
      closable,
      fullModal,
      title
    } = this.props

    const modalParams = {
      onCancel,
      destroyOnClose,
      maskClosable,
      closeIcon,
      closable,
      title
    }

    const isTitlePlaceholder = !title && !fullModal

    return (
      <Modal
        {...modalParams}
        open={visible}
        centered
        zIndex={1008}
        footer={null}
        width={'min-content'}
        wrapClassName={cn(
          styles['modal-container'],
          isTitlePlaceholder && styles['title-placeholder'],
          fullModal && styles['full-modal']
        )}
      >
        {this.props.children}
      </Modal>
    )
  }
}

HobnobModal.defaultProps = {
  maskClosable: true,
  closable: true,
  title: ''
}

HobnobModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  destroyOnClose: PropTypes.bool,
  maskClosable: PropTypes.bool,
  fullModal: PropTypes.bool,
  closable: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  onCancel: PropTypes.func,
  closeIcon: PropTypes.node
}

export default HobnobModal
