import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import HobnobModal from 'src/components/common/HobnobModalV2/HobnobModal'
import Button from 'src/components/common/Button'
import ResizeableTextarea from 'src/components/common/ResizeableTextarea'
import OverlayLoadingIndicator from 'components/common/OverlayLoadingIndicator'

import NoInvitationRSVPView from './NoInvitationRSVPView'
import ChangeRSVP from './ChangeRSVP'
import RSVPAccepted from './RSVPAccepted'
import RSVPDeclined from './RSVPDeclined'
import { isHobnobAndroidClient } from 'src/services/utils'

import { hasUserAccessToken } from 'src/services/access_tokens'
import { canSendInvitationReplyMessage } from 'src/services/invitation_helpers'

import {
  eventSelector,
  eventTokenSelector,
  hasPaidTicketsSelector,
  invitationReplyMessageSelector,
  invitationSelector,
  invitationTokenSelector,
  invitationUserSelector,
  isInvitationCredentialUserSelector,
  listMyOrdersForEventSelector,
  requestedRsvpSelector,
  rsvpModalChangeRsvpSelector,
  rsvpModalVisibleSelector,
  showTicketTypesSelector,
  ticketTypesCountsLoadingStatusSelector,
  ticketTypesCountsSelector,
  userSelector,
  showInvitationReplyFormModalSelector,
  rsvpModalShowDefaultDeclinedPageSelector,
  loginFormSelector
} from 'src/selectors'

import {
  hasChangedRsvpStatus,
  hideRsvpModal,
  showDeclinedSendMessageModal,
  showGuestCountControlsModal,
  showHud,
  showInvitationReplyFormModal,
  updateChangeRsvpStatus
} from 'src/actions/frontend'

import {
  requestAcceptInvitation,
  requestDeclineInvitation,
  requestSendInvitationReplyMessage,
  setInvitationReplyMessage,
  updateInvitation
} from 'src/actions/invitations'

import { resetLoginForm } from 'src/actions/login'
import {
  purchaseTickets,
  revokeTicketsMutation,
  setCurrentOrderId,
  setOrderLineItems,
  setRequestedRsvp,
  setTempTicketTypesCounts
} from '../../actions/paymentGraphql'

import styles from './rsvp-modal-container.scss'
import { hasPaidTickets } from '../../services/payment_utils'
import PaidTicketsContainer from './PaidTicketsContainer'
import FreeTicketsContainer from './FreeTicketsContainer'
import { routerActions } from 'react-router-redux'

class RSVPModalContainer extends Component {
  _onAcceptInvitation = () => {
    const {
      event,
      invitation,
      onSetOrderLineItems,
      onPurchaseTickets,
      ticketTypesCounts,
      isInvitationCredentialUser,
      onSetRequestedRsvp,
      onRequestAcceptInvitation,
      onUpdateChangeRsvpStatus,
      onHasChangedRsvpStatus
    } = this.props

    if (event.ticketing_enabled_at) {
      const hadSelectCount = ticketTypesCounts
        .filter(item => !item.archived_at)
        .some(item => item.count)

      if (invitation.rsvp_status !== 'accepted') {
        if (hadSelectCount) {
          onRequestAcceptInvitation()
        } else if (!event.guest_count_enabled && !hasPaidTickets(ticketTypesCounts)) {
          let queryVariables = []
          ticketTypesCounts
            .filter(item => !item.archived_at)
            .forEach(item => {
              if (!item.count) {
                const obj = { ticket_type_id: item.id, count: 1 }
                queryVariables.push(obj)
              }
            })
          onSetOrderLineItems(queryVariables)
          onPurchaseTickets(invitation.id, queryVariables, null, !isInvitationCredentialUser)
        }
      }
      onSetRequestedRsvp('accepted')
    } else {
      if (invitation.rsvp_status !== 'accepted') {
        onRequestAcceptInvitation()
      }
      onUpdateChangeRsvpStatus({ changeRsvp: true })
    }
    onHasChangedRsvpStatus()
  }

  _onDeclineInvitation = () => {
    const {
      invitation,
      onRequestDeclineInvitation,
      onUpdateChangeRsvpStatus,
      onShowDeclinedSendMessageModal,
      onHasChangedRsvpStatus
    } = this.props
    if (invitation.rsvp_status !== 'declined') {
      onRequestDeclineInvitation()
    }
    onUpdateChangeRsvpStatus({ changeRsvp: true })
    onShowDeclinedSendMessageModal()
    onHasChangedRsvpStatus()
  }

  _onChangeGuestCounts = (guest_count, child_count) => {
    const { onUpdateInvitation, event, eventToken, invitation, invitationToken } = this.props
    const updatedInvitation = {
      ...invitation,
      guest_count,
      child_count
    }

    const nextSuccessActions = [showInvitationReplyFormModal()]
    const errorActions = [
      showHud('error', 'An error occurred and your changes could not be saved.')
    ]

    if (hasUserAccessToken()) {
      onUpdateInvitation(
        updatedInvitation,
        invitation.id,
        event.id,
        nextSuccessActions,
        errorActions
      )
    } else {
      onUpdateInvitation(
        updatedInvitation,
        invitationToken,
        eventToken,
        nextSuccessActions,
        errorActions
      )
    }
  }

  _sendInvitationReplyMessage = () => {
    const {
      invitation,
      invitationReplyMessage,
      onRequestSendInvitationReplyMessage,
      onHideRsvpModal
    } = this.props
    if (invitationReplyMessage.length > 0 && canSendInvitationReplyMessage(invitation)) {
      onRequestSendInvitationReplyMessage()
    }
    onHideRsvpModal()
    if (!isHobnobAndroidClient()) {
      this.handleNavigationHomeRouter()
    }
  }

  _guestName() {
    const { currentUser, invitation, invitationUser } = this.props
    if (currentUser) {
      return currentUser.first_name
    } else if (invitationUser && invitationUser.first_name) {
      return invitationUser.first_name
    } else if (invitation) {
      return invitation.first_name
    }
  }

  _renderInvitationReplyForm = (onDone = null) => {
    const { invitation, invitationReplyMessage, onSetInvitationReplyMessage } = this.props
    const onClick = () => {
      if (onDone) onDone()
      this._sendInvitationReplyMessage()
    }

    return (
      <div className={styles['invitationReplyMessageForm']}>
        {canSendInvitationReplyMessage(invitation) ? (
          <div>
            <ResizeableTextarea
              className={styles['invitation-reply-message-form-text-box']}
              comment={invitationReplyMessage}
              onChangeComment={onSetInvitationReplyMessage}
              onSubmitComment={this._sendInvitationReplyMessage}
              placeholder="Send a message to the host...(optional)"
            />
            <Button disabled={invitationReplyMessage === ''} onClick={onClick}>
              send message
            </Button>
          </div>
        ) : null}
      </div>
    )
  }

  _renderRsvpView() {
    const {
      requestedRsvp,
      event,
      changeRsvp,
      ticketTypesCounts,
      showTicketCounts,
      needPayTicket,
      listMyOrdersForEvent,
      onSetTempTicketTypesCounts,
      onSetOrderLineItems,
      onRevokeFreeTickets,
      onPurchaseTickets,
      isInvitationCredentialUser,
      onShowGuestCountControlsModal,
      onChangeRouter,
      onSetCurrentOrderId,
      loginForm
    } = this.props
    let { invitation } = this.props

    if (!invitation || (invitation && loginForm.loginStep === 'fullName')) {
      return <NoInvitationRSVPView requestedRsvp={requestedRsvp} guestName={this._guestName()} />
    }
    if (
      event.ticketing_enabled_at &&
      (requestedRsvp === 'accepted' || invitation.rsvp_state === 'accepted')
    ) {
      if (needPayTicket) {
        return (
          <PaidTicketsContainer
            guestCountEnabled={event.guest_count_enabled}
            childCountEnabled={event.child_count_enabled}
            showTicketCounts={showTicketCounts}
            listMyOrdersForEvent={listMyOrdersForEvent}
            invitationReplyForm={this._renderInvitationReplyForm()}
            onSetTempTicketTypesCounts={onSetTempTicketTypesCounts}
            onSetOrderLineItems={onSetOrderLineItems}
            onPurchaseTickets={onPurchaseTickets}
            onChangeRouter={onChangeRouter}
            onSetCurrentOrderId={onSetCurrentOrderId}
            onDeclineInvitation={this._onDeclineInvitation}
          />
        )
      } else {
        return (
          <FreeTicketsContainer
            guestCountEnabled={event.guest_count_enabled}
            invitation={invitation}
            changeRsvp={changeRsvp}
            invitationReplyForm={this._renderInvitationReplyForm()}
            showTicketCounts={showTicketCounts}
            ticketTypesCounts={ticketTypesCounts}
            listMyOrdersForEvent={listMyOrdersForEvent}
            onDeclineInvitation={this._onDeclineInvitation}
            onSetTempTicketTypesCounts={onSetTempTicketTypesCounts}
            onSetOrderLineItems={onSetOrderLineItems}
            onRevokeFreeTickets={onRevokeFreeTickets}
            onPurchaseTickets={onPurchaseTickets}
            isInvitationCredentialUser={isInvitationCredentialUser}
          />
        )
      }
    }

    if (invitation.rsvp_state === 'accepted') {
      return (
        <RSVPAccepted
          guestName={this._guestName()}
          guestCountEnabled={event.guest_count_enabled}
          childCountEnabled={event.child_count_enabled}
          invitation={invitation}
          changeRsvp={changeRsvp}
          onDeclineInvitation={this._onDeclineInvitation}
          invitationReplyForm={this._renderInvitationReplyForm()}
          onChangeGuestCounts={this._onChangeGuestCounts}
          showGuestCountControlsAction={onShowGuestCountControlsModal}
          rsvpTextClass={styles['rsvpText']}
        />
      )
    } else if (invitation.rsvp_state === 'declined' && requestedRsvp !== 'accepted') {
      return (
        <RSVPDeclined
          guestName={this._guestName()}
          guestCountEnabled={event.guest_count_enabled}
          changeRsvp={changeRsvp}
          invitationReplyForm={this._renderInvitationReplyForm()}
          onAcceptInvitation={this._onAcceptInvitation}
          rsvpTextClass={styles['rsvpText']}
          needPayTicket={needPayTicket}
          ticketTypesCounts={ticketTypesCounts}
          listMyOrdersForEvent={listMyOrdersForEvent}
          onChangeRouter={onChangeRouter}
          onSetCurrentOrderId={onSetCurrentOrderId}
        />
      )
    } else if (invitation.rsvp_state === 'undecided') {
      return (
        <ChangeRSVP
          rsvpTextClass={styles['rsvpText']}
          onAcceptInvitation={this._onAcceptInvitation}
          onDeclineInvitation={this._onDeclineInvitation}
        />
      )
    }
  }

  _renderLoadingView() {
    return (
      <div className={styles['loading-container']}>
        <OverlayLoadingIndicator loading light />
        Loading...
      </div>
    )
  }

  handleClose = () => {
    const {
      onClose,
      showInvitationReplyFormModal,
      changeRsvp,
      showDefaultDeclinedPage,
      invitation,
      requestedRsvp
    } = this.props
    onClose()

    const isDeclinedContainer =
      invitation?.rsvp_state === 'declined' && requestedRsvp !== 'accepted'
    // After rsvp completes, replyForm is true
    if (
      (showInvitationReplyFormModal ||
        (isDeclinedContainer && (!changeRsvp || (changeRsvp && !showDefaultDeclinedPage)))) &&
      !isHobnobAndroidClient()
    ) {
      this.handleNavigationHomeRouter()
    }
  }

  handleNavigationHomeRouter = () => {
    const { event, userId, onNavigationHomeRouter } = this.props
    console.log('跳到home页面')
    sessionStorage.openMessage = true
    setTimeout(() => {
      if (!sessionStorage.isNewTag) {
        window.mixpanel.track('User From Event', { event_id: event.id, user_id: userId })
        sessionStorage.isNewTag = true
      }
      onNavigationHomeRouter()
    }, 5000)
  }

  render() {
    const { rsvpModalVisible, ticketTypesCountsLoadingStatus } = this.props

    const loading =
      ticketTypesCountsLoadingStatus === 'loading' || ticketTypesCountsLoadingStatus === 'error'

    return (
      <HobnobModal visible={rsvpModalVisible} onCancel={this.handleClose} maskClosable={false}>
        <div className={styles['rsvp-modal-container']}>
          {loading ? this._renderLoadingView() : this._renderRsvpView()}
        </div>
      </HobnobModal>
    )
  }
}

RSVPModalContainer.propTypes = {
  userId: PropTypes.string,
  showDefaultDeclinedPage: PropTypes.bool,
  showInvitationReplyFormModal: PropTypes.bool,
  changeRsvp: PropTypes.bool,
  currentUser: PropTypes.object,
  loginForm: PropTypes.object,
  event: PropTypes.object,
  eventToken: PropTypes.string,
  invitation: PropTypes.object,
  invitationReplyMessage: PropTypes.string,
  invitationToken: PropTypes.string,
  invitationUser: PropTypes.object,
  requestedRsvp: PropTypes.string,
  rsvpModalVisible: PropTypes.bool,
  ticketTypesCounts: PropTypes.array,
  showTicketCounts: PropTypes.bool,
  isInvitationCredentialUser: PropTypes.bool,
  needPayTicket: PropTypes.bool,
  listMyOrdersForEvent: PropTypes.array,
  ticketTypesCountsLoadingStatus: PropTypes.string,
  onSetTempTicketTypesCounts: PropTypes.func,
  onSetOrderLineItems: PropTypes.func,
  onRevokeFreeTickets: PropTypes.func,
  onPurchaseTickets: PropTypes.func,
  onShowGuestCountControlsModal: PropTypes.func,
  onClose: PropTypes.func,
  onRequestSendInvitationReplyMessage: PropTypes.func,
  onHideRsvpModal: PropTypes.func,
  onSetInvitationReplyMessage: PropTypes.func,
  onSetRequestedRsvp: PropTypes.func,
  onRequestAcceptInvitation: PropTypes.func,
  onUpdateChangeRsvpStatus: PropTypes.func,
  onHasChangedRsvpStatus: PropTypes.func,
  onRequestDeclineInvitation: PropTypes.func,
  onShowDeclinedSendMessageModal: PropTypes.func,
  onUpdateInvitation: PropTypes.func,
  onChangeRouter: PropTypes.func,
  onSetCurrentOrderId: PropTypes.func,
  onNavigationHomeRouter: PropTypes.func
}

function mapStateToProps(state) {
  const ticketTypesCounts = ticketTypesCountsSelector(state)

  return {
    userId: state.userId,
    changeRsvp: rsvpModalChangeRsvpSelector(state),
    currentUser: userSelector(state),
    event: eventSelector(state),
    eventToken: eventTokenSelector(state),
    invitation: invitationSelector(state),
    invitationReplyMessage: invitationReplyMessageSelector(state),
    invitationToken: invitationTokenSelector(state),
    invitationUser: invitationUserSelector(state),
    requestedRsvp: requestedRsvpSelector(state),
    rsvpModalVisible: rsvpModalVisibleSelector(state),
    ticketTypesCounts,
    ticketTypesCountsLoadingStatus: ticketTypesCountsLoadingStatusSelector(state),
    showTicketCounts: showTicketTypesSelector(state),
    isInvitationCredentialUser: isInvitationCredentialUserSelector(state),
    needPayTicket: hasPaidTicketsSelector(state),
    listMyOrdersForEvent: listMyOrdersForEventSelector(state),
    showInvitationReplyFormModal: showInvitationReplyFormModalSelector(state),
    showDefaultDeclinedPage: rsvpModalShowDefaultDeclinedPageSelector(state),
    loginForm: loginFormSelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onShowGuestCountControlsModal: () => dispatch(showGuestCountControlsModal()),
    onClose: () => {
      dispatch(hideRsvpModal(true))
      dispatch(resetLoginForm())
    },
    onRequestSendInvitationReplyMessage: () => dispatch(requestSendInvitationReplyMessage()),
    onHideRsvpModal: () => dispatch(hideRsvpModal()),
    onSetInvitationReplyMessage: value => dispatch(setInvitationReplyMessage(value)),
    onSetRequestedRsvp: requestedRsvp => dispatch(setRequestedRsvp(requestedRsvp)),
    onRequestAcceptInvitation: () => dispatch(requestAcceptInvitation()),
    onUpdateChangeRsvpStatus: params => dispatch(updateChangeRsvpStatus(params)),
    onHasChangedRsvpStatus: () => dispatch(hasChangedRsvpStatus()),
    onRequestDeclineInvitation: () => dispatch(requestDeclineInvitation()),
    onShowDeclinedSendMessageModal: () => dispatch(showDeclinedSendMessageModal()),
    onUpdateInvitation: (updatedInvitation, invitationId, eventId, successActions, errorActions) =>
      dispatch(
        updateInvitation(updatedInvitation, invitationId, eventId, {
          successActions,
          errorActions
        })
      ),
    onSetTempTicketTypesCounts: ticketCounts => dispatch(setTempTicketTypesCounts(ticketCounts)),
    onSetOrderLineItems: queryVariables => dispatch(setOrderLineItems(queryVariables)),
    onRevokeFreeTickets: (revokeFreeTicketsIds, successActions) =>
      dispatch(revokeTicketsMutation(revokeFreeTicketsIds, { successActions })),
    onPurchaseTickets: (
      invitationId,
      orderLineItems,
      paymentMethodId,
      isInvitationCredentialUser,
      email
    ) =>
      dispatch(
        purchaseTickets(invitationId, orderLineItems, paymentMethodId, isInvitationCredentialUser, { email })
      ),
    onChangeRouter: (routerTab = 'orders') => {
      dispatch(hideRsvpModal(true))
      dispatch(resetLoginForm())
      dispatch(routerActions.push(routerTab))
      window.scrollTo(0, 0)
    },
    onNavigationHomeRouter: () => {
      dispatch(routerActions.push('/home'))
    },
    onSetCurrentOrderId: orderId => dispatch(setCurrentOrderId(orderId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RSVPModalContainer)
