/* eslint-disable react/jsx-indent-props */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './tickets-counts-selector-modal.scss'
import TicketCountsSelector from './TicketCountsSelector'
import PaymentMethodSelector from './PaymentMethodSelector'
import SvgIcon from '../common/SvgIcon'
import Button from '../common/Button'
import RefundPolicy from './RefundPolicy'
import { displayCurrency, isEmptyObject, sumCount } from '../../services/utils'
import { hasPaidTickets } from '../../services/payment_utils'

import OverlayLoadingIndicator from 'components/common/OverlayLoadingIndicator'
import {
  hasPaidTicketsSelector,
  purchaseTicketsLoadingSelector,
  receiptEmailSelector
} from '../../selectors'
import AppleIcon from '../../assets/icons/apple_icon.svg'
import ApplePayFormContainer from './ApplePayFormContainer'
import LeftChevronIcon from '../../assets/icons/left_chevron.svg'
import { setReciptEmail } from '../../actions/paymentGraphql'
import cn from 'classnames'
import { message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

class TicketsCountsSelectorModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      canMakePayment: false,
      payWithCreditCard: false,
      email: ''
    }
  }

  _onChangeCanMakePayment = (value) => {
    this.setState({
      canMakePayment: value
    })
  }

  _payWithCreditCard = () => {
    this.setState({
      payWithCreditCard: !this.state.payWithCreditCard
    })
  }

  _onPaymentMethodChange = value => {
    const { paymentMethodsList, setPaymentMethod } = this.props

    const paymentMethod = paymentMethodsList.filter(item => item.id === value)[0]

    setPaymentMethod(paymentMethod)
  }

  _sumPrice = (arr) => {
    let total = 0

    for (let i = 0; i < arr.length; i++) {
      total += arr[i].price * arr[i].count
    }

    return total
  }

  _validConfirm = () => {
    const { ticketCounts, email } = this.props
    const emailValid = /^\S+@\S+\.\S+$/.test(email)
    const total = sumCount(ticketCounts)
    return !!total && emailValid
  }

  _amountDue = () => {
    const { ticketCounts } = this.props
    const totalPrice = this._sumPrice(ticketCounts)
    return <div className={styles['amount-due-wrapper']}>
      <div className={styles['total-price']}>Total : {displayCurrency(totalPrice)}</div>
    </div>
  }

  _onConfirm = () => {
    const { onConfirm, showCreateNewCardModal, ticketCounts, paymentMethod } = this.props

    const amountDue = this._sumPrice(ticketCounts)

    const needsCard = amountDue > 0 && (!paymentMethod || isEmptyObject(paymentMethod))
    if (needsCard) {
      showCreateNewCardModal()
    } else {
      onConfirm(amountDue, paymentMethod, this.state.email)
    }
  }

  _applePay = (tokenSid) => {
    const { applePay } = this.props
    applePay(tokenSid, this.state.email)
  }

  _renderButton = () => {
    const { disabledButton, payWithCreditCard } = this.state

    const { ticketCounts } = this.props
    const amountDue = this._sumPrice(ticketCounts)
    const isValidConfirm = this._validConfirm()

    if (!isValidConfirm) {
      return <Button disabled={!isValidConfirm} className={styles['button']}
        onClick={this._onConfirm}>confirm & pay</Button>
    } else if (payWithCreditCard) {
      return <div>
        <Button disabled={!isValidConfirm} className={styles['button']}
          onClick={this._onConfirm}>confirm & pay</Button>
        <a className={styles['credit-card-button']}
          onClick={this._payWithCreditCard}>
          Buy With <SvgIcon icon={AppleIcon} className={styles['apple-icon']} /> Pay / Link by Stripe
        </a>
      </div>
    } else {
      return <ApplePayFormContainer amount={amountDue} onChangeCanMakePayment={this._onChangeCanMakePayment}
        element={<Button disabled={!isValidConfirm || disabledButton}
          className={styles['button']}
          onClick={this._onConfirm}
        >confirm & pay</Button>}
        applePay={this._applePay}
      >

        <a className={styles['credit-card-button']} onClick={this._payWithCreditCard}>Pay With Credit Card</a>
      </ApplePayFormContainer>
    }
  }

  render () {
    const {
      totalTicketsCounts,
      event,
      isPaidEvent,
      ticketTypesCounts,
      showTicketCounts,
      paymentMethodsList,
      showCreateNewCardModal,
      purchaseTicketsLoading,
      onBackPage,
      ticketCounts,
      paymentMethod,
      onChangeCount,
      onEmailChange,
      email
    } = this.props

    const { canMakePayment, payWithCreditCard } = this.state
    const showPaymentMethodList = !!paymentMethodsList.length && hasPaidTickets(ticketTypesCounts) && ((canMakePayment && payWithCreditCard) || !canMakePayment)
    const guestCountEnabled = event.guest_count_enabled
    const total = sumCount(ticketCounts)
    const isValidConfirm = this._validConfirm()

    return (
      <div className={styles['wrapper']}>
        {
          !!totalTicketsCounts && <SvgIcon className={styles['left-chevron-icon']} icon={LeftChevronIcon} padding='5px'
            onClick={onBackPage} divClassName={styles['icon-wrapper']} />
        }
        <h3 className={styles['title']}>{totalTicketsCounts ? 'Buy Tickets' : `Yes, I’m Going`}</h3>
        <div className={styles['event-name']}>{event.name}</div>
        <div className={styles['tip-label']}>{
          totalTicketsCounts ? 'How many tickets do you want to buy?' : 'How many tickets do you need?'
        }</div>
        <TicketCountsSelector
          guestCountEnabled={guestCountEnabled}
          isPaidEvent={isPaidEvent}
          ticketCounts={ticketCounts}
          ticketTypesCounts={ticketTypesCounts}
          showTicketCounts={showTicketCounts}
          onChangeCount={onChangeCount}
        />

        <div className={styles['email-wrapper']}>
          <input
            type='email'
            className={styles['email']}
            name='email'
            value={email || ''}
            onChange={e => onEmailChange(e.target.value)}
            placeholder='Email (required)' />
        </div>

        <div className={styles['method-total-wrapper']}
          style={{ justifyContent: showPaymentMethodList ? 'space-between' : 'flex-end' }}>

          {showPaymentMethodList &&
          <PaymentMethodSelector
            paymentMethod={paymentMethod}
            paymentMethodsList={paymentMethodsList}
            onPickerChange={this._onPaymentMethodChange}
          />}

          {this._amountDue()}
        </div>

        {showPaymentMethodList &&
          <div
            className={cn(styles['add-payment-method'], { [styles.disabled]: !isValidConfirm })}
            onClick={() => {
              if (isValidConfirm) {
                showCreateNewCardModal()
              } else if (!total) {
                message.error('You must select the number of tickets.')
              } else {
                message.error('You must enter a valid email.')
              }
            }}
          >
            <PlusOutlined className={styles['chevron-down-small-icon']} />
            <span>Pay with a New Card</span>
          </div>
        }

        {this._renderButton()}

        {
          event && !!event.refund_policy && <RefundPolicy refundPolicy={event.refund_policy} />
        }

        <OverlayLoadingIndicator loading={purchaseTicketsLoading} light />
      </div>
    )
  }
}

TicketsCountsSelectorModal.propTypes = {
  totalTicketsCounts: PropTypes.number,
  event: PropTypes.object,
  isPaidEvent: PropTypes.bool,
  ticketTypesCounts: PropTypes.array,
  showTicketCounts: PropTypes.bool,
  paymentMethodsList: PropTypes.array,
  showCreateNewCardModal: PropTypes.func,
  applePay: PropTypes.func,
  onConfirm: PropTypes.func,
  purchaseTicketsLoading: PropTypes.bool,
  onBackPage: PropTypes.func,
  ticketCounts: PropTypes.array,
  paymentMethod: PropTypes.object,
  onChangeCount: PropTypes.func,
  onEmailChange: PropTypes.func,
  email: PropTypes.string,
  setPaymentMethod: PropTypes.func
}

function mapStateToProps (state) {
  return {
    isPaidEvent: hasPaidTicketsSelector(state),
    purchaseTicketsLoading: purchaseTicketsLoadingSelector(state),
    email: receiptEmailSelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onEmailChange: (email) => dispatch(setReciptEmail(email))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketsCountsSelectorModal)
