import PropTypes from 'prop-types'
import React from 'react'

import ResizeableTextarea from 'src/components/common/ResizeableTextarea'

import styles from './medium-comment-form.scss'

export default class MediumCommentForm extends React.Component {
  state = {
    mediumCommentContent: ''
  }

  _onChangeComment = (content) => {
    this.setState({ mediumCommentContent: content })
  }

  _onSubmitComment = () => {
    const { onSubmitComment } = this.props
    const { mediumCommentContent } = this.state
    if (mediumCommentContent.length > 0) {
      onSubmitComment(mediumCommentContent)
      this.setState({ mediumCommentContent: '' })
    }
  }

  _renderSubmitButton () {
    const { mediumCommentContent } = this.state
    const postButtonEnabled = mediumCommentContent.length > 0
    const postButtonClass = styles[postButtonEnabled ? 'post-button' : 'post-button-disabled']

    return (
      <div className={styles['post-button-container']}>
        <a className={postButtonClass} onClick={this._onSubmitComment}>
          Post
        </a>
      </div>
    )
  }

  render () {
    const { placeholder } = this.props
    const { mediumCommentContent } = this.state

    return (
      <div className={styles['container']}>
        <ResizeableTextarea
          comment={mediumCommentContent}
          className={styles['comment-input']}
          defaultHeight={35}
          placeholder={placeholder}
          onChangeComment={this._onChangeComment}
          onFocus={this._onFocus}
          onSubmitComment={this._onSubmitComment}
        />
        {this._renderSubmitButton()}
      </div>
    )
  }
}

MediumCommentForm.propTypes = {
  onSubmitComment: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired
}
