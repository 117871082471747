import React from 'react'
import PropTypes from 'prop-types'
import { userSelector } from '../../selectors'
import { injectStripe, PaymentRequestButtonElement } from 'react-stripe-elements'
import { fullName } from '../../services/user_helpers'
import { connect } from 'react-redux'
import styles from './payment-request-button.scss'

class PaymentRequestButton extends React.Component {
  constructor (props) {
    super(props)
    const { onChangeCanMakePayment, stripe, amount, currentUser, onSubmit } = props

    const full_Name = currentUser ? fullName(currentUser) : ''

    // For full documentation of the available paymentRequest options, see:
    // https://stripe.com/docs/stripe.js#the-payment-request-object
    if (stripe) {
      const paymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: full_Name,
          amount: amount
        }
      })

      paymentRequest.on('token', (result) => {
        const { complete, token } = result

        complete('success')
        onSubmit(token.id)
      })

      paymentRequest.canMakePayment().then((result) => {
        this.setState({ canMakePayment: !!result })
        onChangeCanMakePayment(!!result)
      })

      this.state = {
        canMakePayment: false,
        paymentRequest
      }
    } else if (window.mixpanel) {
      window.mixpanel.track(
        'Stripe JS Load Failed (Web)',
        { userAgent: window.navigator.userAgent }
      )
    }
  }

  _onClickPaymentButton = () => {
    const { amount, currentUser, onClickPaymentButton } = this.props
    const full_Name = currentUser ? fullName(currentUser) : ''
    this.state.paymentRequest.update({
      total: {
        label: full_Name,
        amount: amount
      }
    })
    if (onClickPaymentButton) {
      onClickPaymentButton()
    }
  }

  render () {
    const { element, children, stripe } = this.props
    // For more details on how to style the Payment Request Button, see:
    // https://stripe.com/docs/elements/payment-request-button#styling-the-element
    const paymentRequestButton = {
      theme: 'dark',
      height: '46px',
      type: 'buy'
    }

    if (!stripe) {
      return (
        <div className={styles['tip-label']}>
          Payments are unavailable at this time. Please retry later or <a href='mailto:help@hobnob.io?subject=Payments unavailable issue'>contact support</a>.
        </div>
      )
    }

    return this.state.canMakePayment ? (
      <div>
        <PaymentRequestButtonElement
          paymentRequest={this.state.paymentRequest}
          className={styles['PaymentRequestButton']}
          onClick={this._onClickPaymentButton}
          style={{
            paymentRequestButton: paymentRequestButton
          }}
        />
        {children}
      </div>
    ) : element
  }
}

PaymentRequestButton.propTypes = {
  stripe: PropTypes.any,
  onSubmit: PropTypes.func,
  currentUser: PropTypes.object,
  onChangeCanMakePayment: PropTypes.func,
  element: PropTypes.element,
  children: PropTypes.element,
  amount: PropTypes.number,
  onClickPaymentButton: PropTypes.func
}

function mapStateToProps (state) {
  return {
    currentUser: userSelector(state)
  }
}

export default injectStripe(connect(mapStateToProps)(PaymentRequestButton))
