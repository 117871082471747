import uuid from 'uuid'

import { chatCreateComment } from 'src/actions/chat'
import { generateTempId } from 'src/services/utils'
import { mapUrl } from 'src/services/map_helpers'
import { CommentStatus } from 'src/services/comment_helpers'

export const ChatBodyType = Object.freeze({
  IMAGE: 'image',
  LOCATION: 'location'
})

export function createTempLocationComment (userId) {
  let comment = createTempComment('Retrieving location...', {}, userId)

  return comment
}

export function createTempImageUploadComment (imageInfo, userId) {
  const metadata = {
    body_type: ChatBodyType.IMAGE,
    thumb_url: imageInfo.url,
    large_url: imageInfo.url
  }
  let comment = createTempComment('Sending Image', metadata, userId, false)

  return comment
}

export function createTempComment (content, metadata, userId, local = true) {
  // TODO: Use comment_helpers?
  let id
  if (local) {
    id = generateTempId()
  } else {
    id = uuid.v4()
  }

  return {
    id,
    content,
    created_at: new Date().toISOString(),
    // Used to track the comment after created on the server
    localId: id,
    user: userId,
    localStatus: CommentStatus.SENDING,
    metadata
  }
}

export function chatSendLocation (tempComment, lat, lng, eventId) {
  let comment = { ...tempComment }
  comment.content = `Here is my location: ${mapUrl(lat, lng)}`
  comment.metadata = {
    body_type: ChatBodyType.LOCATION,
    latitude: lat.toString(),
    longitude: lng.toString()
  }
  return chatCreateComment(comment, eventId)
}

export function chatSendImage (tempComment, image, eventId) {
  let comment = { ...tempComment }
  comment.content = `Here is an image: ${image.large}`
  comment.metadata = {
    body_type: ChatBodyType.IMAGE,
    thumb_url: image.thumbnail_2x,
    large_url: image.large,
    upload_id: image.id
  }
  return chatCreateComment(comment, eventId, 'PUT')
}
