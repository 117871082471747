import { call, put, select, spawn, take, takeEvery } from 'redux-saga/effects'

import {
  CANCEL_REFUND_REQUEST_BY_GUEST_ERROR,
  CANCEL_REFUND_REQUEST_BY_GUEST_SUCCESS,
  CREATE_PAYMENT_METHOD_ERROR,
  CREATE_PAYMENT_METHOD_SUCCESS,
  CREATE_REFUND_REQUEST_BY_GUEST_ERROR,
  CREATE_REFUND_REQUEST_BY_GUEST_SUCCESS,
  EVENT_TICKETS_COUNTS_SUCCESS,
  eventTicketCountsRequest,
  LIST_MY_ORDERS_FOR_EVENT_REQUEST_SUCCESS,
  listMyOrdersForEventRequest,
  PAYMENT_METHODS_LIST_REQUEST_SUCCESS,
  PURCHASE_TICKETS_ERROR,
  PURCHASE_TICKETS_SUCCESS,
  purchaseTickets,
  REVOKE_TICKETS_MUTATION_ERROR,
  REVOKE_TICKETS_MUTATION_SUCCESS,
  SET_TICKET_TYPES_COUNTS,
  setEventTicketsCounts,
  setListOrdersForEvent,
  setOrderLineItems,
  setPaymentMethodList,
  setTicketTypesCounts,
  showInvitationReplyFormModal,
  showTicketCountControlModal
} from '../actions/paymentGraphql'

import {
  eventIdSelector,
  eventSelector,
  hasPaidTicketsSelector,
  hostCanAcceptPaymentSelector,
  invitationIdSelector,
  invitationSelector,
  isInvitationCredentialUserSelector,
  ticketTypesCountsLoadingStatusSelector,
  orderLineItemsSelector,
  paymentMethodsListSelector,
  tempTicketTypesCountsSelector,
  ticketTypesCountsSelector,
  receiptEmailSelector
} from '../selectors'
import { showHud } from '../actions/frontend'
import {
  ACCEPT_RSVP_INITIATED,
  requestAcceptInvitation
} from '../actions/invitations'
import { changeRefreshModal } from 'src/actions/event'
import { routerActions } from 'react-router-redux'
import { dealWithInvitationsCount } from './utils'

export default function * paymentRootSaga () {
  yield spawn(watchPaymentMethodsListRequest)
  yield spawn(watchCreatePaymentMethodMutation)
  yield spawn(watchPurchaseTicketMutation)
  yield spawn(watchAcceptRsvpInitiated)
  yield spawn(watchListMyOrdersForEventRequest)
  yield spawn(watchRevokeTicketsMutation)
  yield spawn(watchRequestRefundSuccess)
  yield takeEvery([CANCEL_REFUND_REQUEST_BY_GUEST_SUCCESS, CANCEL_REFUND_REQUEST_BY_GUEST_ERROR], watchCancelRefundSuccess)
  yield takeEvery(EVENT_TICKETS_COUNTS_SUCCESS, watchEventTicketsCountsRequest)
}

function * watchPaymentMethodsListRequest () {
  while (true) {
    const action = yield take(PAYMENT_METHODS_LIST_REQUEST_SUCCESS)
    if (action.response && action.response.data.list_all_payment_methods) {
      yield put(setPaymentMethodList(action.response.data.list_all_payment_methods))
    }
  }
}

function * watchCreatePaymentMethodMutation () {
  while (true) {
    const createPaymentMethodAction = yield take([CREATE_PAYMENT_METHOD_SUCCESS, CREATE_PAYMENT_METHOD_ERROR])
    if (createPaymentMethodAction.type === CREATE_PAYMENT_METHOD_ERROR) {
      yield put(showHud('error', 'There was a problem adding you bank or card. Please try again in a few moments'))
    } else if (createPaymentMethodAction.response && createPaymentMethodAction.response && createPaymentMethodAction.response.data.create_payment_method) {
      let currentPaymentMethods = yield select(paymentMethodsListSelector)

      currentPaymentMethods.push(createPaymentMethodAction.response.data.create_payment_method)
      const isInvitationCredentialUser = yield select(isInvitationCredentialUserSelector)

      // purchase tickets
      yield put(setPaymentMethodList(currentPaymentMethods))
      const invitationId = yield select(invitationIdSelector)
      const orderLineItems = yield select(orderLineItemsSelector)
      const email = yield select(receiptEmailSelector)
      const paymentMethodId = createPaymentMethodAction.response.data.create_payment_method.id

      // if user has a choice of tickets counts, the ticket should be purchased,
      // otherwise the ticket number modal should be displayed to select the ticket.
      if (orderLineItems.length && /^\S+@\S+\.\S+$/.test(email)) {
        yield put(purchaseTickets(invitationId, orderLineItems, paymentMethodId, !isInvitationCredentialUser, { email }))
      } else {
        yield put(showTicketCountControlModal())
      }
    } else {
      yield put(showHud('error', createPaymentMethodAction.response.errors[0].message))
    }
  }
}

function * watchPurchaseTicketMutation () {
  let errorMessage = 'There was a problem purchasing tickets. Please try again in a few moments'
  while (true) {
    const action = yield take([PURCHASE_TICKETS_SUCCESS, PURCHASE_TICKETS_ERROR])
    const event = yield select(eventSelector)
    if (action.type === PURCHASE_TICKETS_ERROR) {
      yield put(showHud('error', errorMessage))
    } else if (action.response && action.response.data && action.response.data.purchase_tickets_v2) {
      const tempTicketTypesCounts = yield select(tempTicketTypesCountsSelector)
      yield put(setTicketTypesCounts(tempTicketTypesCounts))

      yield put(showInvitationReplyFormModal())

      const invitation = yield select(invitationSelector)

      if (invitation && invitation.rsvp_state !== 'accepted') {
        // If the guest just purchased tickets, try to ensure that they have also RSVP'd yes
        yield put(requestAcceptInvitation())
      }

      // Can this be combined with the purhcase tickets mutation?
      yield put(listMyOrdersForEventRequest(event.id))
      yield put(eventTicketCountsRequest(event.id))
    } else {
      let errorType = ''
      if (action.response.errors.length) {
        errorMessage = action.response.errors[0].message
        errorType = action.response.errors[0].error_type
      }

      if (errorType === 'invalid_ticket_type') {
        yield put(changeRefreshModal(true))
      } else {
        yield put(showHud('error', errorMessage))
      }
    }
  }
}

// This function waits for the user to hit "Yes I'm Going" on an event. Then if the event does not have paid tickets and we don't need to collect a guest count it finishes the RSVP
function * watchAcceptRsvpInitiated () {
  while (true) {
    yield take(ACCEPT_RSVP_INITIATED)
    const invitation = yield select(invitationSelector)
    const event = yield select(eventSelector)

    // Ensure that ticket types counts have been fetched and processed
    yield call(ticketTypesCountsLoaded)
    const ticketTypesCounts = yield select(ticketTypesCountsSelector)

    const isInvitationCredentialUser = yield select(isInvitationCredentialUserSelector)

    const eventHasPaidTickets = yield select(hasPaidTicketsSelector)
    const hostCanAcceptPayment = yield select(hostCanAcceptPaymentSelector)

    if (eventHasPaidTickets && !hostCanAcceptPayment) {
      const errorMessage = 'Unable to purchase tickets. The host has not completed set-up to enable ticket purchases. Please contact the host in order to complete your RSVP.'
      yield put(showHud('error', errorMessage))
    }

    if (invitation) {
      if (!eventHasPaidTickets && !event.guest_count_enabled) {
        let queryVariables = []
        ticketTypesCounts.filter(item => !item.archived_at).forEach((item) => {
          if (!item.count) {
            const obj = { ticket_type_id: item.id, count: 1 }
            queryVariables.push(obj)
          }
        })
        yield put(setOrderLineItems(queryVariables))
        yield put(purchaseTickets(invitation.id, queryVariables, null, !isInvitationCredentialUser))
      }
    }

    yield put(routerActions.push('rsvp'))
  }
}

function * watchListMyOrdersForEventRequest () {
  while (true) {
    const action = yield take(LIST_MY_ORDERS_FOR_EVENT_REQUEST_SUCCESS)

    if (action.response && action.response.data.list_my_orders_for_event) {
      const orders = action.response.data.list_my_orders_for_event.orders
      yield put(setListOrdersForEvent(orders))
    }
  }
}

function * watchRevokeTicketsMutation () {
  while (true) {
    const errorMessage = 'There was a problem revoke tickets. Please try again in a few moments'
    const action = yield take(REVOKE_TICKETS_MUTATION_SUCCESS, REVOKE_TICKETS_MUTATION_ERROR)
    const event = yield select(eventSelector)
    if (action.type === REVOKE_TICKETS_MUTATION_ERROR) {
      yield put(showHud('error', errorMessage))
    } else if (action.response && action.response.data && action.response.data.revoke_tickets.successful) {
      const tempTicketTypesCounts = yield select(tempTicketTypesCountsSelector)
      yield put(setTicketTypesCounts(tempTicketTypesCounts))

      // request list_my_orders_for_event
      yield put(listMyOrdersForEventRequest(event.id))
      // request eventTicketCountsRequest
      yield put(eventTicketCountsRequest(event.id))
    } else {
      yield put(showHud('error', errorMessage))
    }
  }
}

function * watchEventTicketsCountsRequest (action) {
  const event = yield select(eventSelector)
  if (action.response && action.response.data.events) {
    const eventTicketCounts = action.response.data.events.filter(item => item.id === event.id)[0]
    yield put(setEventTicketsCounts(eventTicketCounts))

    yield call(dealWithInvitationsCount)
  }
}

function * watchRequestRefundSuccess () {
  while (true) {
    const errorMessage = 'There was a problem when request refund. Please try again in a few moments'
    const action = yield take([CREATE_REFUND_REQUEST_BY_GUEST_SUCCESS, CREATE_REFUND_REQUEST_BY_GUEST_ERROR])
    if (action.type === CREATE_REFUND_REQUEST_BY_GUEST_ERROR) {
      yield put(showHud('error', errorMessage))
    } else if (action.response && action.response.data && action.response.data.create_refund_request_by_guest) {
      const eventId = yield select(eventIdSelector)
      yield put(listMyOrdersForEventRequest(eventId))
    } else {
      yield put(showHud('error', errorMessage))
    }
  }
}

function * watchCancelRefundSuccess (action) {
  const errorMessage = 'There was a problem when cancel refund. Please try again in a few moments'
  if (action.type === CANCEL_REFUND_REQUEST_BY_GUEST_ERROR) {
    yield put(showHud('error', errorMessage))
  } else if (action.response && action.response.data && action.response.data.cancel_refund_request_by_guest) {
    const eventId = yield select(eventIdSelector)
    yield put(listMyOrdersForEventRequest(eventId))
  } else {
    yield put(showHud('error', errorMessage))
  }
}

// This function will only return once ticket types counts are loaded
function * ticketTypesCountsLoaded () {
  const loadingStatus = yield select(ticketTypesCountsLoadingStatusSelector)
  if (loadingStatus === 'success') {
    return true
  } else {
    yield take(SET_TICKET_TYPES_COUNTS)
    return true
  }
}
