import {
  CHANGE_EVENT_FORM,
  FETCH_ME_COMMUNITIES_SUCCESS,
  CHANGE_EVENTS_TAB,
  RESET_EVENT_FORM,
  CHANGE_EVENTS_MODAL,
  CHANGE_ADD_GUESTS_LIST,
  RESET_INVITATIONS_PUSHER,
  CHANGE_REFRESH_MODAL
} from 'src/actions/event'

import { PUSHER_EVENT_RECEIVED } from 'src/actions'

import { SHOW_SEND_UPDATE_MODAL, HIDE_SEND_UPDATE_MODAL } from 'src/actions/frontend'

import { SET_EVENT_TICKETS_COUNTS } from 'src/actions/paymentGraphql'

const initialEventForm = {
  name: '', // 1
  starts_at: null, // 1
  ends_at: null, // 1
  timezone: 'Pacific/Honolulu', // 1
  external_community_id: null,
  description: '', // 1
  custom_venue_name: '', // 1
  flyer_design_number: null, // 1
  flyer_design_slug: null,
  guest_list_enabled: true, // 1
  commenting_enabled: true, // 1
  guest_count_enabled: true, // 1
  child_count_enabled: false, // 1
  custom_host_name: '', // 1
  max_capacity: 9999, // 1
  limit_per_invitation: 9999, // 1
  cutoff_date: null, // 1
  custom_invitation_text: '', // 1
  reminders_enabled: true, // 1
  registry_reminder_enabled: true, // 1
  request_media_enabled: true, // 1
  cap_error_msg: 'Sorry, the event is at maximum capacity, we can no longer accept RSVPs',
  event_type: 'event',
  meeting_url: null,
  apple_place_id: null,
  google_place_id: null,
  venue: null
}

export const eventForm = (state = initialEventForm, action) => {
  switch (action.type) {
    case CHANGE_EVENT_FORM:
      return { ...state, ...action.params }
    case RESET_EVENT_FORM:
      return initialEventForm
    default:
      return state
  }
}

export const changeEventFormStatus = (state = 'noUpdate', action) => {
  switch (action.type) {
    case CHANGE_EVENT_FORM:
      return 'updated'
    case RESET_EVENT_FORM:
      return 'noUpdate'
    default:
      return state
  }
}

export const communityMemberList = (state = [], action) => {
  switch (action.type) {
    case FETCH_ME_COMMUNITIES_SUCCESS:
      return action.response.data.me?.communities || []
    default:
      return state
  }
}

export const sendUpdateModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_SEND_UPDATE_MODAL:
      return true
    case HIDE_SEND_UPDATE_MODAL:
      return false
    default:
      return state
  }
}

export const eventsTab = (state = 'active', action) => {
  switch (action.type) {
    case CHANGE_EVENTS_TAB:
      return action.value
    default:
      return state
  }
}

export const eventModal = (state = 'details', action) => {
  switch (action.type) {
    case CHANGE_EVENTS_MODAL:
      return action.value
    default:
      return state
  }
}

export const addGuestsList = (state = [], action) => {
  switch (action.type) {
    case CHANGE_ADD_GUESTS_LIST:
      return action.value
    default:
      return state
  }
}

export const invitationsPusher = (state = [], action) => {
  switch (action.type) {
    case PUSHER_EVENT_RECEIVED:
      if (action.response?.result?.invitation) {
        return [...state, action.response.result.invitation]
      }
      return state
    case SET_EVENT_TICKETS_COUNTS:
      return [1]
    case RESET_INVITATIONS_PUSHER:
      return []
    default:
      return state
  }
}

export const isRefreshModalVisible = (state = false, action) => {
  switch (action.type) {
    case CHANGE_REFRESH_MODAL:
      return action.value
    default:
      return state
  }
}
