import React, { useEffect } from 'react'
import CommonFrame from '../CommonFrame/CommonFrame'

import CreditImage from 'src/assets/checkout-credit-description-image.png'
import CreditOptions from './CreditOptions/CreditOptions'

import styles from './purchase-credit.scss'

const PurchaseCredit = () => {
  useEffect(() => {}, [])

  const actionLayer = (
    <div className={styles['action-container']}>
      <h2>Get more Smart Invites</h2>
      <div style={{ flex: 1, height: 0 }}>
        <CreditOptions />
      </div>
    </div>
  )
  const descLayer = (
    <div className={styles['desc-container']}>
      <h2>The more you buy today, the less they cost!</h2>
      <p>Now that you’re a member, you can buy only the credits you want and they never expire! </p>
      <p>
        Credits can be used for our Automated Invites, which offer invite Delivery and Viewed
        tracking, automated RSVP reminders, and one-tap send of your entire guest list.
      </p>
      <img src={CreditImage} width="260" alt="" />
    </div>
  )

  return <CommonFrame actionLayer={actionLayer} descLayer={descLayer} />
}

export default PurchaseCredit
