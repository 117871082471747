import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'

import SvgIcon from 'src/components/common/SvgIcon'

import ShareFacebookIcon from 'src/assets/icons/share_facebook.svg'
import ShareTwitterIcon from 'src/assets/icons/share_twitter.svg'
import ShareDownloadIcon from 'src/assets/icons/share_download.svg'
import SharePinterestIcon from 'src/assets/icons/share_pinterest.svg'

import styles from './medium-share-tooltip.scss'

export default class MediumShareTooltip extends React.Component {
  _facebookUrl() {
    const { medium } = this.props
    const baseUrl = 'https://www.facebook.com/sharer/sharer.php'
    const params = {
      u: medium.facebook_share_url
    }
    return `${baseUrl}?${queryString.stringify(params)}`
  }

  _twitterUrl() {
    const { medium } = this.props
    const baseUrl = 'https://twitter.com/intent/tweet'
    const params = {
      text: '',
      url: medium.twitter_share_url
    }
    return `${baseUrl}?${queryString.stringify(params)}`
  }

  _downloadUrl() {
    return this.props.medium.file.url_2x
  }

  _pinterestUrl() {
    const { medium } = this.props
    const baseUrl = 'http://www.pinterest.com/pin/create/button/'
    const params = {
      description: '',
      url: medium.pinterest_share_url,
      media: medium.file.url_2x
    }
    return `${baseUrl}?${queryString.stringify(params)}`
  }

  downloadImage = () => {
    const { medium } = this.props
    let img = new Image()
    img.setAttribute('crossOrigin', 'Anonymous')
    img.onload = function () {
      let canvas = document.createElement('canvas')
      let context = canvas.getContext('2d')
      canvas.width = img.width
      canvas.height = img.height
      context.drawImage(img, 0, 0, img.width, img.height)
      canvas.toBlob(blob => {
        let url = URL.createObjectURL(blob)
        let a = document.createElement('a')
        let event = new MouseEvent('click')
        a.download = medium.id || 'default.png'
        a.href = url
        a.dispatchEvent(event)
        URL.revokeObjectURL(url)
      })
    }
    img.src = medium.file.url_2x + '?v=' + Date.now()
  }

  render() {
    return (
      <div className={styles['container']}>
        <a className={styles['icon-container']} href={this._facebookUrl()} target="_blank">
          <SvgIcon className={styles['icon']} icon={ShareFacebookIcon} />
          <div className={styles['icon-label']}>Facebook</div>
        </a>
        <a className={styles['icon-container']} href={this._twitterUrl()} target="_blank">
          <SvgIcon className={styles['icon']} icon={ShareTwitterIcon} />
          <div className={styles['icon-label']}>X (Twitter)</div>
        </a>
        <a className={styles['icon-container']} onClick={this.downloadImage}>
          <SvgIcon className={styles['icon']} icon={ShareDownloadIcon} />
          <div className={styles['icon-label']}>Download</div>
        </a>
        <a className={styles['icon-container']} href={this._pinterestUrl()} target="_blank">
          <SvgIcon className={styles['icon']} icon={SharePinterestIcon} />
          <div className={styles['icon-label']}>Pin it</div>
        </a>
      </div>
    )
  }
}

MediumShareTooltip.propTypes = {
  medium: PropTypes.object.isRequired
}
