export const CHANGE_PLAN_MODAL_VISIBLE = 'CHANGE_PLAN_MODAL_VISIBLE'
export const SET_IS_PLAN_DOWNGRADE = 'SET_IS_PLAN_DOWNGRADE'
export const SET_SPECIFY_PLAN = 'SET_SPECIFY_PLAN'

export const changePlanModalVisible = visible => ({
  type: CHANGE_PLAN_MODAL_VISIBLE,
  visible
})

export const setIsPlanDowngrade = downgrade => ({
  type: SET_IS_PLAN_DOWNGRADE,
  downgrade
})

export const setSpecifyPlan = plan => ({
  type: SET_SPECIFY_PLAN,
  plan
})
