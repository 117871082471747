import React from 'react'
import PropTypes from 'prop-types'
import Cropper from 'react-cropper'
import styles from './image-cropper.scss'
import Button from './Button'
import { compressImage } from '../../actions/utils'

const OPTIMIZED_IMAGE_WIDTH = 1242
const DEFAULT_ZOOM_STEP = 0.1
const DEFAULT_ROTATE_STEP = 10

export default class ImageCropper extends React.Component {
  constructor(props) {
    super(props)
    this.cropperRef = React.createRef()
    this.inputRef = React.createRef()
    this.zoom = 1
    this.rotate = 0
    this.state = {
      selectedImageDataURL: null
    }
  }

  crop = async () => {
    const { onCrop } = this.props
    const cropper = this.cropperRef?.current?.cropper
    if (!cropper) {
      console.warn('cropper is not defined')
      return
    }
    const croppedCanvas = cropper.getCroppedCanvas()
    let croppedImage = croppedCanvas.toDataURL()
    if (croppedCanvas.width > OPTIMIZED_IMAGE_WIDTH) {
      croppedImage = await compressImage(croppedImage, {
        width: OPTIMIZED_IMAGE_WIDTH })
    }
    if (onCrop) {
      onCrop(croppedImage)
    } else {
      onCrop(null)
      console.warn('onCrop is not defined')
    }
  };

  cancel = () => {
    const { onCancel } = this.props
    this.setState({ selectedImageDataURL: null })
    if (onCancel) {
      onCancel()
    } else {
      console.warn('onCancel is not defined')
    }
  }

  render() {
    const viewPortHeight = window.innerHeight
    const cropperHeight = Math.max(viewPortHeight - 500, 325)
    return (
      <main>
        <section>
          <header>
            <h3>Edit Image</h3>
          </header>
        </section>
        <section>
          <Cropper
            className={styles['cropper-wrapper']}
            src={this.props.imageDataURL}
            style={{ height: cropperHeight, width: '100%' }}
            aspectRatio={9 / 16}
            guides
            viewMode={1}
            dragMode="move"
            ref={this.cropperRef}
          />
          <section className={styles['image-operations']}>
            <div onClick={() => {
              this.setCropperZoom('in')
            }}>+</div>
            <div onClick={() => {
              this.setCropperZoom('out')
            }}>-</div>
          </section>
        </section>
        <section className={styles['actions']}>
          <Button filled={false} onClick={this.cancel}><span>Cancel</span></Button>
          <Button onClick={this.crop}><span>Save</span></Button>
        </section>
      </main>
    )
  }

  setCropperZoom(direction = 'in') {
    const cropper = this.cropperRef?.current?.cropper
    if (!cropper) {
      console.warn('cropper is not defined')
      return
    }
    let step
    if (direction === 'in') {
      step = DEFAULT_ZOOM_STEP
    } else {
      step = DEFAULT_ZOOM_STEP * -1
    }
    this.zoom += step
    if (this.zoom < 0.1 || this.zoom > 2) {
      this.zoom = Math.min(2, this.zoom)
      this.zoom = Math.max(0.1, this.zoom)
      return
    }
    cropper.zoom(step)
  }

  setCropperRotate(direction = 'left') {
    const cropper = this.cropperRef?.current?.cropper
    if (!cropper) {
      console.warn('cropper is not defined')
      return
    }
    let step
    if (direction === 'left') {
      step = DEFAULT_ROTATE_STEP * -1
    } else {
      step = DEFAULT_ROTATE_STEP
    }
    this.rotate += step
    if (this.rotate < -180 || this.rotate > 180) {
      this.rotate = Math.min(180, this.rotate)
      this.rotate = Math.max(-180, this.rotate)
      return
    }
    cropper.rotate(step)
  }
}

ImageCropper.propTypes = {
  onCrop: PropTypes.func,
  onCancel: PropTypes.func,
  imageDataURL: PropTypes.string
}
