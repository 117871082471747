import PropTypes from 'prop-types'
import React from 'react'
import omit from 'lodash/omit'
import throttle from 'lodash/throttle'

export default class ResizeableTextarea extends React.Component {
  state = {
    height: 0
  };

  componentDidMount () {
    this._updateTextareaHeight()
  }

  componentWillReceiveProps (nextProps) {
    // If the comment was submitted then reset the height
    if (this.props.comment.length > 0 && nextProps.comment.length === 0) {
      this.setState({ height: this.props.defaultHeight })
    }
  }

  _updateTextareaHeight = () => {
    if (this._calculateTextareaHeight() !== this.state.height) {
      this.setState({
        height: this._calculateTextareaHeight()
      })
    }
  };

  _onChangeComment = (e) => {
    this.props.onChangeComment(e.target.value)
    this._throttledUpdateTextAreaHeight()
  };

  _onKeyDown = (e) => {
    const { onSubmitComment } = this.props
    if (e.key === 'Enter' && onSubmitComment) {
      e.preventDefault()
      onSubmitComment()
    }
  }

  _throttledUpdateTextAreaHeight = throttle(this._updateTextareaHeight, 250, { leading: false });

  _calculateTextareaHeight () {
    const { defaultHeight } = this.props
    if (!this._textarea) {
      return defaultHeight
    }
    if (this.props.comment === '') {
      return defaultHeight
    }

    const newHeight = this._textarea.scrollHeight
    const currentHeight = this._textarea.offsetHeight

    if (newHeight > currentHeight) {
      return newHeight
    }

    return currentHeight
  }

  _saveTextareaRef = (c) => {
    this._textarea = c
  };

  render () {
    const { className, onBlur, onFocus, comment, ...otherProps } = this.props
    const textareaProps = omit(otherProps, ['defaultHeight', 'onSubmitComment', 'onChangeComment'])
    const { height } = this.state
    let styles = { height, maxHeight: '6em' }

    // Disable scrolling when comment is empty
    if (comment.length === 0) {
      styles.overflow = 'hidden'
    }

    return (
      <textarea
        style={styles}
        ref={this._saveTextareaRef}
        onChange={this._onChangeComment}
        className={className}
        value={comment}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={this._onKeyDown}
        {...textareaProps}
      />
    )
  }
}

ResizeableTextarea.propTypes = {
  comment: PropTypes.string.isRequired,
  className: PropTypes.string,
  defaultHeight: PropTypes.number,
  onChangeComment: PropTypes.func.isRequired,
  // Optional: if not provided don't call it
  onSubmitComment: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func
}

ResizeableTextarea.defaultProps = {
  defaultHeight: 50
}
