// The concept of a Redux Duck is losely based on
// https://github.com/erikras/ducks-modular-redux

// A loading module that controls whether to show a loading spinner
export function createLoadingDuck (prefix) {
  // Need to return both the action types and the action creators
  const actions = {
    LOADING: `${prefix}/LOADING`,
    loading () { return { type: actions.LOADING } },
    DONE: `${prefix}/DONE`
  }
  // actions.loading = () => { return {type: actions.LOADING} }
  actions.done = () => { return { type: actions.DONE } }

  // Possible states: false, true
  const reducer = (state = false, action) => {
    if (action.type === actions.LOADING) {
      return true
    } else if (action.type === actions.DONE) {
      return false
    } else {
      return state
    }
  }

  return {
    actions,
    reducer
  }
}
