import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { userSelector, planSelector } from 'src/selectors'
import {
  getActivePlans,
  getPlanBySlug,
  PlanTypeLevel,
  PlanSlugs,
  PlanType,
  BillPeriod
} from 'src/services/stripe_checkout_configs'
import PlanInner from './PlanInner/PlanInner'
import styles from './plan-types.scss'

const PlanTypes = () => {
  const user = useSelector(userSelector)
  const { downgrade, specifyPlan } = useSelector(planSelector)
  const [planTypes, setPlanTypes] = useState([])
  const [selectedPlanPeriod, setSelectedPlanPeriod] = useState(BillPeriod.Monthly)
  const planInnerRefs = useRef([])
  const activePlans = getActivePlans()
  const { slug: userSlug, subscription_period: userPeriod } = user.current_subscription.plan
  const userPlanType = getPlanBySlug(userSlug, 'planType')

  const getPlanTypes = () => {
    const planTypes = activePlans.reduce(function (acc, obj) {
      const index = acc.findIndex(function (group) {
        return group[0].planType === obj.planType
      })
      if (index === -1) {
        acc.push([obj])
      } else {
        acc[index].push(obj)
      }
      return acc
    }, [])

    setPlanTypes(planTypes)
  }

  const handleUpgrade = () => {
    let index = 0
    const isActivePlan = activePlans.some(p => p.slug === userSlug)
    if (specifyPlan) {
      index = planTypes.findIndex(group => group[0].level === PlanTypeLevel[specifyPlan])
    } else if (!isActivePlan) {
      if (userPlanType === PlanType.Premium) {
        index = planTypes.findIndex(group => group[0].level === PlanTypeLevel.EventPlanner)
      } else {
        // Old plan or Free plan.
        index = planTypes.findIndex(group => group[0].level === PlanTypeLevel.InvitationMaker)
      }
    } else if (userSlug === PlanSlugs.GroupOrganizerMonthly) {
      index = planTypes.findIndex(group => group[0].level === PlanTypeLevel.GroupOrganizer)
      setSelectedPlanPeriod(BillPeriod.Annually)
    } else {
      const userPlanLevel = getPlanBySlug(userSlug, 'level')
      index = planTypes.findIndex(group => group[0].level > userPlanLevel)
    }

    planInnerRefs.current[index].scrollIntoView({
      behavior: 'smooth'
    })
  }

  const handleDowngrade = () => {
    let index = 0
    const isActivePlan = activePlans.some(p => p.slug === userSlug)
    if (!isActivePlan) {
      if (userPlanType === PlanType.Premium) {
        index = planTypes.findIndex(group => group[0].level === PlanTypeLevel.PartyHost)
      } else {
        // Old plan
        index = planTypes.findIndex(group => group[0].level === PlanTypeLevel.InvitationMaker)
      }
    } else if (userPeriod === BillPeriod.Annually) {
      const userPlanLevel = getPlanBySlug(userSlug, 'level')
      index = planTypes.findIndex(group => group[0].level === userPlanLevel)
    } else {
      const userPlanLevel = getPlanBySlug(userSlug, 'level')
      index = planTypes.findIndex(group => userPlanLevel - group[0].level === 1)
    }

    planInnerRefs.current[index].scrollIntoView({
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    getPlanTypes()
  }, [])

  useEffect(() => {
    if (planTypes.length > 0) {
      if (downgrade) {
        handleDowngrade()
      } else {
        handleUpgrade()
      }
    }
  }, [planTypes])

  return (
    <div className={styles['plan-types-container']}>
      <ul className={styles['list-ul']}>
        {planTypes.map((item, index) => (
          <PlanInner
            ref={el => (planInnerRefs.current[index] = el)}
            key={item[0].slug}
            plan={item}
            selectedPlanPeriod={selectedPlanPeriod}
            onSetSelectedPlanPeriod={setSelectedPlanPeriod}
          />
        ))}
      </ul>

      <div className={styles['footer-text']}>
        See our <a href="https://hobnob.app/terms/">Terms of Use</a> and{' '}
        <a href="https://hobnob.app/privacy/">Privacy Policy</a>
      </div>
    </div>
  )
}

export default PlanTypes
