import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import EventEmpty from 'assets/event-empty.png'
import TimePollEmpty from 'assets/timePoll-empty.png'
import SulEmpty from 'assets/sul-empty.png'
import styles from './empty-panel.scss'
import Button from 'src/components/common/Button'
import { showLogin } from 'src/actions/login'
import { userIdSelector } from 'src/selectors'
import PropTypes from 'prop-types'

const EmptyPanel = ({ type }) => {
  const userId = useSelector(userIdSelector)
  const dispatch = useDispatch()

  const handleLogin = () => {
    const params = {
      loginModalTitle: 'Sign Up/Sign In',
      loginModalSubtitle:
        'To view your events & activity, please verify your account’s phone number'
    }

    dispatch(showLogin(params))
  }

  const getText = () => {
    let icon = ''
    let title = ''
    let content = ''
    let loginContent = ''

    switch (type) {
      case 'event':
        icon = EventEmpty
        title = 'No Events'
        content =
          'You’ll see all your upcoming and recent events here.\n Get started on your next event now!'
        loginContent = 'Log in to view all your events & announcements'
        break
      case 'tp':
        icon = TimePollEmpty
        title = 'No Polls'
        content = 'You’ll see all your time polls here.\n Get started on your next polls now!'
        loginContent = 'Log in to view all your time polls'
        break
      case 'sul':
        icon = SulEmpty
        title = 'No Lists'
        content = 'You’ll see all your sign up lists here.\n Get started on your next lists now!'
        loginContent = 'Log in to view all your sign up lists'
        break
    }

    return { title, content, loginContent, icon }
  }

  const texts = getText()

  if (!userId) {
    return (
      <div className={styles['empty-container']}>
        <img src={texts.icon} width="280" alt="" />
        <div className={styles['content']}>{texts.loginContent}</div>
        <div className={styles['button-wrapper']}>
          <Button onClick={handleLogin}>LOG IN</Button>
        </div>
      </div>
    )
  }

  return (
    <div className={styles['empty-container']}>
      <img src={texts.icon} width="280" alt="" />
      <div className={styles['title']}>{texts.title}</div>
      <div className={styles['content']} style={{ whiteSpace: 'pre-wrap' }}>
        {texts.content}
      </div>
    </div>
  )
}

EmptyPanel.propTypes = {
  type: PropTypes.string
}

export default EmptyPanel
