import { FlyerLayoutAbstract } from './flyer-layout.abstract'
import moment from 'moment/moment'

export default class Flyer_layout_left_aligned_boxed_53LayoutModel extends FlyerLayoutAbstract {
  getFlourishes() {
    let flourishes = this.flourishes

    if (!this.eventData.starts_at && !this.eventData.ends_at) {
      flourishes = flourishes.filter(f => f.name !== 'flyer_crystal_120_boxes')
    }

    return flourishes
  }

  flourishes = [
    {
      name: 'flyer_crystal_120_boxes',
      left: 0,
      top: 0,
      width: 320,
      height: 568
    }
  ]

  textLayouts = {
    event_name_1: {
      top: 50,
      left: 47,
      size: 70,
      width: 220,
      height: 200,
      verticalAlign: 'center'
    },
    location_1: {
      left: 50,
      top: 480,
      size: 14,
      width: 220,
      height: 50
    }
  }

  constructor(layout, eventData) {
    super(layout, eventData)
    this.init()
  }

  datePropsConfigsMapping = {
    date1: 'date_1',
    date3: 'date_3'
  }

  getLocationText1() {
    const { custom_venue_name, venue } = this.eventData

    if (venue) {
      return `${venue.name}, ${venue.street}`
    }
    return custom_venue_name
  }

  getDateTexts() {
    const startDate = this.eventData.starts_at
    const { date1Props, date3Props } = this.dateProps
    let dates = []

    const textArea1 = {
      width: 85,
      top: 270,
      left: 60,
      size: 50,
      color: date1Props.color.replace('0x', '#'),
      font: date1Props.font.replace(/\s+/g, '-'),
      widthAlign: 'center'
    }

    const textArea2 = {
      width: 85,
      top: 340,
      left: 60,
      size: 50,
      color: date1Props.color.replace('0x', '#'),
      font: date1Props.font.replace(/\s+/g, '-'),
      widthAlign: 'center'
    }

    const textArea3 = {
      width: 108,
      top: 413,
      left: 49,
      size: 14,
      color: date3Props.color.replace('0x', '#'),
      font: date3Props.font.replace(/\s+/g, '-'),
      widthAlign: 'center'
    }

    const textArea4 = {
      width: 108,
      top: 432,
      left: 49,
      size: 14,
      color: date3Props.color.replace('0x', '#'),
      font: date3Props.font.replace(/\s+/g, '-'),
      widthAlign: 'center'
    }

    const s_M = moment(startDate).format('MM')
    textArea1.text = s_M
    dates.push(textArea1)

    const s_D = moment(startDate).format('D')
    textArea2.text = s_D
    dates.push(textArea2)

    const s_W = moment(startDate).format('dddd')
    textArea3.text = date3Props.all_caps ? s_W.toUpperCase() : s_W
    dates.push(textArea3)

    const s_h = moment(startDate).format('h')
    const s_m = moment(startDate).format('mm')
    const s_A = moment(startDate).format('A')
    const text4 = `${s_h}:${s_m}${s_A}`
    textArea4.text = date3Props.all_caps ? text4.toUpperCase() : text4
    dates.push(textArea4)

    return { type: 'date', dates }
  }
}
