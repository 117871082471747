import React from 'react'
import PropTypes from 'prop-types'

import styles from './message-inner.scss'

import ReplyMessage from './ReplyMessage/ReplyMessage'
import UserMessage from './UserMessage/UserMessage'

const MessageInner = props => {
  const { message, user, inReply } = props
  const isReplyVisible = message.replies.filter(reply => !reply.destroyed_at).length > 0

  return (
    <div className={styles['message-inner']} id={message.id}>
      <UserMessage user={user} message={message} inReply={inReply} />

      {isReplyVisible && <ReplyMessage inReply={inReply} message={message} />}
    </div>
  )
}

MessageInner.propTypes = {
  message: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  inReply: PropTypes.bool
}

export default MessageInner
