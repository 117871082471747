import PropTypes from 'prop-types'
import React from 'react'
import Linkify from 'react-linkify'

import { buildRedirectUrl } from 'services/redirect_helpers.js'
import { affiliatizeLinks } from 'services/impact_stat.js'

// Inspired by: https://gist.github.com/kares/740162
const simpleFormatRE1 = /\r\n?/g
const simpleFormatRE2 = /\n\n+/g
const simpleFormatRE3 = /\n/g

export default class HobnobSimpleFormat extends React.Component {
  componentDidMount () {
    affiliatizeLinks()
  }

  componentDidUpdate () {
    affiliatizeLinks()
  }

  _renderLines (paragraph) {
    return paragraph.split(simpleFormatRE3).map((line, i) => {
      return (
        <span key={i}>
          <Linkify linkTransform={buildRedirectUrl} properties={{ href: Linkify.MATCH, target: '_blank', rel: 'nofollow' }}>
            {line}
          </Linkify>
          <br />
        </span>
      )
    })
  }

  _renderParagraphs (text) {
    const { paragraphClass } = this.props
    const paragraphs = text.split(simpleFormatRE2)
    return paragraphs.map((paragraph, i) => {
      return (
        <p key={i} className={paragraphClass}>
          {this._renderLines(paragraph)}
        </p>
      )
    })
  }

  render () {
    const { text } = this.props
    const normalizedText = text ? text.replace(simpleFormatRE1, '\n') : ''

    return (
      <div>
        {this._renderParagraphs(normalizedText)}
      </div>
    )
  }
}

HobnobSimpleFormat.propTypes = {
  text: PropTypes.string.isRequired,
  paragraphClass: PropTypes.string
}
