import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { changeEventsModal, resetInvitationsPusher } from 'src/actions/event'
import { EMAIL_CONTACT_METHOD } from 'src/services/contact_methods'
import {
  eventSelector,
  invitationsPusherSelector,
  activeInvitationsSelector,
  isHostSelector,
  isHostOrCohostSelector,
  eventTicketCountsSelector,
  ticketTypesSelector,
  usersSelector,
  userSelector,
  hostSelector
} from 'src/selectors'
import {
  deleteInvitation,
  updateInvitation,
  invitations_activate,
  updateInvitationDelivery
} from 'src/actions/invitations'

import { setInvitationsCount } from 'src/actions'
import { arrayDeduplicationWithObject } from 'services/utils'
import debounce from 'lodash/debounce'
import moment from 'moment'
import PopConfirm from 'components/common/PopConfirm/PopConfirm'
import { getPlanBySlug, PlanType } from 'src/services/stripe_checkout_configs'
import { changeVisible, setContent, setNeededCredits } from 'src/actions/globalLayer'
import { GLOBAL_LAYER_CONTENT } from 'src/constants'
import { changePlanModalVisible } from 'src/actions/plan'
import { Modal } from 'antd'
import { fullName } from 'src/services/user_helpers'

const WithGuestsListV2 = WrappedComponent => {
  return () => {
    const dispatch = useDispatch()
    const event = useSelector(eventSelector)
    const invitationsPusher = useSelector(invitationsPusherSelector)
    const activeInvitations = useSelector(activeInvitationsSelector)
    const eventTicketCounts = useSelector(eventTicketCountsSelector)
    const ticketTypes = useSelector(ticketTypesSelector)
    const users = useSelector(usersSelector)
    const isHost = useSelector(isHostSelector)
    const isHostOrCohost = useSelector(isHostOrCohostSelector)
    const user = useSelector(userSelector) || {}
    const host = useSelector(hostSelector)

    const tags = ['all', 'yes', 'no', 'not replied', 'unsent']
    const [selectedGuest, setSelectedGuest] = useState(null)
    const [searchValue, setSearchValue] = useState('')
    const [searchGuestsList, setSearchGuestsList] = useState([])
    const [selectedTag, setSelectedTag] = useState('all')
    const [guestsList, setGuestsList] = useState([])
    const [isLimitEmailModalVisible, setIsLimitEmailModalVisible] = useState(false)
    const currentSub = user?.current_subscription
    const planType = getPlanBySlug(currentSub?.plan?.slug, 'planType')
    const isFreePlan = planType === PlanType.Free

    useEffect(() => {
      if (invitationsPusher.length > 0) {
        initialGuestsList()
      }
    }, [invitationsPusher.length])

    useEffect(() => {
      handleSearchGuests()
    }, [guestsList, selectedTag])

    useEffect(() => {
      const invitationsCount = activeInvitations.filter(
        item => item.delivery_state !== 'pending'
      ).length

      dispatch(setInvitationsCount(invitationsCount))
      initialGuestsList()
    }, [activeInvitations])

    useEffect(() => {
      initialGuestsList(true)
    }, [])

    const initialGuestsList = (isCheckUnsent = false) => {
      dispatch(resetInvitationsPusher())

      let currentSelectedTag = selectedTag
      let sentGuests = activeInvitations.sort((a, b) => {
        if (moment(a.created_at).isAfter(moment(b.created_at))) return -1
      })

      if (eventTicketCounts.ticketing_enabled_at) {
        sentGuests = sentGuests.map(invitation => {
          const eventTicket = eventTicketCounts.invitations.find(item => item.id === invitation.id)
          if (eventTicket?.rsvp_state === 'accepted') {
            for (let ticket of eventTicket.ticket_counts) {
              const label = ticketTypes.find(
                ticketType => ticketType.id === ticket.ticket_type_id
              ).label
              if (label === 'adult') {
                invitation.guest_count = ticket.ticket_count
              }
              if (label === 'child') {
                invitation.child_count = ticket.ticket_count
              }
            }
          } else {
            invitation.guest_count = 0
            invitation.child_count = 0
          }

          return invitation
        })
      }

      const unSentInvitations = sentGuests.filter(item => item.delivery_state === 'pending')

      if (isCheckUnsent && unSentInvitations.length > 0) {
        currentSelectedTag = 'unsent'
      } else if (unSentInvitations.length === 0) {
        currentSelectedTag = currentSelectedTag === 'unsent' ? 'all' : currentSelectedTag
      }

      setGuestsList(sentGuests)
      setSelectedTag(currentSelectedTag)
    }

    const searchGuestsDebounce = debounce(value => {
      const activeGuestsList = getGuestsList()

      const searchGuestsWithName = activeGuestsList.filter(({ first_name, last_name }) => {
        const fullName = first_name + ' ' + last_name
        return fullName?.toLowerCase()?.includes(value.toLowerCase())
      })

      const searchGuestsWithContactMethodDisplayName = activeGuestsList.filter(
        ({ contact_method_display_name }) => {
          return contact_method_display_name?.toLowerCase()?.includes(value.toLowerCase())
        }
      )

      const filterGuests = arrayDeduplicationWithObject(
        searchGuestsWithName.concat(searchGuestsWithContactMethodDisplayName),
        'id'
      )

      setSearchGuestsList(filterGuests)
    }, 300)

    const handleSearchGuests = (value = searchValue) => {
      setSearchValue(value)

      if (!value) {
        setSearchGuestsList([])
        return
      }

      searchGuestsDebounce(value)
    }

    const getGuestsList = (tag = selectedTag) => {
      switch (tag) {
        case 'all':
          return guestsList
        case 'yes':
          return guestsList.filter(invitation => invitation.rsvp_state === 'accepted')
        case 'no':
          return guestsList.filter(invitation => invitation.rsvp_state === 'declined')
        case 'unsent':
          return guestsList.filter(invitation => invitation.delivery_state === 'pending')
        case 'created':
          return guestsList.filter(i => i.delivery_state === 'pending' && i.created_at)
        case 'not replied':
          return guestsList.filter(
            i => i.rsvp_state === 'undecided' && i.delivery_state !== 'pending'
          )
        case 'failed':
          return guestsList.filter(
            i => i.delivery_state === 'delivery_failed' || i.delivery_state === 'delivery_aborted'
          )
      }
    }

    const handleSelectTag = tag => {
      setSelectedTag(tag)
      setSearchValue('')
      setSearchGuestsList([])
    }

    const handleRemoveGuest = () => {
      dispatch(
        deleteInvitation({
          eventId: event.id,
          invitationId: selectedGuest.id,
          onSuccessCallback: () => {
            setSelectedGuest(null)
            initialGuestsList()
          }
        })
      )
    }

    const handleEditGuest = params => {
      let { first_name, last_name, cohost } = params
      const guestUser = users[selectedGuest.guest]

      if (typeof first_name === 'undefined') {
        first_name = selectedGuest.first_name
      }

      if (typeof last_name === 'undefined') {
        last_name = selectedGuest.last_name
      }

      if (first_name === '' && last_name === '' && guestUser) {
        first_name = guestUser.first_name
        last_name = guestUser.last_name
      }

      const invitation = {
        ...selectedGuest,
        first_name,
        last_name,
        cohost: typeof cohost === 'boolean' ? cohost : selectedGuest.cohost
      }

      dispatch(
        updateInvitation(
          invitation,
          selectedGuest.id,
          event.id,
          {},
          {
            onSuccessCallback: () => {
              setSelectedGuest(null)
              initialGuestsList()
            }
          }
        )
      )
    }

    const handleSendInvitations = () => {
      window.mixpanel.track('INVITES Sent', { event_id: event.id, user_id: user.id })

      const emailInvitations = guestsList.filter(
        item => item.contact_method.type === EMAIL_CONTACT_METHOD
      )

      if (emailInvitations.length > 100) {
        if (planType === PlanType.InvitationMaker || planType === PlanType.Free) {
          setIsLimitEmailModalVisible(true)
          return
        }
      }

      dispatch(
        invitations_activate({
          eventId: event.id,
          onSuccessCallback: data => {
            const { estimated_cost, available_credit, sending_ids } = data

            if (sending_ids.length > 0) {
              dispatch(updateInvitationDelivery(sending_ids))
            }

            if (available_credit < estimated_cost) {
              if (!isHost) {
                Modal.info({
                  title: 'SEND INVITES',
                  centered: true,
                  content: `Looks like your Host ${fullName(
                    host
                  )} is out of invites. Let them know they need to upgrade their plan in order to send more invites.`
                })
                return
              }
              dispatch(setNeededCredits(Number(estimated_cost) - Number(available_credit)))
              dispatch(setContent(GLOBAL_LAYER_CONTENT.unsentInvites))
              dispatch(changeVisible(true))
            }
          }
        })
      )
    }

    const handleSendHelpEmail = () => {
      if (isFreePlan) {
        dispatch(changePlanModalVisible(true))
      } else {
        const aTag = document.createElement('a')
        aTag.href = process.env.BRANCH_INSTALL_BANNER_LINK
        aTag.click()
      }

      setIsLimitEmailModalVisible(false)
    }

    return (
      <>
        <WrappedComponent
          tags={tags}
          selectedTag={selectedTag}
          selectedGuest={selectedGuest}
          searchValue={searchValue}
          searchGuestsList={searchGuestsList}
          userId={user.id}
          event={event}
          users={users}
          onChangeEventsModal={tab => dispatch(changeEventsModal(tab))}
          isHost={isHost}
          isHostOrCohost={isHostOrCohost}
          onInputChange={handleSearchGuests}
          onGetGuestsList={getGuestsList}
          onSelectTag={handleSelectTag}
          onRemoveGuest={handleRemoveGuest}
          onEditGuest={handleEditGuest}
          onSelectGuest={setSelectedGuest}
          onSendInvitations={handleSendInvitations}
        />

        <PopConfirm
          visible={isLimitEmailModalVisible}
          onClose={() => setIsLimitEmailModalVisible(false)}
          onConfirm={handleSendHelpEmail}
          confirmText={isFreePlan ? 'UPGRADE' : 'OPEN HOBNOB APP'}
          cancelText="No, thanks"
        >
          <div style={{ padding: '0 30px' }}>
            <h3>Upgrade</h3>
            <p style={{ fontSize: '15px', fontWeight: 400 }}>
              It seems that you are trying to send over 100 email invites.&nbsp;
              {isFreePlan
                ? 'Upgrade to continue sending the invites.'
                : 'Go to our iOS app to upgrade to continue sending the invites.'}
            </p>
          </div>
        </PopConfirm>
      </>
    )
  }
}

export default WithGuestsListV2
