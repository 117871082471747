import { fetchApi } from './api'
import queryString from 'query-string'

export const FETCH_ACTIVITY_FEED_LIST = 'FETCH_ACTIVITY_FEED_LIST'
export const FETCH_ACTIVITY_FEED_LIST_SUCCESS = 'FETCH_ACTIVITY_FEED_LIST_SUCCESS'
export const FETCH_ACTIVITY_FEED_LIST_ERROR = 'FETCH_ACTIVITY_FEED_LIST_ERROR'

export const fetchActivityFeed = params => {
  let requestStr = ''
  let requestParams = {}

  if (params?.read_state && params?.read_state !== 'all') {
    requestParams.read_state = params.read_state
  }
  if (params?.before) {
    requestParams.before = params.before
  }

  if (params?.after) {
    requestParams.after = params.after
  }

  if (Object.keys(requestParams).length > 0) {
    requestStr = '?' + queryString.stringify(requestParams)
  }

  return fetchApi({
    requestType: FETCH_ACTIVITY_FEED_LIST,
    successType: FETCH_ACTIVITY_FEED_LIST_SUCCESS,
    errorType: FETCH_ACTIVITY_FEED_LIST_ERROR,
    route: `/activities/groups${requestStr}`,
    payload: {
      loadMore: !!params?.loadMore,
      read_state: params?.read_state || 'all',
      fromWS: !!params?.fromWS
    },
    method: 'GET'
  })
}

export const MARK_READ_ACTIVITY = 'MARK_READ_ACTIVITY'
export const MARK_READ_ACTIVITY_SUCCESS = 'MARK_READ_ACTIVITY_SUCCESS'
export const MARK_READ_ACTIVITY_ERROR = 'MARK_READ_ACTIVITY_ERROR'

export const markReadActivity = (ids, onSuccessCallback) => {
  return fetchApi({
    requestType: MARK_READ_ACTIVITY,
    successType: MARK_READ_ACTIVITY_SUCCESS,
    errorType: MARK_READ_ACTIVITY_ERROR,
    route: '/activities/mark_read',
    options: {
      method: 'POST',
      body: JSON.stringify({ activity_ids: ids })
    },
    onSuccessCallback
  })
}

export const MARK_ALL_READ = 'MARK_ALL_READ'
export const MARK_ALL_READ_SUCCESS = 'MARK_ALL_READ_SUCCESS'
export const MARK_ALL_READ_ERROR = 'MARK_ALL_READ_ERROR'

export const markAllRead = onSuccessCallback => {
  return fetchApi({
    requestType: MARK_ALL_READ,
    successType: MARK_ALL_READ_SUCCESS,
    errorType: MARK_ALL_READ_ERROR,
    route: '/activities/mark_all_read',
    options: {
      method: 'POST'
    },
    onSuccessCallback
  })
}

export const FETCH_MESSAGE = 'FETCH_MESSAGE'
export const FETCH_MESSAGE_SUCCESS = 'FETCH_MESSAGE_SUCCESS'
export const FETCH_MESSAGE_ERROR = 'FETCH_MESSAGE_ERROR'

export function fetchMessage(ids, onSuccessCallback) {
  return fetchApi({
    requestType: FETCH_MESSAGE,
    successType: FETCH_MESSAGE_SUCCESS,
    errorType: FETCH_MESSAGE_ERROR,
    route: `/graphql2`,
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `{
          activities(ids: ${JSON.stringify(ids)}) {
            item_entities {
              entity_id
              entity_type
              label
              entity {
                ... on Message {
                  id
                  destroyed_at
                  message_parts {
                    body
                    id
                    link_previews {
                      description
                      destroyed_at
                      favicon_url
                      id
                      image_height
                      image_url
                      image_width
                      inserted_at
                      service_link
                      service_name
                      sort_order
                      title
                      title_link
                      updated_at
                    }
                    metadata
                    sort_order
                    type
                  }
                  reactions {
                    slug
                    user_ids
                  }
                  reply_count
                  room_id
                  thread_id
                  timestamp
                  type
                  updated_at
                  user_id
                }
              }
            }
          }
        }`
      })
    },
    onSuccessCallback
  })
}

export const SET_ACTIVITY_FEED_LIST = 'SET_ACTIVITY_FEED_LIST'
export const setActivityFeedList = activityFeedList => {
  return {
    type: SET_ACTIVITY_FEED_LIST,
    activityFeedList
  }
}

export const SET_AF_OLDEST_TIMESTAMP = 'SET_AF_OLDEST_TIMESTAMP'
export const setOldestTimestamp = oldestTimestamp => {
  return {
    type: SET_AF_OLDEST_TIMESTAMP,
    oldestTimestamp
  }
}

export const SET_AF_NEWEST_TIMESTAMP = 'SET_AF_NEWEST_TIMESTAMP'
export const setNewestTimestamp = newestTimestamp => {
  return {
    type: SET_AF_NEWEST_TIMESTAMP,
    newestTimestamp
  }
}

export const SET_AF_SELECTED_TAG = 'SET_AF_SELECTED_TAG'
export const setSelectedTag = selectedTag => {
  return {
    type: SET_AF_SELECTED_TAG,
    selectedTag
  }
}

export const SET_AF_LOADING = 'SET_AF_LOADING'
export const setActivityFeedLoading = isLoading => {
  return {
    type: SET_AF_LOADING,
    isLoading
  }
}
