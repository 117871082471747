import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import TextAreaElement from 'components/common/ResizeableTextarea'
import HobnobModal from 'components/common/HobnobModalV2/HobnobModal'
import Button from 'components/common/Button'
import { Select, message } from 'antd'
import { sendUpdateEvent } from 'src/actions/event'
import { eventSelector } from 'src/selectors'

import styles from './send-update.scss'

const sendList = [
  {
    label: 'Attending & undecided',
    value: 1
  },
  {
    label: 'Attending',
    value: 2
  },
  {
    label: 'undecided',
    value: 3
  },
  {
    label: 'Not Attending',
    value: 4
  },
  {
    label: 'All',
    value: 5
  }
]

const sendType = {
  1: ['accepted', 'undecided'],
  2: ['accepted'],
  3: ['undecided'],
  4: ['declined'],
  5: ['accepted', 'undecided', 'declined']
}

const SendUpdate = ({ isSendUpdateVisible, onSetIsSendUpdateVisible }) => {
  const dispatch = useDispatch()

  const [sendCode, setSendCode] = useState(1)
  const [updateComment, setUpdateComment] = useState('')

  const event = useSelector(eventSelector)

  useEffect(() => {
    if (!isSendUpdateVisible) {
      setSendCode(1)
      setUpdateComment('')
    }
  }, [isSendUpdateVisible])

  const handleSendUpdate = () => {
    dispatch(
      sendUpdateEvent({
        eventId: event.id,
        content: updateComment,
        recipient_states: sendType[sendCode],
        onSuccessCallback: () => {
          onSetIsSendUpdateVisible(false)
        },
        onErrorCallback: () => {
          message.error('Failed to send update')
        }
      })
    )
  }

  return (
    <HobnobModal
      visible={isSendUpdateVisible}
      onCancel={() => onSetIsSendUpdateVisible(false)}
      title="Send Update"
      maskClosable={false}
    >
      <div className={styles['send-update-container']}>
        <div className={styles['send-type-wrapper']}>
          <label>To:</label>

          <Select
            className={styles['select-inner']}
            bordered={false}
            value={sendCode}
            onChange={setSendCode}
            options={sendList}
          />
        </div>

        <div className={styles['input-wrapper']}>
          <TextAreaElement
            className={styles['textArea-inner']}
            placeholder="Send your guests a custom reminder or let them know about changes to the event.&#13;&#10;Guests without the app will be notified via text or email. Guests with the app will receive a push notification."
            onChangeComment={setUpdateComment}
            comment={updateComment}
          />
        </div>

        <div className={styles['button-wrapper']}>
          <Button className={styles['button-inner']} onClick={handleSendUpdate}>
            Send
          </Button>
        </div>
      </div>
    </HobnobModal>
  )
}

SendUpdate.propTypes = {
  isSendUpdateVisible: PropTypes.bool,
  onSetIsSendUpdateVisible: PropTypes.func
}

export default SendUpdate
