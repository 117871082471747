import React from 'react'
import CreatePollContainer from './containers/CreatePoll/CreatePollContainer'

import 'assets/styles/date-range/styles.css'
import 'assets/styles/date-range/default.css'

export default function CTPEntry() {
  return (
    <CreatePollContainer />
  )
}
