/* eslint-disable prefer-promise-reject-errors */
import { fork, put, select } from 'redux-saga/effects'

import {
  showHud
} from 'src/actions/frontend'

import {
  REQUEST_SHARE_LOCATION_IN_CHAT,
  addTempChatComment,
  geolocationPermissionChanged,
  shareLocationDenied,
  shareLocationRetrieved,
  updateTempChatCommentStatus
} from 'src/actions/chat'

import {
  eventIdSelector,
  userIdSelector
} from 'src/selectors'

import {
  chatSendLocation,
  createTempLocationComment
} from 'src/services/chat_helpers'
import { CommentStatus } from 'src/services/comment_helpers'

import { ourTakeEvery } from '../utils'

export default function * chatLocationRootSaga () {
  yield fork(watchRequestShareLocationInChat)
}

function * watchRequestShareLocationInChat () {
  yield * ourTakeEvery(REQUEST_SHARE_LOCATION_IN_CHAT, shareLocationInChatWorker)
}

function * shareLocationInChatWorker () {
  try {
    const geoLocationPermissionState = yield getGeolocationPermissionWorker()
    yield put(geolocationPermissionChanged(geoLocationPermissionState))
  } catch (e) {
  }

  const userId = yield select(userIdSelector)
  const tempComment = createTempLocationComment(userId)
  yield put(addTempChatComment(tempComment))
  try {
    const geo = yield getHTML5Location()
    yield put(shareLocationRetrieved())
    const eventId = yield select(eventIdSelector)
    yield put(chatSendLocation(tempComment, geo.coords.latitude, geo.coords.longitude, eventId))
  } catch (errorMessage) {
    yield put(shareLocationDenied())
    yield put(showHud('error', errorMessage))
    yield put(updateTempChatCommentStatus(tempComment.id, CommentStatus.ERROR))
  }
}

// Needs to be exported because it is run as a dynamic saga so that it has a reference to dispatch
export function * watchGeolocationPermissionSaga (dispatch) {
  // NOTE: The permissions api is not fully implemented across all browsers
  // (Safari and IE don't implement it) so we can only use it to enhance our site.
  // Don't depend on it
  if (navigator.permissions) {
    navigator.permissions.query({ name: 'geolocation' }).then(function (permissionStatus) {
      console.log('geolocation permission state is ', permissionStatus.state)

      permissionStatus.onchange = function () {
        console.log('geolocation permission state has changed to ', this.state)
        dispatch(geolocationPermissionChanged(this.state))
      }
    })
  }
}

function * getGeolocationPermissionWorker () {
  return yield new Promise((resolve, reject) => {
    // NOTE: The permissions api is not fully implemented across all browsers
    // (Safari and IE don't implement it) so we can only use it to enhance our site.
    // Don't depend on it
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then(function (permissionStatus) {
        console.log('geolocation permissionStatus:', permissionStatus)
        resolve(permissionStatus.state)
      })
    } else {
      reject()
    }
  })
}

function * getHTML5Location () {
  return yield new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (geo) {
        resolve(geo)
      }, function (error) {
        console.error('Unable to get geolocation', error)
        switch (error.code) {
          case error.PERMISSION_DENIED:
            if (error.message === 'User denied geolocation prompt') {
              reject('Request for location was denied.')
            } else {
              reject(error.message)
            }
            break
          case error.POSITION_UNAVAILABLE:
            reject('Location information is unavailable.')
            break
          case error.TIMEOUT:
            reject('The request to get location timed out.')
            break
          case error.UNKNOWN_ERROR:
            reject('An unknown error occurred while retrieving the location.')
            break
        }
      })
    } else {
      console.error('Location information not supported by the browser')
      reject('Location information not supported by the browser')
    }
  })
}
