import PropTypes from 'prop-types'
import React, { Component } from 'react'

import HobnobModal from 'src/components/common/HobnobModal'

import styles from './refund-policy-modal.scss'

export default class RefundPolicyModal extends Component {
  render () {
    const { show, onClose, refundPolicy } = this.props
    return (
      <HobnobModal
        title={'Refund Policy'}
        show={show}
        onClose={onClose}

      >
        <div className={styles['refund-policy-description']}>
          {refundPolicy}
        </div>
      </HobnobModal>
    )
  }
}

RefundPolicyModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  refundPolicy: PropTypes.string
}
