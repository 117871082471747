import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import GuestCountControls from './GuestCountControls'
import TicketAttending from '../details/TicketAttending'
import Button from 'components/common/Button'

import {
  rsvpModalHasChangedRsvpStatusSelector,
  rsvpModalShowBringGuestSelector,
  rsvpModalShowGuestCountControlsSelector,
  rsvpModalShowInvitationReplyFormSelector
} from 'src/selectors'

import styles from './rsvp-accepted.scss'

class RSVPAccepted extends Component {
  constructor (props) {
    super(props)
    const { guest_count, child_count } = this.props.invitation

    this.state = {
      guestCount: guest_count + 1,
      childCount: child_count
    }
  }

  componentWillMount () {
    const { guest_count, child_count } = this.props.invitation

    this.setState({
      guestCount: guest_count + 1,
      childCount: child_count
    })
  }

  _onChangeGuestCount = (count) => {
    this.setState({ guestCount: count })
  };
  _onChangeChildCount = (count) => {
    this.setState({ childCount: count })
  };

  _noGuestWith = () => {
    const { onChangeGuestCounts } = this.props
    let { childCount } = this.state
    this.setState({
      guestCount: 1
    })
    onChangeGuestCounts(0, childCount)
  };

  _updateGuestCounts = () => {
    const { onChangeGuestCounts } = this.props
    let { guestCount, childCount } = this.state
    guestCount = guestCount - 1
    onChangeGuestCounts(guestCount, childCount)
  };

  _rsvpText () {
    const { guestName, guestCountEnabled, changeRsvp } = this.props
    if (changeRsvp) {
      return (
        <span>
          My RSVP:<strong> Yes, I'm Going</strong>
        </span>
      )
    }
    if (guestCountEnabled) {
      return (
        <span>
          Including yourself,
          <br /> How many <strong>total guests</strong> are going?
        </span>
      )
    }

    return `Glad you can make it ${guestName}!`
  }

  _renderGuestCountControls () {
    const { guestCountEnabled, childCountEnabled, rsvpTextClass, changeRsvp } =
      this.props
    const { guestCount, childCount } = this.state

    return (
      <div className={styles['rsvp-guest-count-wrapper']}>
        <div className={rsvpTextClass}>
          <div className={styles['rsvp-text']}>{this._rsvpText()}</div>
        </div>
        {changeRsvp && guestCountEnabled ? (
          <div className={styles['rsvp-remove-text']}>
            Do you want to add or remove guests?
          </div>
        ) : null}
        {guestCountEnabled ? (
          <GuestCountControls
            guestCountEnabled={guestCountEnabled}
            childCountEnabled={childCountEnabled}
            guestCount={guestCount}
            childCount={childCount}
            changeRsvp={changeRsvp}
            onChangeGuestCount={this._onChangeGuestCount}
            onChangeChildCount={this._onChangeChildCount}
            onConfirm={this._updateGuestCounts}
          />
        ) : null}
      </div>
    )
  }
  _renderFormText () {
    const { changeRsvp, guestCountEnabled, hasChangedRsvpStatus } = this.props

    if (changeRsvp) {
      if (!guestCountEnabled && !hasChangedRsvpStatus) {
        return (
          <span>
            My RSVP:<strong> Yes, I'm Going</strong>
          </span>
        )
      }
      return <div>Great! Your RSVP is updated</div>
    } else {
      return <div>Great! You RSVP'd yes</div>
    }
  }

  _renderInvitationReplyForm () {
    const {
      invitationReplyForm,
      invitation,
      changeRsvp,
      guestCountEnabled,
      hasChangedRsvpStatus,
      showGuestCountControlsAction,
      showInvitationReplyFormModal
    } = this.props

    return (
      <div className={styles['invitation-reply-form-wrapper']}>
        {this._renderFormText()}
        {invitation && guestCountEnabled ? (
          <TicketAttending
            invitation={invitation}
            className={styles['total-attending']}
            onClick={showGuestCountControlsAction}
          />
        ) : null}
        {!changeRsvp || hasChangedRsvpStatus || showInvitationReplyFormModal
          ? invitationReplyForm
          : null}
      </div>
    )
  }

  _renderLabel () {
    const {
      changeRsvp,
      showGuestCountControlsModal,
      showInvitationReplyFormModal
    } = this.props

    if (!changeRsvp) {
      return `Yes, I'm Going`
    } else {
      if (showGuestCountControlsModal) {
        return 'Change RSVP'
      } else if (showInvitationReplyFormModal) {
        return 'RSVP Changed'
      }
    }
  }

  _renderBringGuest () {
    const { showGuestCountControlsAction } = this.props
    return (
      <div>
        <div className={styles['bring-guest-text']}>
          Bringing any guests with you?
        </div>
        <div className={styles['bring-guest-btn-wrapper']}>
          <Button
            className={styles['button']}
            filled={false}
            onClick={showGuestCountControlsAction}
          >
            yes
          </Button>
          <Button
            className={styles['button']}
            filled={false}
            onClick={this._noGuestWith}
          >
            no
          </Button>
        </div>
      </div>
    )
  }

  _renderCurrentComponent () {
    const {
      showBringGuestModal,
      showGuestCountControlsModal,
      showInvitationReplyFormModal,
      guestCountEnabled
    } = this.props
    if (guestCountEnabled) {
      if (showBringGuestModal) {
        return this._renderBringGuest()
      } else if (showGuestCountControlsModal) {
        return this._renderGuestCountControls()
      } else if (showInvitationReplyFormModal) {
        return this._renderInvitationReplyForm()
      } else {
        return null
      }
    } else {
      return this._renderInvitationReplyForm()
    }
  }

  render () {
    const {
      changeRsvp,
      onDeclineInvitation,
      hasChangedRsvpStatus,
      showGuestCountControlsModal
    } = this.props
    return (
      <div className={styles['container']}>
        <h3 className={styles['title']}>{this._renderLabel()}</h3>
        {this._renderCurrentComponent()}
        {changeRsvp && !hasChangedRsvpStatus && showGuestCountControlsModal ? (
          <div className={styles['change-rsvp-text']}>
            Change RSVP:{' '}
            <a href='#!' onClick={onDeclineInvitation}>
              No, Can't Go
            </a>
          </div>
        ) : null}
      </div>
    )
  }
}

RSVPAccepted.propTypes = {
  changeRsvp: PropTypes.bool,
  guestName: PropTypes.string,
  guestCountEnabled: PropTypes.bool,
  childCountEnabled: PropTypes.bool,
  invitation: PropTypes.object,
  onChangeGuestCounts: PropTypes.func,
  invitationReplyForm: PropTypes.element,
  rsvpTextClass: PropTypes.string,
  onDeclineInvitation: PropTypes.func,
  hasChangedRsvpStatus: PropTypes.bool,
  showBringGuestModal: PropTypes.bool,
  showGuestCountControlsModal: PropTypes.bool,
  showInvitationReplyFormModal: PropTypes.bool,
  showGuestCountControlsAction: PropTypes.func
}

function mapStateToProps (state) {
  return {
    hasChangedRsvpStatus: rsvpModalHasChangedRsvpStatusSelector(state),
    showBringGuestModal: rsvpModalShowBringGuestSelector(state),
    showGuestCountControlsModal: rsvpModalShowGuestCountControlsSelector(state),
    showInvitationReplyFormModal:
      rsvpModalShowInvitationReplyFormSelector(state)
  }
}

export default connect(mapStateToProps)(RSVPAccepted)
