import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { updateLoginForm, submitConfirmation } from 'src/actions/login'
import { PHONE_NUMBER_CONTACT_METHOD } from 'src/services/contact_methods'
import { loginFormSelector, contactMethodSelector } from 'src/selectors'
import cn from 'classnames'

import ConfirmationCodeMessage from './ConfirmationCodeMessage/ConfirmationCodeMessage'

import styles from './confirmation-code-input.scss'

const ConfirmationCodeInput = props => {
  const { onResendCode, onlyInput = false, onNextStep } = props
  const dispatch = useDispatch()
  const loginForm = useSelector(loginFormSelector)
  const contactMethod = useSelector(contactMethodSelector)
  const [values, setValues] = useState(['', '', '', '', '', ''])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isClickedResendCode, setIsClickedResendCode] = useState(false)

  const inputRefs = Array.from({ length: 6 }, () => useRef(null))

  const handleClickResendCode = () => {
    onResendCode()
    setIsClickedResendCode(true)
    setTimeout(() => {
      setIsClickedResendCode(false)
    }, 2000)
  }

  useEffect(() => {
    inputRefs[0].current.focus()
  }, [])

  useEffect(() => {
    inputRefs[currentIndex].current.focus()
  }, [values])

  useEffect(() => {
    if (loginForm.confirmationCode.length === 6) {
      const { firstName, lastName, phoneNumber } = loginForm
      dispatch(
        submitConfirmation({
          firstName,
          lastName,
          username: phoneNumber,
          confirmationCode: loginForm.confirmationCode,
          contactMethodType: PHONE_NUMBER_CONTACT_METHOD,
          onSuccessCallback: () => {
            onNextStep?.()
          }
        })
      )
    }
  }, [loginForm.confirmationCode])

  useEffect(() => {
    if (!loginForm.confirmationCodeValid) {
      setCurrentIndex(0)
      setValues(['', '', '', '', '', ''])
      dispatch(updateLoginForm({ confirmationCode: '' }))
    }
  }, [loginForm.confirmationCodeValid])

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && values[index] === '' && index > 0) {
      values.splice(index - 1, 1, '')
      setCurrentIndex(index - 1)
      dispatch(
        updateLoginForm({
          confirmationCode: values.join(''),
          confirmationCodeValid: 'unknown'
        })
      )
      setValues([...values])
    }
  }

  const handleChange = (index, e) => {
    const value = e.target.value

    if (value.length === 1) {
      const isNumber = /^\d*$/.test(value)
      if (!isNumber) {
        return
      }

      values.splice(index, 1, value)

      if (index < inputRefs.length - 1) {
        setCurrentIndex(index + 1)
      }

      setValues([...values])

      dispatch(
        updateLoginForm({
          confirmationCode: values.join(''),
          confirmationCodeValid: 'unknown'
        })
      )
    }

    if (value.length > 1) {
      const texts = Array.from({ length: 6 }).fill('')
      texts.splice(0, value.length, ...value)
      const isAllNumber = texts.every(text => /^\d*$/.test(text))
      if (isAllNumber) {
        dispatch(
          updateLoginForm({
            confirmationCode: texts.join(''),
            confirmationCodeValid: 'unknown'
          })
        )
        for (const i in texts) {
          if (!texts[i]) {
            setCurrentIndex(Number(i))
            break
          }
        }

        setValues(texts)
      }
    }
  }

  return (
    <div
      className={cn(
        styles['confirmation-code-input-container'],
        loginForm.loginInRSVP && styles['confirmation-code-input-container-plus']
      )}
    >
      {!onlyInput && (
        <ConfirmationCodeMessage contactMethod={contactMethod} loginForm={loginForm} />
      )}

      <div className={styles['content-wrapper']}>
        <div
          className={cn(
            styles['wrong-code'],
            !loginForm.confirmationCodeValid && styles['wrong-code-display']
          )}
        >
          Wrong Code!
        </div>
        <div className={styles['input-wrapper']}>
          {[...Array(6)].map((_, index) => (
            <input
              key={index}
              ref={inputRefs[index]}
              type="tel"
              value={values[index]}
              readOnly={currentIndex !== index}
              onKeyDown={e => handleKeyDown(index, e)}
              onChange={e => handleChange(index, e)}
              className={cn(
                styles['input-inner'],
                currentIndex === index && styles['input-inner-focus'],
                !loginForm.confirmationCodeValid && styles['input-inner-invalid']
              )}
            />
          ))}
        </div>

        <span
          className={cn(
            styles['action-link'],
            loginForm.loginInRSVP && styles['action-link-plus'],
            isClickedResendCode && styles['action-link-disabled']
          )}
          onClick={handleClickResendCode}
        >
          Resend Code
        </span>
      </div>
    </div>
  )
}

ConfirmationCodeInput.propTypes = {
  onlyInput: PropTypes.bool,
  onResendCode: PropTypes.func.isRequired,
  onNextStep: PropTypes.func
}

export default ConfirmationCodeInput
