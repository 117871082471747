import React from 'react'
import PropTypes from 'prop-types'

import styles from './common-frame.scss'

const CommonFrame = props => {
  const { actionLayer, descLayer } = props
  return (
    <main className={styles['common-frame-container']}>
      <section className={styles['action-container']}>{actionLayer}</section>
      <section className={styles['desc-container']}>{descLayer}</section>
    </main>
  )
}

CommonFrame.propTypes = {
  actionLayer: PropTypes.element,
  descLayer: PropTypes.element
}

export default CommonFrame
