import React, { Component } from 'react'
import styles from './add-guests.scss'
import HeaderBar from 'src/components/HeaderBar/HeaderBar'
import addGuestsContext from '../addGuestsContext'
import InputGuestIcon from 'assets/icons/input-guest.svg'
import RightIcon from 'assets/icons/right-icon.png'
import PropTypes from 'prop-types'
import CommunityGroup from '../../../Components/CommunityGroup/CommunityGroup'
import Button from 'src/components/common/Button'
import CommunitySkeleton from './CommunitySkeleton/CommunitySkeleton'
import SearchPanel from './SearchPanel/SearchPanel'
import cn from 'classnames'
import UploadFile from 'assets/icons/upload-file.png'
import CSVFileUpload from '../../../Components/CSVFileUpload/CSVFileUpload'
import PreviewAddGuestsList from '../../../Components/PreviewAddGuestsList/PreviewAddGuestsList'
import { POPUP_BOX_TYPES_CONSTANTS } from 'src/constants'
import SvgIcon from 'src/components/common/SvgIcon'

class AddGuests extends Component {
  static contextType = addGuestsContext
  static displayName = 'AddGuests'

  state = {
    isDisableScroll: false,
    isOpenCSVUploadModal: false,
    previewAddGuestsList: []
  }

  handleSaveGuests = () => {
    this.props.onSaveGuests()
    history.back()
  }

  handleGetGuestsFromCSV = guests => {
    if (guests.length > 0) {
      history.pushState(null, null, location.href)
    }
    this.setState({ previewAddGuestsList: guests })
  }

  componentDidMount() {
    this.context.onChangePopupBox(POPUP_BOX_TYPES_CONSTANTS.addGuests)
    this.context.onChangePopupBoxCallBack(() => {
      if (this.state.previewAddGuestsList.length > 0) {
        this.setState({ previewAddGuestsList: [] })
      } else {
        this.context.onChangeComponent('AddGuestsList')
      }
    })
  }

  render() {
    const { previewAddGuestsList } = this.state
    const { loadingCompleted, addedMembers } = this.props
    const visiblePreviewAddGuestsList = previewAddGuestsList.length > 0
    const event = this.context.event

    if (!loadingCompleted) {
      return <CommunitySkeleton />
    }

    return (
      <div className={styles['add-guests-container']}>
        <HeaderBar
          width={40}
          closeIcon
          onBack={() => this.context.onChangeComponent('AddGuestsList')}
          right={
            <img
              src={UploadFile}
              width="22"
              onClick={() => this.setState({ isOpenCSVUploadModal: true })}
            />
          }
        >
          <div className={styles['header-title']}>
            <span className={styles['title']}>add guests</span>
            <span className={styles['subtitle']}>{event.name}</span>
          </div>
        </HeaderBar>

        <div
          className={cn(styles['content-container'], {
            [styles['content-container-disableScroll']]: this.state.isDisableScroll
          })}
        >
          <SearchPanel
            onDisableScroll={value => this.setState({ isDisableScroll: value })}
            onGroupChecked={this.props.onGroupChecked}
            onMemberChecked={this.props.onMemberChecked}
            communityList={this.props.communityList}
            eventList={this.props.eventList}
          />

          <div className={styles['group-wrapper']}>
            <div className={styles['group-title']}>input</div>
            <div
              className={styles['list-item']}
              onClick={() => this.context.onChangeComponent('InputGuests')}
            >
              <SvgIcon icon={InputGuestIcon} width={40} height={40} />
              <div className={styles['content']}>
                <span>Input Numbers or Emails</span>
                <span className={styles['text']}>Add people not in your contacts</span>
              </div>
              <img src={RightIcon} width="20" />
            </div>
          </div>

          <CommunityGroup {...this.props} />
        </div>

        <div className={styles['button-wrapper']}>
          <Button
            disabled={addedMembers.length === 0}
            className={styles['button-inner']}
            onClick={this.handleSaveGuests}
          >
            ADD
          </Button>
        </div>

        <CSVFileUpload
          visible={this.state.isOpenCSVUploadModal}
          onGetGuestsFromCSV={this.handleGetGuestsFromCSV}
          onClose={() => this.setState({ isOpenCSVUploadModal: false })}
        />

        {visiblePreviewAddGuestsList && (
          <PreviewAddGuestsList
            title="Import CSV File"
            previewAddGuestsList={previewAddGuestsList}
            fixed
          />
        )}
      </div>
    )
  }
}

AddGuests.propTypes = {
  communityList: PropTypes.array,
  eventList: PropTypes.array,
  loadingCompleted: PropTypes.bool,
  onSaveGuests: PropTypes.func,
  addedMembers: PropTypes.array,
  onMemberChecked: PropTypes.func,
  onGroupChecked: PropTypes.func
}

export default AddGuests
