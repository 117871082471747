import { put, select } from 'redux-saga/effects'
import { routerActions } from 'react-router-redux'

import { showRsvpModal } from 'src/actions/frontend'
import { requestAcceptInvitation, requestDeclineInvitation } from 'src/actions/invitations'
import { invitationSelector, routeSelector } from 'src/selectors'

function shouldUpdateRSVP ({ rsvpState, rsvpAction }) {
  return (rsvpAction === 'accept' && rsvpState !== 'accepted') ||
    (rsvpAction === 'decline' && rsvpState !== 'declined')
}

export function * handleRSVPRouteSaga () {
  const invitation = yield select(invitationSelector)

  if (invitation) {
    const route = yield select(routeSelector)
    const rsvpState = invitation.rsvp_state
    const [_ignored, rsvpAction] = route.substr(1).split('/')

    if (shouldUpdateRSVP({ rsvpState, rsvpAction })) {
      switch (rsvpAction) {
        case 'accept':
          yield put(requestAcceptInvitation())
          break
        case 'decline':
          yield put(requestDeclineInvitation())
          break
      }
    }

    if (rsvpAction === 'accept' || rsvpAction === 'decline') {
      yield put(routerActions.push('/rsvp'))
      yield put(showRsvpModal())
    }
  }
}
