import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import Button from 'components/common/Button'
import LoadingComponent from 'components/common/LoadingComponent/LoadingComponent'
import { contactMethodSelector, loginFormSelector } from 'src/selectors'
import {
  EMAIL_CONTACT_METHOD,
  PHONE_NUMBER_CONTACT_METHOD,
  VALID_COUNTRY_CODES
} from 'src/services/contact_methods'
import ContactMethodInputInner from './ContactMethodInputInner/ContactMethodInputInner'
import FullNameInputInner from '../FullNameInput/FullNameInputInner/FullNameInputInner'
import cn from 'classnames'

import styles from './contact-method-input.scss'

const ContactMethodInput = props => {
  const { submitForm } = props
  const contactMethod = useSelector(contactMethodSelector)
  const loginForm = useSelector(loginFormSelector)

  const [isErrorVisible, setIsErrorVisible] = useState(false)

  const contactMethodType = contactMethod ? contactMethod.type : PHONE_NUMBER_CONTACT_METHOD

  const isFullNameValid = () => {
    const hasFirstName = loginForm.firstName && loginForm.firstName !== ''
    const hasLastName = loginForm.lastName && loginForm.lastName !== ''
    return hasFirstName || hasLastName
  }

  const isContactMethodValid = () => {
    const phoneNumberValid = loginForm.phoneNumber.length === 11
    const countryCodeValid = VALID_COUNTRY_CODES.find(c => c.code === loginForm.countryCode)

    switch (contactMethodType) {
      case EMAIL_CONTACT_METHOD:
        // FIXME: we should do at least some validation
        return true
      default:
        const isValid = phoneNumberValid && Boolean(countryCodeValid)
        if (loginForm.loginInRSVP) {
          return isValid && isFullNameValid()
        } else {
          return isValid
        }
    }
  }

  const onSubmit = e => {
    e.preventDefault()

    if (isContactMethodValid()) {
      submitForm()
    } else {
      setIsErrorVisible(true)
    }
  }

  return (
    <div className={styles['contact-method-input-container']}>
      <div className={styles['input-wrapper']}>
        {loginForm.loginInRSVP && (
          <div className={styles['full-name']}>
            <FullNameInputInner inline />
          </div>
        )}
        <ContactMethodInputInner
          contactMethodType={contactMethodType}
          valid={!isErrorVisible || isContactMethodValid()}
          disabled={loginForm.loginInRSVP}
        />
        <span className={styles['form-tip']}>
          Don't worry, we'll never spam or share your number.
        </span>
      </div>
      <Button
        disabled={!isContactMethodValid()}
        className={cn(styles['button-inner'], loginForm.loginInRSVP && styles['container-plus'])}
        onClick={onSubmit}
      >
        Next
      </Button>

      <div className={styles['confirmation-text']}>
        By using Hobnob, you agree to our&nbsp;
        <a target="_blank" rel="noopener noreferrer" href="https://hobnob.app/privacy/">
          Privacy Policy
        </a>
        <span> and </span>
        <a href="https://hobnob.app/terms/" target="_blank" rel="noopener noreferrer">
          Terms
        </a>
      </div>

      {loginForm.confirmationCodeLoading && (
        <div className={styles['loading-container']}>
          <LoadingComponent loading type="partial" light />
        </div>
      )}
    </div>
  )
}

ContactMethodInput.propTypes = {
  submitForm: PropTypes.func.isRequired
}

export default ContactMethodInput
