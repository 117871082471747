import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DashboardContainer from 'containers/DashboardContainer'
import Panel from '../components/Panel'
import poll_successfully from 'assets/icons/poll_successfully.png'

import styles from './successfully-container.scss'

class UpdatedPanel extends Component {
  render () {
    const { handleBackClick } = this.props

    const SUBSCRIBED_MESSAGES = {
      title: 'Selections Updated!',
      subtitle: `You've Successfully updated your entries for "${this.props.poll.name}".`
    }

    return (
      <DashboardContainer>
        <Panel
          notification={SUBSCRIBED_MESSAGES}
          handleBackClick={handleBackClick}
          showCreateButton
        >
          <img src={poll_successfully} alt='successfully' className={styles['iconProps']} />
        </Panel>
      </DashboardContainer>
    )
  }
}

UpdatedPanel.propTypes = {
  handleBackClick: PropTypes.func,
  poll: PropTypes.object
}

export default UpdatedPanel
