const iOSRegex = /iP(hone|od|ad)/i
const androidPhoneRegex = /(?=.*\bAndroid\b)(?=.*\bMobile\b)/i

export function platform () {
  if (isAndroid()) {
    return 'Android'
  } else if (isIOS()) {
    return 'iPhone'
  } else {
    return 'mobile'
  }
}

export function isMobile () {
  return isIOS() || isAndroid()
}

export function isIOS () {
  return (typeof navigator !== 'undefined') && iOSRegex.test(navigator.userAgent)
}

export function isAndroid () {
  return (typeof navigator !== 'undefined') && androidPhoneRegex.test(navigator.userAgent)
}

export function supportsExifImageOrientation () {
  // I'm completely unable to find a way to check that the image-orientation
  // attribute is supported so instead we check for Firefox which is currently
  // (2016-05-05) the only browser that supports the attribute
  // https://developer.mozilla.org/en-US/docs/Web/CSS/image-orientation#Browser_compatibility
  return isFirefox() || isIOS()
}

export function isFirefox () {
  // P.S. This is a poor regex (but it doesn't currently seem worthwile to bring
  // in an entire browser-detection library)
  return navigator.userAgent.match(/Firefox\/[0-9]/)
}

// https://stackoverflow.com/a/31732310/175830
export function isSafari () {
  return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1
}

// https://github.com/arasatasaygin/is.js/blob/56294950656ba58f940248510cdf3e45af357a1e/is.js#L628
export function isChrome () {
  var userAgent = ((navigator && navigator.userAgent) || '').toLowerCase()
  var vendor = ((navigator && navigator.vendor) || '').toLowerCase()

  var match = /google inc/.test(vendor) ? userAgent.match(/(?:chrome|crios)\/(\d+)/) : null
  return match !== null
}

export function iOSVersion () {
  if (isIOS()) {
    const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/)
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)]
  }
}
