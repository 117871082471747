import exifJs from 'exif-js'

import { supportsExifImageOrientation } from 'services/browser_detection'

export default function loadImageInfo (file) {
  return new Promise((resolve) => {
    doLoadImageInfo(file, (imageInfo) => {
      imageInfo.isPortrait = imageInfo.width < imageInfo.height
      resolve(imageInfo)
    })
  })
}

function doLoadImageInfo (file, callback) {
  let reader = new FileReader()
  let info = {}
  reader.onload = (fileEvent) => {
    console.log(fileEvent)
    info.url = fileEvent.target.result

    exifJs.getData(file, function () {
      let tags = exifJs.getAllTags(this)

      if (tags.DateTimeOriginal) {
        const takenAtDate = parseDate(tags.DateTimeOriginal)
        info.taken_at = takenAtDate.toISOString()
      }
      // iOS photos have the same width/height for portrait and landscape,
      // but they change the orientation property.
      info.orientation = tags.Orientation
      if (info.orientation === 6 && supportsExifImageOrientation()) {
        // Flipped!
        info.width = tags.PixelYDimension
        info.height = tags.PixelXDimension
      } else {
        info.width = tags.PixelXDimension
        info.height = tags.PixelYDimension
      }

      if (!info.width || !info.height) {
        let img = new Image()
        img.onload = function () {
          info.width = img.width
          info.height = img.height
          callback(info)
        }

        // Load the image so that the onload callback is fired
        img.src = reader.result
      } else {
        callback(info)
      }
    })
  }

  reader.readAsDataURL(file)
}

// Expected input: "2016:02:25 16:19:25"
function parseDate (dateStr) {
  const match = dateStr.match(/(\d\d\d\d):(\d\d):(\d\d) (\d\d):(\d\d):(\d\d)/)
  if (match) {
    const year = match[1]
    const month = match[2]
    const day = match[3]
    const hour = match[4]
    const minute = match[5]
    const second = match[6]
    return new Date(year, month, day, hour, minute, second)
  } else {
    return null
  }
}
