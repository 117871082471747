import { parseEventUrl } from 'services/url_helpers'
import { DESTINATION_TYPES_CONSTANTS } from '../constants'
import { fetchPollBranchLinks } from 'src/actions/timepoll'
import { put } from 'redux-saga/effects'

export default function * branchLinkRootSaga() {
  const path = window.location.pathname
  const { destinationType, eventToken } = parseEventUrl(path)

  if (destinationType === DESTINATION_TYPES_CONSTANTS.timepoll) {
    yield fetchPollBranchLinksSaga(eventToken)
  } else if (destinationType === DESTINATION_TYPES_CONSTANTS.signUp) {
    yield fetchPollBranchLinksSaga(eventToken)
  }
}

function * fetchPollBranchLinksSaga(timePollToken) {
  yield put(fetchPollBranchLinks(timePollToken))
}
