import { EMAIL_CONTACT_METHOD, PHONE_NUMBER_CONTACT_METHOD } from './contact_methods'
import { isValidPhoneNumber } from 'libphonenumber-js'
import SHA256 from 'js-sha256'

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0
}

export function pluralize(count, singularString) {
  if (count === 1) {
    return singularString
  } else {
    switch (singularString) {
      case 'child':
        return 'children'
      case 'Child':
        return 'Children'
      default:
        return `${singularString}s`
    }
  }
}

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function sortByCreatedAtComparator(ascending = true) {
  return (a, b) => {
    if (ascending) {
      return new Date(b.created_at) - new Date(a.created_at)
    } else {
      return new Date(a.created_at) - new Date(b.created_at)
    }
  }
}

export function generateTempId() {
  return `local-${Date.now()}-${Math.floor(Math.random() * 1000)}`
}

export function normalizrResult(response, entitiesKey, resultKey) {
  return response.entities[entitiesKey][response.result[resultKey]]
}

export function flatten(list) {
  return list.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), [])
}

// Reference: http://stackoverflow.com/a/24053987/175830
export function isSameDay(dateA, dateB) {
  return dateA.toDateString() === dateB.toDateString()
}

export function truncate(original, length) {
  if (original.length >= length) {
    let string = original.substr(0, length - 3)
    // Don't end with a space before the ellipsis
    if (string.slice(-1) === ' ') {
      string = string.slice(0, -1)
    }
    string += '…'
    return string
  }

  return original
}

export function isHobnobAndroidClient() {
  return !!window.android
}

export function isHobnobIosClient() {
  return document.body.classList.contains('ios-client')
}

export function androidObject() {
  return window.android
}

const currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

// Render a number like 905 as "$9.05"
export function displayCurrency(priceInCents, stripZeros = false) {
  const formattedNumber = currencyFormat.format(priceInCents / 100)
  if (stripZeros && formattedNumber.endsWith('.00')) {
    return formattedNumber.replace('.00', '')
  } else {
    return formattedNumber
  }
}

export function sumCount(ticket, type = 'count') {
  let total = 0
  for (let i = 0; i < ticket.length; i++) {
    total += ticket[i][type]
  }
  return total
}

export function deepCopyArray(array) {
  return JSON.parse(JSON.stringify(array))
}

export function deepCopyObject(data) {
  if (typeof data !== 'object' || !data) {
    return data
  }

  const copy = new data.constructor()
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      copy[key] = deepCopyObject(data[key])
    }
  }
  return copy
}

export function deepCopy(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  if (obj instanceof Date) {
    return new Date(obj.getTime())
  }

  if (Array.isArray(obj)) {
    const newArray = []
    for (let i = 0; i < obj.length; i++) {
      newArray[i] = deepCopy(obj[i])
    }
    return newArray
  }

  if (obj instanceof Object) {
    const newObject = {}
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObject[key] = deepCopy(obj[key])
      }
    }
    return newObject
  }
}

export function arrayDeduplicationWithObject(array, key) {
  const res = new Map()
  return array.filter(item => !res.has(item[key]) && res.set(item[key], 1))
}

export function changeTicketCounts(ticketCounts, ticketTypeId, count) {
  let tempTicketCounts = deepCopyArray(ticketCounts)

  const editedTicketType = tempTicketCounts.find(tt => tt.id === ticketTypeId)

  // Count the number of tickets that have been archived but have been summed
  // into the count displayed to the user so that we can subtract them when
  // updating the count
  const archivedTicketCount = tempTicketCounts.reduce((acc, ticketType) => {
    if (ticketType.id === ticketTypeId) {
      return acc
    } else if (ticketType.label === editedTicketType.label) {
      return acc + ticketType.count
    } else {
      return acc
    }
  }, 0)

  tempTicketCounts = tempTicketCounts.map(item => {
    if (item.id === ticketTypeId) {
      item.count = count - archivedTicketCount
    }
    return item
  })

  return tempTicketCounts
}

export function defaultPaymentMethod(paymentMethodsList) {
  let paymentMethod = paymentMethodsList.find(item => item.is_default)
  if (paymentMethod) {
    return paymentMethod
  }

  return paymentMethodsList.sort((method1, method2) => {
    const date1 = Date.parse(method1.inserted_at)
    const date2 = Date.parse(method2.inserted_at)

    if (date1 < date2) {
      return -1
    } else if (date1 > date2) {
      return 1
    } else {
      return 0
    }
  })[0]
}

export function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000)

  let interval = seconds / 31536000
  if (interval > 1) {
    const year = Math.floor(interval)
    return `${year} ${year === 1 ? 'year' : 'years'} ago`
  }

  interval = seconds / 2592000
  if (interval > 1) {
    const month = Math.floor(interval)
    return `${month} ${month === 1 ? 'month' : 'months'} ago`
  }

  interval = seconds / 86400
  if (interval > 1) {
    const days = Math.floor(interval)
    return `${days} ${days === 1 ? 'day' : 'days'} ago`
  }

  interval = seconds / 3600
  if (interval > 1) {
    const hours = Math.floor(interval)
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`
  }

  interval = seconds / 60
  if (interval > 1) {
    const minutes = Math.floor(interval)
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`
  }

  return Math.floor(seconds) + ' seconds ago'
}

export function capitalized(str) {
  return str.replace(/(?:^|\s)\w/g, function (c) {
    return c.toUpperCase()
  })
}

export function isValidEmail(value) {
  const isEmail = new RegExp(
    '^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd0-9]{2,6}$'
  ).test(value)

  return isEmail
}

export function isValidPhoneOrEmail(value) {
  if (!value) {
    return false
  }

  if (isValidEmail(value)) {
    return EMAIL_CONTACT_METHOD
  }

  try {
    const phoneReg = isValidPhoneNumber(value, 'US')

    if (phoneReg) {
      return PHONE_NUMBER_CONTACT_METHOD
    }
  } catch (error) {}

  return false
}

export function isDevMod() {
  const mode = window.localStorage.getItem('devMode')
  return !!(mode && mode === 'true')
}

export function getRandomInt(num, min, max) {
  var allNum = []
  for (var i = min; i <= max; i++) {
    allNum.push(i)
  }

  if (num >= allNum.length) {
    return allNum
  }

  var result = []
  while (result.length < num) {
    var index = Math.floor(Math.random() * allNum.length)
    result.push(allNum.splice(index, 1)[0])
  }

  return result
}

export function isEmptyString(str) {
  return !str || str.trim().length === 0
}

export function stringConvertHash(str) {
  const sha256Str = SHA256(str)
  const buffer = Buffer.from(sha256Str, 'hex')
  const encodedString = buffer.toString('base64')

  return encodedString
}

export function base64ToFile(base64Data, contentType = 'image/png', fileName = 'file.png') {
  const byteCharacters = atob(base64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })

  return new File([blob], fileName, { type: contentType })
}

let heic2any = null

export function heicToImage(file) {
  if (!heic2any) {
    heic2any = require('heic2any')
  }

  return new Promise(resolve => {
    heic2any({
      blob: file,
      toType: 'image/jpeg',
      quality: 0.5
    })
      .then(result => {
        resolve(result)
      })
      .catch(error => {
        console.error('Error converting HEIC to JPEG:', error)
      })
  })
}
