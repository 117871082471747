import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { chatSelector } from 'src/selectors'
import { fetchEventById } from 'src/actions/event'
import { saveInvitedEvents } from 'src/actions/eventChat'

import styles from './invitation-reply-message.scss'

const InvitationReplyMessage = ({ invitationReplyContent }) => {
  const dispatch = useDispatch()
  const [event, setEvent] = useState({})
  const {
    body,
    metadata: { event_id, invitation_id }
  } = invitationReplyContent

  const { invitedEvents } = useSelector(chatSelector)

  useEffect(() => {
    let isMounted = true
    if (invitedEvents[event_id]) {
      setEvent(invitedEvents[event_id])
    } else {
      dispatch(
        fetchEventById({
          ids: [event_id],
          onSuccessCallback: data => {
            if (isMounted) {
              const event = data.data.events[0]
              setEvent(event)
              dispatch(saveInvitedEvents(event))
            }
          }
        })
      )
    }

    return () => {
      isMounted = false
    }
  }, [])

  const { rsvp_state, user } =
    event.invitations?.find(invitation => invitation.id === invitation_id) || {}

  return (
    <div className={styles['invitation-reply-message']}>
      <div className={styles['event-info']}>
        <img src={event.flyer?.thumbnail_url_2x} width={50} alt="" />
        <div className={styles['rsvp-info']}>
          <div className={styles['name']}>{event.name}</div>
          <div className={styles['rsvp']}>
            {user?.first_name} RSVP'd&nbsp;
            {rsvp_state === 'accepted' ? 'YES' : 'NO'}
          </div>
        </div>
      </div>

      <div className={styles['text']}>{body}</div>
    </div>
  )
}

InvitationReplyMessage.propTypes = {
  invitationReplyContent: PropTypes.object.isRequired
}

export default InvitationReplyMessage
