/* eslint-disable react/prop-types */
import Modal from 'react-modal'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './hobnob-modal.scss'

const HobnobModal = props => {
  const { isOpen, title, contentClassName, modalClassName, children, onClose } = props

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose?.()}
      className={classNames(styles['modal'], modalClassName)}
      overlayClassName={styles['overlay']}
      ariaHideApp={false}
    >
      <div className={classNames(styles['content'], contentClassName)}>
        {title ? <h2 className={styles['title']}>{title}</h2> : null}
        {children}
      </div>
    </Modal>
  )
}

HobnobModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  contentClassName: PropTypes.string
}

export default HobnobModal
