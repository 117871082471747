import moment from 'moment'

export const findSelectedDate = (date, selectedDates) => {
  const selectionsKeys = Object.keys(selectedDates)
  const selectedKey = selectionsKeys.find(key => {
    const startDate = selectedDates[key].startDate
    const endDate = selectedDates[key].endDate

    return moment(date).isBetween(startDate, endDate, 'day', '[]')
  })

  return selectedDates[selectedKey]
}

export const reformatSelectedDates = selectedDates => {
  const reformattedDates = {}
  Object.keys(selectedDates).forEach((key, index) => {
    const { startDate, endDate, color, id } = selectedDates[key]
    reformattedDates[`selection${index + 1}`] = {
      startDate,
      endDate,
      key: `selection${index + 1}`,
      color: color,
      id
    }
  })

  return reformattedDates
}

export const isDateAlreadySelected = (date, selectedDates) => {
  const selectionsKeys = Object.keys(selectedDates)
  const isBetween = selectionsKeys.some(key => {
    const startDate = selectedDates[key].startDate
    const endDate = selectedDates[key].endDate
    return moment(date).isBetween(startDate, endDate, 'day', '[]')
  })

  return isBetween
}

export const isIncludeDate = (dateRange, selectedDates) => {
  const selectionsKeys = Object.keys(selectedDates)
  const isInclude = selectionsKeys.some(key => {
    if (dateRange.key === key) {
      return false
    }
    const startDate = selectedDates[key].startDate
    const endDate = selectedDates[key].endDate
    const isIncludeStartDate = moment(startDate).isBetween(
      dateRange.startDate,
      dateRange.endDate,
      'day',
      '[]'
    )
    const isIncludeEndDate = moment(endDate).isBetween(
      dateRange.startDate,
      dateRange.endDate,
      'day',
      '[]'
    )
    return isIncludeStartDate || isIncludeEndDate
  })

  return isInclude
}

export const conversionTime = time => {
  if (time.type === 'AM' && time.hour === '12' && time.minute === '00') {
    return {
      type: 'PM',
      hour: '11',
      minute: '59'
    }
  } else if (time.type === 'PM' && time.hour === '11' && time.minute === '59') {
    return {
      type: 'AM',
      hour: '12',
      minute: '00'
    }
  }

  return null
}

export const get24HourTime = time => {
  const { hour, minute, type } = time
  return moment(`${hour}:${minute} ${type}`, 'hh:mm A').format('HH:mm')
}

export const compareTime = (time1, time2) => {
  const date1 = moment(`${time1.hour}:${time1.minute} ${time1.type}`, 'hh:mm A')
  const date2 = moment(`${time2.hour}:${time2.minute} ${time2.type}`, 'hh:mm A')

  return moment(date1).isBefore(date2, 'minute')
    ? { startTime: time1, endTime: time2 }
    : { startTime: time2, endTime: time1 }
}

const formatTimePollDataOfTime = (date, timeList) => {
  const times = timeList.filter(time => time.dateId === date.id)

  if (times.length > 0) {
    return times.map(time => {
      const startDay = moment(date.startDate).format('YYYY/MM/DD')
      const endDay = moment(date.endDate).format('YYYY/MM/DD')
      return {
        starts_at: moment(
          `${startDay} ${time.start.hour}:${time.start.minute} ${time.start.type}`
        ).toDate(),
        ends_at: moment(`${endDay} ${time.end.hour}:${time.end.minute} ${time.end.type}`).toDate()
      }
    })
  }

  return {
    starts_at: date.startDate,
    ends_at: date.endDate
  }
}

export const formatTimePollData = dateRange => {
  const { dates, timeList } = dateRange
  let timePollList = []
  for (const dateKey of Object.keys(dates)) {
    const itemDate = dates[dateKey]
    if (timeList.length > 0) {
      timePollList = timePollList.concat(formatTimePollDataOfTime(itemDate, timeList))
    } else {
      timePollList.push({
        starts_at: itemDate.startDate,
        ends_at: itemDate.endDate
      })
    }
  }
  return timePollList
}
