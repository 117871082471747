export const PurchaseWorkflowNode = {
  Init: 'init',
  PurchaseOnMobileApp: 'purchase-on-mobile',
  PurchasePlanOnMobileApp: 'purchase-plan-on-mobile',
  PurchasePlan: 'purchase-plan',
  UpgradePlan: 'upgrade-plan',
  PurchaseCredits: 'purchase-credits',
  PendingMembershipPaymentResult: 'pending-membership-payment-result',
  PendingCreditsPaymentResult: 'pending-credits-payment-result',
  MembershipCheckoutSuccess: 'membership-checkout-success',
  CreditsCheckoutSuccess: 'credits-checkout-success',
  SendInviteManually: 'send-invite-manually',
  NoMembershipFromPurchasePlan: 'no-membership-from-purchase-plan',
  NoMembershipFromUpgradePlan: 'no-membership-from-upgrade-plan',
  CoHostNotice: 'co-host-notice',
  TestSubs: 'test-subs'
}

export const STRIPE_CHECKOUT_SET_DEFAULT_WORKFLOW_NODE = 'STRIPE_CHECKOUT_SET_DEFAULT_WORKFLOW_NODE'

export const STRIPE_CHECKOUT_SHOW_MODAL = 'STRIPE_CHECKOUT_SHOW_MODAL'
export const STRIPE_CHECKOUT_HIDE_MODAL = 'STRIPE_CHECKOUT_HIDE_MODAL'

export const STRIPE_CHECKOUT_SET_REQUIRED_CREDITS = 'STRIPE_CHECKOUT_SET_REQUIRED_CREDITS'

export const STRIPE_CHECKOUT_SET_OPENED_TAB_REFERENCE = 'STRIPE_CHECKOUT_SET_OPENED_TAB_REFERENCE'

export const STRIPE_CHECKOUT_SET_TIMEOUT_HANDLE = 'STRIPE_CHECKOUT_SET_TIMEOUT_HANDLE'

export const STRIPE_CHECKOUT_SET_PENDING_RESULT_NODE = 'STRIPE_CHECKOUT_SET_PENDING_RESULT_NODE'

export const STRIPE_CHECKOUT_CLEAR_PENDING_RESULT_NODE = 'STRIPE_CHECKOUT_CLEAR_PENDING_RESULT_NODE'

export const STRIPE_CHECKOUT_CLEAR_TIMEOUT_HANDLE = 'STRIPE_CHECKOUT_CLEAR_TIMEOUT_HANDLE'

export const StripeCheckoutBroadcastChannelKey = 'stripe-checkout-broadcast-channel'

export const setDefaultWorkflowNode = workflowNode => {
  return { type: STRIPE_CHECKOUT_SET_DEFAULT_WORKFLOW_NODE, value: workflowNode }
}

export const setCheckoutModalOpen = isOpen => {
  if (isOpen) {
    return { type: STRIPE_CHECKOUT_SHOW_MODAL }
  } else {
    return { type: STRIPE_CHECKOUT_HIDE_MODAL }
  }
}

export const setRequiredCredits = credits => {
  return { type: STRIPE_CHECKOUT_SET_REQUIRED_CREDITS, value: credits }
}

export const setOpenedTabReference = tabRef => {
  return { type: STRIPE_CHECKOUT_SET_OPENED_TAB_REFERENCE, value: tabRef }
}

export const setStripeCheckoutTimeoutHandle = handle => {
  return { type: STRIPE_CHECKOUT_SET_TIMEOUT_HANDLE, value: handle }
}

export const clearStripeCheckoutTimeoutHandle = () => {
  return { type: STRIPE_CHECKOUT_CLEAR_TIMEOUT_HANDLE }
}

export const setCheckoutPendingResultNode = node => {
  return { type: STRIPE_CHECKOUT_SET_PENDING_RESULT_NODE, value: node }
}

export const clearCheckoutPendingResultNode = () => {
  return { type: STRIPE_CHECKOUT_CLEAR_PENDING_RESULT_NODE }
}
