import PropTypes from 'prop-types'
import React from 'react'
import ClassNames from 'classnames'

import {
  EMAIL_CONTACT_METHOD,
  PHONE_NUMBER_CONTACT_METHOD,
  VALID_COUNTRY_CODES
} from 'src/services/contact_methods'

import PhoneInputElement from 'components/forms/PhoneInputElement'
import EmailInputElement from 'components/forms/EmailInputElement'

import styles from './contact-method-input-inner.scss'
import HobnobSelect from 'components/common/HobnobSelect'

export default class ContactMethodInputInner extends React.Component {
  _setPhoneNumber = ({ target }) => {
    const value = '1' + target.value.replace(/\D/g, '')
    this.props.updateForm({ phoneNumber: value, formattedPhoneNumber: target.value })
  }

  _setCountryCode = value => {
    this.props.updateForm({ countryCode: value })
  }

  _setEmailAddress = e => {
    this.props.updateForm({ emailAddress: e.target.value })
  }

  _countryCodeOptions() {
    return VALID_COUNTRY_CODES.map(({ code, value }) => {
      return {
        value: code,
        text: `${code}+${value}`
      }
    })
  }

  _renderPhoneNumberFields() {
    const { className, fields, valid, isLogin } = this.props

    return (
      <div className={isLogin ? styles['login'] : styles['phone-number-container']}>
        <div className={styles['select-wrapper']}>
          <HobnobSelect
            className={ClassNames(className, styles['country-code'])}
            value={fields.countryCode}
            onChange={this._setCountryCode}
            options={this._countryCodeOptions()}
            required
          />
        </div>
        <PhoneInputElement
          errorClassName={styles['error']}
          value={fields.formattedPhoneNumber}
          onChange={this._setPhoneNumber}
          className={ClassNames(
            className,
            isLogin ? styles['phone-number-login'] : styles['phone-number']
          )}
          showError={!valid}
        />
      </div>
    )
  }

  _renderEmailFields() {
    const { className, fields, setUseMaskedEmailFalse, valid } = this.props
    const emailAddressClass = valid ? null : styles.error

    return (
      <EmailInputElement
        useMaskedEmail={fields.useMaskedEmail}
        setUseMaskedEmailFalse={setUseMaskedEmailFalse}
        value={fields.emailAddress}
        maskedEmailAddress={fields.maskedEmailAddress}
        onChange={this._setEmailAddress}
        className={ClassNames(emailAddressClass, className)}
      />
    )
  }

  render() {
    const { contactMethodType } = this.props

    switch (contactMethodType) {
      case PHONE_NUMBER_CONTACT_METHOD:
        return this._renderPhoneNumberFields()
      case EMAIL_CONTACT_METHOD:
        return this._renderEmailFields()
      default:
        console.error(
          'Unable to render login form fields. Falling back to phone number',
          contactMethodType
        )
        return this._renderPhoneNumberFields()
    }
  }
}

ContactMethodInputInner.propTypes = {
  className: PropTypes.string,
  contactMethodType: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  setUseMaskedEmailFalse: PropTypes.func,
  valid: PropTypes.bool.isRequired,
  updateForm: PropTypes.func.isRequired,
  isLogin: PropTypes.bool
}
