import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { locationShape } from 'react-router'

import DashboardContainer from 'containers/DashboardContainer'
import Panel from '../components/Panel'
import LoginPoll from '../components/LoginPoll'
import { routerActions } from 'react-router-redux'

import {
  showPhoneFieldSelector,
  showPhoneCodeFieldSelector,
  loginFormSelector
} from 'src/selectors'

class LoginPage extends React.Component {
  handleNextStep = () => {
    const { location } = this.props

    const prevRoute = location.state?.prevRoute

    if (prevRoute) {
      window.location.href = window.location.origin + prevRoute
    } else {
      window.location.href = window.location.origin + '/home'
    }
  }

  _formatPhoneNumber(phoneNumber) {
    const areaCode = phoneNumber.substr(1, 3)
    const prefix = phoneNumber.substr(4, 3)
    const suffix = phoneNumber.substr(7, 4)

    return `(${areaCode}) ${prefix}-${suffix}`
  }
  _getTitle = (showPhoneField, showPhoneCodeField) => {
    const { loginForm } = this.props
    const confirmNumber = {
      title: 'Confirm Number',
      subtitle:
        'A code was sent to ' +
        this._formatPhoneNumber(loginForm.phoneNumber) +
        '.' +
        ' Enter it here to verify your number'
    }
    const enterNumber = {
      title: 'Login/Create an Account',
      subtitle:
        'A Free account on Hobnob unleashes a suite of tools to make coordinating people and events a breeze!',
      notes: 'Time Polls, Events, Sign Up Lists, Flyers... and more!'
    }
    if (showPhoneField) {
      return enterNumber
    }
    if (showPhoneCodeField) {
      return confirmNumber
    }
  }
  render() {
    const { showPhoneField, showPhoneCodeField, handleBackClick, location } = this.props
    const isSecondButton = true
    return (
      <DashboardContainer>
        <Panel
          isSecondButton={isSecondButton}
          notification={this._getTitle(showPhoneField, showPhoneCodeField)}
          handleBackClick={handleBackClick}
          isLogin
          location={location}
        >
          <LoginPoll onNextStep={this.handleNextStep} />
        </Panel>
      </DashboardContainer>
    )
  }
}

LoginPage.propTypes = {
  loginForm: PropTypes.object,
  showPhoneCodeField: PropTypes.bool,
  showPhoneField: PropTypes.bool,
  location: locationShape,
  handleBackClick: PropTypes.func
}

function mapStateToProps(state) {
  const showPhoneCodeField = showPhoneCodeFieldSelector(state)
  const showPhoneField = showPhoneFieldSelector(state)
  const loginForm = loginFormSelector(state)
  return {
    loginForm,
    showPhoneCodeField,
    showPhoneField
  }
}

function mapDispatchToProps(dispatch) {
  return {
    goBack: () => dispatch(routerActions.goBack()),
    goRouter: params => dispatch(routerActions.push(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
