// If jsonString is valid, return it as parsed object
// If jsonString is invalid, return false
// http://stackoverflow.com/a/20392392/175830
function tryParseJSON (jsonString) {
  try {
    var o = JSON.parse(jsonString)

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns 'null', and typeof null === "object",
    // so we must check for that, too.
    if (o && typeof o === 'object' && o !== null) {
      return o
    }
  } catch (e) {
  }

  return false
}

module.exports = {
  tryParseJSON: tryParseJSON
}
