import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DashboardContainer from 'containers/DashboardContainer'
import Panel from '../components/Panel'
import poll_successfully from 'assets/icons/poll_successfully.png'

import styles from './successfully-container.scss'

class SuccessfulPanel extends Component {
  render () {
    const { handleBackClick } = this.props

    const SUBSCRIBED_MESSAGES = {
      title: 'Subscribed Successfully!',
      subtitle: `You've Successfully Subscribed to updates for "${this.props.poll.name}".`
    }

    return (
      <DashboardContainer>
        <Panel
          notification={SUBSCRIBED_MESSAGES}
          handleBackClick={handleBackClick}
          showCreateButton
        >
          <img
            src={poll_successfully}
            alt='successfully'
            className={styles['iconProps']}
          />
        </Panel>
      </DashboardContainer>
    )
  }
}

SuccessfulPanel.propTypes = {
  handleBackClick: PropTypes.func,
  poll: PropTypes.object
}

export default SuccessfulPanel
