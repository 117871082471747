import { createTempComment } from 'services/comment_helpers'
import { createTempLike, createTempMedium } from 'services/media_helpers'
import { Medium, MediumComment, Like, Flag } from '../schemas'

import { showHud } from './frontend'
import { requireLogin } from './login'
import { fetchApi } from './api'
import { tooltipClickCoordinates } from './utils'

export const REQUEST_FETCH_MORE_MEDIA = 'REQUEST_FETCH_MORE_MEDIA'

export const FETCH_MEDIA = 'FETCH_MEDIA'
export const FETCH_MEDIA_SUCCESS = 'FETCH_MEDIA_SUCCESS'
export const FETCH_MEDIA_ERROR = 'FETCH_MEDIA_ERROR'

export const FETCH_MEDIUM_COMMENTS = 'FETCH_MEDIUM_COMMENTS'
export const FETCH_MEDIUM_COMMENTS_SUCCESS = 'FETCH_MEDIUM_COMMENTS_SUCCESS'
export const FETCH_MEDIUM_COMMENTS_ERROR = 'FETCH_MEDIUM_COMMENTS_ERROR'

export const FETCH_LIKERS = 'FETCH_LIKERS'
export const FETCH_LIKERS_SUCCESS = 'FETCH_LIKERS_SUCCESS'
export const FETCH_LIKERS_ERROR = 'FETCH_LIKERS_ERROR'

export const SET_MEDIA = 'SET_MEDIA'

export const SET_OPEN_MEDIUM_ID = 'SET_OPEN_MEDIUM_ID'

export const UPDATE_MEDIUM_COMMENT = 'UPDATE_MEDIUM_COMMENT'

export const SUBMIT_MEDIUM_COMMENT = 'SUBMIT_MEDIUM_COMMENT'
export const SUBMIT_MEDIUM_COMMENT_SUCCESS = 'SUBMIT_MEDIUM_COMMENT_SUCCESS'
export const SUBMIT_MEDIUM_COMMENT_ERROR = 'SUBMIT_MEDIUM_COMMENT_ERROR'

export const CREATE_MEDIUM = 'CREATE_MEDIUM'
export const CREATE_REMOTE_MEDIUM = 'CREATE_REMOTE_MEDIUM'
export const CREATE_REMOTE_MEDIUM_SUCCESS = 'CREATE_REMOTE_MEDIUM_SUCCESS'
export const CREATE_REMOTE_MEDIUM_ERROR = 'CREATE_REMOTE_MEDIUM_ERROR'

export const UPLOAD_MEDIUM = 'UPLOAD_MEDIUM'
export const UPLOAD_MEDIUM_PROGRESS = 'UPLOAD_MEDIUM_PROGRESS'
export const UPLOAD_MEDIUM_SUCCESS = 'UPLOAD_MEDIUM_SUCCESS'
export const UPLOAD_MEDIUM_ERROR = 'UPLOAD_MEDIUM_ERROR'

export const DESTROY_MEDIUM = 'DESTROY_MEDIUM'
export const DESTROY_MEDIUM_SUCCESS = 'DESTROY_MEDIUM_SUCCESS'
export const DESTROY_MEDIUM_ERROR = 'DESTROY_MEDIUM_ERROR'

export const CREATE_MEDIUM_LIKE = 'CREATE_MEDIUM_LIKE'
export const CREATE_MEDIUM_LIKE_SUCCESS = 'CREATE_MEDIUM_LIKE_SUCCESS'
export const CREATE_MEDIUM_LIKE_ERROR = 'CREATE_MEDIUM_LIKE_ERROR'

export const DESTROY_MEDIUM_LIKE = 'DESTROY_MEDIUM_LIKE'
export const DESTROY_MEDIUM_LIKE_SUCCESS = 'DESTROY_MEDIUM_LIKE_SUCCESS'
export const DESTROY_MEDIUM_LIKE_ERROR = 'DESTROY_MEDIUM_LIKE_ERROR'

export const CREATE_MEDIUM_FLAG = 'CREATE_MEDIUM_FLAG'
export const CREATE_MEDIUM_FLAG_SUCCESS = 'CREATE_MEDIUM_FLAG_SUCCESS'
export const CREATE_MEDIUM_FLAG_ERROR = 'CREATE_MEDIUM_FLAG_ERROR'

export const DESTROY_MEDIUM_FLAG = 'DESTROY_MEDIUM_FLAG'
export const DESTROY_MEDIUM_FLAG_SUCCESS = 'DESTROY_MEDIUM_FLAG_SUCCESS'
export const DESTROY_MEDIUM_FLAG_ERROR = 'DESTROY_MEDIUM_FLAG_ERROR'

export const SHOW_LIKERS_MODAL = 'SHOW_LIKERS_MODAL'
export const HIDE_LIKERS_MODAL = 'HIDE_LIKERS_MODAL'

export const SHOW_MEDIUM_MORE_TOOLTIP = 'SHOW_MEDIUM_MORE_TOOLTIP'
export const HIDE_MEDIUM_MORE_TOOLTIP = 'HIDE_MEDIUM_MORE_TOOLTIP'

export const SHOW_MEDIUM_SHARE_TOOLTIP = 'SHOW_MEDIUM_SHARE_TOOLTIP'
export const HIDE_MEDIUM_SHARE_TOOLTIP = 'HIDE_MEDIUM_SHARE_TOOLTIP'

export function requestFetchMoreMedia () {
  return {
    type: REQUEST_FETCH_MORE_MEDIA
  }
}

export function fetchMedia (eventId, page, per_page) {
  return fetchApi({
    requestType: FETCH_MEDIA,
    successType: FETCH_MEDIA_SUCCESS,
    errorType: FETCH_MEDIA_ERROR,
    route: `/events/${eventId}/media`,
    paginationParams: {
      entityKey: 'media',
      fetchAll: false,
      page,
      per_page
    },
    schema: {
      media: [Medium]
    }
  })
}

export function fetchMediumComments (mediumId, page = 1, per_page = 25, fetchAll = true) {
  return fetchApi({
    requestType: FETCH_MEDIUM_COMMENTS,
    successType: FETCH_MEDIUM_COMMENTS_SUCCESS,
    errorType: FETCH_MEDIUM_COMMENTS_ERROR,
    route: `/media/${mediumId}/comments`,
    payload: {
      mediumId
    },
    paginationParams: {
      entityKey: 'comments',
      fetchAll,
      page,
      per_page
    },
    schema: {
      comments: [MediumComment]
    }
  })
}

export function fetchLikers (mediumId, page = 1, per_page = 25) {
  return fetchApi({
    requestType: FETCH_LIKERS,
    successType: FETCH_LIKERS_SUCCESS,
    errorType: FETCH_LIKERS_ERROR,
    route: `/media/${mediumId}/likes`,
    payload: {
      mediumId
    },
    paginationParams: {
      entityKey: 'likes',
      page,
      per_page
    },
    schema: {
      likes: [Like]
    }
  })
}

export function setMedia (media) {
  return {
    type: SET_MEDIA,
    media
  }
}

export function updateMediumComment (mediumId, content) {
  return {
    type: UPDATE_MEDIUM_COMMENT,
    mediumId,
    content
  }
}

export function submitMediumComment (content, mediumId) {
  const nextActionSuccess = (userId) => {
    const tempComment = createTempComment(content, userId, mediumId)
    const fetchAction = fetchApi({
      requestType: SUBMIT_MEDIUM_COMMENT,
      successType: SUBMIT_MEDIUM_COMMENT_SUCCESS,
      errorType: SUBMIT_MEDIUM_COMMENT_ERROR,
      route: `/media/${mediumId}/comments/${tempComment.id}`,
      payload: {
        comment: tempComment
      },
      schema: {
        comment: MediumComment
      },
      options: {
        method: 'PUT',
        body: JSON.stringify({ comment: { content } })
      },
      errorActions: [
        showHud('error', 'Your comment could not be saved. Please retry in a few moments.')
      ]
    })

    return [fetchAction]
  }

  const loginMessage = "To like and comment on photos, please confirm you're on the guest list"
  const subscribeMessage = '{user_first_name}, add yourself to the guest list to comment on this photo'
  const loginModalFirstPageSubtitle = 'You need to login in order to upload a photo'
  const loginModalTitle = 'Sign In'
  const loginModalSubtitle = 'To like and comment on photos, enter your number to confirm you are on the guest list'
  return requireLogin({ loginMessage, subscribeMessage, loginModalTitle, loginModalSubtitle, loginModalFirstPageSubtitle, nextActionSuccess })
}

export function createLike (mediumId) {
  const nextActionSuccess = (userId) => {
    const fetchAction = fetchApi({
      requestType: CREATE_MEDIUM_LIKE,
      successType: CREATE_MEDIUM_LIKE_SUCCESS,
      errorType: CREATE_MEDIUM_LIKE_ERROR,
      payload: {
        like: createTempLike(userId, mediumId)
      },
      route: `/media/${mediumId}/like`,
      schema: {
        like: Like
      },
      options: {
        method: 'POST'
      },
      errorActions: [
        showHud('error', 'There was an error processing your request. Please retry in a few moments.')
      ]
    })

    return [fetchAction]
  }

  const loginMessage = "To like and comment on photos, please confirm you're on the guest list"
  const subscribeMessage = '{user_first_name}, add yourself to the guest list to like this photo'
  const loginModalFirstPageSubtitle = 'You need to login in order to upload a photo'
  const loginModalTitle = 'Sign In'
  const loginModalSubtitle = 'To like and comment on photos, enter your number to confirm you are on the guest list'
  return requireLogin({ loginMessage, subscribeMessage, loginModalTitle, loginModalSubtitle, loginModalFirstPageSubtitle, nextActionSuccess })
}

export function destroyLike (like) {
  return fetchApi({
    requestType: DESTROY_MEDIUM_LIKE,
    successType: DESTROY_MEDIUM_LIKE_SUCCESS,
    errorType: DESTROY_MEDIUM_LIKE_ERROR,
    payload: { like },
    route: `/media/${like.likeable_id}/like`,
    schema: {
      like: Like
    },
    options: {
      method: 'DELETE',
      body: JSON.stringify({ like: { last_updated_at: like.updated_at } })
    },
    errorActions: [
      showHud('error', 'There was an error processing your request. Please retry in a few moments.')
    ]
  })
}

export function createMedium (file) {
  return {
    type: CREATE_MEDIUM,
    file
  }
}

export function createRemoteMedium (imageInfo, userId, file, eventId) {
  const tempMedium = createTempMedium(imageInfo, userId)
  return fetchApi({
    requestType: CREATE_REMOTE_MEDIUM,
    successType: CREATE_REMOTE_MEDIUM_SUCCESS,
    errorType: CREATE_REMOTE_MEDIUM_ERROR,
    payload: { medium: tempMedium, file },
    route: `/events/${eventId}/photos/${tempMedium.id}`,
    params: {
      // TODO: May need to add this back in to get multiple photo upload support working again.
      //   // Add a random key to force iOS webkit to not combine requests
      //   rk: Math.floor(Math.random() * 1000)
      redirect_to_callback_after_upload: false
    },
    schema: {
      photo: Medium
    },
    options: {
      method: 'PUT'
    }
  })
}

export function uploadMedium (medium, file) {
  return {
    type: UPLOAD_MEDIUM,
    medium,
    file
  }
}

export function uploadMediumProgress (id, percentUploaded) {
  return {
    type: UPLOAD_MEDIUM_PROGRESS,
    id,
    percentUploaded
  }
}

export function uploadMediumSuccess (id) {
  return {
    type: UPLOAD_MEDIUM_SUCCESS,
    id
  }
}

export function uploadMediumError (id) {
  return {
    type: UPLOAD_MEDIUM_ERROR,
    id
  }
}

export function createMediumFlag (medium) {
  return fetchApi({
    requestType: CREATE_MEDIUM_FLAG,
    successType: CREATE_MEDIUM_FLAG_SUCCESS,
    errorType: CREATE_MEDIUM_FLAG_ERROR,
    payload: { medium },
    route: `/media/${medium.id}/flag`,
    schema: {
      flag: Flag
    },
    options: {
      method: 'POST'
    }
  })
}

export function destroyMediumFlag (medium, flag) {
  return fetchApi({
    requestType: DESTROY_MEDIUM_FLAG,
    successType: DESTROY_MEDIUM_FLAG_SUCCESS,
    errorType: DESTROY_MEDIUM_FLAG_ERROR,
    payload: { medium, flag },
    route: `/media/${medium.id}/flag`,
    schema: {
      flag: Flag
    },
    options: {
      method: 'DELETE',
      body: JSON.stringify({ flag: { last_updated_at: flag.updated_at } })
    }
  })
}

export function destroyMedium (medium) {
  return fetchApi({
    requestType: DESTROY_MEDIUM,
    successType: DESTROY_MEDIUM_SUCCESS,
    errorType: DESTROY_MEDIUM_ERROR,
    payload: { medium },
    route: `/events/${medium.mediumable_id}/photos/${medium.id}`,
    schema: {
      photo: Medium
    },
    options: {
      method: 'DELETE',
      body: JSON.stringify({ photo: { last_updated_at: medium.updated_at } })
    }
  })
}

export function showLikersModal (mediumId) {
  return {
    type: SHOW_LIKERS_MODAL,
    mediumId
  }
}

export function hideLikersModal () {
  return {
    type: HIDE_LIKERS_MODAL
  }
}

export function showMediumMoreTooltip (e, mediumId) {
  return {
    type: SHOW_MEDIUM_MORE_TOOLTIP,
    mediumId,
    clickCoordinates: tooltipClickCoordinates(e)
  }
}

export function hideMediumMoreModal () {
  return {
    type: HIDE_MEDIUM_MORE_TOOLTIP
  }
}

export function showMediumShareTooltip (e, mediumId) {
  return {
    type: SHOW_MEDIUM_SHARE_TOOLTIP,
    mediumId,
    clickCoordinates: tooltipClickCoordinates(e)
  }
}
export function hideMediumShareTooltip () {
  return {
    type: HIDE_MEDIUM_SHARE_TOOLTIP
  }
}
