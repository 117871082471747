import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from 'src/components/common/Button'
import SvgIcon from 'components/common/SvgIcon'
import apple_icon from 'assets/icons/apple_icon.svg'
import back_button from 'assets/icons/back_button.png'
import { appUrl } from '../constants'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { routerActions } from 'react-router-redux'
import { parseEventUrl } from 'services/url_helpers'

import {
  showPhoneCodeFieldSelector,
  isLoginContainerSelector,
  showPhoneFieldSelector
} from 'src/selectors'
import { DESTINATION_TYPES_CONSTANTS } from 'src/constants'
import { showPhoneField, hidePhoneCodeField, notIsLoginContainer } from 'src/actions/timepoll'

import LoginImage from 'assets/icons/empty_state_millenials_at_festival.png'

import styles from './panel.scss'

class Panel extends Component {
  _handleClickBack = () => {
    const {
      onShowPhoneField,
      onHideConfirmationCodeForm,
      onNotIsLoginContainer,
      goBack,
      showPhoneField,
      location,
      goBackTwoSteps
    } = this.props
    let backFunction = null
    if (showPhoneField) {
      if (location?.state?.prevRoute === '/tp/create') {
        backFunction = goBackTwoSteps
      } else {
        backFunction = goBack
      }
    }
    onShowPhoneField()
    onHideConfirmationCodeForm()
    onNotIsLoginContainer()

    backFunction?.()
  }

  isShowCustomBack = () => {
    const { handleBackClick, showPhoneField } = this.props

    if (handleBackClick && showPhoneField) {
      return true
    }
  }

  getBackButtonText = () => {
    const { destinationType } = parseEventUrl(window.location.pathname)

    switch (destinationType) {
      case DESTINATION_TYPES_CONSTANTS.signUp:
        return 'Back to Sign Up List'
      case DESTINATION_TYPES_CONSTANTS.timepoll:
        return 'Back to Poll'
      default:
        return 'Back'
    }
  }

  getDownloadButtonText = () => {
    const { destinationType } = parseEventUrl(window.location.pathname)

    switch (destinationType) {
      case DESTINATION_TYPES_CONSTANTS.signUp:
        return 'create my own sign up list'
      case DESTINATION_TYPES_CONSTANTS.timepoll:
        return 'create my own time poll'
      default:
        return ''
    }
  }

  getCreateType = () => {
    const { destinationType } = parseEventUrl(window.location.pathname)

    switch (destinationType) {
      case DESTINATION_TYPES_CONSTANTS.signUp:
        return '/su/create'
      case DESTINATION_TYPES_CONSTANTS.timepoll:
        return '/tp/create'
      default:
        return '/login'
    }
  }

  renderBackButton = () => {
    const { hideBackButton, handleBackClick, showPhoneField, location } = this.props
    if (hideBackButton || (location?.pathname === '/login' && !location?.state?.prevRoute)) {
      return null
    }

    if (handleBackClick && showPhoneField) {
      return (
        <div className={styles['panel__link']} onClick={handleBackClick}>
          <img src={back_button} alt="back button" />
          <span>{this.getBackButtonText()}</span>
        </div>
      )
    }

    return (
      <div className={styles['panel__link']} onClick={this._handleClickBack}>
        <img src={back_button} alt="back button" />
        <span>Back</span>
      </div>
    )
  }

  render() {
    const {
      notification,
      children,
      isSecondButton,
      isStar,
      isLogin,
      isLoginContainer,
      showCreateButton,
      titleSize
    } = this.props

    return (
      <div className={styles['panel']}>
        {this.renderBackButton()}

        <div className={styles['panel__text']}>
          <img src={LoginImage} width="240" />
          <h1 style={{ fontSize: titleSize?.title }}>{notification.title}</h1>
          <p style={{ fontSize: titleSize?.subtitle }}>{notification.subtitle}</p>
          {notification.notes && <p className={styles['title__notes']}>{notification.notes}</p>}
        </div>

        <div className={styles[isStar ? 'content-star' : 'content']}>{children}</div>

        <div className={styles['buttons-container']}>
          {!isLogin && !isLoginContainer && (
            <a href={appUrl} target="_blank" rel="noreferrer" className={styles['link']}>
              <Button
                onClick={() => {}}
                className={styles[isSecondButton ? 'button-second' : 'button']}
              >
                <div>
                  <SvgIcon
                    icon={apple_icon}
                    className={styles[isSecondButton ? 'button-second__icon' : 'button__icon']}
                  />
                  <strong>download app</strong>
                </div>
                <p>make your own free poll</p>
              </Button>
            </a>
          )}
          {showCreateButton && (
            <Link to={this.getCreateType()} className={styles['link']}>
              <Button onClick={() => {}} className={styles['button-second']}>
                <strong>
                  <p>{this.getDownloadButtonText()}</p>
                </strong>
              </Button>
            </Link>
          )}
        </div>
      </div>
    )
  }
}

Panel.propTypes = {
  location: PropTypes.object,
  notification: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  isSecondButton: PropTypes.bool,
  isStar: PropTypes.bool,
  handleBackClick: PropTypes.func,
  isLogin: PropTypes.bool,
  isLoginContainer: PropTypes.bool,
  onHideConfirmationCodeForm: PropTypes.func,
  onShowPhoneField: PropTypes.func,
  onNotIsLoginContainer: PropTypes.func,
  hideBackButton: PropTypes.bool,
  showCreateButton: PropTypes.bool,
  goBack: PropTypes.func,
  showPhoneField: PropTypes.bool,
  titleSize: PropTypes.object,
  goBackTwoSteps: PropTypes.func
}

function mapStateToProps(state) {
  const showPhoneCodeField = showPhoneCodeFieldSelector(state)
  const showPhoneField = showPhoneFieldSelector(state)
  const isLoginContainer = isLoginContainerSelector(state)
  return {
    showPhoneCodeField,
    isLoginContainer,
    showPhoneField
  }
}

function mapDispatchToProps(dispatch) {
  const onShowPhoneField = param => dispatch(showPhoneField(param))
  const onHideConfirmationCodeForm = () => dispatch(hidePhoneCodeField())
  const onNotIsLoginContainer = () => dispatch(notIsLoginContainer())
  return {
    onShowPhoneField,
    onHideConfirmationCodeForm,
    onNotIsLoginContainer,
    goBack: () => dispatch(routerActions.goBack()),
    goBackTwoSteps: () => dispatch(routerActions.go(-2))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Panel)
