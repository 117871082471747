import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { activityFeedSelector } from 'src/selectors'
import { ClearOutlined, HourglassOutlined, CloseOutlined } from '@ant-design/icons'
import { Segmented } from 'antd'
import ActivityFeedInner from './ActivityFeedInner/ActivityFeedInner'
import {
  fetchActivityFeed,
  setSelectedTag,
  setActivityFeedLoading,
  markAllRead,
  setActivityFeedList
} from 'src/actions/activityFeed'
import LoadingComponent from 'src/components/common/LoadingComponent/LoadingComponent'
import PopupConfirm from 'src/components/common/PopupConfirm/PopupConfirm'
import EmptyActivityFeed from 'assets/icons/empty_state_man_leaping.png'

import styles from './activity-feed-panel.scss'

const ActivityFeedPanel = forwardRef(({ onResetMenuState }, ref) => {
  const dispatch = useDispatch()
  const { isLoading, activityFeedList, selectedTag, oldestTimestamp } =
    useSelector(activityFeedSelector)

  useImperativeHandle(ref, () => ({
    onClearRead: handleClearRead
  }))

  const getActivityFeedList = () => {
    switch (selectedTag) {
      case 'All':
        return activityFeedList
      case 'Unread':
        return activityFeedList.filter(item =>
          item.activity_items.some(item => item.read === false)
        )
      case 'Read':
        return activityFeedList.filter(item =>
          item.activity_items.every(item => item.read === true)
        )
    }
  }

  const popupRef = useRef(null)
  let _scrollLeft = 0

  const handleScrollBottom = e => {
    const { scrollTop, clientHeight, scrollHeight, scrollLeft } = e.target
    if (_scrollLeft !== scrollLeft) {
      _scrollLeft = scrollLeft
      return
    }

    if (oldestTimestamp && scrollTop + clientHeight === scrollHeight) {
      const params = {
        before: oldestTimestamp,
        read_state: selectedTag.toLowerCase(),
        loadMore: true
      }
      dispatch(setActivityFeedLoading(true))
      dispatch(fetchActivityFeed(params))
    }
  }

  const handleClearRead = () => {
    dispatch(
      markAllRead(() => {
        for (const activity of activityFeedList) {
          for (const item of activity.activity_items) {
            item.read = true
          }
        }
        dispatch(setActivityFeedList([...activityFeedList]))
      })
    )
  }

  const handleChange = value => {
    dispatch(setSelectedTag(value))
    dispatch(setActivityFeedLoading(true))

    let params = null
    switch (value) {
      case 'Unread':
        params = { read_state: 'unread' }
        break
      case 'Read':
        params = { read_state: 'read' }
        break
    }

    dispatch(fetchActivityFeed(params))
  }

  const firstViewLineIndex = activityFeedList.findIndex(item => item.slug === 'viewed')
  const activityFeeds = getActivityFeedList()

  return (
    <div className={styles['activity-feed-panel']}>
      <div className={styles['title']}>Activity</div>
      <div className={styles['icon-wrapper']}>
        <ClearOutlined className={styles['icon']} onClick={() => popupRef.current.open()} />
        <CloseOutlined className={styles['icon']} onClick={onResetMenuState} />
      </div>

      <div className={styles['beta-text']}>
        <HourglassOutlined />
        <span className={styles['text']}>More types of Activity Feed coming soon...</span>
      </div>

      <Segmented
        value={selectedTag}
        onChange={value => handleChange(value)}
        className={styles['segmented-inner']}
        block
        options={['All', 'Unread', 'Read']}
      />

      {activityFeeds.length === 0 ? (
        <div className={styles['empty-wrapper']}>
          <img src={EmptyActivityFeed} />
          <b>Activity</b>
          <p>You'll see all our event activity here</p>
          <p>including RSVPs, new photos and more...</p>
        </div>
      ) : (
        <ul className={styles['activity-ul']} onScroll={handleScrollBottom}>
          {activityFeeds.map((item, index) => {
            if (index !== 0 && item.slug === 'viewed' && index === firstViewLineIndex) {
              return (
                <li key={item.id} className={styles['viewed-line']}>
                  NEW
                </li>
              )
            }
            return <ActivityFeedInner key={item.id} activityDetail={item} />
          })}
        </ul>
      )}

      <PopupConfirm
        ref={popupRef}
        reverse
        onCancel={handleClearRead}
        cancelText="YES"
        confirmText="CANCEL"
      >
        Are you sure you want to mark all Activity Feed Items as Read?
      </PopupConfirm>

      {isLoading && (
        <div className={styles['loading-component']}>
          <LoadingComponent light type="partial" />
        </div>
      )}
    </div>
  )
})

ActivityFeedPanel.propTypes = {
  onResetMenuState: PropTypes.func
}

export default ActivityFeedPanel
