import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { mapUrl } from 'services/map_helpers'

import styles from './location-message.scss'

const LocationMessage = props => {
  const {
    inReply,
    locationContent: { metadata, id }
  } = props

  const mapId = inReply ? id + 'reply' : id

  // useEffect(() => {
  //   const baseUrl = 'https://snapshot.apple-mapkit.com/api/v1/snapshot'
  //   const signature =
  //     'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlZaN0gzTkxNNkMifQ.eyJpc3MiOiJNREROMlk0TlJGIiwiaWF0IjoxNjc5NDQ5NTM4LCJleHAiOjE3MTEwNjU2MDAsIm9yaWdpbiI6Imh0dHA6Ly9sb2NhbGhvc3Q6ODA5MCJ9.cSI5XNjypRlqQ1Hb0kMVm6E2sBCQjZHX-LuAqxLZCrcDW4CGJEJ-tl7Xklzr9xvaOWqOueP93-j2YjLrkmP2yQ'
  //   const teamId = 'MDDN2Y4NRF'
  //   const keyId = 'QZ8HM92RH3'

  //   // const a = encodeURI(`${baseUrl}?${queryString.stringify(mapParams)}`)
  //   // console.log(777, a)

  //   var latitude = metadata.latitude
  //   var longitude = metadata.longitude
  //   // var url = `https://snapshot.apple-mapkit.com/api/v1/snapshot?center=${latitude},${longitude}&teamId=MDDN2Y4NRF&keyId=2Y53F5ZHA5&signature=${mapParams.signature}`
  //   let b = `${baseUrl}?center=${latitude},${longitude}&teamId=${teamId}&keyId=${keyId}&signature=${signature}`
  //   fetch(b)
  //     .then(response => response.blob())
  //     .then(blob => {
  //       var img = document.getElementById('snapshot')
  //       img.src = URL.createObjectURL(blob)
  //     })
  //     .catch(error => {
  //       console.error('请求出错:', error)
  //     })
  // }, [])

  useEffect(() => {
    var map = new window.mapkit.Map(mapId)

    var coordinate = new window.mapkit.Coordinate(metadata.latitude, metadata.longitude)
    var annotation = new window.mapkit.MarkerAnnotation(coordinate)

    map.showItems([annotation])
  }, [])

  return (
    <a href={mapUrl(metadata.latitude, metadata.longitude)} target='_blank'>
      <div id={mapId} className={styles['location-message']} />
    </a>
  )
}

LocationMessage.propTypes = {
  inReply: PropTypes.bool,
  locationContent: PropTypes.object.isRequired
}

export default LocationMessage
