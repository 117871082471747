import { velocityHelpers } from 'velocity-react'

export const animations = {
  // Copied and tweaked from: https://github.com/julianshapiro/velocity/blob/9c9c052a5fb6d5854c9aad931d9410c6790816ee/velocity.ui.js#L266
  pulse: velocityHelpers.registerEffect({
    defaultDuration: 900,
    calls: [
      [ { scaleX: 1.2, scaleY: 1.2 }, 0.50, { easing: 'easeInExpo' } ],
      [ { scaleX: 1, scaleY: 1 }, 0.50 ]
    ]
  })
}
