import React, { Component } from 'react'
import UserMain from 'assets/icons/user-main.png'
import styles from './community-modal.scss'
import HobnobModal from 'components/common/HobnobModal'
import CommunityGroup from '../../../../Components/CommunityGroup/CommunityGroup'
import Button from 'src/components/common/Button'
import PropTypes from 'prop-types'

class CommunityModal extends Component {
  state = {
    isOpenCommunityModal: false
  }

  handleSaveGuests = () => {
    this.props.onSaveGuests()
    this.setState({ isOpenCommunityModal: false })
  }

  componentDidUpdate(_, prevState) {
    if (!prevState.isOpenCommunityModal && this.state.isOpenCommunityModal) {
      this.props.onInitialGroupList()
    }
  }

  render() {
    return (
      <div className={styles['community-modal-container']}>
        <div
          className={styles['open-button-inner']}
          onClick={() => this.setState({ isOpenCommunityModal: true })}
        >
          <img src={UserMain} alt="" width="20" />
          <span>hobnob contacts</span>
        </div>

        <HobnobModal
          show={this.state.isOpenCommunityModal}
          closeOnOuterClick={false}
          onClose={() => this.setState({ isOpenCommunityModal: false })}
          containerClassName={styles['modal-container']}
          contentClassName={styles['content-container']}
          cancelButtonClassName={styles['cancel-button']}
        >
          <div className={styles['community-guest-container']}>
            <div className={styles['title']}>hobnob contacts</div>

            <div className={styles['interval-line']} />
            <div className={styles['content']}>
              <CommunityGroup {...this.props} />
            </div>

            <div className={styles['button-wrapper']}>
              <Button className={styles['button-inner']} onClick={this.handleSaveGuests}>
                ADD
              </Button>
            </div>
          </div>
        </HobnobModal>
      </div>
    )
  }
}

CommunityModal.propTypes = {
  onSaveGuests: PropTypes.func,
  onInitialGroupList: PropTypes.func
}

export default CommunityModal
