/* globals ga:false */
import { delay } from 'redux-saga'
import { call, fork, race, select, spawn, takeEvery } from 'redux-saga/effects'
import leftPad from 'left-pad'
import { LOCATION_CHANGE } from 'react-router-redux'

import { SET_USER_ID } from 'src/actions'
import { createWatcher } from './utils'

import {
  routeSelector,
  tabRoutesSelector
} from 'src/selectors'

export default function * googleAnalyticsRootSaga () {
  if (process.env.GOOGLE_ANALYTICS_ENABLED !== 'true') return

  // Wait for google analytics to load
  yield call(googleAnalyticsInitSaga)

  // Track initial page view
  yield fork(recordInitialPageViewSaga)
  // Track additional page views
  yield spawn(createWatcher(LOCATION_CHANGE, recordPageViewWorker))
  yield takeEvery(SET_USER_ID, setUserIdSaga)
  yield fork(recordActiveUserSaga)
  yield fork(timeOnPageSaga)
}

function * googleAnalyticsInitSaga () {
  let promise = new Promise((resolve) => {
    // google_analytics.jade calls the callbacks once google analytics is loaded
    window.googleAnalyticsLoadedCallback = () => {
      resolve()
    }
  })
  yield promise
}

function * recordInitialPageViewSaga () {
  let route = yield select(routeSelector)
  let tabRoutes = yield select(tabRoutesSelector)
  tabRoutes = tabRoutes.map((r) => '/' + r)

  if (tabRoutes.indexOf(route) > -1) {
    recordPageView(route)
  } else {
    recordPageView('/details')
  }
}

function * recordPageViewWorker () {
  let route = yield select(routeSelector)
  let tabRoutes = yield select(tabRoutesSelector)
  tabRoutes = tabRoutes.map((r) => '/' + r)
  if (route === '/') {
    route = '/details'
  }

  if (tabRoutes.indexOf(route) > -1) {
    recordPageView(route)
  }
}

function recordPageView (route) {
  if (typeof ga !== 'undefined') {
    ga('set', 'page', route)
    ga('send', 'pageview')
  }
}

// Records if the user is active
// An active user either has clicked anywhere on the page at least once or been on the page for 5 seconds
function * recordActiveUserSaga () {
  const result = yield race({
    globalClick: call(domGlobalClickSaga),
    timeout: call(activeUserByWaiting)
  })
  const didClick = result.hasOwnProperty('globalClick')
  const specificValue = didClick ? 'Click' : 'Time On Page'

  try {
    ga('send', 'event', 'User', 'Active User', specificValue)
  } catch (e) {
    console.log('Unable to log google analytics event')
  }
}

function * activeUserByWaiting () {
  yield call(delay, 45000)
}

function * domGlobalClickSaga () {
  // Wait for a click
  yield new Promise((resolve) => {
    const listener = () => {
      resolve()
      document.body.removeEventListener('click', listener, true)
    }
    // Listen for capture phase so we receive events that have event.preventDefault called on them
    document.body.addEventListener('click', listener, true)
  })
}

function * timeOnPageSaga () {
  if (process.env.GOOGLE_ANALYTICS_ENABLED !== 'true') return

  let count = 1
  let beginDuration = 1
  let endDuration = 10
  let durationStr = ''
  let pad = (duration) => leftPad(duration, 3, 0)

  while (count <= 13) {
    durationStr = `${pad(beginDuration)}-${pad(endDuration)} seconds`
    if (count === 13) {
      durationStr = `${pad(beginDuration - 1)}+ seconds`
    }

    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'User', 'Time on Page (non-unique)', durationStr, { nonInteraction: true })
    }

    count++
    beginDuration += 10
    endDuration += 10
    yield call(delay, 10000)
  }
}

function * setUserIdSaga (action) {
  if (typeof ga !== 'undefined') {
    ga('set', 'userId', action.userId)
  }
}
