import { fetchApi } from 'src/actions/api'

export const CREATE_TIME_POLL_REQUEST = 'CREATE_TIME_POLL_REQUEST'
export const CREATE_TIME_POLL_REQUEST_SUCCESS = 'CREATE_TIME_POLL_REQUEST_SUCCESS'
export const CREATE_TIME_POLL_REQUEST_ERROR = 'CREATE_TIME_POLL_REQUEST_ERROR'

export const FETCH_POLL = `
  mutation createTimePoll($name: String!, $pollType: String!, $notes: String, $options: [PollOptionInput]!, $status: String) {
    poll_create(
      poll_params: {
        name: $name,
        poll_type: $pollType,
        notes: $notes,
        status: $status
      },
      poll_options_params: $options
    ) {
      result {
        id
        token
      }
      messages
      successful
    }
  }
`

export function createTimePoll({ variables, onSuccessCallback }) {
  return fetchApi({
    requestType: CREATE_TIME_POLL_REQUEST,
    successType: CREATE_TIME_POLL_REQUEST_SUCCESS,
    errorType: CREATE_TIME_POLL_REQUEST_ERROR,
    route: '/graphql',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: FETCH_POLL,
        variables
      })
    },
    onSuccessCallback
  })
}
