import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextInputElement from 'components/forms/TextInputElement'
import CheckBoxInput from 'components/forms/CheckboxInput'
import debounce from 'lodash/debounce'
import styles from './location-auto-complete-input.scss'
import SearchList from 'assets/search_list.png'
import SearchIcon from 'assets/icons/search_icon.svg'
import CloseIcon from 'assets/icons/close-icon-bg.png'
import LocationIcon from 'assets/icons/location-icon.svg'
import SvgIcon from 'components/common/SvgIcon'
import Button from 'components/common/Button'
import cn from 'classnames'

export default class LocationAutoCompleteInput extends Component {
  debouncedSearchHandler = debounce(this.searchByMapKit, 300);

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isError: false,
      queryString: '',
      results: [],
      searchId: 0,
      isUseCustomAddress: false
    }
  }

  searchByMapKit(query) {
    if (!window.mapKitSearch) {
      this.setState({ isError: true })
      return
    }
    this.setState({ isLoading: true, isError: false })
    this.cancelCurrentSearch()
    if (!query) {
      this.setState({ results: [], isLoading: false })
      return
    }
    const searchId = window.mapKitSearch.search(query, (error, data) => {
      this.setState({ isLoading: false })
      if (error) {
        this.setState({ isError: true })
        console.warn(error)
      } else {
        if (searchId >= this.state.searchId) {
          this.setState({ results: data.places })
        }
      }
    })
    this.setState({ searchId })
  }

  search(event) {
    const element = event.target
    this.debouncedSearchHandler(element.value)
    this.setState({ queryString: element.value })
  }

  updateCustomLocation(event) {
    const element = event.target
    this.setState({ queryString: element.value })
  }

  confirmCustomLocation() {
    const mockLocation = {
      name: this.state.queryString
    }
    this.selectLocation(mockLocation)
  }

  selectLocation(location) {
    const selectLocationFunc = this.props.onLocationSelected
    const venue = {
      name: location.name,
      latitude: location.coordinate?.latitude,
      longitude: location.coordinate?.longitude,
      formatted_address: location.formattedAddress,
      country: location.country,
      state: location.administrativeArea,
      city: location.locality,
      street: location.fullThoroughfare,
      postal_code: location.postCode
    }
    /* need to clear name if it is same as street
    * why clear venue name not street? because that's how it works on the iOS version
    */
    if (venue.name === venue.street) {
      venue.name = ''
    }
    if (selectLocationFunc) {
      selectLocationFunc(venue)
    } else {
      console.warn('no on selected event handler')
    }
  }

  isInitState() {
    return (this.state.results?.length === 0 && this.state.searchId === 0)
  }

  renderSearchError() {
    return <section className={cn(styles['error-container'])}>
      <p>Something went wrong, please try again later.</p>
    </section>
  }

  renderInitialList() {
    return <div className={styles['message-container']}>
      <img src={SearchList} alt="" width="100" />
      <p>No Recent Searches</p>
    </div>
  }

  toggleCustomAddressOption() {
    const updatedValue = !this.state.isUseCustomAddress
    this.setState({ isUseCustomAddress: updatedValue })
  }

  renderSearchResultList() {
    if (this.state.isLoading) {
      return (<div className={styles['message-container']}>
        <h4>Loading...</h4>
      </div>)
    }
    if (this.state.results.length === 0) {
      return (<div className={styles['message-container']}>
        <img src={SearchList} alt="" width="100" />
        <p>No Location Found</p>
      </div>)
    } else {
      return this.state.results?.map((location, i) => {
        return (
          <div className={styles['search-result-item']} key={i} onClick={() => this.selectLocation(location)}>
            <div className={styles['result-icon-wrapper']}>
              <SvgIcon icon={LocationIcon} className={styles['result-icon']} />
            </div>
            <div className={styles['result-context']}>
              <h4>{location.name}</h4>
              <p>{location.formattedAddress}</p>
            </div>
          </div>
        )
      })
    }
  }

  resetQueryString() {
    this.cancelCurrentSearch()
    this.setState({ queryString: '', results: [], searchId: 0, isLoading: false })
  }

  componentWillUnmount() {
    this.cancelCurrentSearch()
  }

  cancelCurrentSearch() {
    if (this.state.searchId) {
      window.mapKitSearch.cancel(this.state.searchId)
    }
  }

  render() {
    const onCancelClicked = this.props.onCancelClicked
    return (<main>
      <section className={styles['search-input']}>
        <div className={styles['input-wrapper']}>
          <SvgIcon className={styles['search-icon']} icon={SearchIcon} />
          <TextInputElement
            className={styles['text-input']}
            valid
            placeholder="Location"
            onChange={event => {
              if (this.state.isUseCustomAddress) {
                this.updateCustomLocation(event)
              } else {
                this.search(event)
              }
            }}
            value={this.state.queryString}
            autoFocus
          />
          {this.state.queryString && <img onClick={() => this.resetQueryString()} src={CloseIcon} width="20" />}
        </div>
        {onCancelClicked && <div
          className={styles['cancel-button']}
          onClick={() => {
            onCancelClicked()
          }}
        >
          <span>cancel</span>
        </div>}
      </section>
      <section className={styles['custom-address-option-container']}>
        <CheckBoxInput
          className={styles['check-icon']}
          onChange={() => this.toggleCustomAddressOption()}
          checked={this.state.isUseCustomAddress} size={16} />
        <p onClick={() => this.toggleCustomAddressOption()}>Don't use an address</p>
      </section>
      {this.state.isUseCustomAddress && <section className={styles['confirm-container']}>
        <Button
          className={styles['button-inner']}
          onClick={() => this.confirmCustomLocation()}
        >
          <span>confirm</span>
        </Button>
      </section>}
      {!this.state.isUseCustomAddress && !this.state.isError && <section className={styles['list-container']}>
        {this.isInitState() && this.renderInitialList()}
        {!this.isInitState() && this.renderSearchResultList()}
      </section>}
      {!this.state.isUseCustomAddress && this.state.isError && this.renderSearchError()}
    </main>)
  }
}

LocationAutoCompleteInput.propTypes = {
  onLocationSelected: PropTypes.func,
  onCancelClicked: PropTypes.func
}
