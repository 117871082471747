import React, { Component } from 'react'
import styles from './tags.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'

class Tags extends Component {
  render() {
    const { tagsList, selectedTag, onGetGuestsList, onSelectTag } = this.props
    return (
      <div className={styles['tags-container']}>
        {tagsList.map(item => {
          const count = onGetGuestsList(item).length
          if (item === 'unsent' && onGetGuestsList(item).length === 0) {
            return
          }
          return (
            <span
              key={item}
              className={cn(styles['tag-item'], {
                [styles['tag-item-selected']]: selectedTag === item
              })}
              onClick={() => onSelectTag(item)}
            >
              {item} {count > 0 ? `(${count})` : ''}
            </span>
          )
        })}
      </div>
    )
  }
}

Tags.propTypes = {
  tagsList: PropTypes.array,
  selectedTag: PropTypes.string,
  onGetGuestsList: PropTypes.func,
  onSelectTag: PropTypes.func
}

export default Tags
