import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { pageLoaded } from 'src/actions'
import DashboardContainer from 'src/containers/DashboardContainer'
import { eventSelector, eventModalSelector, isRefreshModalVisibleSelector } from 'src/selectors'
import NoticeYellow from 'src/assets/icons/ico-notice-yellow.png'
import RSVPModalContainer from 'src/components/rsvp_modal/RSVPModalContainer'
import ImageModalContainer from 'src/components/modals/ImageModalContainer'
import ToastMessagesContainer from 'src/components/global/ToastMessagesContainer'
import HobnobModal from 'components/common/HobnobModalV2/HobnobModal'
import Button from 'components/common/Button'
import EventSection from './EventSection/EventSection'
import FlyerContainer from './FlyerContainer/FlyerContainer'
import { changeRefreshModal } from 'src/actions/event'

import cn from 'classnames'

import styles from './guest-event.scss'

const GuestEvent = () => {
  const dispatch = useDispatch()
  const event = useSelector(eventSelector)
  const eventModal = useSelector(eventModalSelector)
  const isRefreshModalVisible = useSelector(isRefreshModalVisibleSelector)

  useEffect(() => {
    dispatch(pageLoaded())
  }, [])

  const renderDraftNotice = () => {
    return (
      <div className={styles['draft-notice']}>
        <img src={NoticeYellow} width="20" />
        <span>
          This is a draft preview, activate it to send your invite! (you can edit it anytime)
        </span>
      </div>
    )
  }

  const isDraft = event.state === 'draft'

  return (
    <DashboardContainer
      menuIcon
      visibleHeaderBar={eventModal === 'details'}
      documentTitle={event.name}
    >
      <>
        {isDraft && renderDraftNotice()}

        <div
          className={cn(styles['guest-event-container'], {
            [styles['guest-event-container-draft']]: isDraft
          })}
        >
          <FlyerContainer />
          <div className={styles['information']}>
            <EventSection />
            <RSVPModalContainer />
            <ImageModalContainer />
            <ToastMessagesContainer />
          </div>
        </div>

        <HobnobModal
          visible={isRefreshModalVisible}
          onCancel={() => dispatch(changeRefreshModal(false))}
        >
          <div className={styles['refresh-container']}>
            <div className={styles['title']}>
              Your Host has changed the settings of this event. Please refresh the page to RSVP.
            </div>
            <Button className={styles['button-inner']} onClick={() => window.location.reload()}>
              REFRESH
            </Button>
          </div>
        </HobnobModal>
      </>
    </DashboardContainer>
  )
}

export default GuestEvent
