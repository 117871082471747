import PropTypes from 'prop-types'
import React, { Component } from 'react'

import HobnobModal from 'src/components/common/HobnobModal'
import Button from 'src/components/common/Button'
import ResizeableTextarea from 'src/components/common/ResizeableTextarea'
import FlyerPoster from 'assets/flyer-poster.png'
import { isAndroid } from 'services/browser_detection'

import styles from './message-modal-container.scss'

import emptyMessageModal from 'src/assets/icons/empty_message_modal.jpg'

export default class MessageModal extends Component {
  _renderDownloadAppMessage() {
    const { branchLink, messageTitle, messageSubTitle } = this.props

    return (
      <div className={styles['invitation-reply-message-form']}>
        <img src={emptyMessageModal} className={styles['empty-message-modal-icon']} />
        <div className={styles['download-app-description']}>
          <h3>{messageTitle}</h3>
          <div>{messageSubTitle}</div>
        </div>
        <Button
          wrapperElement={'a'}
          href={branchLink}
          target="_blank"
          className={styles['button']}
          onClick={this._recordOpenInAppClick}
        >
          <span>Get hobnob to chat</span>
        </Button>
      </div>
    )
  }

  _renderTemplateMessage() {
    const { branchLink, onClose } = this.props
    return (
      <div className={styles['modal-wrapper']}>
        <div className={styles['img-inner']}>
          <img src={FlyerPoster} alt="" />
        </div>
        <div className={styles['content-container']}>
          <div className={styles['text-1']}>
            Create and send invites for your own event for free!
          </div>
          <div className={styles['text-2']}>
            Try our beta on the web, or get ALL the features in our app
          </div>

          <Button className={styles['button-inner']} onClick={this.navigationCreateEvent}>
            design my invite
          </Button>

          {!isAndroid() && (
            <Button
              className={styles['button-inner-white']}
              wrapperElement={'a'}
              href={branchLink}
              target="_blank"
            >
              download app
            </Button>
          )}

          <div className={styles['thx-text']} onClick={onClose}>
            no, thanks
          </div>
        </div>
      </div>
    )
  }

  _renderInvitationReplyForm() {
    const { invitationReplyMessage, onChangeInvitationReplyMessage, onSendInvitationReplyMessage } =
      this.props
    const disabled = invitationReplyMessage.length === 0

    return (
      <div className={styles['invitation-reply-message-form-textarea']}>
        <ResizeableTextarea
          className={styles['invitation-reply-message-form-text-box']}
          comment={invitationReplyMessage}
          onChangeComment={onChangeInvitationReplyMessage}
          placeholder="Message..."
        />
        <Button disabled={disabled} onClick={onSendInvitationReplyMessage}>
          Send
        </Button>
      </div>
    )
  }

  navigationCreateEvent = () => {
    const { isHost, userId } = this.props

    window.mixpanel.track('Create Event Clicked', {
      source: 'design_my_invite',
      user_type: isHost ? 'host' : 'guest',
      user_id: userId
    })
    window.location.href = window.location.origin + '/e/create'
  }

  render() {
    const { canSendReply, show, showMessageSent, onClose } = this.props

    let modalTitle = canSendReply && !showMessageSent ? 'Send Message' : ''
    let modalSubTitle =
      canSendReply && !showMessageSent ? 'Write a private message to the host' : ''

    const visibleReplyForm = canSendReply && !showMessageSent

    return (
      <HobnobModal
        containerClassName={!visibleReplyForm ? styles['hobnob-container'] : ''}
        showCancelIcon={false}
        title={modalTitle}
        subTitle={modalSubTitle}
        show={show}
        onClose={onClose}
      >
        <div className={styles['container']}>
          {visibleReplyForm ? this._renderInvitationReplyForm() : this._renderTemplateMessage()}
          {/* {canSendReply
            ? showMessageSent
              ? this._renderTemplateMessage()
              : this._renderInvitationReplyForm()
            : this._renderTemplateMessage()} */}
        </div>
      </HobnobModal>
    )
  }
}
MessageModal.defaultProps = {
  messageTitle: 'Message Sent'
}

MessageModal.propTypes = {
  userId: PropTypes.string,
  branchLink: PropTypes.string,
  canSendReply: PropTypes.bool,
  invitationReplyMessage: PropTypes.string,
  onChangeInvitationReplyMessage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSendInvitationReplyMessage: PropTypes.func.isRequired,
  show: PropTypes.bool,
  messageTitle: PropTypes.string,
  messageSubTitle: PropTypes.string,
  showMessageSent: PropTypes.bool,
  isHost: PropTypes.bool
}
