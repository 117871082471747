import { combineReducers } from 'redux'

import { SHOW_SHARE_MODAL, HIDE_SHARE_MODAL } from 'src/actions/frontend'

const visible = (state = false, action) => {
  switch (action.type) {
    case SHOW_SHARE_MODAL:
      return true
    case HIDE_SHARE_MODAL:
      return false
    default:
      return state
  }
}

const initialContent = {
  title: '',
  subTitle: '',
  shareUrl: '',
  twitter: {
    subject: '',
    url: ''
  },
  facebook: {
    redirect_url: '',
    href: ''
  },
  email: {
    subject: '',
    body: ''
  }
}

const content = (state = initialContent, action) => {
  switch (action.type) {
    case SHOW_SHARE_MODAL:
      return { ...state, ...action.content }
    case HIDE_SHARE_MODAL:
      return initialContent
    default:
      return state
  }
}

export default combineReducers({
  visible,
  content
})
