import React, { Component } from 'react'
import { connect } from 'react-redux'
import { hideShareModal } from 'src/actions/frontend'
import HobnobModal from 'src/components/common/HobnobModalV2/HobnobModal'
import SvgIcon from 'src/components/common/SvgIcon'
import ShareFacebookIcon from 'src/assets/icons/icons8-facebook-circled.svg'
import ShareEmailIcon from 'src/assets/icons/icons8-circle-envelop.svg'
import ShareTwitterIcon from 'src/assets/icons/new_twitter_logo.svg'
import PropTypes from 'prop-types'
import {
  shareModalVisibleSelector,
  shareModalContentSelector,
  destinationTypeSelector
} from 'src/selectors'
import dateformat from 'dateformat'
import { mailtoLink, twitterLink, facebookLink } from 'services/share'
import CloneHostIcon from 'assets/icons/ico-clone-host.png'
import { message } from 'antd'
import { DESTINATION_TYPES_CONSTANTS } from 'src/constants'
import styles from './share-modal.scss'

class ShareModal extends Component {
  _twitterLink() {
    const {
      content: { twitter }
    } = this.props
    const params = {
      subject: twitter.subject,
      url: twitter.url
    }

    return twitterLink(params)
  }

  _facebookLink() {
    const {
      content: { facebook }
    } = this.props

    const params = {
      href: facebook.href,
      redirect_uri: facebook.url
    }

    return facebookLink(params)
  }

  _emailLink = () => {
    const { destinationType } = this.props
    if (destinationType === DESTINATION_TYPES_CONSTANTS.event) {
      return this._mailtoEventLink()
    } else if (destinationType === DESTINATION_TYPES_CONSTANTS.signUp) {
      return this._mailtoSULLink()
    } else if (destinationType === DESTINATION_TYPES_CONSTANTS.timepoll) {
      return this._mailtoTPLink()
    }
  }

  _mailtoTPLink() {
    const {
      content: {
        email: { tp = {}, url }
      }
    } = this.props
    let body = `Can you make it to ${tp.name}`

    if (tp.starts_at) {
      const formattedDate = dateformat(tp.starts_at, 'dddd, mmmm dS')
      body += ` on ${formattedDate}`
    }
    body += `?\n\n${url}`

    const params = {
      subject: `You're invited to ${tp.name}`,
      body
    }

    return mailtoLink(params)
  }

  _mailtoSULLink() {
    const {
      content: {
        email: { sul = {}, url }
      }
    } = this.props
    let body = `Can you make it to ${sul.name}`

    if (sul.starts_at) {
      const formattedDate = dateformat(sul.starts_at, 'dddd, mmmm dS')
      body += ` on ${formattedDate}`
    }
    body += `?\n\n${url}`

    const params = {
      subject: `You're invited to ${sul.name}`,
      body
    }

    return mailtoLink(params)
  }

  _mailtoEventLink() {
    const {
      content: {
        email: { event = {}, host }
      }
    } = this.props
    let body = ''
    if (event.event_type === 'event') {
      body = `Can you make it to ${event.name}`

      if (event.starts_at) {
        const formattedDate = dateformat(event.starts_at, 'dddd, mmmm dS')
        body += ` on ${formattedDate}`
      }
      body += `?\n\n${event.email_share_url}`
    } else {
      body = 'Find out more details below!'
      if (host) {
        body += `\n\nFrom: ${host.first_name} ${host.last_name}`
      }
      body += `\n\n${event.email_share_url}`
    }

    const params = {
      subject:
        event.event_type === 'event'
          ? `You're invited to ${event.name}`
          : `We’re sharing the news with you about ${event.name}`,
      body
    }

    return mailtoLink(params)
  }

  handleTrack = type => {
    if (type === 'email') {
      window.mixpanel.track('Email Client Clicked')
    } else if (type === 'facebook') {
      window.mixpanel.track('Facebook Client Clicked')
      window.mixpanel.track('Social Channel Client Clicked')
    } else if (type === 'twitter') {
      window.mixpanel.track('Twitter Client Clicked')
      window.mixpanel.track('Social Channel Client Clicked')
    }
  }

  handleCopyLink = () => {
    window.mixpanel.track('Link Copied ')
    navigator.clipboard.writeText(this.props.content.shareUrl)
    message.success('Copied! 🎉')
  }

  render() {
    const { visible, onHideShareModal, content } = this.props

    return (
      <HobnobModal visible={visible} onCancel={onHideShareModal}>
        <div className={styles['event-share-container']}>
          <div className={styles['title']}>{content.title}</div>
          <div className={styles['description']}>{content.subtitle}</div>
          <div className={styles['input-wrapper']} onClick={this.handleCopyLink}>
            <span>{content.shareUrl}</span>
            <img src={CloneHostIcon} width="30" />
          </div>
          <div className={styles['or-text']}>or</div>
          <a
            className={styles['input-wrapper']}
            href={this._facebookLink()}
            target="_blank"
            onClick={() => this.handleTrack('facebook')}
          >
            <span>Facebook</span>
            <SvgIcon icon={ShareFacebookIcon} className={styles['icon']} />
          </a>
          <a
            className={styles['input-wrapper']}
            href={this._emailLink()}
            target="_blank"
            onClick={() => this.handleTrack('email')}
          >
            <span>Email</span>
            <SvgIcon icon={ShareEmailIcon} className={styles['icon']} />
          </a>
          <a
            className={styles['input-wrapper']}
            href={this._twitterLink()}
            target="_blank"
            onClick={() => this.handleTrack('twitter')}
          >
            <span>X (f.k.a Twitter)</span>
            <SvgIcon icon={ShareTwitterIcon} className={styles['icon']} />
          </a>
        </div>
      </HobnobModal>
    )
  }
}

ShareModal.propTypes = {
  destinationType: PropTypes.string,
  visible: PropTypes.bool,
  host: PropTypes.object,
  content: PropTypes.object,
  onHideShareModal: PropTypes.func
}

function mapStateToProps(state) {
  return {
    visible: shareModalVisibleSelector(state),
    content: shareModalContentSelector(state),
    destinationType: destinationTypeSelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onHideShareModal: () => dispatch(hideShareModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareModal)
