import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'

import styles from './hobnob-input.scss'

export default class HobnobInput extends React.Component {
  _renderInput = () => {
    const {
      placeholder,
      inputClassName,
      value,
      defaultValue,
      onChange,
      type,
      valid,
      theme,
      rows,
      style,
      readOnly
    } = this.props
    const validClassName = valid ? '' : styles['invalid']
    switch (type) {
      case 'textarea':
        return (
          <textarea
            value={value}
            autoComplete='off'
            rows={rows}
            placeholder={placeholder}
            className={ClassNames(
              `${styles['textarea__inner']} ${inputClassName}`
            )}
            style={{ minHeight: `${rows * 21}px` }}
            onChange={onChange}
            readOnly={readOnly}
          />
        )
      case 'text':
        return (
          <input
            type='text'
            defaultValue={defaultValue}
            value={value}
            autoComplete='off'
            placeholder={placeholder}
            style={{ color: theme === 'dark' ? '#FFF' : '', ...style }}
            className={ClassNames(
              styles['input__inner'],
              validClassName,
              { 'bg-dark': theme === 'dark' },
              inputClassName
            )}
            onChange={onChange}
            readOnly={readOnly}
          />
        )
      default:
        return (
          <input
            type='text'
            defaultValue={defaultValue}
            value={value}
            autoComplete='off'
            placeholder={placeholder}
            style={{ color: theme === 'dark' ? '#FFF' : '', ...style }}
            className={ClassNames(
              styles['input__inner'],
              validClassName,
              { 'bg-dark': theme === 'dark' },
              inputClassName
            )}
            onChange={onChange}
            readOnly={readOnly}
          />
        )
    }
  }

  render () {
    const {
      prefix,
      suffix,
      className,
      children,
      suffixClassName,
      prefixClassName,
      onClick,
      theme
    } = this.props

    return (
      <div
        className={ClassNames(
          styles['input-wrapper'],
          { [styles['input--prefix']]: !!prefix },
          { [styles['input--suffix']]: !!suffix },
          { [styles['input-group']]: !!children },
          { [styles['input-group--prepend']]: !!children },
          { 'bg-dark': theme === 'dark' },
          className
        )}
        onClick={onClick}
      >
        {children ? (
          <div className={ClassNames(styles['input-group__prepend'])}>
            {children}
          </div>
        ) : null}

        {this._renderInput()}

        {prefix ? (
          <span className={ClassNames(styles['input__prefix'], prefixClassName)}>
            {prefix}
          </span>
        ) : null}

        {suffix ? (
          <span className={ClassNames(styles['input__suffix'], suffixClassName)}>
            {suffix}
          </span>
        ) : null}
      </div>
    )
  }
}

HobnobInput.defaultProps = {
  valid: true,
  type: 'text',
  theme: 'light',
  rows: 1
}

HobnobInput.propTypes = {
  placeholder: PropTypes.any,
  prefix: PropTypes.any,
  suffix: PropTypes.any,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  defaultValue: PropTypes.string,
  valid: PropTypes.bool,
  theme: PropTypes.string,
  type: PropTypes.string,
  rows: PropTypes.number,
  style: PropTypes.object,
  suffixClassName: PropTypes.string,
  prefixClassName: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
  readOnly: PropTypes.any
}
