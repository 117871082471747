import React from 'react'
import styles from './holiday-promo.scss'

const HolidayPromo = () => {
  const texts = [
    {
      title: 'Eligibility',
      content: [
        'Hobnob for the Holidays (the “Sweepstakes”) is open only to those who are using version 5.7.8+ of the Hobnob app from the Apple App Store (ID 947574948) and who are 21+ as of the date of entry. The sweepstakes is only open to legal residents of United States and is void where prohibited by law. Employees of myhobnob, inc. (the “Sponsor”) their respective affiliates, subsidiaries, advertising and promotion agencies, suppliers and their immediate family members and/or those living in the same household of each are not eligible to participate in the Sweepstakes. The Sweepstakes is subject to all applicable federal, state and local laws and regulations, void where prohibited.'
      ]
    },
    {
      title: 'Agreement to Rules',
      content: [
        'By participating, you agree to be fully unconditionally bound by these Rules, and you represent and warrant that you meet the eligibility requirements set forth herein. In addition, you agree to accept the decisions of the Sponsor as final and binding as it relates to the content. The Sweepstakes is subject to all applicable federal, state and local laws.'
      ]
    },
    {
      title: 'Sweepstakes Period',
      content: [
        'Entries will be accepted online starting on Dec 06, 2023 12:00 AM Pacific Standard Time (“PST”), and ending Dec 22, 2023 11:59 PM PST. All entries must be received by Dec 22, 2023 3:00 PM PST .'
      ]
    },
    {
      title: 'How to Enter',
      content: [
        'The Sweepstakes is automatically entered when: a) an existing user installs and logs in to the latest version of the Hobnob iOS app (v 5.7.8) and then shares Hobnob using the Share Hobnob button in user profile, creating a Unique Share Link, and subsequently  b) a unique user who has not previously installed the Hobnob app via the Apple App Store (App Store ID947574948) installs the latest version of the Hobnob app (v5.7.8)  and registers a new single User Account using a verified phone number (“New User”) by using the Unique Share Link.  Each Friday between December 6, 2023 and December 22, 2023 at 3:00PM Pacific Standard Time an entry will be randomly selected as the winner of that week’s prize.  Each entry must fulfill all sweepstakes requirements, as specified, for the User to be eligible to win a prize. Entries that are not complete or do not adhere to the rules or specifications may be disqualified at the sole discretion of the Sponsor. An entry that is a result of a single user creating multiple accounts using multiple email addresses or phone numbers in an attempt to circumvent the rules will be considered a fraudulent entry. If you use fraudulent methods or otherwise attempt to circumvent the rules your submission may be removed from eligibility at the sole discretion of the Sponsor.'
      ]
    },

    {
      title: 'Prizes',
      content: [
        'Winners will receive:$100 Gift Card valid in the United States + a charity match donation from a select group of 501c(3) options in the Winners names. Actual/appraised value may differ at time of prize award. The specifics of the prize shall be solely determined by the Sponsor. No cash or other prize substitution permitted except at Sponsor’s discretion. The prize is non transferable. Any and all prize related expenses, including without limitation any and all federal, state, and/or local taxes shall be the sole responsibility of the winner. No substitution of prize or transfer/assignment of prize to others or request for the cash equivalent by winners is permitted. Acceptance of prize constitutes permission for Sponsor to use winner’s name, likeness, and entry for purposes of advertising and trade without further compensation, unless prohibited by law.'
      ]
    },
    {
      title: 'Odds',
      content: ['The odds of winning depend on the number of eligible entries received.']
    },
    {
      title: 'Winner selection and notification',
      content: [
        'Winners of the Sweepstakes will be selected in a random drawing under the supervision of the Sponsor. Winners will be notified by push notification in the Sponsor software app using the iOS latest version available in the Apple App Store software application, and via Sponsor’s Instagram social media. Push notifications must be set to “ON” and Sponsor takes no responsibility if Winners do not see the notification in a timely manner to claim the prize (within 5 calendar days). Winner must contact Sponsor with a screenshot of the notification, and verify identity by sending a text message from the phone number associated with the Sponsor app account to a number to be provided by Sponsor. Sponsor shall have no liability for a winner’s failure to receive notices due to winners’ spam, Internet connectivity issues, improperly functioning notifications on device,  junk e-mail or other security settings or for winners’ provision of incorrect or otherwise non-functioning contact information. If the selected winner cannot be contacted, is ineligible, fails to claim the prize within 72 hours from the time award notification was sent, or fails to timely return a completed and executed declaration and releases as required, prize may be forfeited and an alternate winner selected.',
        'The receipt by winner of the prize offered in this Sweepstakes is conditioned upon compliance with any and all federal and state laws and regulations. ANY VIOLATION OF THESE OFFICIAL RULES BY ANY WINNER (AT SPONSOR’S SOLE DISCRETION) WILL RESULT IN SUCH WINNER’S DISQUALIFICATION AS WINNER OF THE SWEEPSTAKES AND ALL PRIVILEGES AS WINNER WILL BE IMMEDIATELY TERMINATED.'
      ]
    },
    {
      title: 'Rights Granted by you',
      content: [
        'By entering this Sweepstakes you understand that the Sponsor, anyone acting on behalf of the Sponsor, or its respective licensees, successors and assigns will have the right, where permitted by law, without any further notice, review or consent to print, publish, broadcast, distribute, and use, worldwide in any media now known or hereafter in perpetuity and throughout the World, your entry, including, without limitation, the entry and winner’s name, portrait, picture, voice, likeness, image or statements about the Sweepstakes, and biographical information as news, publicity or information and for trade, advertising, public relations and promotional purposes without any further compensation.'
      ]
    },
    {
      title: 'Terms',
      content: [
        'Sponsor reserves the right, in its sole discretion to cancel, terminate, modify or suspend the Sweepstakes should (in its sole discretion) a virus, bugs, non-authorized human intervention, fraud or other causes beyond its control corrupt or affect the administration, security, fairness or proper conduct of the Sweepstakes. In such a case, Sponsor may select the recipients from all eligible entries received prior to and/or after (if appropriate) the action taken by Sponsor.',
        'Sponsor reserves the right at its sole discretion to disqualify any individual who tampers or attempts to tamper with the entry process or the operation of the Sweepstakes or website or violates these Terms & Conditions.',
        'Sponsor has the right, in its sole discretion, to maintain the integrity of the Sweepstakes, to void entries for any reason, including, but not limited to; multiple entries from the same user from different IP addresses; multiple entries from the same computer in excess of that allowed by sweepstakes rules; or the use of bots, macros or scripts or other technical means for entering. Any attempt by an entrant to deliberately damage any web site or undermine the legitimate operation of the sweepstakes may be a violation of criminal and civil laws and should such an attempt be made, myhobnob, inc. reserves the right to seek damages from any such person to the fullest extent permitted by law.',
        'By entering the Sweepstakes you agree to receive email newsletters periodically from the Sponsor. You can opt-out of receiving this communication at any time by clicking the unsubscribe link in the newsletter.'
      ]
    },
    {
      title: 'Limitation of Liability',
      content: [
        'By entering you agree to release and hold harmless the Sponsor and its subsidiaries, affiliates, advertising and promotion agencies, partners, representatives, agents, successors, assigns, employees, officers and directors from any liability, illness, injury, death, loss, litigation, claim or damage that may occur, directly or indirectly, whether caused by negligence or not, from (i) such entrant’s participation in the sweepstakes and/or his/her acceptance, possession, use, or misuse of any prize or any portion thereof, (ii) technical failures of any kind, including but not limited to the malfunctioning of any computer, cable, network, hardware or software; (iii) the unavailability or inaccessibility of any transmissions or telephone or Internet service; (iv) unauthorized human intervention in any part of the entry process or the Promotion; (v) electronic or human error which may occur in the administration of the Promotion or the processing of entries.'
      ]
    },
    {
      title: 'Disputes',
      content: [
        'THIS SWEEPSTAKES IS GOVERNED BY THE LAWS OF United States AND Hawaii WITHOUT RESPECT TO CONFLICT OF LAW DOCTRINES. As a condition of participating in this Sweepstakes, participant agrees that any and all disputes which cannot be resolved between the parties, and causes of action arising out of or connected with this Sweepstakes, shall be resolved individually, without resort to any form of class action, exclusively before a court located in Hawaii having jurisdiction. Further, in any such dispute, under no circumstances will participant be permitted to obtain awards for, and hereby waives all rights to claim punitive, incidental, or consequential damages, including reasonable attorneys’ fees, other than participant’s actual out-of-pocket expenses (i.e. costs associated with entering this Sweepstakes), and participant further waives all rights to have damages multiplied or increased.'
      ]
    },
    {
      title: 'Privacy Policy',
      content: [
        'Information submitted with an entry is subject to the Privacy Policy stated on the Sponsor’s Web Site. To read the Privacy Policy, click here.'
      ]
    },
    {
      title: 'Winners List',
      content: [
        'To obtain a copy of the winner’s name or a copy of these Official Rules, mail your request along with a stamped, self-addressed envelope to: myhobnob, inc. 770 Haiku Road, #1407 Haiku Hawaii 96708 United States. Requests must be received no later than 60 days after the Promotion ends.'
      ]
    },
    {
      title: 'Sponsor',
      content: [
        'The Sponsor of the Sweepstakes is myhobnob, inc. 770 Haiku Road, #1407 Haiku Hawaii 96708 United States.'
      ]
    },
    {
      content: [
        'This promotion is in no way sponsored, endorsed or administered by, or associated with Facebook, Twitter, Instagram, Snapchat, Youtube, Pinterest, LinkedIn or Google. You understand that you are providing your information to the owner of this sweepstakes and not to Facebook, Twitter, Instagram, Snapchat, Youtube, Pinterest, LinkedIn or Google.'
      ]
    }
  ]

  const addLinkToText = text => {
    const url = 'https://hobnob.app/privacy/'
    const linkText = 'click here'

    const index = text.indexOf(linkText)
    if (index !== -1) {
      return (
        <>
          {text.substring(0, index)}
          <a href={url}>{linkText}</a>
          {text.substring(index + linkText.length)}
        </>
      )
    } else {
      return text
    }
  }

  return (
    <div className={styles['holiday-promo-container']}>
      <h4>Hobnob for the Holidays</h4>
      <h4>OFFICIAL RULES</h4>
      <h4>
        NO PURCHASE IS NECESSARY TO ENTER OR WIN. A PURCHASE DOES NOT INCREASE THE CHANCES OF
        WINNING.
      </h4>
      {texts.map((item, index) => (
        <div className={styles['section-wrapper']} key={item.title}>
          {item.content.map((text, i) => {
            if (i === 0) {
              return (
                <div className={styles['title']} key={i}>
                  <h4>
                    {index + 1}.{item.title && `${item.title}:`}&nbsp;
                  </h4>
                  <p className={styles['first-paragraph']}>{addLinkToText(text)}</p>
                </div>
              )
            }
            return <p key={i}>{addLinkToText(text)}</p>
          })}
        </div>
      ))}
    </div>
  )
}

export default HolidayPromo
