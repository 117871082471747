/* eslint-disable react/prop-types */
import styles from './card.scss'
import moment from 'moment'
import React from 'react'

const Card = props => {
  const { date } = props

  const startMoment = moment(date.starts_at)
  const endMoment = moment(date.ends_at)

  const getMonth = () => {
    if (startMoment.isSame(endMoment, 'month')) {
      return startMoment.format('MMM')
    }

    return `${startMoment.format('MMM')} - ${endMoment.format('MMM')}`
  }

  const getDate = () => {
    if (startMoment.isSame(endMoment, 'day')) {
      return startMoment.format('DD')
    }

    return `${startMoment.format('DD')} - ${endMoment.format('DD')}`
  }

  const getDay = () => {
    const start = startMoment.format('ddd')
    const end = endMoment.format('ddd')
    if (start === end) {
      return start
    }

    return `${start} - ${end}`
  }

  const getTime = () => {
    const start = startMoment.format('hh:mma')
    const end = endMoment.format('hh:mma')
    if (start !== end) {
      return `${start} - ${end}`
    }

    return null
  }

  return (
    <div className={styles['card']}>
      <div className={styles['card-date-wrapper']}>
        <span className={styles['date-month']}>{getMonth()}</span>
        <span className={styles['date-day']}>{getDate()}</span>
        <span className={styles['date-date']}>{getDay()}</span>
      </div>
      <div className={styles['card-time-wrapper']}>
        <span className={styles['date-time']}>{getTime()}</span>
      </div>
    </div>
  )
}

export default Card
