import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './ticket-counts-selector.scss'
import { displayCurrency, isHobnobAndroidClient } from '../../services/utils'
import HobnobSelect from '../common/HobnobSelect'
import SvgIcon from '../common/SvgIcon'
import TriangleDownIcon from '../../assets/icons/triangle-down-icon.svg'
import SelectElement from 'components/forms/SelectElement'

export default class TicketCountsSelector extends Component {
  _countOptions () {
    const { guestCountEnabled } = this.props

    if (!guestCountEnabled) {
      return [{
        value: '1',
        text: '1'
      }]
    } else {
      const minTicketsCount = 0
      const maxTicketCount = 10
      let options = []

      for (let i = minTicketsCount; i <= maxTicketCount; i++) {
        options.push(i)
      }
      return options.map((value) => {
        return {
          value: value.toString(),
          text: value.toString()
        }
      })
    }
  }

  _renderLabel = (countType) => {
    if (countType === 'adult') {
      return 'Adults'
    } else {
      return 'Children'
    }
  }

  _renderRow = () => {
    const { ticketCounts, onChangeCount, ticketTypesCounts, showTicketCounts } = this.props

    // Handle abnormal situations.
    if (ticketCounts.length === 0) {
      window.location.reload()
      return ''
    }

    const ticketTypesCountsByLabel = ticketTypesCounts.reduce((acc, ticketTypeCount) => {
      if (acc[ticketTypeCount.label]) {
        acc[ticketTypeCount.label] += ticketTypeCount.count
      } else {
        acc[ticketTypeCount.label] = ticketTypeCount.count
      }
      return acc
    }, {})

    const ticketCountsByLabel = ticketCounts.reduce((acc, ticketTypeCount) => {
      if (acc[ticketTypeCount.label]) {
        acc[ticketTypeCount.label] += ticketTypeCount.count
      } else {
        acc[ticketTypeCount.label] = ticketTypeCount.count
      }
      return acc
    }, {})

    // console.log('ticketTypesCountsByLabel', ticketTypesCountsByLabel, ticketTypesCounts)
    // console.log('ticketCountsByLabel', ticketCountsByLabel, ticketCounts)

    return ticketCounts.filter(item => !item.archived_at).map((item) => {
      const value = ticketCountsByLabel[item.label].toString()
      const defaultValue = ticketTypesCountsByLabel[item.label].toString()

      return (
        <div key={item.id} className={styles['row']}>
          <form className={styles['select-wrapper']} onSubmit={this._onSubmit}>
            {
              showTicketCounts ? <div>
                <span className={styles['label']}>{ticketCounts.length > 1 ? item.label : 'ticket'}</span>
                <span
                  className={styles['price']}><div className={styles['divider-dot']} />
                  {item.price ? displayCurrency(item.price) : 'Free'}</span>
              </div>
                : <div>
                  <span
                    className={styles['label']}>{ticketCounts.length > 1 ? this._renderLabel(item.label) : 'Guests'}</span>
                </div>
            }

            {
              isHobnobAndroidClient() ? <HobnobSelect
                className={styles['count-select-android']}
                inputClassName={styles['count-input']}
                onChange={(newValue) => onChangeCount(item.id, parseInt(newValue))}
                value={value}
                options={this._countOptions()} />
                : (
                  <div className={styles['count-select-wrapper']}>
                    <div className={styles['triangle-down-wrapper']}>
                      {value}
                      <SvgIcon icon={TriangleDownIcon} className={styles['triangle-down-icon']} />
                    </div>
                    <SelectElement
                      className={styles['count-select']}
                      value={value}
                      defaultValue={defaultValue}
                      onChange={(e) => onChangeCount(item.id, parseInt(e.target.value))}
                      options={this._countOptions()}
                    />
                  </div>
                )
            }
            <input style={{ display: 'none' }} type='submit' />
          </form>
        </div>
      )
    })
  }

  render () {
    return <div>
      {this._renderRow()}
    </div>
  }
}

TicketCountsSelector.propTypes = {
  guestCountEnabled: PropTypes.bool.isRequired,
  onChangeCount: PropTypes.func,
  showTicketCounts: PropTypes.bool,
  ticketCounts: PropTypes.array,
  ticketTypesCounts: PropTypes.array
}
