import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isHobnobAndroidClient } from '../../services/utils'
import styles from './payment-method-selector.scss'

import SelectElement from 'components/forms/SelectElement'
import SvgIcon from '../common/SvgIcon'
import ChevronDownSmall from '../../assets/icons/chevron_down_small.svg'
import HobnobSelect from '../common/HobnobSelect'

class PaymentMethodSelector extends Component {
  _getOptions = () => {
    const { paymentMethodsList } = this.props
    return paymentMethodsList.map((item) => {
      return {
        value: item.id,
        text: `${item.brand} ${item.last4}`
      }
    })
  }

  render () {
    const { paymentMethod, onPickerChange } = this.props
    const pickerValue = paymentMethod.id
    return (
      <div>
        {
          isHobnobAndroidClient() ? <div className={styles['method-wrapper']}>
            <SvgIcon icon={ChevronDownSmall} divClassName={styles['chevron-down-small-box']}
              className={styles['chevron-down-small-icon']} />
            <span className={styles['payment-method']}>{paymentMethod.brand} {paymentMethod.last4}</span>
            <HobnobSelect
              className={styles['picker-selector']}
              value={pickerValue}
              suffixClassName={styles['suffix']}
              onChange={(value) => onPickerChange(value)}
              options={this._getOptions()} />
          </div>
            : (
              <div className={styles['method-wrapper']}>
                <SvgIcon icon={ChevronDownSmall} divClassName={styles['chevron-down-small-box']}
                  className={styles['chevron-down-small-icon']} />
                <span className={styles['payment-method']}>{paymentMethod.brand} {paymentMethod.last4}</span>
                <SelectElement
                  className={styles['selector']}
                  value={pickerValue}
                  onChange={(e) => onPickerChange(e.target.value)}
                  options={this._getOptions()}
                />
              </div>
            )
        }
      </div>
    )
  }
}

PaymentMethodSelector.propTypes = {
  paymentMethodsList: PropTypes.array,
  paymentMethod: PropTypes.object,
  onPickerChange: PropTypes.func
}

export default PaymentMethodSelector
