import { fetchApi } from './api'
import { Polls } from 'src/schemas'
import { formatDoubleQuotes } from './utils'

export const CHANGE_SIGN_UP_FORM = 'CHANGE_SIGN_UP_FORM'
export const RESET_SIGN_UP_FORM = 'RESET_SIGN_UP_FORM'

export const FETCH_LIST_UPDATE = 'FETCH_LIST_UPDATE'
export const FETCH_LIST_UPDATE_SUCCESS = 'FETCH_LIST_UPDATE_SUCCESS'
export const FETCH_LIST_UPDATE_FAILED = 'FETCH_LIST_UPDATE_FAILED'

export const FETCH_POLL_DELETE = 'FETCH_POLL_DELETE'
export const FETCH_POLL_DELETE_SUCCESS = 'FETCH_POLL_DELETE_SUCCESS'
export const FETCH_POLL_DELETE_FAILED = 'FETCH_POLL_DELETE_FAILED'

export const FETCH_TIME_POLL = 'FETCH_TIME_POLL'
export const FETCH_TIME_POLL_SUCCESS = 'FETCH_TIME_POLL_SUCCESS'
export const TIME_POLL_NOT_FOUND = 'TIME_POLL_NOT_FOUND'

export const FETCH_SIGN_UP = 'FETCH_SIGN_UP'
export const FETCH_SIGN_UP_SUCCESS = 'FETCH_SIGN_UP_SUCCESS'
export const SIGN_UP_NOT_FOUND = 'SIGN_UP_NOT_FOUND'

export const FETCH_SIGN_UP_LIST = 'FETCH_SIGN_UP_LIST'
export const FETCH_SIGN_UP_LIST_SUCCESS = 'FETCH_SIGN_UP_LIST_SUCCESS'
export const FETCH_SIGN_UP_LIST_FAILED = 'FETCH_SIGN_UP_LIST_FAILED'

export const UPDATE_SIGN_UP_USER_SELECTIONS = 'UPDATE_SIGN_UP_USER_SELECTIONS'
export const UPDATE_SIGN_UP_USER_SELECTIONS_SUCCESS = 'UPDATE_SIGN_UP_USER_SELECTIONS_SUCCESS'
export const UPDATE_SIGN_UP_USER_SELECTIONS_FAILED = 'UPDATE_SIGN_UP_USER_SELECTIONS_FAILED'

export const UPDATE_POLL_USER_SELECTIONS = 'UPDATE_POLL_USER_SELECTIONS'
export const UPDATE_POLL_USER_SELECTIONS_SUCCESS = 'UPDATE_POLL_USER_SELECTIONS_SUCCESS'
export const UPDATE_POLL_USER_SELECTIONS_FAILED = 'UPDATE_POLL_USER_SELECTIONS_FAILED'

export const DELETE_POLL_USER_SELECTIONS = 'DELETE_POLL_USER_SELECTIONS'
export const DELETE_POLL_USER_SELECTIONS_SUCCESS = 'DELETE_POLL_USER_SELECTIONS_SUCCESS'
export const DELETE_POLL_USER_SELECTIONS_FAILED = 'DELETE_POLL_USER_SELECTIONS_FAILED'

export const UPDATE_POLL_USER_SELECTIONS_WHEN_LOGIN = 'UPDATE_POLL_USER_SELECTIONS_WHEN_LOGIN'
export const UPDATE_POLL_USER_SELECTIONS_WHEN_LOGIN_SUCCESS =
  'UPDATE_POLL_USER_SELECTIONS_WHEN_LOGIN_SUCCESS'
export const UPDATE_POLL_USER_SELECTIONS_WHEN_LOGIN_FAILED =
  'UPDATE_POLL_USER_SELECTIONS_WHEN_LOGIN_FAILED'

export const UPDATE_SELECTED_TIME_POLL_OPTIONS = 'UPDATE_SELECTED_TIME_POLL_OPTIONS'
export const UPDATE_SELECTED_TIME_POLL_VOTES = 'UPDATE_SELECTED_TIME_POLL_VOTES'

export const SUBSCRIBE_TO_MAILCHIMP = 'SUBSCRIBE_TO_MAILCHIMP'
export const SUBSCRIBE_TO_MAILCHIMP_SUCCESS = 'SUBSCRIBE_TO_MAILCHIMP_SUCCESS'
export const SUBSCRIBE_TO_MAILCHIMP_FAILED = 'SUBSCRIBE_TO_MAILCHIMP_FAILED'

export const CREATE_TIME_POLL = 'CREATE_TIME_POLL'
export const CREATE_TIME_POLL_SUCCESS = 'CREATE_TIME_POLL_SUCCESS'
export const CREATE_TIME_POLL_FAILED = 'CREATE_TIME_POLL_FAILED'

export const FETCH_POLL_LIST = 'FETCH_POLL_LIST'
export const FETCH_POLL_LIST_SUCCESS = 'FETCH_POLL_LIST_SUCCESS'
export const FETCH_POLL_LIST_FAILED = 'FETCH_POLL_LIST_FAILED'

export const FETCH_POLL_BY_ID = 'FETCH_POLL_BY_ID'
export const FETCH_POLL_BY_ID_SUCCESS = 'FETCH_POLL_BY_ID_SUCCESS'
export const POLL_NOT_FOUND = 'POLL_NOT_FOUND'

export const SHOW_PHONE_CODE_FIELD = 'SHOW_PHONE_CODE_FIELD'
export const HIDE_PHONE_CODE_FIELD = 'HIDE_PHONE_CODE_FIELD'
export const SHOW_EMAIL_FIELD = 'SHOW_EMAIL_FIELD'
export const SHOW_PHONE_FIELD = 'SHOW_PHONE_FIELD'
export const HIDE_PHONE_FIELD = 'HIDE_PHONE_FIELD'

export const IS_SUBSCRIBE_POLL_CONTAINER = 'IS_SUBSCRIBE_POLL_CONTAINER'
export const NOT_IN_SUBSCRIBE_POLL_CONTAINER = 'NOT_IN_SUBSCRIBE_POLL_CONTAINER'

export const IS_LOGIN_CONTAINER = 'IS_LOGIN_CONTAINER'
export const NOT_IS_LOGIN_CONTAINER = 'NOT_IS_LOGIN_CONTAINER'

export const IS_UPDATE_SELECTIONS = 'IS_UPDATE_SELECTIONS'

export const CHANGE_POLL_TAB = 'CHANGE_POLL_TAB'

export const FETCH_POLL_BRANCH_LINKS = 'FETCH_POLL_BRANCH_LINKS'
export const FETCH_POLL_BRANCH_LINKS_SUCCESS = 'FETCH_POLL_BRANCH_LINKS_SUCCESS'
export const FETCH_POLL_BRANCH_LINKS_ERROR = 'FETCH_POLL_BRANCH_LINKS_ERROR'

export function fetchPollBranchLinks(timePollToken) {
  return fetchApi({
    requestType: FETCH_POLL_BRANCH_LINKS,
    successType: FETCH_POLL_BRANCH_LINKS_SUCCESS,
    errorType: FETCH_POLL_BRANCH_LINKS_ERROR,
    route: `/meta/branch/branch_links/poll/${timePollToken}`,
    options: { method: 'GET' }
  })
}

export function deleteSUL({ id, onSuccessCallback }) {
  return fetchApi({
    requestType: FETCH_POLL_DELETE,
    successType: FETCH_POLL_DELETE_SUCCESS,
    errorType: FETCH_POLL_DELETE_FAILED,
    route: '/graphql',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `
          mutation {
            poll_delete (id: "${id}") {
              messages
              successful
            }
          }
        `
      })
    },
    onSuccessCallback
  })
}

export function list_update({
  eventId,
  list_params,
  list_options_params,
  onSuccessCallback,
  onFailCallback
}) {
  return fetchApi({
    requestType: FETCH_LIST_UPDATE,
    successType: FETCH_LIST_UPDATE_SUCCESS,
    errorType: FETCH_LIST_UPDATE_FAILED,
    route: '/graphql',
    payload: {
      eventId
    },
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `
          mutation {
            list_update(
              list_params: ${formatDoubleQuotes(list_params)},
              list_options_params: [${list_options_params.map(item => formatDoubleQuotes(item))}]
            ) {
              messages
              successful
              result {
                event_id
                id
                token
                name
                created_at
                timezone
                poll_type
                custom_creator_name
                created_at
                ends_at
                destroyed_at
                status
                email_require
                updated_at
                notes
                creator {
                  avatar {
                    small_url_2x
                  }
                  first_name
                  last_name
                  id
                }
                image_upload {
                  id
                  image_url
                }
                poll_options {
                  item_rank
                  ends_at
                  id
                  is_all_day
                  is_selected_by_creator
                  poll_id
                  selection_limit
                  count_limit
                  available_count
                  starts_at
                  description
                  item
                  poll_user_selections {
                    id
                    poll_option_id
                    count
                    custom_user_name
                    anonymous_user_id
                    user {
                      first_name
                      last_name
                      avatar {
                        small_url_2x
                      }
                    }
                    user_id
                  }
                }
              }
            }
          }
        `
      })
    },
    onSuccessCallback,
    onFailCallback
  })
}

export function updateSignUpUserSelections({
  poll_id,
  list_user_selections_params,
  anonymous_user_id,
  onSuccessCallback,
  onFailCallback
}) {
  return fetchApi({
    requestType: UPDATE_SIGN_UP_USER_SELECTIONS,
    successType: UPDATE_SIGN_UP_USER_SELECTIONS_SUCCESS,
    errorType: UPDATE_SIGN_UP_USER_SELECTIONS_FAILED,
    route: '/graphql',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `
            mutation {
                list_user_selections_update(
                  anonymous_user_id: "${anonymous_user_id || ''}",
                  poll_id: "${poll_id}",
                  list_user_selections_params: [${list_user_selections_params.map(item => {
          if (item.id) {
            return `{
                          id: "${item.id}",
                          poll_option_id: "${item.poll_option_id}",
                          user_id: "${item.user_id || ''}",
                          count: ${item.count},
                          note: "${item.note}",
                          anonymous_user_id: "${item.anonymous_user_id || ''}",
                          custom_user_name: "${item.custom_user_name || ''}"
                        }`
          }
          return `{
                        poll_option_id: "${item.poll_option_id}",
                        user_id: "${item.user_id || ''}",
                        count: ${item.count},
                        note: "${item.note}",
                        anonymous_user_id: "${item.anonymous_user_id || ''}",
                        custom_user_name: "${item.custom_user_name || ''}"
                      }`
        })}],
                ) {
                    messages
                    successful
                    result {
                      id
                      custom_user_name
                      poll_option_id
                      anonymous_user_id
                    }
                }
            }`
      })
    },
    onSuccessCallback,
    onFailCallback
  })
}

export function fetchSignUp({ token, onSuccessCallback }) {
  return fetchApi({
    requestType: FETCH_SIGN_UP,
    successType: FETCH_SIGN_UP_SUCCESS,
    errorType: SIGN_UP_NOT_FOUND,
    route: '/graphql',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `
        {
          list_via_token (token: "${token}") {
            event_id
            name
            id
            notes
            timezone
            poll_type
            custom_creator_name
            created_at
            ends_at
            destroyed_at
            status
            email_require
            token
            updated_at
            creator {
              app_user
              avatar {
                small_url_2x
                medium_url_2x
              }
              first_name
              last_name
              id
            }
            image_upload {
              id
              image_url
            }
            poll_options {
              item_rank
              ends_at
              id
              is_all_day
              is_selected_by_creator
              poll_id
              selection_limit
              count_limit
              available_count
              starts_at
              description
              item
              poll_user_selections {
                id
                destroyed_at
                poll_option_id
                count
                custom_user_name
                anonymous_user_id
                note
                user {
                  first_name
                  last_name
                  app_user
                  avatar {
                    small_url_2x
                    medium_url_2x
                  }
                }
                user_id
              }
            }
          }
        }`
      })
    },
    onSuccessCallback
  })
}

export function fetchTimePoll(timePollToken) {
  return fetchApi({
    requestType: FETCH_TIME_POLL,
    successType: FETCH_TIME_POLL_SUCCESS,
    errorType: TIME_POLL_NOT_FOUND,
    route: '/graphql',
    schema: {
      data: {
        poll_via_token: { Polls }
      }
    },
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `
        {
          poll_via_token (token: "${timePollToken}") {
            event_id
            name
            id
            notes
            timezone
            poll_type
            custom_creator_name
            created_at
            destroyed_at
            status
            token
            poll_options {
              ends_at
              id
              is_all_day
              is_selected_by_creator
              poll_id
              selection_limit
              starts_at
              poll_user_selections {
                id
                poll_option_id
                custom_user_name
                anonymous_user_id
                user {
                  first_name
                  last_name
                }
                user_id
              }
            }
          }
        }`
      })
    }
  })
}

export function updatePollUserSelection({ timePollToken, name, options, deleteIds, anonUser }) {
  return fetchApi({
    requestType: UPDATE_POLL_USER_SELECTIONS,
    successType: UPDATE_POLL_USER_SELECTIONS_SUCCESS,
    errorType: UPDATE_POLL_USER_SELECTIONS_FAILED,
    route: '/graphql',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `
            mutation {
                poll_user_selections_update(
                  poll_id: "${timePollToken}",
                  anonymous_user_id: "${anonUser}",
                    create_params: [${options.map(
          id => `{
                      poll_option_id: "${id}",
                      custom_user_name: "${name}"
                    }`
        )}],
                    delete_ids: [${deleteIds.map(id => `"${id}"`)}]
                ) {
                    messages
                    successful
                    result {
                      id
                      custom_user_name
                      poll_option_id
                      anonymous_user_id
                    }
                }
            }`
      })
    }
  })
}

export function subscribeToMailchimp({ name, email }) {
  return fetchApi({
    requestType: SUBSCRIBE_TO_MAILCHIMP,
    successType: SUBSCRIBE_TO_MAILCHIMP_SUCCESS,
    errorType: SUBSCRIBE_TO_MAILCHIMP_FAILED,
    route: '/graphql',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `
            mutation {
                mailchimp_subscribe(
                    user_name: "${name}",
                    email_address: "${email}"
                ) {
                    messages
                    successful
                }
            }`
      })
    }
  })
}

export function fetchPollList({ status, pollType, onSuccessCallback }) {
  return fetchApi({
    requestType: pollType === 'item' ? FETCH_SIGN_UP_LIST : FETCH_POLL_LIST,
    successType: pollType === 'item' ? FETCH_SIGN_UP_LIST_SUCCESS : FETCH_POLL_LIST_SUCCESS,
    errorType: pollType === 'item' ? FETCH_SIGN_UP_LIST_FAILED : FETCH_POLL_LIST_FAILED,
    route: '/graphql',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: pollListRequestQuery({ status, pollType })
      })
    },
    onSuccessCallback
  })
}

function pollListRequestQuery({ status, pollType }) {
  const timePollQuery = `mutation {
    poll_list(filter: "${status}", poll_type: "${pollType}") {
      result {
        id
        name
        custom_creator_name
        notes
        status
        created_at
        event_id
        creator {
          id
          first_name
          last_name
          app_user
          avatar {
            small_url_2x
            medium_url_2x
          }
        }
        poll_options {
          ends_at
          id
          is_all_day
          is_selected_by_creator
          poll_id
          starts_at
          poll_user_selections {
            id
            poll_option_id
            custom_user_name
            anonymous_user_id
            user_id
          }
        }
        token
      }
    }
  }`

  const signUpListQuery = `mutation {
    poll_list(filter: "${status}", poll_type: "${pollType}") {
      result {
        id
        name
        custom_creator_name
        notes
        status
        created_at
        event_id
        image_upload {
          image_url
        }
        creator {
          id
          first_name
          last_name
          app_user
          avatar {
            small_url_2x
            medium_url_2x
          }
        }
        poll_options {
          item_rank
          id
          count_limit
          description
          item
        }
        token
      }
    }
  }`

  return pollType === 'item' ? signUpListQuery : timePollQuery
}

export function pollCreate({ name, pollType, notes, dateStart, options }) {
  return fetchApi({
    requestType: CREATE_TIME_POLL,
    successType: CREATE_TIME_POLL_SUCCESS,
    errorType: CREATE_TIME_POLL_FAILED,
    route: '/graphql',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `
            mutation {
                poll_create(
                  poll_params: {
                  name: "${name}",
                  poll_type: "${pollType}",
                  notes: "${notes}",
                  starts_at: "${dateStart}",
                },
                  poll_options_params: [${options.map(id => `"${id}"`)}],
                ) {
                    result {
                        id
                        name
                        notes
                        apple_place {
                            id
                            latitude
                            longitude
                        }
                    }
                }
            }`
      })
    }
  })
}

export function fetchPollsById(pollsId) {
  return fetchApi({
    requestType: FETCH_POLL_BY_ID,
    successType: FETCH_POLL_BY_ID_SUCCESS,
    errorType: POLL_NOT_FOUND,
    route: '/graphql',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `{
          
          polls (ids: [${pollsId.map(pollId => `"${pollId.id}"`)}]) {
            id
            name
            custom_creator_name
            notes
            status,
            token,
            created_at
            apple_place {
              id
              latitude
              longitude
            }
          }
        }`
      })
    }
  })
}

export function showPhoneCodeField() {
  return { type: SHOW_PHONE_CODE_FIELD }
}

export function hidePhoneCodeField() {
  return { type: HIDE_PHONE_CODE_FIELD }
}

export function showEmailField() {
  return { type: SHOW_EMAIL_FIELD }
}

export function showPhoneField() {
  return { type: SHOW_PHONE_FIELD }
}

export function hidePhoneField() {
  return { type: HIDE_PHONE_FIELD }
}

export function isSubscribePollContainer() {
  return { type: IS_SUBSCRIBE_POLL_CONTAINER }
}

export function notInSubscribePollContainer() {
  return { type: NOT_IN_SUBSCRIBE_POLL_CONTAINER }
}

export function isLoginContainer() {
  return { type: IS_LOGIN_CONTAINER }
}

export function notIsLoginContainer() {
  return { type: NOT_IS_LOGIN_CONTAINER }
}

export function isUpdateSelections() {
  return { type: IS_UPDATE_SELECTIONS }
}

export function changePollTab(tab) {
  return { type: CHANGE_POLL_TAB, value: tab }
}

export function changeSignUpForm(params) {
  return { type: CHANGE_SIGN_UP_FORM, params }
}

export function resetSignUpForm(params) {
  return { type: RESET_SIGN_UP_FORM, params }
}
