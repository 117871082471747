import React from 'react'
import PropTypes from 'prop-types'

import alert from 'assets/icons/alert.svg'
import SvgIcon from 'components/common/SvgIcon'
import warning from 'assets/icons/warning.svg'

import styles from './alert.scss'

export default class Alert extends React.Component {
  getIcon () {
    const { type = 'warning' } = this.props
    if (type.includes('alert')) {
      return alert
    }
    return warning
  }

  render () {
    const { message, type = 'warning' } = this.props
    return (
      <div className={styles[type]}>
        <SvgIcon className={styles[type + '__icon']} icon={this.getIcon()} />
        <span className={styles[type + '__message']}>{message}</span>
      </div>
    )
  }
}

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string
}
