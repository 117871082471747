import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { chatSelector } from 'src/selectors'
import LoadingComponent from 'src/components/common/LoadingComponent/LoadingComponent'
import cn from 'classnames'
import Button from 'components/common/Button'
import { createSocket } from 'src/services/websocket'
import { changeChatStatus } from 'src/actions/eventChat'
import styles from './chat-loading.scss'

const ChatLoading = () => {
  const dispatch = useDispatch()
  const { socketInstance, chatStatus, channelName, isImageUploading } = useSelector(chatSelector)

  const renderLoadingContent = () => {
    let content = 'Connecting'

    if (isImageUploading) {
      content = 'Uploading Image'
    }

    if (chatStatus === 'disconnected') {
      const handleReconnect = () => {
        dispatch(changeChatStatus('connecting'))

        if (socketInstance) {
          socketInstance.connect()
        } else {
          createSocket({ dispatch, channelName })
        }
      }

      content = (
        <Button className={styles['reconnect-button']} onClick={handleReconnect}>
          RECONNECT
        </Button>
      )
    }

    return <LoadingComponent loading type="partial" light content={content} />
  }

  const isLoading = isImageUploading || chatStatus === 'connecting' || chatStatus === 'disconnected'
  const isChatError = chatStatus === 'connection_error'

  return (
    <div
      className={cn(
        styles['chat-loading'],
        !isChatError && !isLoading && styles['chat-loading-hidden']
      )}
    >
      {isLoading && renderLoadingContent()}

      {isChatError && (
        <div className={styles['error-mask']}>
          <div>Session Expired</div>
          <Button className={styles['reconnect-button']} onClick={() => location.reload()}>
            REFRESH PAGE
          </Button>
        </div>
      )}
    </div>
  )
}

export default ChatLoading
