import PropTypes from 'prop-types'
import React, { Component } from 'react'

import UserAvatarContainer from 'src/components/common/UserAvatarContainer'

import styles from './ascending-avatar.scss'

// NOTE: Uses absolute positioning. Needs to be in a position:relative container.
export default class AscendingAvatar extends Component {
  render () {
    const { size, ...otherProps } = this.props
    const containerInlineStyles = {
      marginTop: `-${size / 2}px`,
      maxWidth: `${size}px`
    }

    return <div className={styles['container']} style={containerInlineStyles}>
      <UserAvatarContainer
        size={size}
        {...otherProps}
      />
    </div>
  }
}

AscendingAvatar.propTypes = {
  size: PropTypes.number
}
