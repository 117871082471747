import React, { useEffect } from 'react'
import MembershipDetail from 'src/components/MenuBar/panels/MembershipDetail/MembershipDetail'
import styles from './membership.scss'
import { userSelector, planSelector } from 'src/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { getPlanTypeBySlug, PlanType } from 'src/services/stripe_checkout_configs'
import DashboardContainer from 'containers/DashboardContainer'
import { hideBanner, SHOW_BANNER } from 'src/actions/frontend'

import cn from 'classnames'

const planThemeColor = {
  [PlanType.Free]: '#f9f9f9',
  [PlanType.Premium]: styles['event-planner-theme-color'],
  [PlanType.Essentials]: styles['party-host-theme-color'],
  [PlanType.Basic]: styles['invitation-maker-theme-color'],
  [PlanType.InvitationMaker]: styles['invitation-maker-theme-color'],
  [PlanType.PartyHost]: styles['party-host-theme-color'],
  [PlanType.EventPlanner]: styles['event-planner-theme-color'],
  [PlanType.GroupOrganizer]: styles['group-organizer-theme-color']
}

const Membership = () => {
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const currentSub = user.current_subscription
  const { visible: isPlanModalVisible } = useSelector(planSelector)

  const planTypeName = getPlanTypeBySlug(currentSub.plan.slug)

  const insetMetaTag = () => {
    const metaTag = document.createElement('meta')

    metaTag.setAttribute('name', 'theme-color')
    metaTag.setAttribute('content', planThemeColor[planTypeName])
    document.head.appendChild(metaTag)
  }

  useEffect(() => {
    const planType = getPlanTypeBySlug(currentSub?.plan?.slug)
    const isFreePlan = planType === PlanType.Free

    if (isFreePlan) {
      window.location.reload()
    }
  }, [currentSub])

  useEffect(() => {
    if (isPlanModalVisible) {
      const metaTag = document.querySelector('meta[name="theme-color"]')
      document.head.removeChild(metaTag)

      // membership don't show banner, but planModal need to show banner
      if (!sessionStorage.getItem('hideBanner')) {
        dispatch({ type: SHOW_BANNER })
      }
    } else {
      insetMetaTag()
      dispatch(hideBanner())
    }
  }, [isPlanModalVisible])

  useEffect(() => {
    dispatch(hideBanner())

    return () => {
      const metaTag = document.querySelector('meta[name="theme-color"]')
      document.head.removeChild(metaTag)
      if (!sessionStorage.getItem('hideBanner')) {
        dispatch({ type: SHOW_BANNER })
      }
    }
  }, [])

  return (
    <DashboardContainer
      menuIcon
      whiteIcon={planTypeName !== 'free'}
      containerClassName={cn(styles['header-bar-inner'], styles[planTypeName])}
      title={<div className={styles['title']}>Membership</div>}
    >
      <div className={cn(styles['membership-container'], styles[planTypeName])}>
        <div className={styles['membership-panel']}>
          <MembershipDetail />
        </div>
      </div>
    </DashboardContainer>
  )
}

export default Membership
