import React from 'react'
import { PHONE_NUMBER_CONTACT_METHOD } from 'services/contact_methods'
import { isValidPhoneOrEmail } from 'services/utils'

import parsePhoneNumber, { AsYouType } from 'libphonenumber-js'

function withInputGuests(WrappedComponent) {
  class WithInputGuests extends React.Component {
    state = {
      first_name: '',
      last_name: '',
      phoneOrEmail: '',
      visibleErrorText: false
    }

    resetForm = () => {
      this.setState({
        first_name: '',
        last_name: '',
        phoneOrEmail: '',
        visibleErrorText: false
      })
    }

    handleInputPhoneOrEmail = value => {
      const inputValue = value.replace(/\u202D|\u202C/g, '')
      const numberReg = new RegExp('^[0-9]{10}$').test(inputValue)
      let phoneOrEmail = ''

      if (numberReg) {
        phoneOrEmail = new AsYouType('US').input(inputValue)
      } else {
        phoneOrEmail = inputValue.replace(/\(|\)|-|\s/g, '')
      }

      this.setState({
        phoneOrEmail,
        visibleErrorText: false
      })
    }

    handleInputChange = (name, value) => {
      if (name === 'phoneOrEmail') {
        this.handleInputPhoneOrEmail(value)
        return
      }
      this.setState({
        [name]: value
      })
    }

    handleGetGuestInfo = () => {
      let { first_name, last_name, phoneOrEmail } = this.state
      const contact_method_type = isValidPhoneOrEmail(phoneOrEmail)

      if (!contact_method_type) {
        this.setState({ visibleErrorText: true })
        return
      }

      let guestInformation = {
        key: Math.random(),
        first_name,
        last_name,
        contact_method_type
      }

      if (first_name === '' && last_name === '') {
        guestInformation.first_name = 'Guest'
        guestInformation.last_name = 'User'
      }

      if (contact_method_type === PHONE_NUMBER_CONTACT_METHOD) {
        const phoneNumber = parsePhoneNumber(phoneOrEmail, 'US')
        guestInformation.contact_method_display_name = phoneNumber
          .formatInternational()
          .replace(/\s|\+/g, '')
      } else {
        guestInformation.contact_method_display_name = phoneOrEmail
      }

      this.resetForm()

      return guestInformation
    }

    render() {
      return (
        <WrappedComponent
          {...this.state}
          onInputChange={this.handleInputChange}
          onGetGuestInfo={this.handleGetGuestInfo}
          onResetForm={this.resetForm}
        />
      )
    }
  }

  WithInputGuests.displayName = `WithInputGuests(${WrappedComponent.displayName})`

  return WithInputGuests
}

export default withInputGuests
