import { FlyerLayoutAbstract } from './flyer-layout.abstract'

export default class Flyer_layout_bottom_polaroid_013LayoutModel extends FlyerLayoutAbstract {
  flourishes = [
    {
      name: 'white_square_border',
      left: 0,
      top: 0,
      width: 320,
      height: 568
    }
  ]

  textLayouts = {
    event_name_1: {
      top: 404,
      left: 25,
      size: 34,
      width: 270,
      height: 34
    },
    location_1: {
      top: 457,
      size: 16,
      right: 25,
      align: 'right',
      width: 270,
      nowrap: true
    }
  }

  dateLayouts = {
    date_1: {
      top: 440,
      daySize: 16,
      right: 25,
      width: 270,
      height: 16,
      align: 'right'
    }
  }

  constructor(flyerDesign, eventData) {
    super(flyerDesign, eventData)
    this.init()
  }

  getTexts() {
    let texts = super.getTexts()
    const location1 = texts.find(t => t.slug === 'location_1')
    const location2 = texts.find(t => t.slug === 'location_2')
    if (location2?.text) {
      location1.text += `, ${location2.text}`
      texts = texts.filter(t => t.slug !== 'location_2')
    }
    return texts
  }

  getDateTexts() {
    const endDate = this.eventData.ends_at

    const { daySize, top, align, width, height, right } = this.dateLayouts.date_1

    const dateInformation = this.formatDates({
      day: 'dddd, MMMM D, h:mmA',
      time: 'h:mmA'
    })

    const { formattedStart, formattedEnd, isSameDay } = dateInformation
    let dates = []
    let textTop = top

    dates.push({
      text: formattedStart,
      align,
      width,
      top: textTop,
      height,
      right: endDate ? (isSameDay ? 70 : 160) : right,
      color: this.dateProps.date1Props.color.replace('0x', '#'),
      font: this.dateProps.date1Props.font.replace(/\s+/g, '-'),
      size: daySize
    })

    if (!endDate) {
      return { type: 'date', dates }
    }

    dates.push({
      text: ' - ' + formattedEnd,
      top: textTop,
      width,
      align,
      right,
      color: this.dateProps.date2Props.color.replace('0x', '#'),
      font: this.dateProps.date2Props.font.replace(/\s+/g, '-'),
      size: daySize
    })

    return { type: 'date', dates }
  }
}
