import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import classnames from 'classnames'
import { buildRedirectUrl } from 'services/redirect_helpers.js'
import { affiliatizeLinks } from 'services/impact_stat.js'
import styles from './event-link.scss'

const EventLink = ({ link, isMeetingLink, eventId }) => {
  const trackLinkClick = () => {
    if (window.mixpanel) {
      window.mixpanel.track('Join Meeting Clicked', { event_id: eventId })
    }
  }

  useEffect(() => {
    affiliatizeLinks()
  }, [window.impactStat])

  const renderThumbnail = () => {
    const containerClass = classnames(styles['thumbnail-container'], {
      [styles['thumbnail-container-meeting-link']]: isMeetingLink
    })

    return (
      <div className={containerClass}>
        <img src={link.image_url} />
      </div>
    )
  }

  const renderLinkWithDetails = () => (
    <div className={styles['link-with-details']}>
      {link.image_url ? renderThumbnail() : null}
      <div className={styles['details']}>
        <div className={styles['title']}>{link.title}</div>
        <div className={styles['details-link']}>{link.url}</div>
      </div>
    </div>
  )

  const renderPlainLink = () => <div className={styles['plain-link']}>{link.url}</div>

  const url = buildRedirectUrl(link.url)

  const containerClass = classnames(styles['container'], {
    [styles['container-meeting-link']]: isMeetingLink
  })

  return (
    <a className={containerClass} href={url} target="_blank" onClick={trackLinkClick}>
      {link.title ? renderLinkWithDetails() : renderPlainLink()}
    </a>
  )
}

EventLink.propTypes = {
  link: PropTypes.object,
  isMeetingLink: PropTypes.bool,
  eventId: PropTypes.string
}

export default EventLink
