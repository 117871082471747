import React from 'react'
import PendingImage from 'assets/checkout-payment-pending-image.png'
import Button from 'components/common/Button'
import styles from './pending-payment-result.scss'

const PendingPaymentResult = () => {
  return (
    <main className={styles['main-container']}>
      <img src={PendingImage} alt="checkout" />

      <section className={styles['section']}>
        <h1>Have you completed your payment?</h1>
        <p>Please let us know if you encountered any issues</p>
      </section>

      <a href="mailto:help@hobnob.app">
        <Button className={styles['btn-finished']}>I couldn’t complete payment</Button>
      </a>
    </main>
  )
}

export default PendingPaymentResult
