import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './image-box.scss'
import LoadingComponent from 'components/common/LoadingComponent/LoadingComponent'

const ImageBox = props => {
  const { imgSrc } = props
  const [isLoading, setIsLoading] = useState(true)
  const imgRef = useRef(null)

  useEffect(() => {
    let _isMounted = true
    const imgObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const IMG = new Image()
          let img = entry.target
          IMG.onload = () => {
            img.setAttribute('src', imgSrc)
            observer.unobserve(entry.target)
            if (_isMounted) {
              setIsLoading(false)
            }
          }
          IMG.src = imgSrc
        }
      })
    })

    imgObserver.observe(imgRef.current)

    return () => {
      _isMounted = false
      imgObserver.disconnect()
    }
  }, [])

  return (
    <div className={styles['img-container']}>
      <img ref={imgRef} alt="" className={styles['img-inner']} />
      {isLoading && (
        <div className={styles['loading-container']}>
          <LoadingComponent loading type="partial" dark />
        </div>
      )}
    </div>
  )
}

ImageBox.propTypes = {
  imgSrc: PropTypes.string.isRequired
}

export default ImageBox
