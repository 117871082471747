import React, { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DashboardContainer from 'src/containers/DashboardContainer'
import BusinessType from './BusinessType/BusinessType'
import BusinessPanel from './BusinessPanel/BusinessPanel'
import { routerActions } from 'react-router-redux'
import { showMessageModal } from 'src/actions/frontend'
import ActivityIconTransparent from 'src/assets/icons/ico-notification-transparent.png'
import { activityFeedSelector } from 'src/selectors'
import styles from './home-page.scss'

const HomePage = () => {
  const dispatch = useDispatch()
  const { isHasUnread } = useSelector(activityFeedSelector)
  const containerRef = useRef(null)

  const scrollToTop = () => {
    requestAnimationFrame(() =>
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    )
  }

  const handleMessageModal = () => {
    if (sessionStorage.getItem('openMessage')) {
      dispatch(showMessageModal())
      sessionStorage.removeItem('openMessage')
    }
  }

  useEffect(() => {
    handleMessageModal()

    return () => {
      sessionStorage.removeItem('openMessage')
    }
  }, [])

  const renderHeaderRight = () => {
    const handleClick = () => {
      console.log('Activity Icon Clicked')
      dispatch(routerActions.push('/activity-feed'))
    }

    return (
      <div className={styles['header-right']} onClick={handleClick}>
        <img src={ActivityIconTransparent} width={22} />
        {isHasUnread && <div className={styles['unread-dot']} />}
      </div>
    )
  }

  return (
    <DashboardContainer menuIcon right={renderHeaderRight()} onClickTitle={scrollToTop}>
      <div className={styles['home-page-container']} ref={containerRef}>
        <BusinessType />
        <BusinessPanel containerRef={containerRef} />
      </div>
    </DashboardContainer>
  )
}

export default HomePage
