import { fork, put, select, spawn, takeEvery, take } from 'redux-saga/effects'

import { fetchUser } from 'src/actions/user'

import { getMessageCount } from 'src/actions'

import { CHAT_RECEIVED } from 'src/actions/chat'

import { MESSAGE_REQUEST_SUCCESS } from 'src/actions/graphql2'

import { usersSelector } from 'src/selectors'

import chatUploadRootSaga from './upload'
import chatLocationRootSaga from './location'
import pusherChatRootSaga from './pusher_chat'

export default function * chatRootSaga() {
  yield spawn(chatUploadRootSaga)
  yield spawn(chatLocationRootSaga)
  yield spawn(pusherChatRootSaga)

  yield fork(watchChatReceived)
  yield spawn(watchMessageCountSuccess)
}

function * watchMessageCountSuccess() {
  while (true) {
    const action = yield take(MESSAGE_REQUEST_SUCCESS)
    const { result, entities } = action.response
    if (entities.message_container) {
      const messageContainer = entities.message_container[result.data.message_container]
      yield put(getMessageCount(messageContainer.message_count))
    }
  }
}

function * watchChatReceived() {
  yield takeEvery(CHAT_RECEIVED, chatReceivedWorker)
}

// When a chat is received check if we know who the user is
// If we don't then look them up
function * chatReceivedWorker(action) {
  const users = yield select(usersSelector)
  const commentUserId = action.comment.user
  const user = users[commentUserId]
  if (commentUserId && !user) {
    yield put(fetchUser(commentUserId))
  }
}
