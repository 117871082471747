import { connect } from 'react-redux'
import { eventSelector, activeInvitationsSelector, usersSelector } from 'src/selectors'
import { setInvitationsCount } from 'src/actions'
import { changeEventsModal, changeAddGuestsList } from 'src/actions/event'
import {
  invitations_large_bulk,
  invitations_activate,
  insertMockInvitation,
  updateInvitationDelivery
} from 'src/actions/invitations'
import {
  PurchaseWorkflowNode,
  setCheckoutModalOpen,
  setDefaultWorkflowNode,
  setRequiredCredits
} from 'src/actions/stripeCheckout'

import AddGuestsPage from './AddGuestsPage'

function mapDispatchToProps(dispatch) {
  return {
    onChangeAddGuestsList: params => dispatch(changeAddGuestsList(params)),
    onChangeEventsModal: tab => dispatch(changeEventsModal(tab)),
    onInsertMockInvitation: params => dispatch(insertMockInvitation(params)),
    onSaveInvitations: params => dispatch(invitations_large_bulk(params)),
    onSendInvitations: params => dispatch(invitations_activate(params)),
    onUpdateInvitationDelivery: params => dispatch(updateInvitationDelivery(params)),
    onSetInvitationsCount: params => dispatch(setInvitationsCount(params)),
    checkout: credits => {
      dispatch(setRequiredCredits(credits))
      dispatch(setDefaultWorkflowNode(PurchaseWorkflowNode.Init))
      dispatch(setCheckoutModalOpen(true))
    }
  }
}

function mapStateToProps(state) {
  return {
    event: eventSelector(state),
    eventId: state.eventId,
    addGuestsList: state.frontend.addGuestsList,
    activeInvitations: activeInvitationsSelector(state),
    users: usersSelector(state),
    userId: state.userId
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddGuestsPage)
