import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { locationShape } from 'react-router'
import { hasUserAccessToken } from 'src/services/access_tokens'
import { logout } from 'src/actions/login'
import GuestEvent from './GuestEvent/GuestEvent'
import MissingEvent from 'components/MissingEvent'
import LockedEvent from 'containers/event/EventDetail/LockedEvent/LockedEvent'
import LoadingScreen from 'components/LoadingScreen'
import { eventSelector, hostSelector, invitationSelector } from 'src/selectors'

const EventDetail = ({ location }) => {
  const dispatch = useDispatch()
  const invitation = useSelector(invitationSelector)
  const event = useSelector(eventSelector)
  const host = useSelector(hostSelector)
  const showLoadingScreen = useSelector(state => state.frontend.showLoadingScreen)
  const eventLoadingStatus = useSelector(state => state.frontend.eventLoadingStatus)
  const invitationLoadingStatus = useSelector(state => state.frontend.invitationLoadingStatus)

  useEffect(() => {
    return () => {
      if (!hasUserAccessToken() && invitation && eventLoadingStatus === 'success') {
        dispatch(logout())
      }
    }
  }, [])

  const showMissingEvent = () => {
    if (showLoadingScreen) {
      return false
    }

    const errorStatuses = ['error', 'destroyed', 'not_found']
    const eventError = errorStatuses.indexOf(eventLoadingStatus) > -1
    return eventError
  }

  const showLocked = () => event && !!event.locked_at

  const showEvent = () => {
    if (showLoadingScreen) {
      return false
    }

    const invitationLoaded = invitationLoadingStatus !== 'loading'
    return eventLoadingStatus === 'success' && invitationLoaded && event
  }

  if (showMissingEvent()) {
    return <MissingEvent host={host} eventLoadingStatus={eventLoadingStatus} />
  } else if (showLocked()) {
    return <LockedEvent />
  } else if (showEvent()) {
    return <GuestEvent location={location} />
  } else {
    return <LoadingScreen event={event} />
  }
}

EventDetail.propTypes = {
  location: locationShape
}

export default EventDetail
