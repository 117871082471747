import React, { useState } from 'react'
import { PlanType } from 'src/services/stripe_checkout_configs'
import PropTypes from 'prop-types'
import { CheckOutlined } from '@ant-design/icons'
import RightIcon from 'assets/icons/right-icon.png'
import cn from 'classnames'
import styles from './plan-description.scss'

const planDescriptions = period => {
  const description1 = (num, color) => (
    <div>
      <b style={{ color }}>{num}</b> Invite Credits{period === 'monthly' ? '' : '/month'} included
      for one-tap “Send” of multimedia flyers to your whole guest list
    </div>
  )
  const description2 = (
    <div>
      Unlock <b>ALL</b> Premium Designs + Custom Video & Photo Flyers
    </div>
  )
  const description3 = (
    <div>
      <b>UNLIMITED</b> Active Polls & Lists
    </div>
  )
  const description4 = <div>Automated RSVP reminders</div>
  const description5 = <div>Track Views and Delivery Time Stamps of your Smart Invites</div>
  const description6 = <div>Send Instant Event Updates</div>
  const description7 = <div>Sell tickets</div>
  const description8 = (num, color) => (
    <div>
      Share{' '}
      <b>
        up to <span style={{ color }}>{num}</span>
      </b>{' '}
      Event Videos to the Event Album
    </div>
  )

  return {
    [PlanType.InvitationMaker]: {
      title: 'For Hosts simply seeking stunning, customized video and photo flyers',
      themeColor: styles['invitation-maker-theme-color'],
      tick: (
        <CheckOutlined
          style={{ color: styles['invitation-maker-theme-color'], fontSize: '18px' }}
        />
      ),
      descriptions: [
        description1(10, styles['invitation-maker-theme-color'], period),
        description2,
        description3
      ]
    },
    [PlanType.PartyHost]: {
      title: 'For the savvy Host who wants ultimate efficiency and style',
      themeColor: styles['party-host-theme-color'],
      tick: <CheckOutlined style={{ color: styles['party-host-theme-color'], fontSize: '18px' }} />,
      descriptions: [
        description1(50, styles['party-host-theme-color'], period),
        description2,
        description3,
        description4,
        description5,
        description6,
        description7,
        description8(10, styles['party-host-theme-color'])
      ]
    },
    [PlanType.EventPlanner]: {
      title: 'For Hosts who want to effortlessly manage a larger event',
      themeColor: styles['event-planner-theme-color'],
      tick: (
        <CheckOutlined style={{ color: styles['event-planner-theme-color'], fontSize: '18px' }} />
      ),
      descriptions: [
        description1(150, styles['event-planner-theme-color'], period),
        description2,
        description3,
        description4,
        description5,
        description6,
        description7,
        description8(20, styles['event-planner-theme-color'])
      ]
    },
    [PlanType.GroupOrganizer]: {
      title: 'Best for Hosts responsible for organizing large or recurring events',
      themeColor: styles['group-organizer-theme-color'],
      tick: (
        <CheckOutlined style={{ color: styles['group-organizer-theme-color'], fontSize: '18px' }} />
      ),
      descriptions: [
        description1(350, styles['group-organizer-theme-color'], period),
        description2,
        description3,
        description4,
        description5,
        description6,
        description7,
        description8(30, styles['group-organizer-theme-color'])
      ]
    }
  }
}

const PlanDescription = props => {
  const { planType, selectedPlanPeriod } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const { title = '', tick, descriptions = [] } = planDescriptions(selectedPlanPeriod)[planType]

  const renderDescription = (desc, index) => {
    const A = planType === PlanType.InvitationMaker && index !== 0
    const B = planType !== PlanType.InvitationMaker
    const C = index !== 0 && index !== descriptions.length - 1

    const isHidden = !isExpanded && (A || (B && C))

    return (
      <li
        key={index}
        className={cn(styles['description-li'], isHidden && styles['description-li-hidden'])}
      >
        {tick}
        {desc}
      </li>
    )
  }

  const renderExpandButtonText = () => {
    const count = descriptions.length - (planType === PlanType.InvitationMaker ? 1 : 2)
    if (isExpanded) {
      return (
        <>
          Collapse <b>{count}</b> benefits&nbsp;
          <img src={RightIcon} className={styles['img-inner-expanded']} width={18} />
        </>
      )
    } else {
      return (
        <>
          Expand <b>{count}</b> more benefits&nbsp;
          <img src={RightIcon} className={styles['img-inner']} width={18} />
        </>
      )
    }
  }

  return (
    <div className={styles['plan-description-container']}>
      <h3>{title}</h3>
      <ul className={styles['description-ul']}>
        {descriptions.map((desc, index) => renderDescription(desc, index))}
      </ul>
      <div className={styles['expand-button']} onClick={() => setIsExpanded(!isExpanded)}>
        {renderExpandButtonText()}
      </div>
    </div>
  )
}

PlanDescription.propTypes = {
  planType: PropTypes.string,
  selectedPlanPeriod: PropTypes.string
}

export default PlanDescription
