import React from 'react'
import PropTypes from 'prop-types'
import { Elements } from 'react-stripe-elements'

import PaymentRequestButton from './PaymentRequestButton'

const fontUrl = process.env.CLOUD_TYPOGRAPHY_URL

class ApplePayFormContainer extends React.Component {
  render () {
    const { applePay, children, ...otherProps } = this.props
    return (
      <Elements
        fonts={[
          {
            cssSrc: fontUrl
          }
        ]}
      >
        <PaymentRequestButton onSubmit={applePay} {...otherProps}>
          {children}
        </PaymentRequestButton>
      </Elements>
    )
  }
}

ApplePayFormContainer.propTypes = {
  applePay: PropTypes.func,
  children: PropTypes.element
}

export default ApplePayFormContainer
