import { combineReducers } from 'redux'

import {
  SHOW_MESSAGE_MODAL,
  HIDE_MESSAGE_MODAL,
  SHOW_MESSAGE_SENT
} from 'src/actions/frontend'

const visible = (state = false, action) => {
  switch (action.type) {
    case SHOW_MESSAGE_MODAL:
      return true
    case HIDE_MESSAGE_MODAL:
      return false
    default:
      return state
  }
}

const messageHost = (state = false, action) => {
  switch (action.type) {
    case SHOW_MESSAGE_MODAL:
      return action.messageHost
    default:
      return state
  }
}

const messageTitle = (state = '', action) => {
  switch (action.type) {
    case SHOW_MESSAGE_MODAL:
    case SHOW_MESSAGE_SENT:
      return action.messageTitle
    default:
      return state
  }
}

const messageSubTitle = (state = '', action) => {
  switch (action.type) {
    case SHOW_MESSAGE_MODAL:
    case SHOW_MESSAGE_SENT:
      return action.messageSubTitle
    default:
      return state
  }
}

const showMessageSentDefault = false
const showMessageSent = (state = showMessageSentDefault, action) => {
  switch (action.type) {
    case SHOW_MESSAGE_MODAL:
      return !action.messageHost
    case SHOW_MESSAGE_SENT:
      return true
    case HIDE_MESSAGE_MODAL:
      return false
    default:
      return state
  }
}

export default combineReducers({
  visible,
  messageHost,
  messageTitle,
  messageSubTitle,
  showMessageSent
})
