import React from 'react'
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import PropTypes from 'prop-types'
import { locationShape } from 'react-router'

import MissingEvent from 'components/MissingEvent'
import LoadingScreen from 'components/LoadingScreen'
import TimePollInformation from './TimePollInformation/TimePollInformation'
import SubscribeContainer from 'containers/time-poll/panels/SubscribeContainer/SubscribeContainer'
import SuccessfulPanel from 'containers/time-poll/panels/SuccessfullyContainer'
import UpdatedPanel from 'containers/time-poll/panels/SelectionsUpdatedContainer'
import LoginPanel from 'containers/time-poll/panels/LoginContainer'
import DeletedPanel from 'containers/time-poll/panels/DeletedContainer'
import ClosedPanel from 'containers/time-poll/panels/ClosedContainer'
import EmailPanel from 'containers/time-poll/panels/EmailContainer'
import ThanksPanel from 'containers/time-poll/panels/ThanksContainer'
import OpenInAppModal from 'src/components/OpenInAppModal'
import { routerActions } from 'react-router-redux'

import {
  hostSelector,
  timePollRoutesSelector,
  eventTokenSelector,
  userSelector,
  isSubscribeContainerSelector,
  isUpdateSelectionsSelector,
  contactMethodsSelector,
  loginFormSelector
} from 'src/selectors'

import { DESTINATION_TYPES_CONSTANTS } from 'src/constants'
import {
  updatePollUserSelection,
  subscribeToMailchimp,
  hidePhoneCodeField,
  pollCreate,
  fetchTimePoll
} from 'src/actions/timepoll'
import { fetchCurrentUser } from 'src/actions/user'

class TimePollDetail extends React.Component {
  _showMissingTimePoll() {
    const { showLoadingScreen, timePollLoadingStatus, poll } = this.props

    if (showLoadingScreen) {
      return false
    }

    const errorStatuses = ['error', 'not_found']
    const timePollError = errorStatuses.indexOf(timePollLoadingStatus) > -1
    return timePollError && !poll
  }

  _showTimePoll() {
    const { poll, showLoadingScreen, timePollLoadingStatus } = this.props

    if (showLoadingScreen) {
      return false
    }

    return timePollLoadingStatus === 'success' && poll
  }

  _routerActiveKey() {
    const { timePollRoutes } = this.props
    let route = this.props.location.pathname
    if (route) {
      const routeMatch = route.match(/\//g)
      if (routeMatch && routeMatch.length > 1 && !route.endsWith('/')) {
        history.pushState({}, '', route + '/')
      }
      const routePath = route.match(/subscribe|successful|closed|deleted|login|updated/gi)
      route = routePath ? routePath[0] : route
    }
    if (timePollRoutes.indexOf(route) === -1) {
      return ''
    } else {
      return route
    }
  }

  _backToPoll(tab) {
    const { backToPoll } = this.props
    const { eventToken } = this.props
    let destinationUrl = `/tp/${eventToken}/`
    backToPoll(destinationUrl + tab)
  }

  _getCurComponent() {
    const { onChangeTab, poll, updateSelections } = this.props
    const activeKey = this._routerActiveKey()
    const newActiveKey = activeKey.toLowerCase().replace(/-./g, x => x[1].toUpperCase())

    const components = {
      subscribe: (
        <SubscribeContainer
          handleBackClick={() => onChangeTab()}
          onSubscribeToMailchimp={this.props.onSubscribeToMailchimp}
        />
      ),
      successful: <SuccessfulPanel handleBackClick={() => onChangeTab()} poll={poll} />,
      deleted: <DeletedPanel />,
      closed: <ClosedPanel />,
      login: <LoginPanel handleBackClick={() => this._backToPoll('')} />,
      updated: <UpdatedPanel poll={poll} handleBackClick={() => onChangeTab()} />,
      email: <EmailPanel handleBackClick={() => onChangeTab()} />,
      thanks: <ThanksPanel poll={poll} handleBackClick={() => onChangeTab()} />
    }
    let curComponent = components[newActiveKey]
    if (poll.status === 'closed') {
      return components.closed
    }
    if (poll.destroyed_at) {
      return components.deleted
    }
    if (this.props.updatingTimePollStatus === 'success' && !updateSelections) {
      if (this.props.user && this.props.loginForm.loginStep !== 'fullName') {
        if (
          this.props.contactMethods?.isThereAnEmail === false &&
          this.props.subscribeToMailchimpStatus !== 'success'
        ) {
          return components.email
        }
        if (
          this.props.contactMethods?.isThereAnEmail === false &&
          this.props.subscribeToMailchimpStatus === 'success'
        ) {
          return components.thanks
        }
        return components.successful
      } else {
        this._handleTimePollStorageUpdate()
        return components.subscribe
      }
    }
    if (this.props.updatingTimePollStatus === 'success' && updateSelections) {
      if (this.props.user && this.props.loginForm.loginStep !== 'fullName') {
        return components.updated
      } else {
        this._handleTimePollStorageUpdate()
      }
      return components.subscribe
    }
    return curComponent
  }

  _handleTimePollStorageUpdate() {
    const { user } = this.props
    if (this.props.timePollVotes.length > 0) {
      const { eventToken } = this.props
      const pollStorage = JSON.parse(localStorage.getItem('polls') || '{}')
      const timePollVotesFiltered = this.props.timePollVotes.filter(
        vote => vote.poll_option_id !== null
      )
      if (timePollVotesFiltered.length && !user) {
        pollStorage[eventToken] = {
          name: timePollVotesFiltered[0].custom_user_name,
          options: timePollVotesFiltered.map(({ poll_option_id }) => poll_option_id),
          votes: timePollVotesFiltered.map(({ id }) => id)
        }
        localStorage.setItem('polls', JSON.stringify(pollStorage))
      } else {
        localStorage.removeItem('polls')
      }
    }
  }

  componentDidMount() {
    const {
      params: { timePollToken: token },
      onGetTimePollDetails
    } = this.props
    onGetTimePollDetails(token)
  }

  render() {
    const {
      poll,
      host,
      timePollLoadingStatus,
      updatingTimePollStatus,
      onUpdatePollUserSelection,
      onClose
    } = this.props
    const isDesktop = window.innerWidth >= 1080

    if (this._showMissingTimePoll()) {
      return (
        <MissingEvent
          host={host}
          eventLoadingStatus={timePollLoadingStatus}
          type={DESTINATION_TYPES_CONSTANTS.timepoll}
        />
      )
    } else if (this._showTimePoll()) {
      return (
        this._getCurComponent() || (
          <div>
            <TimePollInformation
              onClose={onClose}
              onUpdatePollUserSelection={onUpdatePollUserSelection}
              updatingTimePollStatus={updatingTimePollStatus}
            />
            {!isDesktop && <OpenInAppModal />}
          </div>
        )
      )
    } else {
      return <LoadingScreen event={poll} />
    }
  }
}

TimePollDetail.propTypes = {
  poll: PropTypes.object,
  host: PropTypes.object,
  timePollRoutes: PropTypes.array,
  onChangeTab: PropTypes.func,
  showLoadingScreen: PropTypes.bool,
  timePollLoadingStatus: PropTypes.string.isRequired,
  location: locationShape,
  onUpdatePollUserSelection: PropTypes.func,
  updatingTimePollStatus: PropTypes.string,
  timePollVotes: PropTypes.array,
  eventToken: PropTypes.string,
  onSubscribeToMailchimp: PropTypes.func,
  subscribeToMailchimpStatus: PropTypes.string,
  onClose: PropTypes.func,
  user: PropTypes.object,
  backToPoll: PropTypes.func,
  updateSelections: PropTypes.bool,
  contactMethods: PropTypes.object,
  params: PropTypes.object,
  onGetTimePollDetails: PropTypes.func,
  loginForm: PropTypes.object
}

function mapStateToProps(state) {
  const user = userSelector(state)
  const subscribeContainer = isSubscribeContainerSelector(state)
  const updateSelections = isUpdateSelectionsSelector(state)
  const loginForm = loginFormSelector(state)
  return {
    poll: state.frontend.timePoll,
    host: hostSelector(state),
    timePollRoutes: timePollRoutesSelector(state),
    contactMethods: contactMethodsSelector(state),
    showLoadingScreen: state.frontend.showLoadingScreen,
    timePollLoadingStatus: state.frontend.timePollLoadingStatus,
    updatingTimePollStatus: state.frontend.updatingTimePollStatus,
    deletingTimePollStatus: state.frontend.deletingTimePollStatus,
    timePollVotes: state.frontend.votes,
    eventToken: eventTokenSelector(state),
    subscribeToMailchimpStatus: state.frontend.subscribeToMailchimpStatus,
    user,
    subscribeContainer,
    updateSelections,
    loginForm
  }
}

function mapDispatchToProps(dispatch) {
  const onHidePhoneCodeField = () => dispatch(hidePhoneCodeField())
  return {
    onChangeTab: () => {
      dispatch(fetchCurrentUser())
      window.location.reload()
      window.scrollTo(0, 0)
    },
    backToPoll: route => {
      dispatch(routerActions.push(route))
      dispatch(fetchCurrentUser())
      window.scrollTo(0, 0)
    },
    onHidePhoneCodeField,
    onUpdatePollUserSelection: params => dispatch(updatePollUserSelection(params)),
    onSubscribeToMailchimp: params => dispatch(subscribeToMailchimp(params)),
    onCreateTimePoll: params => dispatch(pollCreate(params)),
    onGetTimePollDetails: params => dispatch(fetchTimePoll(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(hot(TimePollDetail))
