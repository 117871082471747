import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import PreviewImageIcon from 'assets/icons/preview-image-icon.png'
import BackIcon from 'assets/icons/back-icon.png'
import LabelIcon from 'assets/icons/label-icon.png'
import BalloonIcon from 'assets/icons/balloon-icon.png'
import CloseIcon from 'assets/icons/close-icon-bg.png'
import StrokeIcon from 'assets/icons/stroke-icon.png'
import NotesIcon from 'assets/icons/notes-icon.png'
import TextInputElement from 'components/forms/TextInputElement'
import TextAreaElement from 'components/common/ResizeableTextarea'
import HobnobModal from 'components/common/HobnobModalV2/HobnobModal'
import EventList from '../../CreateSignUp/FirstStep/EventList/EventList'
import SecondStep from '../../CreateSignUp/SecondStep/SecondStep'

import { changeSignUpForm } from 'src/actions/timepoll'
import { connect } from 'react-redux'
import cn from 'classnames'

import styles from './input-information.scss'

const InputInformation = props => {
  const { userId, signUpForm, onChangeSignUpForm, goBack } = props
  const [imageUrl, setImageUrl] = useState(props.imageUrl)
  const [isEventModalVisible, setIsEventModalVisible] = useState(false)
  const [isImageModalVisible, setIsImageModalVisible] = useState(false)

  useEffect(() => {
    setImageUrl(props.imageUrl)
  }, [props.imageUrl])

  return (
    <>
      <div className={styles['information-container']}>
        <div className={styles['img-wrapper']}>
          <img className={styles['image-inner']} src={imageUrl} />

          <img
            src={PreviewImageIcon}
            onClick={() => setIsImageModalVisible(true)}
            className={styles['preview-icon']}
            width="40"
          />

          <img src={BackIcon} onClick={goBack} className={styles['back-icon']} width="40" />
        </div>

        <div className={styles['information-wrapper']}>
          <div className={styles['input-wrapper']}>
            <img src={LabelIcon} alt="" width="18" />
            <TextInputElement
              className={styles['input-inner']}
              placeholder="Name Your Sign Up List"
              onChange={e => onChangeSignUpForm({ name: e.target.value })}
              valid
              value={signUpForm.name}
            />
          </div>

          <div className={styles['information-label']}>OPTIONAL</div>

          {userId && (
            <div className={styles['input-wrapper']}>
              <img src={BalloonIcon} alt="" width="20" />
              <TextInputElement
                onClick={() => setIsEventModalVisible(true)}
                className={styles['input-inner']}
                placeholder="Event"
                onChange={() => {}}
                valid
                value={signUpForm.checkedEvent?.name}
                readOnly
              />
              {signUpForm.checkedEvent && (
                <img
                  src={CloseIcon}
                  width="20"
                  style={{ cursor: 'pointer' }}
                  onClick={() => onChangeSignUpForm({ checkedEvent: null, event_id: null })}
                />
              )}
            </div>
          )}

          <div className={styles['input-wrapper']}>
            <img src={StrokeIcon} alt="" width="20" />
            <TextInputElement
              className={styles['input-inner']}
              placeholder="Custom Creator Name"
              valid
              value={signUpForm.custom_creator_name}
              onChange={event => onChangeSignUpForm({ custom_creator_name: event.target.value })}
            />
          </div>

          <div className={cn(styles['input-wrapper'], styles['textArea-wrapper'])}>
            <img src={NotesIcon} alt="" width="22" />
            <TextAreaElement
              className={styles['textArea-inner']}
              placeholder="Notes"
              onChangeComment={value => onChangeSignUpForm({ notes: value })}
              comment={signUpForm.notes || ''}
            />
          </div>
        </div>
      </div>

      {userId && (
        <EventList
          selectedEventId={signUpForm.checkedEvent?.id}
          isEventModalVisible={isEventModalVisible}
          onClose={() => setIsEventModalVisible(false)}
        />
      )}

      <HobnobModal
        visible={isImageModalVisible}
        onCancel={() => setIsImageModalVisible(false)}
        fullModal
        closable={false}
      >
        <div className={styles['second-step-container']}>
          <SecondStep
            onChangeImage={url => setImageUrl(url)}
            onClose={() => setIsImageModalVisible(false)}
            type="edit"
          />
        </div>
      </HobnobModal>
    </>
  )
}

InputInformation.propTypes = {
  imageUrl: PropTypes.string,
  userId: PropTypes.string,
  signUpForm: PropTypes.object,
  onChangeSignUpForm: PropTypes.func,
  goBack: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
  onChangeSignUpForm: params => dispatch(changeSignUpForm(params))
})

const mapStateToProps = state => ({
  userId: state.userId,
  signUpForm: state.frontend.signUpForm
})

export default connect(mapStateToProps, mapDispatchToProps)(InputInformation)
