/* eslint-disable react/no-did-update-set-state */
import React from 'react'
import PropTypes from 'prop-types'
import styles from './hobnob-select.scss'
import HobnobInput from './HobnobInput'
import ClassNames from 'classnames'

import HobnobPicker from './HobnobPicker'

import TriangleDownIcon from '../../assets/icons/triangle-down-icon.svg'
import SvgIcon from './SvgIcon'

export default class HobnobSelect extends React.Component {
  state = {
    focus: false,
    inputValue: '',
    pickerValue: ''
  }
  componentDidMount() {
    const { options, value } = this.props
    const filterOption = options.filter(item => item.value === value)[0]
    const defaultValue = filterOption && filterOption.text ? filterOption.text : ''

    this.setState({
      inputValue: defaultValue,
      pickerValue: defaultValue
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.state.inputValue !== this.props.value) {
      const { options, value } = this.props
      const filterOption = options.filter(item => item.value === value)[0]
      const defaultValue = filterOption && filterOption.text ? filterOption.text : ''

      this.setState({
        inputValue: defaultValue,
        pickerValue: defaultValue
      })
    }
  }

  _onChange = e => {
    this.setState({
      inputValue: e.target.value
    })
  }

  _cancel = () => {
    this.setState({
      focus: !this.state.focus
    })
  }

  _ok = () => {
    const { options, onChange } = this.props
    const { pickerValue } = this.state
    const value = options.filter(item => item.value === pickerValue)[0].text
    this.setState({
      inputValue: value,
      focus: !this.state.focus
    })
    onChange(this.state.pickerValue)
  }

  _onPickerChange = value => {
    this.setState({
      pickerValue: value
    })
  }
  _onClickSelect = e => {
    e.stopPropagation()
    const { value, options } = this.props
    if (value) {
      this.setState({
        focus: !this.state.focus,
        pickerValue: value
      })
    } else {
      this.setState({
        focus: !this.state.focus,
        pickerValue: options[0].value
      })
    }
  }

  _onCloseModal = () => {
    this.setState({
      focus: !this.state.focus
    })
  }

  renderValueText = () => {
    const { textBlank, options } = this.props
    const { inputValue } = this.state
    if (textBlank) {
      const option = options.find(item => item.text === inputValue)

      if (option?.value) {
        return option.text
      } else {
        return ''
      }
    } else {
      return inputValue
    }
  }

  _renderPickerModal = () => {
    const { options, closeOnOuterClick } = this.props
    const { focus, pickerValue } = this.state

    return (
      <HobnobPicker
        show={focus}
        value={pickerValue}
        options={options}
        onPickerChange={this._onPickerChange}
        ok={this._ok}
        cancel={this._cancel}
        onClose={this._onCloseModal}
        closeOnOuterClick={closeOnOuterClick}
      />
    )
  }

  render() {
    const { placeholder } = this.props
    const { focus } = this.state
    const { className, inputClassName, theme, style, editable, suffixClassName } = this.props

    return (
      <div className={ClassNames(styles['select-wrapper'], className)} style={style}>
        <HobnobInput
          className={ClassNames(styles['select-input'], inputClassName)}
          inputClassName={styles['input']}
          value={this.renderValueText()}
          suffix={
            <SvgIcon
              icon={TriangleDownIcon}
              className={ClassNames(styles['select__caret'], styles['icon-arrow-up'], {
                [styles['is-reverse']]: focus
              })}
            />
          }
          editable={editable}
          suffixClassName={ClassNames(styles['suffix'], suffixClassName)}
          readOnly="readonly"
          placeholder={placeholder}
          theme={theme}
          onChange={this._onChange}
          onClick={this._onClickSelect}
        />
        {this._renderPickerModal()}
      </div>
    )
  }
}

HobnobSelect.defaultProps = {
  theme: 'light'
}

HobnobSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  theme: PropTypes.string,
  style: PropTypes.object,
  editable: PropTypes.bool,
  suffixClassName: PropTypes.string,
  closeOnOuterClick: PropTypes.bool,
  textBlank: PropTypes.bool,
  placeholder: PropTypes.string
}
