import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe } from 'react-stripe-elements'

import SvgIcon from 'src/components/common/SvgIcon'
import Button from 'src/components/common/Button'
import CardIcon from 'src/assets/icons/card_icon.svg'
import OverlayLoadingIndicator from 'components/common/OverlayLoadingIndicator'

import styles from './payment-method-form.scss'

import { createPaymentMethodLoadingSelector, userSelector } from '../../selectors'
import { fullName } from 'src/services/user_helpers'

const fontFamily = `"Gotham SSm A", "Gotham SSm B"`

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize: '13px',
        color: '#303030',
        fontFamily: fontFamily,
        '::placeholder': {
          color: '#9099A7'
        },
        ...(padding ? { padding } : {})
      },
      invalid: {
        color: '#FF3E57'
      }
    }
  }
}

class PaymentMethodForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      cardNumberInvalid: false,
      cardExpiryInvalid: false,
      cardCVCInvalid: false
    }
  }

 debounce = (func, wait, immediate) => {
   let timeout, result

   return function (e) {
     let context = this
     let args = arguments

     if (timeout) clearTimeout(timeout)
     e.persist && e.persist()
     if (immediate) {
       let callNow = !timeout
       timeout = setTimeout(function () {
         timeout = null
       }, wait)
       if (callNow) result = func.apply(context, args)
     } else {
       timeout = setTimeout(function () {
         result = func.apply(context, args)
       }, wait)
     }

     return result
   }
 }

  handleSubmit = (ev) => {
    const { onSubmit, stripe, currentUser } = this.props
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault()
    if (!stripe) {
      return false
    }

    const full_Name = currentUser ? fullName(currentUser) : ''
    // See our tokens documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-create-token
    this.props.stripe.createToken({ type: 'card', name: full_Name }).then((result) => {
      if (!result.error) {
        // Send the token to your server.
        onSubmit(result.token.id)
      }
    })
  }

  _handleSubmit = this.debounce(this.handleSubmit, 1000, true)

  _onCardNumberChange = (e) => {
    this.setState({
      cardNumberInvalid: e.complete
    })
  }

  _onCardExpiryChange = (e) => {
    this.setState({
      cardExpiryInvalid: e.complete
    })
  }

  _onCardCVCChange = (e) => {
    this.setState({
      cardCVCInvalid: e.complete
    })
  }

  render () {
    const { createPaymentMethodLoading } = this.props
    const { cardNumberInvalid, cardExpiryInvalid, cardCVCInvalid } = this.state
    const validConfirm = cardNumberInvalid && cardExpiryInvalid && cardCVCInvalid
    return (
      <form onSubmit={this._handleSubmit} className={styles['wrapper']}>
        <div className={styles['card-number-wrapper']}>
          <SvgIcon icon={CardIcon} className={styles['card-icon']} />
          <CardNumberElement
            className={styles['card-number']}
            {...createOptions()}
            placeholder={'Card Number'}
            onChange={this._onCardNumberChange}
          />
        </div>
        <div className={styles['expiry-cvc-wrapper']}>
          <CardExpiryElement {...createOptions()} placeholder={'MM/YY'} onChange={this._onCardExpiryChange} />
          <CardCVCElement {...createOptions()} onChange={this._onCardCVCChange} />
        </div>
        <Button disabled={!validConfirm}>submit payment</Button>
        <OverlayLoadingIndicator loading={createPaymentMethodLoading} light />
      </form>
    )
  }
}

PaymentMethodForm.propTypes = {
  stripe: PropTypes.any,
  onSubmit: PropTypes.func,
  currentUser: PropTypes.object,
  createPaymentMethodLoading: PropTypes.bool
}

function mapStateToProps (state) {
  return {
    currentUser: userSelector(state),
    createPaymentMethodLoading: createPaymentMethodLoadingSelector(state)
  }
}

export default injectStripe(connect(mapStateToProps)(PaymentMethodForm))
