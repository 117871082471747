import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { loginFormSelector, userSelector } from 'src/selectors'
import { updateLoginForm } from 'src/actions/login'

import TextInputElement from 'components/forms/TextInputElement'
import cn from 'classnames'
import styles from './full-name-input-inner.scss'

const FullNameInputInner = props => {
  const { inline } = props
  const loginForm = useSelector(loginFormSelector)
  const user = useSelector(userSelector)

  const dispatch = useDispatch()

  const setFirstName = event => {
    dispatch(updateLoginForm({ firstName: event.target.value }))
  }

  const setLastName = event => {
    dispatch(updateLoginForm({ lastName: event.target.value }))
  }

  useEffect(() => {
    if (user) {
      const { first_name, last_name } = user
      if ((!loginForm.newUserId && first_name !== 'Hobnob User')) {
        dispatch(
          updateLoginForm({
            firstName: first_name,
            lastName: last_name
          })
        )
      }
    }
  }, [user, loginForm.loginInRSVP])

  return (
    <div
      className={cn(
        styles['full-name-inner-container'],
        inline && styles['full-name-inner-container-inline']
      )}
    >
      <TextInputElement
        className={styles['input-inner']}
        onChange={setFirstName}
        placeholder="First Name"
        valid
        value={loginForm.firstName}
      />
      <TextInputElement
        className={styles['input-inner']}
        placeholder="Last Name"
        value={loginForm.lastName}
        valid
        onChange={setLastName}
      />
    </div>
  )
}

FullNameInputInner.propTypes = {
  inline: PropTypes.bool
}

export default FullNameInputInner
