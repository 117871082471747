import merge from 'lodash/merge'
import { combineReducers } from 'redux'
import omit from 'lodash/omit'

import { CommentStatus } from 'src/services/comment_helpers'

import { PUSHER_EVENT_RECEIVED, SET_NOTIFICATION_PREFERENCE } from 'src/actions'

import {
  ADD_TEMP_CHAT_COMMENT,
  CHAT_CREATE_COMMENT_SUCCESS,
  CHAT_CREATE_COMMENT_ERROR,
  CHAT_DELETE_COMMENT_SUCCESS,
  UPDATE_TEMP_CHAT_COMMENT_STATUS,
  REMOVE_TEMP_CHAT_COMMENT
} from 'src/actions/chat'

import {
  ACCEPT_INVITATION,
  ACCEPT_INVITATION_ERROR,
  DECLINE_INVITATION,
  DECLINE_INVITATION_ERROR,
  UPDATE_INVITATION_SUCCESS,
  DELETE_INVITATION_SUCCESS,
  INVITATIONS_ACTIVATE_SUCCESS,
  INSERT_MOCK_INVITATION,
  UPDATE_INVITATION_DELIVERY
} from 'src/actions/invitations'

import {
  CREATE_REMOTE_MEDIUM,
  CREATE_REMOTE_MEDIUM_SUCCESS,
  CREATE_MEDIUM_LIKE,
  CREATE_MEDIUM_LIKE_SUCCESS,
  CREATE_MEDIUM_LIKE_ERROR,
  DESTROY_MEDIUM_LIKE,
  DESTROY_MEDIUM_LIKE_SUCCESS,
  DESTROY_MEDIUM_LIKE_ERROR,
  CREATE_MEDIUM_FLAG,
  CREATE_MEDIUM_FLAG_SUCCESS,
  DESTROY_MEDIUM_FLAG,
  DESTROY_MEDIUM,
  DESTROY_MEDIUM_ERROR,
  FETCH_LIKERS_SUCCESS,
  UPLOAD_MEDIUM_PROGRESS,
  UPLOAD_MEDIUM_SUCCESS,
  UPLOAD_MEDIUM_ERROR,
  SUBMIT_MEDIUM_COMMENT
} from '../actions/media'

import { SEND_UPDATE_EVENT_SUCCESS, CACHE_EVENT } from 'src/actions/event'
import { FETCH_LIST_UPDATE_SUCCESS } from 'src/actions/timepoll'

const mergeReducer =
  key =>
  (state = {}, action) => {
    if (action.response && action.response.entities && action.response.entities[key]) {
      return merge({}, state, action.response.entities[key])
    }

    return state
  }

const invitations = (state = {}, action) => {
  switch (action.type) {
    // TODO: break out entities into their own reducers
    case SET_NOTIFICATION_PREFERENCE:
      return {
        ...state,
        [action.invitationId]: {
          ...state[action.invitationId],
          notification_preference: action.value
        }
      }
    case ACCEPT_INVITATION:
      return {
        ...state,
        [action.payload.invitation.id]: {
          ...state[action.payload.invitation.id],
          rsvp_state: 'accepted'
        }
      }

    case DECLINE_INVITATION:
      return {
        ...state,
        [action.payload.invitation.id]: {
          ...state[action.payload.invitation.id],
          rsvp_state: 'declined'
        }
      }

    case ACCEPT_INVITATION_ERROR:
    case DECLINE_INVITATION_ERROR: {
      let originalInvitation = action.originalAction.payload.invitation
      if (action.response && action.response.result.invitation) {
        // A more up-to-date entity was returned from the response so use it
        return mergeReducer('invitations')(state, action)
      } else {
        return {
          ...state,
          [originalInvitation.id]: {
            ...state[originalInvitation.id],
            rsvp_state: action.originalAction.payload.invitation.rsvp_state
          }
        }
      }
    }

    case UPDATE_INVITATION_SUCCESS:
    case DELETE_INVITATION_SUCCESS:
      return {
        ...state,
        [action.response.result.invitation]: {
          ...action.response.entities.invitations[action.response.result.invitation]
        }
      }

    case INSERT_MOCK_INVITATION:
      return {
        ...state,
        ...action.value
      }
    case UPDATE_INVITATION_DELIVERY:
      const invitations = { ...state }
      for (let i of action.value) {
        invitations[i] = { ...invitations[i], delivery_state: 'queued' }
      }
      return invitations
    default:
      return mergeReducer('invitations')(state, action)
  }
}

const media_comments = (state = {}, action) => {
  switch (action.type) {
    case SUBMIT_MEDIUM_COMMENT:
      return {
        ...state,
        [action.payload.comment.id]: {
          ...action.payload.comment
        }
      }

    default:
      return mergeReducer('media_comments')(state, action)
  }
}

const likes = (state = {}, action) => {
  switch (action.type) {
    case CREATE_MEDIUM_LIKE:
      return {
        ...state,
        [action.payload.like.id]: {
          ...action.payload.like,
          request_pending: true
        }
      }
    case CREATE_MEDIUM_LIKE_SUCCESS:
    case CREATE_MEDIUM_LIKE_ERROR: {
      const oldTempLikeId = action.originalAction.payload.like.id
      const nextState = omit(state, oldTempLikeId)
      return mergeReducer('likes')(nextState, action)
    }

    case DESTROY_MEDIUM_LIKE: {
      const likeId = action.payload.like.id
      return {
        ...state,
        [likeId]: {
          ...state[likeId],
          destroyed_at: new Date().toISOString(),
          request_pending: true
        }
      }
    }
    case DESTROY_MEDIUM_LIKE_ERROR: {
      const likeId = action.originalAction.payload.like.id
      return {
        ...state,
        [likeId]: {
          ...state[likeId],
          destroyed_at: null,
          request_pending: false
        }
      }
    }
    case DESTROY_MEDIUM_LIKE_SUCCESS: {
      const likeId = action.originalAction.payload.like.id
      const nextState = {
        ...state,
        [likeId]: {
          ...state[likeId],
          request_pending: false
        }
      }
      return mergeReducer('likes')(nextState, action)
    }

    default:
      return mergeReducer('likes')(state, action)
  }
}

const event_comments = (state = {}, action) => {
  switch (action.type) {
    case ADD_TEMP_CHAT_COMMENT:
      return {
        ...state,
        [action.comment.id]: { ...action.comment }
      }
    case UPDATE_TEMP_CHAT_COMMENT_STATUS: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          localStatus: action.status
        }
      }
    }
    case REMOVE_TEMP_CHAT_COMMENT:
      return omit(state, action.id)
    case CHAT_CREATE_COMMENT_SUCCESS: {
      // We need to replace the locally created comment
      // We don't want to use the PUT endpoint because CORS would require us to make a OPTIONS call each time
      const localComment = state[action.originalAction.payload.comment.id]
      if (localComment) {
        const nextState = omit(state, localComment.id)
        const remoteId = action.response.result.comment
        const nextNextState = mergeReducer('event_comments')(nextState, action)
        return {
          ...nextNextState,
          [remoteId]: {
            ...nextNextState[remoteId],
            localId: localComment.localId
          }
        }
      } else {
        return mergeReducer('event_comments')(state, action)
      }
    }
    case CHAT_CREATE_COMMENT_ERROR: {
      const localComment = state[action.originalAction.payload.comment.id]
      return {
        ...state,
        [localComment.id]: {
          ...state[localComment.id],
          created_at: new Date().toISOString(),
          localStatus: CommentStatus.ERROR
        }
      }
    }
    case CHAT_DELETE_COMMENT_SUCCESS:
      return omit(state, action.originalAction.payload.comment.id)
    case PUSHER_EVENT_RECEIVED:
      if (action.payload.eventType === 'comment-destroyed') {
        return omit(state, action.response.comment.id)
      } else {
        return mergeReducer('event_comments')(state, action)
      }
    default:
      return mergeReducer('event_comments')(state, action)
  }
}

const media = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LIKERS_SUCCESS: {
      const mediumId = action.originalAction.payload.mediumId
      return {
        ...state,
        [mediumId]: {
          ...state[mediumId],
          has_fetched_likes: state[mediumId].has_fetched_likes || !action.apiPayload.hasMoreEntities
        }
      }
    }

    case CREATE_MEDIUM_LIKE: {
      const likeable_id = action.payload.like.likeable_id
      return {
        ...state,
        [likeable_id]: {
          ...state[likeable_id],
          likes_count: state[likeable_id].likes_count + 1,
          like: action.payload.like.id,
          has_fetched_likes: state[likeable_id].likes_count === 0
        }
      }
    }

    case CREATE_MEDIUM_LIKE_SUCCESS: {
      const mediumId = action.originalAction.payload.like.likeable_id

      return {
        ...state,
        [mediumId]: {
          ...state[mediumId],
          like: action.response.result.like
        }
      }
    }

    case CREATE_MEDIUM_LIKE_ERROR: {
      const mediumId = action.originalAction.payload.like.likeable_id

      return {
        ...state,
        [mediumId]: {
          ...state[mediumId],
          likes_count: state[mediumId].likes_count - 1,
          like: null
        }
      }
    }

    case DESTROY_MEDIUM_LIKE: {
      const mediumId = action.payload.like.likeable_id
      return {
        ...state,
        [mediumId]: {
          ...state[mediumId],
          likes_count: state[mediumId].likes_count - 1
        }
      }
    }

    case DESTROY_MEDIUM_LIKE_ERROR: {
      const mediumId = action.originalAction.payload.like.likeable_id

      return {
        ...state,
        [mediumId]: {
          ...state[mediumId],
          likes_count: state[mediumId].likes_count + 1
        }
      }
    }

    case PUSHER_EVENT_RECEIVED: {
      const allowedTypes = ['comment-created', 'comment-destroyed']
      if (
        allowedTypes.indexOf(action.payload.eventType) > -1 &&
        action.response.entities &&
        action.response.entities.media_comments
      ) {
        const comment = action.response.entities.media_comments[action.response.result.comment]
        const mediaId = comment.commentable_id
        const additionalCount = action.payload.eventType === 'comment-created' ? 1 : -1
        return {
          ...state,
          [mediaId]: {
            ...state[mediaId],
            comments_count: state[mediaId].comments_count + additionalCount
          }
        }
      } else {
        return mergeReducer('media')(state, action)
      }
    }

    case CREATE_MEDIUM_FLAG: {
      const mediumId = action.payload.medium.id
      return {
        ...state,
        [mediumId]: {
          ...state[mediumId],
          flag: true
        }
      }
    }

    case CREATE_MEDIUM_FLAG_SUCCESS: {
      const flagId = action.response.result.flag
      const mediumId = action.originalAction.payload.medium.id

      return {
        ...state,
        [mediumId]: {
          ...state[mediumId],
          flag: flagId
        }
      }
    }

    case DESTROY_MEDIUM_FLAG: {
      const mediumId = action.payload.medium.id

      return {
        ...state,
        [mediumId]: {
          ...state[mediumId],
          flag: null
        }
      }
    }

    case CREATE_REMOTE_MEDIUM: {
      return {
        ...state,
        [action.payload.medium.id]: {
          ...action.payload.medium
        }
      }
    }

    case CREATE_REMOTE_MEDIUM_SUCCESS: {
      const localMedium = state[action.originalAction.payload.medium.id]
      return {
        ...state,
        [localMedium.id]: {
          ...action.originalAction.payload.medium,
          file_state: localMedium.file_state
        }
      }
    }

    case DESTROY_MEDIUM: {
      const medium = action.payload.medium
      return {
        ...state,
        [medium.id]: {
          ...state[medium.id],
          destroyed_at: new Date().toString()
        }
      }
    }

    case DESTROY_MEDIUM_ERROR: {
      const medium = action.originalAction.payload.medium
      return {
        ...state,
        [medium.id]: medium
      }
    }

    case UPLOAD_MEDIUM_PROGRESS:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          percent_uploaded: action.percentUploaded
        }
      }

    case UPLOAD_MEDIUM_SUCCESS:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          loading: false
        }
      }
    case UPLOAD_MEDIUM_ERROR:
      return omit(state, action.id)

    default:
      return mergeReducer('media')(state, action)
  }
}

// If a user is verified we want to use their user name
// If a user is NOT verified we want to use their invitation name
const namesByUserId = (state = {}, action) => {
  if (action.response && action.response.entities) {
    if (action.response.entities.users && action.response.entities.invitations) {
      let nextState = {}
      const { users, invitations } = action.response.entities
      const invitationsByUserId = Object.values(invitations)
        .filter(invitation => !invitation.destroyed_at)
        .reduce((groupedInvitations, invitation) => {
          groupedInvitations[invitation.guest] = invitation

          return groupedInvitations
        }, {})

      nextState = Object.values(users).reduce((names, user) => {
        if (user.verified) {
          names[user.id] = {
            first_name: user.first_name,
            last_name: user.last_name,
            is_masked: false,
            display_name: null
          }
        } else {
          const invitation = invitationsByUserId[user.id]
          if (invitation && !invitation.destroyed_at) {
            names[user.id] = {
              first_name: invitation.first_name,
              last_name: invitation.last_name,
              display_name: invitation.display_name,
              is_masked: invitation.is_masked
            }
          }
        }

        return names
      }, nextState)

      return merge({}, state, nextState)
    }
  }

  return state
}

const events = (state = {}, action) => {
  switch (action.type) {
    case INVITATIONS_ACTIVATE_SUCCESS:
      return {
        ...state,
        [action.payload.eventId]: {
          ...state[action.payload.eventId],
          state: 'active'
        }
      }
    case FETCH_LIST_UPDATE_SUCCESS:
      const eventId = action.payload.eventId
      if (eventId) {
        const sul = action.response.data.list_update.result
        let lists = state[eventId].lists
        if (sul.event_id) {
          lists = [...state[eventId].lists, sul]
        } else {
          lists = lists.filter(item => item.id !== sul.id)
        }
        return {
          ...state,
          [eventId]: {
            ...state[eventId],
            lists
          }
        }
      }
      return state
    case CACHE_EVENT:
      if (typeof action.event === 'string') {
        return {
          ...state,
          [action.event]: null
        }
      } else {
        return {
          ...state,
          [action.event.id]: action.event
        }
      }
    default:
      return mergeReducer('events')(state, action)
  }
}

const event_updates = (state = {}, action) => {
  switch (action.type) {
    case SEND_UPDATE_EVENT_SUCCESS:
      const eventUpdateId = action.response.result.event_update
      return {
        ...state,
        [eventUpdateId]: action.response.entities.event_updates[eventUpdateId]
      }
    default:
      return mergeReducer('event_updates')(state, action)
  }
}

const users = (state = {}, action) => {
  switch (action.type) {
    default:
      return mergeReducer('users')(state, action)
  }
}

const entities = combineReducers({
  invitations,
  event_comments,
  media_comments,
  media,
  likes,
  flags: mergeReducer('flags'),
  users,
  event_updates,
  events,
  polls: mergeReducer('polls'),
  links: mergeReducer('links'),
  namesByUserId
})

export default entities
