import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CloseIcon from 'assets/icons/close-icon.png'
import DatePickerIcon from 'assets/icons/date-picker-icon.png'
import PropTypes from 'prop-types'
import { userSelector, eventFormSelector } from 'src/selectors'
import { Switch } from 'antd'
import TextAreaElement from 'components/common/ResizeableTextarea'
import DateSelector from '../FirstStep/DateModal/DateSelector/DateSelector'
import { changeEventForm } from 'src/actions/event'
import moment from 'moment'
import cn from 'classnames'
import { PlanType, getPlanBySlug } from 'src/services/stripe_checkout_configs'
import { changeVisible, setContent } from 'src/actions/globalLayer'
import { UPGRADE_PLAN_SOURCE } from 'src/constants'
import { LockOutlined } from '@ant-design/icons'
import 'assets/styles/date-range/default.css'
import 'assets/styles/date-range/styles.css'

import styles from './event-setting.scss'

const EventSetting = ({ onClose }) => {
  const dispatch = useDispatch()
  const eventForm = useSelector(eventFormSelector)
  const user = useSelector(userSelector)

  const [isCalendarVisible, setIsCalendarVisible] = useState(false)
  const planType = getPlanBySlug(user?.current_subscription?.plan?.slug, 'planType')
  const isFreePlan = planType === PlanType.Free

  const handleChange = (type, value) => {
    switch (type) {
      case 'custom_invitation_text_enabled':
        if (isFreePlan) {
          dispatch(setContent(UPGRADE_PLAN_SOURCE.customMessage))
          dispatch(changeVisible(true))
          return
        }

        const invitationText = `${user.first_name} ${user.last_name} invited you to ${eventForm.name}`
        dispatch(changeEventForm({ custom_invitation_text: value ? invitationText : '' }))
        break
      case 'guest_count_enabled':
        dispatch(
          changeEventForm({
            [type]: value,
            child_count_enabled: value ? eventForm.child_count_enabled : false
          })
        )
        break
      case 'cutoff_date_enabled':
        dispatch(
          changeEventForm({
            cutoff_date: value ? moment().add(3, 'day').hour(0).minute(0).toJSON() : null
          })
        )
        break
      case 'cutoff_date':
        let { hour, minute } = value.time
        const formatDate = moment(value.date).format('YYYY-MM-DD')

        if (value.time.type === 'AM') {
          hour = hour === '12' ? '0' : hour
        } else {
          hour = hour === '12' ? hour : Number(hour) + 12
        }

        dispatch(
          changeEventForm({
            cutoff_date: moment(`${formatDate} ${hour}:${minute}`, moment.defaultFormat).toJSON()
          })
        )
        setIsCalendarVisible(false)
        break
      case 'max_capacity':
      case 'limit_per_invitation':
        const isZero = value === '0' || !value

        dispatch(changeEventForm({ [type]: isZero ? 9999 : Number(value) }))
        break
      default:
        dispatch(changeEventForm({ [type]: value }))
    }
  }

  return (
    <div className={styles['setting-container']}>
      <div className={styles['over-wrapper']} />

      <div className={styles['setting-wrapper']}>
        <div className={styles['setting-header']}>
          <span>settings</span>
          <img src={CloseIcon} width="18" onClick={onClose} />
        </div>

        <div className={styles['option-wrapper']}>
          <div className={styles['option-title']}>OPTIONS</div>

          <div className={cn(styles['option-item'], styles['border-none'])}>
            <div className={styles['label-wrapper']}>
              <div className={styles['label-text']}>
                Custom Invite Message
                {isFreePlan && <LockOutlined style={{ color: '#2cd4ae', marginLeft: 10 }} />}
              </div>
              <div className={styles['label-explain']}>
                Include a custom message with your invite
              </div>
            </div>
            <Switch
              onChange={checked => handleChange('custom_invitation_text_enabled', checked)}
              checked={!!eventForm.custom_invitation_text}
              disabled={!user?.id}
            />
          </div>

          {!!eventForm.custom_invitation_text && (
            <div className={cn(styles['input-wrapper'], styles['textArea-wrapper'])}>
              <TextAreaElement
                className={styles['textArea-inner']}
                onChangeComment={value => handleChange('custom_invitation_text', value)}
                comment={eventForm.custom_invitation_text}
              />
            </div>
          )}

          <div className={styles['option-item']}>
            <div className={styles['label-wrapper']}>
              <div className={styles['label-text']}>Show Guest List</div>
              <div className={styles['label-explain']}>
                All guests will be able to view the guest list
              </div>
            </div>
            <Switch
              onChange={checked => handleChange('guest_list_enabled', checked)}
              checked={eventForm.guest_list_enabled}
            />
          </div>

          <div className={styles['option-item']}>
            <div className={styles['label-wrapper']}>
              <div className={styles['label-text']}>Set Max Capacity for Guest List</div>
            </div>
            <input
              type="number"
              placeholder="Unlimited"
              className={styles['input-wrapper-number']}
              value={eventForm.max_capacity >= 9999 ? '' : eventForm.max_capacity}
              onChange={e => handleChange('max_capacity', e.target.value)}
              max="9999"
              min="0"
            />
          </div>

          <div className={styles['option-item']}>
            <div className={styles['label-wrapper']}>
              <div className={styles['label-text']}>Request Guest Count</div>
              <div className={styles['label-explain']}>
                Ask guests if they are bringing additional guests
              </div>
            </div>
            <Switch
              onChange={checked => handleChange('guest_count_enabled', checked)}
              checked={eventForm.guest_count_enabled}
            />
          </div>

          <div className={styles['option-item-indentation']}>
            <div className={styles['option-item']}>
              <div className={styles['label-wrapper']}>
                <div className={styles['label-text']}>Limit per invitation</div>
              </div>
              <input
                type="number"
                placeholder="Unlimited"
                className={styles['input-wrapper-number']}
                value={eventForm.limit_per_invitation > 10 ? '' : eventForm.limit_per_invitation}
                onChange={e => handleChange('limit_per_invitation', e.target.value)}
                max="10"
                min="0"
              />
            </div>

            <div className={styles['option-item']}>
              <div className={styles['label-wrapper']}>
                <div className={styles['label-text']}>Ask guests for number of kids</div>
              </div>
              <Switch
                onChange={checked => handleChange('child_count_enabled', checked)}
                checked={eventForm.child_count_enabled}
                disabled={!eventForm.guest_count_enabled}
              />
            </div>

            <div className={cn(styles['option-item'], styles['border-none'])}>
              <div className={styles['label-wrapper']}>
                <div className={styles['label-text']}>Custom Error message</div>
                <div className={styles['label-explain']}>Max guest capacity already exceeded</div>
              </div>
            </div>

            <div className={cn(styles['input-wrapper'], styles['textArea-wrapper'])}>
              <TextAreaElement
                className={styles['textArea-inner']}
                onChangeComment={value => handleChange('cap_error_msg', value)}
                comment={eventForm.cap_error_msg}
              />
            </div>
          </div>

          <div
            className={cn(styles['option-item'], {
              [styles['border-none']]: eventForm.cutoff_date
            })}
          >
            <div className={styles['label-wrapper']}>
              <div className={styles['label-text']}>RSVP cut-off</div>
              <div className={styles['label-explain']}>
                Event will no longer accept RSVPs after this time
              </div>
            </div>
            <Switch
              onChange={checked => handleChange('cutoff_date_enabled', checked)}
              checked={!!eventForm.cutoff_date}
            />
          </div>
          {eventForm.cutoff_date && (
            <div className={styles['label-value']} onClick={() => setIsCalendarVisible(true)}>
              <span>{moment(eventForm.cutoff_date).format('ddd, MMM DD, YYYY, h:mm A')}</span>
              <img src={DatePickerIcon} width="14" />
            </div>
          )}

          <div className={styles['option-item']}>
            <div className={styles['label-wrapper']}>
              <div className={styles['label-text']}>Event Chat</div>
              <div className={styles['label-explain']}>Allow guests with the app to chat</div>
            </div>
            <Switch
              onChange={checked => handleChange('commenting_enabled', checked)}
              checked={eventForm.commenting_enabled}
            />
          </div>

          <div className={styles['option-title']}>NOTIFICATIONS</div>

          <div className={styles['option-item']}>
            <div className={styles['label-wrapper']}>
              <div className={styles['label-text']}>Auto Reminders</div>
              <div className={styles['label-explain']}>
                <div className={styles['label-explain-row']}>
                  <label>RSVP reminders: </label>
                  <span>two days after the invite sent and day before event date</span>
                </div>
                <div className={styles['label-explain-row']}>
                  <label>EVENT reminders: </label>
                  <span>one hour before (includes link to directions for mapped locations)</span>
                </div>
              </div>
            </div>
            <Switch
              onChange={checked => handleChange('reminders_enabled', checked)}
              checked={eventForm.reminders_enabled}
            />
          </div>

          <div className={styles['option-item']}>
            <div className={styles['label-wrapper']}>
              <div className={styles['label-text']}>Registry Reminder</div>
              <div className={styles['label-explain']}>
                If there is a registry, notify guests (1 week before)
              </div>
            </div>
            <Switch
              onChange={checked => handleChange('registry_reminder_enabled', checked)}
              checked={eventForm.registry_reminder_enabled}
            />
          </div>

          <div className={styles['option-item']}>
            <div className={styles['label-wrapper']}>
              <div className={styles['label-text']}>Request Photos</div>
              <div className={styles['label-explain']}>
                Automatically ask guests to share photos
              </div>
            </div>
            <Switch
              onChange={checked => handleChange('request_media_enabled', checked)}
              checked={eventForm.request_media_enabled}
            />
          </div>
        </div>

        <DateSelector
          visible={isCalendarVisible}
          onCancel={() => setIsCalendarVisible(false)}
          onSave={(date, time) => {
            handleChange('cutoff_date', { date, time })
          }}
          initialInputTime={eventForm.cutoff_date}
        />
      </div>
    </div>
  )
}

EventSetting.propTypes = {
  onClose: PropTypes.func
}

export default EventSetting
