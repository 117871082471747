import { put, select, spawn, takeEvery } from 'redux-saga/effects'

import { createTempComment } from 'src/services/chat_helpers'
import { localComment } from 'src/services/comment_helpers'

import {
} from 'src/actions'

import {
  CHAT_REQUEST_DELETE_COMMENT,
  CHAT_REQUEST_SEND_MESSAGE,
  addTempChatComment,
  chatCreateComment,
  chatDeleteComment,
  removeTempChatComment
} from 'src/actions/chat'

import {
  eventIdSelector,
  userIdSelector
} from 'src/selectors'

export default function * xmppRootSaga () {
  yield spawn(watchChatRequestSendMessage)
  yield spawn(watchChatRequestDeleteComment)
  // yield spawn(watchForPusherComments)
}

function * watchChatRequestSendMessage () {
  yield takeEvery(CHAT_REQUEST_SEND_MESSAGE, sendChatMessageWorker)
}

function * sendChatMessageWorker (action) {
  const userId = yield select(userIdSelector)
  const eventId = yield select(eventIdSelector)

  let tempComment = createTempComment(action.content, action.metadata, userId)
  yield put(addTempChatComment(tempComment))

  yield put(chatCreateComment(tempComment, eventId))
}

function * watchChatRequestDeleteComment () {
  yield takeEvery(CHAT_REQUEST_DELETE_COMMENT, deleteCommentWorker)
}

function * deleteCommentWorker (action) {
  const { comment } = action
  yield put(removeTempChatComment(comment.id))
  if (!localComment(comment)) {
    const eventId = yield select(eventIdSelector)
    yield put(chatDeleteComment(eventId, comment))
  }
}

// function * watchForPusherComments () {
//   while (false) {
//     const action = yield take(PUSHER_EVENT_RECEIVED)
//     const commentPusherTypes = ['comment-created', 'comment-updated', 'comment-destroyed']
//     if (commentPusherTypes.indexOf(action.payload.eventType) > -1) {
//       const comment = action.response.entities.event_comments[action.response.result.comment]
//       console.log('pusher comment!', comment)
//       yield put(updateTempChatCommentStatus(comment.id, 'success'))
//     }
//   }
// }
