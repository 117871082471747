import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import LoadingIndicator from 'src/components/common/LoadingIndicator'
import { changeEventForm } from 'src/actions/event'
import { eventFormSelector, flyerSelector } from 'src/selectors'
import cn from 'classnames'
import { Design } from 'src/models/design.model'
import FlyerTextElement from './FlyerTextElement.jsx/FlyerTextElement'

import styles from './flyer-card.scss'

const THUMB_WIDTH = 320

const FlyerCard = forwardRef(({ isThumbnail = false, flyerDetail, containerClassName }, ref) => {
  const dispatch = useDispatch()

  const eventForm = useSelector(eventFormSelector)
  const [isLoadingFlyer, setIsLoadingFlyer] = useState(true)
  const [isLoadedBackGroundImage, setIsLoadedBackGroupImage] = useState(false)
  const { customFlyerURL } = useSelector(flyerSelector)
  const detectImageViewRef = useRef(null)
  const backGroundImageRef = useRef(null)
  const [backGroundImageInstance, setBackGroundImageInstance] = useState(null)
  const { layout } = new Design(flyerDetail, eventForm)
  const layoutImages = layout?.getFlourishes() || []
  const layoutImageRefs = useRef([])
  const flyerTexts = layout?.getTexts() || []

  const observerInstance = useRef(null)

  useImperativeHandle(ref, () => ({
    onCanvasToImage: canvasToImage
  }))

  const getDefaultBackgroundImage = () => {
    if (flyerDetail.image_bg_slug) {
      return require(`src/assets/Layouts/Backgrounds/${flyerDetail.image_bg_slug}.jpg`)
    }

    return ''
  }

  const loadBackGroundImage = () => {
    const IMG = new Image()
    IMG.crossOrigin = 'Anonymous'
    IMG.onload = () => {
      setBackGroundImageInstance(IMG)
      setIsLoadingFlyer(false)
    }

    IMG.src = customFlyerURL || getDefaultBackgroundImage()
  }

  useEffect(() => {
    if (observerInstance.current && isLoadingFlyer) {
      observerInstance.current.disconnect()
    }

    if (detectImageViewRef.current && isLoadingFlyer) {
      observerInstance.current = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            console.log('in view')
            loadBackGroundImage()
            observerInstance.current.unobserve(entry.target)
          }
        })
      })

      observerInstance.current.observe(detectImageViewRef.current)
    }
  }, [detectImageViewRef, customFlyerURL])

  useEffect(() => {
    if (!isLoadingFlyer) {
      loadBackGroundImage()
    }
  }, [customFlyerURL])

  const canvasToImage = () => {
    const layoutImageEls = layoutImageRefs.current
    const canvasWidth = backGroundImageInstance.width
    const canvasHeight = backGroundImageInstance.height
    const ratio = backGroundImageInstance.width / THUMB_WIDTH

    const canvasEl = document.createElement('canvas')
    const context = canvasEl.getContext('2d')
    canvasEl.className = styles['canvas']
    canvasEl.width = canvasWidth
    canvasEl.height = canvasHeight

    // image
    context.drawImage(backGroundImageInstance, 0, 0, canvasWidth, canvasHeight)
    if (layoutImageEls.length > 0) {
      for (let i = 0; i < layoutImageEls.length; i++) {
        const imageEl = layoutImageEls[i]
        const imagePosition = JSON.parse(imageEl.getAttribute('data-item'))
        const layoutWidth = imagePosition.width * ratio
        const layoutHeight = imagePosition.height * ratio
        const layoutTop = imagePosition.top * ratio
        const layoutLeft = imagePosition.left * ratio
        context.drawImage(imageEl, layoutLeft, layoutTop, layoutWidth, layoutHeight)
      }
    }
    // text
    for (let item of flyerTexts) {
      if (item.type === 'date') {
        for (let date of item.dates) {
          writeWrapText(context, date)
        }
      } else {
        writeWrapText(context, item)
      }
    }

    const imgFile = canvasEl.toDataURL('image/png', 1.0)

    dispatch(changeEventForm({ checkedFlyer: imgFile }))

    return imgFile
  }

  const writeWrapText = (context, item) => {
    let isExceedHeight = true
    const canvasWidth = backGroundImageInstance.width
    const canvasHeight = backGroundImageInstance.height
    const ratio = backGroundImageInstance.width / THUMB_WIDTH

    let {
      text,
      size,
      align,
      right,
      left,
      top,
      width,
      height,
      font,
      widthAlign,
      nowrap,
      verticalAlign,
      rowGap = 0
    } = item
    let x = align === 'right' ? right * ratio : left * ratio
    let y = top * ratio
    let maxWidth = width ? width * ratio : canvasWidth
    let maxHeight = height ? height * ratio : canvasHeight
    let lineHeight = size * ratio + rowGap

    let lines = []
    let words = text ? (nowrap ? [text] : text.split(' ')) : []
    let line = ''

    while (isExceedHeight) {
      context.font = `${size * ratio || 16}px ${font}`

      for (var n = 0; n < words.length; n++) {
        var testLine = line + words[n] + ' '
        var metrics = context.measureText(testLine)
        var testWidth = metrics.width
        if (testWidth > maxWidth && n > 0) {
          lines.push({
            text: line,
            x: writeTextAlign(context, x, line, align, maxWidth, widthAlign),
            y
          })
          line = words[n] + ' '
          y += lineHeight
        } else {
          line = testLine
        }
      }
      lines.push({
        text: line,
        x: writeTextAlign(context, x, line, align, maxWidth, widthAlign),
        y
      })
      // The first line does not need a gap
      if (lineHeight * lines.length - rowGap > maxHeight) {
        size -= 0.3
        line = ''
        lines = []
        lineHeight = size * ratio + rowGap
        y = top * ratio
      } else {
        let isExceedWidth = lines.some(i => context.measureText(i.text).width > maxWidth)
        if (!isExceedWidth) {
          isExceedHeight = false
          // test code
          if (verticalAlign) {
            lines = handleVerticalAlign({
              lines,
              maxHeight,
              lineHeight,
              rowGap,
              verticalAlign
            })
          }

          writeCanvasText({ context, lines, size: size * ratio, item })
        } else {
          size -= 0.3
          line = ''
          lines = []
          lineHeight = size * ratio + rowGap
          y = top * ratio
        }
      }
    }
  }

  const handleVerticalAlign = params => {
    const { lines, maxHeight, lineHeight, rowGap, verticalAlign } = params

    const linesHeight = lineHeight * lines.length - rowGap
    const gapHeight = (maxHeight - linesHeight) / (verticalAlign === 'center' ? 2 : 1)

    const data = lines.map(item => ({
      text: item.text,
      x: item.x,
      y: item.y + gapHeight
    }))

    return data
  }

  const writeCanvasText = params => {
    const { context, lines, size, item } = params

    context.beginPath()
    context.font = `${size || 16}px ${item.font}`
    context.fillStyle = item.color.replace('0x', '#')
    context.textBaseline = 'top'
    if (item.align === 'center') {
      context.textAlign = item.align
    } else {
      context.textAlign = 'left'
    }

    for (let line of lines) {
      const { text, x, y } = line
      context.fillText(text, x, y)
    }
    context.closePath()
  }

  const writeTextAlign = (ctx, x, text, align, maxWidth, widthAlign) => {
    const canvasWidth = backGroundImageInstance.width
    if (align === 'center') {
      return canvasWidth / 2
    } else if (align === 'right') {
      if (widthAlign === 'center') {
        return (
          canvasWidth -
          ctx.measureText(text).width -
          (maxWidth - ctx.measureText(text).width) / 2 -
          x
        )
      }
      return canvasWidth - ctx.measureText(text).width - x
    } else {
      if (widthAlign === 'center') {
        return (maxWidth - ctx.measureText(text).width) / 2 + x
      }
      return x
    }
  }

  const handleClick = () => {
    if (isThumbnail) return
    const { design_number, design_slug } = flyerDetail
    const isFlyerSelected = eventForm.flyer_design_number === design_number

    dispatch(
      changeEventForm({
        flyer_design_number: isFlyerSelected ? null : design_number,
        flyer_design_slug: isFlyerSelected ? null : design_slug,
        checkedFlyer: isFlyerSelected ? null : eventForm.checkedFlyer
      })
    )
  }

  const renderBackgroundImage = () => {
    // const url = customFlyerURL || backGroundImageInstance.src
    return (
      <img
        className={styles['image-inner']}
        src={backGroundImageInstance.src}
        ref={backGroundImageRef}
        onLoad={setIsLoadedBackGroupImage}
        alt={`${flyerDetail?.layout_slug} can't see image? try to refresh the page.`}
      />
    )
  }

  const renderOverlayImage = () => {
    const ratio = backGroundImageRef.current.clientWidth / THUMB_WIDTH
    if (!layout) return

    return layoutImages.map((item, index) => {
      const srcProp = require(`src/assets/Layouts/Flourishes/${item.name}.png`)
      let imgStyle = {
        left: item.left * ratio,
        top: item.top * ratio,
        width: item.width * ratio,
        height: item.height * ratio
      }
      return (
        <img
          style={imgStyle}
          className={styles['layout-inner']}
          key={index}
          src={srcProp}
          data-item={JSON.stringify(item)}
          crossOrigin="Anonymous"
          alt={`${item.name} can't see images? try to refresh the page.`}
          ref={ref => (layoutImageRefs.current[index] = ref)}
        />
      )
    })
  }

  const renderOverlayImageSlug = () => {
    let imageOverlaySrc = ''
    try {
      imageOverlaySrc = require(`src/assets/Layouts/Flourishes/${flyerDetail.image_overlay_slug}.png`)
    } catch (e) {}

    return (
      <img
        style={{ left: 0, top: 0, width: '100%', height: '100%' }}
        className={styles['layout-inner']}
        src={imageOverlaySrc}
        crossOrigin="Anonymous"
      />
    )
  }

  if (isLoadingFlyer) {
    return (
      <div className={styles['container']} ref={detectImageViewRef}>
        <div className={styles['loading-container']}>
          <LoadingIndicator />
        </div>
      </div>
    )
  }

  return (
    <div className={cn(styles['container'], containerClassName)}>
      <div
        className={cn(styles['flyer-container'], {
          [styles['flyer-container-checked']]:
            eventForm.flyer_design_number === flyerDetail.design_number
        })}
        onClick={handleClick}
      >
        {renderBackgroundImage()}
        {isLoadedBackGroundImage && flyerDetail.image_overlay_slug && renderOverlayImageSlug()}
        {isLoadedBackGroundImage && renderOverlayImage()}
        {isLoadedBackGroundImage && flyerTexts.length > 0 && (
          <FlyerTextElement
            flyerTexts={flyerTexts}
            ratio={backGroundImageRef.current.clientWidth / THUMB_WIDTH}
          />
        )}
      </div>
    </div>
  )
})

FlyerCard.propTypes = {
  isThumbnail: PropTypes.bool,
  flyerDetail: PropTypes.object,
  containerClassName: PropTypes.string
}

export default FlyerCard
