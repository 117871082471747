import { delay } from 'redux-saga'
import { call, put, select, spawn, take, takeEvery } from 'redux-saga/effects'
import { routerActions } from 'react-router-redux'

import { setInvitationViewedCookie } from 'src/middleware'

import { updateEventUrl } from 'services/url_helpers'

import { fetchEventBranchLinks } from 'src/actions'

import { hideRsvpModal, showHud, showRsvpModal } from 'src/actions/frontend'

import {
  ACCEPT_INVITATION_ERROR,
  ACCEPT_INVITATION_SUCCESS,
  acceptInvitation,
  DECLINE_INVITATION_ERROR,
  DECLINE_INVITATION_SUCCESS,
  declineInvitation,
  REQUEST_ACCEPT_INVITATION,
  REQUEST_DECLINE_INVITATION,
  REQUEST_SEND_INVITATION_REPLY_MESSAGE,
  SEND_INVITATION_REPLY_MESSAGE_ERROR,
  sendInvitationReplyMessage,
  SET_INVITATION_ID,
  setInvitationReplyMessage,
  setInvitationToken,
  UPDATE_INVITATION_ERROR,
  updateInvitation,
  VIEW_INVITATION_SUCCESS,
  viewInvitation,
  DELETE_INVITATION_SUCCESS,
  DELETE_INVITATIONS_SUCCESS
} from 'src/actions/invitations'

import {
  eventSelector,
  destinationTypeSelector,
  eventTokenSelector,
  hostForInvitationSelector,
  invitationIdSelector,
  invitationReplyMessageSelector,
  invitationSelector,
  invitationTokenSelector,
  invitationViewedSelector,
  isInvitationCredentialUserSelector,
  namesByUserIdSelector,
  requestedRsvpSelector,
  ticketTypesSelector,
  userSelector,
  eventTicketCountsLoadingStatusSelector
} from 'src/selectors'

import { fullName } from 'src/services/user_helpers'
import {
  EVENT_TICKETS_COUNTS_SUCCESS,
  eventTicketCountsRequest,
  listMyOrdersForEventRequest,
  purchaseTickets,
  setInitialLoadingPaymentModal,
  setOrderLineItems,
  setTicketTypesCounts,
  ticketTypesCountsLoaded
} from '../actions/paymentGraphql'
import { hasPaidTickets } from '../services/payment_utils'

export default function * invitationsRootSaga () {
  yield spawn(retryUpdateInvitation)
  yield spawn(spawnSetInvitationId)
  yield spawn(viewInvitationSaga)
  yield spawn(watchRequestAcceptOrDeclineInvitation)
  yield spawn(sendInvitationReplyMessageError)
  yield spawn(watchDeleteInvitation)
  yield takeEvery(SET_INVITATION_ID, watchSetInvitationId)
  yield takeEvery(REQUEST_SEND_INVITATION_REPLY_MESSAGE, sendInvitationReplyMessageWorker)
}

// Used only to prevent duplicate requests.
let tempInvitationId = ''

function * watchSetInvitationId () {
  while (true) {
    yield take(SET_INVITATION_ID)
    let eventTicketCountsLoadingStatus = yield select(eventTicketCountsLoadingStatusSelector)
    if (eventTicketCountsLoadingStatus !== 'loading' && eventTicketCountsLoadingStatus !== 'not_spawned') {
      let event = yield select(eventSelector)
      let currentInvitation = yield select(invitationSelector)
      let invitationId = yield select(invitationIdSelector)

      if (invitationId === currentInvitation?.id) {
        if (!event.invitations.includes(invitationId)) {
          let eventTicketCounts = {}
          yield put(eventTicketCountsRequest(event.id))
          const action = yield take(EVENT_TICKETS_COUNTS_SUCCESS)

          if (action.response && action.response.data.events) {
            eventTicketCounts = action.response.data.events.filter(item => item.id === event.id)[0]
          }
          yield call(dealWithTicketTypes, event, currentInvitation, eventTicketCounts)
          yield put(listMyOrdersForEventRequest(event.id))
        }
      }
    }
  }
}

function * spawnSetInvitationId () {
  while (true) {
    yield take(SET_INVITATION_ID)
    let currentInvitation = yield select(invitationSelector)
    let event = yield select(eventSelector)

    let eventTicketCounts = {}
    yield put(setInitialLoadingPaymentModal(true))

    yield put(eventTicketCountsRequest(event.id))
    // FIXME: We should handle the case where the event ticket counts cannot be fetched
    const action = yield take(EVENT_TICKETS_COUNTS_SUCCESS)
    if (action.response && action.response.data.events) {
      eventTicketCounts = action.response.data.events.filter(item => item.id === event.id)[0]
    }

    const destinationType = yield select(destinationTypeSelector)
    const eventToken = yield select(eventTokenSelector)
    const invitationToken = yield select(invitationTokenSelector)

    if (currentInvitation) {
      if (currentInvitation.destroyed_at) return

      // The request for the invitation was authenticated then we will have the token and can compare it against the current invitation
      if (currentInvitation.token && (currentInvitation.token !== invitationToken)) {
        yield put(setInvitationToken(currentInvitation.token))
        updateEventUrl(destinationType, eventToken, currentInvitation.token)
        yield put(fetchEventBranchLinks(eventToken, invitationToken))
      }

      if (event.ticketing_enabled_at && eventTicketCounts && eventTicketCounts.invitations) {
        yield call(dealWithTicketTypes, event, currentInvitation, eventTicketCounts)
      }

      // request list_my_orders_for_event
      yield put(listMyOrdersForEventRequest(event.id))
    } else {
      /**
       * 错误标记
       */
      localStorage.hobnobError = JSON.stringify({
        currentInvitation,
        eventTicketCounts: action.response.data,
        time: new Date().getTime()
      })

      yield put(ticketTypesCountsLoaded())
      yield put(setInvitationToken(''))
      updateEventUrl(destinationType, eventToken)
      yield put(fetchEventBranchLinks(eventToken))
      yield put(setInitialLoadingPaymentModal(false))
    }
  }
}

function * dealWithTicketTypes (event, currentInvitation, eventTicketCounts) {
  // get ticket_types_for_container
  const ticketTypes = yield select(ticketTypesSelector)

  const invitation = eventTicketCounts.invitations.find(item => item.id === currentInvitation.id)
  let invitationTicketCounts = []
  if (invitation) {
    invitationTicketCounts = invitation.ticket_counts
  } else {
    const ticket_counts = ticketTypes.map(item => {
      return { ticket_type_id: item.id, ticket_count: 0 }
    })
    invitationTicketCounts = ticket_counts
  }

  const filterInvitationTicketCounts = invitationTicketCounts.filter(item => !!item)

  const ticketTypesCounts = ticketTypes.map((ticketType) => {
    const ticket = filterInvitationTicketCounts.find(value => value.ticket_type_id === ticketType.id)

    const count = ticket ? ticket.ticket_count : 0
    return {
      ...ticketType,
      count
    }
  })
  yield put(setTicketTypesCounts(ticketTypesCounts))
  yield put(setInitialLoadingPaymentModal(false))

  // Handle the web-client links that RSVP immediately
  // In the case of already rsvp. Does not execute the function.
  if (invitation.rsvp_state !== 'undecided' && !event.guest_count_enabled) {
    yield put(hideRsvpModal())
    return
  }
  yield call(maybePurchaseFreeTickets, ticketTypesCounts, event)
}

function * maybePurchaseFreeTickets (ticketCounts, event) {
  const needPayTicket = hasPaidTickets(ticketCounts)
  const invitationId = yield select(invitationIdSelector)

  const requestedRsvp = yield select(requestedRsvpSelector)
  if (!needPayTicket && !event.guest_count_enabled && requestedRsvp === 'accepted' && tempInvitationId !== invitationId) {
    const isInvitationCredentialUser = yield select(isInvitationCredentialUserSelector)
    tempInvitationId = invitationId

    let queryVariables = []
    ticketCounts.forEach((item) => {
      if (!item.count) {
        const obj = { ticket_type_id: item.id, count: 1 }
        queryVariables.push(obj)
      }
    })

    yield put(setOrderLineItems(queryVariables))
    yield put(purchaseTickets(invitationId, queryVariables, null, !isInvitationCredentialUser))
  }
}

function * watchRequestAcceptOrDeclineInvitation () {
  while (true) {
    const action = yield take([REQUEST_ACCEPT_INVITATION, REQUEST_DECLINE_INVITATION])
    const isAccept = action.type === REQUEST_ACCEPT_INVITATION
    const createRsvpAction = isAccept ? acceptInvitation : declineInvitation

    const invitation = yield select(invitationSelector)
    const user = yield select(userSelector)
    const event = yield select(eventSelector)
    let hiding = false

    if (invitation) {
      if (user) {
        yield put(createRsvpAction(invitation, invitation.id, invitation.event_id, {
          successActions: action.successActions,
          errorActions: action.errorActions
        }))
      } else {
        const eventToken = yield select(eventTokenSelector)
        const invitationToken = yield select(invitationTokenSelector)
        yield put(createRsvpAction(invitation, invitationToken, eventToken, {
          successActions: action.successActions,
          errorActions: action.errorActions
        }))
      }

      const resultAction = yield take([ACCEPT_INVITATION_SUCCESS, ACCEPT_INVITATION_ERROR, DECLINE_INVITATION_SUCCESS, DECLINE_INVITATION_ERROR])
      if (resultAction.type === ACCEPT_INVITATION_ERROR || resultAction.type === DECLINE_INVITATION_ERROR) {
        if (resultAction.response) {
          const errors = resultAction.response.result.errors
          if (errors && errors.rsvp_state && errors.rsvp_state[0] && errors.rsvp_state[0].startsWith('cannot transition')) {
            console.log('no error')
          } else {
            yield put(showHud('error', 'There was a problem sending your RSVP. Please try again in a few moments'))
            hiding = true
            yield put(hideRsvpModal(true))
          }
        } else {
          yield put(showHud('error', 'There was a problem sending your RSVP. Please try again in a few moments'))
          hiding = false
        }
      }

      if (event.ticketing_enabled_at) {
        yield put(eventTicketCountsRequest(event.id))
      }
    }

    yield put(routerActions.push('rsvp'))
    if (!hiding) {
      yield put(showRsvpModal())
    }
  }
}

function * viewInvitationSaga () {
  while (true) {
    yield take(SET_INVITATION_ID)
    let currentInvitation = yield select(invitationSelector)
    let invitationViewed = yield select(invitationViewedSelector)
    // Delay viewing the invitation in an attempt to avoid last_updated_at
    // conflicts with email rsvp accepted and declined links
    yield call(delay, 3000)

    if (currentInvitation && !currentInvitation.viewed_at && !invitationViewed) {
      if (currentInvitation.destroyed_at) return
      const user = yield select(userSelector)

      if (user) {
        if (!currentInvitation.viewed_at) {
          yield put(viewInvitation(currentInvitation, currentInvitation.id, currentInvitation.event_id))
        }
      } else {
        const eventToken = yield select(eventTokenSelector)
        const invitationToken = yield select(invitationTokenSelector)
        yield put(viewInvitation(currentInvitation, invitationToken, eventToken))
      }
      yield take(VIEW_INVITATION_SUCCESS)
      yield put(setInvitationViewedCookie())
    }
  }
}

function * retryUpdateInvitation () {
  while (true) {
    const action = yield take(UPDATE_INVITATION_ERROR)
    let { invitation, invitationIdentifier, eventIdentifier } = action.originalAction.payload
    if (action.response) {
      const { entities, result } = action.response

      if (result.errors && result.errors.last_updated_at) {
        const { updated_at } = entities.invitations[result.invitation]
        invitation.updated_at = updated_at

        yield put(updateInvitation(invitation, invitationIdentifier, eventIdentifier))
      }
    } else {
      yield put(showHud('error', 'An error occurred and your changes could not be saved.'))
    }
  }
}

function * sendInvitationReplyMessageWorker () {
  const invitation = yield select(invitationSelector)
  const invitationReplyMessage = yield select(invitationReplyMessageSelector)
  const receiverUser = yield select(hostForInvitationSelector)
  const namesByUserId = yield select(namesByUserIdSelector)
  const receiverName = fullName(receiverUser, namesByUserId)
  yield put(sendInvitationReplyMessage(invitation.id, invitationReplyMessage, receiverName))
  yield put(setInvitationReplyMessage(''))
}

function * sendInvitationReplyMessageError () {
  const action = yield take(SEND_INVITATION_REPLY_MESSAGE_ERROR)
  if (!action.response) {
    yield put(showHud('error', 'There was a problem sending your message. Please try again in a few moments'))
  }
}

function * watchDeleteInvitation () {
  // After deleting the invitation, refresh the event ticket counts
  while (true) {
    yield take([DELETE_INVITATION_SUCCESS, DELETE_INVITATIONS_SUCCESS])
    const event = yield select(eventSelector)
    if (event.ticketing_enabled_at) {
      yield put(eventTicketCountsRequest(event.id))
    }
  }
}

export const _test = {
  watchRequestAcceptOrDeclineInvitation
}
