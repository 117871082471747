import Flyer_layout_split_three_corners_034LayoutModel from 'src/models/layouts/flyer_layout_split_three_corners_034.layout.model'
import Flyer_layout_split_ellipse_036LayoutModel from 'src/models/layouts/flyer_layout_split_ellipse_036.layout.model'
import Flyer_layout_center_boxed_laurel_037LayoutModel from 'src/models/layouts/flyer_layout_center_boxed_laurel_037.layout.model'
import Flyer_layout_split_burst_026LayoutModel from 'src/models/layouts/flyer_layout_split_burst_026.layout.model'
import Flyer_layout_split_diagonal_lines_027LayoutModel from 'src/models/layouts/flyer_layout_split_diagonal_lines_027.layout.model'
import Flyer_layout_bottom_flourish_border_029LayoutModel from 'src/models/layouts/flyer_layout_bottom_flourish_border_029.layout.model'
import Flyer_layout_split_sketch_border_003LayoutModel from 'src/models/layouts/flyer_layout_split_sketch_border_003.layout.model'
import Flyer_layout_center_thin_lines_007LayoutModel from 'src/models/layouts/flyer_layout_center_thin_lines_007.layout.model'
import Flyer_layout_bottom_polaroid_013LayoutModel from 'src/models/layouts/flyer_layout_bottom_polaroid_013.layout.model'
import Flyer_layout_bottom_right_aligned_004LayoutModel from 'src/models/layouts/flyer_layout_bottom_right_aligned_004.layout.model'
import Flyer_layout_bottom_split_alignment_008LayoutModel from 'src/models/layouts/flyer_layout_bottom_split_alignment_008.layout.model'
import Flyer_layout_column_left_arrows_023LayoutModel from 'src/models/layouts/flyer_layout_column_left_arrows_023.layout.model'
import Flyer_layout_right_aligned_column_015LayoutModel from 'src/models/layouts/flyer_layout_right_aligned_column_015.layout.model'
import Flyer_layout_left_aligned_boxed_53LayoutModel from 'src/models/layouts/flyer_layout_left_aligned_boxed_53.layout.model'

export const flyerDesignLayoutConfig = [
  {
    slug: 'flyer_layout_left_aligned_boxed_53',
    layout: Flyer_layout_left_aligned_boxed_53LayoutModel,
    designIds: [120]
  },
  {
    slug: 'flyer_layout_right_aligned_column_015',
    layout: Flyer_layout_right_aligned_column_015LayoutModel,
    designIds: [17]
  },
  {
    slug: 'flyer_layout_column_left_arrows_023',
    layout: Flyer_layout_column_left_arrows_023LayoutModel,
    designIds: [34]
  },
  {
    slug: 'flyer_layout_bottom_split_alignment_008',
    layout: Flyer_layout_bottom_split_alignment_008LayoutModel,
    designIds: [9]
  },
  {
    slug: 'flyer_layout_split_three_corners_034',
    layout: Flyer_layout_split_three_corners_034LayoutModel,
    designIds: [71, 137, 144, 147, 151]
  },
  {
    slug: 'flyer_layout_split_ellipse_036',
    layout: Flyer_layout_split_ellipse_036LayoutModel,
    designIds: [75, 139, 146, 149]
  },
  {
    slug: 'flyer_layout_center_boxed_laurel_037',
    layout: Flyer_layout_center_boxed_laurel_037LayoutModel,
    designIds: [72, 138, 152]
  },
  {
    slug: 'flyer_layout_split_burst_026',
    layout: Flyer_layout_split_burst_026LayoutModel,
    designIds: [53, 135]
  },
  {
    slug: 'flyer_layout_split_diagonal_lines_027',
    layout: Flyer_layout_split_diagonal_lines_027LayoutModel,
    designIds: [54, 136, 143, 148]
  },
  {
    slug: 'flyer_layout_bottom_flourish_border_029',
    layout: Flyer_layout_bottom_flourish_border_029LayoutModel,
    designIds: [51, 134, 141, 150, 154]
  },
  {
    slug: 'flyer_layout_split_sketch_border_003',
    layout: Flyer_layout_split_sketch_border_003LayoutModel,
    designIds: [3, 130]
  },
  {
    slug: 'flyer_layout_center_thin_lines_007',
    layout: Flyer_layout_center_thin_lines_007LayoutModel,
    designIds: [47, 133, 140, 153]
  },
  {
    slug: 'flyer_layout_bottom_polaroid_013',
    layout: Flyer_layout_bottom_polaroid_013LayoutModel,
    designIds: [15, 132, 145]
  },
  {
    slug: 'flyer_layout_bottom_right_aligned_004',
    layout: Flyer_layout_bottom_right_aligned_004LayoutModel,
    designIds: [4, 131, 142]
  },
  {
    slug: 'custom',
    layout: null,
    designIds: [8]
  }
]
