import {
  SET_EVENT_ID,
  SET_DESTINATION_TYPE,
  SET_EVENT_TOKEN,
  SET_USER_ID,
  GET_MESSAGE_COUNT,
  GET_MEDIA_COUNT,
  SET_INVITATIONS_COUNT,
  SET_INVITATIONS_ACCEPTED_COUNT,
  SET_PROCESSED_PHOTO_COUNT,
  SET_PROCESSED_VIDEO_COUNT
} from 'src/actions'
import { SHOW_HUD, HIDE_HUD } from 'src/actions/frontend'

import {
  LOGOUT,
  EVENT_LOGOUT
} from 'src/actions/login'

import {
  SET_INVITATION_ID,
  SET_INVITATION_TOKEN
} from 'src/actions/invitations'

import credentials from './credentials'
import entities from './entities'
import frontend from './frontend'
import loginForm from './loginForm'
import toastMessages from './toastMessages'
import branch from './branch'

const invitationTokenDefaultState = ''
const invitationToken = (state = invitationTokenDefaultState, action) => {
  switch (action.type) {
    case SET_INVITATION_TOKEN:
      return action.invitationToken || ''
    case LOGOUT:
    case EVENT_LOGOUT:
      return invitationTokenDefaultState
    default:
      return state
  }
}

const eventTokenDefaultState = ''
const eventToken = (state = eventTokenDefaultState, action) => {
  switch (action.type) {
    case SET_EVENT_TOKEN:
      return action.eventToken
    case LOGOUT:
      return eventTokenDefaultState
    default:
      return state
  }
}

const destinationType = (state = '', action) => {
  switch (action.type) {
    case SET_DESTINATION_TYPE:
      return action.destinationType
    default:
      return state
  }
}

const invitationIdDefaultState = ''
const invitationId = (state = invitationIdDefaultState, action) => {
  switch (action.type) {
    case SET_INVITATION_ID:
      return action.invitationId
    case LOGOUT:
    case EVENT_LOGOUT:
      return invitationIdDefaultState
    default:
      return state
  }
}

const eventIdDefaultState = ''
const eventId = (state = eventIdDefaultState, action) => {
  switch (action.type) {
    case SET_EVENT_ID:
      return action.eventId
    case LOGOUT:
      return eventIdDefaultState
    default:
      return state
  }
}

const userIdDefaultState = ''
const userId = (state = userIdDefaultState, action) => {
  switch (action.type) {
    case SET_USER_ID:
      return action.userId
    case LOGOUT:
    case EVENT_LOGOUT:
      return userIdDefaultState
    default:
      return state
  }
}

const hudDefaultState = {
  show: false,
  message: '',
  type: ''
}
const hud = (state = hudDefaultState, action) => {
  switch (action.type) {
    case SHOW_HUD:
      return {
        ...state,
        show: true,
        message: action.message,
        type: action.hudType
      }
    case HIDE_HUD:
      return {
        ...state,
        show: false
      }
    default:
      return state
  }
}

const messageCount = (state = 0, action) => {
  switch (action.type) {
    case GET_MESSAGE_COUNT:
      return action.messageCount
    default:
      return state
  }
}

const processedMediaCount = (state = 0, action) => {
  switch (action.type) {
    case GET_MEDIA_COUNT:
      return action.mediaCount
    default:
      return state
  }
}

const processedPhotoCount = (state = 0, action) => {
  switch (action.type) {
    case SET_PROCESSED_PHOTO_COUNT:
      return action.photoCount
    default:
      return state
  }
}

const processedVideoCount = (state = 0, action) => {
  switch (action.type) {
    case SET_PROCESSED_VIDEO_COUNT:
      return action.videoCount
    default:
      return state
  }
}

const invitationsCount = (state = 0, action) => {
  switch (action.type) {
    case SET_INVITATIONS_COUNT:
      return action.invitationsCount
    default:
      return state
  }
}

const invitationsAcceptedCount = (state = 0, action) => {
  switch (action.type) {
    case SET_INVITATIONS_ACCEPTED_COUNT:
      return action.invitationAcceptedCount
    default:
      return state
  }
}

export default {
  branch,
  credentials,
  hud,
  entities,
  destinationType,
  eventToken,
  invitationToken,
  eventId,
  invitationId,
  userId,
  loginForm,
  frontend,
  toastMessages,
  messageCount,
  processedMediaCount,
  invitationsCount,
  invitationsAcceptedCount,
  processedPhotoCount,
  processedVideoCount
}
