import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import SvgIcon from 'components/common/SvgIcon'
import Button from 'components/common/Button'
import HobnobModal from 'components/common/HobnobModal'

import {
  isChrome,
  isFirefox,
  isSafari
} from 'src/services/browser_detection'

import { setOpenInAppModalClosed } from 'src/services/cookies'

import { hideOpenInAppModal } from 'src/actions/frontend'
import { parseEventUrl } from 'services/url_helpers'
import { DESTINATION_TYPES_CONSTANTS } from 'src/constants'

import {
  openInAppModalVisibleSelector,
  branchOpenInAppModalLinkSelector,
  branchOpenInAppModalPollLinkSelector
} from 'src/selectors'

import iosIcon from 'src/assets/icons/hobnob_iOS_icon.svg'
import browserIcon from 'src/assets/icons/browser_app_icon.svg'

/* eslint-disable import/no-webpack-loader-syntax */
import firefoxIcon from '!url-loader?limit=1!src/assets/icons/firefox_app_icon.png'
import chromeIcon from '!url-loader?limit=1!src/assets/icons/chrome_app_icon.png'
import safariIcon from '!url-loader?limit=1!src/assets/icons/safari_app_icon.png'

import styles from './open-in-app-modal.scss'

class OpenInAppModal extends React.Component {
  _close = () => {
    const { onClose } = this.props
    onClose()
    setOpenInAppModalClosed()
  }

  _renderBrowserName () {
    if (isChrome()) {
      return 'Chrome'
    } else if (isFirefox()) {
      return 'Firefox'
    } else if (isSafari()) {
      return 'Safari'
    } else {
      return 'Browser'
    }
  }

  _renderBrowserIcon () {
    if (isChrome()) {
      return <img className={styles['app-icon']} src={chromeIcon} />
    } else if (isFirefox()) {
      return <img className={styles['app-icon']} src={firefoxIcon} />
    } else if (isSafari()) {
      return <img className={styles['app-icon']} src={safariIcon} />
    } else {
      var svgStyles = {
        width: '30px',
        height: '30px',
        fill: 'white',
        stroke: '#040159'
      }

      return <SvgIcon icon={browserIcon} style={svgStyles} />
    }
  }

  getOpenInAppLink = () => {
    const { openInAppLink, openInAppPollLink } = this.props
    const path = window.location.pathname
    const { destinationType } = parseEventUrl(path)

    if (destinationType === DESTINATION_TYPES_CONSTANTS.timepoll || destinationType === DESTINATION_TYPES_CONSTANTS.signUp) {
      return openInAppPollLink
    } else {
      return openInAppLink
    }
  }

  render () {
    const { onClose, show, event } = this.props

    const containerStyles = {
      bottom: 0,
      left: '50%',
      right: '0',
      top: 'auto',
      padding: '20px 25px',
      borderRadius: '10px 10px 0 0',
      width: '100%',
      transform: 'translateX(-50%)',
      // Based on the event-width mixin
      max_width: '48.82117% !important'
    }

    const svgStyles = {
      width: '30px',
      height: '30px'
    }

    const getLabel = (event) => {
      let label = 'Open this '

      const path = window.location.pathname
      const { destinationType } = parseEventUrl(path)
      if (event) {
        return label + (event.event_type || '') + ' in...'
      }

      if (destinationType === DESTINATION_TYPES_CONSTANTS.timepoll) {
        return label + 'time poll in...'
      }

      if (destinationType === DESTINATION_TYPES_CONSTANTS.signUp) {
        return label + 'sign up list in...'
      }

      return label + (event.event_type || '') + ' in...'
    }

    return (
      <HobnobModal
        onClose={onClose}
        show={show}
        title={null}
        containerStyles={containerStyles}
        showCancelIcon={false}
      >
        <div>
          {getLabel(event)}
        </div>
        <div className={styles['spacer']} />
        <div className={styles['open-buttons-container']}>
          <div className={styles['open-button-row']}>
            <SvgIcon icon={iosIcon} style={svgStyles} />
            <span className={styles['open-button-name']}>Hobnob App</span>
            <Button wrapperElement='a' purple className={styles['button-app']} href={this.getOpenInAppLink()}>
              Open
            </Button>
          </div>
        </div>
        <div className={styles['open-buttons-container']}>
          <div className={styles['open-button-row']}>
            {this._renderBrowserIcon()}
            <span className={styles['open-button-name']}>{this._renderBrowserName()}</span>
            <Button wrapperElement='a' className={styles['button-browser']} onClick={this._close} filled={false}>
              Continue
            </Button>
          </div>
        </div>
      </HobnobModal>
    )
  }
}

OpenInAppModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  openInAppLink: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  event: PropTypes.object,
  openInAppPollLink: PropTypes.string
}

function mapStateToProps (state) {
  return {
    show: openInAppModalVisibleSelector(state),
    openInAppLink: branchOpenInAppModalLinkSelector(state),
    openInAppPollLink: branchOpenInAppModalPollLinkSelector(state)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    onClose: () => dispatch(hideOpenInAppModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OpenInAppModal)
