import { fetchApi } from './api'

export const UPDATE_FLYER_ZOOM = 'UPDATE_FLYER_ZOOM'
export const SET_CUSTOM_FLYER_URL = 'SET_CUSTOM_FLYER_URL'
export const CLEAR_CUSTOM_FLYER_URL = 'CLEAR_CUSTOM_FLYER_URL'

export const FETCH_FLYER_DESIGNS = 'FETCH_FLYER_DESIGNS'
export const FETCH_FLYER_DESIGNS_SUCCESS = 'FETCH_FLYER_DESIGNS_SUCCESS'
export const FETCH_FLYER_DESIGNS_ERROR = 'FETCH_FLYER_DESIGNS_ERROR'

export function updateFlyerZoom(flyerZoom) {
  return {
    type: UPDATE_FLYER_ZOOM,
    flyerZoom
  }
}

export function setCustomFlyerURL(url) {
  return {
    type: SET_CUSTOM_FLYER_URL,
    url
  }
}

export function clearCustomFlyerURL() {
  return {
    type: CLEAR_CUSTOM_FLYER_URL
  }
}

export function fetchFlyerDesigns(web_version) {
  return fetchApi({
    requestType: FETCH_FLYER_DESIGNS,
    successType: FETCH_FLYER_DESIGNS_SUCCESS,
    errorType: FETCH_FLYER_DESIGNS_ERROR,
    route: '/graphql',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `{
          flyer_designs {
            active_galleries {
              flyer_designs {
                design_number
                design_slug
                version
                gallery_name
                image_bg_slug
                video_bg_slug
                filter_slug
                image_overlay_slug
                video_overlay_slug
                layout_slug
                status
                layout_properties {
                  slug
                  color
                  font
                  all_caps
                }
                default_type
                bg_crop_area {
                  top
                  right
                  bottom
                  left
                }
              }
            }
          }
        }`
      })
    },
    customHeaders: { web_version }
  })
}
