import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import styles from './dashboard-container.scss'
import { topBannerSelector, userSelector, planSelector } from 'src/selectors'
import { useSelector, useDispatch } from 'react-redux'
import MenuBar from '../components/MenuBar/MenuBar'
import HeaderBar from 'components/HeaderBar/HeaderBar'
import cn from 'classnames'
import DownloadAppBanner from 'components/global/DownloadAppBanner'
import queryString from 'query-string'
import { updateMenuPanel } from 'src/actions/frontend'
import { PanelSlug } from 'components/MenuBar/panels/PanelContainer'
import { isMobile } from 'services/browser_detection'
import { isHobnobAndroidClient } from 'src/services/utils'
import PlanContainer from 'containers/PlanContainer/PlanContainer'
import { changePlanModalVisible } from 'src/actions/plan'
import { getPlanBySlug, PlanType } from 'src/services/stripe_checkout_configs'

function DashboardContainer(props) {
  const { children, title, visibleHeaderBar, documentTitle } = props
  const dispatch = useDispatch()
  const { visible: isBannerVisible } = useSelector(topBannerSelector)
  const { visible: isPlanModalVisible, downgrade } = useSelector(planSelector)
  const user = useSelector(userSelector)
  const menuBarRef = useRef(null)
  const headerBarRef = useRef(null)

  useEffect(() => {
    document.title = documentTitle
  }, [documentTitle])

  useEffect(() => {
    handleUrlParams()
  }, [user])

  const handleUrlParams = () => {
    const parsedUrl = new URL(window.location.href)
    const params = queryString.parse(parsedUrl.search)
    if (params.from === 'corp-site') {
      if (isMobile()) {
        headerBarRef.current.onSetIsHeaderSliderVisible(true)
      } else {
        menuBarRef.current.onSetIsMenuSliderVisible(true)
      }
      dispatch(updateMenuPanel(user ? PanelSlug.Profile : PanelSlug.Login))
    }
  }
  const isAndroidApp = isHobnobAndroidClient()

  const handleHeaderBarProps = () => {
    const headerBarProps = { ...props }
    if (isPlanModalVisible) {
      headerBarProps.closeIcon = true
      headerBarProps.whiteIcon = true
      headerBarProps.left = null
      headerBarProps.right = null
      headerBarProps.containerClassName = styles['plan-header']
      headerBarProps.onBack = () => dispatch(changePlanModalVisible(false))
    }

    return headerBarProps
  }

  const renderHeaderBarTitle = () => {
    const planType = getPlanBySlug(user?.current_subscription?.plan?.slug, 'planType')
    if (isPlanModalVisible) {
      let titleText = ''
      if (planType === PlanType.Free) {
        titleText = 'Subscribes'
      } else if (downgrade) {
        titleText = 'A Lower-Cost Plan'
      } else {
        titleText = 'Upgrade Plan'
      }

      return <div style={{ color: '#fff' }}>{titleText}</div>
    }

    return title
  }

  const isHeaderBarVisible = (visibleHeaderBar && !isAndroidApp) || isPlanModalVisible

  return (
    <div className={styles['container']}>
      {isHeaderBarVisible && (
        <section>
          <HeaderBar ref={headerBarRef} {...handleHeaderBarProps()}>
            {renderHeaderBarTitle()}
          </HeaderBar>
        </section>
      )}

      {isBannerVisible && !isAndroidApp && <DownloadAppBanner />}

      <section className={cn('web-view', styles['menu-container'])}>
        <MenuBar ref={menuBarRef} />
      </section>
      <section className={styles['dashboard-section']}>
        {children}
        {isPlanModalVisible && <PlanContainer />}
      </section>
    </div>
  )
}

DashboardContainer.defaultProps = {
  visibleHeaderBar: true,
  documentTitle: 'Hobnob'
}

DashboardContainer.propTypes = {
  children: PropTypes.any,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  visibleHeaderBar: PropTypes.bool,
  documentTitle: PropTypes.string
}

export default DashboardContainer
