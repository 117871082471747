import React from 'react'
import PropTypes from 'prop-types'

import styles from './link-message.scss'

const TextMessage = props => {
  const { linkContent } = props
  const linkPreviews = linkContent.link_previews
  const metadata = linkContent.metadata

  return (
    <div className={styles['link-message']}>
      {linkPreviews.map(link => (
        <a key={link.id} className={styles['a-preview']} href={link.title_link} target="_blank">
          <img src={link.image_url} alt="" />
          <div className={styles['link-information']}>
            <div className={styles['title']}>{link.title}</div>
            <div className={styles['link']}>{link.title_link}</div>
          </div>
        </a>
      ))}
      {linkPreviews.length === 0 && (
        <a className={styles['a-preview-v2']} href={metadata.url} target="_blank">
          <span className={styles['title']}>{metadata.url}</span>
          <span className={styles['link']}>{metadata.url}</span>
        </a>
      )}
    </div>
  )
}

TextMessage.propTypes = {
  linkContent: PropTypes.object.isRequired
}

export default TextMessage
