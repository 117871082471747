import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { message } from 'antd'
import uuid from 'uuid'
import {
  PHONE_NUMBER_CONTACT_METHOD,
  EMAIL_CONTACT_METHOD,
  HOBNOB_USER_CONTACT_METHOD
} from 'src/services/contact_methods'
import { stringConvertHash } from 'services/utils'
import AddGuestsPageWeb from './AddGuestsPageWeb/AddGuestsPageWeb'
import AddGuestsPageMobile from './AddGuestsPageMobile/AddGuestsPageMobile'

export default class AddGuestsPage extends Component {
  state = {
    initialComplete: false
  }

  formateContactMethod = guest => {
    const { contact_method_type, contact_method_display_name, contact_method_hash, user_id } = guest
    switch (contact_method_type) {
      case PHONE_NUMBER_CONTACT_METHOD:
        return {
          country_code: 'US',
          type: contact_method_type,
          [contact_method_hash ? 'hash' : 'phone_number']:
            contact_method_hash || contact_method_display_name
        }
      case EMAIL_CONTACT_METHOD:
        return {
          type: contact_method_type,
          [contact_method_hash ? 'hash' : 'email_address']:
            contact_method_hash || contact_method_display_name
        }
      case HOBNOB_USER_CONTACT_METHOD:
        return {
          user_id,
          type: contact_method_type
        }
    }
  }

  saveToStoreCache = newGuests => {
    const { onInsertMockInvitation, eventId } = this.props
    const mockInvitations = {}

    for (let item of newGuests) {
      const {
        id,
        first_name,
        last_name,
        display_name,
        avatar_url,
        user_id = null,
        app_user,
        verified,
        contact_method_type,
        contact_method_hash,
        contact_method_display_name
      } = item

      mockInvitations[item.id] = {
        updated_at: new Date().toISOString(),
        cost_type: 'unchecked',
        delivery_state: 'pending',
        rsvp_state: 'undecided',
        cohost: false,
        destroyed_at: null,
        id,
        event_id: eventId,
        first_name,
        last_name,
        display_name,
        avatar_url,
        guest: user_id,
        app_user: !!app_user,
        verified: !!verified,
        contact_method_display_name,
        contact_method: {
          type: contact_method_type,
          hash: contact_method_hash
        }
      }
    }

    onInsertMockInvitation(mockInvitations)
  }

  handleRemoveNewGuests = () => {
    this.props.onChangeAddGuestsList([])
  }

  filterInvitedGuest = () => {
    const { addGuestsList, activeInvitations } = this.props

    const invitationHash = activeInvitations.map(item => item.contact_method.hash)

    const filterAddGuestsList = addGuestsList.filter(item => {
      const hash = stringConvertHash(item.contact_method_display_name)

      return !invitationHash.includes(hash)
    })

    return filterAddGuestsList
  }

  handleSaveGuests = () => {
    const { onSaveInvitations, eventId, onChangeAddGuestsList, onChangeEventsModal, userId } =
      this.props
    window.mixpanel.track('SAVE AND SEND LATER Clicked', {
      event_id: eventId,
      user_id: userId
    })

    const newGuestList = this.filterInvitedGuest()

    if (newGuestList.length === 0) {
      onChangeAddGuestsList([])
      onChangeEventsModal('guests')
      return
    }

    const mapNewGuestListWithId = newGuestList.map(item => {
      item.id = uuid.v4()
      return item
    })

    const saveInvitationsParams = mapNewGuestListWithId.map(item => {
      return {
        id: item.id,
        first_name: item.first_name,
        last_name: item.last_name,
        contact_method_attributes: this.formateContactMethod(item)
      }
    })

    onSaveInvitations({
      eventId,
      invitations: saveInvitationsParams,
      onSuccessCallback: data => {
        if (data.successful) {
          this.saveToStoreCache(mapNewGuestListWithId)
          onChangeAddGuestsList([])
          onChangeEventsModal('guests')

          // if (isSend) {
          //   this.handleSendGuests()
          // } else {
          //   onChangeAddGuestsList([])
          //   onChangeEventsModal('guests')
          // }
        }
      }
    })
  }

  handleSendGuests = () => {
    const {
      eventId,
      onSendInvitations,
      onChangeAddGuestsList,
      onChangeEventsModal,
      addGuestsList,
      onUpdateInvitationDelivery,
      onSetInvitationsCount
    } = this.props

    onSendInvitations({
      eventId,
      onSuccessCallback: data => {
        const { estimated_cost, available_credit, sending_ids } = data

        // pending > estimated_cost 有free。
        // available_credit >= estimated_cost 足够发送.
        // available_credit > 0 && available_credit < estimated_cost 发送一部分

        if (
          addGuestsList.length > estimated_cost ||
          available_credit >= estimated_cost ||
          (available_credit > 0 && available_credit < estimated_cost)
        ) {
          onUpdateInvitationDelivery(sending_ids)
          const invitationsCount = this.props.activeInvitations.filter(
            item => item.delivery_state !== 'pending'
          ).length

          onSetInvitationsCount(invitationsCount)
        }

        onChangeAddGuestsList([])
        onChangeEventsModal('guests')

        if (available_credit < estimated_cost) {
          this.openCheckoutModal(Number(estimated_cost) - Number(available_credit))
        }
      }
    })
  }

  initialAddGuestsList = () => {
    const { users, activeInvitations, onChangeAddGuestsList } = this.props
    const FilterInvitation = activeInvitations.filter(
      invitation => invitation.delivery_state === 'pending'
    )

    const mapInvitations = FilterInvitation.map(invitation => {
      const user = users[invitation.guest]
      return {
        key: invitation.id,
        id: invitation.id,
        avatar_url: user?.avatar.small_url_2x || invitation.avatar_url,
        first_name: invitation.first_name,
        last_name: invitation.last_name,
        user_id: invitation.guest,
        display_name: invitation.display_name,
        contact_method_display_name: invitation.contact_method_display_name,
        contact_method_hash: invitation.contact_method.hash,
        contact_method_type: invitation.contact_method.type,
        updated_at: invitation.updated_at,
        app_user: !!user?.app_user || !!invitation.app_user,
        verified: !!user?.verified || !!invitation.verified
      }
    })

    if (mapInvitations.length > 0) {
      onChangeAddGuestsList(mapInvitations)
    }

    this.setState({ initialComplete: true })
  }

  openCheckoutModal = credits => {
    this.props.checkout(credits)
  }

  componentDidMount() {
    // this.initialAddGuestsList()
  }

  render() {
    // if (!this.state.initialComplete) return <CommunitySkeleton />

    const isDesktop = window.innerWidth > 1080
    if (isDesktop) {
      return (
        <AddGuestsPageWeb
          onChangeEventsModal={this.props.onChangeEventsModal}
          onSaveGuests={this.handleSaveGuests}
          onRemoveNewGuests={this.handleRemoveNewGuests}
        />
      )
    } else {
      return (
        <div className="mobile-view">
          <AddGuestsPageMobile
            event={this.props.event}
            addGuestsList={this.props.addGuestsList}
            onChangeEventsModal={this.props.onChangeEventsModal}
            onSaveGuests={this.handleSaveGuests}
            onRemoveNewGuests={this.handleRemoveNewGuests}
          />
        </div>
      )
    }
  }
}

AddGuestsPage.propTypes = {
  userId: PropTypes.string,
  event: PropTypes.object,
  eventId: PropTypes.string,
  addGuestsList: PropTypes.array,
  onChangeAddGuestsList: PropTypes.func,
  onChangeEventsModal: PropTypes.func,
  onInsertMockInvitation: PropTypes.func,
  onSaveInvitations: PropTypes.func,
  onSendInvitations: PropTypes.func,
  onUpdateInvitationDelivery: PropTypes.func,
  onSetInvitationsCount: PropTypes.func,
  activeInvitations: PropTypes.array,
  users: PropTypes.object,
  checkout: PropTypes.func
}
