/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react'

import { Tooltip } from 'antd'
import DayPicker from '../day-picker/DayPicker'
import TimeRangePicker from '../time-range-picker/time-range-picker'
import SvgIcon from 'components/common/SvgIcon'
import clockIcon from '../../assets/icons/clock-icon.svg'
import rightArrow from 'assets/icons/right-arrow.svg'

import styles from './date-picker.scss'

const formatTimeList = timeList => {
  return timeList.map(item => {
    const { start, end } = item
    return `${start.hour}:${start.minute}${start.type} - ${end.hour}:${end.minute}${end.type}`
  })
}

const DatePicker = props => {
  const { selectedDateRange, handleSelectedDone } = props
  const [selectedDates, setSelectedDates] = useState(selectedDateRange.dates)
  const [selectedTimeList, setSelectedTimeList] = useState(selectedDateRange.timeList)
  const [isOpenTimeModal, setIsOpenTimeModal] = useState(false)

  const timeRangeList = useMemo(() => {
    return formatTimeList(selectedTimeList)
  }, [selectedTimeList])

  if (isOpenTimeModal) {
    return (
      <TimeRangePicker
        onCloseTimeModal={() => setIsOpenTimeModal(false)}
        selectedDates={selectedDates}
        selectedTimeList={selectedTimeList}
        onSelectedTimeSave={timeList => setSelectedTimeList(timeList)}
      />
    )
  }

  return (
    <div style={{ width: '350px' }}>
      <div className={styles['date-picker-title']}>
        <div className={styles['title-text']}>select dates</div>
        <span
          className={styles['done-button']}
          onClick={() => handleSelectedDone({ dates: selectedDates, timeList: selectedTimeList })}
        >
          Done
        </span>
      </div>

      <div className={styles['date-picker-time']} onClick={() => setIsOpenTimeModal(true)}>
        <div className={styles['time-title']}>
          <SvgIcon icon={clockIcon} style={{ width: 20 }} divStyle={{ flex: '0 0 auto' }} />
          <div>
            {selectedTimeList.length ? (
              <span>edit time</span>
            ) : (
              <Tooltip
                placement="bottom"
                color="#9013FE"
                title="Need to be more specific? Add times here"
              >
                <span>Add Time</span>
              </Tooltip>
            )}
          </div>
          <SvgIcon
            icon={rightArrow}
            style={{ width: 8, height: 15 }}
            divStyle={{ flex: '0 0 20px', textAlign: 'center' }} />
        </div>

        <div className={styles['date-picker-time-list']}>
          {timeRangeList.map((item, index) => (
            <span key={index}>{item}</span>
          ))}
        </div>
      </div>

      <DayPicker
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        onDeleteTime={dateId =>
          setSelectedTimeList(selectedTimeList.filter(time => time.dateId !== dateId))
        }
      />
    </div>
  )
}

export default DatePicker
