import React, { Component } from 'react'

import DashboardContainer from 'containers/DashboardContainer'
import Panel from '../components/Panel'
import poll_deleted from 'assets/icons/poll_deleted.png'
import { pollDeleted, signUpDeleted } from '../constants'
import { DESTINATION_TYPES_CONSTANTS } from 'src/constants'
import { parseEventUrl } from 'services/url_helpers'

import styles from './deleted-container.scss'
class DeletedPanel extends Component {
  getCreateType = () => {
    const { destinationType } = parseEventUrl(window.location.pathname)

    switch (destinationType) {
      case DESTINATION_TYPES_CONSTANTS.signUp:
        return signUpDeleted
      case DESTINATION_TYPES_CONSTANTS.timepoll:
        return pollDeleted
    }
  }

  render() {
    return (
      <DashboardContainer>
        <Panel hideBackButton notification={this.getCreateType()}>
          <img src={poll_deleted} alt="deleted" className={styles['iconProps']} />
        </Panel>
      </DashboardContainer>
    )
  }
}

export default DeletedPanel
