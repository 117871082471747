import { FlyerLayoutAbstract } from './flyer-layout.abstract'
import moment from 'moment/moment'

export default class Flyer_layout_split_sketch_border_003LayoutModel extends FlyerLayoutAbstract {
  flourishes = [
    {
      name: 'sketch_border',
      left: 0,
      top: 0,
      width: 320,
      height: 568
    }
  ]

  textLayouts = {
    event_name_1: {
      top: 75,
      size: 72,
      width: 290,
      height: 125,
      align: 'center'
    },
    location_1: {
      top: 438,
      left: 30,
      size: 24,
      width: 258,
      height: 30,
      align: 'center'
    },
    location_2: {
      top: 470,
      left: 30,
      size: 24,
      width: 258,
      height: 30,
      align: 'center'
    }
  }

  dateLayouts = {
    date_1: {
      top: 382,
      daySize: 24,
      width: 258,
      align: 'center'
    }
  }

  constructor(layout, eventData) {
    super(layout, eventData)
    this.init()
  }

  getDateTexts() {
    const startDate = this.eventData.starts_at
    const endDate = this.eventData.ends_at

    const { daySize, top, align, width } = this.dateLayouts.date_1

    const dateInformation = this.formatDates({
      day: 'ddd, MMM D, h:mmA',
      time: 'h:mmA'
    })

    const { formattedStart, formattedEnd, isSameDay } = dateInformation
    let dates = []
    let textTop = top

    // 开始时间
    if (endDate) {
      dates.push({
        text: isSameDay ? moment(startDate).format('ddd, MMM D') : formattedStart,
        align,
        width,
        top: textTop,
        color: this.dateProps.date1Props.color.replace('0x', '#'),
        font: this.dateProps.date1Props.font.replace(/\s+/g, '-'),
        size: daySize
      })

      textTop += daySize

      dates.push({
        text: isSameDay
          ? `${moment(startDate).format('h:mmA')} - ${moment(endDate).format('h:mmA')}`
          : formattedEnd,
        align,
        width,
        top: textTop,
        color: this.dateProps.date2Props.color.replace('0x', '#'),
        font: this.dateProps.date2Props.font.replace(/\s+/g, '-'),
        size: daySize
      })
    } else {
      dates.push({
        text: formattedStart,
        align,
        top: textTop,
        width,
        color: this.dateProps.date1Props.color.replace('0x', '#'),
        font: this.dateProps.date1Props.font.replace(/\s+/g, '-'),
        size: daySize
      })
    }

    return { type: 'date', dates }
  }
}
