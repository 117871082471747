/* eslint-disable react/prop-types */
import React, { useState, useMemo } from 'react'

import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import SvgIcon from 'components/common/SvgIcon'
import LeftArrow from '../../assets/icons/left-arrow.svg'
import moment from 'moment'
import { v4 as uuid } from 'uuid'

import 'assets/styles/react-big-calendar/styles.css'
import 'assets/styles/react-big-calendar/react-big-calendar.css'
import styles from './time-range-picker.scss'

const localizer = momentLocalizer(moment)
const DragAndDropCalendar = withDragAndDrop(Calendar)

const initialCalendarDate = selectedDates => {
  return Object.keys(selectedDates).map(key => {
    const selectedDate = selectedDates[key]
    if (moment(selectedDate.startDate).isSame(selectedDate.endDate, 'day')) {
      return {
        dateId: selectedDate.id,
        title: moment(selectedDate.startDate).format('MMM DD')
      }
    } else {
      return {
        dateId: selectedDate.id,
        title: `${moment(selectedDate.startDate).format('MMM DD')} - ${moment(
          selectedDate.endDate
        ).format('MMM DD')}`
      }
    }
  })
}

const initialCalendarEvent = selectedTimeList => {
  return selectedTimeList.map(item => {
    const { id, dateId, start, end } = item
    return {
      id,
      resourceId: dateId,
      start: moment(`${start.hour}:${start.minute} ${start.type}`, 'hh:mm A').toDate(),
      end: moment(`${end.hour}:${end.minute} ${end.type}`, 'hh:mm A').toDate()
    }
  })
}

const formatEvent = eventList => {
  return eventList.map(item => {
    const { id, resourceId, start, end } = item
    const startTime = moment(start)
    const endTime = moment(end)
    return {
      id,
      dateId: resourceId,
      start: {
        hour: startTime.format('h'),
        minute: startTime.format('mm'),
        type: startTime.format('A')
      },
      end: {
        hour: endTime.format('h'),
        minute: endTime.format('mm'),
        type: endTime.format('A')
      }
    }
  })
}

const TimeRangePicker = props => {
  const { selectedDates, selectedTimeList, onSelectedTimeSave, onCloseTimeModal } = props
  const [eventList, setEventList] = useState(initialCalendarEvent(selectedTimeList))

  const calendarFormats = useMemo(
    () => ({
      timeGutterFormat: (date, culture, localizer) => localizer.format(date, 'h a', culture),
      eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
        localizer.format(start, 'hh:mm a', culture) +
        ' - ' +
        localizer.format(end, 'hh:mm a', culture),
      selectRangeFormat: ({ start, end }, culture, localizer) =>
        localizer.format(start, 'hh:mm a', culture) +
        ' - ' +
        localizer.format(end, 'hh:mm a', culture)
    }),
    []
  )

  const eventPropGetter = () => ({
    style: {
      backgroundColor: 'rgba(144, 19, 254, 0.04)',
      color: '#9013FE',
      borderRadius: '4px',
      border: '2px solid #9013FE'
    }
  })

  const handleSaveTime = () => {
    onSelectedTimeSave(formatEvent(eventList))
    onCloseTimeModal()
  }

  const handleSelectSlot = slot => {
    const { start, end, resourceId } = slot
    setEventList([...eventList, { start, end, id: uuid(), resourceId }])
  }

  const handleMoveChange = ({ event, start, end }) => {
    const momentEndDay = moment(end)
    const endHour = momentEndDay.hour()
    const endMinute = momentEndDay.minute()

    if (endHour === 0 && endMinute === 0) {
      momentEndDay.subtract(1, 'minute')
    } else if (endMinute === 59 || endMinute === 29) {
      momentEndDay.add(1, 'minute')
    }

    const updatedEvent = { ...event, start, end: momentEndDay.toDate() }
    setEventList([...eventList.filter(item => item.id !== event.id), updatedEvent])
  }

  return (
    <div className={styles['time-range-wrapper']}>
      <div className={styles['time-range-title-wrapper']}>
        <div className={styles['back-button-wrapper']} onClick={() => onCloseTimeModal()}>
          <SvgIcon icon={LeftArrow} style={{ width: 20 }} />
          <span>dates</span>
        </div>
        <span className={styles['title-text']}>add Time</span>
        <span className={styles['save-button']} onClick={handleSaveTime}>
          save
        </span>
      </div>

      <div className={styles['time-range-calendar']}>
        <DragAndDropCalendar
          selectable
          resizable
          events={eventList}
          localizer={localizer}
          onEventDrop={handleMoveChange}
          onEventResize={handleMoveChange}
          onSelectSlot={handleSelectSlot}
          onSelectEvent={slot => setEventList(eventList.filter(item => item.id !== slot.id))}
          defaultView={Views.DAY}
          views={['day', 'work_week']}
          step={60}
          resources={initialCalendarDate(selectedDates)}
          resourceIdAccessor="dateId"
          resourceTitleAccessor="title"
          allDayAccessor={() => false}
          eventPropGetter={eventPropGetter}
          scrollToTime={new Date(0, 0, 0, 9)}
          longPressThreshold={10}
          formats={calendarFormats}
        />
      </div>
    </div>
  )
}
export default TimeRangePicker
