import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'

import SvgIcon from '../common/SvgIcon'
import totalGuestIcon from '../../assets/icons/total_guests_icon.svg'
import totalTicketsIcon from '../../assets/icons/total_tickets_icon.svg'
import styles from './total-tickets-counts.scss'
import { displayCurrency, pluralize, sumCount } from '../../services/utils'
import dateformat from 'dateformat'
import { maxBy } from 'lodash'
import { renderOrderStatus, getDisplayOrderTickets } from '../../services/payment_utils'

export default class TotalTicketsCounts extends Component {
  _sumPrice = (arr) => {
    let total = 0
    for (let i = 0; i < arr.length; i++) {
      const amount = arr[i].payment ? arr[i].payment.amount : 0
      total += amount
    }

    return total
  }

  _requestRefundPending = () => {
    const { listMyOrdersForEvent } = this.props
    const flag = listMyOrdersForEvent.some(order => {
      const status = renderOrderStatus(order.refund_requests, '')
      return status === 'pending'
    })

    return flag
  }

  render() {
    const { needPayTicket, ticketCounts, className, listMyOrdersForEvent, showPurchasedTime } = this.props
    const icon = needPayTicket ? totalTicketsIcon : totalGuestIcon
    const iconClassName = needPayTicket ? styles['total-tickets-icon'] : styles['total-guest-icon']

    const adultTicketCounts = ticketCounts.filter((item) => item.label === 'adult')
    const childTicketCounts = ticketCounts.filter((item) => item.label === 'child')
    const activeCounts = getDisplayOrderTickets(listMyOrdersForEvent, ticketCounts, 'active')
    const totalPrice = this._sumPrice(activeCounts)

    const lastOrder = maxBy(listMyOrdersForEvent, function (order) {
      return new Date(order.inserted_at)
    })

    const numTickets = sumCount(ticketCounts)
    const guestText = pluralize(numTickets, 'guest')

    return (
      <div className={ClassNames(styles['wrapper'], className)}>
        <SvgIcon icon={icon} className={iconClassName} divClassName={styles['icon-wrapper']} />

        <div className={styles['tickets-counts-wrapper']}>
          <div>
            <div className={styles['title']}>{numTickets} {needPayTicket ? 'tickets' : guestText}</div>
            {!!childTicketCounts.length &&
              <div
                className={styles['ticket-label']}>{`${sumCount(adultTicketCounts)} Adult, ${sumCount(childTicketCounts)} Child`}</div>
            }

            {
              needPayTicket && !!totalPrice &&
              <div className={styles['sub-label']}>Total Paid: {displayCurrency(totalPrice)}</div>
            }

            {
              (!needPayTicket || showPurchasedTime) && lastOrder &&
              <div className={styles['sub-label']}>{showPurchasedTime ? 'Purchased: ' : 'RSVP’d: '} {dateformat(lastOrder.inserted_at, 'mmm. dd, yyyy')}</div>
            }

            {
              needPayTicket && this._requestRefundPending() &&
              <div className={styles['order-status']}>Refund Request Pending</div>
            }
          </div>
        </div>
      </div>
    )
  }
}

TotalTicketsCounts.defaultProps = {
  listMyOrdersForEvent: [],
  showPurchasedTime: false
}

TotalTicketsCounts.propTypes = {
  needPayTicket: PropTypes.bool,
  ticketCounts: PropTypes.array,
  className: PropTypes.string,
  listMyOrdersForEvent: PropTypes.array,
  showPurchasedTime: PropTypes.bool
}
