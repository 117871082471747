import React, { Component } from 'react'
import DateSelector from './DateSelector/DateSelector'
import { changeEventForm } from 'src/actions/event'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import CloseIconBg from 'assets/icons/close-icon-bg.png'
import timezoneJSON from 'assets/frontend-json/timezone.json'
import HobnobSelect from 'components/common/HobnobSelect'
import cn from 'classnames'
import HobnobModal from 'components/common/HobnobModalV2/HobnobModal'
import { Select } from 'antd'

import styles from './date-modal.scss'

const optionGroups = timezoneJSON.timezone

class DateModal extends Component {
  state = {
    eventKey: null,
    showDateModal: false
  }

  handleSave = (date, time) => {
    const { onChangeEventForm, eventForm } = this.props
    const { eventKey } = this.state
    let { hour, minute, type } = time
    const formatDate = moment(date).format('YYYY-MM-DD')

    if (type === 'AM') {
      hour = hour === '12' ? '0' : hour
    } else {
      hour = hour === '12' ? hour : Number(hour) + 12
    }
    const dateTime = moment(`${formatDate} ${hour}:${minute}`, moment.defaultFormat).format(
      'YYYY-MM-DD HH:mm'
    )

    if (
      (eventKey === 'starts_at' &&
        eventForm.ends_at &&
        !moment(dateTime).isBefore(eventForm.ends_at)) ||
      (eventKey === 'ends_at' && !moment(eventForm.starts_at).isBefore(dateTime))
    ) {
      alert('Please select and ending date/time after the Start Time')
      return
    }

    onChangeEventForm({
      [eventKey]: dateTime
    })
    this.setState({ showDateModal: false })
  }

  componentDidUpdate(prevProps) {
    const { eventForm, onChangeEventForm } = this.props

    if (!prevProps.visible && this.props.visible && !eventForm.starts_at) {
      const getHour = moment().get('hour')
      onChangeEventForm({
        starts_at: moment().set('hour', getHour).set('minute', 0).format('YYYY-MM-DD HH:mm'),
        timezone: 'Pacific/Honolulu'
      })
    }
  }

  handleSaveTimezone = value => {
    this.props.onChangeEventForm({ timezone: value })
  }

  renderText = key => {
    const { eventForm } = this.props
    switch (key) {
      case 'starts_at':
      case 'ends_at':
        if (eventForm[key]) {
          return moment(eventForm[key]).format('ddd, MMM DD, YYYY, h:mm A')
        } else {
          return 'Optional End Time'
        }
    }
  }

  handleClickDate = key => {
    const { eventForm, onChangeEventForm } = this.props
    switch (key) {
      case 'starts_at':
        this.setState({ showDateModal: true, eventKey: key })
        break
      case 'ends_at':
        if (eventForm[key]) {
          this.setState({ showDateModal: true, eventKey: key })
        } else {
          onChangeEventForm({
            [key]: moment(eventForm.starts_at).add(1, 'h').format('YYYY-MM-DD HH:mm')
          })
        }
    }
  }

  handleDeleteDate = (e, key) => {
    const { onChangeEventForm, onClose } = this.props
    e.stopPropagation()
    switch (key) {
      case 'starts_at':
        onChangeEventForm({ starts_at: null, ends_at: null, timezone: 'Pacific/Honolulu' })
        onClose()
        break
      case 'ends_at':
        onChangeEventForm({ ends_at: null })
        break
    }
  }

  render() {
    const { eventForm, visible, onClose } = this.props
    const { eventKey } = this.state
    return (
      <>
        <HobnobModal visible={visible} onCancel={onClose}>
          <div className={styles['date-selector-container']}>
            <div className={styles['input-row']} onClick={() => this.handleClickDate('starts_at')}>
              <label>Start Date:</label>
              <div className={styles['input-wrapper']}>
                <span
                  className={cn(styles['text'], {
                    [styles['text-checked']]: !!eventForm.starts_at
                  })}
                >
                  {this.renderText('starts_at')}
                </span>
                <img
                  src={CloseIconBg}
                  width="18"
                  className={styles['icon']}
                  onClick={e => this.handleDeleteDate(e, 'starts_at')}
                />
              </div>
            </div>
            <div className={styles['input-row']} onClick={() => this.handleClickDate('ends_at')}>
              <label>End Date:</label>
              <div className={styles['input-wrapper']}>
                <span
                  className={cn(styles['text'], { [styles['text-checked']]: !!eventForm.ends_at })}
                >
                  {this.renderText('ends_at')}
                </span>
                <img
                  src={CloseIconBg}
                  width="18"
                  className={styles['icon']}
                  onClick={e => this.handleDeleteDate(e, 'ends_at')}
                />
              </div>
            </div>
            {eventForm.timezone && (
              <>
                <section className={cn(styles['input-row'], styles['mobile-web'])}>
                  <label>Timezone:</label>
                  <div className={styles['input-wrapper']}>
                    <HobnobSelect
                      value={eventForm.timezone}
                      onChange={this.handleSaveTimezone}
                      options={optionGroups}
                      className={styles['select-inner']}
                      closeOnOuterClick={false}
                    />
                  </div>
                </section>

                <section className={cn(styles['input-row'], 'web-view')}>
                  <label>Timezone:</label>
                  <Select
                    value={eventForm.timezone}
                    onChange={this.handleSaveTimezone}
                    className={styles['select-inner-web']}
                    options={optionGroups}
                    fieldNames={{ label: 'text' }}
                  />
                </section>
              </>
            )}
          </div>
        </HobnobModal>

        <DateSelector
          visible={this.state.showDateModal}
          onCancel={() => this.setState({ showDateModal: false })}
          onSave={this.handleSave}
          initialInputTime={eventForm[eventKey]}
        />
      </>
    )
  }
}

DateModal.propTypes = {
  visible: PropTypes.bool,
  eventForm: PropTypes.object,
  onChangeEventForm: PropTypes.func,
  onClose: PropTypes.func
}

function mapStateToProps(state) {
  return {
    eventForm: state.frontend.eventForm
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeEventForm: params => dispatch(changeEventForm(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DateModal)
