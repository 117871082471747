import PropTypes from 'prop-types'
import React from 'react'

import styles from './loading-indicator.scss'
/* eslint-disable import/no-webpack-loader-syntax */
import loadingIcon from '!raw-loader!src/assets/loading.svg'

export default function LoadingIndicator (props) {
  const size = props.size || '30px'
  const inlineStyles = {
    width: size,
    height: size
  }

  return (
    <div className={styles['indicator']} style={inlineStyles} dangerouslySetInnerHTML={{ __html: loadingIcon }} />
  )
}

LoadingIndicator.propTypes = {
  size: PropTypes.string
}
