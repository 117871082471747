import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { globalLayerSelector } from 'src/selectors'
import { changeVisible } from 'src/actions/globalLayer'
import HobnobModal from 'src/components/common/HobnobModalV2/HobnobModal'
import FreeMembership from './FreeMembership/FreeMembership'
import PendingPaymentResult from './PendingPaymentResult/PendingPaymentResult'
import SubscribeSuccess from './SubscribeSuccess/SubscribeSuccess'
import CancelMembership from './CancelMembership/CancelMembership'
import PurchaseCredit from './PurchaseCredit/PurchaseCredit'
import UpgradePlan from './UpgradePlan/UpgradePlan'
import CloseIconWhite from 'assets/icons/close_white.png'
import LoadingComponent from 'components/common/LoadingComponent/LoadingComponent'
import { GLOBAL_LAYER_CONTENT } from 'src/constants'
import UnsentInvites from './UnsentInvites/UnsentInvites'

import styles from './global-layer.scss'

const GlobalLayer = () => {
  const dispatch = useDispatch()
  const { visible, content, loading } = useSelector(globalLayerSelector)

  const handleCloseModal = () => {
    dispatch(changeVisible(false))
  }

  const renderLayer = () => {
    switch (content) {
      case GLOBAL_LAYER_CONTENT.pendingPaymentResult:
        return <PendingPaymentResult />
      case GLOBAL_LAYER_CONTENT.freeMembership:
        return <FreeMembership />
      case GLOBAL_LAYER_CONTENT.subscribeSuccess:
        return <SubscribeSuccess />
      case GLOBAL_LAYER_CONTENT.cancelMembership:
        return <CancelMembership />
      case GLOBAL_LAYER_CONTENT.purchaseCredit:
        return <PurchaseCredit />
      case GLOBAL_LAYER_CONTENT.unsentInvites:
        return <UnsentInvites />
      default:
        return <UpgradePlan />
    }
  }

  const renderCloseIcon = (
    <div className={styles['close-icon']}>
      <img src={CloseIconWhite} width="14" alt="close" />
    </div>
  )

  return (
    <HobnobModal
      visible={visible}
      destroyOnClose
      maskClosable={false}
      fullModal
      onCancel={handleCloseModal}
      closeIcon={renderCloseIcon}
    >
      {renderLayer()}
      {loading && (
        <div className={styles['loading-container']}>
          <LoadingComponent light type="partial" />
        </div>
      )}
    </HobnobModal>
  )
}

export default GlobalLayer
