import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  chatSelector,
  usersSelector,
  userIdSelector,
  activeInvitationsSelector
} from 'src/selectors'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import IcoGroupMessage from 'assets/icons/ico-group-message.png'
import IcoGuestMessage from 'assets/icons/ico-guest-message.png'
import RefreshIcon from 'assets/icons/refresh-icon.png'
import cn from 'classnames'
import { switchChatUser, fetchUnreadMessage } from 'src/actions/eventChat'
import {
  HOBNOB_USER_CONTACT_METHOD,
  PHONE_NUMBER_CONTACT_METHOD
} from 'src/services/contact_methods'
import { isEmptyString } from 'services/utils'

import styles from './chat-users.scss'

const ChatUsers = ({ chatSectionRef, onSetIsUnreadLoading }) => {
  const dispatch = useDispatch()
  const users = useSelector(usersSelector)
  const userId = useSelector(userIdSelector)
  const activeInvitations = useSelector(activeInvitationsSelector)
  const { chatUser, unreadMessageList } = useSelector(chatSelector)
  const [memberTags, setMemberTags] = useState([])
  const ulRef = useRef(null)

  const getDisplayName = invitation => {
    const { first_name, last_name, display_name } = invitation
    const user = users[invitation.guest]
    let displayName = ''
    if (user.verified) {
      displayName = `${user.first_name || ''} ${user.last_name || ''}`
    } else if (!isEmptyString(first_name + last_name)) {
      displayName = `${first_name || ''} ${last_name || ''}`
    } else {
      displayName = display_name
    }

    return displayName
  }

  const getUnreadMessage = () => {
    onSetIsUnreadLoading(true)
    dispatch(fetchUnreadMessage(() => onSetIsUnreadLoading(false)))
  }

  useEffect(() => {
    if (chatUser && chatUser !== 'allUsers' && ulRef.current) {
      const chatSectionEl = chatSectionRef.current
      const index = memberTags.findIndex(item => item.guest === chatUser.id)
      const selectedLiElement = ulRef.current.children[index]
      const containerRect = chatSectionEl.getBoundingClientRect()
      const liRect = selectedLiElement.getBoundingClientRect()

      const inView =
        liRect.top >= containerRect.top &&
        liRect.left >= containerRect.left &&
        liRect.bottom <= containerRect.bottom &&
        liRect.right <= containerRect.bottom

      if (!inView) {
        chatSectionEl.scrollTop = liRect.top - containerRect.top
      }
    }
  }, [memberTags])

  useEffect(() => {
    const filterMemberList = activeInvitations.filter(
      invitation => invitation.delivery_state !== 'pending' && invitation.guest !== userId
    )
    const sortedMemberList = filterMemberList.sort((a, b) => {
      const isAccepted_A = a.rsvp_state === 'accepted'
      const isAccepted_B = b.rsvp_state === 'accepted'

      const isUndecided_A = a.rsvp_state === 'undecided'
      const isUndecided_B = b.rsvp_state === 'undecided'

      const isDeclined_A = a.rsvp_state === 'declined'
      const isDeclined_B = b.rsvp_state === 'declined'

      const displayName_A = getDisplayName(a)
      const displayNameB = getDisplayName(b)

      const isHobnobUser_A =
        a.contact_method.type === HOBNOB_USER_CONTACT_METHOD ||
        a.contact_method.type === PHONE_NUMBER_CONTACT_METHOD

      const isHobnobUser_B =
        b.contact_method.type === HOBNOB_USER_CONTACT_METHOD ||
        b.contact_method.type === PHONE_NUMBER_CONTACT_METHOD

      const isUnread_A = unreadMessageList.find(item => {
        const user_A = item.entities.find(entity => entity.type === 'User')
        return user_A.id === a.guest
      })
      const isUnread_B = unreadMessageList.find(item => {
        const user_B = item.entities.find(entity => entity.type === 'User')
        return user_B.id === b.guest
      })

      const unreadTime_A = isUnread_A ? new Date(isUnread_A.activity_time).getTime() : 0
      const unreadTime_B = isUnread_B ? new Date(isUnread_B.activity_time).getTime() : 0

      if (!!isUnread_A === !!isUnread_B) {
        if (unreadTime_A === unreadTime_B) {
          if (isHobnobUser_A === isHobnobUser_B) {
            if (isAccepted_A === isAccepted_B) {
              if (isUndecided_A === isUndecided_B) {
                if (isDeclined_A === isDeclined_B) {
                  return displayName_A.localeCompare(displayNameB)
                } else {
                  return isDeclined_A ? -1 : 1
                }
              } else {
                return isUndecided_A ? -1 : 1
              }
            } else {
              return isAccepted_A ? -1 : 1
            }
          } else {
            return isHobnobUser_A ? -1 : 1
          }
        } else {
          return unreadTime_B - unreadTime_A
        }
      } else {
        return isUnread_A ? -1 : 1
      }
    })

    setMemberTags(sortedMemberList)
  }, [activeInvitations, unreadMessageList])

  const calcAcceptedCount = () => {
    return memberTags.filter(invitation => invitation.rsvp_state === 'accepted').length
  }

  const calculateTime = unread => {
    const timestamp = new Date(unread.activity_time).getTime()
    const nowTime = new Date().getTime()
    const time = Math.floor((nowTime - timestamp) / 1000)

    if (time < 60) {
      return `${time || 1}s`
    } else if (time >= 60 && time < 3600) {
      return `${Math.floor(time / 60)}m`
    } else if (time >= 3600 && time < 86400) {
      return `${Math.floor(time / 3600)}h`
    } else if (time >= 86400) {
      return `${Math.floor(time / 86400)}d`
    }
  }

  return (
    <>
      <div
        className={cn(
          styles['member-option'],
          chatUser === 'allUsers' && styles['member-option-selected']
        )}
        onClick={() => dispatch(switchChatUser('allUsers'))}
      >
        <img src={IcoGroupMessage} width={36} />
        <div className={styles['chat-text']}>Group Chat</div>
      </div>

      <div className={styles['member-option']} style={{ cursor: 'auto' }}>
        <img src={IcoGuestMessage} width={36} />
        <div className={styles['chat-text']}>
          Guest List&nbsp;
          <span className={styles['count']}>
            ({memberTags.length} Invited, {calcAcceptedCount()} Attending)
          </span>
        </div>
        <img
          src={RefreshIcon}
          className={styles['refresh-icon']}
          width={16}
          onClick={getUnreadMessage}
        />
      </div>

      <ul className={styles['member-ul']} ref={ulRef}>
        {memberTags.map(invitation => {
          const isSelected = chatUser?.id === invitation.guest
          const isCanChat =
            invitation.contact_method.type === HOBNOB_USER_CONTACT_METHOD ||
            invitation.contact_method.type === PHONE_NUMBER_CONTACT_METHOD

          const user = users[invitation.guest]

          const unreadMessage = unreadMessageList.find(item => {
            const user = item.entities.find(entity => entity.type === 'User')
            return user.id === invitation.guest
          })

          return (
            <li
              key={invitation.id}
              className={cn(
                styles['member-option'],
                isSelected && styles['member-option-selected'],
                !isCanChat && styles['member-option-disabled']
              )}
              onClick={() => dispatch(switchChatUser(users[invitation.guest]))}
            >
              <UserAvatarContainer user={user} size={30} />
              <span className={styles['name']}>{getDisplayName(invitation)}</span>
              {invitation.rsvp_state === 'accepted' && (
                <span className={styles['attending-text']}>Attending</span>
              )}

              {invitation.rsvp_state === 'declined' && (
                <span className={styles['declined-text']}>Not Attending</span>
              )}

              {unreadMessage && (
                <div className={styles['unread']}>
                  <span className={styles['time']}>{calculateTime(unreadMessage)}</span>
                  <span className={styles['dot']} />
                </div>
              )}
            </li>
          )
        })}
      </ul>
    </>
  )
}

ChatUsers.propTypes = {
  chatSectionRef: PropTypes.object,
  onSetIsUnreadLoading: PropTypes.func
}

export default ChatUsers
