import React, { Component } from 'react'
import styles from './medium-video.scss'
import PropTypes from 'prop-types'

class MediumVideo extends Component {
  render() {
    const { medium } = this.props

    return (
      <div className={styles['video-container']}>
        <video controls poster={medium.file.url_2x} className={styles['video-inner']}>
          <source src={medium.video_upload.video_url} type={medium.video_upload.mimetype} />
          <p>Sorry, your browser does not support the video provided</p>
        </video>
      </div>
    )
  }
}

MediumVideo.propTypes = {
  medium: PropTypes.object
}

export default MediumVideo
