import { fetchApi } from './api'

export const UPDATE_EVENT_COUNT = 'UPDATE_EVENT_COUNT'
export const UPDATE_EVENT_COUNT_SUCCESS = 'UPDATE_EVENT_COUNT_SUCCESS'
export const UPDATE_EVENT_COUNT_FAILED = 'UPDATE_EVENT_COUNT_FAILED'

export function eventViewCountRequest ({ eventId }) {
  return fetchApi({
    requestType: UPDATE_EVENT_COUNT,
    successType: UPDATE_EVENT_COUNT_SUCCESS,
    errorType: UPDATE_EVENT_COUNT_FAILED,
    route: '/graphql',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: eventViewCountsQuery(eventId)
      })
    },
    autoRetry: 1
  })
}

function eventViewCountsQuery (eventId) {
  const eventViewCounts = `
    event_view_count(event_id:"${eventId}"){
      successful
    }
  `
  return `mutation{
  ${eventViewCounts}
  }`
}
