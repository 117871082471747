import React, { useState, useContext, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import HeaderBar from 'components/HeaderBar/HeaderBar'
import Button from 'components/common/Button'
import SearchInput from '../../Components/SearchInput/SearchInput'
import Tags from '../../Components/Tags/Tags'
import GuestGroupsList from './GuestGroupsList/GuestGroupsList'
import NoticeRed from 'assets/icons/ico-notice-red.png'
import ActionSheet from 'components/common/ActionSheet/ActionSheet'
import PopConfirm from 'components/common/PopConfirm/PopConfirm'
import EditModal from '../../Components/EditModal/EditModal'
import { PHONE_NUMBER_CONTACT_METHOD, HOBNOB_USER_CONTACT_METHOD } from 'services/contact_methods'
import compWithGuestEditModal from '../HOC/WithGuestEditModal'
import { hasUserAccessToken } from 'services/access_tokens'
import { POPUP_BOX_TYPES_CONSTANTS } from 'src/constants'
import PageContainerContext from 'services/contexts'
import { deepCopyObject, isEmptyString } from 'services/utils'
import LoadingComponent from 'src/components/common/LoadingComponent/LoadingComponent'
import DMChat from './DMChat/DMChat'
import { switchChatUser } from 'src/actions/eventChat'

import styles from './guests-list-page-mobile.scss'

const EditModalHOC = compWithGuestEditModal(EditModal)

const GuestsListPageMobile = ({
  userId,
  isHost,
  isHostOrCohost,
  selectedGuest,
  event,
  users,
  searchValue,
  selectedTag,
  searchGuestsList,
  tags,
  onSelectGuest,
  onGetGuestsList,
  onSelectTag,
  onInputChange,
  onRemoveGuest,
  onEditGuest,
  onChangeEventsModal,
  onSendInvitations
}) => {
  const dispatch = useDispatch()
  const [isOpenActionSheet, setIsOpenActionSheet] = useState(false)
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false)
  const [actionSheetData, setActionSheetData] = useState([])
  const { onChangePopupBox, onChangePopupBoxCallBack } = useContext(PageContainerContext)
  const editModalRef = useRef(null)
  const failedCount = onGetGuestsList('failed').length
  const unSentCount = onGetGuestsList('unsent').length
  const createdCount = onGetGuestsList('created').length
  const isCreating = createdCount !== unSentCount

  useEffect(() => {
    let timeout = null
    timeout = setTimeout(() => {
      history.pushState(null, null, location.href)
      onChangePopupBox(POPUP_BOX_TYPES_CONSTANTS.guestsList)
      onChangePopupBoxCallBack(() => onChangeEventsModal('details'))
    }, 1000)

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
      onChangePopupBox(null)
      onChangePopupBoxCallBack(null)
    }
  }, [])

  const getUser = item => {
    const { guest, first_name, last_name, display_name } = item

    const hobnobUser = deepCopyObject(users[guest])
    const isHost = event.host === guest

    const user = hobnobUser || item

    if (isHost && !isEmptyString(event.custom_host_name)) {
      user.displayName = event.custom_host_name
    } else if (user.verified) {
      user.displayName = `${user.first_name || ''} ${user.last_name || ''}`
    } else if (!isEmptyString(first_name + last_name)) {
      user.displayName = `${first_name || ''} ${last_name || ''}`
    } else {
      user.displayName = display_name
    }

    return user
  }

  const generateActionData = checkedGuest => {
    const actionList = []

    const renderFullNameTag = () => {
      if (!checkedGuest) return null

      const user = users[checkedGuest.guest]
      if (user?.verified) return null
      return (
        <li key="contact" onClick={handleOpenEditModal}>
          edit contact
        </li>
      )
    }

    const renderCohostTag = () => {
      if (!checkedGuest) return null

      const { contact_method, cohost } = checkedGuest
      if (
        !isHost ||
        (contact_method.type !== PHONE_NUMBER_CONTACT_METHOD &&
          contact_method.type !== HOBNOB_USER_CONTACT_METHOD) ||
        (checkedGuest.delivery_state !== 'delivered' && checkedGuest.delivery_state !== 'viewed') ||
        event.host === checkedGuest.guest
      ) {
        return null
      }

      const style = cohost ? { color: '#d00218' } : { textTransform: 'initial' }
      const params = cohost ? { cohost: false } : { cohost: true }

      return (
        <li key="cohost" style={style} onClick={() => handleEditGuest(params)}>
          {cohost ? 'remove cohost' : 'Add as Cohost'}
        </li>
      )
    }

    const renderRemoveTag = () => {
      if (!checkedGuest) return null
      if (!isHostOrCohost) return null
      return (
        <li
          onClick={() => {
            setIsOpenRemoveModal(true)
            setIsOpenActionSheet(false)
          }}
          style={{ color: '#d00218' }}
          key="remove"
        >
          remove guest
        </li>
      )
    }

    const renderMessageTag = () => {
      if (!checkedGuest) return null
      const { contact_method, guest } = checkedGuest

      const isCanChat =
        (contact_method?.type === HOBNOB_USER_CONTACT_METHOD ||
          contact_method?.type === PHONE_NUMBER_CONTACT_METHOD) &&
        hasUserAccessToken() &&
        checkedGuest.guest !== userId

      if (isCanChat) {
        return (
          <li
            key="message"
            onClick={() => {
              const user = users[guest]
              dispatch(switchChatUser(user))
              setIsOpenActionSheet(false)
            }}
          >
            Send Message
          </li>
        )
      }

      return null
    }

    const fullNameEl = renderFullNameTag()
    const cohostEl = renderCohostTag()
    const removeEl = renderRemoveTag()
    const messageEl = renderMessageTag()

    if (fullNameEl) actionList.push(fullNameEl)
    if (cohostEl) actionList.push(cohostEl)
    if (messageEl) actionList.push(messageEl)
    if (removeEl) actionList.push(removeEl)

    return actionList
  }

  const renderButton = () => {
    if (!isHostOrCohost || !hasUserAccessToken()) return null

    const isHaveUnsent = unSentCount > 0

    return (
      <div className={styles['button-wrapper']}>
        <Button
          className={styles['button-inner']}
          onClick={() => {
            window.mixpanel.track('ADD GUESTS Clicked', { event_id: event.id, user_id: userId })
            onChangeEventsModal('addGuests')
          }}
          plain={isHaveUnsent}
        >
          add guests
        </Button>

        {isHaveUnsent && (
          <Button
            disabled={isCreating}
            className={styles['button-inner']}
            onClick={onSendInvitations}
          >
            {isCreating ? `${createdCount}/${unSentCount} CREATED` : `SEND ${unSentCount} INVITES`}
          </Button>
        )}
      </div>
    )
  }

  const handleOpenEditModal = () => {
    editModalRef.current.openModal(selectedGuest)
    setIsOpenActionSheet(false)
  }

  const handleEditGuest = params => {
    onEditGuest(params)
    setIsOpenActionSheet(false)
  }

  const handleRemoveGuest = () => {
    onRemoveGuest()
    setIsOpenRemoveModal(false)
  }

  const handleClickGuest = guest => {
    if (guest.guest !== event.host) {
      onSelectGuest(guest)
      const data = generateActionData(guest)
      if (data.length > 0) {
        setActionSheetData(data)
        setIsOpenActionSheet(true)
      }
    }
  }

  const displayName = getUser(selectedGuest || {}).displayName

  return (
    <div className={styles['guests-list-mobile-container']}>
      <HeaderBar backIcon>
        <div className={styles['header-title']}>
          <span className={styles['title']}>guest list</span>
          <span className={styles['subtitle']}>{event.name}</span>
        </div>
      </HeaderBar>

      {isCreating ? (
        <div className={styles['loading-container']}>
          <LoadingComponent light type="partial" />
        </div>
      ) : (
        <div className={styles['content-container']}>
          <SearchInput value={searchValue} onChange={onInputChange} />

          {failedCount > 0 && (
            <div className={styles['failed-text']}>
              <img src={NoticeRed} width="14" />
              You have {failedCount} failed invites!
            </div>
          )}
          <Tags
            tagsList={tags}
            selectedTag={selectedTag}
            onSelectTag={onSelectTag}
            onGetGuestsList={onGetGuestsList}
          />

          <GuestGroupsList
            guestsList={searchValue ? searchGuestsList : onGetGuestsList()}
            onClickGuest={handleClickGuest}
            onGetUser={getUser}
          />
        </div>
      )}

      {renderButton()}

      <ActionSheet
        visible={isOpenActionSheet}
        onClose={() => setIsOpenActionSheet(false)}
        headerNode={
          <div className={styles['action-sheet-header']}>
            <span className={styles['title']}>{displayName}</span>
            <span className={styles['subtitle']}>{selectedGuest?.contact_method_display_name}</span>
          </div>
        }
      >
        <ul>{actionSheetData.map(item => item)}</ul>
      </ActionSheet>

      <DMChat />

      <PopConfirm
        visible={isOpenRemoveModal}
        onClose={() => setIsOpenRemoveModal(false)}
        onConfirm={handleRemoveGuest}
        confirmText="remove"
        type="delete"
      >
        Are you sure you want to remove “{displayName}” from your guest list?
      </PopConfirm>

      <EditModalHOC ref={editModalRef} onEditGuest={handleEditGuest} />
    </div>
  )
}

GuestsListPageMobile.propTypes = {
  userId: PropTypes.string,
  isHost: PropTypes.bool,
  isHostOrCohost: PropTypes.bool,
  selectedGuest: PropTypes.object,
  event: PropTypes.object,
  users: PropTypes.object,
  searchValue: PropTypes.string,
  selectedTag: PropTypes.string,
  searchGuestsList: PropTypes.array,
  tags: PropTypes.array,
  onSelectGuest: PropTypes.func,
  onGetGuestsList: PropTypes.func,
  onSelectTag: PropTypes.func,
  onInputChange: PropTypes.func,
  onRemoveGuest: PropTypes.func,
  onEditGuest: PropTypes.func,
  onChangeEventsModal: PropTypes.func,
  onSendInvitations: PropTypes.func
}

export default GuestsListPageMobile
