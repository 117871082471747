import { schema } from 'normalizr'

export const Event = new schema.Entity('events')
export const EventUpdate = new schema.Entity('event_updates')
export const EventComment = new schema.Entity('event_comments')
export const Invitation = new schema.Entity('invitations')
export const Medium = new schema.Entity('media')
export const MediumComment = new schema.Entity('media_comments')
export const ContactMethod = new schema.Entity('contact_methods')
export const User = new schema.Entity('users')
export const Like = new schema.Entity('likes')
export const Flag = new schema.Entity('flags')
export const Link = new schema.Entity('links')
export const MessageContainer = new schema.Entity('message_container')
export const Polls = new schema.Entity('polls')
export const Ids = new schema.Entity('ids')

Medium.define({
  user: User,
  like: Like,
  flag: Flag,
  recent_likes: [Like]
})

const commentDefinition = {
  user: User
}

MediumComment.define(commentDefinition)

Event.define({
  invitations: [Invitation],
  host: User,
  links: [Link]
})

EventUpdate.define({
  user: User
})

EventComment.define(commentDefinition)

Invitation.define({
  guest: User,
  user: User,
  host_for_invitation: User
})

Like.define({
  user: User
})
