import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchEvent, cacheEvent, fetchEventUpdateById } from 'src/actions/event'
import { eventsSelector, eventIdSelector, usersSelector, invitationsSelector } from 'src/selectors'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import HobnobLogoCircle from 'src/assets/icons/hobnob-logo-circle.png'
import { Skeleton } from 'antd'
import { ActivitySlugs } from '../ActivityFeedInner'
import { fullName, firstName } from 'src/services/user_helpers'
import { redirectDomain } from 'services/url_helpers'

import styles from './activity-feed-item.scss'

const EventNotification = ({ activitySlug, activityItems }) => {
  const dispatch = useDispatch()
  const events = useSelector(eventsSelector)
  const users = useSelector(usersSelector)
  const invitations = useSelector(invitationsSelector)
  const [isLoading, setIsLoading] = useState(true)
  const [eventUpdate, setEventUpdate] = useState(null)
  const { entities, timestamp, read } = activityItems[0]
  const eventId = entities.find(item => item.type === 'Event').id
  const actorId = entities.find(item => item.type === 'User')?.id
  const invitationId = entities.find(item => item.type === 'Invitation')?.id
  const eventUpdateId = entities.find(item => item.type === 'EventUpdate')?.id
  const isInEventPage = eventId === useSelector(eventIdSelector)
  const event = events[eventId]
  let invitation = null

  useEffect(() => {
    let _isMounted = true

    if (!events.hasOwnProperty(eventId)) {
      dispatch(cacheEvent(eventId))
      dispatch(fetchEvent(eventId, null, false))
    }

    if (eventUpdateId) {
      dispatch(
        fetchEventUpdateById({
          eventId,
          eventUpdateId,
          onSuccessCallback: data => {
            if (_isMounted) {
              setEventUpdate(data.event_update)
            }
          }
        })
      )
    }

    return () => (_isMounted = false)
  }, [])

  useEffect(() => {
    if (event) {
      setIsLoading(false)
    }
  }, [events])

  if (isLoading) {
    return (
      <div style={{ padding: '0 16px' }}>
        <Skeleton active avatar paragraph={{ rows: 2 }} />
      </div>
    )
  }

  if (event.destroyed_at) {
    return null
  }

  if (isInEventPage) {
    invitation = invitations[invitationId]
  } else {
    invitation = event.invitations.find(item => item.id === invitationId)
  }

  const getActorUser = () => {
    switch (activitySlug) {
      case ActivitySlugs.LegacyUserInvitationDelivered:
      case ActivitySlugs.LegacyUserCohostInvitation:
      case ActivitySlugs.LegacyUserCreatedEventUpdate:
      case ActivitySlugs.InvitationTwoDayReminder:
        return isInEventPage ? users[event.host] : event.host
      case ActivitySlugs.LegacyUserInvitationDeclined:
      case ActivitySlugs.LegacyUserInvitationAccepted:
      case ActivitySlugs.FailedInvitationDelivery:
        return isInEventPage ? users[actorId] : invitation.guest
      default:
        return null
    }
  }

  const calculateTime = () => {
    const currentTime = new Date().getTime()
    const eventTime = new Date(timestamp).getTime()
    const time = (currentTime - eventTime) / 1000
    if (time < 60) {
      return `Just Now`
    } else if (time >= 60 && time < 3600) {
      return `${Math.floor(time / 60)}m`
    } else if (time >= 3600 && time < 86400) {
      return `${Math.floor(time / 3600)}h`
    } else if (time >= 86400) {
      return `${Math.floor(time / 86400)}d`
    }
  }

  const actorUser = getActorUser()
  const renderUserAvatar = () => {
    switch (activitySlug) {
      case ActivitySlugs.LegacyUserInvitationDelivered:
      case ActivitySlugs.LegacyUserInvitationDeclined:
      case ActivitySlugs.LegacyUserInvitationAccepted:
      case ActivitySlugs.LegacyUserCohostInvitation:
      case ActivitySlugs.LegacyUserCreatedEventUpdate:
      case ActivitySlugs.FailedInvitationDelivery:
        return <UserAvatarContainer user={actorUser} size={43} />
      case ActivitySlugs.InvitationHourBeforeReminder:
      case ActivitySlugs.InvitationTwoDayReminder:
      case ActivitySlugs.invitationDayBeforeReminder:
      case ActivitySlugs.invitationDraftOneDayReminder:
      case ActivitySlugs.invitationDraftTwoDayReminder:
        return <img src={HobnobLogoCircle} width={45} />
    }
  }

  const getRsvpButton = () => {
    switch (invitation.rsvp_state) {
      case 'undecided':
        return <div className={styles['button-text']}>RSVP Now</div>
      case 'accepted':
        return <div className={styles['normal-text']}>RSVP: I'm going</div>
      case 'declined':
        return <div className={styles['normal-text']}>RSVP: Can't go</div>
    }
  }

  const renderContent = () => {
    let title = null
    let text = null
    let button = null
    switch (activitySlug) {
      case ActivitySlugs.LegacyUserInvitationDelivered:
        title = <b>{fullName(actorUser)}</b>
        text = (
          <>
            Invited you to <b>{event.name}</b>
          </>
        )
        button = getRsvpButton()
        break
      case ActivitySlugs.LegacyUserInvitationDeclined:
        title = <b>{firstName(actorUser)}</b>
        text = (
          <>
            <b>Can't go</b> to <b>{event.name}</b>
          </>
        )
        break
      case ActivitySlugs.LegacyUserInvitationAccepted:
        title = <b>{firstName(actorUser)}</b>
        text = (
          <>
            is <b>Going</b> to <b>{event.name}</b>
          </>
        )
        break

      case ActivitySlugs.InvitationHourBeforeReminder:
        text = (
          <>
            <b>{event.name}</b> is coming up in an hour!
          </>
        )
        button = <div className={styles['button-text']}>Get Directions</div>
        break
      case ActivitySlugs.LegacyUserCohostInvitation:
        title = <b>{fullName(actorUser)}</b>
        text = (
          <>
            Added you as a <b>co-host</b> for <b>{event.name}</b>
          </>
        )
        button = (
          <>
            {getRsvpButton()}
            <span className={styles['interval-point']} />
            <div className={styles['button-text']} onClick={handleInviteAction}>
              Invite Guests
            </div>
          </>
        )
        break
      case ActivitySlugs.LegacyUserCreatedEventUpdate:
        title = <b>{fullName(actorUser)}</b>
        text = (
          <>
            <b>Sent event update: </b> {eventUpdate?.content}
          </>
        )
        break
      case ActivitySlugs.FailedInvitationDelivery:
        title = <b>{firstName(actorUser)}</b>
        text = (
          <>
            <b>We couldn't deliver you invite:</b> We couldn't deliver your message. Try a different
            phone number.
          </>
        )
        break
      case ActivitySlugs.InvitationTwoDayReminder:
        text = (
          <>
            Don't forget: {fullName(actorUser)} invited you to <b>{event.name}</b>, can you go?
          </>
        )
        button = getRsvpButton()
        break
      case ActivitySlugs.invitationDayBeforeReminder:
        text = (
          <>
            <b>{event.name}</b> is tomorrow
          </>
        )
        button = getRsvpButton()
        break
      case ActivitySlugs.invitationDraftOneDayReminder:
        text = (
          <>
            Almost done! Your draft event <b>{event.name}</b> is ready to send.
          </>
        )
        button = <div className={styles['button-text']}>View Event</div>
        break
      case ActivitySlugs.invitationDraftTwoDayReminder:
        text = (
          <>
            Still want to host <b>{event.name}</b>? Learn more about <b>how to send for free.</b>
          </>
        )
        button = <div className={styles['button-text']}>Learn More</div>
        break
    }

    return (
      <>
        {title && <p>{title}</p>}
        {text && (
          <p className={styles['text']}>
            {text}
            <span className={styles['time-text']}> {calculateTime()}</span>
          </p>
        )}
        {button && <div className={styles['button-wrapper']}>{button}</div>}
      </>
    )
  }

  const handleRedirectEvent = () => {
    switch (activitySlug) {
      case ActivitySlugs.FailedInvitationDelivery:
      case ActivitySlugs.LegacyUserInvitationAccepted:
      case ActivitySlugs.LegacyUserInvitationDeclined:
        redirectDomain(`${event.url}/#guests`, true)
        break
      case ActivitySlugs.invitationDraftTwoDayReminder:
        window.open(
          'https://help.hobnob.app/article/h2z1tylegk-how-can-i-send-invites-for-free',
          '_blank'
        )
        break
      case ActivitySlugs.LegacyUserCreatedEventUpdate:
        redirectDomain(`${event.url}/#updates`, true)
        break
      default:
        redirectDomain(event.url, true)
    }
  }

  const handleInviteAction = e => {
    e.stopPropagation()
    redirectDomain(`${event.url}/#guests`)
  }

  return (
    <li className={styles['activity-feed-item-container']} onClick={handleRedirectEvent}>
      {!read && <div className={styles['read-dot']} />}
      {renderUserAvatar()}
      <div className={styles['content']}>{renderContent()}</div>
      <img src={event.flyer?.thumbnail_url} className={styles['flyer-img']} />
    </li>
  )
}

EventNotification.propTypes = {
  activitySlug: PropTypes.string,
  activityItems: PropTypes.array
}

export default EventNotification
