import { eventChannel, delay } from 'redux-saga'
import { call, select, spawn, take } from 'redux-saga/effects'

import { PUSHER_EVENT_RECEIVED, pusherEventReceived } from 'src/actions'
import { REFRESH_TOKEN_SUCCESS } from 'src/actions/accessTokens'
import { eventSelector } from 'src/selectors'

import { apiDefaultHeaders } from 'universal/services/api_client'

import { getOrRefreshAccessToken } from './access_tokens'

let pusherPromise = new Promise((resolve) => {
  setTimeout(() => {
    require.ensure([], (require) => {
      const Pusher = require('pusher-js')
      resolve(Pusher)
    })
  }, 2000)
})

let pusherInstanceDeferred = {}
pusherInstanceDeferred.promise = new Promise((resolve, reject) => {
  pusherInstanceDeferred.resolve = resolve
  pusherInstanceDeferred.reject = reject
})

let pusherChannelEmitter = null
export const pusherEventChannel = eventChannel((emitter) => {
  pusherChannelEmitter = emitter
  // We're required to include an unsubscribe function, but in this case it does nothing
  return () => {}
})

export default function * pusherRootSaga () {
  yield spawn(updateAccessTokenWorker)
}

// Keep the access token that pusher uses updated
function * updateAccessTokenWorker () {
  const pusher = yield pusherInstanceDeferred.promise
  while (true) {
    const action = yield take(REFRESH_TOKEN_SUCCESS)
    const accessToken = action.response.access_token
    pusher.config.auth.headers = apiDefaultHeaders(accessToken)
  }
}

// Needs to be exported because it is run as a dynamic saga so that it has a reference to dispatch
export function * pusherInit (dispatch) {
  const Pusher = yield pusherPromise
  yield spawn(updateAccessTokenOnAuthFailure, dispatch)
  const accessToken = (yield getOrRefreshAccessToken()).token
  let pusher = new Pusher(process.env.PUSHER_APP_KEY, {
    encrypted: process.env.NODE_ENV === 'production',
    authEndpoint: `${process.env.API_BASE_URL}/pusher/auth`,
    auth: {
      headers: apiDefaultHeaders(accessToken)
    }
  })
  pusher.config.auth.headers.Authorization = 'Bearer 83d55a0b9953b9dd58136b1e1176d996e2f9380f3a78aabb5c511d5a93f8a803'

  window.pusher = pusher
  pusherInstanceDeferred.resolve(pusher)

  yield call(subscribeToPusherChannelWorker, dispatch)
}

export function * updateAccessTokenOnAuthFailure (dispatch) {
  while (true) {
    const action = yield take(PUSHER_EVENT_RECEIVED)

    if (action.payload.eventType === 'pusher:subscription_error') {
      yield call(subscribeToPusherChannelWorker, dispatch)
    }
  }
}

function * subscribeToPusherChannelWorker (dispatch) {
  const pusher = yield pusherInstanceDeferred.promise
  const event = yield select(eventSelector)
  const accessToken = (yield getOrRefreshAccessToken()).token
  pusher.config.auth.headers = apiDefaultHeaders(accessToken)

  if (event && event.pusher_channel) {
    const channel = pusher.channels.channels[event.pusher_channel]
    if (!channel || !channel.subscribed) {
      subscribeToPusherChannel(pusher, event.pusher_channel, dispatch)
      // Give pusher time to subscribe
      yield delay(1500)
    }
  }
}

function subscribeToPusherChannel (pusher, channelName, dispatch) {
  const dispatchPusherEvent = (...args) => dispatch(pusherEventReceived(...args))
  const emitEventChannel = (...args) => pusherChannelEmitter(pusherEventReceived(...args))

  let channel = pusher.subscribe(channelName)

  const allowedTypes = [
    'event-update-created',
    'event-update-updated',
    'event-update-destroyed',
    'event-updated',
    'medium-destroyed',
    'medium-created',
    'photo-processed',
    'invitation-created',
    'invitation-updated',
    'invitation-destroyed',
    'comment-created',
    'comment-updated',
    'comment-destroyed',
    'like-created',
    'like-updated',
    'like-destroyed',
    'link-created',
    'link-updated',
    'link-destroyed',

    // Pusher events
    'pusher:subscription_error'
  ]

  channel.bind_all(function (eventType, data) {
    if (eventType === 'pusher:subscription_error') {
      console.log('found subscription error')
    }
    if (allowedTypes.indexOf(eventType) > -1) {
      // Emit to event channel first so that we can take action before redux reducers fire
      emitEventChannel(eventType, data)
      dispatchPusherEvent(eventType, data)
    } else {
      console.log('ignoring ', eventType)
    }
  })
}
