import React, { Component } from 'react'

import HobnobModal from 'components/common/HobnobModal'
import Option from './Option/Option'
import { fetchSignUp, list_update } from 'src/actions/timepoll'
import {
  eventSelector,
  invitationSelector,
  isHostOrCohostSelector,
  userSelector
} from 'src/selectors'
import UnionIconAdd from 'assets/icons/union-icon-add.png'
import { connect } from 'react-redux'
import SULList from './SULList/SULList'
import CloseIcon from 'assets/icons/close-icon-bg.png'
import Button from 'components/common/Button'

import PropTypes from 'prop-types'
import styles from './sign-up-list-container.scss'

class SignUpListContainer extends Component {
  state = {
    showModal: false,
    showRSVPToast: false,
    isOpenAddSULModal: false,
    isOpenDeleteModal: false,
    currentSUL: null
  }

  openModal = token => {
    const { onGetSignUpDetails } = this.props

    onGetSignUpDetails({
      token,
      onSuccessCallback: () => {
        this.setState({ showModal: true })
      }
    })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  handleDeleteSUL = sul => {
    this.setState({ isOpenDeleteModal: true, currentSUL: sul })
  }

  handleChangeSUL = (sul, type) => {
    const { onSignUpFormSubmit, event } = this.props
    const list_params = {
      id: sul.id,
      event_id: type === 'remove' ? '' : event.id
    }

    const list_options_params = sul.poll_options?.map(item => ({
      id: item.id,
      count_limit: item.count_limit,
      description: item.description,
      item: item.item,
      item_rank: item.item_rank
    }))

    onSignUpFormSubmit({
      list_params,
      list_options_params,
      eventId: event.id,
      onSuccessCallback: data => {
        if (data.data.list_update.successful) {
          this.setState({ isOpenAddSULModal: false })
        }
      },
      onFailCallback: data => {
        if (!data.data.list_update.successful) {
          alert(data.data.list_update.messages[0])
        }
      }
    })

    this.setState({ isOpenDeleteModal: false, currentSUL: null })
  }

  isShowDelete = option => {
    const { isHostOrCohost, user } = this.props
    return isHostOrCohost && user?.id === option.creator.id
  }

  render() {
    const {
      event: { lists },
      invitation,
      isHostOrCohost
    } = this.props
    const { isOpenAddSULModal, isOpenDeleteModal } = this.state

    return (
      <div className={styles['sul-container']}>
        <h3 className={styles['header']}>sign up list</h3>
        <ul className={styles['sul-wrapper']}>
          {lists?.map(item => (
            <li className={styles['sul-li']} key={item.id}>
              <img className={styles['sul-img']} src={item.image_upload?.image_url} />
              <div className={styles['sul-information']} onClick={() => this.openModal(item.token)}>
                <div className={styles['name']}>{item.name}</div>
                <div className={styles['notes']}>{item.notes}</div>
              </div>
              {this.isShowDelete(item) && (
                <div
                  className={styles['close-icon-container']}
                  onClick={() => this.handleDeleteSUL(item)}
                >
                  <img src={CloseIcon} width="20" />
                </div>
              )}
            </li>
          ))}
        </ul>

        {isHostOrCohost && (
          <div
            className={styles['sign-up-add-button']}
            onClick={() => this.setState({ isOpenAddSULModal: true })}
          >
            <img src={UnionIconAdd} alt="" width="20" />
            <div>Add a Sign Up List</div>
          </div>
        )}

        <HobnobModal
          containerStyles={{ padding: 0 }}
          showCancelIcon={false}
          show={this.state.showModal}
          onClose={this.closeModal}
        >
          <Option onClose={this.closeModal} invitation={invitation} />
        </HobnobModal>

        <HobnobModal
          show={isOpenAddSULModal}
          onClose={() => this.setState({ isOpenAddSULModal: false })}
        >
          <SULList onChange={this.handleChangeSUL} />
        </HobnobModal>

        <HobnobModal
          containerStyles={{ padding: 0 }}
          show={isOpenDeleteModal}
          onClose={() => this.setState({ isOpenDeleteModal: false })}
          showCancelIcon={false}
          closeOnOuterClick={false}
        >
          <div className={styles['delete-modal']}>
            <div className={styles['title']}>Remove Sign Up List</div>
            <div className={styles['subTitle']}>
              This will remove this Sign Up List from your Event. It will remain active in the Polls
              & Lists section of Hobnob
            </div>
            <Button
              className={styles['button-inner']}
              onClick={() => this.handleChangeSUL(this.state.currentSUL, 'remove')}
            >
              remove list
            </Button>
            <Button
              className={styles['button-inner-white']}
              onClick={() => this.setState({ isOpenDeleteModal: false, currentSUL: null })}
            >
              cancel
            </Button>
          </div>
        </HobnobModal>
      </div>
    )
  }
}

SignUpListContainer.propTypes = {
  event: PropTypes.object,
  onGetSignUpDetails: PropTypes.func,
  invitation: PropTypes.object,
  isHostOrCohost: PropTypes.bool,
  onSignUpFormSubmit: PropTypes.func,
  user: PropTypes.object
}

function mapDispatchToProps(dispatch) {
  return {
    onGetSignUpDetails: params => dispatch(fetchSignUp(params)),
    onSignUpFormSubmit: params => dispatch(list_update(params))
  }
}

function mapStateToProps(state) {
  return {
    event: eventSelector(state),
    invitation: invitationSelector(state),
    isHostOrCohost: isHostOrCohostSelector(state),
    user: userSelector(state)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpListContainer)
