import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import styles from './text-input.scss'

export default class TextInputElement extends React.Component {
  render() {
    const { className, onChange, valid, value, ...otherProps } = this.props

    const validClassName = valid ? '' : styles['invalid']

    return (
      <input
        type="text"
        autoComplete="off"
        className={classnames(validClassName, className)}
        value={value || ''}
        onChange={(e) => onChange?.(e)}
        {...otherProps}
      />
    )
  }
}

TextInputElement.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  valid: PropTypes.bool,
  value: PropTypes.any
}
