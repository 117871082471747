import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  mediaSelector,
  mediumMoreTooltipMediumIdSelector,
  isHostSelector,
  userIdSelector,
  mediumMoreTooltipClickCoordinatesSelector,
  flagsSelector,
  mediumMoreTooltipVisibleSelector
} from 'src/selectors'

import { hideTooltip } from 'src/actions/frontend'

import {
  destroyMedium,
  createMediumFlag,
  destroyMediumFlag
} from 'src/actions/media'

import TooltipModal from 'src/components/common/TooltipModal'
import MediumMoreTooltip from './MediumMoreTooltip'

export class MediumMoreTooltipModalContainer extends Component {
  _destroyMedium = () => this.props.dispatch(destroyMedium(this.props.medium));
  _hideTooltip = () => this.props.dispatch(hideTooltip());
  _toggleFlag = () => {
    const { dispatch, flags, medium } = this.props
    if (medium.flag) {
      dispatch(destroyMediumFlag(medium, flags[medium.flag]))
    } else {
      dispatch(createMediumFlag(medium))
    }
  };

  render () {
    const { canRemoveMedium, clickCoordinates, visible } = this.props

    return (
      <TooltipModal
        clickCoordinates={clickCoordinates}
        direction='right'
        onClose={this._hideTooltip}
        visible={visible}
      >
        {visible ? (
          <MediumMoreTooltip
            canRemoveMedium={canRemoveMedium}
            onHideTooltip={this._hideTooltip}
            visible={visible}
            onDestroyMedium={this._destroyMedium}
            onToggleFlag={this._toggleFlag}
          />
        ) : null}
      </TooltipModal>
    )
  }
}

MediumMoreTooltipModalContainer.propTypes = {
  canRemoveMedium: PropTypes.bool,
  clickCoordinates: PropTypes.object,
  dispatch: PropTypes.func,
  flags: PropTypes.object,
  medium: PropTypes.object,
  visible: PropTypes.bool
}

function mapStateToProps (state) {
  const media = mediaSelector(state)
  const medium = media[mediumMoreTooltipMediumIdSelector(state)]
  const isHost = isHostSelector(state)
  const userId = userIdSelector(state)

  const isMediumOwner = medium && medium.user === userId
  const canRemoveMedium = isHost || isMediumOwner

  return {
    clickCoordinates: mediumMoreTooltipClickCoordinatesSelector(state),
    canRemoveMedium,
    flags: flagsSelector(state),
    medium,
    visible: mediumMoreTooltipVisibleSelector(state)
  }
}

export default connect(mapStateToProps)(MediumMoreTooltipModalContainer)
