import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import FolderIcon from 'src/assets/icons/folder-ico-green.svg'
import SvgIcon from 'src/components/common/SvgIcon'
import {
  getCreditsConfigs,
  buildCheckoutURLForCreditsBundle,
  storeUTMDataByKey
} from 'services/stripe_checkout_configs'
import cn from 'classnames'
import Button from 'src/components/common/Button'
import uuid from 'uuid'
import { fetchUserGQ } from 'src/actions/user'
import { changeVisible } from 'src/actions/globalLayer'
import { setOpenedTabReference } from 'src/actions/stripeCheckout'

import styles from './credit-options.scss'

const CreditOptions = () => {
  const dispatch = useDispatch()
  const creditOptions = getCreditsConfigs()
  const [selectedBundle, setSelectedBundle] = useState(creditOptions[0])

  const handlePurchaseCredits = () => {
    dispatch(changeVisible(false))
    const userReloadCallback = resp => {
      const user = resp?.data?.me
      if (user) {
        const utmCode = uuid.v4()
        storeUTMDataByKey(user.current_subscription, utmCode)
        const href = buildCheckoutURLForCreditsBundle(selectedBundle, user.id, utmCode)
        /* safari will block window.open in async calls, wrap it with setTimeout can get around it */
        /* short-turn solution: */
        /* https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari */
        /* long-turn solution: */
        /* use promise instead of callback to handle network requests in src/sagas/api.js:apiWorker() */
        setTimeout(() => {
          const newTabRef = window.open(href, '_blank')
          dispatch(setOpenedTabReference(newTabRef))
        }, 0)
      }
    }

    dispatch(fetchUserGQ(userReloadCallback))
  }

  return (
    <div className={styles['credit-options-container']}>
      <h3>
        <SvgIcon width={20} height={20} icon={FolderIcon} padding="5px" />
        Select credits quantity{' '}
      </h3>

      <p className={styles['subtitle']}>(Credits bought here will never expire)</p>

      <div style={{ flex: 1, height: 0, overflowY: 'auto' }}>
        <ul className={styles['bundle-ul']}>
          {creditOptions.map((item, i) => {
            return (
              <li
                key={i}
                className={cn(styles['bundle-li'], {
                  [styles['selected']]: item.stripeId === selectedBundle.stripeId
                })}
                onClick={() => setSelectedBundle(item)}
              >
                <div>
                  <span className={styles['total']}>{item.creditsNumber}</span>
                </div>
                <div>
                  <span className={styles['price']}>{item.priceLabel}</span>
                </div>
              </li>
            )
          })}
        </ul>
      </div>

      <div className={styles['price-summary']}>
        <div className={styles['price-summary-item']}>
          <span>Unit Price x Quantity </span>
          <span>{selectedBundle.formula}</span>
        </div>
        <div className={styles['price-summary-item']}>
          <span>
            <b>Total Price</b>
          </span>
          <span>
            <b>{selectedBundle.subTotalLabel}</b>
          </span>
        </div>
      </div>

      <Button className={styles['purchase-button']} onClick={handlePurchaseCredits}>
        PURCHASE
      </Button>
    </div>
  )
}

export default CreditOptions
