import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { EMAIL_CONTACT_METHOD, VALID_COUNTRY_CODES } from 'src/services/contact_methods'

import { loginFormSelector } from 'src/selectors'

import { updateLoginForm } from 'src/actions/login'
import { Select } from 'antd'

import PhoneInputInner from '../PhoneInputInner/PhoneInputInner'
import EmailInputElement from 'components/forms/EmailInputElement'

import styles from './contact-method-input-inner.scss'
import inputStyles from 'src/styles/inputs.scss'

const ContactMethodInputInner = props => {
  const { contactMethodType, valid, disabled, inputClassName } = props
  const dispatch = useDispatch()
  const loginForm = useSelector(loginFormSelector)

  const setPhoneNumber = ({ target }) => {
    const value = '1' + target.value.replace(/\D/g, '')
    dispatch(updateLoginForm({ phoneNumber: value, formattedPhoneNumber: target.value }))
  }

  const setCountryCode = value => {
    dispatch(updateLoginForm({ countryCode: value }))
  }

  const setEmailAddress = e => {
    dispatch(updateLoginForm({ emailAddress: e.target.value }))
  }

  const countryCodeOptions = () => {
    return VALID_COUNTRY_CODES.map(({ code, value }) => {
      return {
        value: code,
        label: `${code}+${value}`
      }
    })
  }

  const renderPhoneNumberFields = () => {
    return (
      <div
        className={cn(
          styles['phone-number-container'],
          inputClassName,
          disabled && styles['phone-number-container-disabled']
        )}
      >
        <div className={styles['select-wrapper']}>
          <Select
            bordered={false}
            value={loginForm.countryCode}
            onChange={setCountryCode}
            options={countryCodeOptions()}
          />
        </div>

        <PhoneInputInner
          value={loginForm.formattedPhoneNumber}
          onChange={setPhoneNumber}
          invalid={!valid}
          disabled={disabled}
        />
      </div>
    )
  }

  const renderEmailFields = () => {
    const emailAddressClass = valid ? null : styles.error

    return (
      <EmailInputElement
        useMaskedEmail={loginForm.useMaskedEmail}
        value={loginForm.emailAddress}
        maskedEmailAddress={loginForm.maskedEmailAddress}
        onChange={setEmailAddress}
        className={cn(emailAddressClass, inputStyles['text-input'])}
      />
    )
  }

  const renderFields = () => {
    switch (contactMethodType) {
      case EMAIL_CONTACT_METHOD:
        return renderEmailFields()
      default:
        return renderPhoneNumberFields()
    }
  }

  return renderFields()
}

ContactMethodInputInner.propTypes = {
  contactMethodType: PropTypes.string,
  valid: PropTypes.bool,
  disabled: PropTypes.bool,
  inputClassName: PropTypes.string
}

export default ContactMethodInputInner
