import React from 'react'
import PropTypes from 'prop-types'
import TextInputElement from 'components/forms/TextInputElement'
import TextAreaElement from 'components/common/ResizeableTextarea'
import RemoveIconGray from 'assets/icons/remove-icon-gray.png'
import addIconChecked from 'assets/icons/add-icon-checked.png'
import minusIconChecked from 'assets/icons/minus-icon-checked.png'
import { message } from 'antd'
import cn from 'classnames'
import styles from './options.scss'

const InputOptions = props => {
  const { list_options_params, isQuantityVisible, onSetListOptionsParams, invalidList } = props

  const handleChangeCount = (type, count_limit, index) => {
    let count = count_limit === 9999 ? 0 : count_limit

    if ((type === 'add' && count >= 50) || (type === 'minus' && count <= 0)) {
      return
    }

    switch (type) {
      case 'add':
        count += 1
        break
      case 'minus':
        count -= 1
        break
    }

    handleChangeOption('count_limit', count === 0 ? 9999 : count, index)
  }

  const handleRemoveOption = index => {
    if (list_options_params.length === 1) {
      message.warning('You must have at least one option')
      return
    }

    list_options_params.splice(index, 1)
    const mapOptions = list_options_params.map((item, index) => ({ ...item, item_rank: index }))
    onSetListOptionsParams(mapOptions)
  }

  const handleChangeOption = (key, value, index) => {
    list_options_params[index][key] = value
    onSetListOptionsParams([...list_options_params])
  }

  return (
    <ul className={styles['option-ul']}>
      {list_options_params.map((item, index) => (
        <li
          className={cn(
            styles['option-li'],
            invalidList.has(item.item_rank) && styles['option-li-invalid']
          )}
          key={index}
        >
          <div className={styles['item-information']}>
            <img
              style={{ cursor: 'pointer' }}
              src={RemoveIconGray}
              width="18"
              onClick={() => handleRemoveOption(index)}
            />
            <TextInputElement
              className={styles['input-inner']}
              placeholder="Input Slot/Item"
              onChange={event => handleChangeOption('item', event.target.value, index)}
              valid
              value={item.item}
            />

            {isQuantityVisible && (
              <div className={styles['button-wrapper']}>
                <div
                  className={styles['icon-wrapper']}
                  onClick={() => handleChangeCount('minus', item.count_limit, index)}
                >
                  <img src={minusIconChecked} width="10" />
                </div>

                <div className={styles['count']}>
                  {item.count_limit === 9999 ? 0 : item.count_limit}
                </div>

                <div
                  className={styles['icon-wrapper']}
                  onClick={() => handleChangeCount('add', item.count_limit, index)}
                >
                  <img src={addIconChecked} width="10" />
                </div>
              </div>
            )}
          </div>

          <div className={styles['line']} />

          <div className={styles['notes']}>
            <TextAreaElement
              className={styles['textArea-inner']}
              placeholder="Notes"
              onChangeComment={value => handleChangeOption('description', value, index)}
              comment={item.description || ''}
            />
          </div>
        </li>
      ))}
    </ul>
  )
}

InputOptions.propTypes = {
  invalidList: PropTypes.object,
  list_options_params: PropTypes.array,
  isQuantityVisible: PropTypes.bool,
  onSetListOptionsParams: PropTypes.func
}

export default InputOptions
