import { combineReducers } from 'redux'

import {
  CHANGE_PLAN_MODAL_VISIBLE,
  SET_IS_PLAN_DOWNGRADE,
  SET_SPECIFY_PLAN
} from 'src/actions/plan'

const visible = (state = false, action) => {
  switch (action.type) {
    case CHANGE_PLAN_MODAL_VISIBLE:
      return action.visible
    default:
      return state
  }
}

const downgrade = (state = false, action) => {
  switch (action.type) {
    case SET_IS_PLAN_DOWNGRADE:
      return action.downgrade
    case CHANGE_PLAN_MODAL_VISIBLE:
      return action.visible ? state : false
    default:
      return state
  }
}

const specifyPlan = (state = null, action) => {
  switch (action.type) {
    case SET_SPECIFY_PLAN:
      return action.plan
    case CHANGE_PLAN_MODAL_VISIBLE:
      return action.visible ? state : null
    default:
      return state
  }
}

export default combineReducers({
  visible,
  downgrade,
  specifyPlan
})
