import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { changePollTab } from 'src/actions/timepoll'
import { routerActions } from 'react-router-redux'
import DashboardContainer from 'containers/DashboardContainer'
import Button from 'components/common/Button'
import cn from 'classnames'
import SULPanel from 'containers/HomePage/BusinessPanel/SULPanel/SULPanel'
import TimePollPanel from 'containers/HomePage/BusinessPanel/TimePollPanel/TimePollPanel'
import styles from './time-poll-container.scss'
import addPollIcon from 'assets/icons/add-poll-icon.png'

const TimePollContainer = () => {
  const pollTab = useSelector(state => state.frontend.pollTab)
  const userId = useSelector(state => state.userId)
  const dispatch = useDispatch()

  const onChangePollTab = type => {
    dispatch(changePollTab(type))
  }

  const goCreatePage = () => {
    const router = {
      tp: () => dispatch(routerActions.push('/tp/create/')),
      sul: () => dispatch(routerActions.push('/su/create/'))
    }

    window.mixpanel.track(`Create ${pollTab === 'tp' ? 'Time Poll' : 'Sign Up List'} Clicked`, {
      user_id: userId,
      source: 'list'
    })

    router[pollTab]()
  }

  return (
    <DashboardContainer menuIcon>
      <div className={styles['poll-container']}>
        <div className={styles['poll-header']}>
          <ul className={styles['polls-tabs-wrapper']}>
            <li
              className={cn(styles['polls-tabs-inner'], {
                [styles['polls-tabs-inner-active']]: pollTab === 'tp'
              })}
              onClick={() => onChangePollTab('tp')}
            >
              Time Polls
            </li>
            <li
              className={cn(styles['polls-tabs-inner'], {
                [styles['polls-tabs-inner-active']]: pollTab === 'sul'
              })}
              onClick={() => onChangePollTab('sul')}
            >
              Sign Up List
            </li>
          </ul>

          <div className={styles['button-wrapper']}>
            <Button className={styles['create-polls-button']} onClick={goCreatePage}>
              + Create {pollTab === 'tp' ? 'time poll' : 'sign up list'}
            </Button>
          </div>
          <img src={addPollIcon} className={styles['create-polls-icon']} onClick={goCreatePage} />
        </div>

        <div className={styles['poll-component']}>
          <div
            className={cn(
              styles['panel-container'],
              pollTab === 'tp' && [styles['panel-container-active']]
            )}
          >
            <TimePollPanel />
          </div>
          <div
            className={cn(
              styles['panel-container'],
              pollTab === 'sul' && [styles['panel-container-active']]
            )}
          >
            <SULPanel />
          </div>
        </div>
      </div>
    </DashboardContainer>
  )
}

export default TimePollContainer
