import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { list_update } from 'src/actions/timepoll'
import { uploadFiles, requestUploadFile } from 'src/actions/files'
import SULInformation from '../../SULInformation/SULInformation'
import { signUpFormSelector, userSelector } from 'src/selectors'
import { showLogin } from 'src/actions/login'
import { Tooltip } from 'antd'
import { routerActions } from 'react-router-redux'
import { showLoadingIcon, hideLoadingIcon } from 'src/actions/frontend'
import { changeVisible, setContent } from 'src/actions/globalLayer'
import { UPGRADE_PLAN_SOURCE } from 'src/constants'

import EditIcon from 'assets/icons/edit-icon.png'
import Button from 'components/common/Button'
import moment from 'moment'
import cn from 'classnames'

import styles from './draft-step.scss'

const DraftStep = () => {
  const dispatch = useDispatch()
  const signUpForm = useSelector(signUpFormSelector)
  const user = useSelector(userSelector)

  useEffect(() => {
    sessionStorage.setItem('signUpForm', JSON.stringify(signUpForm))
  }, [])

  const getSignUpDetail = () => {
    return {
      name: signUpForm.name,
      image: signUpForm.checkedImage.url,
      creator: user,
      eventName: signUpForm.checkedEvent?.name,
      ends_at: signUpForm.ends_at,
      createName: signUpForm.custom_creator_name
        ? signUpForm.custom_creator_name
        : user
        ? `${user?.first_name} ${user?.last_name}`
        : '',
      created_at: moment().toDate(),
      notes: signUpForm.notes
    }
  }

  const handleCreateSUL = () => {
    window.mixpanel.track('SUL Saved', { user_id: user.id })

    if (!user.id) {
      const params = {
        loginModalSubtitle:
          'In order for you to create a Sign Up List, we need to confirm your number'
      }
      dispatch(showLogin(params))

      return
    }

    const sulForm = {
      email_require: signUpForm.email_require,
      name: signUpForm.name,
      custom_creator_name: signUpForm.custom_creator_name,
      notes: signUpForm.notes,
      ends_at: signUpForm.ends_at,
      event_id: signUpForm.checkedEvent?.id || null,
      poll_type: 'item',
      timezone: signUpForm.timezone
    }

    dispatch(showLoadingIcon())

    dispatch(
      uploadFiles({
        mimetype: 'image/jpeg',
        onSuccessCallback: async data => {
          const bodyData = data.data.image_upload_update.result
          await requestUploadFile(signUpForm.checkedImage.url, bodyData.presigned_url)
          sulForm.image_upload_id = bodyData.id

          dispatch(
            list_update({
              list_params: sulForm,
              list_options_params: signUpForm.list_options_params,
              onSuccessCallback: data => {
                if (data.data.list_update.successful) {
                  setTimeout(() => {
                    dispatch(routerActions.push(`/su/${data.data.list_update.result.token}`))
                    sessionStorage.removeItem('signUpForm')
                    dispatch(hideLoadingIcon())
                  }, 5000)
                }
              },
              onFailCallback: data => {
                dispatch(hideLoadingIcon())
                if (!data.data.list_update.successful) {
                  dispatch(changeVisible(true))
                  dispatch(setContent(UPGRADE_PLAN_SOURCE.getMorePollsAndLists))
                }
              }
            })
          )
        }
      })
    )
  }

  return (
    <div className={styles['draft-step-container']}>
      <div className={styles['draft-step-left']}>
        <div className={styles['draft-step-information']}>
          <SULInformation type="draft" signUpDetail={getSignUpDetail()} />
          <div className={styles['draft-edit-button-web']}>
            <div
              className={styles['button-inner']}
              onClick={() => dispatch(routerActions.push('/su/edit'))}
            >
              <img src={EditIcon} alt="" width="20" />
              <span>edit draft</span>
            </div>
          </div>
        </div>

        <div className={styles['draft-save-button-web']}>
          <Button
            className={cn(styles['button-inner'], styles['button-inner-save'])}
            onClick={handleCreateSUL}
          >
            SAVE
          </Button>
        </div>
      </div>

      <div className={styles['draft-step-right']}>
        <div className={styles['title']}>Slot/Item</div>
        <div className={styles['option-wrapper']}>
          <ul className={styles['option-ul']}>
            {signUpForm.list_options_params.map((item, index) => (
              <li className={styles['option-inner']} key={index}>
                <div className={styles['option-title']}>
                  <Tooltip placement="topLeft" title={item.item}>
                    <span className={styles['option-name']}>{item.item}</span>
                  </Tooltip>
                  {item.count_limit !== 9999 && (
                    <div className={styles['option-number']}>
                      <label>count:</label>
                      <span>{item.count_limit}</span>
                    </div>
                  )}
                </div>

                <Tooltip placement="topLeft" title={item.description}>
                  {item.description}
                </Tooltip>
              </li>
            ))}
          </ul>
        </div>
        <footer className={styles['mobile-style']}>
          <div className={styles['draft-save-button']}>
            <Button className={styles['button-inner']} onClick={handleCreateSUL}>
              SAVE
            </Button>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default DraftStep
