import { fork, take, spawn } from 'redux-saga/effects'
import Honeybadger from 'honeybadger-js'

import { parseEventUrl } from 'services/url_helpers'

import { SET_USER_ID } from 'src/actions'
import { SET_INVITATION_ID } from 'src/actions/invitations'

export default function * honeybadgerRootSaga () {
  Honeybadger.resetContext()
  yield fork(setHoneybadgerUserContextSaga)
  yield fork(setHoneybadgerInvitationContextSaga)
  yield fork(setHoneybadgerTokensContextSaga)
  yield spawn(honeybadgerAdditionalInfoSaga)
}

function * setHoneybadgerUserContextSaga () {
  while (true) {
    const action = yield take(SET_USER_ID)
    Honeybadger.setContext({
      userId: action.userId
    })
  }
}

function * setHoneybadgerInvitationContextSaga () {
  while (true) {
    const action = yield take(SET_INVITATION_ID)
    Honeybadger.setContext({
      invitationId: action.invitationId
    })
  }
}

function setHoneybadgerTokensContextSaga () {
  const path = window.location.pathname
  const { destinationType, eventToken, invitationToken } = parseEventUrl(path)
  Honeybadger.setContext({
    destinationType,
    eventToken,
    invitationToken
  })
}

// Send additional info to honeybadger when there is an error
function * honeybadgerAdditionalInfoSaga () {
  let actions = []

  Honeybadger.beforeNotify((notice) => {
    notice.context = {
      recentActions: actions
    }
  })

  while (true) {
    let action = yield take('*')
    actions.push(action)
    if (actions.length >= 10) {
      actions.shift()
    }
  }
}
