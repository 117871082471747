import React from 'react'
import PropTypes from 'prop-types'
import { Elements } from 'react-stripe-elements'

import PaymentMethodForm from './PaymentMethodForm'

const fontUrl = process.env.CLOUD_TYPOGRAPHY_URL

class CreateNewCardForm extends React.Component {
  render () {
    const { addNewCard } = this.props
    return (
      <Elements
        fonts={[
          {
            cssSrc: fontUrl
          }
        ]}
      >
        <PaymentMethodForm onSubmit={addNewCard} />
      </Elements>
    )
  }
}

CreateNewCardForm.propTypes = {
  addNewCard: PropTypes.func
}

export default CreateNewCardForm
