import React, { Component } from 'react'
import styles from './input-guests.scss'
import EditModal from '../../../Components/EditModal/EditModal'
import PropTypes from 'prop-types'
import addGuestsContext from '../addGuestsContext'
import PreviewAddGuestsList from '../../../Components/PreviewAddGuestsList/PreviewAddGuestsList'
import { POPUP_BOX_TYPES_CONSTANTS } from 'src/constants'
import SvgIcon from 'components/common/SvgIcon'
import IcoInput from 'assets/icons/ico-input.svg'

class InputGuests extends Component {
  static contextType = addGuestsContext
  static displayName = 'InputGuests'

  state = {
    isOpenEditModal: true,
    previewAddGuestsList: []
  }

  handleClose = () => {
    this.setState({ isOpenEditModal: false })
    this.props.onResetForm()
  }

  handleSaveAndExit = () => {
    if (this.handleSaveAndContinue()) {
      this.setState({
        isOpenEditModal: false
      })
    }
  }

  handleSaveAndContinue = () => {
    const { previewAddGuestsList } = this.state
    const guestInformation = this.props.onGetGuestInfo()
    if (guestInformation) {
      this.setState({
        previewAddGuestsList: [guestInformation, ...previewAddGuestsList]
      })

      return true
    }

    return false
  }

  componentDidMount() {
    this.context.onChangePopupBox(POPUP_BOX_TYPES_CONSTANTS.inputGuests)
    this.context.onChangePopupBoxCallBack(() => {
      this.context.onChangeComponent('AddGuests')
    })
  }

  render() {
    const { first_name, last_name, phoneOrEmail, visibleErrorText, onInputChange } = this.props
    const { previewAddGuestsList, isOpenEditModal } = this.state
    return (
      <div className={styles['input-guests-container']}>
        <PreviewAddGuestsList
          title="New Number or Email"
          headerRight={
            <SvgIcon
              icon={IcoInput}
              onClick={() => this.setState({ isOpenEditModal: true })}
              width={20}
              height={20}
            />
          }
          previewAddGuestsList={previewAddGuestsList}
          disableScroll={isOpenEditModal}
        />

        <EditModal
          isOpenEditModal={this.state.isOpenEditModal}
          first_name={first_name}
          last_name={last_name}
          phoneOrEmail={phoneOrEmail}
          visibleErrorText={visibleErrorText}
          closeIcon
          title="New Number or Email"
          cancelText="Save and Exit"
          confirmText="Save and Continue"
          onClose={this.handleClose}
          onCancel={this.handleSaveAndExit}
          onSave={this.handleSaveAndContinue}
          onInputChange={onInputChange}
        />
      </div>
    )
  }
}

InputGuests.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  phoneOrEmail: PropTypes.string,
  visibleErrorText: PropTypes.bool,
  onInputChange: PropTypes.func,
  onGetGuestInfo: PropTypes.func,
  onResetForm: PropTypes.func
}

export default InputGuests
