import { combineReducers } from 'redux'

import {
  SHOW_LIKERS_MODAL,
  HIDE_LIKERS_MODAL
} from 'src/actions/media'

const visible = (state = false, action) => {
  switch (action.type) {
    case SHOW_LIKERS_MODAL:
      return true
    case HIDE_LIKERS_MODAL:
      return false
    default:
      return state
  }
}

const mediumId = (state = '', action) => {
  switch (action.type) {
    case SHOW_LIKERS_MODAL:
      return action.mediumId
    default:
      return state
  }
}

export default combineReducers({
  visible,
  mediumId
})
