import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import FullNameInput from '../FullNameInput/FullNameInput'
import ContactMethodInput from '../ContactMethodInput/ContactMethodInput'
import ConfirmationCodeInput from '../ConfirmationCodeInput/ConfirmationCodeInput'

import { requestSubmitLogin, updateLoginForm } from 'src/actions/login'
import { updateUser } from 'src/actions/user'

import {
  hostSelector,
  invitationSelector,
  usersSelector,
  loginFormSelector,
  userIdSelector
} from 'src/selectors'

import {
  CONTACT_METHOD,
  EMAIL_CONTACT_METHOD,
  PHONE_NUMBER_CONTACT_METHOD
} from 'src/services/contact_methods'
import LeftChevronIcon from 'assets/icons/left_chevron.svg'
import SvgIcon from 'components/common/SvgIcon'

import styles from './login-panel.scss'

const LoginPanel = props => {
  const { onSubscribeSuccessActions } = props
  const dispatch = useDispatch()

  const { invitation, loginForm, userId, users } = useSelector(state => ({
    invitation: invitationSelector(state),
    loginForm: loginFormSelector(state),
    userId: userIdSelector(state),
    users: usersSelector(state),
    host: hostSelector(state)
  }))
  const user = users[userId]

  useEffect(() => {
    if (user && onSubscribeSuccessActions) {
      onSubscribeSuccessActions.forEach(action => {
        dispatch(action)
      })
    }
  }, [user])

  const handleRequestSubmitLogin = () => {
    const username = oauthUsername()
    let contactMethodType = getContactMethodType()
    if (contactMethodType === EMAIL_CONTACT_METHOD && loginForm.useMaskedEmail) {
      contactMethodType = CONTACT_METHOD
    }

    dispatch(requestSubmitLogin(username, contactMethodType))
  }

  const getContactMethodType = () => {
    // if (contactMethod) {
    //   return contactMethod.type
    // } else {
    return PHONE_NUMBER_CONTACT_METHOD
    // }
  }

  const oauthUsername = () => {
    switch (getContactMethodType()) {
      case EMAIL_CONTACT_METHOD:
        if (loginForm.useMaskedEmail) {
          return invitation.contact_method.hash
        } else {
          return loginForm.emailAddress
        }
      default:
        return loginForm.phoneNumber
    }
  }

  const handleClickBack = () => {
    let prevStep = 'contactMethod'

    if (loginForm.loginStep === 'fullName') {
      prevStep = 'confirmationCode'
    }

    dispatch(
      updateLoginForm({
        loginStep: prevStep,
        confirmationCodeValid: 'unknown',
        confirmationCode: ''
      })
    )
  }

  const handleNextStep = () => {
    if (loginForm.loginInRSVP) {
      dispatch(
        updateUser({
          user: {
            first_name: loginForm.firstName,
            last_name: loginForm.lastName
          }
        })
      )
    }
  }

  const renderConfirmationCodeInput = () => {
    return (
      <ConfirmationCodeInput onNextStep={handleNextStep} onResendCode={handleRequestSubmitLogin} />
    )
  }

  const renderContactMethodFieldsInput = () => {
    return <ContactMethodInput submitForm={handleRequestSubmitLogin} />
  }

  const renderComponent = () => {
    switch (loginForm.loginStep) {
      case 'contactMethod':
        return renderContactMethodFieldsInput()
      case 'confirmationCode':
        return renderConfirmationCodeInput()
      case 'fullName':
      default:
        return <FullNameInput />
    }
  }

  const getTitleAndSubTitle = () => {
    if (loginForm.loginStep === 'contactMethod') {
      return {
        title: loginForm.loginModalTitle,
        subTitle: loginForm.loginModalSubtitle
      }
    }

    if (loginForm.loginStep === 'confirmationCode') {
      return {
        title: 'Sign Up/Sign In',
        subTitle: ''
      }
    }

    if (loginForm.loginStep === 'fullName') {
      return {
        title: loginForm.fullNameTitle,
        subTitle: loginForm.fullNameSubtitle
      }
    }
  }

  let { title = '', subTitle = '' } = getTitleAndSubTitle()

  return (
    <div className={styles['login-panel-container']}>
      {loginForm.loginStep === 'confirmationCode' && (
        <div className={styles['back-icon-wrapper']}>
          <SvgIcon
            className={styles['left-chevron-icon']}
            icon={LeftChevronIcon}
            padding="5px"
            onClick={handleClickBack}
          />
        </div>
      )}
      <h3 className={styles['title']}>{title}</h3>
      <div className={styles['sub-title']}>{subTitle}</div>
      {renderComponent()}
    </div>
  )
}

LoginPanel.propTypes = {
  onSubscribeSuccessActions: PropTypes.array
}

export default LoginPanel
