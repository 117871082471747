/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import classnames from 'classnames'
import React from 'react'

const TextInputElement = props => {
  const { className, onChange, value, otherProps, placeholder } = props

  return (
    <input
      type="text"
      autoComplete="off"
      className={classnames(className)}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      {...otherProps}
    />
  )
}

TextInputElement.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
}

export default TextInputElement
