import { PHONE_NUMBER_CONTACT_METHOD } from 'services/contact_methods'

import { fetchApi } from './api'

export const UPDATE_LOGIN_FORM = 'UPDATE_LOGIN_FORM'
export const RESET_LOGIN_FORM = 'RESET_LOGIN_FORM'
export const REQUEST_SUBMIT_LOGIN = 'REQUEST_SUBMIT_LOGIN'
export const SHOW_CONFIRMATION_CODE_FORM = 'SHOW_CONFIRMATION_CODE_FORM'
export const SHOW_CONTACT_METHOD_FIELDS_FORM = 'SHOW_CONTACT_METHOD_FIELDS_FORM'
export const SHOW_UPLOAD_IMAGE_CROPPER = 'SHOW_UPLOAD_IMAGE_CROPPER'
export const HIDE_UPLOAD_IMAGE_CROPPER = 'HIDE_UPLOAD_IMAGE_CROPPER'

export const SUBMIT_LOGIN = 'SUBMIT_LOGIN'
export const SUBMIT_LOGIN_SUCCESS = 'SUBMIT_LOGIN_SUCCESS'
export const SUBMIT_LOGIN_ERROR = 'SUBMIT_LOGIN_ERROR'
export const SUBMIT_LOGIN_CONFIRMATION = 'SUBMIT_LOGIN_CONFIRMATION'
export const SUBMIT_LOGIN_CONFIRMATION_SUCCESS = 'SUBMIT_LOGIN_CONFIRMATION_SUCCESS'
export const SUBMIT_LOGIN_CONFIRMATION_ERROR = 'SUBMIT_LOGIN_CONFIRMATION_ERROR'

export const SET_USE_MASKED_EMAIL = 'SET_USE_MASKED_EMAIL'

export const REQUIRE_LOGIN = 'REQUIRE_LOGIN'
export const SHOW_LOGIN = 'SHOW_LOGIN'
export const HIDE_LOGIN = 'HIDE_LOGIN'

export const LOGIN_COMPLETE = 'LOGIN_COMPLETE'
export const LOGOUT = 'LOGOUT'
export const EVENT_LOGOUT = 'EVENT_LOGOUT'
export const BACK_TO_CONTACT_METHOD_FIELDS_FORM_PAGE = 'BACK_TO_CONTACT_METHOD_FIELDS_FORM_PAGE'
export const BACK_TO_LOGIN_FORM_PAGE = 'BACK_TO_LOGIN_FORM_PAGE'

export const UPLOAD_AVATAR_PROGRESS = 'UPLOAD_AVATAR_PROGRESS'
export const UPLOAD_AVATAR_SUCCESS = 'UPLOAD_AVATAR_SUCCESS'
export const UPLOAD_AVATAR_ERROR = ' UPLOAD_AVATAR_ERROR'
// Require the user to login before running the nextActionSuccess actions
// nextActionSuccess can either be an array of actions or a function that returns an array of actions
export function requireLogin({
  loginMessage,
  subscribeMessage,
  loginModalTitle,
  loginModalSubtitle,
  loginModalFirstPageSubtitle,
  nextActionSuccess = []
}) {
  return {
    type: REQUIRE_LOGIN,
    loginMessage,
    subscribeMessage,
    loginModalTitle,
    loginModalSubtitle,
    loginModalFirstPageSubtitle,
    nextActionSuccess
  }
}

export function showLogin(params) {
  return {
    ...params,
    type: SHOW_LOGIN
  }
}

export function hideLogin(canceled = false) {
  return {
    type: HIDE_LOGIN,
    canceled
  }
}

export function loginComplete() {
  return {
    type: LOGIN_COMPLETE
  }
}

export function logout() {
  return {
    type: LOGOUT
  }
}

export function eventLogout() {
  return {
    type: EVENT_LOGOUT
  }
}

export function wrongPhoneNumber() {
  return {
    type: BACK_TO_CONTACT_METHOD_FIELDS_FORM_PAGE
  }
}

export function backToLoginFormPage() {
  return {
    type: BACK_TO_LOGIN_FORM_PAGE
  }
}

export function updateLoginForm(loginForm) {
  return {
    type: UPDATE_LOGIN_FORM,
    loginForm
  }
}

// Request a saga to log in
export function requestSubmitLogin(username, contactMethodType = PHONE_NUMBER_CONTACT_METHOD) {
  return {
    type: REQUEST_SUBMIT_LOGIN,
    username,
    contactMethodType
  }
}

export function showConfirmationCodeForm() {
  return {
    type: SHOW_CONFIRMATION_CODE_FORM
  }
}

export function showContactMethodFieldsForm() {
  return {
    type: SHOW_CONTACT_METHOD_FIELDS_FORM
  }
}

export function submitLogin(username, contactMethodType = PHONE_NUMBER_CONTACT_METHOD) {
  return fetchApi({
    requestType: SUBMIT_LOGIN,
    successType: SUBMIT_LOGIN_SUCCESS,
    errorType: SUBMIT_LOGIN_ERROR,
    route: '/oauth/token',
    options: {
      method: 'POST',
      body: JSON.stringify({
        grant_type: 'password',
        username,
        contact_method_type: contactMethodType
      })
    },
    onErrorCallback: () => {}
  })
}

export function submitConfirmation({
  first_name,
  last_name,
  username,
  confirmationCode,
  contactMethodType = PHONE_NUMBER_CONTACT_METHOD,
  onSuccessCallback,
  onErrorCallback
}) {
  return fetchApi({
    requestType: SUBMIT_LOGIN_CONFIRMATION,
    successType: SUBMIT_LOGIN_CONFIRMATION_SUCCESS,
    errorType: SUBMIT_LOGIN_CONFIRMATION_ERROR,
    route: '/oauth/token',
    options: {
      method: 'POST',
      body: JSON.stringify({
        grant_type: 'password',
        first_name,
        last_name,
        username,
        password: confirmationCode,
        contact_method_type: contactMethodType
      })
    },
    onSuccessCallback,
    onErrorCallback
  })
}

export function resetLoginForm() {
  return {
    type: RESET_LOGIN_FORM
  }
}

export function setUseMaskedEmail(value) {
  return {
    type: SET_USE_MASKED_EMAIL,
    value
  }
}

export function uploadAvatarProgress(id, percentUploaded) {
  return {
    type: UPLOAD_AVATAR_PROGRESS,
    id,
    percentUploaded
  }
}

export function uploadAvatarSuccess(id) {
  return {
    type: UPLOAD_AVATAR_SUCCESS,
    id
  }
}

export function uploadAvatarError(id) {
  return {
    type: UPLOAD_AVATAR_ERROR,
    id
  }
}
