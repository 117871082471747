import React, { Component } from 'react'

import styles from './legal-notice.scss'

export default class LegalNotice extends Component {
  render () {
    return (
      <div className={styles['text']}>
        By using this site, you agree to our <a href='https://hobnob.app/privacy/'>Privacy Policy</a> and <a href='https://hobnob.app/terms/'>Terms</a>
      </div>
    )
  }
}
