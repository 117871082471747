import React, { Component } from 'react'
import styles from './add-guests.scss'
import InputGuests from './InputGuests/InputGuests'
import compWithInputGuests from '../../HOC/WithInputGuests'
import CommunityModal from './CommunityModal/CommunityModal'
import compWithCommunityGuests from '../../HOC/WithCommunityGuests'
import UploadModal from './UploadModal/UploadModal'
import PropTypes from 'prop-types'

const InputGuestsHOC = compWithInputGuests(InputGuests)
const CommunityGuestsHOC = compWithCommunityGuests(CommunityModal)

class AddGuests extends Component {
  render() {
    return (
      <div className={styles['add-guests-container']}>
        <div className={styles['button-wrapper']}>
          <CommunityGuestsHOC />
          <UploadModal />
        </div>
        <InputGuestsHOC ref={this.props.customRef} />
      </div>
    )
  }
}

AddGuests.propTypes = {
  customRef: PropTypes.object
}

export default React.forwardRef((props, ref) => <AddGuests customRef={ref} />)
