import React, { Component } from 'react'
import styles from './search-input.scss'
import TextInputElement from 'components/forms/TextInputElement'
import SearchIcon from 'assets/icons/search_icon.svg'
import SvgIcon from 'components/common/SvgIcon'
import CloseIcon from 'assets/icons/close-icon-bg.png'
import PropTypes from 'prop-types'

class SearchInput extends Component {
  render() {
    const { value, onChange } = this.props
    return (
      <div className={styles['search-wrapper']}>
        <SvgIcon className={styles['search-icon']} icon={SearchIcon} />
        <TextInputElement
          className={styles['input-inner']}
          valid
          placeholder="Search..."
          onChange={e => onChange(e.target.value)}
          value={value}
        />
        {value && <img src={CloseIcon} width="18" onClick={() => onChange('')} />}
      </div>
    )
  }
}

SearchInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default SearchInput
