import { combineReducers } from 'redux'

import {
  SHOW_MENU_PANEL,
  HIDE_MENU_PANEL,
  CHANGE_SELECTED_MENU_PANEL
} from 'src/actions/frontend'

const isMenuPanelVisible = (state = false, action) => {
  switch (action.type) {
    case SHOW_MENU_PANEL:
      return true
    case HIDE_MENU_PANEL:
      return false
    default:
      return state
  }
}

const activeMenuPanel = (state = null, action) => {
  switch (action.type) {
    case CHANGE_SELECTED_MENU_PANEL:
      return action.panel
    default:
      return state
  }
}

export default combineReducers({
  isMenuPanelVisible,
  activeMenuPanel
})
