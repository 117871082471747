import React, { useState, useEffect, useCallback, useRef, useContext } from 'react'
import PropTypes from 'prop-types'

import queryString from 'query-string'
import { Input } from 'antd'
import LazyLoadedImage from '../LazyLoadedImage/LazyLoadedImage'
import debounce from 'lodash/debounce'
import { isMobile } from 'services/browser_detection'
import cn from 'classnames'
import InputMessageContext from '../InputMessageContext'

import styles from './input-gif-message.scss'

const Search = Input.Search

let isLoadingGif = false

const InputGifMessage = ({ onSetIsInputGifVisible, onSetMediaMessage }) => {
  const [gifList, setGifList] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [giphyOffset, setGiphyOffset] = useState(0)
  const inputGifMessageRef = useRef(null)
  const ulRef = useRef(null)
  const { inputRef, isInputGifVisible, mediaMessage } = useContext(InputMessageContext)

  const fetchGiphyGif = (options = {}) => {
    isLoadingGif = true
    const params = {
      api_key: process.env.GIPHY_API_KEY,
      limit: 10,
      offset: options.offset || 0,
      q: options.query
    }

    const isQuery = !!params.q
    const fetchUrl = `https://api.giphy.com/v1/gifs/${isQuery ? 'search' : 'trending'}`
    return fetch(`${fetchUrl}?${queryString.stringify(params)}`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Giphy API returned error: ${response.statusText}`)
        }

        return response.json()
      })
      .then(res => {
        if (inputGifMessageRef.current) {
          isLoadingGif = false
          setGifList([...gifList, ...res.data])
        }
      })
  }

  const listenerContainer = e => {
    const isClickInsideElement = inputGifMessageRef.current.contains(e.target)
    if (!isClickInsideElement) {
      onSetIsInputGifVisible(false)
      inputRef.current.focus()
    }
  }
  useEffect(() => {
    if (isInputGifVisible) {
      if (!gifList.length) {
        fetchGiphyGif()
      }

      document.addEventListener('click', listenerContainer)
    } else {
      document.removeEventListener('click', listenerContainer)
    }

    return () => {
      document.removeEventListener('click', listenerContainer)
    }
  }, [isInputGifVisible])

  const handleScrollUl = () => {
    const element = ulRef.current
    if (!isLoadingGif && element.scrollLeft + element.clientWidth === element.scrollWidth) {
      const currentOffset = giphyOffset + 10
      setGiphyOffset(currentOffset)
      fetchGiphyGif({
        offset: currentOffset,
        query: searchValue
      })
    }
  }

  const handleSearch = useCallback(value => {
    setGifList([])
    setGiphyOffset(0)
    fetchGiphyGif({ query: value })
  }, [])

  const handleChange = useCallback(e => {
    setSearchValue(e.target.value)
    debounceSearch(e.target.value)
  }, [])

  const debounceSearch = debounce(handleSearch, 300)

  const handleClickGif = gif => {
    const messagePart = {
      id: gif.id,
      type: 'giphy',
      giphy_id: gif.id,
      url: gif.images.fixed_width.webp
    }

    onSetMediaMessage([...mediaMessage, messagePart])
    onSetIsInputGifVisible(false)
    inputRef.current.focus()
  }

  return (
    <div
      className={cn(
        styles['input-gif-message-container'],
        isInputGifVisible && styles['input-gif-message-container-show']
      )}
      ref={inputGifMessageRef}
    >
      <ul className={styles['gif-ul']} onScroll={handleScrollUl} ref={ulRef}>
        {gifList.map(gif => (
          <li key={gif.id} className={styles['gif-li']} onClick={() => handleClickGif(gif)}>
            <LazyLoadedImage src={gif.images.fixed_width.webp} />
          </li>
        ))}
      </ul>

      <Search
        onInput={handleChange}
        placeholder="input search text"
        allowClear
        enterButton="Search"
        size={isMobile() ? 'middle' : 'large'}
        onSearch={handleSearch}
      />
    </div>
  )
}

InputGifMessage.propTypes = {
  onSetIsInputGifVisible: PropTypes.func,
  onSetMediaMessage: PropTypes.func
}

export default InputGifMessage
