import { fetchApi } from './api'

export const CREATE_WEBSOCKET = 'CREATE_WEBSOCKET'
export const SET_CHANNEL_NAME = 'SET_CHANNEL_NAME'
export const CHANGE_CHAT_STATUS = 'CHANGE_CHAT_STATUS'
export const SET_IMAGE_UPLOADING = 'SET_IMAGE_UPLOADING'
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE'
export const SAVE_GALLERIES = 'SAVE_GALLERIES'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const SAVE_HISTORY_MESSAGE = 'SAVE_HISTORY_MESSAGE'
export const SAVE_INVITED_EVENTS = 'SAVE_INVITED_EVENTS'
export const FETCH_GALLERY_IMAGE = 'FETCH_GALLERY_IMAGE'
export const FETCH_GALLERY_IMAGE_SUCCESS = 'FETCH_GALLERY_IMAGE_SUCCESS'
export const FETCH_GALLERY_IMAGE_ERROR = 'FETCH_GALLERY_IMAGE_ERROR'
export const SET_GROUP_LIST = 'SET_GROUP_LIST'
export const SET_MEMBER_LIST = 'SET_MEMBER_LIST'
export const FETCH_GROUP_GUESTS = 'FETCH_GROUP_GUESTS'
export const FETCH_GROUP_GUESTS_SUCCESS = 'FETCH_GROUP_GUESTS_SUCCESS'
export const FETCH_GROUP_GUESTS_ERROR = 'FETCH_GROUP_GUESTS_ERROR'
export const UPLOAD_GALLERIES = 'UPLOAD_GALLERIES'
export const UPLOAD_GALLERIES_SUCCESS = 'UPLOAD_GALLERIES_SUCCESS'
export const UPLOAD_GALLERIES_ERROR = 'UPLOAD_GALLERIES_ERROR'
export const CREATE_CHAT_ROOM = 'CREATE_CHAT_ROOM'
export const CREATE_CHAT_ROOM_SUCCESS = 'CREATE_CHAT_ROOM_SUCCESS'
export const CREATE_CHAT_ROOM_ERROR = 'CREATE_CHAT_ROOM_ERROR'
export const SWITCH_CHAT_USER = 'SWITCH_CHAT_USER'
export const SET_REPLY_MESSAGE = 'SET_REPLY_MESSAGE'
export const RESET_CHAT = 'RESET_CHAT'
export const FETCH_UNREAD_MESSAGE = 'FETCH_UNREAD_MESSAGE'
export const FETCH_UNREAD_MESSAGE_SUCCESS = 'FETCH_UNREAD_MESSAGE_SUCCESS'
export const FETCH_UNREAD_MESSAGE_ERROR = 'FETCH_UNREAD_MESSAGE_ERROR'

export const MARK_READ_MESSAGE = 'MARK_READ_MESSAGE'
export const MARK_READ_MESSAGE_SUCCESS = 'MARK_READ_MESSAGE_SUCCESS'
export const MARK_READ_MESSAGE_ERROR = 'MARK_READ_MESSAGE_ERROR'

export const resetChat = () => ({ type: RESET_CHAT })

export function setChannelName(channelName) {
  return {
    type: SET_CHANNEL_NAME,
    channelName
  }
}

export function createWebSocket(socket) {
  return {
    type: CREATE_WEBSOCKET,
    socket
  }
}

export function changeChatStatus(status) {
  return {
    type: CHANGE_CHAT_STATUS,
    status
  }
}

export function setImageUploading(loading) {
  return {
    type: SET_IMAGE_UPLOADING,
    loading
  }
}

export const SET_UNREAD_MESSAGE_LIST = 'SET_UNREAD_MESSAGE_LIST'
export function setUnreadMessageList(unreadMessageList) {
  return {
    type: SET_UNREAD_MESSAGE_LIST,
    unreadMessageList
  }
}

export function saveHistoryMessage(messages) {
  return {
    type: SAVE_HISTORY_MESSAGE,
    messages
  }
}

export function saveInvitedEvents(event) {
  return {
    type: SAVE_INVITED_EVENTS,
    event
  }
}

export function saveGalleries(gallery) {
  return {
    type: SAVE_GALLERIES,
    gallery
  }
}

export function addMessage(message) {
  return {
    type: ADD_MESSAGE,
    message
  }
}

export function updateMessage(message) {
  return {
    type: UPDATE_MESSAGE,
    message
  }
}

export function setGroupList(groupList) {
  return {
    type: SET_GROUP_LIST,
    groupList
  }
}

export function setMemberList(memberList) {
  return {
    type: SET_MEMBER_LIST,
    memberList
  }
}

export function deleteMessage(message) {
  return {
    type: DELETE_MESSAGE,
    message
  }
}

export function createChatRoom({ users, onSuccessCallback }) {
  return fetchApi({
    requestType: CREATE_CHAT_ROOM,
    successType: CREATE_CHAT_ROOM_SUCCESS,
    errorType: CREATE_CHAT_ROOM_ERROR,
    route: '/groups',
    options: {
      method: 'POST',
      body: JSON.stringify({
        group: {
          type: 'MemberDefinedGroup',
          group_members_attributes: users.map(user => ({ user_id: user.id })),
          message_attributes: null
        }
      })
    },
    onSuccessCallback
  })
}

export function switchChatUser(user) {
  return {
    type: SWITCH_CHAT_USER,
    user
  }
}

export function setReplyMessage(message) {
  return {
    type: SET_REPLY_MESSAGE,
    message
  }
}

export function fetchGalleryImage({ galleryId, onSuccessCallback }) {
  return fetchApi({
    requestType: FETCH_GALLERY_IMAGE,
    successType: FETCH_GALLERY_IMAGE_SUCCESS,
    errorType: FETCH_GALLERY_IMAGE_ERROR,
    route: '/graphql2',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `{
          galleries (ids: ["${galleryId}"]) {
            destroyed_at
            gallery_image_uploads {
              gallery_id
              id
              image_upload_id 
              sort_order
              image_upload {
                id
                large_url
                medium_url
                medium_cropped_url
                metadata {
                  dominant_color
                  width
                  height
                }
                state
                thumbnail_url
                upload_url
              }
            }
            id
            inserted_at
            updated_at
            user_id
          }
        }`
      })
    },
    onSuccessCallback
  })
}

export function fetchGroupGuests({ groupId, onSuccessCallback }) {
  return fetchApi({
    requestType: FETCH_GROUP_GUESTS,
    successType: FETCH_GROUP_GUESTS_SUCCESS,
    errorType: FETCH_GROUP_GUESTS_ERROR,
    route: `/graphql`,
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `{
          groups(ids: ["${groupId}"]) {
            channel
            destroyed_at
            external_room_id
            ... on NamedGroup {
              avatar {
                large_url_2x
                medium_url_2x
              }
              avatar_state
              name
            }
            ... on MemberDefinedGroup {
              name
            }
            group_members {
              destroyed_at
              id
              user {
                app_user
                verified
                id
                first_name
                last_name
              }
            }
            id
            type
           }
        }`
      })
    },
    onSuccessCallback
  })
}

export function uploadGalleries({ galleryId, galleries, onSuccessCallback }) {
  return fetchApi({
    requestType: UPLOAD_GALLERIES,
    successType: UPLOAD_GALLERIES_SUCCESS,
    errorType: UPLOAD_GALLERIES_ERROR,
    route: `/galleries/${galleryId}`,
    options: {
      method: 'PUT',
      body: JSON.stringify({
        gallery: { image_uploads: galleries }
      })
    },
    onSuccessCallback
  })
}

export function fetchUnreadMessage(onSuccessCallback) {
  return fetchApi({
    requestType: FETCH_UNREAD_MESSAGE,
    successType: FETCH_UNREAD_MESSAGE_SUCCESS,
    errorType: FETCH_UNREAD_MESSAGE_ERROR,
    route: '/mini_badges',
    options: {
      method: 'GET'
    },
    onSuccessCallback
  })
}
