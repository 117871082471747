import { FlyerLayoutAbstract } from './flyer-layout.abstract'
import moment from 'moment/moment'
import n2w from 'number-to-words'

export default class Flyer_layout_bottom_split_alignment_008LayoutModel extends FlyerLayoutAbstract {
  getFlourishes() {
    let flourishes = this.flourishes

    if (!this.eventData.starts_at && !this.eventData.ends_at) {
      flourishes = flourishes.filter(f => f.name !== 'bunting_flyer_flourish')
    }

    return flourishes
  }

  calcImageGap() {
    const startDate = this.eventData.starts_at
    const endDate = this.eventData.ends_at

    const isSameDay = moment(startDate).isSame(endDate, 'day')
    const isSameMonth = moment(startDate).isSame(endDate, 'month')

    if (endDate) {
      if (isSameDay) {
        return 0
      }

      if (isSameMonth) {
        return 40
      } else {
        return 70
      }
    }

    return 0
  }

  calcNameGap() {
    const { starts_at, ends_at } = this.eventData

    if (!starts_at && !ends_at) {
      return 70
    }

    return 0
  }

  flourishes = [
    {
      name: 'bunting_flyer_flourish',
      left: 125 - this.calcImageGap(),
      top: 435,
      width: 80,
      height: 28
    }
  ]

  textLayouts = {
    event_name_1: {
      top: 375 + this.calcNameGap(),
      left: 20,
      size: 30,
      width: 280,
      nowrap: true
    },
    location_1: {
      top: 490,
      right: 20,
      size: 24,
      width: 280,
      align: 'right',
      nowrap: true
    },
    location_2: {
      top: 515,
      right: 20,
      size: 24,
      width: 280,
      height: 24,
      align: 'right'
    }
  }

  dateLayouts = {
    date_1: {
      top: 350,
      left: 20,
      daySize: 26,
      width: 280,
      height: 26
    }
  }

  datePropsConfigsMapping = {
    date1: 'date_1',
    date3: 'date_3'
  }

  constructor(layout, eventData) {
    super(layout, eventData)
    this.init()
  }

  getDateTexts() {
    const startDate = this.eventData.starts_at
    const endDate = this.eventData.ends_at
    const date1 = this.dateLayouts.date_1
    const paddingGap = this.calcImageGap()

    let dates = []

    const isSameDay = moment(startDate).isSame(endDate, 'day')
    const { date1Props, date3Props } = this.dateProps

    const textArea1 = {
      width: date1.width,
      height: date1.height,
      top: date1.top,
      left: date1.left,
      size: date1.daySize,
      color: date1Props.color.replace('0x', '#'),
      font: date1Props.font.replace(/\s+/g, '-')
    }

    const textArea2 = {
      width: date1.width,
      height: date1.height,
      top: 410,
      right: 20,
      size: date1.daySize,
      color: date1Props.color.replace('0x', '#'),
      font: date1Props.font.replace(/\s+/g, '-'),
      align: 'right'
    }

    const textArea3 = {
      width: 90 + paddingGap,
      height: 36,
      top: 435,
      right: 20,
      size: 36,
      color: date1Props.color.replace('0x', '#'),
      font: date3Props.font.replace(/\s+/g, '-'),
      align: 'right'
    }

    const startMoment = moment(startDate)
    const endMoment = moment(endDate)

    if (startDate && !endDate) {
      const text1 = startMoment.format('dddd')
      textArea1.text = date1Props.all_caps ? text1.toUpperCase() : text1
      dates.push(textArea1)

      const s_h = startMoment.format('H')
      const s_m = startMoment.format('mm')
      const text2 = n2w.toWords(s_h) + ' ' + n2w.toWords(s_m)
      textArea2.text = date1Props.all_caps ? text2.toUpperCase() : text2
      dates.push(textArea2)

      const s_M = startMoment.format('MMMM').substring(0, 4)
      const s_D = startMoment.format('D')
      textArea3.text = s_M + ' ' + s_D
      dates.push(textArea3)
    }

    if (startDate && endDate) {
      if (isSameDay) {
        const text1 = startMoment.format('dddd')
        textArea1.text = date1Props.all_caps ? text1.toUpperCase() : text1
        dates.push(textArea1)

        const s_h = startMoment.format('H')
        const s_m = startMoment.format('mm')
        const e_h = endMoment.format('H')
        const e_m = endMoment.format('mm')
        const s_str = n2w.toWords(s_h) + ' ' + (Number(s_m) === 0 ? "o'clock" : n2w.toWords(s_m))
        const e_str = n2w.toWords(e_h) + ' ' + (Number(e_m) === 0 ? "o'clock" : n2w.toWords(e_m))
        const text2 = s_str + ' - ' + e_str
        textArea2.text = date1Props.all_caps ? text2.toUpperCase() : text2
        dates.push(textArea2)

        const s_M = startMoment.format('MMMM').substring(0, 4)
        const s_D = startMoment.format('D')
        textArea3.text = s_M + ' ' + s_D
        dates.push(textArea3)
      } else {
        const s_h = startMoment.format('H')
        const s_m = startMoment.format('mm')
        const s_w = startMoment.format('dddd')
        const text1 =
          s_w + '-' + n2w.toWords(s_h) + ' ' + (Number(s_m) === 0 ? "o'clock" : n2w.toWords(s_m))
        textArea1.text = date1Props.all_caps ? text1.toUpperCase() : text1
        dates.push(textArea1)

        const e_h = endMoment.format('H')
        const e_m = endMoment.format('mm')
        const e_w = endMoment.format('dddd')

        const text2 =
          'till' +
          ' ' +
          e_w +
          '-' +
          n2w.toWords(e_h) +
          ' ' +
          (Number(e_m) === 0 ? "o'clock" : n2w.toWords(e_m))
        textArea2.text = date1Props.all_caps ? text2.toUpperCase() : text2
        dates.push(textArea2)

        const s_M = startMoment.format('MMMM').substring(0, 4)
        const s_D = startMoment.format('D')
        const e_M = endMoment.format('MMMM').substring(0, 4)
        const e_D = endMoment.format('D')

        const isSameMonth = startMoment.isSame(endMoment, 'month')
        if (isSameMonth) {
          textArea3.text = `${s_M} ${s_D} - ${e_D}`
        } else {
          textArea3.text = `${s_M} ${s_D} - ${e_M} ${e_D}`
        }
        dates.push(textArea3)
      }
    }

    return { type: 'date', dates }
  }
}
