import React, { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { userSelector } from 'src/selectors'
import { updateMenuPanel } from 'src/actions/frontend'
import { PanelSlug } from '../PanelContainer'
import MembershipInfo from '../../MembershipInfo/MembershipInfo'
import { LeftOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { PlanType, getPlanBySlug, PlanSlugs } from 'src/services/stripe_checkout_configs'
import { verifySubscriptionPlatform } from 'src/services/frontend_tips'
import CreditIcon from 'assets/icons/credit-icon.png'
import cn from 'classnames'
import Button from 'src/components/common/Button'
import { changePlanModalVisible } from 'src/actions/plan'
import { changeVisible, setContent } from 'src/actions/globalLayer'
import { isMobile } from 'services/browser_detection'
import { routerActions } from 'react-router-redux'
import { GLOBAL_LAYER_CONTENT } from 'src/constants'
import RightIcon from 'assets/icons/right-icon.png'
import debounce from 'lodash/debounce'

import styles from './membership-detail.scss'

const planDescriptions = period => {
  const description1 = num => (
    <div>
      <b>
        {num} included credits{period === 'monthly' ? '' : '/month'}
      </b>{' '}
      - send multimedia invites with our Automated Invite technology to track views and send RSVP
      reminders
    </div>
  )
  const description2 = (
    <div>
      <b>Thousands</b> of premium images and videos and flyer and invitation templates
    </div>
  )
  const description3 = (
    <div>
      Send <b>Instant Updates</b> by text to tell guests last minute changes or important
      information
    </div>
  )
  const description4 = (
    <div>
      <b>Unlimited polls</b> to efficiently plan best times with your group, putting an end to
      endless group texts
    </div>
  )
  const description5 = (
    <div>
      Access to <b>purchase credits anytime</b> you need more - for larger events and other member
      benefits
    </div>
  )
  const description6 = (
    <div>
      Sell tickets like a pro with <b>the lowest fees</b> in the industry
    </div>
  )
  const description7 = num => (
    <div>
      Share <b>up to {num}</b> Event Videos in the Event Chat and Media Album
    </div>
  )
  const description8 = (
    <div>
      Share <b>4</b> Event Videos to the Media Album
    </div>
  )

  return {
    [PlanType.Basic]: {
      themeColor: styles['invitation-maker-theme-color'],
      tick: (
        <CheckOutlined
          style={{ color: styles['invitation-maker-theme-color'], fontSize: '18px' }}
        />
      ),
      descriptions: [description4, description1(20), description5, description7(20)]
    },
    [PlanType.Essentials]: {
      themeColor: styles['party-host-theme-color'],
      tick: <CheckOutlined style={{ color: styles['party-host-theme-color'], fontSize: '18px' }} />,
      descriptions: [
        description2,
        description3,
        description4,
        description1(10),
        description5,
        description6
      ]
    },
    [PlanType.Premium]: {
      themeColor: styles['event-planner-theme-color'],
      tick: (
        <CheckOutlined style={{ color: styles['event-planner-theme-color'], fontSize: '18px' }} />
      ),
      descriptions: [
        description1(350),
        description2,
        description3,
        description4,
        description5,
        description6,
        description7(30)
      ]
    },
    [PlanType.InvitationMaker]: {
      title: 'For Hosts simply seeking stunning, customized video and photo flyers',
      themeColor: styles['invitation-maker-theme-color'],
      tick: (
        <CheckOutlined
          style={{ color: styles['invitation-maker-theme-color'], fontSize: '18px' }}
        />
      ),
      descriptions: [description1(10), description2, description4, description8]
    },
    [PlanType.PartyHost]: {
      title: 'For the savvy Host who wants ultimate efficiency and style',
      themeColor: styles['party-host-theme-color'],
      tick: <CheckOutlined style={{ color: styles['party-host-theme-color'], fontSize: '18px' }} />,
      descriptions: [
        description1(50),
        description2,
        description3,
        description4,
        description5,
        description6,
        description7(10)
      ]
    },
    [PlanType.EventPlanner]: {
      title: 'For Hosts who want to effortlessly manage a larger event',
      themeColor: styles['event-planner-theme-color'],
      tick: (
        <CheckOutlined style={{ color: styles['event-planner-theme-color'], fontSize: '18px' }} />
      ),
      descriptions: [
        description1(150, styles['event-planner-theme-color'], period),
        description2,
        description3,
        description4,
        description5,
        description6,
        description7(20)
      ]
    },
    [PlanType.GroupOrganizer]: {
      title: 'Best for Hosts responsible for organizing large or recurring events',
      themeColor: styles['group-organizer-theme-color'],
      tick: (
        <CheckOutlined style={{ color: styles['group-organizer-theme-color'], fontSize: '18px' }} />
      ),
      descriptions: [
        description1(350),
        description2,
        description3,
        description4,
        description5,
        description6,
        description7(30)
      ]
    }
  }
}

const planBgColor = {
  [PlanType.Free]: '#f9f9f9',
  [PlanType.Essentials]: styles['invitation-maker-bg-color'],
  [PlanType.Premium]: styles['invitation-maker-bg-color'],
  [PlanType.Basic]: styles['invitation-maker-bg-color'],
  [PlanType.InvitationMaker]: styles['invitation-maker-bg-color'],
  [PlanType.PartyHost]: styles['party-host-bg-color'],
  [PlanType.EventPlanner]: styles['event-planner-bg-color'],
  [PlanType.GroupOrganizer]: styles['group-organizer-bg-color']
}

const MembershipDetail = props => {
  const { onResetMenuState } = props
  const dispatch = useDispatch()

  const [isScrollToBottom, setIsScrollToBottom] = useState(false)
  const user = useSelector(userSelector)
  const ulRef = useRef(null)
  const { plan, cancel_at_period_end, subscription_type } = user.current_subscription
  const userPlanType = getPlanBySlug(plan.slug, 'planType')
  const subPeriod = plan.subscription_period
  const { themeColor, descriptions, tick } = planDescriptions(subPeriod)[userPlanType]

  const handlePurchaseCredits = () => {
    if (isMobile()) {
      dispatch(routerActions.push('/purchase-credit'))
    } else {
      dispatch(setContent(GLOBAL_LAYER_CONTENT.purchaseCredit))
      dispatch(changeVisible(true))
    }
  }

  const handleClickSubscribe = () => {
    if (verifySubscriptionPlatform(subscription_type)) {
      onResetMenuState?.()
      dispatch(changePlanModalVisible(true))
    }
  }

  const handleCancelMembership = () => {
    if (verifySubscriptionPlatform(subscription_type)) {
      onResetMenuState?.()
      dispatch(changeVisible(true))
      dispatch(setContent(GLOBAL_LAYER_CONTENT.cancelMembership))
    }
  }

  const handleClickSeeMore = () => {
    const ulElement = ulRef.current
    ulElement.scrollTo({
      top: ulElement.scrollHeight,
      behavior: 'smooth'
    })
  }

  const handleScroll = debounce(() => {
    const ulElement = ulRef.current
    if (ulElement.scrollTop === ulElement.scrollHeight - ulElement.clientHeight) {
      setIsScrollToBottom(true)
      return
    }

    if (isScrollToBottom) {
      setIsScrollToBottom(false)
    }
  }, 300)

  const isUpgradeButtonVisible = plan.slug !== PlanSlugs.GroupOrganizerAnnually
  const isCancelButtonVisible = !cancel_at_period_end && plan.slug !== PlanSlugs.Free

  return (
    <main className={styles['membership-detail-container']}>
      <section
        className={cn('web-view', styles['go-back-btn'])}
        onClick={() => dispatch(updateMenuPanel(PanelSlug.Profile))}
      >
        <LeftOutlined
          style={{ fontSize: 24 }}
          onClick={() => dispatch(updateMenuPanel(PanelSlug.Profile))}
        />
        <CloseOutlined style={{ fontSize: 24 }} onClick={onResetMenuState} />
      </section>

      <section className={styles['content-container']}>
        <section
          className={cn(styles['profile-container'])}
          style={{ backgroundColor: planBgColor[userPlanType] }}
        >
          <MembershipInfo isInDetail />
          <div className={styles['purchase-credit-button']} onClick={handlePurchaseCredits}>
            <img src={CreditIcon} width={18} />
            Purchase Credits
          </div>
        </section>

        <section className={styles['title']}>membership benefits</section>

        <div className={styles['description-wrapper']}>
          <ul className={styles['description-ul']} ref={ulRef} onScroll={handleScroll}>
            {descriptions.map((desc, index) => (
              <li key={index} className={styles['description-li']}>
                {tick}
                {desc}
              </li>
            ))}
          </ul>
          {descriptions.length > 4 && (
            <div
              className={cn(
                styles['see-more-button'],
                isScrollToBottom && styles['see-more-button-disabled']
              )}
            >
              <div className={styles['button-inner']} onClick={handleClickSeeMore}>
                <span />
                <span>SEE MORE</span>
                <img src={RightIcon} alt="" />
              </div>
            </div>
          )}
        </div>
      </section>

      <section className={styles['membership-actions']}>
        {isUpgradeButtonVisible && (
          <Button
            style={{ backgroundColor: themeColor }}
            onClick={handleClickSubscribe}
            className={cn(styles['upgrade-button-inner'])}
          >
            SEE UPGRADE OPTIONS
          </Button>
        )}

        {isCancelButtonVisible && (
          <p className={styles['cancel-button-inner']} onClick={handleCancelMembership}>
            Cancel My Membership
          </p>
        )}
      </section>
    </main>
  )
}

MembershipDetail.propTypes = {
  onResetMenuState: PropTypes.func
}

export default MembershipDetail
