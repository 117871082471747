import PropTypes from 'prop-types'
import React from 'react'

import { fullName } from 'services/user_helpers'

import HobnobLogo from 'src/assets/hobnob_logo.png'
import { DESTINATION_TYPES_CONSTANTS } from 'src/constants'
import styles from './missing-event.scss'

export default class MissingEvent extends React.Component {
  _message () {
    const { host, eventLoadingStatus, type = DESTINATION_TYPES_CONSTANTS.event } = this.props

    if (eventLoadingStatus === 'destroyed') {
      if (host) {
        return `${fullName(host)} has deleted this ${type === DESTINATION_TYPES_CONSTANTS.timepoll ? 'time poll' : 'event'}`
      } else {
        return `This ${type === DESTINATION_TYPES_CONSTANTS.timepoll ? 'time poll' : 'event'} has been deleted by the host.`
      }
    }

    if (eventLoadingStatus === 'not_found') {
      return `This ${type === DESTINATION_TYPES_CONSTANTS.timepoll ? 'time poll' : 'event'} could not be found, please check the link and try again`
    }

    return 'Something went wrong'
  }

  render () {
    return (
      <div className={styles['destroyed-page']}>
        <div className={styles['destroyed-container']}>
          <div className={styles['destroyed']}>
            <img className={styles['logo']} src={HobnobLogo} />
            {this._message()}
          </div>
        </div>
      </div>
    )
  }
}

MissingEvent.propTypes = {
  host: PropTypes.object,
  eventLoadingStatus: PropTypes.string,
  type: PropTypes.string
}
