import React, { Component } from 'react'
import ReactActionSheet from 'actionsheet-react'
import PropTypes from 'prop-types'
import styles from './action-sheet.scss'

class ActionSheet extends Component {
  actionSheetRef = React.createRef()

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      if (this.props.visible) {
        this.actionSheetRef.current.open()
      } else {
        this.actionSheetRef.current.close()
      }
    }
  }

  render() {
    const { onClose } = this.props
    const actionSheetStyle = {
      backgroundColor: 'transparent',
      left: '3%',
      right: '3%',
      bottom: '0',
      width: 'auto',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    }

    return (
      <ReactActionSheet
        ref={this.actionSheetRef}
        sheetStyle={actionSheetStyle}
        bgStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
        onClose={onClose}
      >
        <div className={styles['action-sheet-container']}>
          {this.props.headerNode}
          {this.props.children}
        </div>
        <div className={styles['cancel-text']} onClick={onClose}>
          cancel
        </div>
      </ReactActionSheet>
    )
  }
}

ActionSheet.defaultProps = {
  visible: false
}

ActionSheet.propTypes = {
  children: PropTypes.node.isRequired,
  headerNode: PropTypes.node,
  visible: PropTypes.bool,
  onClose: PropTypes.func
}

export default ActionSheet
