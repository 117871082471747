import React from 'react'
import PropTypes from 'prop-types'

import SvgIcon from 'components/common/SvgIcon'
import ArrowIcon from 'assets/icons/arrow.svg'
import cn from 'classnames'

import styles from './card.scss'

class Card extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isExpanded: false
    }
  }

  _toogleCollapse = () => {
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  _applyTimeZone = (date, timeZone) => {
    if (date) return new Date(date.toLocaleString('en-US', { timeZone: timeZone }))
  }

  _getDate = (date, timeZoneSelected) => {
    const newDate = new Date(date)
    if (timeZoneSelected) return this._applyTimeZone(newDate, timeZoneSelected)
    return newDate
  }

  render() {
    const { opt, children, style, isTimePoll, timeZoneSelected, isStar, isDisabled } = this.props

    const date = this._getDate(opt.starts_at, timeZoneSelected)
    const endDate = this._getDate(opt.ends_at, timeZoneSelected)

    const formatDate = date => {
      const weekday = date.toLocaleString('default', { weekday: 'long' }).slice(0, 3)
      const month = date.toLocaleString('default', { month: 'short' }).slice(0, 3)
      const day = date.getDate()
      return weekday + ', ' + month + ' ' + day
    }

    const formatMultiDate = (start, end) => {
      const weekday = start.toLocaleString('default', { weekday: 'long' }).slice(0, 3)
      const weekdayEnd = end.toLocaleString('default', { weekday: 'long' }).slice(0, 3)
      const month = start.toLocaleString('default', { month: 'short' }).slice(0, 3)
      const day = start.getDate()
      const dayEnd = end.getDate()
      return weekday + '-' + weekdayEnd + ', ' + month + ' ' + day + '-' + dayEnd
    }

    const formatHour = date => {
      const hour = date.toLocaleTimeString(navigator.language, {
        hour12: true,
        hour: '2-digit',
        minute: '2-digit'
      })
      return hour
    }

    const formatMessage = message => message.join(', ')

    const formatMessageWithLimit = (message, max) => {
      if (message.length >= max) return message.slice(0, max) + '...'
      return message
    }

    const isDesktop = window.innerWidth >= 1080
    const chartsMax = isDesktop ? 30 : 45
    if (!isTimePoll) {
      opt.poll_user_selections = []
    }
    const filteredUserSelections = opt.poll_user_selections.filter(
      option => option.poll_option_id !== null
    )
    const tooltipMessage = filteredUserSelections.map(
      item =>
        item.custom_user_name ||
        (item.user && `${item.user.first_name} ${item.user.last_name}`) ||
        ''
    )
    const tooltipMessageFormat = tooltipMessage.join(', ')
    const voters = formatMessage(tooltipMessage)
    const textUserSelections = formatMessageWithLimit(voters, chartsMax)
    const isCollapseMessage = textUserSelections.length >= chartsMax
    return (
      <div className={cn(styles['card'], style, { [styles['card--disabled']]: isDisabled })}>
        {opt.starts_at && opt.ends_at ? (
          <div className={styles['card__date-information']}>
            <div className={styles[isStar ? 'card__date-star' : 'card__date--desktop']}>
              <span className={styles['card__date--desktop-month']}>
                {date.toLocaleString('default', { month: 'short' })}
              </span>
              <span className={styles['card__date--desktop-date']}>
                {opt.is_all_day ? date.getDate() + '-' + endDate.getDate() : date.getDate()}
              </span>
              <span className={styles['card__date--desktop-day']}>
                {opt.is_all_day
                  ? date.toLocaleString('default', { weekday: 'short' }) +
                    '-' +
                    endDate.toLocaleString('default', { weekday: 'short' })
                  : date.toLocaleString('default', { weekday: 'short' })}
              </span>
            </div>
            {!isDesktop && opt.is_all_day ? (
              <p className={styles['card__hour-range']}>{formatMultiDate(date, endDate)}</p>
            ) : (
              <p className={styles[isStar ? 'card__date-hidden' : 'card__date--mobile-date']}>
                {formatDate(date)}
              </p>
            )}
            {!opt.is_all_day && (
              <p className={styles['card__date--mobile-hour']}>
                {formatHour(date) + ' - ' + formatHour(endDate)}
              </p>
            )}
          </div>
        ) : (
          <div className={styles['card__no-options']}>
            <span className={styles['card__no-options_item']}>No</span>
            <span className={styles['card__no-options_item']}>Options</span>
            <span className={styles['card__no-options_item']}>Work</span>
          </div>
        )}
        {children}
        {isTimePoll && (
          <div
            className={styles['card__text-voters']}
            aria-label={isDesktop ? tooltipMessageFormat || null : null}
            data-cooltipz-dir={tooltipMessage.length ? 'top' : null}
            data-cooltipz-size="fit"
          >
            <p>{this.state.isExpanded ? voters : textUserSelections}</p>
            {!isDesktop && isCollapseMessage && (
              <SvgIcon
                icon={ArrowIcon}
                className={styles['icon']}
                divClassName={
                  this.state.isExpanded ? styles['icon__arrow-expanded'] : styles['icon__arrow']
                }
                onClick={this._toogleCollapse}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

Card.propTypes = {
  opt: PropTypes.object.isRequired,
  style: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isTimePoll: PropTypes.bool,
  timeZoneSelected: PropTypes.string,
  isStar: PropTypes.bool,
  isDisabled: PropTypes.bool
}

export default Card
