import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import LoadingComponent from 'src/components/common/LoadingComponent/LoadingComponent'
import PageContainerContext from 'services/contexts'
import {
  POPUP_BOX_TYPES_CONSTANTS,
  DESTINATION_TYPES_CONSTANTS,
  ROOM_CHANNEL_TYPES_CONSTANTS
} from './constants'
import { fetchMeWithCommunity, fetchUserGQ } from 'src/actions/user'
// import StripeCheckoutContainer from 'containers/StripeCheckoutContainer/StripeCheckoutContainerV2'
import LoginModal from 'src/components/LoginModal/LoginModal'
import { parseEventUrl } from 'services/url_helpers'
import {
  clearStripeCheckoutTimeoutHandle,
  StripeCheckoutBroadcastChannelKey
} from 'src/actions/stripeCheckout'
import {
  stripeCheckoutSelector,
  userIdSelector,
  isLoadingVisibleSelector,
  destinationTypeSelector,
  eventTokenSelector,
  previewGalleriesSelector,
  eventModalSelector,
  chatSelector,
  activityFeedSelector
} from 'src/selectors'
import { clearUTMData } from 'services/stripe_checkout_configs'
import { isSafariLessThan14, safariHacks, isMobile } from './services/browser_detection'
import { Image } from 'antd'
import { debounce } from 'lodash'
import ShareModal from 'components/ShareModal/ShareModal'
import data from '@emoji-mart/data'
import { init } from 'emoji-mart'
import { hideBanner, setPreviewIndex } from 'src/actions/frontend'
import HobnobHud from 'src/components/common/HobnobHud'
import MessageModalContainer from 'src/components/modals/MessageModalContainer'
import GlobalLayer from 'containers/GlobalLayer/GlobalLayer'
import { fetchEvent } from 'src/actions/event'
import { fetchActivityFeed } from 'src/actions/activityFeed'
import { hasUserAccessToken } from 'src/services/access_tokens'
import { createSocket, resetSocket, subscribeChannel } from 'services/websocket'
import { changeChatStatus } from 'src/actions/eventChat'

const PageContainer = ({ children }) => {
  const dispatch = useDispatch()
  const isLoadingVisible = useSelector(isLoadingVisibleSelector)
  const eventModal = useSelector(eventModalSelector)
  const checkoutContext = useSelector(stripeCheckoutSelector)
  const userId = useSelector(userIdSelector)
  const destinationType = useSelector(destinationTypeSelector)
  const eventToken = useSelector(eventTokenSelector)
  const previewGalleries = useSelector(previewGalleriesSelector)
  const { newestTimestamp, selectedTag } = useSelector(activityFeedSelector)
  const { socketInstance, chatStatus, channelName } = useSelector(chatSelector)
  const isHasUserAccessToken = hasUserAccessToken()

  const popupBoxName = useRef(null)
  const popupBoxCallBack = useRef(null)
  const storageCallback = useRef(null)
  const newestTime = useRef(newestTimestamp)

  useEffect(() => {
    if (newestTime.current !== newestTimestamp) {
      newestTime.current = newestTimestamp
    }
  }, [newestTimestamp])

  const handleBanner = () => {
    if (sessionStorage.getItem('hideBanner')) {
      dispatch(hideBanner())
    }
  }

  const handleResize = debounce(() => {
    if (!isMobile()) {
      safariHacks()
    }
  }, 500)

  useEffect(() => {
    const verifyPopupBox = () => {
      const { destinationType } = parseEventUrl(window.location.pathname)

      switch (destinationType) {
        case DESTINATION_TYPES_CONSTANTS.event:
          const modals = ['addGuests', 'guests', 'chat']
          if (modals.includes(eventModal)) {
            return true
          }
          return false
        default:
          return false
      }
    }

    const watchPopState = () => {
      const popupBoxes = Object.values(POPUP_BOX_TYPES_CONSTANTS)
      if (
        verifyPopupBox() &&
        popupBoxes.includes(popupBoxName.current) &&
        popupBoxCallBack.current
      ) {
        popupBoxCallBack.current()
      }
    }

    window.addEventListener('popstate', watchPopState)

    return () => {
      window.removeEventListener('popstate', watchPopState)
    }
  }, [eventModal])

  useEffect(() => {
    const watchStorage = event => {
      if (event.key === StripeCheckoutBroadcastChannelKey) {
        const data = JSON.parse(event.newValue)

        if (storageCallback.current) {
          storageCallback.current(data)
        }

        if (data && !data?.hasError && (data.planUpdated || data.creditsUpdated)) {
          checkoutContext.openedTabRef?.close()
          clearUTMData()
          dispatch(fetchUserGQ())

          // If it is in an event, the event data should be reloaded.
          if (destinationType === DESTINATION_TYPES_CONSTANTS.event) {
            dispatch(fetchEvent(eventToken))
            return
          }
        }

        dispatch(clearStripeCheckoutTimeoutHandle())
        localStorage.removeItem(StripeCheckoutBroadcastChannelKey)
      }
    }

    window.addEventListener('storage', watchStorage)

    return () => window.removeEventListener('storage', watchStorage)
  }, [checkoutContext])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    isSafariLessThan14()
    safariHacks()
    handleBanner()
    init({ data })

    if (window.location.href.includes('/home')) {
      setTimeout(() => {
        if (window.mixpanel) {
          window.mixpanel.track('User From Search', { user_id: userId })
        }
      }, 2000)
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const initSocket = () => {
    if (isHasUserAccessToken && !socketInstance && userId) {
      resetSocket(dispatch)
      createSocket({ dispatch })

      // Subscribe to activity-feed.
      subscribeChannel({
        channelName: `user:${userId}`,
        dispatch,
        subscriberList: [
          {
            action: ROOM_CHANNEL_TYPES_CONSTANTS.mini_badge_created,
            onReceived: () => {
              let params = null
              if (newestTime.current && selectedTag !== 'Read') {
                params = { after: newestTime.current, fromWS: true }
                dispatch(fetchActivityFeed(params))
              }
            }
          }
        ]
      })
    }
  }

  useEffect(() => {
    if (isHasUserAccessToken) {
      dispatch(fetchMeWithCommunity())
      dispatch(fetchActivityFeed())
      initSocket()
    }
  }, [isHasUserAccessToken, userId])

  useEffect(() => {
    const watchVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        console.log('回到前端页面')
        dispatch(changeChatStatus('connecting'))

        if (socketInstance) {
          window.eventChatTimeout = setTimeout(() => {
            if (chatStatus === 'connecting') {
              dispatch(changeChatStatus('disconnected'))
            }
          }, 5000)

          socketInstance.connect()
        } else {
          createSocket({ dispatch, channelName })
        }
      } else {
        console.log('离开前端页面')
        if (window.eventChatTimeout) {
          clearTimeout(window.eventChatTimeout)
          window.eventChatTimeout = null
        }
        socketInstance.disconnect()
      }
    }

    if (socketInstance && userId) {
      document.addEventListener('visibilitychange', watchVisibilityChange)
    }

    return () => {
      document.removeEventListener('visibilitychange', watchVisibilityChange)
    }
  }, [socketInstance, userId])

  return (
    <PageContainerContext.Provider
      value={{
        onChangePopupBox: name => (popupBoxName.current = name),
        onChangePopupBoxCallBack: callback => (popupBoxCallBack.current = callback),
        onSetStorageCallback: callback => (storageCallback.current = callback)
      }}
    >
      {children}
      {isLoadingVisible && <LoadingComponent dark />}
      <svg style={{ visibility: 'hidden', height: 0, width: 0 }}>
        <defs>
          <linearGradient id="hobnob-gradient" x1="100%" y1="100%" x2="0%">
            <stop stopColor="#55EFCB" />
            <stop offset="1" stopColor="#99E09C" />
          </linearGradient>
        </defs>
      </svg>

      <LoginModal />
      <ShareModal />
      <HobnobHud />
      <MessageModalContainer />
      <GlobalLayer />
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{
            visible: Boolean(previewGalleries.index > -1),
            onVisibleChange: () => {
              dispatch(setPreviewIndex(-1))
            },
            current: previewGalleries.index
          }}
        >
          {previewGalleries.galleries.map(img => (
            <Image key={img.id} src={img.url} />
          ))}
        </Image.PreviewGroup>
      </div>
    </PageContainerContext.Provider>
  )
}

PageContainer.propTypes = {
  children: PropTypes.node.isRequired
}

export default PageContainer
