import React, { Component } from 'react'
import PropTypes from 'prop-types'

import HobnobNavBar from 'components/common/HobnobNavBar'
import { currentOrderSelector, eventSelector, ticketTypesSelector } from '../selectors'
import { connect } from 'react-redux'
import SvgIcon from '../components/common/SvgIcon'
import InformationIcon from '../assets/icons/information_icon.svg'
import styles from './request-refund-page.scss'
import TotalTicketsCounts from '../components/rsvp_modal/TotalTicketsCounts'
import ResizeableTextarea from '../components/common/ResizeableTextarea'

import { displayCurrency, isEmptyObject } from '../services/utils'
import Button from '../components/common/Button'
import { routerActions } from 'react-router-redux'

import { showHud } from '../actions/frontend'
import { createRefundRequestByGuest } from '../actions/paymentGraphql'
import RefundPolicyModal from '../components/modals/RefundPolicyModal'

class RequestRefundPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      reason: '',
      disabledButton: false,
      showRefundPolicyModal: false
    }
  }

  componentWillMount () {
    const { order, onChangeRoute } = this.props
    if (!order || isEmptyObject(order)) {
      onChangeRoute('details')
    }
  }

  _onSetReason = (value) => {
    this.setState({
      reason: value
    })
  }

  _refundMoney = (totalFee) => {
    const { order } = this.props
    return displayCurrency((order.payment.amount - parseInt(totalFee)))
  }

  _viewRefundPolicy = () => {
    this.setState({
      showRefundPolicyModal: true
    })
  }

  _onCloseRefundPolicyModal = () => {
    this.setState({
      showRefundPolicyModal: false
    })
  }

  _resetState = () => {
    this.setState({
      reason: '',
      disabledButton: true
    })
  }

  _hudCallBackAction = () => {
    const { onChangeRoute } = this.props
    onChangeRoute('orderDetail')
  }

  _requestRefund = () => {
    const { order } = this.props
    const { reason } = this.state
    const { onCreateRefundRequestByGuest, onShowHud } = this.props
    const successActions = [onShowHud('sent', 'Refund Requested', this._hudCallBackAction), this._resetState()]
    onCreateRefundRequestByGuest({ order_id: order.id, reason }, { successActions })
  }

  render () {
    const { event, order, ticketTypes } = this.props

    if (!order || isEmptyObject(order)) {
      return null
    }

    const { reason, disabledButton, showRefundPolicyModal } = this.state
    const totalFee = order.payment.platform_fee + order.payment.stripe_fee
    const ticketCounts = order.tickets.map((ticket) => {
      const ticketType = ticketTypes.find(item => item.id === ticket.ticket_type_id)
      return {
        ...ticket,
        id: ticket.ticket_type_id,
        label: ticketType.label,
        price: ticketType.price,
        count: 1
      }
    })
    return (
      <div className={styles['wrapper']}>
        <HobnobNavBar
          backPathName='orderDetail'
          title={'Request Refund'}
          subTitle={event.name}
          close
        >
          <a href={'http://refunds.faq.hobnob.io'} target='_blank'>
            <SvgIcon icon={InformationIcon} className={styles['information-icon']} />
          </a>
        </HobnobNavBar>

        <div className={styles['container']}>
          <div>
            <TotalTicketsCounts
              needPayTicket
              listMyOrdersForEvent={[order]}
              ticketCounts={ticketCounts}
              className={styles['total-tickets-counts']}
              showPurchasedTime
            />
            <ResizeableTextarea
              className={styles['reason-text-box']}
              comment={reason}
              onChangeComment={this._onSetReason}
              placeholder='Let the host know why you are requesting a refund…(optional)'
            />
          </div>

          {
            <div className={styles['refund-due-wrapper']}>
              <div className={styles['fee']}>Paid:&nbsp;{displayCurrency(order.payment.amount)}</div>
              <div className={styles['fee']}>Fees:&nbsp;{displayCurrency(totalFee)}</div>
              <div className={styles['refund']}>Refund Total:&nbsp;
                {this._refundMoney(totalFee)}
              </div>
            </div>
          }
          <div className={styles['policy-wrapper']}>
            {
              !!event.refund_policy &&
              <a className={styles['policy-link']} onClick={this._viewRefundPolicy}>View Refund Policy</a>
            }

            <div className={styles['tip']}>
              Payment processing fees are non-refundable. Receiving a refund automatically changes your RSVP to No.
            </div>
          </div>

        </div>

        <Button disabled={disabledButton} className={styles['button']} onClick={this._requestRefund}>request refund</Button>

        <RefundPolicyModal show={showRefundPolicyModal} onClose={this._onCloseRefundPolicyModal}
          refundPolicy={event.refund_policy} />
      </div>
    )
  }
}

RequestRefundPage.propTypes = {
  event: PropTypes.object,
  order: PropTypes.object,
  onChangeRoute: PropTypes.func,
  onCreateRefundRequestByGuest: PropTypes.func,
  onShowHud: PropTypes.func,
  ticketTypes: PropTypes.array
}

function mapStateToProps (state) {
  return {
    order: currentOrderSelector(state),
    event: eventSelector(state),
    ticketTypes: ticketTypesSelector(state)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    onChangeRoute: (routerPath) => {
      dispatch(routerActions.push(routerPath))
      window.scrollTo(0, 0)
    },
    onCreateRefundRequestByGuest: (variables) => dispatch(createRefundRequestByGuest(variables)),
    onShowHud: (hudType, message, callback) => dispatch(showHud(hudType, message, callback))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestRefundPage)
