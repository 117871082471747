import { combineReducers } from 'redux'

import {
  SHOW_CHAT_SETTINGS_MODAL,
  HIDE_CHAT_SETTINGS_MODAL
} from 'src/actions/chat'

import {
  UPDATE_INVITATION,
  UPDATE_INVITATION_SUCCESS,
  UPDATE_INVITATION_ERROR
} from 'src/actions/invitations'

const visible = (state = false, action) => {
  switch (action.type) {
    case SHOW_CHAT_SETTINGS_MODAL :
      return true
    case HIDE_CHAT_SETTINGS_MODAL :
      return false
    default:
      return state
  }
}

const updatingInvitation = (state = false, action) => {
  switch (action.type) {
    case UPDATE_INVITATION:
      return true
    case UPDATE_INVITATION_SUCCESS:
    case UPDATE_INVITATION_ERROR:
      return false
    default:
      return state
  }
}

export default combineReducers({
  visible,
  updatingInvitation
})
