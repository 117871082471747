import { combineReducers } from 'redux'
import { sortBy } from 'lodash'

import {
  CREATE_WEBSOCKET,
  ADD_MESSAGE,
  SAVE_HISTORY_MESSAGE,
  SAVE_INVITED_EVENTS,
  UPDATE_MESSAGE,
  SET_GROUP_LIST,
  SET_MEMBER_LIST,
  DELETE_MESSAGE,
  SET_CHANNEL_NAME,
  CHANGE_CHAT_STATUS,
  SAVE_GALLERIES,
  SWITCH_CHAT_USER,
  SET_IMAGE_UPLOADING,
  SET_REPLY_MESSAGE,
  RESET_CHAT,
  SET_UNREAD_MESSAGE_LIST
} from 'src/actions/eventChat'

const channelName = (state = null, action) => {
  switch (action.type) {
    case SET_CHANNEL_NAME:
      return action.channelName
    case RESET_CHAT:
      return null
    default:
      return state
  }
}

const socketInstance = (state = null, action) => {
  switch (action.type) {
    case CREATE_WEBSOCKET:
      return action.socket
    default:
      return state
  }
}

const chatStatus = (state = 'connecting', action) => {
  switch (action.type) {
    case CHANGE_CHAT_STATUS:
      return action.status
    case RESET_CHAT:
      return 'connecting'
    default:
      return state
  }
}

const isImageUploading = (state = false, action) => {
  switch (action.type) {
    case SET_IMAGE_UPLOADING:
      return action.loading
    case RESET_CHAT:
      return false
    default:
      return state
  }
}

const chatMessages = (state = {}, action) => {
  switch (action.type) {
    case SAVE_HISTORY_MESSAGE:
      return action.messages.reduce((acc, message) => {
        acc[message.id] = message
        return acc
      }, {})
    case ADD_MESSAGE:
    case UPDATE_MESSAGE:
    case DELETE_MESSAGE:
      return {
        ...state,
        [action.message.id]: action.message
      }
    case RESET_CHAT:
      return {}
    default:
      return state
  }
}

const invitedEvents = (state = {}, action) => {
  switch (action.type) {
    case SAVE_INVITED_EVENTS:
      const event = action.event
      // const events = action.events.reduce((acc, event) => {
      //   acc[event.id] = event
      //   return acc
      // }, {})
      state[event.id] = event
      return state
    default:
      return state
  }
}

const chatUser = (state = null, action) => {
  switch (action.type) {
    case SWITCH_CHAT_USER:
      return action.user
    case RESET_CHAT:
      return null
    default:
      return state
  }
}

const messageList = (state = [], action) => {
  switch (action.type) {
    case SAVE_HISTORY_MESSAGE:
      const filteredMessage = action.messages.filter(message => {
        const { destroyed_at, thread_id, replies } = message
        return !destroyed_at && ((thread_id && replies.length > 0) || !thread_id)
      })

      return sortBy(filteredMessage, ['timestamp'])
    case ADD_MESSAGE:
      const messageIndex = state.findIndex(message => message.id === action.message.id)
      if (!action.message.thread_id) {
        if (messageIndex === -1) {
          return sortBy([...state, action.message], ['timestamp'])
        } else {
          state[messageIndex] = action.message
        }
      }

      return state
    case UPDATE_MESSAGE:
      return state.map(message => {
        if (message.id === action.message.id) {
          return action.message
        }
        return message
      })
    case DELETE_MESSAGE:
      return state.filter(message => message.id !== action.message.id)
    case RESET_CHAT:
      return []
    default:
      return state
  }
}

const unreadMessageList = (state = [], action) => {
  switch (action.type) {
    case SET_UNREAD_MESSAGE_LIST:
      return action.unreadMessageList
    default:
      return state
  }
}

const chatGalleries = (state = {}, action) => {
  switch (action.type) {
    case SAVE_GALLERIES:
      const gallery = action.gallery
      state[gallery.id] = gallery

      return state
    default:
      return state
  }
}

const groupList = (state = [], action) => {
  switch (action.type) {
    case SET_GROUP_LIST:
      return action.groupList
    case RESET_CHAT:
      return []
    default:
      return state
  }
}

const memberList = (state = [], action) => {
  switch (action.type) {
    case SET_MEMBER_LIST:
      return action.memberList
    case RESET_CHAT:
      return []
    default:
      return state
  }
}

const replyMessage = (state = null, action) => {
  switch (action.type) {
    case SET_REPLY_MESSAGE:
      return action.message
    case RESET_CHAT:
      return null
    default:
      return state
  }
}

export default combineReducers({
  channelName,
  socketInstance,
  chatUser,
  chatStatus,
  isImageUploading,
  chatMessages,
  chatGalleries,
  invitedEvents,
  messageList,
  unreadMessageList,
  groupList,
  memberList,
  replyMessage
})
