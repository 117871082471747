import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import FlyerFonts from 'src/assets/frontend-json/flyer-fonts.json'
import { flyerDesignLayoutConfig } from 'assets/frontend-json/flyer-position'
import FlyerCard from 'src/containers/event/CreateEvent/SelectFlyerStep/FlyerCard/FlyerCard'
import CustomFlyerPicker from 'src/components/common/CustomFlyerPicker'
import {
  fetchCreateEvent,
  fetchUploadFlyer,
  resetEventForm,
  createTicketType,
  archiveTicketType
} from 'src/actions/event'
import { fetchFlyerDesigns } from 'src/actions/flyer'
import { showLogin } from 'src/actions/login'
import {
  ticketTypesSelector,
  userIdSelector,
  eventFormSelector,
  flyerSelector
} from 'src/selectors'
import PreviewIcon from 'assets/icons/preview-icon.png'
import ImagesIcon from 'src/assets/icons/icon_images.png'
import HobnobModal from 'src/components/common/HobnobModal'
import SelectFlyerStep from 'src/containers/event/CreateEvent/SelectFlyerStep/SelectFlyerStep'
import InputOptions from './InputOptions/InputOptions'
import LoadingComponent from 'components/common/LoadingComponent/LoadingComponent'
import { deepCopy } from 'services/utils'
import moment from 'moment-timezone'
import uuid from 'uuid'
import cn from 'classnames'
import { message } from 'antd'
import { redirectDomain } from 'services/url_helpers'
import { isMobile } from 'services/browser_detection'

import styles from './input-event-detail.scss'

const onLoadFlyerFonts = () => {
  const fonts = FlyerFonts.fonts

  for (let font of fonts) {
    let myFonts = new FontFace(font.slice(0, -4), `url(../../../assets/Fonts/${font})`) // 这是对的
    myFonts.load().then(loadFace => {
      document.fonts.add(loadFace)
    })
  }
}

const getTicketTypes = ticketTypes => {
  return ticketTypes.map(item => ({
    id: uuid.v4(),
    label: item,
    price: 0
  }))
}

const InputEventDetail = ({ child_count_enabled }) => {
  const dispatch = useDispatch()
  const eventForm = useSelector(eventFormSelector)
  const userId = useSelector(userIdSelector)
  const { flyerList, customFlyerURL } = useSelector(flyerSelector)
  const ticketTypes = useSelector(ticketTypesSelector)

  const [selectedFlyerNumber, setSelectedFlyerNumber] = useState(null)
  const [isFlyerModalVisible, setIsFlyerModalVisible] = useState(
    eventForm.flyer?.state === 'pending' || eventForm.state === 'activation_pending_flier'
  )

  const [isZoomFlyer, setIsZoomFlyer] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const flyerRef = useRef(null)
  const isEdit = Boolean(eventForm.id)

  useEffect(() => {
    dispatch(fetchFlyerDesigns())
    onLoadFlyerFonts()
    setSelectedFlyerNumber(eventForm.flyer_design_number)

    return () => {
      dispatch(resetEventForm())
    }
  }, [])

  const handleChangeFlyer = useCallback(() => {
    setIsFlyerModalVisible(false)
    setSelectedFlyerNumber(eventForm.flyer_design_number)
  }, [eventForm.flyer_design_number])

  const handleOpenFlyerModal = useCallback(e => {
    e.stopPropagation()
    setIsFlyerModalVisible(true)
  }, [])

  const verifyTicketTypeChanged = () => {
    const isChanged = child_count_enabled !== eventForm.child_count_enabled
    if (!isEdit || !isChanged) {
      return
    }

    if (eventForm.child_count_enabled) {
      dispatch(createTicketType(eventForm.id))
    } else {
      const child = ticketTypes.find(item => item.label === 'child' && !item.archived_at)
      if (child) {
        dispatch(archiveTicketType(child.id))
      }
    }
  }

  const handleSubmit = (params = {}) => {
    if (userId) {
      window.mixpanel.track('Save CHANGES Clicked', {
        'flyer#': selectedFlyerNumber,
        user_id: userId
      })
      handleCreateOrEditEvent(params)
      return
    }

    const loginParams = {
      loginModalSubtitle: 'In order for you to create an Event, we need to confirm your number'
    }
    dispatch(showLogin(loginParams))
  }

  const handleCreateOrEditEvent = ({ apple_place_id }) => {
    if (!selectedFlyerNumber) {
      message.error('Please select flyer.')
      setIsFlyerModalVisible(true)
      return
    }

    setIsLoading(true)
    const eventParams = deepCopy(eventForm)
    delete eventParams.checkedFlyer
    delete eventParams.invitations

    if (eventParams.starts_at) {
      eventParams.starts_at = moment.tz(eventParams.starts_at, eventParams.timezone).format()
      if (eventParams.ends_at) {
        eventParams.ends_at = moment.tz(eventParams.ends_at, eventParams.timezone).format()
      }
    }

    if (apple_place_id) {
      eventParams.apple_place_id = apple_place_id
    }

    if (!isEdit) {
      eventParams.ticket_types_attributes = getTicketTypes(
        eventForm.child_count_enabled ? ['adult', 'child'] : ['adult']
      )
    }
    dispatch(
      fetchCreateEvent({
        eventId: eventParams.id,
        eventForm: eventParams,
        onSuccessCallback: data => {
          const {
            event: { id, url }
          } = data

          verifyTicketTypeChanged()
          handleUploadFlyer(id, url)
        },
        onErrorCallback: () => {
          setIsLoading(false)
          message.error('Creation failed.')
        }
      })
    )
  }

  const handleUploadFlyer = (eventId, eventUrl) => {
    dispatch(
      fetchUploadFlyer({
        eventId,
        design_number: selectedFlyerNumber,
        customBgDataURL: customFlyerURL,
        onSuccessCallback: async data => {
          const requests = []

          if (data?.image_flyer?.image_upload?.presigned_url) {
            const flyerBase64 = flyerRef.current?.onCanvasToImage()
            const flyerImage = await fetch(flyerBase64)
            const flyerImageBlob = await flyerImage.blob()
            requests.push(
              fetch(data.image_flyer.image_upload.presigned_url, {
                headers: {
                  'Content-Type': 'image/png'
                },
                method: 'PUT',
                body: flyerImageBlob
              })
            )
          }

          if (data?.image_flyer?.image_bg_upload?.presigned_url) {
            const flyerBgImage = await fetch(customFlyerURL)
            const flyerBgImageBlob = await flyerBgImage.blob()
            requests.push(
              fetch(data.image_flyer.image_bg_upload.presigned_url, {
                headers: {
                  'Content-Type': 'image/png'
                },
                method: 'PUT',
                body: flyerBgImageBlob
              })
            )
          }

          if (requests.length === 0) {
            setIsLoading(false)
            alert('Upload Error.')
            return
          }

          Promise.all(requests).then(responses => {
            if (responses.every(response => response.status === 200)) {
              setTimeout(() => {
                // if (type === 'save') {
                //   location.href = window.location.origin + '/e/list'
                // } else {
                redirectDomain(eventUrl + '/')
                dispatch(resetEventForm())
                // }
              }, 5000)
            } else {
              setIsLoading(false)
              alert('Upload Error.')
            }
          })
        }
      })
    )
  }

  const renderSelectedFlyer = () => {
    const customFlyerConfig = flyerDesignLayoutConfig.find(item => item.slug === 'custom')
    const isCustomFlyer = customFlyerConfig?.designIds.includes(Number(selectedFlyerNumber))
    const customFlyerDetail = { design_number: '8', design_slug: 'hobnob:custom' }

    const selectedFlyerDetail = isCustomFlyer
      ? customFlyerDetail
      : flyerList.find(flyer => flyer.design_number === selectedFlyerNumber)

    if (selectedFlyerDetail) {
      return (
        <FlyerCard
          key={selectedFlyerDetail.design_number}
          ref={flyerRef}
          flyerDetail={selectedFlyerDetail}
          isThumbnail
        />
      )
    }
  }

  return (
    <>
      <div className={styles['input-all-options']}>
        <div
          className={cn(styles['flyer-container'], isZoomFlyer && styles['flyer-container-zoom'])}
          onClick={() => {
            if (isMobile()) {
              setIsZoomFlyer(!isZoomFlyer)
            }
          }}
        >
          {renderSelectedFlyer()}

          <img
            onClick={handleOpenFlyerModal}
            src={PreviewIcon}
            width="54"
            className={styles['preview-icon']}
            alt="preview"
          />
        </div>

        <InputOptions onSubmit={handleSubmit} />
      </div>

      <HobnobModal
        show={isFlyerModalVisible}
        onClose={() => setIsFlyerModalVisible(false)}
        containerStyles={{ width: 'auto', maxWidth: 'none' }}
      >
        <div className={styles['hobnob-container']}>
          <header className={styles['header']}>
            <div />
            <h2>Design</h2>
            <div>
              <CustomFlyerPicker>
                <img src={ImagesIcon} width={24} alt={'custom-flyer'} />
              </CustomFlyerPicker>
            </div>
          </header>
          <div className={styles['content']}>
            <SelectFlyerStep onButtonClick={handleChangeFlyer} />
          </div>
        </div>
      </HobnobModal>

      <LoadingComponent loading={isLoading} dark />
    </>
  )
}

InputEventDetail.propTypes = {
  child_count_enabled: PropTypes.bool
}

export default InputEventDetail
