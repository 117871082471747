import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

export default class SelectElement extends React.Component {
  render () {
    const {
      options,
      className,
      errorClassName,
      showError,
      defaultValue,
      disabledOptions,
      ...otherProps
    } = this.props

    const _disabledOptions = (value) => {
      if (disabledOptions) {
        return disabledOptions(value, defaultValue)
      }
      return false
    }
    return (
      <select
        className={classnames(className, { [errorClassName]: showError })}
        {...otherProps}
      >
        {options.map(({ value, text }) =>
          <option value={value} key={value} disabled={_disabledOptions(value)}>{text}</option>
        )}
      </select>
    )
  }
}

SelectElement.propTypes = {
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  showError: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabledOptions: PropTypes.any
}
