import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'

import Button from 'components/common/Button'
import SvgIcon from 'src/components/common/SvgIcon'
import SendGiftIcon from 'src/assets/icons/gift_icon.svg'

import styles from './event-details-buttons.scss'

export default class EventDetailsButtons extends React.Component {
  _multipleButtons = () => this.props.showSendGiftButton && this.props.showMessageHostButton

  _buttonStyles = () => {
    const { showMessageHostButton, showSendGiftButton } = this.props
    const multipleButtons = showSendGiftButton && showMessageHostButton

    return {
      [styles['button']]: true,
      [styles['single-button']]: !multipleButtons,
      [styles['multiple-buttons']]: multipleButtons
    }
  }

  _renderMessageHostButton () {
    const { onMessageHostClick } = this.props

    return (
      <Button
        className={ClassNames(styles['message-host-button'], this._buttonStyles())}
        filled={false}
        onClick={onMessageHostClick}>
        <div className={styles['message-host-vert-spacer']} />
        Message Host
      </Button>
    )
  }

  _renderSendGiftButton () {
    const { sendGiftUrl } = this.props
    const className = ClassNames(styles['send-gift-button'], this._buttonStyles())

    if (window.screen.width >= 375) {
      return (
        <Button className={className} filled={false} wrapperElement='a' href={sendGiftUrl}>
          <SvgIcon className={styles['send-gift-icon']} divClassName={styles['send-gift-icon-container']} icon={SendGiftIcon} /> Send A Gift
        </Button>
      )
    } else {
      return (
        <Button className={className} filled={false} wrapperElement='a' href={sendGiftUrl}>
          Send Gift
        </Button>
      )
    }
  }

  render () {
    const { showMessageHostButton, showSendGiftButton } = this.props

    return (
      <div className={styles['container']}>
        {showMessageHostButton
          ? this._renderMessageHostButton()
          : <div className={styles['bottom-spacer']} />}

        {showSendGiftButton
          ? this._renderSendGiftButton()
          : null}
      </div>
    )
  }
}

EventDetailsButtons.propTypes = {
  onMessageHostClick: PropTypes.func.isRequired,
  showMessageHostButton: PropTypes.bool.isRequired,
  showSendGiftButton: PropTypes.bool.isRequired,
  sendGiftUrl: PropTypes.string
}
