import { fetchApi } from './api'

export const FETCH_ACCESS_TOKEN = 'FETCH_ACCESS_TOKEN'
export const FETCH_ACCESS_TOKEN_SUCCESS = 'FETCH_ACCESS_TOKEN_SUCCESS'
export const FETCH_ACCESS_TOKEN_ERROR = 'FETCH_ACCESS_TOKEN_ERROR'

export const GET_INVITATION_CREDENTIALS = 'GET_INVITATION_CREDENTIALS'
export const GET_INVITATION_CREDENTIALS_SUCCESS = 'GET_INVITATION_CREDENTIALS_SUCCESS'
export const GET_INVITATION_CREDENTIALS_ERROR = 'GET_INVITATION_CREDENTIALS_ERROR'

export const GET_CLIENT_CREDENTIALS = 'GET_CLIENT_CREDENTIALS'
export const GET_CLIENT_CREDENTIALS_SUCCESS = 'GET_CLIENT_CREDENTIALS_SUCCESS'
export const GET_CLIENT_CREDENTIALS_ERROR = 'GET_CLIENT_CREDENTIALS_ERROR'

export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS'
export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR'

// Fetch pre-existing access tokens from the API server
export function fetchAccessToken () {
  return fetchApi({
    requestType: FETCH_ACCESS_TOKEN,
    successType: FETCH_ACCESS_TOKEN_SUCCESS,
    errorType: FETCH_ACCESS_TOKEN_ERROR,
    skipGetOrRefreshAccessToken: true,
    route: '/api/v2/users/tokens',
    options: {
      baseUrl: process.env.API_APP_URL,
      headers: {
        Accept: 'application/json'
      },
      method: 'GET',
      credentials: 'include'
    }
  })
}

export function getInvitationCredentials (userId, eventToken, invitationToken) {
  return fetchApi({
    requestType: GET_INVITATION_CREDENTIALS,
    successType: GET_INVITATION_CREDENTIALS_SUCCESS,
    errorType: GET_INVITATION_CREDENTIALS_ERROR,
    skipGetOrRefreshAccessToken: true,
    route: '/oauth/token',
    options: {
      method: 'POST',
      body: JSON.stringify({
        grant_type: 'password',
        username: userId,
        password: `${eventToken}:${invitationToken}`,
        authentication_method: 'Invitation',
        scope: 'invitation_credential'
      })
    }
  })
}

export function getClientCredentials () {
  return fetchApi({
    requestType: GET_CLIENT_CREDENTIALS,
    successType: GET_CLIENT_CREDENTIALS_SUCCESS,
    errorType: GET_CLIENT_CREDENTIALS_ERROR,
    skipGetOrRefreshAccessToken: true,
    route: '/oauth/token',
    options: {
      method: 'POST',
      body: JSON.stringify({
        client_id: process.env.CLIENT_ID,
        client_secret: process.env.CLIENT_SECRET,
        grant_type: 'client_credentials'
      })
    }
  })
}

export const PUT_CLIENT_CREDENTIALS = 'PUT_CLIENT_CREDENTIALS'
export function putClientCredentials (clientCredentials) {
  return {
    type: PUT_CLIENT_CREDENTIALS,
    accessToken: clientCredentials.accessToken,
    expiresAt: clientCredentials.expiresAt
  }
}

export function refreshToken (refresh_token) {
  return fetchApi({
    requestType: REFRESH_TOKEN,
    successType: REFRESH_TOKEN_SUCCESS,
    errorType: REFRESH_TOKEN_ERROR,
    skipGetOrRefreshAccessToken: true,
    route: '/oauth/token',
    options: {
      method: 'POST',
      body: JSON.stringify({
        refresh_token,
        grant_type: 'refresh_token'
      })
    }
  })
}
