export const CHANGE_VISIBLE = 'CHANGE_VISIBLE'
export const SET_CONTENT = 'SET_CONTENT'
export const CHANGE_LOADING = 'CHANGE_LOADING'
export const SET_NEEDED_CREDITS = 'SET_NEEDED_CREDITS'

export const changeVisible = visible => ({
  type: CHANGE_VISIBLE,
  visible
})

export const changeLoading = loading => ({
  type: CHANGE_LOADING,
  loading
})

export const setContent = content => ({
  type: SET_CONTENT,
  content
})

export const setNeededCredits = count => ({
  type: SET_NEEDED_CREDITS,
  count
})
