import { fork, put, select, take, takeEvery } from 'redux-saga/effects'

import {
  eventIdSelector,
  eventTicketCountsSelector,
  destinationTypeSelector,
  eventTokenSelector,
  hostSelector,
  invitationTokenSelector,
  requestedRsvpSelector,
  userSelector,
  isHostSelector
} from 'src/selectors'

import { loadUserComplete } from './bootstrap'
import {
  fetchEventBranchLinks,
  setInvitationsAcceptedCount,
  setInvitationsCount,
  eventSubscribe
} from '../actions'
import {
  acceptInvitation,
  declineInvitation,
  setInvitationLoadingStatus,
  setInvitationToken
} from '../actions/invitations'

import { fetchEvent, FETCH_EVENT_SUCCESS } from 'src/actions/event'

import { updateEventUrl, parseEventUrl } from 'services/url_helpers'
import { sumCount } from '../services/utils'
import { DESTINATION_TYPES_CONSTANTS } from '../constants'

// Add a delay so the saga is not run within the same microtask
// Reference: https://github.com/yelouafi/redux-saga/issues/277#issuecomment-228222584
export function * ourTakeEvery (actionType, saga) {
  const worker = function * (...args) {
    yield Promise.resolve()
    yield fork(saga, ...args)
  }
  yield takeEvery(actionType, worker)
}

// I was getting sick of creating watchers manually
export function createWatcher (...args) {
  return function * () {
    yield takeEvery(...args)
  }
}

export function * multiPut (actions) {
  for (let action of actions) {
    yield put(action)
  }
}

export function * isGuestSaga () {
  const host = yield select(hostSelector)
  let user = yield select(userSelector)
  if (!user) {
    // Wait for the user to be completely loaded
    yield loadUserComplete.promise
    user = yield select(userSelector)
  }
  if (host) {
    const isHost = user && (user.id === host.id)
    return !isHost
  } else {
    // Mainly on server-side
    // FIXME: Should we add a delay here?
    return true
  }
}

// Updates the invitation counts based on tickets
export function * dealWithInvitationsCount () {
  const event = yield select(eventTicketCountsSelector)
  const isHost = yield select(isHostSelector)

  if (!event.guest_list_enabled && !isHost) {
    return
  }

  let invitationsCount = 0
  let invitationsAcceptedCount = 0
  if (event && event.ticketing_enabled_at) {
    const invitations = event.invitations
    const activeInvitations = invitations.filter((item) => !item.destroyed_at)
    // invitationsCount = activeInvitations.length
    invitationsCount = activeInvitations.filter(item => item.delivery_state !== 'pending').length
    const acceptedInvitation = activeInvitations.filter((item) => {
      return item.rsvp_state === 'accepted'
    })

    let acceptedInvitationCounts = 0
    for (let i = 0; i < acceptedInvitation.length; i++) {
      acceptedInvitationCounts += sumCount(acceptedInvitation[i].ticket_counts, 'ticket_count')
    }
    invitationsAcceptedCount = acceptedInvitationCounts
  } else {
    invitationsCount = event['invitations_count']
    invitationsAcceptedCount = event['invitations_accepted_count']
  }
  yield put(setInvitationsCount(invitationsCount))
  yield put(setInvitationsAcceptedCount(invitationsAcceptedCount))
}

export function * dealWithShowSubscribeModal (userId) {
  const destinationType = yield select(destinationTypeSelector)
  const path = window.location.pathname
  let { eventToken } = parseEventUrl(path)
  if (
      (destinationType === DESTINATION_TYPES_CONSTANTS.event ||
      destinationType === DESTINATION_TYPES_CONSTANTS.announcement) &&
      eventToken !== 'create' &&
      eventToken !== 'createV2' &&
      eventToken !== 'list' &&
      eventToken !== 'edit'
  ) {
    const eventId = yield select(eventIdSelector)
    yield put(fetchEvent(eventId))
    const action = yield take(FETCH_EVENT_SUCCESS)
    const invitations = parseInvitations(action)

    let currentInvitation = getUserInvitation(invitations, userId)
    let invitationId = currentInvitation ? currentInvitation.id : ''

    if (currentInvitation && !currentInvitation.destroyed_at) {
      const destinationType = yield select(destinationTypeSelector)
      const eventToken = yield select(eventTokenSelector)
      const invitationToken = yield select(invitationTokenSelector)
      if (currentInvitation.token && (currentInvitation.token !== invitationToken)) {
        yield put(setInvitationToken(currentInvitation.token))
        // If you are logged in, the URL will be changed to an invitation token.
        updateEventUrl(destinationType, eventToken, currentInvitation.token)
        yield put(fetchEventBranchLinks(eventToken, invitationToken))
      }
    }
    if (currentInvitation && currentInvitation.destroyed_at) {
      yield put(setInvitationLoadingStatus('destroyed'))
    } else {
      yield put(setInvitationLoadingStatus('success'))
    }

    const requestedRsvp = yield select(requestedRsvpSelector)
    const user = yield select(userSelector)

    // yield put(setInvitationId(invitationId))
    yield put(eventSubscribe(eventId, requestedRsvp))

    const eventToken = yield select(eventTokenSelector)
    const invitationToken = yield select(invitationTokenSelector)

    if (invitationId !== '' && requestedRsvp === 'declined' && currentInvitation.rsvp_state !== 'declined') {
      if (user) {
        yield put(declineInvitation(currentInvitation, currentInvitation.id, currentInvitation.event_id))
      } else {
        yield put(declineInvitation(currentInvitation, invitationToken, eventToken))
      }
    }

    if (invitationId !== '' &&
      requestedRsvp === 'accepted' &&
      currentInvitation.rsvp_state !== 'accepted' &&
      currentInvitation.rsvp_state !== 'undecided') {
      if (user) {
        yield put(acceptInvitation(currentInvitation, currentInvitation.id, currentInvitation.event_id))
      } else {
        yield put(acceptInvitation(currentInvitation, invitationToken, eventToken))
      }
    }
  }
}

function getUserInvitation (invitations, userId) {
  const userInvitations = invitations.filter(invitation => {
    return invitation.guest === userId
  })

  const activeInvitations = userInvitations.filter(invitation => {
    return !invitation.destroyed_at
  })

  if (activeInvitations.length > 0) {
    // If there's multiple invitations simply choose the first one
    return activeInvitations[0]
  } else {
    return userInvitations[0]
  }
}

function parseInvitations (action) {
  const entities = action.response.entities
  if (entities.invitations) {
    return Object.values(entities.invitations)
  } else {
    return []
  }
}
