import React, { Component } from 'react'
import SvgIcon from 'src/components/common/SvgIcon'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import { connect } from 'react-redux'
import { userSelector } from 'src/selectors'
import PropTypes from 'prop-types'
import { hostFullName } from 'src/services/event_helpers'
// import EventDateTime from 'src/components/details/EventDateTime'
// import EventVenue from 'src/components/details/EventVenue'

import ShareEmailIcon from 'src/assets/icons/icons8-circle-envelop.svg'
import ShareFacebookIcon from 'src/assets/icons/icons8-facebook-circled.svg'
import MessageIcon from 'assets/icons/message-icon.png'
import DirectionsIcon from 'src/assets/icons/directions_icon.svg'
import CalendarIcon from 'src/assets/icons/calendar_icon.svg'

import styles from './draft-step.scss'

const imgUrl =
  'https://hobnob-staging.imgix.net/image/18cbf244-d8c8-428a-a758-16ae16c36db0/image.jpg?ixlib=rails-3.0.2&fm=pjpg&v=1663924799&w=640&h=1136&mark=https%3A%2F%2Fd8kze32hv32fb.cloudfront.net%2Fassets%2Fwatermark%402x-7d1c897539db6122c811c60c109544ef592c6c2b7c8d6b814dcb1744eaff807e.png&markscale=22&markpad=10&markalign=bottom%2Cright&s=0056cac329194741c912a7ac975ac579'

class DraftStep extends Component {
  onCalendarClick = () => {}
  _renderEventCalendarAndMap() {
    const { event, onMapClick } = this.props

    return (
      <div className={styles['event-calendar-and-map']}>
        {event.ics_url ? (
          <div className={styles['icon-wrapper']} onClick={this.onCalendarClick}>
            <SvgIcon icon={CalendarIcon} className={styles['map-icon']} />
            <a href="#!">Add to Calendar</a>
          </div>
        ) : null}
        {event.venue ? (
          <div className={styles['icon-wrapper']} onClick={onMapClick}>
            <SvgIcon icon={DirectionsIcon} className={styles['map-icon']} />
            <a href="#!">Get Directions</a>
          </div>
        ) : null}
      </div>
    )
  }

  render() {
    const { user } = this.props

    return (
      <div className={styles['draft-step-container']}>
        <div className={styles['draft-step-left']}>
          <img src={imgUrl} />
        </div>
        <div className={styles['draft-step-right']}>
          <div className={styles['event-details-wrapper']}>
            <div className={styles['event-details']}>
              <h1 className={styles['event-name']}>Beer Tasting</h1>
              <div className={styles['event-share']}>
                <div className={styles['icon-container']}>
                  <SvgIcon icon={ShareFacebookIcon} className={styles['icon']} />
                  <p className={styles['icon-label']}>Facebook</p>
                </div>
                <div className={styles['icon-container']}>
                  <SvgIcon icon={ShareEmailIcon} className={styles['icon']} />
                  <p className={styles['icon-label']}>Email</p>
                </div>
              </div>
            </div>

            {user && (
              <div className={styles['event-host']}>
                <UserAvatarContainer user={user} size={34} />
                <h3 className={styles['host-name']}>
                  Host:&nbsp;
                  {hostFullName(null, user)}
                </h3>
              </div>
            )}

            <div className={styles['event-message-host']}>
              <img src={MessageIcon} width="24" />
              <span>Message Host</span>
            </div>

            <div className={styles['date-and-venue']}>
              {/* <div className={styles['event-date']}>
                <EventDateTime
                  endsAt={'event.ends_at'}
                  startsAt={'event.starts_at'}
                  timezone={'event.timezone'}
                />
              </div>
              <EventVenue customVenueName={'event.custom_venue_name'} venue={tempObj} /> */}
            </div>

            {this._renderEventCalendarAndMap()}
          </div>
        </div>
      </div>
    )
  }
}

DraftStep.defaultProps = {
  event: {
    ics_url: true,
    venue: true
  },
  onMapClick: () => {}
}

DraftStep.propTypes = {
  user: PropTypes.object,
  event: PropTypes.object,
  onMapClick: PropTypes.func
}

function mapStateToProps(state) {
  return {
    user: userSelector(state)
  }
}

export default connect(mapStateToProps, null)(DraftStep)
