import React from 'react'
import HobnobModal from '../../../components/common/HobnobModal'
import PropTypes from 'prop-types'
import { timeZones } from '../constants'

import styles from './pickerZoneTime.scss'
class PickerZoneTime extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      openModal: false
    }
  }
  handleClick = (zone) => {
    const { setTimeZone } = this.props
    setTimeZone(zone)
    this.setState({ openModal: false })
  }

  render () {
    const { timeZone } = this.props
    return (
      <div>
        <span className={styles['picker-zone']}
          onClick={() => this.setState({ openModal: true })}
        >
          {timeZone}
        </span>
        <HobnobModal
          show={this.state.openModal}
          onClose={() => this.setState({ openModal: false })}
          showCancelIcon={false}
        >
          <div className={styles['picker-zone__container']}>
            <div className={styles['picker-zone__title']}>
              <h3>Current Selected: </h3>
              <span>{timeZone}</span>
            </div>
            <div className={styles['picker-zone__content']}>
              {timeZones.map((zone, index) => {
                return (
                  <div
                    key={'timezone-' + index}
                    onClick={() => this.handleClick(zone)}
                    className={styles['picker-zone__options']}
                  >
                    {zone}
                  </div>
                )
              }
              )}
            </div>
          </div>
        </HobnobModal>
      </div>
    )
  }
}

PickerZoneTime.propTypes = {
  setTimeZone: PropTypes.func,
  timeZone: PropTypes.string
}

export default PickerZoneTime
