import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { isMobile } from 'services/browser_detection'
import { userSelector, globalLayerSelector, eventSelector } from 'src/selectors'
import Button from 'src/components/common/Button'
import UnSendInvitesBg from 'assets/icons/unSend-invites-bg.png'
import CommonFrame from '../CommonFrame/CommonFrame'
import { PlanType, getPlanBySlug, getLegacyPlans } from 'src/services/stripe_checkout_configs'
import { changePlanModalVisible, setSpecifyPlan } from 'src/actions/plan'
import { changeVisible, setContent } from 'src/actions/globalLayer'
import { GLOBAL_LAYER_CONTENT } from 'src/constants'
import { verifySubscriptionPlatform } from 'src/services/frontend_tips'
import ManuallySend from './ManuallySend/ManuallySend'
import { message } from 'antd'

import styles from './unsent-invites.scss'

const UnsentInvites = () => {
  const dispatch = useDispatch()
  const [isQrCodeVisible, setIsQrCodeVisible] = useState(false)
  const { neededCredits } = useSelector(globalLayerSelector)
  const event = useSelector(eventSelector)
  const { is_trial_available, current_subscription } = useSelector(userSelector)
  const {
    plan: { slug: userSlug },
    subscription_type
  } = current_subscription
  const isLegacyPlan = getLegacyPlans().some(p => p.slug === userSlug)

  const planType = getPlanBySlug(userSlug, 'planType')

  const isFree = planType === PlanType.Free
  const isInvitationMakerPlan = planType === PlanType.InvitationMaker
  const isPartyHost = planType === PlanType.PartyHost
  const isBasic = planType === PlanType.Basic
  const isPremium = planType === PlanType.Premium
  const isEssentials = planType === PlanType.Essentials
  const isGroupOrganizer = planType === PlanType.GroupOrganizer

  let isUpgradePlan = false
  let upgradeToPlan = 'PartyHost'
  let isBuyCredits = false

  const lowLevelPlan = isFree || isLegacyPlan || isBasic || isEssentials || isInvitationMakerPlan

  if (neededCredits <= 50 && lowLevelPlan) {
    isUpgradePlan = true
    upgradeToPlan = 'PartyHost'
  } else if (neededCredits <= 150 && (lowLevelPlan || isPartyHost || isPremium)) {
    isUpgradePlan = true
    upgradeToPlan = 'EventPlanner'
  } else if ((neededCredits <= 350 && !isGroupOrganizer) || (isFree && neededCredits > 350)) {
    isUpgradePlan = true
    upgradeToPlan = 'GroupOrganizer'
  } else {
    isBuyCredits = true
  }

  const handleClickButton = () => {
    if (is_trial_available) {
      dispatch(changeVisible(false))
      dispatch(changePlanModalVisible(true))
      dispatch(setSpecifyPlan('PartyHost'))
      return
    }

    if (isBuyCredits) {
      if (isMobile()) {
        dispatch(routerActions.push('/purchase-credit'))
        dispatch(changeVisible(false))
      } else {
        dispatch(setContent(GLOBAL_LAYER_CONTENT.purchaseCredit))
      }
      return
    }

    if (isUpgradePlan && verifySubscriptionPlatform(subscription_type)) {
      dispatch(changeVisible(false))
      dispatch(changePlanModalVisible(true))
      dispatch(setSpecifyPlan(upgradeToPlan))
    }
  }

  const handleClickManually = () => {
    if (isMobile()) {
      navigator.clipboard.writeText(event.url.substring(8))
      message.success('Copied! 🎉')
      dispatch(changeVisible(false))
    } else {
      setIsQrCodeVisible(true)
    }
  }

  const actionLayer = () => {
    let description = null
    let buttonText = ''

    if (is_trial_available) {
      buttonText = 'REDEEM YOUR FREE WEEK'
      description = (
        <div>
          <p>
            Looks like you need {neededCredits} more credits to finish sending your invites with our
            Smart Invite feature.
          </p>

          <p>
            Redeem your <b>FREE WEEK</b> of Hobnob’s Subscriptions to use our world-class automation
            for less than 50c per guest.
          </p>
        </div>
      )
    } else if (isUpgradePlan) {
      buttonText = 'GET CREDITS WITH NEW PLAN'
      description = (
        <div>
          <p>
            Want to send the rest with one tap? You need more credits to finish sending your invites
            with our Automated Invites feature.
          </p>

          <p>Get delivery, view, and RSVP status tracking and automated reminders.</p>
        </div>
      )
    } else {
      buttonText = 'GET MORE SMART INVITES'
      description = (
        <div>
          <p>
            Looks like you need {neededCredits} more credits to finish sending your invites with our
            Smart Invite feature.
          </p>
          <p>
            Since you’ve already used the credits included in your plan, you can either purchase
            more or continue to send for free from your own phone number by tapping on that guest in
            your guest list.
          </p>
        </div>
      )
    }

    return (
      <div className={styles['action-layer']}>
        <h1>Unsent Invites</h1>
        {description}

        <Button className={styles['upgrade-button']} onClick={handleClickButton}>
          {buttonText}
        </Button>

        <span className={styles['close-button']} onClick={handleClickManually}>
          Send Invites Manually
        </span>
      </div>
    )
  }

  const descLayer = (
    <div className={styles['desc-layer']}>
      <img src={UnSendInvitesBg} className={styles['img-inner']} alt="" />
      <div className={styles['right-triangle']} />
    </div>
  )

  if (isQrCodeVisible) {
    return <ManuallySend onSetIsQrCodeVisible={setIsQrCodeVisible} />
  }

  return <CommonFrame actionLayer={actionLayer()} descLayer={descLayer} />
}

export default UnsentInvites
