import PropTypes from 'prop-types'
import React from 'react'
import Dropzone from 'react-dropzone'
import classnames from 'classnames'

import styles from './photo-upload.scss'

export default class PhotoUpload extends React.Component {
  _onDrop = (files) => {
    files.forEach((file) => {
      this.props.onCreateMedium(file)
    })
  };

  render () {
    const { onRequireLogin, requiresLogin, children, style } = this.props

    return (
      <div className={styles['container']}>
        <Dropzone
          className={styles['add-photos-button']}
          multiple={false}
          onDrop={this._onDrop}
          style={style}
          accept='image/jpeg, image/png'
        >
          {children}
          <span className={classnames(styles['click-overlay'], 'needsclick')} />
        </Dropzone>
        {requiresLogin
          ? <div className={styles['click-overlay']} onClick={onRequireLogin} />
          : null}
      </div>
    )
  }
}

PhotoUpload.propTypes = {
  requiresLogin: PropTypes.bool,
  onCreateMedium: PropTypes.func.isRequired,
  onRequireLogin: PropTypes.func.isRequired,
  style: PropTypes.object,
  children: PropTypes.any
}
