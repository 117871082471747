import React, { Component } from 'react'
import styles from './upload-modal.scss'
import UploadMain from 'assets/icons/upload-main.png'
import CSVFileUpload from '../../../../Components/CSVFileUpload/CSVFileUpload'
import { connect } from 'react-redux'
import { changeAddGuestsList } from 'src/actions/event'
import PropTypes from 'prop-types'

class UploadModal extends Component {
  state = {
    isOpenCSVUploadModal: false
  }

  handleGetGuestsFromCSV = guests => {
    const { onChangeAddGuestsList, addGuestsList } = this.props
    onChangeAddGuestsList([...guests, ...addGuestsList])
  }

  render() {
    return (
      <div className={styles['upload-modal-container']}>
        <div
          className={styles['open-button-inner']}
          onClick={() => this.setState({ isOpenCSVUploadModal: true })}
        >
          <img src={UploadMain} alt="" width="20" />
          <span>upload file</span>
        </div>

        <CSVFileUpload
          visible={this.state.isOpenCSVUploadModal}
          onGetGuestsFromCSV={this.handleGetGuestsFromCSV}
          onClose={() => this.setState({ isOpenCSVUploadModal: false })}
        />
      </div>
    )
  }
}

UploadModal.propTypes = {
  addGuestsList: PropTypes.array,
  onChangeAddGuestsList: PropTypes.func
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeAddGuestsList: params => dispatch(changeAddGuestsList(params))
  }
}

function mapStateToProps(state) {
  return {
    addGuestsList: state.frontend.addGuestsList
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadModal)
