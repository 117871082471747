import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { pushChannel } from 'services/websocket'
import { chatSelector, userIdSelector } from 'src/selectors'
import { ROOM_CHANNEL_TYPES_CONSTANTS } from 'src/constants'
import {
  uploadGalleries,
  saveGalleries,
  addMessage,
  updateMessage,
  setImageUploading
} from 'src/actions/eventChat'
import uuid from 'uuid/v4'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { isMobile } from 'services/browser_detection'
import InputGifMessage from './InputGifMessage/InputGifMessage'
import MentionBox from './MentionBox/MentionBox'
import PreviewMedia from './PreviewMedia/PreviewMedia'
import InputInnerDesktop from './InputInnerDesktop/InputInnerDesktop'
import InputInnerMobile from './InputInnerMobile/InputInnerMobile'
import { requestUploadFile } from 'src/actions/files'
import { heicToImage } from 'src/services/utils'
import InputMessageContext from './InputMessageContext'

import styles from './input-message.scss'

const InputMessage = props => {
  const { inReply, message = null } = props
  const dispatch = useDispatch()
  const { socketInstance, channelName } = useSelector(chatSelector)
  const userId = useSelector(userIdSelector)

  const [textMessage, setTextMessage] = useState('')
  const [mediaMessage, setMediaMessage] = useState([])
  const [tagList, setTagList] = useState({})
  const [isInputGifVisible, setIsInputGifVisible] = useState(false)
  const [isInputTagVisible, setIsInputTagVisible] = useState(false)
  const inputRef = useRef(null)

  const handleUploadGallery = payload => {
    const needUploads = mediaMessage.filter(m => m.type === 'gallery')

    const uploadedStatus = needUploads
      .map(m => m.files.map(item => ({ id: item.id, status: 'not_created' })))
      .flat(Infinity)

    for (const item of needUploads) {
      dispatch(
        uploadGalleries({
          galleryId: item.galleryId,
          galleries: item.files.map(item => ({
            id: item.id,
            mime_type: item.mime_type
          })),
          onSuccessCallback: async data => {
            const { gallery_image_uploads } = data.gallery

            for (let i = 0; i < gallery_image_uploads.length; i++) {
              const uploadUrl = gallery_image_uploads[i].image_upload.upload_url
              await requestUploadFile(item.files[i].url, uploadUrl)

              // Check if all galleries were created successfully.
              const uploadedStatusIndex = uploadedStatus.findIndex(m => m.id === item.files[i].id)
              uploadedStatus.splice(uploadedStatusIndex, 1)

              if (uploadedStatus.length === 0) {
                setTimeout(() => {
                  sendMessage(payload)
                }, 2000)
              }
            }
          }
        })
      )
    }
  }

  const handleSendMessage = debounce(() => {
    const payload = sendLocalMessage()

    if (mediaMessage.some(m => m.type === 'gallery')) {
      handleUploadGallery(payload)
    } else {
      sendMessage(payload)
    }
  }, 200)

  const sendLocalMessage = () => {
    // format tag user.
    const message_parts = []

    if (textMessage) {
      message_parts.push({
        id: uuid(),
        type: 'text',
        body: textMessage.replace(
          /@(Everyone|Undecided|Attending|Declined|(\w+\s+\w+))/g,
          match => {
            const tag = tagList[match]
            return tag || match
          }
        )
      })
    }

    const medias = mediaMessage.map(media => {
      if (media.type === 'giphy') {
        return {
          id: uuid(),
          type: 'giphy',
          metadata: {
            giphy_id: media.giphy_id,
            url_large: media.url
          }
        }
      } else if (media.type === 'gallery') {
        return {
          id: uuid(),
          type: 'gallery',
          metadata: {
            gallery_id: media.galleryId
          }
        }
      }
    })

    message_parts.push(...medias)

    const payload = {
      id: uuid(),
      type: 'chat',
      thread_id: inReply ? message.id : null,
      message_parts
    }

    const clonePayload = {
      ...payload,
      timestamp: new Date().toISOString(),
      user_id: userId,
      replies: []
    }

    dispatch(addMessage(clonePayload))
    if (inReply) {
      const cloneMessage = { ...message }
      cloneMessage.replies.push({
        id: payload.id,
        user_id: userId
      })
      dispatch(updateMessage(cloneMessage))
    }
    setTextMessage('')
    setTagList({})
    setMediaMessage([])

    /** return message body is sent to the server. */
    console.log(payload)
    return payload
  }

  const sendMessage = payload => {
    if (socketInstance.isConnected()) {
      pushChannel({
        channelName,
        action: ROOM_CHANNEL_TYPES_CONSTANTS.create_new_message,
        payload
      })
    }
  }

  const handleSelectImage = async e => {
    const files = []
    const mediaCount = e.target.files.length
    inputRef.current.focus()
    dispatch(setImageUploading(true))

    for (const file of e.target.files) {
      if (file) {
        let blobFile = file

        if (file.type === 'image/heic') {
          blobFile = await heicToImage(file)
        }

        const reader = new FileReader()
        reader.onload = async () => {
          const media = {
            id: uuid(),
            url: reader.result,
            type: 'image',
            mime_type: 'image/jpeg'
          }
          files.push(media)

          if (mediaCount === files.length) {
            const galleryId = uuid()

            setMediaMessage([
              ...mediaMessage,
              {
                type: 'gallery',
                galleryId,
                files
              }
            ])

            dispatch(
              saveGalleries({
                id: galleryId,
                gallery_image_uploads: files.map(img => ({
                  id: img.id,
                  image_upload: {
                    gallery_id: galleryId,
                    medium_url: img.url
                  }
                }))
              })
            )
            dispatch(setImageUploading(false))
          }
        }
        reader.readAsDataURL(blobFile)
      }
    }
  }

  const handleInputChange = e => {
    const value = e === '@' ? textMessage + '@' : e.target.value
    setTextMessage(value)
    if (value.endsWith('@')) {
      setIsInputTagVisible(true)
    }
  }

  const handleKeyDown = e => {
    switch (e.key) {
      case 'Backspace':
        if (textMessage.endsWith('@')) {
          setIsInputTagVisible(false)
        }
        break
      case ' ':
        if (isInputTagVisible) {
          setIsInputTagVisible(false)
        }
        break
    }
  }

  return (
    <InputMessageContext.Provider
      value={{
        inputRef,
        textMessage,
        mediaMessage,
        isInputGifVisible
      }}
    >
      <div className={styles['input-message-container']}>
        <InputGifMessage
          onSetIsInputGifVisible={setIsInputGifVisible}
          onSetMediaMessage={setMediaMessage}
        />
        {isInputTagVisible && (
          <MentionBox
            key={textMessage}
            tagList={tagList}
            onSetTextMessage={setTextMessage}
            onSetTagList={setTagList}
            onSetIsInputTagVisible={setIsInputTagVisible}
          />
        )}

        {isMobile() ? (
          <InputInnerMobile
            onSetMediaMessage={setMediaMessage}
            onSetIsInputGifVisible={setIsInputGifVisible}
            onSendMessage={handleSendMessage}
            onSelectImage={handleSelectImage}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <>
            {mediaMessage.length > 0 && (
              <PreviewMedia mediaMessage={mediaMessage} onSetMediaMessage={setMediaMessage} />
            )}
            <InputInnerDesktop
              onSetIsInputGifVisible={setIsInputGifVisible}
              onSendMessage={handleSendMessage}
              onSelectImage={handleSelectImage}
              onInputChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
          </>
        )}
      </div>
    </InputMessageContext.Provider>
  )
}

InputMessage.propTypes = {
  inReply: PropTypes.bool,
  message: PropTypes.object
}

export default InputMessage
