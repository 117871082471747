import { combineReducers } from 'redux'

import {
  FETCH_EVENT_BRANCH_LINKS_SUCCESS
} from 'src/actions'

import { FETCH_POLL_BRANCH_LINKS_SUCCESS } from 'src/actions/timepoll'

function createLinkReducer (defaultState, key) {
  return (state = defaultState, action) => {
    if (action.type === FETCH_EVENT_BRANCH_LINKS_SUCCESS) {
      return action.response[key] || state
    } else {
      return state
    }
  }
}

function createPollLinkReducer (defaultState, key) {
  return (state = defaultState, action) => {
    if (action.type === FETCH_POLL_BRANCH_LINKS_SUCCESS) {
      return action.response[key] || state
    } else {
      return state
    }
  }
}

const defaultState = process.env.DEFAULT_BRANCH_LINK
export default combineReducers({
  floatingButtonLink: createLinkReducer(defaultState, 'floating_button'),
  emailHostMessageAttempt: createLinkReducer(defaultState, 'email_host_message_attempt'),
  messageIconLink: createLinkReducer(defaultState, 'message_icon'),
  gatedChatLink: createLinkReducer(defaultState, 'gated_chat'),
  openInAppModalLink: createLinkReducer(defaultState, 'open_in_app_modal'),
  openInAppModalPollLink: createPollLinkReducer(defaultState, 'open_in_app_modal')
})
