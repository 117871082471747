import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { userIdSelector, eventFormSelector, flyerSelector } from 'src/selectors'
import Button from 'src/components/common/Button'
import PropTypes from 'prop-types'
import RightIconWhite from 'src/assets/icons/right-icon-white.png'
import CloseIconGray from 'src/assets/icons/close-icon-gray.png'
import FlyerCard from './FlyerCard/FlyerCard'
import CustomFlyerPicker from './CustomFlyerPicker/CustomFlyerPicker'
import { clearCustomFlyerURL } from 'src/actions/flyer'

import styles from './select-flyer-step.scss'

const SelectFlyerStep = ({ type = 'create', onButtonClick }) => {
  const dispatch = useDispatch()

  const userId = useSelector(userIdSelector)
  const eventForm = useSelector(eventFormSelector)
  const { customFlyerURL, flyerList } = useSelector(flyerSelector)
  const flyerCardRefs = useRef([])

  const [activeFlyerList, setActiveFlyerList] = useState([])

  const handleButtonClick = () => {
    if (type === 'create') {
      const index = activeFlyerList.findIndex(
        item => item.design_number === eventForm.flyer_design_number
      )

      flyerCardRefs.current[index]?.onCanvasToImage()
      window.mixpanel.track('SAVE DESIGN Clicked', { user_id: userId })
    }
    onButtonClick()
  }

  useEffect(() => {
    const filterFlyerList = flyerList.filter(item => item.image_bg_slug)

    if (customFlyerURL) {
      filterFlyerList.unshift({ design_number: '8', design_slug: 'hobnob:custom' })
    }
    console.log('filterFlyerList:', JSON.parse(JSON.stringify(filterFlyerList)))
    setActiveFlyerList(filterFlyerList)
  }, [flyerList, customFlyerURL])

  return (
    <div className={styles['selected-flyer-step-container']}>
      <div className={styles['image-view']}>
        <div className={styles['image-wrapper']}>
          <div className={styles['flyer-wrapper']}>
            <CustomFlyerPicker />
          </div>

          {activeFlyerList.map((item, index) => {
            return (
              <FlyerCard
                flyerDetail={item}
                key={item.design_number}
                ref={ref => (flyerCardRefs.current[index] = ref)}
              />
            )
          })}
        </div>
      </div>
      <div className={styles['button-wrapper']}>
        {customFlyerURL && (
          <Button
            className={styles['remove-image-button']}
            purple
            onClick={() => dispatch(clearCustomFlyerURL())}
          >
            <img src={CloseIconGray} alt="" width="16" />
            <span>Remove Image</span>
          </Button>
        )}
        <Button
          className={styles['button-inner']}
          onClick={handleButtonClick}
          disabled={!eventForm.flyer_design_number}
        >
          <span>{type === 'create' ? 'SAVE DESIGN' : 'change'}</span>
          <img src={RightIconWhite} alt="" width="24" />
        </Button>
      </div>
    </div>
  )
}

SelectFlyerStep.propTypes = {
  type: PropTypes.string,
  onButtonClick: PropTypes.func
}

export default SelectFlyerStep
