import PropTypes from 'prop-types'
import React from 'react'

import HobnobModal from 'src/components/common/HobnobModal'

import styles from './tooltip-modal.scss'

export default class TooltipModal extends React.Component {
  _displayAboveIcon() {
    return false
  }

  _containerPosition() {
    const { clickCoordinates, direction } = this.props
    const arrowPadding = 15
    const windowHeight = window.innerHeight
    if (this._displayAboveIcon()) {
      return {
        top: 'auto',
        transform: 'translateX(-50%)',
        bottom: `${windowHeight - clickCoordinates.clientY + arrowPadding}px`
      }
    } else {
      if (direction === 'left') {
        return {
          top: `${clickCoordinates.clientY + arrowPadding + 15}px`,
          left: `${clickCoordinates.clientX - 15}px`
        }
      } else if (direction === 'right') {
        return {
          top: `${clickCoordinates.clientY + arrowPadding + 15}px`,
          left: `${clickCoordinates.clientX - 230}px`
        }
      }
    }
  }

  render() {
    const { arrowOffset, children, direction, onClose, place, visible } = this.props

    const containerStyles = {
      position: 'absolute',
      width: 'max-content',
      maxWidth: '100%',
      margin: '0 auto',
      transform: 'none',
      // left: '0',
      // right: '0',
      background: 'transparent',
      padding: '0',
      ...this._containerPosition()
    }

    let arrowStyle = {}
    if (direction === 'left') {
      arrowStyle.left = `${arrowOffset}px`
    } else {
      arrowStyle.right = `${arrowOffset}px`
    }

    return (
      <HobnobModal
        containerClassName={styles['container']}
        containerStyles={containerStyles}
        show={visible}
        onClose={onClose}
        showCancelIcon={false}
      >
        <div className={styles['content']} onClick={this._ignoreClick}>
          {children}
        </div>
        <div
          className={styles[place === 'above' ? 'arrow-above' : 'arrow-below']}
          style={arrowStyle}
        />
      </HobnobModal>
    )
  }
}

TooltipModal.propTypes = {
  arrowOffset: PropTypes.number,
  children: PropTypes.any,
  clickCoordinates: PropTypes.object,
  direction: PropTypes.oneOf(['left', 'right']),
  onClose: PropTypes.func.isRequired,
  place: PropTypes.oneOf(['above', 'below']),
  visible: PropTypes.bool
}
TooltipModal.defaultProps = {
  arrowOffset: 16,
  direction: 'left',
  place: 'below'
}
