import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import AddIconTheme from 'assets/icons/add-icon-theme.png'
import SettingIcon from 'assets/icons/setting-icon.png'
import Button from 'src/components/common/Button'
import Switch from 'react-switch'
import Options from './Options/Options'
import { connect } from 'react-redux'
import { showLogin } from 'src/actions/login'
import { showLoadingIcon, hideLoadingIcon } from 'src/actions/frontend'
import { uploadFiles, requestUploadFile } from 'src/actions/files'
import { list_update, resetSignUpForm } from 'src/actions/timepoll'
import { message, Modal } from 'antd'
import { routerActions } from 'react-router-redux'
import { parseEventUrl } from 'src/services/url_helpers'
import styles from './input-options.scss'

const InputOptions = props => {
  const {
    SULToken,
    onOpenSetting,
    signUpForm,
    userId,
    onShowLogin,
    onShowLoadingIcon,
    onHideLoadingIcon,
    onUploadFiles,
    onSULSubmit,
    goBack,
    goSULDetail,
    onResetSignUpForm
  } = props

  const [isQuantityVisible, setIsQuantityVisible] = useState(true)
  const [list_options_params, setListOptionsParams] = useState(props.list_options_params)
  const [invalidList, setInvalidList] = useState(new Set())

  useEffect(() => {
    setListOptionsParams(props.list_options_params)
  }, [props.list_options_params])

  const handleAddOption = () => {
    list_options_params.push({
      item: '',
      item_rank: list_options_params.length,
      count_limit: 9999,
      description: ''
    })

    setListOptionsParams([...list_options_params])
  }

  const handleSubmit = () => {
    if (!parseEventUrl(window.location.pathname).invitationToken) {
      window.mixpanel.track('SUL Saved', { user_id: userId })
    }

    if (!userId) {
      const params = {
        loginModalSubtitle:
          'In order for you to create a Sign Up List, we need to confirm your number'
      }
      onShowLogin(params)

      return
    }

    const newSet = new Set()
    for (const item of list_options_params) {
      if (item.item) {
        newSet.delete(item.item_rank)
      } else {
        newSet.add(item.item_rank)
      }
    }

    if (newSet.size > 0) {
      setInvalidList(newSet)
      message.warning('Please input all option names')
      return
    }

    const sulForm = {
      id: signUpForm.id,
      email_require: signUpForm.email_require,
      name: signUpForm.name,
      custom_creator_name: signUpForm.custom_creator_name,
      notes: signUpForm.notes,
      ends_at: signUpForm.ends_at,
      event_id: signUpForm.checkedEvent?.id || null,
      poll_type: 'item'
    }

    onShowLoadingIcon()

    if (signUpForm.checkedImage) {
      onUploadFiles({
        mimetype: 'image/jpeg',
        onSuccessCallback: async data => {
          const bodyData = data.data.image_upload_update.result
          await requestUploadFile(signUpForm.checkedImage.url, bodyData.presigned_url)
          sulForm.image_upload_id = bodyData.id

          setTimeout(() => {
            onSubmit(sulForm)
          }, 5000)
        }
      })
    } else {
      onSubmit(sulForm)
    }
  }

  const onSubmit = form => {
    const mapOptions = list_options_params.map(item => ({
      id: item.id,
      count_limit: isQuantityVisible ? item.count_limit : 9999,
      description: item.description,
      item: item.item,
      item_rank: item.item_rank
    }))

    onSULSubmit({
      list_params: form,
      list_options_params: mapOptions,
      onSuccessCallback: data => {
        onHideLoadingIcon()
        if (data.data.list_update.successful) {
          // reset form
          onResetSignUpForm()

          if (SULToken) {
            goBack()
          } else {
            goSULDetail(data.data.list_update.result.token)
            sessionStorage.removeItem('signUpForm')
          }
        }
      },
      onFailCallback: data => {
        onHideLoadingIcon()
        if (!data.data.list_update.successful) {
          Modal.error({
            content: data.data.list_update.messages[0]
          })
        }
      }
    })
  }

  return (
    <div className={styles['input-options-container']}>
      <div className={styles['content-container']}>
        <div className={styles['header']}>
          <div className={styles['label']}>Slot/Item</div>

          <div className={styles['switch-wrapper']}>
            <label>Select Quantity</label>
            <Switch
              onChange={checked => setIsQuantityVisible(checked)}
              checked={isQuantityVisible}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#2cd4ae"
              width={40}
              height={20}
            />
          </div>

          <img
            src={SettingIcon}
            onClick={onOpenSetting}
            className={styles['setting-icon']}
            width="20"
          />
        </div>

        <div className={styles['options-wrapper']}>
          <Options
            invalidList={invalidList}
            list_options_params={list_options_params}
            isQuantityVisible={isQuantityVisible}
            onSetListOptionsParams={setListOptionsParams}
          />
        </div>

        <div className={styles['add-button']} onClick={handleAddOption}>
          <img src={AddIconTheme} width="18" />
          <span>ADD</span>
        </div>
      </div>

      <div className={styles['edit-button']} onClick={handleSubmit}>
        <Button disabled={!signUpForm.name} className={styles['button-inner']}>
          SAVE CHANGE
        </Button>
      </div>
    </div>
  )
}

InputOptions.propTypes = {
  SULToken: PropTypes.string,
  userId: PropTypes.string,
  signUpForm: PropTypes.object,
  list_options_params: PropTypes.array,
  onOpenSetting: PropTypes.func,
  onShowLogin: PropTypes.func,
  onShowLoadingIcon: PropTypes.func,
  onHideLoadingIcon: PropTypes.func,
  onUploadFiles: PropTypes.func,
  onSULSubmit: PropTypes.func,
  goBack: PropTypes.func,
  goSULDetail: PropTypes.func,
  onResetSignUpForm: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
  onShowLogin: params => dispatch(showLogin(params)),
  onShowLoadingIcon: () => dispatch(showLoadingIcon()),
  onHideLoadingIcon: () => dispatch(hideLoadingIcon()),
  onUploadFiles: params => dispatch(uploadFiles(params)),
  onSULSubmit: params => dispatch(list_update(params)),
  goSULDetail: token => dispatch(routerActions.push(`/su/${token}`)),
  onResetSignUpForm: () => dispatch(resetSignUpForm())
})

const mapStateToProps = state => ({
  userId: state.userId,
  signUpForm: state.frontend.signUpForm
})

export default connect(mapStateToProps, mapDispatchToProps)(InputOptions)
