import React, { useState, useContext, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { hasUserAccessToken } from 'services/access_tokens'
import SvgIcon from 'src/components/common/SvgIcon'
import plusIcon from 'src/assets/icons/plus_icon.svg'
import AddIcon from 'src/assets/icons/add-icon.png'
import LeftChevron from 'src/assets/icons/left_chevron.svg'
import Button from 'components/common/Button'
import Tags from '../../Components/Tags/Tags'
import TextInputElement from 'components/forms/TextInputElement'
import SearchIcon from 'assets/icons/search_icon.svg'
import GuestTable from './GuestTable/GuestTableV2'
import PopConfirm from 'components/common/PopConfirm/PopConfirm'
import NoticeRed from 'assets/icons/ico-notice-red.png'
import EditModal from './EditModal/EditModal'
import compWithGuestEditModal from '../HOC/WithGuestEditModal'
import { POPUP_BOX_TYPES_CONSTANTS } from 'src/constants'
import PageContainerContext from 'services/contexts'
import LoadingComponent from 'src/components/common/LoadingComponent/LoadingComponent'

import styles from './guests-list-page-web.scss'

const EditModalHOC = compWithGuestEditModal(EditModal)

const GuestsListPageWeb = ({
  userId,
  isHost,
  isHostOrCohost,
  event,
  searchValue,
  selectedTag,
  tags,
  searchGuestsList,
  onRemoveGuest,
  onSelectGuest,
  onChangeEventsModal,
  onGetGuestsList,
  onInputChange,
  onEditGuest,
  onSendInvitations,
  onSelectTag
}) => {
  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false)
  const [removeDisplayName, setRemoveDisplayName] = useState('')
  const { onChangePopupBox, onChangePopupBoxCallBack } = useContext(PageContainerContext)
  const editModalRef = useRef(null)
  const failedCount = onGetGuestsList('failed').length
  const unSentCount = onGetGuestsList('unsent').length
  const createdCount = onGetGuestsList('created').length
  const isCreating = createdCount !== unSentCount

  useEffect(() => {
    history.pushState(null, null, location.href)
    onChangePopupBox(POPUP_BOX_TYPES_CONSTANTS.guestsList)
    onChangePopupBoxCallBack(() => onChangeEventsModal('details'))
  }, [])

  const handleRemoveGuest = () => {
    onRemoveGuest()
    setIsRemoveModalVisible(false)
  }

  const handleClickOption = (option, guest) => {
    onSelectGuest(guest)
    switch (option) {
      case 'remove':
        setIsRemoveModalVisible(true)
        setRemoveDisplayName(guest.displayName)
        break
      case 'edit':
        editModalRef.current.openModal(guest)
        break
    }
  }

  const renderButton = () => {
    if (!isHostOrCohost || !hasUserAccessToken()) return

    const isHaveUnsent = unSentCount > 0

    return (
      <>
        <Button
          className={styles['button-inner']}
          onClick={() => {
            window.mixpanel.track('ADD GUESTS Clicked', {
              event_id: event.id,
              user_id: userId
            })
            onChangeEventsModal('addGuests')
          }}
          plain={isHaveUnsent}
        >
          {isHaveUnsent ? (
            <img src={AddIcon} width={16} />
          ) : (
            <SvgIcon icon={plusIcon} className={styles['plus-icon']} />
          )}
          <span>add guests</span>
        </Button>

        {isHaveUnsent && (
          <Button
            disabled={isCreating}
            className={styles['button-inner']}
            onClick={onSendInvitations}
          >
            {isCreating ? `${createdCount}/${unSentCount} CREATED` : `SEND ${unSentCount} INVITES`}
          </Button>
        )}
      </>
    )
  }

  return (
    <div className={styles['guests-list-web-container']}>
      <div className={styles['header-wrapper']}>
        <SvgIcon
          icon={LeftChevron}
          className={styles['back-icon']}
          onClick={() => history.back()}
        />
        <div className={styles['title']}>Guest List</div>
        {renderButton()}
      </div>

      {isCreating ? (
        <div className={styles['loading-wrapper']}>
          <LoadingComponent light type="partial" />
        </div>
      ) : (
        <div className={styles['content-container']}>
          {failedCount > 0 && (
            <div className={styles['failed-text']}>
              <img src={NoticeRed} width="14" />
              You have {failedCount} failed invites!
            </div>
          )}
          <Tags
            tagsList={tags}
            selectedTag={selectedTag}
            onSelectTag={onSelectTag}
            onGetGuestsList={onGetGuestsList}
          />

          <div className={styles['input-wrapper']}>
            <SvgIcon className={styles['search-icon']} icon={SearchIcon} />
            <TextInputElement
              className={styles['input-inner']}
              valid
              placeholder="Find guests"
              onChange={e => onInputChange(e.target.value)}
              value={searchValue}
            />
          </div>

          <GuestTable
            isHostOrCohost={isHostOrCohost}
            event={event}
            isHost={isHost}
            tableList={searchValue ? searchGuestsList : onGetGuestsList()}
            onClickOption={handleClickOption}
          />
        </div>
      )}

      <PopConfirm
        visible={isRemoveModalVisible}
        onClose={() => setIsRemoveModalVisible(false)}
        onConfirm={handleRemoveGuest}
        confirmText="remove"
        type="delete"
      >
        Are you sure you want to remove “{removeDisplayName}” from your guest list?
      </PopConfirm>

      <EditModalHOC
        ref={editModalRef}
        isHost={isHost}
        event={event}
        isHostOrCohost={isHostOrCohost}
        onEditGuest={onEditGuest}
      />
    </div>
  )
}

GuestsListPageWeb.propTypes = {
  userId: PropTypes.string,
  isHost: PropTypes.bool,
  isHostOrCohost: PropTypes.bool,
  selectedTag: PropTypes.string,
  searchValue: PropTypes.string,
  tags: PropTypes.array,
  searchGuestsList: PropTypes.array,
  event: PropTypes.object,
  onChangeEventsModal: PropTypes.func,
  onSelectTag: PropTypes.func,
  onGetGuestsList: PropTypes.func,
  onInputChange: PropTypes.func,
  onRemoveGuest: PropTypes.func,
  onSelectGuest: PropTypes.func,
  onEditGuest: PropTypes.func,
  onSendInvitations: PropTypes.func
}

export default GuestsListPageWeb
