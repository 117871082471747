import uuid from 'uuid'

import { generateTempId, sortByCreatedAtComparator } from 'services/utils'

export const LOCAL_MEDIUM = 'local_medium'

// Number of comments to show by default before the user clicks a "more comments" link
export const NUM_INITIAL_COMMENTS = 3

export function createTempMedium (imageInfo, userId) {
  const id = uuid.v4()
  return {
    id,
    // Note: might be a dataUrl
    file: {
      tiny_url_2x: imageInfo.url,
      url_2x: imageInfo.url
    },
    created_at: new Date().toISOString(),
    file_state: LOCAL_MEDIUM,
    has_fetched_likes: true,
    width_2x: imageInfo.width,
    height_2x: imageInfo.height,
    width: imageInfo.width,
    height: imageInfo.height,
    imageInfo: imageInfo,
    likes_count: 0,
    comments_count: 0,
    user: userId,
    taken_at: imageInfo.taken_at,
    loading: true,
    percent_uploaded: 0.05
  }
}

export function sortedProcessedMedia (media) {
  return Object.values(media)
    .filter(visibleMedium)
    .sort(sortByCreatedAtComparator(true))
}

export function createTempLike (userId, likeable_id) {
  const id = generateTempId()
  return {
    id,
    created_at: new Date().toISOString(),
    user: userId,
    likeable_id
  }
}

export function visibleMedium (medium) {
  return !medium.destroyed_at &&
  !medium.flagged_at &&
  (medium.file_state === 'processed' || medium.file_state === LOCAL_MEDIUM)
}
