import React from 'react'
import FreeMembershipImage from 'src/assets/checkout-no-membership.png'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

import styles from './free-membership.scss'

const GreenTick = <CheckOutlined style={{ color: '#2CD4AE', marginRight: 10 }} />
const RedCross = <CloseOutlined style={{ color: '#FE4259', marginRight: 10 }} />

const descriptions = [
  {
    icon: GreenTick,
    description: <div>Free sharing via email, social, and your own SMS</div>
  },
  {
    icon: GreenTick,
    description: (
      <div>
        <b>2</b> Active Polls & Lists
      </div>
    )
  },
  {
    icon: GreenTick,
    description: (
      <div>
        <b>10</b> Automated Invites/month to send to your guest list in one tap
      </div>
    )
  },
  {
    icon: GreenTick,
    description: (
      <div>
        Share <b>4</b> Event Videos to the Media Album
      </div>
    )
  },
  {
    icon: RedCross,
    description: <div>Invite Delivery & Viewed status, RSVP reminders</div>
  },
  {
    icon: RedCross,
    description: <div>Purchase additional credits anytime</div>
  },
  {
    icon: RedCross,
    description: <div>Unlock ALL Premium Designs</div>
  },
  {
    icon: RedCross,
    description: <div>Send Instant Event Updates</div>
  },
  {
    icon: RedCross,
    description: <div>Sell tickets</div>
  }
]

const FreeMembership = () => {
  return (
    <main className={styles['free-membership-container']}>
      <section className={styles['action-container']}>
        <h1>Free Plan</h1>
        <p>
          For hosts who don’t mind spending the time to send invites individually from your own
          number
        </p>
      </section>

      <section className={styles['description-container']}>
        <img src={FreeMembershipImage} alt="" />
        <ul>
          {descriptions.map(({ icon, description }, index) => (
            <li key={index}>
              {icon}
              {description}
            </li>
          ))}
        </ul>
      </section>
    </main>
  )
}

export default FreeMembership
