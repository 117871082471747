import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { loginFormSelector } from 'src/selectors'

import Button from 'components/common/Button'
import FullNameInputInner from './FullNameInputInner/FullNameInputInner'
import { updateUser, updateAvatar, fetchCurrentUser } from 'src/actions/user'
import { base64ToFile } from 'src/services/utils'
import AvatarUpload from 'components/common/AvatarUpload/AvatarUpload'
import { loginComplete } from 'src/actions/login'
import styles from './full-name-input.scss'

const FullNameInput = props => {
  const { onNextStep } = props
  const dispatch = useDispatch()
  const [croppedAvatar, setCroppedAvatar] = useState(null)
  const loginForm = useSelector(loginFormSelector)

  const isFullNameValid = () => {
    const hasFirstName = loginForm.firstName && loginForm.firstName !== ''
    const hasLastName = loginForm.lastName && loginForm.lastName !== ''
    return hasFirstName || hasLastName
  }

  const handleUpdateName = () => {
    const { firstName, lastName } = loginForm

    if (croppedAvatar) {
      var encodedData = btoa(croppedAvatar.split(',')[1])
      var decodedData = atob(encodedData)
      dispatch(
        updateAvatar({
          file: base64ToFile(decodedData, 'image/png'),
          onSuccessCallback: () => {
            setTimeout(() => {
              dispatch(fetchCurrentUser())
            }, 5000)
          }
        })
      )
    }

    dispatch(
      updateUser({
        user: { first_name: firstName, last_name: lastName },
        onSuccessCallback: () => {
          dispatch(loginComplete())
          onNextStep?.()
        }
      })
    )
  }

  return (
    <div className={styles['full-name-container']}>
      <div className={styles['content-wrapper']}>
        <AvatarUpload onSetCroppedAvatar={setCroppedAvatar} croppedAvatar={croppedAvatar} />
        <div className={styles['input-wrapper']}>
          <FullNameInputInner />
        </div>
      </div>

      <Button
        disabled={!isFullNameValid()}
        className={styles['submit-button']}
        onClick={handleUpdateName}
      >
        DONE
      </Button>

      <div className={styles['confirmation-text']}>
        By using Hobnob, you agree to our&nbsp;
        <a target="_blank" rel="noopener noreferrer" href="https://hobnob.app/privacy/">
          Privacy Policy
        </a>
        <span> and </span>
        <a href="https://hobnob.app/terms/" target="_blank" rel="noopener noreferrer">
          Terms
        </a>
      </div>
    </div>
  )
}

FullNameInput.propTypes = {
  onNextStep: PropTypes.func
}

export default FullNameInput
