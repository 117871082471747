import { call, fork, take, put, select, spawn } from 'redux-saga/effects'

import { delay } from 'services/utils'
import {
  GET_CLIENT_CREDENTIALS_SUCCESS,
  GET_CLIENT_CREDENTIALS_ERROR,
  GET_INVITATION_CREDENTIALS_SUCCESS,
  GET_INVITATION_CREDENTIALS_ERROR,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
  getClientCredentials,
  getInvitationCredentials,
  refreshToken
} from 'src/actions/accessTokens'

import {
  isInvalidTokenError,
  getAccessToken,
  getRefreshToken,
  getTokenExpirationDate,
  hasUserAccessToken,
  tokenNeedsRefresh,
  clearTokens,
  setTokens
} from 'services/access_tokens'

import {
  clientCredentialsSelector,
  eventTokenSelector,
  invitationCredentialsSelector,
  invitationSelector,
  invitationTokenSelector,
  usersSelector
} from 'src/selectors'

export const AccessTokenType = Object.freeze({
  USER: 'user',
  CLIENT: 'client',
  INVITATION: 'invitation'
})

export default function * accessTokenRootSaga () {
  yield fork(optimisticRefreshTokenWorker)
  yield fork(invalidRefreshTokenWatcher)
}

// singleton variable to coordinate token requests
let tokenWorker

export function * getOrRefreshAccessToken () {
  if (tokenWorker) {
    yield tokenWorker.done
  }

  const tokenType = yield call(accessTokenTypeSaga)
  let token
  switch (tokenType) {
    case AccessTokenType.USER:
      token = yield getOrRefreshUserAccessToken()
      break
    case AccessTokenType.INVITATION:
      token = yield getOrRefreshInvitationCredentialsAccessToken()
      break
    case AccessTokenType.CLIENT:
    default:
      token = yield getOrRefreshClientCredentialsAccessToken()
      break
  }

  return { token, tokenType }
}

export function * getOrRefreshClientCredentialsAccessToken () {
  let clientCredentials = yield select(clientCredentialsSelector)
  const now = new Date()
  const expiresAt = new Date(clientCredentials.expiresAt)
  const needsRefresh = now > expiresAt

  if (needsRefresh) {
    yield runRefreshTokenWorker(AccessTokenType.CLIENT)
  }

  clientCredentials = yield select(clientCredentialsSelector)
  return clientCredentials.accessToken
}

function * getOrRefreshInvitationCredentialsAccessToken () {
  let invitationCredentials = yield select(invitationCredentialsSelector)
  const now = new Date()
  const expiresAt = new Date(invitationCredentials.expiresAt)
  const needsRefresh = now > expiresAt

  if (needsRefresh) {
    yield runRefreshTokenWorker(AccessTokenType.INVITATION)
  }

  invitationCredentials = yield select(invitationCredentialsSelector)
  return invitationCredentials.accessToken
}

function * getOrRefreshUserAccessToken () {
  if (tokenNeedsRefresh()) {
    yield runRefreshTokenWorker(AccessTokenType.USER)
  }

  return getAccessToken()
}

function * runRefreshTokenWorker (tokenType) {
  if (tokenWorker) {
    yield tokenWorker.done
  } else {
    const worker = yield startRefreshTokenWorker(tokenType)
    yield worker.done
  }
}

export function * refreshAccessToken () {
  const tokenType = yield call(accessTokenTypeSaga)
  yield runRefreshTokenWorker(tokenType)
}

function * startRefreshTokenWorker (tokenType) {
  tokenWorker = yield spawn(refreshTokenWorker, tokenType)
  return tokenWorker
}

function * refreshTokenWorker (tokenType) {
  switch (tokenType) {
    case AccessTokenType.USER: {
      const token = getRefreshToken()
      yield put(refreshToken(token))
      const action = yield take([REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_ERROR])
      if (action.type === REFRESH_TOKEN_SUCCESS) {
        setTokens(action.response)
      } else {
        clearTokens()
      }
      break
    }
    case AccessTokenType.INVITATION: {
      const invitation = yield select(invitationSelector)
      const userId = invitation.user
      const eventToken = yield select(eventTokenSelector)
      const invitationToken = yield select(invitationTokenSelector)

      yield put(getInvitationCredentials(userId, eventToken, invitationToken))
      yield take([GET_INVITATION_CREDENTIALS_SUCCESS, GET_INVITATION_CREDENTIALS_ERROR])
      break
    }
    case AccessTokenType.CLIENT: {
      yield put(getClientCredentials())
      yield take([GET_CLIENT_CREDENTIALS_SUCCESS, GET_CLIENT_CREDENTIALS_ERROR])
      break
    }
    default:
      console.error(`Unhandled token type: ${tokenType}`)
  }

  tokenWorker = null
}

function * optimisticRefreshTokenWorker () {
  if (!hasUserAccessToken()) {
    yield take(REFRESH_TOKEN_SUCCESS)
  }

  while (true) {
    if (tokenWorker) {
      yield tokenWorker.done
    }
    const expirationDate = getTokenExpirationDate()
    const now = new Date()
    const buffer = 5 * 1000
    const msTillExpires = expirationDate.getTime() - now.getTime()
    const diff = msTillExpires - buffer

    if (diff > 24 * 60 * 60 * 1000) {
      // the token is good for more than 24 hours so cancel the optimisticRefreshTokenWorker
      // Also our setTimeout-based delay function breaks on large values
      // http://stackoverflow.com/a/3468650/175830
      break
    }
    if (diff > 0) {
      yield delay(diff)
    }

    yield startRefreshTokenWorker(AccessTokenType.USER)
    yield take(REFRESH_TOKEN_SUCCESS)
  }
}

function * invalidRefreshTokenWatcher () {
  while (true) {
    const action = yield take(REFRESH_TOKEN_ERROR)
    const { wwwAuthenticateHeader } = action.apiPayload
    const isInvalidToken = wwwAuthenticateHeader && isInvalidTokenError(wwwAuthenticateHeader)
    if (isInvalidToken) {
      console.error('Invalid token error on refresh. Clearing access tokens!')
      clearTokens()
    }
  }
}

// Find the access token type to use
export function * accessTokenTypeSaga () {
  if (hasUserAccessToken()) {
    return AccessTokenType.USER
  } else if (yield call(canUseInvitationCredentials)) {
    return AccessTokenType.INVITATION
  } else {
    return AccessTokenType.CLIENT
  }
}

function * canUseInvitationCredentials () {
  const invitation = yield select(invitationSelector)
  if (!invitation) return false
  const users = yield select(usersSelector)

  const userId = invitation.user
  const user = users[userId]
  const invitationCredentials = yield select(invitationCredentialsSelector)
  const alreadyHasInvitationCredentials = invitationCredentials.accessToken

  return alreadyHasInvitationCredentials || user
}
