import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styles from './rsvp-accepted.scss'
import {
  eventSelector,
  rsvpModalHasChangedRsvpStatusSelector,
  rsvpModalShowDefaultDeclinedPageSelector
} from '../../selectors'
import { connect } from 'react-redux'
import Button from '../common/Button'
import TotalTicketsCounts from './TotalTicketsCounts'
import { pluralize, sumCount } from '../../services/utils'
import {
  setShowGuestsChangeRsvpModal,
  showInvitationReplyFormModal,
  showTicketCountControlModal
} from '../../actions/paymentGraphql'
import { getDisplayOrderTickets } from '../../services/payment_utils'

class RSVPDeclined extends Component {
  _renderLabel () {
    const { changeRsvp, showDefaultDeclinedPage } = this.props

    if (changeRsvp) {
      if (!showDefaultDeclinedPage) {
        return 'RSVP Changed'
      }
      return `No, Can't Go`
    } else {
      return `No, Can't Go`
    }
  }

  _renderSubTitle () {
    const { changeRsvp, rsvpTextClass, showDefaultDeclinedPage } = this.props
    if (changeRsvp) {
      if (!showDefaultDeclinedPage) {
        return (
          <div className={rsvpTextClass}>
            <div className={styles['rsvp-text']}>Sorry you can't make it!
            </div>
          </div>
        )
      }
      return null
    } else {
      return (
        <span>Sorry you can't make it!</span>
      )
    }
  }

  _changeRsvp = () => {
    const {
      guestCountEnabled,
      onAcceptInvitation,
      onShowInvitationReplyFormModal,
      needPayTicket,
      ticketTypesCounts,
      onShowGuestsChangeRsvpModal,
      onShowTicketCountControlModal
    } = this.props

    onAcceptInvitation()
    const totalTicketsCounts = sumCount(ticketTypesCounts)

    if (needPayTicket) {
      if (totalTicketsCounts) {
        onShowInvitationReplyFormModal()
      } else {
        onShowTicketCountControlModal()
      }
    } else {
      if (guestCountEnabled) {
        onShowGuestsChangeRsvpModal()
      } else {
        onShowInvitationReplyFormModal()
      }
    }
  }

  _renderRsvpText () {
    const { changeRsvp, showDefaultDeclinedPage } = this.props
    return (
      changeRsvp && showDefaultDeclinedPage
        ? <Button className={styles['button']} onClick={this._changeRsvp}> change rsvp to yes</Button>
        : null
    )
  }

  _invitationReplyForm () {
    const { invitationReplyForm } = this.props

    return (
      <div className={styles['invitation-reply-form-wrapper']}>
        { invitationReplyForm }
      </div>
    )
  }

  _renderCurrentComponent () {
    const { changeRsvp, showDefaultDeclinedPage } = this.props
    if (changeRsvp) {
      if (!showDefaultDeclinedPage) {
        return this._invitationReplyForm()
      } else {
        return null
      }
    } else {
      return this._invitationReplyForm()
    }
  }

  _viewOrders = (ordersWithCounts) => {
    const { onChangeRouter, onSetCurrentOrderId } = this.props
    if (ordersWithCounts.length > 1) {
      onChangeRouter('orders')
    } else {
      onSetCurrentOrderId(ordersWithCounts[0].id)
      onChangeRouter('orderDetail')
    }
  }

  render () {
    const {
      event,
      showDefaultDeclinedPage,
      changeRsvp,
      needPayTicket,
      ticketTypesCounts,
      listMyOrdersForEvent,
      hasChangedRsvpStatus
    } = this.props

    const totalTicketsCounts = sumCount(ticketTypesCounts)
    const ordersWithCounts = getDisplayOrderTickets(listMyOrdersForEvent, ticketTypesCounts)
    const label = pluralize(ordersWithCounts.length, 'order')
    return (
      <div className={styles['declined-wrapper']}>
        <h3 className={styles['title']}>{this._renderLabel()}</h3>
        {
          showDefaultDeclinedPage && <div className={styles['event-name']}>{event.name}</div>
        }

        {this._renderSubTitle()}

        {
          changeRsvp && !hasChangedRsvpStatus && !!totalTicketsCounts && needPayTicket && <TotalTicketsCounts needPayTicket
            ticketCounts={ticketTypesCounts}
            listMyOrdersForEvent={listMyOrdersForEvent}
            className={styles['total-tickets-counts']}
          />
        }

        {this._renderCurrentComponent()}
        {this._renderRsvpText()}

        {
          changeRsvp && !hasChangedRsvpStatus && !!totalTicketsCounts && needPayTicket && !!ordersWithCounts.length &&
          <a className={styles['view-order-link']} onClick={() => this._viewOrders(ordersWithCounts)}>view {label}</a>
        }
      </div>
    )
  }
}

RSVPDeclined.propTypes = {
  guestCountEnabled: PropTypes.bool.isRequired,
  rsvpTextClass: PropTypes.string,
  invitationReplyForm: PropTypes.element,
  onAcceptInvitation: PropTypes.func,
  changeRsvp: PropTypes.bool,
  showDefaultDeclinedPage: PropTypes.bool,
  event: PropTypes.object,
  needPayTicket: PropTypes.bool,
  ticketTypesCounts: PropTypes.array,
  listMyOrdersForEvent: PropTypes.array,
  onChangeRouter: PropTypes.func,
  onShowInvitationReplyFormModal: PropTypes.func,
  onShowGuestsChangeRsvpModal: PropTypes.func,
  onShowTicketCountControlModal: PropTypes.func,
  onSetCurrentOrderId: PropTypes.func,
  hasChangedRsvpStatus: PropTypes.bool
}
function mapStateToProps (state) {
  return {
    showDefaultDeclinedPage: rsvpModalShowDefaultDeclinedPageSelector(state),
    event: eventSelector(state),
    hasChangedRsvpStatus: rsvpModalHasChangedRsvpStatusSelector(state)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    onShowInvitationReplyFormModal: () => dispatch(showInvitationReplyFormModal()),
    onShowGuestsChangeRsvpModal: () => dispatch(setShowGuestsChangeRsvpModal()),
    onShowTicketCountControlModal: () => dispatch(showTicketCountControlModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RSVPDeclined)
