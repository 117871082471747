import React, { Component } from 'react'
import HobnobModal from 'components/common/HobnobModal'
import Button from 'components/common/Button'
import PropTypes from 'prop-types'
import styles from './activate-modal.scss'

class ActivateModal extends Component {
  render() {
    const { visible, onClose, onSaveGuests } = this.props
    return (
      <HobnobModal closeOnOuterClick={false} show={visible} onClose={onClose}>
        <div className={styles['activate-modal-container']}>
          <div className={styles['title']}>Activate your event</div>
          <div className={styles['subtitle']}>Please activate your event to send invites!</div>
          <div className={styles['content']}>
            If you don’t want to do so now, we will save your guest list as draft that you can come
            back to and edit whenever you want.
          </div>

          <div className={styles['button-wrapper']}>
            <Button className={styles['button-inner']} onClick={() => onSaveGuests(true)}>
              Send & activate
            </Button>
            <Button className={styles['button-inner']} plain onClick={() => onSaveGuests()}>
              save & send later
            </Button>
          </div>
        </div>
      </HobnobModal>
    )
  }
}

ActivateModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSaveGuests: PropTypes.func
}

export default ActivateModal
