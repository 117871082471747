import React, { Component } from 'react'
import { parseEventCSV } from 'src/actions/event'
import { connect } from 'react-redux'
import { EMAIL_CONTACT_METHOD, PHONE_NUMBER_CONTACT_METHOD } from 'services/contact_methods'
import PropTypes from 'prop-types'
import HobnobModal from 'components/common/HobnobModal'
import UploadWhite from 'assets/icons/upload-white.png'
import BookIcon from 'assets/icons/book-icon.png'
import styles from './CSVFile-upload.scss'
import * as XLSX from 'xlsx'
import { message, Table } from 'antd'

const ALLOWED_IMAGE_TYPES = ['.csv', '.CSV', '.xlsx', '.XLSX', '.xls', '.XLS']

const tableHeader = [
  { dataIndex: 'first_name', ellipsis: true },
  { dataIndex: 'last_name', ellipsis: true },
  { dataIndex: 'contacts', ellipsis: true }
]

const tableColumns = [
  {
    first_name: 'Jane',
    last_name: 'Williams',
    contacts: '8083863881'
  },
  {
    first_name: 'John',
    last_name: 'Smith',
    contacts: 'john.smith@gmail.com'
  },
  {
    first_name: 'Jennifer',
    last_name: 'Green',
    contacts: '7079687688'
  }
]

class CSVFileUpload extends Component {
  inputRef = React.createRef()

  updateGuestsList = guestsList => {
    const { onGetGuestsFromCSV } = this.props
    const mapRecords = guestsList.map(item => {
      const { first_name, last_name, phone_number, email_address } = item
      const member = {
        key: Math.random(),
        first_name,
        last_name
      }

      if (phone_number) {
        member.contact_method_type = PHONE_NUMBER_CONTACT_METHOD
        member.contact_method_display_name = phone_number
      } else if (email_address) {
        member.contact_method_type = EMAIL_CONTACT_METHOD
        member.contact_method_display_name = email_address
      }

      return member
    })
    onGetGuestsFromCSV(mapRecords)
  }

  handleInputChange = e => {
    const file = e.target.files[0]
    const fileName = file.name

    if (!ALLOWED_IMAGE_TYPES.some(type => fileName.endsWith(type))) {
      message.error('Please upload a valid file, we support .csv, .xlsx and .xls file types')
      return
    }

    if (fileName.includes('.csv') || fileName.includes('.CSV')) {
      const READER = new FileReader()
      READER.readAsText(file)
      READER.onload = () => {
        const str = READER.result
        this.handleUploadFile(str)
      }
    } else {
      this.handleXlsxFile(file)
    }
  }

  handleUploadFile = input => {
    const { onParseEventCSV, onClose } = this.props

    onParseEventCSV({
      input,
      onSuccessCallback: data => {
        if (data?.data?.parse_event_csv) {
          const { parse_event_csv } = data.data

          if (parse_event_csv.successful) {
            if (parse_event_csv?.result?.invalid_csv) {
              message.warning('invalid csv :' + parse_event_csv?.result?.invalid_csv)
            }

            this.updateGuestsList(parse_event_csv.result.valid_records)
          } else {
            message.error(parse_event_csv.human_readable_error)
          }
          onClose()
        }
      },
      onFailCallback: data => {
        onClose()
        if (data.errors) {
          message.error('Upload Failed')
        }
      }
    })
  }

  handleXlsxFile = file => {
    const READER = new FileReader()
    READER.readAsBinaryString(file)

    READER.onload = () => {
      const str = READER.result
      const workbook = XLSX.read(str, { type: 'binary' })
      let data = []
      for (const sheet in workbook.Sheets) {
        if (workbook.Sheets.hasOwnProperty(sheet)) {
          data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet], { header: 1 }))
        }
      }

      let input = ''
      for (let item of data) {
        input += item.join(',') + '\r\n'
      }

      this.handleUploadFile(input)
    }
  }

  render() {
    const isDesktop = window.innerWidth >= 1080

    return (
      <HobnobModal show={this.props.visible} closeOnOuterClick={false} onClose={this.props.onClose}>
        <div className={styles['upload-file-container']}>
          <div className={styles['modal-title']}>upload file</div>
          <div className={styles['upload-button']} onClick={() => this.inputRef.current.click()}>
            <img src={UploadWhite} alt="" width="20" />
            <span>upload file</span>
            <input
              accept={ALLOWED_IMAGE_TYPES.join(',')}
              type="file"
              style={{ display: 'none' }}
              onChange={this.handleInputChange}
              ref={this.inputRef}
            />
          </div>

          <div className={styles['interval-line']} />

          <div className={styles['help-wrapper']}>
            <div className={styles['help-title']}>file help</div>
            <div className={styles['help-content']}>
              <div className={styles['help-item']}>1. Our maximum guest list is 2,500</div>
              <div className={styles['help-item']}>
                2. You may have a first and last name in Column A
              </div>
              <div className={styles['help-item']}>
                3. No special characters or Facebook Addresses
              </div>
              <div className={styles['help-item']}>
                4. No headers at the top, such as "Name", "Email" or "Phone"
              </div>
              <div className={styles['help-item']}>
                5. Save or export your file as a .csv, .xlsx, or .xls file before uploading
              </div>
            </div>
          </div>

          <div className={styles['download-template']}>
            {isDesktop && (
              <>
                <img src={BookIcon} alt="" width="18" />
                <a
                  className={styles['download-text']}
                  href="../../../../../../assets/tableTemplate/Event Guest List Template.csv"
                  download
                >
                  Download sample CSV template
                </a>
              </>
            )}
          </div>

          <Table
            showHeader={false}
            bordered
            columns={tableHeader}
            dataSource={tableColumns}
            rowKey={record => record.first_name}
            pagination={false}
            size="small"
          />
        </div>
      </HobnobModal>
    )
  }
}

CSVFileUpload.propTypes = {
  onParseEventCSV: PropTypes.func,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onGetGuestsFromCSV: PropTypes.func
}

function mapDispatchToProps(dispatch) {
  return {
    onParseEventCSV: data => dispatch(parseEventCSV(data))
  }
}

export default connect(null, mapDispatchToProps)(CSVFileUpload)
