import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import AvatarEditor from 'react-avatar-editor'
import UserAvatarContainer from '../UserAvatarContainer'
import { userSelector } from 'src/selectors'
import HobnobModal from 'components/common/HobnobModalV2/HobnobModal'
import Button from 'components/common/Button'
import SvgIcon from 'components/common/SvgIcon'
import CameraIcon from 'assets/icons/camera.svg'
import { Slider } from 'antd'
import { compressImage } from 'src/actions/utils'
import styles from './avatar-upload.scss'

const AvatarUpload = props => {
  const { onSetCroppedAvatar, croppedAvatar } = props
  const user = useSelector(userSelector) || {}
  const [croppingAvatar, setCroppingAvatar] = useState(null)
  const [zoomValue, setZoomValue] = useState(1.2)
  const [rotateValue, setRotateValue] = useState(0)
  const editorRef = useRef(null)
  const inputRef = useRef(null)

  const handleImageChange = e => {
    const file = e.target.files[0]

    if (file) {
      setCroppingAvatar(URL.createObjectURL(file))
    }
  }

  const handleClose = () => {
    setCroppingAvatar(null)
    inputRef.current.value = ''
  }

  const handleSave = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImage()
      const dataURL = canvas.toDataURL('image/png')
      const compressedImage = await compressImage(dataURL, { width: 120 })
      onSetCroppedAvatar(compressedImage)
      setCroppingAvatar(null)
    }
  }

  const renderAvatar = () => {
    const isUploadVisible = !croppedAvatar && user?.avatar && !user.avatar.medium_url_2x
    if (isUploadVisible) {
      return (
        <div className={styles['camera-icon-wrapper']}>
          <SvgIcon icon={CameraIcon} className={styles['camera-icon']} />
          <span className={styles['text']}>Add Photo</span>
        </div>
      )
    }
    return <UserAvatarContainer user={user} url={croppedAvatar} size={60} />
  }

  const avatarWidth = 200
  const avatarHeight = 200

  return (
    <div className={styles['avatar-upload-container']}>
      <div className={styles['avatar-container']}>
        {renderAvatar()}

        <input
          ref={inputRef}
          type="file"
          accept="image/*"
          className={styles['input-upload-inner']}
          onChange={handleImageChange}
        />
      </div>

      <HobnobModal visible={Boolean(croppingAvatar)} onCancel={handleClose}>
        <div className={styles['avatar-cropper-container']}>
          <AvatarEditor
            ref={editorRef}
            image={croppingAvatar}
            width={avatarWidth}
            height={avatarHeight}
            border={30}
            borderRadius={avatarWidth / 2}
            color={[255, 255, 255, 0.6]}
            scale={zoomValue}
            rotate={rotateValue}
          />

          <div className={styles['slider-wrapper']}>
            <span className={styles['text']}>Zoom: </span>
            <Slider
              className={styles['slider-inner']}
              value={zoomValue}
              min={1}
              max={2}
              step={0.1}
              onChange={value => setZoomValue(value)}
            />
          </div>

          <div className={styles['slider-wrapper']}>
            <span className={styles['text']}>Rotate: </span>
            <Slider
              className={styles['slider-inner']}
              value={rotateValue}
              min={0}
              max={360}
              step={1}
              onChange={value => setRotateValue(value)}
            />
          </div>

          <Button onClick={handleSave}>Save</Button>
        </div>
      </HobnobModal>
    </div>
  )
}

AvatarUpload.propTypes = {
  croppedAvatar: PropTypes.string,
  onSetCroppedAvatar: PropTypes.func
}

export default AvatarUpload
