import queryString from 'query-string'
const BASE_URL = 'https://maps.googleapis.com/maps/api/staticmap'
const GOOGLE_BROWSER_API_KEY = process.env.GOOGLE_BROWSER_API_KEY
const APPLE_MAPS_URL = 'http://maps.apple.com/'

export function venueMapImageUrl (venue) {
  if (!venue) return ''
  return staticMapImageUrl(venue.lat, venue.lng)
}

export function staticMapImageUrl (lat, lng, zoom = 14) {
  const latlng = `${lat},${lng}`

  const mapParams = {
    center: latlng,
    key: GOOGLE_BROWSER_API_KEY,
    maptype: 'roadmap',
    markers: `icon:${process.env.ASSET_HOST}/assets/map-pin.png|scale=1|${latlng}`,
    scale: 2,
    size: '320x320',
    zoom
  }

  return `${BASE_URL}?${queryString.stringify(mapParams)}`
}

export function mapUrl (lat, lng) {
  return `${APPLE_MAPS_URL}?${queryString.stringify({ q: latLng(lat, lng) })}`
}

export function directionsUrl (lat, lng) {
  return `${APPLE_MAPS_URL}?${queryString.stringify({ daddr: latLng(lat, lng) })}`
}

function latLng (lat, lng) {
  return `${lat},${lng}`
}
