import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { userSelector, eventSelector, planSelector } from 'src/selectors'
import { changeVisible, setContent } from 'src/actions/globalLayer'
import { UPGRADE_PLAN_SOURCE } from 'src/constants'
import DashboardContainer from 'containers/DashboardContainer'
import { showLogin } from 'src/actions/login'
import { hasUserAccessToken } from 'src/services/access_tokens'

import styles from './locked-event.scss'

const LockedEvent = () => {
  const dispatch = useDispatch()
  const event = useSelector(eventSelector)
  const user = useSelector(userSelector)
  const { visible: isPlanModalVisible } = useSelector(planSelector)

  useEffect(() => {
    if (hasUserAccessToken()) {
      if (user) {
        dispatch(setContent(UPGRADE_PLAN_SOURCE.lockedEvent))
        dispatch(changeVisible(true))
      }
    } else {
      dispatch(showLogin())
    }
  }, [user])

  const flyerUrl = () => {
    if (event && event.flyer) {
      return event.flyer.watermarked_url_2x
    } else {
      return ''
    }
  }

  return (
    <DashboardContainer visibleHeaderBar={isPlanModalVisible}>
      <div className={styles['flier-container']}>
        <img className={styles['flier']} src={flyerUrl()} />
      </div>
    </DashboardContainer>
  )
}

export default LockedEvent
