import uuid from 'uuid'
import dateformat from 'dateformat'

import { sortByCreatedAtComparator } from 'src/services/utils'

export const CommentStatus = Object.freeze({
  SENDING: 'sending',
  SENT: 'sent',
  ERROR: 'error'
})

export function createTempComment (commentBody, userId, commentable_id) {
  const id = uuid.v4()
  return {
    id,
    content: commentBody,
    created_at: new Date().toISOString(),
    user: userId,
    commentable_id
  }
}

export function visibleComment (comment) {
  return !comment.destroyed_at
}

export function commentSending (comment) {
  return comment.localStatus === CommentStatus.SENDING
}

export function commentError (comment) {
  return comment.localStatus === CommentStatus.ERROR
}

export function localComment (comment) {
  return comment.localStatus && comment.localStatus !== CommentStatus.SENT
}

export function commentsSort (a, b) {
  const aSending = a.localStatus === CommentStatus.SENDING
  const bSending = b.localStatus === CommentStatus.SENDING
  const createdByComparator = sortByCreatedAtComparator(false)
  if (aSending && bSending) {
    return createdByComparator(a, b)
  } else if (aSending && !bSending) {
    return 1
  } else if (!aSending && bSending) {
    return -1
  } else {
    return createdByComparator(a, b)
  }
}

export function commentTimestamp (created_at) {
  const date = new Date(created_at)
  const now = new Date(Date.now())
  const formattedTime = dateformat(created_at, 'h:MMTT')

  const within2Minutes = (now.getTime() - date.getTime()) < (2 * 60 * 1000)
  const within1Hour = (now.getTime() - date.getTime()) < (1 * 60 * 60 * 1000)
  const isSameYear = date.getYear() === now.getYear()
  const isSameMonth = date.getMonth() === now.getMonth()
  const isToday = date.getDate() === now.getDate() && isSameMonth && isSameYear

  if (within2Minutes) {
    return 'Just Now'
  } else if (within1Hour) {
    const minutes = Math.floor((now.getTime() - date.getTime()) / (60 * 1000))
    return `${minutes}m`
  } else if (isToday) {
    return formattedTime
  } else if (isSameYear) {
    return dateformat(created_at, 'mmm d, hTT')
  } else {
    return `${dateformat(created_at, 'mmm d, yyyy')}`
  }
}
