import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { VelocityTransitionGroup } from 'velocity-react'

import {
  namesByUserIdSelector,
  userSelector,
  toastMessagesMessagesSelector,
  toastMessagesVisibleSelector
} from 'src/selectors'

import { hideToastMessages } from 'src/actions/toastMessages'

import ToastMessage from './ToastMessage'

import styles from './toast-messages-container.scss'

export class ToastMessagesContainer extends React.Component {
  _onClose = () => this.props.dispatch(hideToastMessages())

  _renderMessage = (message, i) => {
    const { namesByUserId, visible } = this.props
    if (!visible) return null

    return (
      <div key={i}>
        <ToastMessage
          message={message}
          namesByUserId={namesByUserId}
          onClose={this._onClose}
        />
      </div>
    )
  }

  render () {
    const { messages } = this.props

    return (
      <div className={styles['container']}>
        <div className={styles['message-container']}>
          <VelocityTransitionGroup enter={{ animation: 'transition.slideDownBigIn' }} leave={{ animation: 'transition.slideUpBigOut' }}>
            {messages.map(this._renderMessage)}
          </VelocityTransitionGroup>
        </div>
      </div>
    )
  }
}

ToastMessagesContainer.propTypes = {
  dispatch: PropTypes.func,
  messages: PropTypes.array,
  namesByUserId: PropTypes.object,
  visible: PropTypes.bool
}

function mapStateToProps (state) {
  return {
    visible: toastMessagesVisibleSelector(state),
    messages: toastMessagesMessagesSelector(state),
    namesByUserId: namesByUserIdSelector(state),
    user: userSelector(state)
  }
}

export default connect(mapStateToProps)(ToastMessagesContainer)
