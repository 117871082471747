import { connect } from 'react-redux'

import {
  branchEmailHostMessageAttemptLinkSelector,
  branchMessageIconLinkSelector,
  invitationReplyMessageSelector,
  invitationSelector,
  messageModalMessageHostSelector,
  messageModalSubTitleSelector,
  messageModalTitleSelector,
  messageModalVisibleSelector,
  showMessageSentSelector,
  isHostSelector
} from 'src/selectors'

import { hideHostMessageModal } from 'src/actions/frontend'

import {
  requestSendInvitationReplyMessage,
  setInvitationReplyMessage
} from 'src/actions/invitations'

import { canSendInvitationReplyMessage } from 'src/services/invitation_helpers'

import MessageModal from './MessageModal'

function mapStateToProps(state) {
  const messageHost = messageModalMessageHostSelector(state)
  const currentInvitation = invitationSelector(state)

  const props = {
    invitationReplyMessage: invitationReplyMessageSelector(state),
    show: messageModalVisibleSelector(state),
    messageTitle: messageModalTitleSelector(state),
    messageSubTitle: messageModalSubTitleSelector(state),
    showMessageSent: showMessageSentSelector(state),
    branchLink: branchMessageIconLinkSelector(state),
    isHost: isHostSelector(state),
    userId: state.userId
  }

  if (messageHost) {
    return {
      ...props,
      branchLink: branchEmailHostMessageAttemptLinkSelector(state),
      canSendReply: canSendInvitationReplyMessage(currentInvitation)
    }
  } else {
    return {
      ...props,
      canSendReply: false
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeInvitationReplyMessage: value => {
      dispatch(setInvitationReplyMessage(value))
    },
    onClose: () => dispatch(hideHostMessageModal()),
    onSendInvitationReplyMessage: () => {
      dispatch(requestSendInvitationReplyMessage())
      dispatch(hideHostMessageModal())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageModal)
