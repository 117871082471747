import {
  EssentialsAnnualDescription,
  EssentialsMonthlyDescription,
  PremiumMonthlyDescription
} from 'src/containers/StripeCheckoutContainer/components/description-components'
import { isProdReactEnvironment } from '../actions/utils'

const calculateDiscount = (monthlyPrice, yearlyPrice) => {
  return Math.trunc((1 - yearlyPrice / 365 / (monthlyPrice / 30)) * 100) + '%'
}

export const BillPeriod = { Monthly: 'monthly', Annually: 'yearly' }
export const PlanType = {
  Free: 'free',
  Essentials: 'essentials',
  Premium: 'premium',
  Premium1: 'premium1',
  Pro: 'pro',
  Plus: 'plus',
  Standard: 'standard',
  Basic: 'basic',
  InvitationMaker: 'invitation_maker',
  PartyHost: 'party_host',
  EventPlanner: 'event_planner',
  GroupOrganizer: 'group_organizer'
}

// frontend custom level
/**
 * Because there is no level mechanism for plans in the backend.
 * Therefore, in order to facilitate front-end development, the front-end customizes a plan level mechanism.
 */
export const PlanTypeLevel = {
  Free: 0,
  InvitationMaker: 1,
  PartyHost: 2,
  EventPlanner: 3,
  GroupOrganizer: 4
}

export const STRIPE_CHECKOUT_TIMEOUT = 60 * 5 * 1000 // 5 mins in milliseconds

/**
 * only add active plan slugs here, for legacy plans, add them to legacy section in MembershipPlansConfig
 */
export const PlanSlugs = {
  Free: 'free',
  EssentialsMonthly: 'sub_new_essentials_1_month',
  EssentialsAnnually: 'sub_essentials_yearly',
  PremiumAnnually: 'sub_premium_2_yearly',
  BasicAnnually: 'sub_basic_yearly',
  InvitationMakerMonthly: 'sub_invitation_maker_monthly',
  InvitationMakerAnnually: 'sub_invitation_maker_annual',
  PartyHostMonthly: 'sub_party_host_monthly',
  PartyHostAnnually: 'sub_party_host_annual',
  EventPlannerMonthly: 'sub_event_planner_1_month',
  EventPlannerAnnually: 'sub_event_planner_annual',
  GroupOrganizerMonthly: 'sub_group_organizer_1_month',
  GroupOrganizerAnnually: 'sub_group_organizer_annual'
}

export const SubscriptionPlatform = {
  Hobnob: 'hobnob',
  AppStore: 'app_store',
  GooglePlay: 'play_store',
  Stripe: 'stripe'
}

const UTM_INFO_STORE_KEY = 'utm_info'

export const UpgradableSubscriptionPlatform = [
  SubscriptionPlatform.Hobnob,
  SubscriptionPlatform.Stripe
]

export const PlanTier = { Legacy: -1, Free: 0, Tier1: 1, Tier2: 2, Tier3: 3, Tier4: 4, Tier5: 5 }

/** Stripe Checkout Configs **/
/**
 * use getCreditsConfigs() and getMembershipPlanConfigs() to get the configs
 * as this method will detect environment to provide different config
 */
const StripeProductIds = {
  EssentialsMonthly: 'prod_OMZQO99H5Rl2Ng',
  EssentialsAnnually: 'prod_OMZLWGMztlMypw',
  PremiumAnnually: 'prod_OMZGt6nPC0IfhO',
  CreditsBundle1000: 'prod_OMY7OXEVVMN5N8',
  CreditsBundle500: 'prod_OMYB3PNCs2vL8R',
  CreditsBundle400: 'prod_OMYDJbP7Vmo5eu',
  CreditsBundle300: 'prod_OMYJ50S7qiVuId',
  CreditsBundle200: 'prod_OMYkLQ8l6y0nhV',
  CreditsBundle100: 'prod_OMYnVL4kQDuF0A',
  CreditsBundle50: 'prod_OMYsdIEmIODydF',
  CreditsBundle25: 'prod_OMYwl87TCwFk5Z',
  CreditsBundle10: 'prod_OMYyS77DaHYEsv',
  CreditsBundle5: 'prod_OMZD9njU3Hb0mH',
  InvitationMakerMonthly: 'prod_QA7gmmh5tHaLqd',
  InvitationMakerAnnually: 'prod_QA7h0XnwcGiV0M',
  PartyHostMonthly: 'prod_QA7hsoKd80Pvaf',
  PartyHostAnnually: 'prod_QA7hjZp0F7dmS9',
  EventPlannerMonthly: 'prod_QA7hTq2v0UkOE4',
  EventPlannerAnnually: 'prod_QA7hBKJpF3yESC',
  GroupOrganizerMonthly: 'prod_QA7hSmLJvyrAHi',
  GroupOrganizerAnnually: 'prod_QA7hnNE2iWsUVN'
}

const StripeProductIdsStaging = {
  EssentialsMonthly: 'prod_Nx0RmWwpbY0atm',
  EssentialsAnnually: 'prod_Nx0Z9OUp6sQtwM',
  PremiumAnnually: 'prod_Nx0cEy2OGATo69',
  CreditsBundle1000: 'prod_OFSrSnkt95k1Wk',
  CreditsBundle500: 'prod_OFSos6qe0W8Evr',
  CreditsBundle400: 'prod_OFSkA2dVtDMJLA',
  CreditsBundle300: 'prod_O0NnSUT6fKzOrm',
  CreditsBundle200: 'prod_O0NnolFfone5GX',
  CreditsBundle100: 'prod_O0Nm1IRiuVnEyK',
  CreditsBundle50: 'prod_O0NmWRMI8RsS9M',
  CreditsBundle25: 'prod_O0NlgPJIS5k7JY',
  CreditsBundle10: 'prod_O0NlrvSINeSX7P',
  CreditsBundle5: 'prod_O0NkhKGUT9JuL2',
  InvitationMakerMonthly: 'prod_PsylXlBhX2lzbb',
  InvitationMakerAnnually: 'prod_PsypzVtEcRYPca',
  PartyHostMonthly: 'prod_PsysbMT3hVyZQK',
  PartyHostAnnually: 'prod_Psyv0WkI1vTf4P',
  EventPlannerMonthly: 'prod_Psyw1qsrw4J0ox',
  EventPlannerAnnually: 'prod_PsyxSDeui4ccfd',
  GroupOrganizerMonthly: 'prod_PsyyuZAlD2CvXt',
  GroupOrganizerAnnually: 'prod_Psyztk07JcA1SG'
}

const legacyPlanConfigs = [
  {
    slug: 'sub_pro_1_year',
    legacySlugs: null,
    stripeId: '',
    detailDescriptionComp: null,
    checkoutInfo: null,
    trialCheckoutInfo: null,
    isBestValue: false,
    billPeriod: BillPeriod.Annually,
    planType: PlanType.Pro,
    tier: PlanTier.Legacy,
    active: false
  },
  {
    slug: 'sub_pro_1_month',
    legacySlugs: null,
    stripeId: '',
    detailDescriptionComp: null,
    checkoutInfo: null,
    trialCheckoutInfo: null,
    isBestValue: false,
    billPeriod: BillPeriod.Monthly,
    planType: PlanType.Pro,
    tier: PlanTier.Legacy,
    active: false
  },
  {
    slug: 'sub_plus_1_year',
    legacySlugs: null,
    stripeId: '',
    detailDescriptionComp: null,
    checkoutInfo: null,
    trialCheckoutInfo: null,
    isBestValue: false,
    billPeriod: BillPeriod.Annually,
    planType: PlanType.Plus,
    tier: PlanTier.Legacy,
    active: false
  },
  {
    slug: 'sub_plus_1_month',
    legacySlugs: null,
    stripeId: '',
    detailDescriptionComp: null,
    checkoutInfo: null,
    trialCheckoutInfo: null,
    isBestValue: false,
    billPeriod: BillPeriod.Monthly,
    planType: PlanType.Plus,
    tier: PlanTier.Legacy,
    active: false
  },
  {
    slug: 'sub_standard_1_year',
    legacySlugs: null,
    stripeId: '',
    detailDescriptionComp: null,
    checkoutInfo: null,
    trialCheckoutInfo: null,
    isBestValue: false,
    billPeriod: BillPeriod.Annually,
    planType: PlanType.Standard,
    tier: PlanTier.Legacy,
    active: false
  },
  {
    slug: 'sub_standard_1_month',
    legacySlugs: null,
    stripeId: '',
    detailDescriptionComp: null,
    checkoutInfo: null,
    trialCheckoutInfo: null,
    isBestValue: false,
    billPeriod: BillPeriod.Monthly,
    planType: PlanType.Standard,
    tier: PlanTier.Legacy,
    active: false
  },
  {
    slug: 'sub_premium_1_month',
    legacySlugs: null,
    stripeId: '',
    detailDescriptionComp: null,
    checkoutInfo: null,
    trialCheckoutInfo: null,
    isBestValue: false,
    billPeriod: BillPeriod.Monthly,
    planType: PlanType.Premium1,
    tier: PlanTier.Tier3,
    active: false
  },
  {
    slug: 'sub_premium_1_year',
    legacySlugs: null,
    stripeId: '',
    detailDescriptionComp: null,
    checkoutInfo: null,
    trialCheckoutInfo: null,
    isBestValue: false,
    billPeriod: BillPeriod.Annually,
    planType: PlanType.Premium1,
    tier: PlanTier.Tier3,
    active: false
  }
]

const otherPlanConfigs = [
  {
    slug: PlanSlugs.Free,
    legacySlugs: null,
    stripeId: '',
    detailDescriptionComp: null,
    checkoutInfo: null,
    trialCheckoutInfo: null,
    isBestValue: false,
    billPeriod: null,
    planType: PlanType.Free,
    tier: PlanTier.Free,
    active: false
  },
  {
    slug: PlanSlugs.BasicAnnually,
    legacySlugs: null,
    stripeId: '',
    detailDescriptionComp: null,
    checkoutInfo: null,
    trialCheckoutInfo: null,
    isBestValue: false,
    billPeriod: BillPeriod.Annually,
    planType: PlanType.Basic,
    tier: PlanTier.Legacy,
    active: false
  },
  {
    slug: PlanSlugs.EssentialsMonthly,
    legacySlugs: null,
    stripeId: '',
    detailDescriptionComp: EssentialsMonthlyDescription,
    checkoutInfo: null,
    trialCheckoutInfo: null,
    isBestValue: false,
    billPeriod: BillPeriod.Monthly,
    planType: PlanType.Essentials,
    tier: PlanTier.Tier1,
    active: false
  },
  {
    slug: PlanSlugs.EssentialsAnnually,
    legacySlugs: null,
    stripeId: '',
    detailDescriptionComp: EssentialsAnnualDescription,
    checkoutInfo: null,
    trialCheckoutInfo: null,
    isBestValue: true,
    billPeriod: BillPeriod.Annually,
    planType: PlanType.Essentials,
    tier: PlanTier.Tier2,
    active: false
  },
  {
    slug: PlanSlugs.PremiumAnnually,
    legacySlugs: null,
    stripeId: '',
    detailDescriptionComp: PremiumMonthlyDescription,
    checkoutInfo: null,
    trialCheckoutInfo: null,
    isBestValue: false,
    billPeriod: BillPeriod.Annually,
    planType: PlanType.Premium,
    tier: PlanTier.Tier3,
    active: false
  },
  {
    slug: 'sub_party_host_annual_promo',
    legacySlugs: null,
    stripeId: '',
    detailDescriptionComp: null,
    checkoutInfo: null,
    isBestValue: false,
    billPeriod: BillPeriod.Annually,
    planType: PlanType.PartyHost,
    tier: PlanTier.Tier5,
    level: PlanTypeLevel.PartyHost,
    active: false
  }
]

const MembershipPlansConfigStaging = [
  {
    slug: 'sub_invitation_maker_monthly',
    legacySlugs: null,
    stripeId: StripeProductIdsStaging.InvitationMakerMonthly,
    detailDescriptionComp: null,
    checkoutInfo: {
      price: '$3.99',
      checkoutURL: 'https://buy.stripe.com/test_cN22c12WI4p023u8xo'
      // checkoutURL: 'https://buy.stripe.com/test_eVa2c1apa9Jk4bC4hi'
    },
    isBestValue: false,
    billPeriod: BillPeriod.Monthly,
    planType: PlanType.InvitationMaker,
    tier: PlanTier.Free,
    level: PlanTypeLevel.InvitationMaker,
    active: true
  },
  {
    slug: 'sub_invitation_maker_annual',
    legacySlugs: null,
    stripeId: StripeProductIdsStaging.InvitationMakerAnnually,
    detailDescriptionComp: null,
    checkoutInfo: {
      price: '$0.99',
      priceDesc: 'Billed annually at $11.99',
      checkoutURL: 'https://buy.stripe.com/test_aEU8Ap9l64p00ZqbJB',
      discount: calculateDiscount(3.99, 11.99)
    },
    isBestValue: false,
    billPeriod: BillPeriod.Annually,
    planType: PlanType.InvitationMaker,
    tier: PlanTier.Free,
    level: PlanTypeLevel.InvitationMaker,
    active: true
  },
  {
    slug: 'sub_party_host_monthly',
    legacySlugs: null,
    stripeId: StripeProductIdsStaging.PartyHostMonthly,
    detailDescriptionComp: null,
    checkoutInfo: {
      price: '$9.99',
      checkoutURL: 'https://buy.stripe.com/test_fZeaIx54QaNo8rSeVX',
      trialCheckoutURL: 'https://buy.stripe.com/test_3cs5odgNy3kW23u4ha'
    },
    trialCheckoutInfo: null,
    isBestValue: false,
    billPeriod: BillPeriod.Monthly,
    planType: PlanType.PartyHost,
    tier: PlanTier.Tier5,
    level: PlanTypeLevel.PartyHost,
    active: true
  },
  {
    slug: 'sub_party_host_annual',
    legacySlugs: null,
    stripeId: StripeProductIdsStaging.PartyHostAnnually,
    detailDescriptionComp: null,
    checkoutInfo: {
      price: '$4.16',
      priceDesc: 'Billed annually at $49.99',
      discount: calculateDiscount(9.99, 49.99),
      checkoutURL: 'https://buy.stripe.com/test_9AQg2R7cYcVw9vW29c',
      trialCheckoutURL: 'https://buy.stripe.com/test_aEUbMB68U1cO8rSbJD'
    },
    isBestValue: false,
    billPeriod: BillPeriod.Annually,
    planType: PlanType.PartyHost,
    tier: PlanTier.Tier5,
    level: PlanTypeLevel.PartyHost,
    active: true
  },
  {
    slug: 'sub_event_planner_1_month',
    legacySlugs: null,
    stripeId: StripeProductIdsStaging.EventPlannerMonthly,
    detailDescriptionComp: null,
    checkoutInfo: {
      price: '$27.99',
      checkoutURL: 'https://buy.stripe.com/test_3cs9Et9l63kW7nOcNR',
      trialCheckoutURL: 'https://buy.stripe.com/test_9AQ2c168U08KeQgbJE'
    },
    isBestValue: false,
    billPeriod: BillPeriod.Monthly,
    planType: PlanType.EventPlanner,
    tier: PlanTier.Tier5,
    level: PlanTypeLevel.EventPlanner,
    active: true
  },
  {
    slug: 'sub_event_planner_annual',
    legacySlugs: null,
    stripeId: StripeProductIdsStaging.EventPlannerAnnually,
    detailDescriptionComp: null,
    checkoutInfo: {
      price: '$8.33',
      priceDesc: 'Billed annually at $99.99',
      discount: calculateDiscount(27.99, 99.99),
      checkoutURL: 'https://buy.stripe.com/test_9AQcQFeFq7Bc0Zq29e',
      trialCheckoutURL: 'https://buy.stripe.com/test_fZeg2R54QdZAfUk8xt'
    },
    isBestValue: false,
    billPeriod: BillPeriod.Annually,
    planType: PlanType.EventPlanner,
    tier: PlanTier.Tier5,
    level: PlanTypeLevel.EventPlanner,
    active: true
  },
  {
    slug: 'sub_group_organizer_1_month',
    legacySlugs: null,
    stripeId: StripeProductIdsStaging.GroupOrganizerMonthly,
    detailDescriptionComp: null,
    checkoutInfo: {
      price: '$59.99',
      checkoutURL: 'https://buy.stripe.com/test_eVa3g5dBm6x84bC29f',
      trialCheckoutURL: 'https://buy.stripe.com/test_7sIbMBcxig7I6jKcNK'
    },
    isBestValue: false,
    billPeriod: BillPeriod.Monthly,
    planType: PlanType.GroupOrganizer,
    tier: PlanTier.Tier5,
    level: PlanTypeLevel.GroupOrganizer,
    active: true
  },
  {
    slug: 'sub_group_organizer_annual',
    legacySlugs: null,
    stripeId: StripeProductIdsStaging.GroupOrganizerAnnually,
    detailDescriptionComp: null,
    checkoutInfo: {
      price: '$14.99',
      priceDesc: 'Billed annually at $179.99',
      discount: calculateDiscount(59.99, 179.99),
      checkoutURL: 'https://buy.stripe.com/test_28o03T9l64p09vW5ls',
      trialCheckoutURL: 'https://buy.stripe.com/test_7sIeYNbte6x837y9Bz'
    },
    isBestValue: false,
    billPeriod: BillPeriod.Annually,
    planType: PlanType.GroupOrganizer,
    tier: PlanTier.Tier5,
    level: PlanTypeLevel.GroupOrganizer,
    active: true
  },
  // legacy plans section, please set tier to PlanTier.Legacy,
  ...otherPlanConfigs,
  ...legacyPlanConfigs
]

const MembershipPlansConfig = [
  {
    slug: 'sub_invitation_maker_monthly',
    legacySlugs: null,
    stripeId: StripeProductIds.InvitationMakerMonthly,
    checkoutInfo: {
      price: '$3.99',
      checkoutURL: 'https://buy.stripe.com/eVa14A9898l8flK6oL'
    },
    isBestValue: false,
    billPeriod: BillPeriod.Monthly,
    planType: PlanType.InvitationMaker,
    tier: PlanTier.Free,
    level: PlanTypeLevel.InvitationMaker,
    active: true
  },
  {
    slug: 'sub_invitation_maker_annual',
    legacySlugs: null,
    stripeId: StripeProductIds.InvitationMakerAnnually,
    checkoutInfo: {
      price: '$0.99',
      priceDesc: 'Billed annually at $11.99',
      checkoutURL: 'https://buy.stripe.com/3cs6oU3NPbxk8Xm9AY',
      discount: calculateDiscount(3.99, 11.99)
    },
    isBestValue: false,
    billPeriod: BillPeriod.Annually,
    planType: PlanType.InvitationMaker,
    tier: PlanTier.Free,
    level: PlanTypeLevel.InvitationMaker,
    active: true
  },
  {
    slug: 'sub_party_host_monthly',
    legacySlugs: null,
    stripeId: StripeProductIds.PartyHostMonthly,
    checkoutInfo: {
      price: '$9.99',
      checkoutURL: 'https://buy.stripe.com/eVabJe701fNA3D200p',
      trialCheckoutURL: 'https://buy.stripe.com/7sI7sYest9pc8XmcNc'
    },
    trialCheckoutInfo: null,
    isBestValue: false,
    billPeriod: BillPeriod.Monthly,
    planType: PlanType.PartyHost,
    tier: PlanTier.Tier5,
    level: PlanTypeLevel.PartyHost,
    active: true
  },
  {
    slug: 'sub_party_host_annual',
    legacySlugs: null,
    stripeId: StripeProductIds.PartyHostAnnually,
    checkoutInfo: {
      price: '$4.16',
      priceDesc: 'Billed annually at $49.99',
      discount: calculateDiscount(9.99, 49.99),
      checkoutURL: 'https://buy.stripe.com/00g00w5VX30OgpO5kL',
      trialCheckoutURL: 'https://buy.stripe.com/fZebJefwxgREddC00s'
    },
    isBestValue: false,
    billPeriod: BillPeriod.Annually,
    planType: PlanType.PartyHost,
    tier: PlanTier.Tier5,
    level: PlanTypeLevel.PartyHost,
    active: true
  },
  {
    slug: 'sub_event_planner_1_month',
    legacySlugs: null,
    stripeId: StripeProductIds.EventPlannerMonthly,
    checkoutInfo: {
      price: '$27.99',
      checkoutURL: 'https://buy.stripe.com/4gw3cIbgheJwehGcNg',
      trialCheckoutURL: 'https://buy.stripe.com/eVa4gM4RTcBob5u7sV'
    },
    isBestValue: false,
    billPeriod: BillPeriod.Monthly,
    planType: PlanType.EventPlanner,
    tier: PlanTier.Tier5,
    level: PlanTypeLevel.EventPlanner,
    active: true
  },
  {
    slug: 'sub_event_planner_annual',
    legacySlugs: null,
    stripeId: StripeProductIds.EventPlannerAnnually,
    checkoutInfo: {
      price: '$8.33',
      priceDesc: 'Billed annually at $99.99',
      discount: calculateDiscount(27.99, 99.99),
      checkoutURL: 'https://buy.stripe.com/3cs5kQ0BD8l86Pe9B6',
      trialCheckoutURL: 'https://buy.stripe.com/5kAeVqestcBoflKeVp'
    },
    isBestValue: false,
    billPeriod: BillPeriod.Annually,
    planType: PlanType.EventPlanner,
    tier: PlanTier.Tier5,
    level: PlanTypeLevel.EventPlanner,
    active: true
  },
  {
    slug: 'sub_group_organizer_1_month',
    legacySlugs: null,
    stripeId: StripeProductIds.GroupOrganizerMonthly,
    checkoutInfo: {
      price: '$59.99',
      checkoutURL: 'https://buy.stripe.com/dR6fZubgh30Ob5ueVs',
      trialCheckoutURL: 'https://buy.stripe.com/4gw8x25VX58Wa1q9B7'
    },
    isBestValue: false,
    billPeriod: BillPeriod.Monthly,
    planType: PlanType.GroupOrganizer,
    tier: PlanTier.Tier5,
    level: PlanTypeLevel.GroupOrganizer,
    active: true
  },
  {
    slug: 'sub_group_organizer_annual',
    legacySlugs: null,
    stripeId: StripeProductIds.GroupOrganizerAnnually,
    checkoutInfo: {
      price: '$14.99',
      priceDesc: 'Billed annually at $179.99',
      discount: calculateDiscount(59.99, 179.99),
      checkoutURL: 'https://buy.stripe.com/7sIdRmest58W3D26oY',
      trialCheckoutURL: 'https://buy.stripe.com/6oE8x2fwx0SGddC00z'
    },
    isBestValue: false,
    billPeriod: BillPeriod.Annually,
    planType: PlanType.GroupOrganizer,
    tier: PlanTier.Tier5,
    level: PlanTypeLevel.GroupOrganizer,
    active: true
  },
  // legacy plans section, please set tier to PlanTier.Legacy,
  ...otherPlanConfigs,
  ...legacyPlanConfigs
]

const CreditBundlesConfig = [
  {
    creditsNumber: 10,
    slug: 'sub_10_credits',
    priceLabel: '$0.4/Credit',
    subTotalLabel: '$3.99',
    formula: '$0.4 x 10',
    stripeId: StripeProductIds.CreditsBundle10,
    checkoutURL: 'https://buy.stripe.com/00g4gMgABfNA5La28u'
  },
  {
    creditsNumber: 25,
    slug: 'sub_25_credits',
    priceLabel: '$0.37/Credit',
    subTotalLabel: '$9.29',
    formula: '$0.37 x 25',
    stripeId: StripeProductIds.CreditsBundle25,
    checkoutURL: 'https://buy.stripe.com/fZe28EdopeJw8XmdRb'
  },
  {
    creditsNumber: 50,
    slug: 'sub_50_credits',
    priceLabel: '$0.35/Credit',
    subTotalLabel: '$17.49',
    formula: '$0.35 x 50',
    stripeId: StripeProductIds.CreditsBundle50,
    checkoutURL: 'https://buy.stripe.com/fZe9B61FH1WKa1qaEY'
  },
  {
    creditsNumber: 100,
    slug: 'sub_100_credits',
    priceLabel: '$0.33/Credit',
    subTotalLabel: '$32.99',
    formula: '$0.33 x 100',
    stripeId: StripeProductIds.CreditsBundle100,
    checkoutURL: 'https://buy.stripe.com/eVafZu0BDatggpO4gz'
  },
  {
    creditsNumber: 200,
    slug: 'sub_200_credits',
    priceLabel: '$0.28/Credit',
    subTotalLabel: '$55.99',
    formula: '$0.28 x 200',
    stripeId: StripeProductIds.CreditsBundle200,
    checkoutURL: 'https://buy.stripe.com/bIYcNi845eJw6Pe8wO'
  },
  {
    creditsNumber: 300,
    slug: 'sub_300_credits',
    priceLabel: '$0.24/Credit',
    subTotalLabel: '$71.99',
    formula: '$0.24 x 300',
    stripeId: StripeProductIds.CreditsBundle300,
    checkoutURL: 'https://buy.stripe.com/fZe4gMdopcBo6PecN3'
  },
  {
    creditsNumber: 400,
    slug: 'sub_400_credits',
    priceLabel: '$0.21/Credit',
    subTotalLabel: '$83.99',
    formula: '$0.21 x 400',
    stripeId: StripeProductIds.CreditsBundle400,
    checkoutURL: 'https://buy.stripe.com/fZedRm0BD58Wb5u7sI'
  },
  {
    creditsNumber: 500,
    slug: 'sub_500_credits',
    priceLabel: '$0.19/Credit',
    subTotalLabel: '$94.99',
    formula: '$0.19 x 500',
    stripeId: StripeProductIds.CreditsBundle500,
    checkoutURL: 'https://buy.stripe.com/fZedRm0BDgRE7TicN1'
  },
  {
    creditsNumber: 1000,
    slug: 'sub_1000_credits',
    priceLabel: '$0.12/Credit',
    subTotalLabel: '$118.99',
    formula: '$0.12 x 1000',
    stripeId: StripeProductIds.CreditsBundle1000,
    checkoutURL: 'https://buy.stripe.com/5kA3cI5VXdFs1uU5ky'
  }
]

const CreditBundlesConfigStaging = [
  {
    creditsNumber: 10,
    slug: 'sub_10_credits',
    priceLabel: '$0.4/Credit',
    subTotalLabel: '$3.99',
    formula: '$0.4 x 10',
    stripeId: StripeProductIdsStaging.CreditsBundle10,
    checkoutURL: 'https://buy.stripe.com/test_eVa3g5fJu5t4eQg00O'
    // checkoutURL: 'https://buy.stripe.com/test_8wM9Et7cYf3EaA0g08'
  },
  {
    creditsNumber: 25,
    slug: 'sub_25_credits',
    priceLabel: '$0.37/Credit',
    subTotalLabel: '$9.29',
    formula: '$0.37 x 25',
    stripeId: StripeProductIdsStaging.CreditsBundle25,
    checkoutURL: 'https://buy.stripe.com/test_7sIdUJfJu4p08rS4h3'
  },
  {
    creditsNumber: 50,
    slug: 'sub_50_credits',
    priceLabel: '$0.35/Credit',
    subTotalLabel: '$17.49',
    formula: '$0.35 x 50',
    stripeId: StripeProductIdsStaging.CreditsBundle50,
    checkoutURL: 'https://buy.stripe.com/test_fZeaIx40M3kWaA08xi'
  },
  {
    creditsNumber: 100,
    slug: 'sub_100_credits',
    priceLabel: '$0.33/Credit',
    subTotalLabel: '$32.99',
    formula: '$0.33 x 100',
    stripeId: StripeProductIdsStaging.CreditsBundle100,
    checkoutURL: 'https://buy.stripe.com/test_fZebMB68U08KgYo00L'
  },
  {
    creditsNumber: 200,
    slug: 'sub_200_credits',
    priceLabel: '$0.28/Credit',
    subTotalLabel: '$55.99',
    formula: '$0.28 x 200',
    stripeId: StripeProductIdsStaging.CreditsBundle200,
    checkoutURL: 'https://buy.stripe.com/test_3cs8Ap0OA7BcfUkcNw'
  },
  {
    creditsNumber: 300,
    slug: 'sub_300_credits',
    priceLabel: '$0.24/Credit',
    subTotalLabel: '$71.99',
    formula: '$0.24 x 300',
    stripeId: StripeProductIdsStaging.CreditsBundle300,
    checkoutURL: 'https://buy.stripe.com/test_dR67wl8h2g7I0ZqbJr'
  },
  {
    creditsNumber: 400,
    slug: 'sub_400_credits',
    priceLabel: '$0.21/Credit',
    subTotalLabel: '$83.99',
    formula: '$0.21 x 400',
    stripeId: StripeProductIdsStaging.CreditsBundle400,
    checkoutURL: 'https://buy.stripe.com/test_eVa4k92WI2gScI8cNu'
  },
  {
    creditsNumber: 500,
    slug: 'sub_500_credits',
    priceLabel: '$0.19/Credit',
    subTotalLabel: '$94.99',
    formula: '$0.19 x 500',
    stripeId: StripeProductIdsStaging.CreditsBundle500,
    checkoutURL: 'https://buy.stripe.com/test_3csdUJeFqg7I0Zq5l1'
  },
  {
    creditsNumber: 1000,
    slug: 'sub_1000_credits',
    priceLabel: '$0.12/Credit',
    subTotalLabel: '$118.99',
    formula: '$0.12 x 1000',
    stripeId: StripeProductIdsStaging.CreditsBundle1000,
    checkoutURL: 'https://buy.stripe.com/test_fZe5odgNy2gS4bC7t8'
  }
]
/** Stripe Checkout Configs **/

export const getActivePlans = () => {
  return getMembershipPlanConfigs().filter(p => p.active)
}

export const getLegacyPlans = () => {
  return legacyPlanConfigs
}

export const getPlanBySlug = (slug, key) => {
  const plan = getMembershipPlanConfigs().find(p => {
    if (p.slug === slug) {
      return true
    } else {
      return p.legacySlugs && p.legacySlugs.includes(slug)
    }
  })

  return plan ? (key ? plan[key] : plan) : null
}

export const getPlanTypeBySlug = slug => {
  const found = getPlanBySlug(slug)
  if (found) {
    return found.planType
  } else {
    return null
  }
}

export const getCheckoutUrlByFreeTrial = (plan, isCanTrial) => {
  const { checkoutURL, trialCheckoutURL } = plan.checkoutInfo
  return isCanTrial && trialCheckoutURL ? trialCheckoutURL : checkoutURL
}

export const buildCheckoutURLForPlans = (plan, isCanTrial, userId, utmCode) => {
  const checkoutURL = getCheckoutUrlByFreeTrial(plan, isCanTrial)
  return `${checkoutURL}?client_reference_id=${userId}&utm_source=web&utm_content=${utmCode}`
}

export const buildCheckoutURLForCreditsBundle = (plan, userId, utmCode) => {
  return `${plan.checkoutURL}?client_reference_id=${userId}&utm_source=web&utm_content=${utmCode}`
}

export const storeUTMDataByKey = (subscription, key) => {
  const data = {
    planSlug: subscription.plan?.slug,
    credits: subscription.available_credit
  }
  const utmData = {}
  utmData[key] = data
  persistUTMData(utmData)
}

export const getUTMDataByKey = key => {
  const utmData = getPersistedUTMData()
  if (utmData) {
    return utmData[key]
  } else {
    return null
  }
}

export const clearUTMData = () => {
  window.localStorage.removeItem(UTM_INFO_STORE_KEY)
}

const getPersistedUTMData = () => {
  try {
    return JSON.parse(window.localStorage.getItem(UTM_INFO_STORE_KEY))
  } catch (e) {
    console.warn('failed to get UTM info', e)
    return null
  }
}

const persistUTMData = data => {
  try {
    window.localStorage.setItem(UTM_INFO_STORE_KEY, JSON.stringify(data))
  } catch (e) {
    console.warn('failed to store UTM info', e)
  }
}

export const getMembershipPlanConfigs = () => {
  if (isProdReactEnvironment()) {
    return MembershipPlansConfig
  } else {
    return MembershipPlansConfigStaging
  }
}

export const getCreditsConfigs = () => {
  if (isProdReactEnvironment()) {
    return CreditBundlesConfig
  } else {
    return CreditBundlesConfigStaging
  }
}
