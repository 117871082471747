import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { Drawer } from 'antd'
import PropTypes from 'prop-types'
import { routerActions } from 'react-router-redux'
import MenuIcon from 'assets/icons/menu-icon.svg'
import MenuIconWhite from 'assets/icons/menu-icon-white.svg'
import HobnobLogoWhite from 'assets/hobnob-logo-white.svg'
import HobnobLogo from 'assets/hobnob-logo.svg'
import BackIcon from 'assets/icons/back_button.png'
import CloseIcon from 'src/assets/icons/close-icon.png'
import CloseWhite from 'assets/icons/close_white.png'
import { PanelSlug } from '../MenuBar/panels/PanelContainer'
import { useSelector, useDispatch } from 'react-redux'
import { updateMenuPanel } from 'src/actions/frontend'
import { fetchUserGQ } from 'src/actions/user'
import { userSelector, activeMenuPanelSelector } from 'src/selectors'
import ProfilePanel from './ProfilePanel/ProfilePanel'
import LoginPanel from 'components/LoginModal/LoginPanel/LoginPanel'
import SvgIcon from 'components/common/SvgIcon'
import cn from 'classnames'
import styles from './header-bar.scss'

const HeaderBar = forwardRef((props, ref) => {
  const {
    left,
    right,
    children,
    width = 20,
    containerClassName,
    whiteIcon,
    menuIcon,
    backIcon = true,
    closeIcon,
    onBack = () => history.back(),
    onClickTitle
  } = props
  const [isHeaderSliderVisible, setIsHeaderSliderVisible] = useState(false)
  const activeMenuPanel = useSelector(activeMenuPanelSelector)
  const user = useSelector(userSelector)
  const dispatch = useDispatch()

  useImperativeHandle(ref, () => ({
    onSetIsHeaderSliderVisible: setIsHeaderSliderVisible
  }))

  const handleOpenMenuPanel = () => {
    dispatch(updateMenuPanel(user ? PanelSlug.Profile : PanelSlug.Login))
    setIsHeaderSliderVisible(true)
  }

  const handleCloseMenuPanel = () => {
    setIsHeaderSliderVisible(false)
    setTimeout(() => {
      dispatch(updateMenuPanel(null))
    }, 200)
  }

  useEffect(() => {
    if (isHeaderSliderVisible && activeMenuPanel === PanelSlug.Profile) {
      dispatch(fetchUserGQ())
    }
  }, [activeMenuPanel])

  const renderLeftComponent = () => {
    if (left) {
      return left
    }

    if (closeIcon) {
      return (
        <img
          onClick={onBack}
          width={14}
          className={styles['back-icon']}
          src={whiteIcon ? CloseWhite : CloseIcon}
        />
      )
    }

    if (menuIcon) {
      return (
        <SvgIcon
          icon={whiteIcon ? MenuIconWhite : MenuIcon}
          width={18}
          height={18}
          onClick={handleOpenMenuPanel}
        />
      )
    }

    if (backIcon) {
      return <img onClick={onBack} width="18" className={styles['back-icon']} src={BackIcon} />
    }
  }

  const renderTitle = () => {
    if (children) {
      return children
    }

    const handleClickTitle = () => {
      if (onClickTitle) {
        onClickTitle()
        return
      }
      dispatch(routerActions.push('/home'))
    }

    return (
      <SvgIcon
        className={styles['hobnob-logo']}
        icon={whiteIcon ? HobnobLogoWhite : HobnobLogo}
        onClick={handleClickTitle}
      />
    )
  }

  const renderPanel = () => {
    switch (activeMenuPanel) {
      case PanelSlug.Login:
        return (
          <div className={cn(styles['login-panel-container'])}>
            <LoginPanel onClose={handleCloseMenuPanel} />
          </div>
        )

      case PanelSlug.Profile:
        return <ProfilePanel onClose={handleCloseMenuPanel} />
    }
  }

  return (
    <div className={cn(styles['header-bar-container'], containerClassName)}>
      <div className={styles['header-bar-nav']}>
        <div style={{ width }}>{renderLeftComponent()}</div>
        <div className={styles['header-title']}>{renderTitle()}</div>
        <div style={{ width, textAlign: 'right' }}>{right}</div>
      </div>

      <Drawer
        width="90%"
        bodyStyle={{ padding: 0 }}
        placement="left"
        closable={false}
        onClose={handleCloseMenuPanel}
        open={isHeaderSliderVisible}
      >
        {renderPanel()}
      </Drawer>
    </div>
  )
})

HeaderBar.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  children: PropTypes.node,
  width: PropTypes.number,
  containerClassName: PropTypes.string,
  whiteIcon: PropTypes.bool,
  menuIcon: PropTypes.bool,
  backIcon: PropTypes.bool,
  closeIcon: PropTypes.bool,
  onBack: PropTypes.func,
  onClickTitle: PropTypes.func
}

export default HeaderBar
