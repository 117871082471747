import React, { Component } from 'react'
import { Skeleton, Space } from 'antd'

class CommunitySkeleton extends Component {
  render() {
    return (
      <div style={{ padding: '2px' }}>
        {[1, 2, 3].map(item => (
          <div key={item}>
            <Skeleton.Input active block />
            <br />
            <br />
          </div>
        ))}

        {[4, 5, 6].map(item => (
          <div key={item}>
            <Space>
              <Skeleton.Avatar shape="square" active />
              <Skeleton.Input active block />
            </Space>
            <br />
            <br />
          </div>
        ))}
        <br />
        <br />
        <div style={{ height: '40px' }} />
        {[7, 8, 9].map(item => (
          <div key={item}>
            <Space>
              <Skeleton.Avatar shape="square" active />
              <Skeleton.Input active block />
            </Space>
            <br />
            <br />
          </div>
        ))}
      </div>
    )
  }
}

export default CommunitySkeleton
