import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { meetingInProgress, timeUntilMeetingStarts } from 'src/services/event_helpers.js'

import EventLink from './EventLink.jsx'
import SvgIcon from 'src/components/common/SvgIcon'

import LiveIcon from 'src/assets/icons/live_tag.svg'

import styles from './join-meeting-section.scss'

export default class JoinMeetingSection extends Component {
  myRef = null

  componentDidMount () {
    const { event, scrollTo } = this.props
    const timeRemaining = timeUntilMeetingStarts(event)

    if (scrollTo) {
      scrollTo(this.myRef)
    }

    if (timeRemaining > 0) {
      setTimeout(() => {
        this.forceUpdate()
      }, timeRemaining * 1000 + 30)
    }
  }

  _saveRef = (ref) => {
    this.myRef = ref
  }

  _renderIcon () {
    const { event } = this.props
    if (!meetingInProgress(event)) {
      return null
    }

    return (
      <SvgIcon icon={LiveIcon} className={styles['live-icon']} />
    )
  }

  render () {
    const { event } = this.props
    if (!event.meeting_link) { return null }

    return (
      <div>
        <div className={styles['spacer']} />
        <div className={styles['container']} ref={this._saveRef}>
          {this._renderIcon()}
          <div className={styles['cell-title']}>
            Join Meeting
          </div>
          <EventLink link={event.meeting_link} isMeetingLink />
        </div>
      </div>
    )
  }
}

JoinMeetingSection.propTypes = {
  event: PropTypes.object.isRequired,
  scrollTo: PropTypes.func
}
