export const HIDE_LOADING_SCREEN = 'HIDE_LOADING_SCREEN'
export const SHOW_LOADING_SCREEN = 'SHOW_LOADING_SCREEN'
export const SHOW_LOADING_ICON = 'SHOW_LOADING_ICON'
export const HIDE_LOADING_ICON = 'HIDE_LOADING_ICON'
export const SHOW_FLOATING_BUTTON = 'SHOW_FLOATING_BUTTON'
export const HIDE_FLOATING_BUTTON = 'HIDE_FLOATING_BUTTON'

export const UPDATE_EVENT_COMMENT = 'UPDATE_EVENT_COMMENT'
export const SUBMIT_EVENT_COMMENT = 'SUBMIT_EVENT_COMMENT'

export const SHOW_HUD = 'SHOW_HUD'
export const HIDE_HUD = 'HIDE_HUD'

export const HIDE_TOOLTIP = 'HIDE_TOOLTIP'

// share Modals
export const SHOW_SHARE_MODAL = 'SHOW_SHARE_MODAL'
export const HIDE_SHARE_MODAL = 'HIDE_SHARE_MODAL'

// Menu
export const SHOW_MENU_PANEL = 'SHOW_MENU_PANEL'
export const HIDE_MENU_PANEL = 'HIDE_MENU_PANEL'
export const CHANGE_SELECTED_MENU_PANEL = 'CHANGE_SELECTED_MENU_PANEL'

// Modals
export const SHOW_RSVP_MODAL = 'SHOW_RSVP_MODAL'
export const HIDE_RSVP_MODAL = 'HIDE_RSVP_MODAL'
export const CHANGE_RSVP_STATUS = 'CHANGE_RSVP_STATUS'
export const HAS_CHANGED_RSVP_STATUS = 'HAS_CHANGED_RSVP_STATUS'

export const SHOW_MAP_MODAL = 'SHOW_MAP_MODAL'
export const HIDE_MAP_MODAL = 'HIDE_MAP_MODAL'

export const SHOW_MESSAGE_MODAL = 'SHOW_MESSAGE_MODAL'
export const HIDE_MESSAGE_MODAL = 'HIDE_MESSAGE_MODAL'
export const SHOW_MESSAGE_SENT = 'SHOW_MESSAGE_SENT'

export const SHOW_IMAGE_MODAL = 'SHOW_IMAGE_MODAL'
export const HIDE_IMAGE_MODAL = 'HIDE_IMAGE_MODAL'

// accepted, declined modal
export const SHOW_BRING_GUEST_MODAL = 'SHOW_BRING_GUEST_MODAL'
export const SHOW_GUEST_COUNT_CONTROLS_MODAL = 'SHOW_GUEST_COUNT_CONTROLS_MODAL'
export const SHOW_ACCEPTED_SEND_MESSAGE_MODAL = 'SHOW_ACCEPTED_SEND_MESSAGE_MODAL'
export const SHOW_DECLINED_SEND_MESSAGE_MODAL = 'SHOW_DECLINED_SEND_MESSAGE_MODAL'

export const SET_TOTAL_ATTENDING = 'SET_TOTAL_ATTENDING'

export function hideLoadingScreen() {
  return {
    type: HIDE_LOADING_SCREEN
  }
}

export function showLoadingScreen() {
  return {
    type: SHOW_LOADING_SCREEN
  }
}

export function showLoadingIcon() {
  return { type: SHOW_LOADING_ICON }
}

export function hideLoadingIcon() {
  return { type: HIDE_LOADING_ICON }
}

export function showFloatingButton() {
  return {
    type: SHOW_FLOATING_BUTTON
  }
}
export function hideFloatingButton() {
  return {
    type: HIDE_FLOATING_BUTTON
  }
}

export function updateEventComment(content) {
  return {
    type: UPDATE_EVENT_COMMENT,
    content
  }
}

export function submitEventComment(content) {
  return {
    type: SUBMIT_EVENT_COMMENT,
    content
  }
}

export function showHud(hudType, message, callbackAction = () => {}) {
  return {
    type: SHOW_HUD,
    hudType,
    message,
    callbackAction
  }
}
export function hideHud() {
  return {
    type: HIDE_HUD
  }
}

export function hideTooltip() {
  return {
    type: HIDE_TOOLTIP
  }
}

// ////////////////
// Modals
// ////////////////
export function showShareModal(content) {
  return { type: SHOW_SHARE_MODAL, content }
}

export function hideShareModal() {
  return { type: HIDE_SHARE_MODAL }
}

// ////////////////
// Menu
// ////////////////

export function showMenuPanel() {
  return {
    type: SHOW_MENU_PANEL
  }
}
// canceled = RSVP modal was hidden due to an error (typically this means an error recording the RSVP)
export function hideMenuPanel() {
  return {
    type: HIDE_MENU_PANEL
  }
}

export function updateMenuPanel(panel = null) {
  return {
    type: CHANGE_SELECTED_MENU_PANEL,
    panel
  }
}

// ////////////////
// Modals
// ////////////////

export function showRsvpModal() {
  return {
    type: SHOW_RSVP_MODAL
  }
}
// canceled = RSVP modal was hidden due to an error (typically this means an error recording the RSVP)
export function hideRsvpModal(canceled = false) {
  return {
    type: HIDE_RSVP_MODAL,
    canceled
  }
}

export function updateChangeRsvpStatus({ changeRsvp = true } = {}) {
  return {
    type: CHANGE_RSVP_STATUS,
    changeRsvp
  }
}

export function showDeclinedSendMessageModal() {
  return {
    type: SHOW_DECLINED_SEND_MESSAGE_MODAL
  }
}

export function showGuestCountControlsModal() {
  return {
    type: SHOW_GUEST_COUNT_CONTROLS_MODAL
  }
}

export function showInvitationReplyFormModal() {
  return {
    type: SHOW_ACCEPTED_SEND_MESSAGE_MODAL
  }
}

export function hasChangedRsvpStatus() {
  return {
    type: HAS_CHANGED_RSVP_STATUS
  }
}

export function showMapModal() {
  return {
    type: SHOW_MAP_MODAL
  }
}
export function hideMapModal() {
  return {
    type: HIDE_MAP_MODAL
  }
}

export function showMessageModal(data) {
  return {
    type: SHOW_MESSAGE_MODAL,
    messageHost: false,
    messageTitle: data ? data.title : '',
    messageSubTitle: data ? data.subTitle : ''
  }
}
export function showHostMessageModal(data) {
  return {
    type: SHOW_MESSAGE_MODAL,
    messageHost: true,
    messageTitle: data ? data.title : '',
    messageSubTitle: data ? data.subTitle : ''
  }
}
export function showMessageSent(data) {
  return {
    type: SHOW_MESSAGE_SENT,
    messageTitle: data ? data.title : '',
    messageSubTitle: data ? data.subTitle : ''
  }
}
export function hideHostMessageModal() {
  return {
    type: HIDE_MESSAGE_MODAL
  }
}

export function showImageModal(url) {
  return {
    type: SHOW_IMAGE_MODAL,
    url
  }
}
export function hideImageModal() {
  return {
    type: HIDE_IMAGE_MODAL
  }
}

export function setTotalAttending(params) {
  return {
    type: SET_TOTAL_ATTENDING,
    params
  }
}

export const SHOW_OPEN_IN_APP_MODAL = 'SHOW_OPEN_IN_APP_MODAL'
export function showOpenInAppModal() {
  return { type: SHOW_OPEN_IN_APP_MODAL }
}

export const HIDE_OPEN_IN_APP_MODAL = 'HIDE_OPEN_IN_APP_MODAL'
export function hideOpenInAppModal() {
  return { type: HIDE_OPEN_IN_APP_MODAL }
}

export const SHOW_MODAL_POLL_LOGIN = 'SHOW_MODAL_POLL_LOGIN'
export function showModalPollLogin() {
  return { type: SHOW_MODAL_POLL_LOGIN }
}

export const HIDE_MODAL_POLL_LOGIN = 'HIDE_MODAL_POLL_LOGIN'
export function hideModalPollLogin() {
  return { type: HIDE_MODAL_POLL_LOGIN }
}

export const SHOW_SEND_UPDATE_MODAL = 'SHOW_SEND_UPDATE_MODAL'
export function showSendUpdateModal() {
  return { type: SHOW_SEND_UPDATE_MODAL }
}

export const HIDE_SEND_UPDATE_MODAL = 'HIDE_SEND_UPDATE_MODAL'
export function hideSendUpdateModal() {
  return { type: HIDE_SEND_UPDATE_MODAL }
}

export const SHOW_BANNER = 'SHOW_BANNER'
export function showBanner() {
  return { type: SHOW_BANNER }
}

export const HIDE_BANNER = 'HIDE_BANNER'
export function hideBanner() {
  return { type: HIDE_BANNER }
}

export const SET_PREVIEW_GALLERIES = 'SET_PREVIEW_GALLERIES'
export const setPreviewGalleries = galleries => ({
  type: SET_PREVIEW_GALLERIES,
  galleries
})

export const SET_PREVIEW_INDEX = 'SET_PREVIEW_INDEX'
export const setPreviewIndex = index => ({
  type: SET_PREVIEW_INDEX,
  index
})
