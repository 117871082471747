import { call, put } from 'redux-saga/effects'

export function * uploadFileToS3Worker (xhr, onProgressActionCreator, onSuccessActions, onErrorActions, onSuccessCallback) {
  try {
    while (true) {
      const progress = yield call(xhr.nextProgressEvent)
      if (onProgressActionCreator) {
        yield put(onProgressActionCreator(progress))
      }
      if (progress >= 1) break
    }

    if (onSuccessCallback) {
      yield call(onSuccessCallback)
    }

    for (let i = 0; i < onSuccessActions.length; i++) {
      yield put(onSuccessActions[i])
    }
  } catch (e) {
    console.warn('uploadFileToS3Worker error', e)
    for (let i = 0; i < onErrorActions.length; i++) {
      yield put(onErrorActions[i])
    }
  }
}
