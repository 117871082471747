import React, { Component } from 'react'

import Switch from 'react-switch'

import CloseIcon from 'assets/icons/close-icon.png'
import DatePickerIcon from 'assets/icons/date-picker-icon.png'
import styles from './setting-wrapper.scss'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { changeSignUpForm } from 'src/actions/timepoll'
import DateSelector from 'src/containers/event/CreateEvent/FirstStep/DateModal/DateSelector/DateSelector'

import 'assets/styles/date-range/default.css'
import 'assets/styles/date-range/styles.css'

class SettingWrapper extends Component {
  state = {
    showCalendar: false
  }

  handleChange = (type, value) => {
    const { onChangeSignUpForm } = this.props

    switch (type) {
      case 'email':
        onChangeSignUpForm({ email_require: value })
        break
      case 'ends_at_enabled':
        if (value) {
          onChangeSignUpForm({
            ends_at: moment().add(1, 'day').hour(0).minute(0).toJSON()
          })
        } else {
          onChangeSignUpForm({ ends_at: null })
        }
        break
      case 'ends_at':
        let { hour, minute } = value.time
        const formatDate = moment(value.date).format('YYYY-MM-DD')

        if (value.time.type === 'AM') {
          hour = hour === '12' ? '0' : hour
        } else {
          hour = hour === '12' ? hour : Number(hour) + 12
        }
        onChangeSignUpForm({
          ends_at: moment(`${formatDate} ${hour}:${minute}`, moment.defaultFormat).toJSON()
        })
        this.setState({ showCalendar: false })
        break
    }
  }

  render() {
    const { onClose, signUpForm } = this.props

    return (
      <div className={styles['setting-wrapper']}>
        <div className={styles['setting-header']}>
          <span>settings</span>
          <img src={CloseIcon} width="18" onClick={onClose} />
        </div>
        <div className={styles['option-wrapper']}>
          <div className={styles['option-item']}>
            <div className={styles['label-wrapper']}>
              <div className={styles['label-text']}>require email</div>
            </div>
            <Switch
              onChange={checked => this.handleChange('email', checked)}
              checked={signUpForm.email_require}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#2cd4ae"
              offColor="#ffffff"
              width={50}
              height={30}
            />
          </div>

          <div className={styles['option-item']}>
            <div className={styles['label-wrapper']}>
              <div className={styles['label-text']}>end date of sign up</div>
              <div className={styles['label-explain']}>Cut off signups after this time</div>
              {!!signUpForm.ends_at && (
                <div
                  className={styles['label-value']}
                  onClick={() => this.setState({ showCalendar: true })}
                >
                  <span>{moment(signUpForm.ends_at).format('ddd, MMM DD, YYYY h:mm A')}</span>
                  <img src={DatePickerIcon} width="14" />
                </div>
              )}
            </div>
            <Switch
              onChange={checked => this.handleChange('ends_at_enabled', checked)}
              checked={!!signUpForm.ends_at}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#2cd4ae"
              offColor="#ffffff"
              width={50}
              height={30}
            />
          </div>
        </div>

        <DateSelector
          visible={this.state.showCalendar}
          onCancel={() => this.setState({ showCalendar: false })}
          onSave={(date, time) => {
            this.handleChange('ends_at', { date, time })
          }}
          initialInputTime={signUpForm.ends_at}
        />
      </div>
    )
  }
}

SettingWrapper.propTypes = {
  onClose: PropTypes.func,
  signUpForm: PropTypes.object,
  onChangeSignUpForm: PropTypes.func
}

function mapStateToProps(state) {
  return {
    signUpForm: state.frontend.signUpForm
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeSignUpForm: params => dispatch(changeSignUpForm(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingWrapper)
