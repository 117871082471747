import { connect } from 'react-redux'

import EventLinks from './EventLinks'

import {
  activeLinksSelector
} from 'src/selectors'

function mapStateToProps (state) {
  return {
    links: activeLinksSelector(state)
  }
}

export default connect(mapStateToProps)(EventLinks)
