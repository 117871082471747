import jsCookie from 'js-cookie'

export function setTokens ({ access_token, refresh_token, expires_in, expires_at }) {
  // Update cookie with access token and refresh token
  let expirationDate = new Date(expires_at)

  if (!expires_at) {
    const today = new Date()
    expirationDate = new Date(today.getTime() + (expires_in * 1000))
  }

  if (access_token && refresh_token && expirationDate) {
    jsCookie.set('access_token', access_token, { expires: expirationDate })
    jsCookie.set('refresh_token', refresh_token)
    jsCookie.set('expiration_date', expirationDate.toString())
  } else {
    console.error('unable to set tokens in cookie')
  }
}

export function clearTokens () {
  jsCookie.remove('access_token')
  jsCookie.remove('refresh_token')
  jsCookie.remove('expiration_date')
}

export function hasUserAccessToken () {
  return (typeof document !== 'undefined') && !!jsCookie.get('refresh_token')
}

export function getAccessToken () {
  return jsCookie.get('access_token')
}

export function getRefreshToken () {
  return jsCookie.get('refresh_token')
}

export function getTokenExpirationDate () {
  return new Date(jsCookie.get('expiration_date'))
}

export function tokenNeedsRefresh () {
  return new Date() > getTokenExpirationDate()
}

export function isInvalidTokenError (wwwAuthenticateHeader) {
  if (!wwwAuthenticateHeader) return false
  return wwwAuthenticateHeader.indexOf('error="invalid_token"') > -1
}
