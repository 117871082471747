import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { CloseOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { Button } from 'antd'

import styles from './popup-confirm.scss'

const PopupConfirm = forwardRef((props, ref) => {
  const {
    children,
    reverse,
    title,
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    onConfirm,
    onCancel
  } = props
  const dialogRef = useRef(null)

  useImperativeHandle(ref, () => ({
    open: () => dialogRef.current.showModal(),
    close: () => dialogRef.current.close()
  }))

  const handleCancel = () => {
    onCancel?.()
    dialogRef.current.close()
  }

  const handleConfirm = () => {
    onConfirm?.()
    dialogRef.current.close()
  }

  return (
    <dialog ref={dialogRef} className={styles['dialog-container']}>
      <header className={cn(styles['header'], title && styles['header-border'])}>
        {title}
        <CloseOutlined className={styles['close-icon']} onClick={() => dialogRef.current.close()} />
      </header>
      <section className={styles['content']}>{children}</section>

      <footer className={cn(styles['footer'], reverse && styles['footer-reverse'])}>
        <Button
          size="large"
          className={cn(styles['button-inner'], styles['button-cancel'])}
          onClick={handleCancel}
        >
          {cancelText}
        </Button>
        <Button
          size="large"
          type="primary"
          className={cn(styles['button-inner'], styles['button-confirm'])}
          onClick={handleConfirm}
        >
          {confirmText}
        </Button>
      </footer>
    </dialog>
  )
})

PopupConfirm.propTypes = {
  reverse: PropTypes.bool,
  title: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  children: PropTypes.node,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}

export default PopupConfirm
