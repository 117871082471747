import PropTypes from 'prop-types'
import React from 'react'
import { CSSTransitionGroup } from 'react-transition-group' // ES6

import LoadingIndicator from './LoadingIndicator'

import styles from './overlay-loading-indicator.scss'

/*
  Overlay loading indicator. Covers the container with a loading indicator
  Requires the container to have 'position: relative'
*/
export default function OverlayLoadingIndicator (props) {
  const { dark, light, loading, size, style } = props

  const styleName = dark
    ? 'dark-container'
    : light
      ? 'light-container'
      : 'container'

  const loadingIndicator = (
    <div key='loading-indicator' className={styles[styleName]} style={style}>
      <LoadingIndicator size={size || '30px'} />
    </div>
  )

  return (
    <div>
      {loading ? (
        <CSSTransitionGroup
          component='div'
          transitionName={styles}
          transitionEnterTimeout={0}
          transitionLeaveTimeout={1300}
        >
          {loading ? loadingIndicator : null}
        </CSSTransitionGroup>
      ) : null}
    </div>
  )
}

OverlayLoadingIndicator.propTypes = {
  dark: PropTypes.bool,
  light: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.string,
  style: PropTypes.object
}

OverlayLoadingIndicator.defaultProps = {
  dark: false,
  light: false
}
