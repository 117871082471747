import React from 'react'
import cn from 'classnames'
import styles from './description-components.scss'
import EssentialAnnuallyImage from 'src/assets/checkout-membership-essentials-annually-image.png'
import EssentialMonthlyImage from 'src/assets/checkout-membership-essentials-monthly-image.png'
import PremiumImage from 'src/assets/checkout-membership-premium-image.png'
import CreditImage from 'src/assets/checkout-credit-description-image.png'
import NoMembershipImage from 'src/assets/checkout-no-membership.png'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

export const GreenTick = () => {
  return <CheckOutlined style={{ color: '#2CD4AE', marginRight: 10 }} />
}

export const PurpleTick = () => {
  return <CheckOutlined style={{ color: '#9013FE', marginRight: 10 }} />
}

export const BlueTick = () => {
  return <CheckOutlined style={{ color: '#6B87FF', marginRight: 10 }} />
}

export const RedCross = () => {
  return <CloseOutlined style={{ color: '#FE4259', marginRight: 10 }} />
}

// eslint-disable-next-line react/prop-types
export const ColorTick = ({ color }) => {
  return <CheckOutlined style={{ color: color, marginRight: 10 }} />
}

export const EssentialsMonthlyDescription = () => {
  return (<main className={cn(styles['essential-monthly-plan-description-main'], styles['plan-description'])}>
    <p>For the savvy Host who wants ultimate efficiency and style</p>
    <img alt="essentials plan" src={EssentialMonthlyImage} height='152' />
    <ul>
      <li><GreenTick /><b>20</b> Smart Invite Credits included for one-tap “Send” to your whole guest list</li>
      <li><GreenTick />Track Views and Delivery Time Stamps of your Smart Invites</li>
      <li><GreenTick />Send Instant Event Updates</li>
      <li><GreenTick />Unlock ALL Premium Designs</li>
      <li><GreenTick /><b>UNLIMITED</b> Active Polls & Lists</li>
      <li><GreenTick />Sell tickets</li>
      <li><GreenTick />Free sharing via email, social, and your own SMS</li>
    </ul>
  </main>)
}
export const EssentialsAnnualDescription = () => {
  return (<main className={cn(styles['essential-annual-plan-description-main'], styles['plan-description'])}>
    <p>For the savvy Host who wants ultimate efficiency and style. <b>60</b> Smart Invite Credits</p>
    <img alt="essentials plan" src={EssentialAnnuallyImage} height='152' />
    <ul>
      <li><GreenTick /><strike>20</strike> <b>60</b> Smart Invite Credits included for one-tap “Send” to your whole guest list</li>
      <li><GreenTick />Track Views and Delivery Time Stamps of your Smart Invites</li>
      <li><GreenTick />Send Instant Event Updates</li>
      <li><GreenTick />Unlock ALL Premium Designs</li>
      <li><GreenTick /><b>UNLIMITED</b> Active Polls & Lists</li>
      <li><GreenTick />Sell tickets</li>
      <li><GreenTick />Free sharing via email, social, and your own SMS</li>
    </ul>
  </main>)
}

export const PremiumMonthlyDescription = () => {
  return (<main className={cn(styles['premium-plan-description-main'], styles['plan-description'])}>
    <p>For the savvy Host who wants ultimate efficiency and style. All-inclusive <b>300</b> credits</p>
    <img alt="premium plan" src={PremiumImage} height='164' />
    <ul>
      <li><PurpleTick /><strike>60</strike> <b>300</b> Smart Invite Credits included for one-tap “Send” to your whole guest list</li>
      <li><PurpleTick />Track Views and Delivery Time Stamps of your Smart Invites</li>
      <li><PurpleTick />Send Instant Event Updates</li>
      <li><PurpleTick />Unlock ALL Premium Designs</li>
      <li><PurpleTick /><b>UNLIMITED</b> Active Polls & Lists</li>
      <li><PurpleTick />Sell tickets</li>
      <li><PurpleTick />Free sharing via email, social, and your own SMS</li>
    </ul>
  </main>)
}

export const CreditsDescription = () => {
  return (<main className={cn(styles['credits-description-main'], styles['plan-description'])}>
    <h1>The more you buy today, the less they cost!</h1>

    <p>Now that you’re a member, you can buy only the credits you want and they never expire!</p>
    <p>Credits can be used for our Automated Invites, which offer invite Delivery and Viewed tracking, automated RSVP reminders, and one-tap send of your entire guest list.</p>
    <img alt="purchase credits" src={CreditImage} height='263' />
  </main>)
}

export const NoMembershipDescriptionItems = () => {
  return (
    <ul>
      <li><GreenTick />Free sharing via email, social, and your own SMS</li>
      <li><GreenTick /><div><b>2</b> Active Polls & Lists</div></li>
      <li><GreenTick /><div><b>10</b> Automated Invites to send guest list in one-tap</div></li>
      <li><GreenTick /><div>Share <b>4</b> Event Videos</div></li>
      <li><RedCross />Invite Delivery & Viewed status, RSVP reminders</li>
      <li><RedCross />Purchase additional credits anytime</li>
      <li><RedCross />Unlock ALL Premium Designs</li>
      <li><RedCross />Send Instant Event Updates</li>
      <li><RedCross />Sell tickets</li>
    </ul>
  )
}

export const NoMembershipDescription = () => {
  return (<main className={cn(styles['no-membership-description-main'], styles['plan-description'])}>
    <img alt="no membership" src={NoMembershipImage} width='212' />
    <ul>
      <li><GreenTick />Free sharing via email, social, and your own SMS</li>
      <li><GreenTick /><b>2</b> Active Polls & Lists</li>
      <li><GreenTick /><b>10</b> Automated Invites to send guest list in one-tap</li>
      <li><GreenTick />Share <b>4</b> Event Videos</li>
      <li><RedCross />Invite Delivery & Viewed status, RSVP reminders</li>
      <li><RedCross />Purchase additional credits anytime</li>
      <li><RedCross />Unlock ALL Premium Designs</li>
      <li><RedCross />Send Instant Event Updates</li>
      <li><RedCross />Sell tickets</li>
    </ul>
  </main>)
}
