import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DashboardContainer from 'containers/DashboardContainer'
import Panel from '../components/Panel'
import poll_successfully from 'assets/icons/poll_successfully.png'

import styles from './thanks-container.scss'

class ThanksPanel extends Component {
  render () {
    const { handleBackClick } = this.props

    const THANKS_MESSAGES = {
      title: 'Thank you for participating!',
      subtitle: `You've Successfully Subscribed to updates for "${this.props.poll.name}".`
    }

    return (
      <DashboardContainer>
        <Panel
          notification={THANKS_MESSAGES}
          handleBackClick={handleBackClick}
          showCreateButton
        >
          <img
            src={poll_successfully}
            alt='successfully'
            className={styles['iconProps']}
          />
        </Panel>
      </DashboardContainer>
    )
  }
}

ThanksPanel.propTypes = {
  handleBackClick: PropTypes.func,
  poll: PropTypes.object
}

export default ThanksPanel
