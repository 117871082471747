import { buffers } from 'redux-saga'
import { actionChannel, call, fork, take, put, select } from 'redux-saga/effects'

import loadImageInfo from 'services/image_loader'

import { PUSHER_EVENT_RECEIVED, SET_EVENT_ID } from 'src/actions'
import { showHud } from 'src/actions/frontend'

import {
  CREATE_MEDIUM,
  CREATE_REMOTE_MEDIUM_SUCCESS,
  CREATE_REMOTE_MEDIUM_ERROR,
  DESTROY_MEDIUM_ERROR,
  FETCH_MEDIA_SUCCESS,
  FETCH_MEDIA_ERROR,
  REQUEST_FETCH_MORE_MEDIA,
  SHOW_LIKERS_MODAL,
  UPLOAD_MEDIUM,
  fetchMedia,
  fetchMediumComments,
  fetchLikers,
  uploadMedium,
  uploadMediumProgress,
  uploadMediumSuccess,
  uploadMediumError,
  createRemoteMedium, FETCH_LIKERS_ERROR
} from 'src/actions/media'

import {
  eventIdSelector,
  mediaSelector,
  userIdSelector
} from 'src/selectors'

import { uploadToS3ByPresignedPost } from 'src/services/s3_upload_client'

import { uploadFileToS3Worker } from './s3'
import { createWatcher, multiPut } from './utils'

export default function * mediaRootSaga () {
  yield fork(watchCreateMedium)
  yield fork(watchUploadMedium)
  yield fork(watchCreateRemoteMediumSuccessful)
  yield fork(createWatcher(CREATE_REMOTE_MEDIUM_ERROR, createRemoteMediumErrorWorker))
  yield fork(createWatcher(DESTROY_MEDIUM_ERROR, destroyMediumErrorWorker))
  yield fork(watchShowLikersModal)
  yield fork(watchRequestFetchMoreMedia)
  yield fork(createWatcher(PUSHER_EVENT_RECEIVED, pusherWorker))
  yield fork(createWatcher(FETCH_MEDIA_SUCCESS, fetchMediaSuccessWorker))
  yield fork(createWatcher(FETCH_LIKERS_ERROR, fetchLikersErrorWorker))
}

function * fetchMediaSuccessWorker (action) {
  if (action.response.entities.media) {
    const media = Object.values(action.response.entities.media)
    for (let medium of media) {
      yield call(fetchCommentsWorker, medium)
    }
  }
}

function * fetchCommentsWorker (medium) {
  if (medium.comments_count > 0) {
    yield put(fetchMediumComments(medium.id, 1, 3, false))
  }
}

function * watchCreateMedium () {
  // TODO: convert into takeEvery
  while (true) {
    const action = yield take(CREATE_MEDIUM)
    yield fork(createMediumWorker, action)
  }
}

function * createMediumWorker (action) {
  const userId = yield select(userIdSelector)
  const imageInfo = yield loadImageInfo(action.file)

  const eventId = yield select((state) => state.eventId)
  yield put(createRemoteMedium(imageInfo, userId, action.file, eventId))
}

function * watchCreateRemoteMediumSuccessful () {
  while (true) {
    const action = yield take(CREATE_REMOTE_MEDIUM_SUCCESS)
    const medium = action.response.entities.media[action.response.result.photo]
    yield put(uploadMedium(medium, action.originalAction.payload.file))
  }
}

function * destroyMediumErrorWorker () {
  yield put(showHud('error', 'There was an error while deleting your photo. Please retry in a few moments.'))
}

function * createRemoteMediumErrorWorker (action) {
  const medium = action.originalAction.payload.medium
  const error = JSON.stringify(action.response.result.errors)
  yield call(handleUploadMediumErrorSaga, medium, error)
}

function * handleUploadMediumErrorSaga (medium, error = null) {
  if (error) {
    // TODO: This should send to honeybadger
    console.error('unable to upload photo', error)
  }
  yield multiPut(handleUploadMediumErrorActions(medium))
}

function handleUploadMediumErrorActions (medium) {
  return [
    showHud('error', 'There was an error while uploading your photo. Please retry in a few moments.'),
    uploadMediumError(medium.id)
  ]
}

function * watchUploadMedium () {
  while (true) {
    // TODO: Convert into takeEvery
    // TODO: change name to REQUEST_UPLOAD_MEDIUM
    const { medium, file } = yield take(UPLOAD_MEDIUM)
    const onSuccessActions = [uploadMediumSuccess(medium.id)]
    const onErrorActions = handleUploadMediumErrorActions(medium)
    const onProgressActionCreator = (progress) => {
      return uploadMediumProgress(medium.id, progress)
    }

    try {
      const request = uploadToS3ByPresignedPost(medium.file_presigned_post, file, medium.redirect_url)
      yield fork(uploadFileToS3Worker, request, onProgressActionCreator, onSuccessActions, onErrorActions)
    } catch (e) {
      yield call(handleUploadMediumErrorSaga, medium, e)
    }
  }
}

function * watchShowLikersModal () {
  while (true) {
    const action = yield take(SHOW_LIKERS_MODAL)
    yield put(fetchLikers(action.mediumId))
  }
}

function * fetchLikersErrorWorker (action) {
  if (!action.response) {
    yield put(showHud('error', 'There was an error while fetching likers. Please retry in a few moments.'))
  }
}

function * pusherWorker (action) {
  switch (action.payload.eventType) {
    case 'like-created':
    case 'like-destroyed':
      yield call(handlePusherLikeCreatedDestroyedWorker, action)
      return
    case 'photo-processed':
      yield call(fetchCommentsWorker, action)
  }
}

// Purpose: to keep the likes_count synchronized with the server
// if someone else has liked a medium then we need to fetch all the likes so that
// we can rely on the count of all the likes instead of the medium.likes_count
function * handlePusherLikeCreatedDestroyedWorker (action) {
  const likeable_id = action.response.result.metadata.likeable_id
  const media = yield select(mediaSelector)
  const medium = media[likeable_id]
  if (medium && !medium.has_fetched_likes) {
    yield put(fetchLikers(likeable_id))
  }
}

// Watch REQUEST_FETCH_MORE_MEDIA and fetch more media. Specifically not built
// with takeEvery because we only want to process one REQUEST_FETCH_MORE_MEDIA
// request at a time
// Note: this is the only place that more media are fetched so that the page number stays consistent
function * watchRequestFetchMoreMedia () {
  const channel = yield actionChannel(REQUEST_FETCH_MORE_MEDIA, buffers.sliding(1))
  let page = 1
  while (true) {
    yield take(channel)
    let eventId = yield select(eventIdSelector)
    if (!eventId) {
      yield take(SET_EVENT_ID)
      eventId = yield select(eventIdSelector)
    }
    yield put(fetchMedia(eventId, page, 6))
    page++
    yield take([FETCH_MEDIA_SUCCESS, FETCH_MEDIA_ERROR])
  }
}
