import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SimpleReactModal from 'simple-react-modal'
import { hostSelector, hudSelector } from 'src/selectors'
import { hideHud } from 'src/actions/frontend'

import SvgIcon from 'components/common/SvgIcon'

import { fullName } from 'src/services/user_helpers'

import CheckMark from 'src/assets/icons/hud_checkmark.svg'
import HudError from 'src/assets/hud_error.svg'
import PaperAirplane from 'src/assets/icons/hud_paper_airplane.svg'

import styles from './hobnob-hud.scss'

const HobnobHud = () => {
  const dispatch = useDispatch()
  const host = useSelector(hostSelector) || {}
  const { show, message, type } = useSelector(hudSelector)
  const containerStyles = {
    padding: '30vh 0 0 0',
    background: 'transparent',
    margin: '0 auto',
    width: '220px',
    textAlign: 'center'
  }

  const style = {
    transition: 'opacity 0.25s ease-in-out',
    fontFamily: null
  }

  const renderIcon = () => {
    switch (type) {
      case 'success':
        return CheckMark
      case 'sent':
        return PaperAirplane
      case 'error':
        return HudError
      default:
        return HudError
    }
  }

  const formattedMessage = () => {
    let updatedMessage = message.replace('{host_name}', fullName(host))
    return updatedMessage
  }

  return (
    <SimpleReactModal
      show={show}
      onClose={() => dispatch(hideHud())}
      closeOnOuterClick
      style={style}
      containerStyle={containerStyles}
      transitionSpeed={250}
    >
      <div className={styles['container']}>
        <SvgIcon icon={renderIcon()} />
        <div className={styles['message']}>{formattedMessage()}</div>
      </div>
    </SimpleReactModal>
  )
}

export default HobnobHud
