import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { eventSelector, eventModalSelector, eventTokenSelector } from 'src/selectors'
import Flier from 'src/components/details/Flier'
import LoadingComponent from 'src/components/common/LoadingComponent/LoadingComponent'
import Button from 'src/components/common/Button'
import { routerActions } from 'react-router-redux'

import ChatSection from './ChatSection/ChatSection'

import styles from './flyer-container.scss'

const FlyerContainer = () => {
  const dispatch = useDispatch()
  const event = useSelector(eventSelector)
  const eventToken = useSelector(eventTokenSelector)
  const eventModal = useSelector(eventModalSelector)

  const renderFlyer = () => {
    if (event.flyer === null) {
      return <LoadingComponent type="partial" loading light />
    }

    if (event.flyer?.state === 'pending' || event.state === 'activation_pending_flier') {
      return (
        <div className={styles['flyer-empty-container']}>
          <h3>Flyer upload failed. Please try again.</h3>
          <Button onClick={() => dispatch(routerActions.push(`/e/edit/${eventToken}`))}>
            SELECT FLYER
          </Button>
        </div>
      )
    }

    return <Flier event={event} />
  }

  const isGroupVisible = eventModal === 'chat'

  return (
    <div className={styles['flyer-container']}>
      {renderFlyer()}
      {isGroupVisible && <ChatSection />}
    </div>
  )
}

export default FlyerContainer
