import { fetchApi } from './api'

export const EVENT_TICKET_INFO_REQUEST = 'EVENT_TICKET_INFO_REQUEST'
export const EVENT_TICKET_INFO_REQUEST_SUCCESS = 'EVENT_TICKET_INFO_REQUEST_SUCCESS'
export const EVENT_TICKET_INFO_REQUEST_ERROR = 'EVENT_TICKET_INFO_REQUEST_ERROR'
export const SET_TICKET_TYPES = 'SET_TICKET_TYPES'

// payment methods
export const PAYMENT_METHODS_LIST_REQUEST = 'PAYMENT_METHODS_LIST_REQUEST'
export const PAYMENT_METHODS_LIST_REQUEST_SUCCESS = 'PAYMENT_METHODS_LIST_REQUEST_SUCCESS'
export const PAYMENT_METHODS_LIST_REQUEST_ERROR = 'PAYMENT_METHODS_LIST_REQUEST_ERROR'
export const SET_PAYMENT_METHODS_LIST = 'SET_PAYMENT_METHODS_LIST'

export const SHOW_CREATE_NEW_CARD_MODAL = 'SHOW_CREATE_NEW_CARD_MODAL'
export const SHOW_TICKET_COUNT_CONTROL_MODAL = 'SHOW_TICKET_COUNT_CONTROL_MODAL'
export const SHOW_INVITATION_REPLY_FORM_MODAL = 'SHOW_INVITATION_REPLY_FORM_MODAL'
export const SHOW_TOTAL_TICKETS_COUNTS_MODAL = 'SHOW_TOTAL_TICKETS_COUNTS_MODAL'
export const SET_SHOW_GUESTS_CHANGE_RSVP_MODAL = 'SET_SHOW_GUESTS_CHANGE_RSVP_MODAL'

export const CREATE_PAYMENT_METHOD = 'CREATE_PAYMENT_METHOD'
export const CREATE_PAYMENT_METHOD_SUCCESS = 'CREATE_PAYMENT_METHOD_SUCCESS'
export const CREATE_PAYMENT_METHOD_ERROR = 'CREATE_PAYMENT_METHOD_ERROR'

// purchase tickets
export const PURCHASE_TICKETS = 'PURCHASE_TICKETS'
export const PURCHASE_TICKETS_SUCCESS = 'PURCHASE_TICKETS_SUCCESS'
export const PURCHASE_TICKETS_ERROR = 'PURCHASE_TICKETS_ERROR'

export const SET_ORDER_LINE_ITEMS = 'SET_ORDER_LINE_ITEMS'

export const SET_REQUESTED_RSVP = 'SET_REQUESTED_RSVP'

// event ticket count
export const EVENT_TICKETS_COUNTS = 'EVENT_TICKETS_COUNTS'
export const EVENT_TICKETS_COUNTS_SUCCESS = 'EVENT_TICKETS_COUNTS_SUCCESS'
export const EVENT_TICKETS_COUNTS_ERROR = 'EVENT_TICKETS_COUNTS_ERROR'
export const SET_EVENT_TICKETS_COUNTS = 'SET_EVENT_TICKETS_COUNTS'
export const SET_EVENT_TICKETS_COUNTS_LOADING_STATUS = 'SET_EVENT_TICKETS_COUNTS_LOADING_STATUS'

export const SET_TICKET_TYPES_COUNTS = 'SET_TICKET_TYPES_COUNTS'
export const SET_TEMP_TICKET_TYPES_COUNTS = 'SET_TEMP_TICKET_TYPES_COUNTS'
export const SET_HOST_CAN_ACCEPT_PAYMENTS = 'SET_HOST_CAN_ACCEPT_PAYMENTS'
export const SET_PAYMENT_RECEIPT_EMAIL = 'SET_PAYMENT_RECEIPT_EMAIL'

// revoke tickets
export const LIST_MY_ORDERS_FOR_EVENT_REQUEST = 'LIST_MY_ORDERS_FOR_EVENT_REQUEST'
export const LIST_MY_ORDERS_FOR_EVENT_REQUEST_SUCCESS = 'LIST_MY_ORDERS_FOR_EVENT_REQUEST_SUCCESS'
export const LIST_MY_ORDERS_FOR_EVENT_REQUEST_ERROR = 'LIST_MY_ORDERS_FOR_EVENT_REQUEST_ERROR'
export const SET_LIST_ORDERS_FOR_EVENT = 'SET_LIST_ORDERS_FOR_EVENT'
export const REVOKE_TICKETS_MUTATION = 'REVOKE_TICKETS_MUTATION'
export const REVOKE_TICKETS_MUTATION_SUCCESS = 'REVOKE_TICKETS_MUTATION_SUCCESS'
export const REVOKE_TICKETS_MUTATION_ERROR = 'REVOKE_TICKETS_MUTATION_ERROR'

export const SET_INITIAL_LOADING_PAYMENT_MODAL = 'SET_INITIAL_LOADING_PAYMENT_MODAL'

export const SET_CURRENT_ORDER_ID = 'SET_CURRENT_ORDER_ID'

// request refund
export const CREATE_REFUND_REQUEST_BY_GUEST = 'CREATE_REFUND_REQUEST_BY_GUEST'
export const CREATE_REFUND_REQUEST_BY_GUEST_SUCCESS = 'CREATE_REFUND_REQUEST_BY_GUEST_SUCCESS'
export const CREATE_REFUND_REQUEST_BY_GUEST_ERROR = 'CREATE_REFUND_REQUEST_BY_GUEST_ERROR'

export const CANCEL_REFUND_REQUEST_BY_GUEST = 'CANCEL_REFUND_REQUEST_BY_GUEST'
export const CANCEL_REFUND_REQUEST_BY_GUEST_SUCCESS = 'CANCEL_REFUND_REQUEST_BY_GUEST_SUCCES'
export const CANCEL_REFUND_REQUEST_BY_GUEST_ERROR = 'CANCEL_REFUND_REQUEST_BY_GUEST_ERROR'

// graphql
function eventTicketInfoRequestQuery(containerId, containerType) {
  const ticketTypesForContainer = `
  ticket_types_for_container(container_id: "${containerId}", container_type: "${containerType}") {
    id
    container_id
    container_type
    label
    price
    description
    inserted_at
    updated_at
    archived_at
  }
  users(ids: $userIds) {
    id
    can_accept_payments
  }
  `
  return `query EventTicketInfo($userIds: [UUID]!) {
     ${ticketTypesForContainer}
  }`
}

function paymentMethodsListRequestQuery() {
  const listAllPaymentMethods = `
     list_all_payment_methods {
          id
          brand
          country
          currency
          is_default
          last4
          payment_method_sid
          status
          stripe_customer_id
          type
          inserted_at
          updated_at
      }
  `
  return `{
     ${listAllPaymentMethods}
  }`
}

function createPaymentMethodMutationRequest(tokenSid) {
  const createPaymentMethodMutation = `
   create_payment_method(is_default: true, token_sid: "${tokenSid}") {
      id
      is_default
      stripe_customer_id
      status
      last4
      type
      country
      currency
      brand
      payment_method_sid 
    }
  `

  return `mutation {
    ${createPaymentMethodMutation}
  }`
}

function authPurchaseTicketsMutationRequest() {
  const purchaseTicketsMutation = `
    purchase_tickets_v2(
    order_info: $orderInfo
    payment_method_id: $paymentMethodId
  ) {
    container_id
    container_type
    id
    payment {
      amount
      category_reference_id
      category_payment_group_id
      created_by_user_id
      destination_user_id
      id
      payment_category
      platform_fee
      source_user_id
      status
      stripe_fee
    }
    payment_id
    state
    tickets {
      comped_ticket_line_item_id
      id
      issued_by
      issued_by_user_id
      order_line_item_id
      refunded_by_line_item_id
      state
      ticket_type_id
    }
    user_id
  }
  `
  return `mutation purchaseTickets($orderInfo: OrderInfoInput!, $paymentMethodId: UUID) {
    ${purchaseTicketsMutation}
  }
  `
}

function purchaseTicketsMutationRequest() {
  const purchaseTicketsMutation = `
    purchase_tickets_v2(
    order_info: $orderInfo
    payment_method_token_sid: $paymentMethodTokenSid
  ) {
    container_id
    container_type
    id
    payment {
      amount
      category_reference_id
      category_payment_group_id
      created_by_user_id
      destination_user_id
      id
      payment_category
      platform_fee
      source_user_id
      status
      stripe_fee
    }
    payment_id
    state
    tickets {
      comped_ticket_line_item_id
      id
      issued_by
      issued_by_user_id
      order_line_item_id
      refunded_by_line_item_id
      state
      ticket_type_id
    }
    user_id
  }
  `
  return `mutation purchaseTickets($orderInfo: OrderInfoInput!, $paymentMethodTokenSid: String) {
    ${purchaseTicketsMutation}
  }
  `
}

function eventTicketCountsQuery(eventId) {
  const eventTicketCounts = `
   events(ids: ["${eventId}"]){
    id
    ticketing_enabled_at
    guest_list_enabled
    destroyed_at
    user_id
    invitations_count
    invitations_accepted_count
    invitations {
      id
      rsvp_state
      destroyed_at
      delivery_state
      updated_at
      ticket_counts {
        ticket_count
        ticket_type_id
      }
    }
  }
  `
  return `{
  ${eventTicketCounts}
  }`
}

function listMyOrdersForEventQuery(eventId) {
  const listMyOrdersForEvent = `
   list_my_orders_for_event(event_id:"${eventId}"){
    orders{
      id
      state
      payment_id
      container_id
      inserted_at
      payment{
        amount
        id
        inserted_at
        payment_type
        payment_description
        payment_method_description
        platform_fee
        stripe_fee
        source_user_id
        created_by_user_id
        destination_user_id
        status
      }
      refund_requests{
        id
        state
        reason
        updated_at
        inserted_at
      }
      tickets{
        id
        state
        ticket_type_id
        comped_ticket_line_item_id
      }
    }
   }
  `
  return `{
  ${listMyOrdersForEvent}
  }`
}

function revokeTicketsMutationRequest() {
  const revokeTicketsMutation = `
   revoke_tickets(input:{ids:$ids, operation_type:my_tickets}) {
      successful
    }
  `

  return `mutation revokeTickets($ids: [UUID]!) {
    ${revokeTicketsMutation}
  }`
}

function createRefundRequestByGuestRequest() {
  const createRefundRequestByGuest = `
    create_refund_request_by_guest(
    order_id:$order_id,
    reason:$reason
    ){
    id
    user_id
    state
  }
  `
  return `mutation purchaseTickets($order_id: UUID!, $reason: String) {
    ${createRefundRequestByGuest}
  }
  `
}

function cancelRefundRequestByGuestMutation(refundRequestId) {
  const cancelRefundRequestByGuest = `
    cancel_refund_request_by_guest(refund_request_id:"${refundRequestId}"){
    id
    state
    order_id
  }
  `
  return `
    mutation {
      ${cancelRefundRequestByGuest}
    }
  `
}

// actions
export function eventTicketInfoRequest({ containerId, containerType, hostId, onSuccessCallback }) {
  return fetchApi({
    requestType: EVENT_TICKET_INFO_REQUEST,
    successType: EVENT_TICKET_INFO_REQUEST_SUCCESS,
    errorType: EVENT_TICKET_INFO_REQUEST_ERROR,
    route: '/graphql2',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: eventTicketInfoRequestQuery(containerId, containerType),
        variables: {
          userIds: [hostId]
        }
      })
    },
    onSuccessCallback
  })
}

export function paymentMethodsListRequest() {
  return fetchApi({
    requestType: PAYMENT_METHODS_LIST_REQUEST,
    successType: PAYMENT_METHODS_LIST_REQUEST_SUCCESS,
    errorType: PAYMENT_METHODS_LIST_REQUEST_ERROR,
    route: '/graphql2',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: paymentMethodsListRequestQuery()
      })
    }
  })
}

export function createPaymentMethodMutation(tokenSid) {
  return fetchApi({
    requestType: CREATE_PAYMENT_METHOD,
    successType: CREATE_PAYMENT_METHOD_SUCCESS,
    errorType: CREATE_PAYMENT_METHOD_ERROR,
    route: '/graphql2',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: createPaymentMethodMutationRequest(tokenSid)
      })
    }
  })
}

export function purchaseTickets(invitationId, orderLineItem, paymentMethodId, hasUser, { successActions = [], errorActions = [], email = '' } = {}) {
  const variables = hasUser ? {
    orderInfo: {
      invitation: {
        id: invitationId
      },
      comped_tickets: [],
      order_line_item: orderLineItem,
      email
    },
    paymentMethodId
  } : {
    orderInfo: {
      invitation: {
        id: invitationId
      },
      comped_tickets: [],
      order_line_item: orderLineItem,
      email
    },
    paymentMethodTokenSid: paymentMethodId
  }

  return fetchApi({
    requestType: PURCHASE_TICKETS,
    successType: PURCHASE_TICKETS_SUCCESS,
    errorType: PURCHASE_TICKETS_ERROR,
    route: '/graphql2',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: hasUser ? authPurchaseTicketsMutationRequest() : purchaseTicketsMutationRequest(),
        variables
      })
    },
    successActions,
    errorActions
  })
}

export function eventTicketCountsRequest(eventId) {
  return fetchApi({
    requestType: EVENT_TICKETS_COUNTS,
    successType: EVENT_TICKETS_COUNTS_SUCCESS,
    errorType: EVENT_TICKETS_COUNTS_ERROR,
    route: '/graphql',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: eventTicketCountsQuery(eventId)
      })
    },
    autoRetry: 1
  })
}

export function setEventTicketCountsLoadingStatus(status) {
  return {
    type: SET_EVENT_TICKETS_COUNTS_LOADING_STATUS,
    status
  }
}

export function setEventTicketsCounts(eventTicketCounts) {
  return {
    type: SET_EVENT_TICKETS_COUNTS,
    eventTicketCounts
  }
}

export function setPaymentMethodList(paymentList) {
  return {
    type: SET_PAYMENT_METHODS_LIST,
    paymentList
  }
}

export function showTicketCountControlModal() {
  return {
    type: SHOW_TICKET_COUNT_CONTROL_MODAL
  }
}

export function showCreateNewCardModal() {
  return {
    type: SHOW_CREATE_NEW_CARD_MODAL
  }
}

export function showInvitationReplyFormModal() {
  return {
    type: SHOW_INVITATION_REPLY_FORM_MODAL
  }
}

export function setOrderLineItems(orderLineItems) {
  return {
    type: SET_ORDER_LINE_ITEMS,
    orderLineItems
  }
}

export function setTicketTypes(ticketTypes) {
  return {
    type: SET_TICKET_TYPES,
    ticketTypes
  }
}

export function setTicketTypesCounts(ticketTypesCounts) {
  return {
    type: SET_TICKET_TYPES_COUNTS,
    ticketTypesCounts
  }
}

export function setTempTicketTypesCounts(tempTicketTypesCounts) {
  return {
    type: SET_TEMP_TICKET_TYPES_COUNTS,
    tempTicketTypesCounts
  }
}

export const TICKET_TYPES_COUNTS_LOADED = 'TICKET_TYPES_COUNTS_LOADED'
export function ticketTypesCountsLoaded() {
  return { type: TICKET_TYPES_COUNTS_LOADED }
}

export const TICKET_TYPES_COUNTS_LOADED_RESET = 'TICKET_TYPES_COUNTS_LOADED_RESET'
export function ticketTypesCountsLoadedReset() {
  return { type: TICKET_TYPES_COUNTS_LOADED_RESET }
}

export function setRequestedRsvp(requestedRsvp) {
  return {
    type: SET_REQUESTED_RSVP,
    requestedRsvp
  }
}

export function setHostCanAcceptPayments(canAcceptPayments) {
  return { type: SET_HOST_CAN_ACCEPT_PAYMENTS, canAcceptPayments }
}

export function setReciptEmail(email) {
  return { type: SET_PAYMENT_RECEIPT_EMAIL, email }
}

// start revoke free tickets
export function listMyOrdersForEventRequest(eventId) {
  return fetchApi({
    requestType: LIST_MY_ORDERS_FOR_EVENT_REQUEST,
    successType: LIST_MY_ORDERS_FOR_EVENT_REQUEST_SUCCESS,
    errorType: LIST_MY_ORDERS_FOR_EVENT_REQUEST_ERROR,
    route: '/graphql2',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: listMyOrdersForEventQuery(eventId)
      })
    }
  })
}

export function setListOrdersForEvent(listOrdersForEvent) {
  return {
    type: SET_LIST_ORDERS_FOR_EVENT,
    listOrdersForEvent
  }
}

export function revokeTicketsMutation(ids, { successActions = [], errorActions = [] } = {}) {
  const variables = {
    ids: ids
  }
  return fetchApi({
    requestType: REVOKE_TICKETS_MUTATION,
    successType: REVOKE_TICKETS_MUTATION_SUCCESS,
    errorType: REVOKE_TICKETS_MUTATION_ERROR,
    route: '/graphql2',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: revokeTicketsMutationRequest(ids),
        variables
      })
    },
    successActions,
    errorActions
  })
}

export function setInitialLoadingPaymentModal(initialLoadingPaymentModal) {
  return {
    type: SET_INITIAL_LOADING_PAYMENT_MODAL,
    initialLoadingPaymentModal
  }
}

export function setShowGuestsChangeRsvpModal() {
  return {
    type: SET_SHOW_GUESTS_CHANGE_RSVP_MODAL
  }
}

export function showTotalTicketCountsModal() {
  return {
    type: SHOW_TOTAL_TICKETS_COUNTS_MODAL
  }
}

export function setCurrentOrderId(orderId) {
  return {
    type: SET_CURRENT_ORDER_ID,
    orderId
  }
}

export function createRefundRequestByGuest({ order_id, reason }, { successActions = [], errorActions = [] } = {}) {
  const variables = {
    order_id,
    reason
  }
  return fetchApi({
    requestType: CREATE_REFUND_REQUEST_BY_GUEST,
    successType: CREATE_REFUND_REQUEST_BY_GUEST_SUCCESS,
    errorType: CREATE_REFUND_REQUEST_BY_GUEST_ERROR,
    route: '/graphql2',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: createRefundRequestByGuestRequest(),
        variables
      })
    },
    successActions,
    errorActions
  })
}

export function cancelRefundRequestByGuest(refund_request_id, { successActions = [], errorActions = [] } = {}) {
  return fetchApi({
    requestType: CANCEL_REFUND_REQUEST_BY_GUEST,
    successType: CANCEL_REFUND_REQUEST_BY_GUEST_SUCCESS,
    errorType: CANCEL_REFUND_REQUEST_BY_GUEST_ERROR,
    route: '/graphql2',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: cancelRefundRequestByGuestMutation(refund_request_id)
      })
    },
    successActions,
    errorActions
  })
}
