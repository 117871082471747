import PropTypes from 'prop-types'
import React from 'react'

import HobnobModal from 'components/common/HobnobModalV2/HobnobModal'

import UserAvatarContainer from 'components/common/UserAvatarContainer'

import { fullName } from 'src/services/user_helpers'
import LoadingComponent from 'components/common/LoadingComponent/LoadingComponent'

import styles from './likers-modal.scss'

export default class LikersModal extends React.Component {
  _renderLiker = liker => {
    const { namesByUserId } = this.props

    return (
      <li key={liker.id} className={styles['liker']}>
        <UserAvatarContainer user={liker} size={34} />
        <div className={styles['name']}>{fullName(liker, namesByUserId)}</div>
      </li>
    )
  }

  render() {
    const { open, onClose, likers, loadingLikers } = this.props
    const visibleLikers = likers.filter(liker => liker && liker.id)

    return (
      <HobnobModal visible={open} onCancel={onClose} title="Likers">
        {loadingLikers ? (
          <div className={styles['loading-container']}>
            <LoadingComponent loading light type="partial" />
          </div>
        ) : (
          <ul className={styles['likers-list']}>
            {visibleLikers.length === 0 ? (
              <li className={styles['no-likers']}>No likes yet</li>
            ) : (
              visibleLikers.map(this._renderLiker)
            )}
          </ul>
        )}

        {/* <InlineLoadingIndicator loading={loadingLikers} /> */}
      </HobnobModal>
    )
  }
}

LikersModal.propTypes = {
  likers: PropTypes.array,
  loadingLikers: PropTypes.bool,
  namesByUserId: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func
}
