import React from 'react'
import PropTypes from 'prop-types'
import LinkMessage from '../LinkMessage/LinkMessage'

import styles from './text-message.scss'

const TextMessage = props => {
  const { textContent } = props
  const linkPreviews = textContent.link_previews || []

  const renderMessage = () => {
    let str = textContent.body
    const LinkRegex = /<link:(.*?)>/g
    const tagRegex = /<(user|user_group|implicit_group):[^|]+\|([^>]+)>/g

    str = textContent.body
      .replace(LinkRegex, '<a href="http://$1" target="_blank">$1</a>')
      .replace(tagRegex, `<span class="${styles['user-tag']}">$2</span>`)

    return <div className={styles['text-message']} dangerouslySetInnerHTML={{ __html: str }} />
  }

  return (
    <>
      {renderMessage()}
      {linkPreviews.length > 0 && <LinkMessage linkContent={textContent} />}
    </>
  )
}

TextMessage.propTypes = {
  textContent: PropTypes.object.isRequired
}

export default TextMessage
