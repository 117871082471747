import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import LoadingComponent from 'src/components/common/LoadingComponent/LoadingComponent'
import VideoDeletedImage from 'assets/icons/video_deleted.png'

import styles from './video-message.scss'

const VideoMessage = props => {
  const [isLoadedVideo, setIsLoadedVideo] = useState(false)
  const videoRef = useRef(null)
  const {
    videoContent: { metadata }
  } = props

  const handleError = () => {
    setTimeout(() => {
      videoRef.current.load()
    }, 5000)
  }

  const handleLoadedMetadata = () => {
    setIsLoadedVideo(true)
  }

  if (metadata.deleted) {
    return (
      <div className={styles['video-message']}>
        <div className={styles['video-content-box']}>
          <img className={styles['img-inner']} src={VideoDeletedImage} alt="video deleted" />
        </div>
      </div>
    )
  }

  return (
    <div className={styles['video-message']}>
      <div className={styles['video-content-box']}>
        <video
          ref={videoRef}
          src={metadata.video_url}
          className={styles['video-inner']}
          controls
          poster={metadata.thumbnail_url}
          onError={handleError}
          onLoadedMetadata={handleLoadedMetadata}
        />

        {!isLoadedVideo && (
          <div className={styles['mask-box']}>
            <LoadingComponent loading type="partial" light content="This video is uploading..." />
          </div>
        )}
      </div>
    </div>
  )
}

VideoMessage.propTypes = {
  videoContent: PropTypes.object.isRequired
}

export default VideoMessage
