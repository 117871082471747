import React, { Component } from 'react'

import cn from 'classnames'
import PropTypes from 'prop-types'
import TextInputElement from 'components/forms/TextInputElement'
import TextAreaElement from 'components/common/ResizeableTextarea'
import Button from 'components/common/Button'
import EventList from './EventList/EventList'

import LabelIcon from 'assets/icons/label-icon.png'
import BalloonIcon from 'assets/icons/balloon-icon.png'
import RightIcon from 'assets/icons/right-icon.png'
import StrokeIcon from 'assets/icons/stroke-icon.png'
import NotesIcon from 'assets/icons/notes-icon.png'
import RightIconWhite from 'assets/icons/right-icon-white.png'
import { connect } from 'react-redux'
import { changeSignUpForm } from 'src/actions/timepoll'
import { capitalized } from 'services/utils'
import CloseIcon from 'assets/icons/close-icon-bg.png'
import styles from './first-step.scss'

const tagList = ['Potluck', 'Wishlist', 'Volunteer']

class FirstStep extends Component {
  state = {
    isEventModalVisible: false
  }

  inputRefs = {}

  handleChange = (type, value) => {
    const { onChangeSignUpForm } = this.props
    switch (type) {
      case 'name':
      case 'custom_creator_name':
        if (typeof value === 'string') {
          onChangeSignUpForm({ [type]: value })
          return
        }

        if (!this.inputRefs[type]) {
          this.inputRefs[type] = value.target
        }

        const nameStr = capitalized(this.inputRefs[type].value)
        onChangeSignUpForm({ [type]: nameStr })
        this.setState({
          nameInputSelectionStart: this.inputRefs[type].selectionStart,
          nameInputSelectionEnd: this.inputRefs[type].selectionEnd
        })

        break
      default:
        onChangeSignUpForm({ [type]: value })
    }
  }

  componentDidUpdate(prevProp) {
    for (const key of Object.keys(this.inputRefs)) {
      if (prevProp.signUpForm[key] !== this.props.signUpForm[key]) {
        this.inputRefs[key]?.setSelectionRange(
          this.state.nameInputSelectionStart,
          this.state.nameInputSelectionEnd
        )
      }
    }
  }

  render() {
    const { onButtonClick, signUpForm, userId, onChangeSignUpForm } = this.props
    const { isEventModalVisible } = this.state

    return (
      <div className={styles['first-step-container']}>
        <div className={styles['name-wrapper']}>
          <div className={styles['input-wrapper']}>
            <img src={LabelIcon} alt="" width="18" />
            <TextInputElement
              className={styles['input-inner']}
              placeholder="Name Your Sign Up List"
              onChange={event => this.handleChange('name', event)}
              valid
              value={signUpForm.name}
              autoFocus
              maxLength={50}
            />
          </div>

          <ul className={styles['name-list']}>
            {tagList.map((item, index) => (
              <li
                onClick={() => this.handleChange('name', item)}
                className={cn(styles['name-item'], {
                  [styles['name-item-checked']]: item === signUpForm.name
                })}
                key={index}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className={styles['information-wrapper']}>
          <div className={styles['information-label']}>optional</div>

          {userId && (
            <div className={styles['input-wrapper']}>
              <img src={BalloonIcon} alt="" width="20" />
              <TextInputElement
                className={styles['input-inner']}
                placeholder="Event"
                onChange={() => {}}
                valid
                value={signUpForm.checkedEvent?.name || ''}
                readOnly
                onClick={() => this.setState({ isEventModalVisible: true })}
                style={{ cursor: 'pointer' }}
              />
              {signUpForm.checkedEvent ? (
                <img
                  src={CloseIcon}
                  width={20}
                  style={{ cursor: 'pointer' }}
                  onClick={() => onChangeSignUpForm({ checkedEvent: null })}
                />
              ) : (
                <img src={RightIcon} width="20" />
              )}
            </div>
          )}

          <div className={styles['input-wrapper']}>
            <img src={StrokeIcon} alt="" width="20" />
            <TextInputElement
              className={styles['input-inner']}
              placeholder="Custom Creator Name"
              valid
              value={signUpForm.custom_creator_name}
              onChange={event => this.handleChange('custom_creator_name', event)}
            />
          </div>

          <div className={cn(styles['input-wrapper'], styles['textArea-wrapper'])}>
            <img src={NotesIcon} alt="" width="22" />
            <TextAreaElement
              className={styles['textArea-inner']}
              placeholder="Notes"
              onChangeComment={value => this.handleChange('notes', value)}
              comment={signUpForm.notes}
            />
          </div>
        </div>
        <div className={styles['button-wrapper']}>
          <Button
            disabled={!signUpForm.name}
            className={styles['button-inner']}
            onClick={onButtonClick}
          >
            <span>NEXT</span>
            <img src={RightIconWhite} alt="" width="24" />
          </Button>
        </div>

        {userId && (
          <EventList
            selectedEventId={signUpForm.checkedEvent?.id}
            isEventModalVisible={isEventModalVisible}
            onClose={() => this.setState({ isEventModalVisible: false })}
          />
        )}
      </div>
    )
  }
}

FirstStep.propTypes = {
  userId: PropTypes.string,
  onButtonClick: PropTypes.func,
  signUpForm: PropTypes.object,
  onChangeSignUpForm: PropTypes.func
}

function mapStateToProps(state) {
  return {
    userId: state.userId,
    signUpForm: state.frontend.signUpForm
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeSignUpForm: params => dispatch(changeSignUpForm(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstStep)
