import React, { Component } from 'react'
import PropTypes from 'prop-types'
import HobnobModal from 'components/common/HobnobModal'
import TextInputElement from 'components/forms/TextInputElement'
import cn from 'classnames'
import styles from './edit-modal.scss'

class EditModal extends Component {
  render() {
    const {
      first_name,
      last_name,
      phoneOrEmail,
      visibleErrorText,
      isOpenEditModal,
      guestInfo,
      onClose,
      onInputChange,
      onSave
    } = this.props
    const visibleContactInput = !guestInfo?.userFromHobnobContacts

    return (
      <HobnobModal
        closeOnOuterClick={false}
        title="Edit Contact"
        show={isOpenEditModal}
        onClose={onClose}
        visibleButton
        confirmText="save"
        onConfirm={onSave}
        buttonContainerClassName={styles['button-container']}
        cancelClassName={styles['cancel-button']}
      >
        <div className={styles['input-wrapper']}>
          <TextInputElement
            className={styles['input-inner']}
            placeholder="First Name"
            onChange={e => onInputChange('first_name', e.target.value)}
            valid
            value={first_name}
          />
          <TextInputElement
            className={styles['input-inner']}
            placeholder="Last Name"
            onChange={e => onInputChange('last_name', e.target.value)}
            valid
            value={last_name}
          />
        </div>

        {visibleContactInput && (
          <>
            <div className={styles['input-wrapper']}>
              <TextInputElement
                className={cn(styles['input-inner'], {
                  [styles['input-inner-error']]: visibleErrorText
                })}
                placeholder="Enter Phone Number or Email"
                onChange={e => onInputChange('phoneOrEmail', e.target.value)}
                valid={!visibleErrorText || phoneOrEmail === ''}
                value={phoneOrEmail}
              />
            </div>
            <div
              className={cn(styles['error-text'], {
                [styles['error-text-hidden']]: !visibleErrorText || phoneOrEmail === ''
              })}
            >
              Invalid phone number or email!
            </div>
          </>
        )}
      </HobnobModal>
    )
  }
}

EditModal.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  phoneOrEmail: PropTypes.string,
  visibleErrorText: PropTypes.bool,
  isOpenEditModal: PropTypes.bool,
  guestInfo: PropTypes.object,
  onClose: PropTypes.func,
  onInputChange: PropTypes.func,
  onSave: PropTypes.func
}

export default EditModal
