import React, { Component } from 'react'
import styles from './preview-add-guests-list.scss'
import PropTypes from 'prop-types'
import HeaderBar from 'src/components/HeaderBar/HeaderBar'
import Button from 'src/components/common/Button'
import cn from 'classnames'
import addGuestsContext from '../../AddGuestsPage/AddGuestsPageMobile/addGuestsContext'
import { connect } from 'react-redux'
import { changeAddGuestsList } from 'src/actions/event'
import { PHONE_NUMBER_CONTACT_METHOD, EMAIL_CONTACT_METHOD } from 'src/services/contact_methods'
import parsePhoneNumber from 'libphonenumber-js'

class PreviewAddGuestsList extends Component {
  static contextType = addGuestsContext
  static displayName = 'PreviewAddGuestsList'

  getContactText = item => {
    const { contact_method_type, contact_method_display_name } = item
    switch (contact_method_type) {
      case PHONE_NUMBER_CONTACT_METHOD:
        let str = contact_method_display_name

        if (contact_method_display_name?.startsWith('1')) {
          const phoneNumber = parsePhoneNumber(contact_method_display_name, 'US')
          str = phoneNumber.formatNational()
        }

        return str
      case EMAIL_CONTACT_METHOD:
        return contact_method_display_name
    }
  }

  handleAddGuests = () => {
    const { previewAddGuestsList, onChangeAddGuestsList, addGuestsList } = this.props
    onChangeAddGuestsList([...previewAddGuestsList, ...addGuestsList])

    this.context.onChangePopupBoxCallBack(() => {
      this.context.onChangeComponent('AddGuestsList')
    })
    history.go(-2)
  }

  render() {
    const { previewAddGuestsList, disableScroll, headerRight, fixed, title } = this.props
    return (
      <div
        className={cn(styles['input-guests-container'], {
          [styles['input-guests-container-fixed']]: fixed
        })}
      >
        <HeaderBar width={40} backIcon right={headerRight}>
          <div className={styles['header-title']}>
            {title} ({previewAddGuestsList.length})
          </div>
        </HeaderBar>

        <div
          className={cn(styles['content-container'], {
            [styles['content-container-disableScroll']]: disableScroll
          })}
        >
          {previewAddGuestsList.map(item => (
            <div className={styles['list-item']} key={item.key}>
              <div className={styles['name-text']}>
                {item.first_name} {item.last_name}
              </div>
              <div className={styles['contact-text']}>{this.getContactText(item)}</div>
            </div>
          ))}
        </div>

        <div className={styles['button-wrapper']}>
          <Button
            disabled={previewAddGuestsList.length === 0}
            className={styles['button-inner']}
            onClick={this.handleAddGuests}
          >
            ADD
          </Button>
        </div>
      </div>
    )
  }
}

PreviewAddGuestsList.propTypes = {
  addGuestsList: PropTypes.array,
  onChangeAddGuestsList: PropTypes.func,
  headerRight: PropTypes.node,
  disableScroll: PropTypes.bool,
  previewAddGuestsList: PropTypes.array,
  fixed: PropTypes.bool,
  title: PropTypes.string
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeAddGuestsList: params => dispatch(changeAddGuestsList(params))
  }
}

function mapStateToProps(state) {
  return {
    addGuestsList: state.frontend.addGuestsList
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewAddGuestsList)
