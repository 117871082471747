import React, { Component } from 'react'
import HobnobModal from 'src/components/common/HobnobModal'
import ImageCropper from 'src/components/common/ImageCropper'
import { compressImage, isSmallScreen } from '../../actions/utils'
import { setCustomFlyerURL } from 'src/actions/flyer'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import UploadIcon from 'src/assets/icons/icon-upload.svg'
import SvgIcon from './SvgIcon'
import styles from './custom-flyer-picker.scss'
import ImagesIcon from 'src/assets/icons/icon_images.png'

const ALLOWED_IMAGE_TYPES = ['.png', '.PNG', '.jpeg', '.JPEG', '.jpg', '.JPG']

class CustomFlyerPicker extends Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }

  state = {
    isImageCropperOpen: false,
    selectedImageDataURL: ''
  }

  render() {
    const { isImageCropperOpen, selectedImageDataURL } = this.state
    const { children } = this.props

    return (
      <>
        <main
          onClick={() => {
            this.setState({ isImageCropperOpen: true })
            this.inputRef.current.click()
          }}
          className={styles['main-container']}
        >
          {children || (
            <>
              <div className={styles['default-container']}>
                <section className={styles['section-container']}>
                  <SvgIcon icon={UploadIcon} width={40} height={40} />
                  <p className={styles['first-tip']}>Upload Your Own Image or Flyer</p>
                </section>
                <section className={styles['section-container']}>
                  <p className={styles['second-tip']}>Suggested size:</p>
                  <p className={styles['second-tip']}>1242*2208 px (9:16)</p>
                </section>
              </div>
            </>
          )}
        </main>
        <HobnobModal
          show={isImageCropperOpen && !!selectedImageDataURL}
          onClose={() => {
            this.resetState()
          }}
          containerStyles={{ maxWidth: '760px', width: isSmallScreen() ? '90vw' : '60vw' }}
          closeOnOuterClick={false}
        >
          <img
            className={styles['edit-icon']}
            src={ImagesIcon}
            width={'24px'}
            onClick={() => {
              this.inputRef.current.click()
            }}
          />
          <ImageCropper
            imageDataURL={selectedImageDataURL}
            onCancel={() => {
              this.resetState()
            }}
            onCrop={imageDataURL => {
              this.props.setCustomFlyerURL(imageDataURL)
              this.resetState()
            }}
          />
        </HobnobModal>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          type="file"
          ref={this.inputRef}
          onChange={e => {
            const file = e.target.files[0]
            if (!file && !selectedImageDataURL) {
              alert('Please select an image')
              return
            }
            const fileName = file.name
            if (ALLOWED_IMAGE_TYPES.some(type => fileName.endsWith(type))) {
              const reader = new FileReader()
              reader.onload = async e => {
                let dataURL = e.target.result
                if (file.size > 10 * 1024 * 1024) {
                  console.log('Compressing image larger than 10MB')
                  dataURL = await compressImage(e.target.result, {
                    width: 5000
                  })
                }
                this.setState({ selectedImageDataURL: dataURL })
              }
              reader.readAsDataURL(file)
            } else {
              alert('Please upload a valid image file, we support .png, .jpeg and .jpg image types')
            }
          }}
        />
      </>
    )
  }

  resetState() {
    this.setState({ isImageCropperOpen: false, selectedImageDataURL: '' })
    if (this.inputRef.current) {
      this.inputRef.current.value = ''
    }
  }
}

CustomFlyerPicker.propTypes = {
  setCustomFlyerURL: PropTypes.func,
  children: PropTypes.element
}

function mapDispatchToProps(dispatch) {
  return {
    setCustomFlyerURL: params => dispatch(setCustomFlyerURL(params))
  }
}

export default connect(null, mapDispatchToProps)(CustomFlyerPicker)
