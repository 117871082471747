import {
  SHOW_TOAST_MESSAGE,
  HIDE_TOAST_MESSAGES
} from 'src/actions/toastMessages'

const toastMessagesDefaultState = {
  visible: false,
  messages: []
}
export default function toastMessages (state = toastMessagesDefaultState, action) {
  switch (action.type) {
    case SHOW_TOAST_MESSAGE:
      return {
        ...state,
        visible: true,
        messages: [{
          user: action.user,
          messageType: action.messageType,
          messageContent: action.messageContent
        }]
      }
    case HIDE_TOAST_MESSAGES:
      return toastMessagesDefaultState
    default:
      return state
  }
}
