import { FlyerLayoutAbstract } from './flyer-layout.abstract'
import moment from 'moment/moment'

export default class Flyer_layout_column_left_arrows_023LayoutModel extends FlyerLayoutAbstract {
  flourishes = [
    {
      name: 'vintage_coffee_arrows',
      left: 40,
      top: 250,
      width: 120,
      height: 87
    },
    {
      name: 'vintage_coffee_white_border',
      left: 0,
      top: 0,
      width: 320,
      height: 568
    }
  ]

  textLayouts = {
    event_name_1: {
      left: 40,
      top: 60,
      size: 40,
      width: 120,
      height: 150,
      widthAlign: 'center',
      verticalAlign: 'center'
    },
    location_1: {
      left: 30,
      top: 370,
      size: 16,
      width: 150,
      height: 30,
      widthAlign: 'center',
      verticalAlign: 'bottom'
    },
    location_2: {
      left: 30,
      top: 403,
      size: 16,
      width: 150,
      widthAlign: 'center',
      nowrap: true
    }
  }

  constructor(layout, eventData) {
    super(layout, eventData)
    this.init()
  }

  getDateTexts() {
    const startDate = this.eventData.starts_at
    const endDate = this.eventData.ends_at
    let dates = []

    const isSameDay = moment(startDate).isSame(endDate, 'day')
    const { date1Props, date2Props } = this.dateProps

    const textArea1 = {
      top: 260,
      left: 80,
      size: 16,
      width: 40,
      color: date1Props.color.replace('0x', '#'),
      font: date1Props.font.replace(/\s+/g, '-'),
      widthAlign: 'center'
    }

    const textArea2 = {
      top: 290,
      left: 30,
      size: 16,
      width: 60,
      color: date1Props.color.replace('0x', '#'),
      font: date1Props.font.replace(/\s+/g, '-'),
      widthAlign: 'center'
    }

    const textArea3 = {
      top: 318,
      left: 80,
      size: 16,
      width: 40,
      color: date1Props.color.replace('0x', '#'),
      font: date1Props.font.replace(/\s+/g, '-'),
      widthAlign: 'center'
    }

    const textArea4 = {
      top: 290,
      left: 110,
      size: 16,
      width: 60,
      color: date1Props.color.replace('0x', '#'),
      font: date1Props.font.replace(/\s+/g, '-'),
      widthAlign: 'center'
    }

    const textArea5 = {
      top: 243,
      left: 80,
      size: 16,
      width: 40,
      color: date1Props.color.replace('0x', '#'),
      font: date1Props.font.replace(/\s+/g, '-'),
      widthAlign: 'center'
    }
    const textArea6 = {
      top: 335,
      left: 80,
      size: 16,
      width: 40,
      color: date1Props.color.replace('0x', '#'),
      font: date1Props.font.replace(/\s+/g, '-'),
      widthAlign: 'center'
    }

    const startMoment = moment(startDate)
    const endMoment = moment(endDate)

    if (startDate && !endDate) {
      let text1 = startMoment.format('h:mA').replace(':0', '')
      textArea1.text = date1Props.all_caps ? text1.toUpperCase() : text1
      dates.push(textArea1)

      const text2 = startMoment.format('Do')
      textArea2.text = date1Props.all_caps ? text2.toUpperCase() : text2
      dates.push(textArea2)

      const text3 = startMoment.format('YYYY')
      textArea3.text = date1Props.all_caps ? text3.toUpperCase() : text3
      dates.push(textArea3)

      const text4 = startMoment.format('MMM')
      textArea4.text = date1Props.all_caps ? text4.toUpperCase() : text4
      dates.push(textArea4)
    }

    if (startDate && endDate) {
      if (isSameDay) {
        const text1 = startMoment.format('h:mA').replace(':0', '')
        textArea1.text = date1Props.all_caps ? text1.toUpperCase() : text1
        dates.push(textArea1)

        const text2 = startMoment.format('Do')
        textArea2.text = date1Props.all_caps ? text2.toUpperCase() : text2
        dates.push(textArea2)

        const text3 = endMoment.format('h:mA').replace(':0', '')
        textArea3.text = date2Props.all_caps ? text3.toUpperCase() : text3
        dates.push(textArea3)

        const text4 = startMoment.format('MMM')
        textArea4.text = date1Props.all_caps ? text4.toUpperCase() : text4
        dates.push(textArea4)
      } else {
        const text1 = startMoment.format('h:mA').replace(':0', '')
        textArea1.text = date1Props.all_caps ? text1.toUpperCase() : text1
        dates.push(textArea1)

        const text2 = startMoment.format('MMM - Do')
        textArea2.text = date1Props.all_caps ? text2.toUpperCase() : text2
        dates.push(textArea2)

        const text3 = endMoment.format('h:mA').replace(':0', '')
        textArea3.text = date2Props.all_caps ? text3.toUpperCase() : text3
        dates.push(textArea3)

        const text4 = endMoment.format('MMM - Do')
        textArea4.text = date2Props.all_caps ? text4.toUpperCase() : text4
        dates.push(textArea4)

        const text5 = 'START'
        textArea5.text = text5
        dates.push(textArea5)

        const text6 = 'END'
        textArea6.text = text6
        dates.push(textArea6)
      }
    }

    return { type: 'date', dates }
  }
}
