import { combineReducers } from 'redux'

import {
  HIDE_MODAL_POLL_LOGIN,
  SHOW_MODAL_POLL_LOGIN
} from 'src/actions/frontend'

const visible = (state = false, action) => {
  switch (action.type) {
    case SHOW_MODAL_POLL_LOGIN: return true
    case HIDE_MODAL_POLL_LOGIN: return false
    default: return state
  }
}

export default combineReducers({
  visible
})
