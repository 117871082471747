import React, { Component } from 'react'

import TextAreaElement from 'components/common/ResizeableTextarea'
import TextInputElement from 'components/forms/TextInputElement'

import PropTypes from 'prop-types'
import RemoveIcon from 'assets/icons/remove-icon-gray.png'
import AddIconChecked from 'assets/icons/add-icon-checked.png'
import MinusIconChecked from 'assets/icons/minus-icon-checked.png'
import cn from 'classnames'
import styles from './option.scss'

class Option extends Component {
  handleChangeCount = type => {
    const { optionInformation, onChange, index } = this.props
    let count = optionInformation.count_limit === 9999 ? 0 : optionInformation.count_limit

    if (type === 'add') {
      if (count >= 50) {
        return
      }
      count += 1
    }

    if (type === 'minus') {
      if (count <= 0) {
        return
      }
      count -= 1

      if (count === 0) {
        count = 9999
      }
    }

    onChange({ value: count, type: 'count_limit', index })
  }
  render() {
    const { hasQuantity, optionInformation, onChange, onRemove, index, invalid } = this.props
    const count_limit = optionInformation.count_limit === 9999 ? 0 : optionInformation.count_limit

    return (
      <li className={cn(styles['option-inner'], invalid && styles['option-inner-invalid'])}>
        <div className={styles['name-wrapper']}>
          <div className={styles['icon-wrapper']} onClick={() => onRemove(index)}>
            <img src={RemoveIcon} alt="" width="18" />
          </div>
          <TextInputElement
            className={styles['input-inner']}
            placeholder="Input Slot/Item"
            value={optionInformation.item}
            onChange={event => {
              onChange({ value: event.target.value, type: 'item', index })
            }}
            valid
            autoFocus
          />
          {hasQuantity && (
            <div className={styles['quantity-wrapper']}>
              <div
                className={cn(
                  styles['icon-wrapper'],
                  count_limit === 0 && styles['icon-wrapper-disabled']
                )}
                onClick={() => this.handleChangeCount('minus')}
              >
                <img src={MinusIconChecked} width="12" />
              </div>
              <div className={styles['num-text']}>{count_limit}</div>
              <div
                className={cn(
                  styles['icon-wrapper'],
                  count_limit === 50 && styles['icon-wrapper-disabled']
                )}
                onClick={() => this.handleChangeCount('add')}
              >
                <img src={AddIconChecked} width="12" />
              </div>
            </div>
          )}
        </div>
        <div className={styles['line']} />
        <div className={styles['description-wrapper']}>
          <TextAreaElement
            className={styles['textArea-inner']}
            placeholder="Add description..."
            onChangeComment={value => onChange({ value, type: 'description', index })}
            comment={optionInformation.description}
          />
        </div>
      </li>
    )
  }
}

Option.propTypes = {
  invalid: PropTypes.bool,
  hasQuantity: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  optionInformation: PropTypes.object,
  index: PropTypes.number
}

export default Option
