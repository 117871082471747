import { firstName, fullName } from 'src/services/user_helpers'
import moment from 'moment'

export function pastRequestPhotosTime (event) {
  if (!event.starts_at) return false

  const today = new Date()
  return today > requestPhotosTime(event)
}

export function eventStarted (event, date = new Date()) {
  if (!event.starts_at) return false

  const startDate = new Date(event.starts_at)
  return date >= startDate
}

export function eventFinished (event, date = new Date()) {
  const endDate = new Date(event.effective_ends_at)
  return date >= endDate
}

export function eventInProgress (event, date = new Date()) {
  return eventStarted(event, date) && !eventFinished(event, date)
}

export function meetingInProgress (event, date = new Date()) {
  date = moment(date).add(5, 'm').toDate()

  return event.meeting_link && eventInProgress(event, date)
}

export function timeUntilMeetingStarts (event, date = new Date()) {
  date = moment(date).add(5, 'm').toDate()
  return timeUntilEventStarts(event, date)
}

export function timeUntilEventStarts (event, date = new Date()) {
  const startDate = new Date(event.starts_at)
  return (startDate.getTime() - date.getTime()) / 1000
}

export function dayOfEventStart (event, date = new Date()) {
  if (!event.starts_at) return false

  const startDate = new Date(event.starts_at)
  const sameDay = date.getDate() === startDate.getDate()
  const sameMonth = date.getMonth() === startDate.getMonth()
  const sameYear = date.getYear() === startDate.getYear()

  return sameDay && sameMonth && sameYear
}

export function hostFirstName (event, host) {
  return event.custom_host_name || firstName(host)
}

export function hostFullName (event, host) {
  return event?.custom_host_name || fullName(host)
}

function requestPhotosTime (event) {
  if (event.ends_at) {
    return new Date(event.ends_at)
  }

  let endDate = new Date(event.starts_at)
  // Set date to 9am the day after starts_at
  endDate.setDate(endDate.getDate() + 1)
  endDate.setHours(9)
  endDate.setMinutes(0)

  return endDate
}
