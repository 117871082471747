import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { setUseMaskedEmail } from 'src/actions/login'

import styles from './email-input-element.scss'

const EmailInputElement = ({ useMaskedEmail, maskedEmailAddress, value, ...otherProps }) => {
  const dispatch = useDispatch()
  let inputValue = value === '' ? null : value
  if (useMaskedEmail) {
    inputValue = maskedEmailAddress
  }

  const changeEmailLink = <a onClick={() => dispatch(setUseMaskedEmail(false))}>Change</a>

  return (
    <div className={styles['container']}>
      <input
        type="text"
        placeholder="Email Address"
        autoComplete="off"
        value={inputValue}
        disabled={useMaskedEmail}
        {...otherProps}
      />
      {useMaskedEmail ? changeEmailLink : null}
    </div>
  )
}

EmailInputElement.propTypes = {
  maskedEmailAddress: PropTypes.string,
  useMaskedEmail: PropTypes.bool,
  value: PropTypes.string.isRequired
}

export default EmailInputElement
