import React, { Component } from 'react'
import OverlayLoadingIndicator from 'components/common/OverlayLoadingIndicator'
import PropTypes from 'prop-types'
import styles from './loading-component.scss'

class LoadingComponent extends Component {
  render() {
    const { type, dark, light, loading, backgroundColor, content } = this.props

    const params = {
      dark,
      light,
      loading
    }

    if (loading) {
      return (
        <div
          style={{ backgroundColor: backgroundColor }}
          className={styles[`${type}-loading-container`]}
        >
          <OverlayLoadingIndicator {...params} />
          {content && <div className={styles['loading-content']}>{content}</div>}
        </div>
      )
    } else {
      return null
    }
  }
}

LoadingComponent.defaultProps = {
  type: 'global',
  dark: false,
  light: false,
  loading: true,
  backgroundColor: 'transparent'
}

LoadingComponent.propTypes = {
  type: PropTypes.oneOf(['global', 'partial']),
  backgroundColor: PropTypes.string,
  dark: PropTypes.bool,
  light: PropTypes.bool,
  loading: PropTypes.bool,
  content: PropTypes.node
}

export default LoadingComponent
