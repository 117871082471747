import { FlyerLayoutAbstract } from './flyer-layout.abstract'

export default class Flyer_layout_bottom_right_aligned_004LayoutModel extends FlyerLayoutAbstract {
  flourishes = []

  textLayouts = {
    event_name_1: {
      top: 380,
      size: 47,
      height: 60,
      width: 300,
      right: 10,
      align: 'right'
    },
    location_1: {
      top: 470,
      size: 18,
      width: 300,
      height: 18,
      nowrap: true,
      right: 10,
      align: 'right'
    }
  }

  dateLayouts = {
    date_1: {
      top: 444,
      right: 10,
      daySize: 18,
      width: 300,
      align: 'right'
    }
  }

  constructor(flyerDesign, eventData) {
    super(flyerDesign, eventData)
    this.init()
  }

  getTexts() {
    let texts = super.getTexts()
    const location1 = texts.find(t => t.slug === 'location_1')
    if (location1?.text) {
      location1.text = `AT ${location1.text}`
    }
    return texts
  }

  getDateTexts() {
    const endDate = this.eventData.ends_at
    const { daySize, top, align, width, right } = this.dateLayouts.date_1
    const dateInformation = this.formatDates({
      day: 'dddd, MMM D h:mmA',
      time: 'h:mmA'
    })

    const { formattedStart, formattedEnd } = dateInformation
    let dates = []
    let textTop = top

    dates.push({
      text: endDate ? `${formattedStart} - ${formattedEnd}` : formattedStart,
      nowrap: true,
      width,
      align,
      right,
      top: textTop,
      color: this.dateProps.date1Props.color.replace('0x', '#'),
      font: this.dateProps.date1Props.font.replace(/\s+/g, '-'),
      size: daySize,
      height: daySize
    })

    return { type: 'date', dates }
  }
}
