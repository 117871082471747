import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import {
  hasPaidTicketsSelector,
  listMyOrdersForEventSelector,
  ticketTypesCountsSelector
} from 'src/selectors'

import styles from './total-attending.scss'
import { renderOrderStatus } from '../../services/payment_utils'
import { pluralize, sumCount } from '../../services/utils'

class TicketAttending extends React.Component {
  _onClick = () => {
    const { onClick } = this.props
    if (onClick) {
      onClick()
    }
  }

  _renderLabel = (sumAdultCounts, sumChildCounts, sumTicketCount) => {
    const { isPaidEvent } = this.props

    const ticketTypesLabel = isPaidEvent ? 'Total Tickets: ' : 'Total Attending: '

    if (sumChildCounts) {
      const adultText = pluralize(sumAdultCounts, 'Adult')
      const childText = pluralize(sumChildCounts, 'Child')
      return `${ticketTypesLabel}${sumAdultCounts} ${adultText}, ${sumChildCounts} ${childText}`
    }
    return ` ${ticketTypesLabel}${sumTicketCount}`
  }

  _renderStatus = (sumAdultCounts, sumChildCounts, sumTicketCount) => {
    const { invitation, isPaidEvent } = this.props

    // For paid events we show the ticket status even if the invitation is declined
    if (!isPaidEvent && invitation.rsvp_state === 'declined') {
      return null
    }

    const label = this._renderLabel(sumAdultCounts, sumChildCounts, sumTicketCount)
    if (this._requestRefundPending()) {
      return <div className={styles['status-wrapper']}>
        {label}
        <div className={styles['order-status']}>Refund Request Pending</div>
      </div>
    }

    return <div
      className={styles['status-wrapper']}>
      {label}
    </div>
  }

  _requestRefundPending = () => {
    const { listMyOrdersForEvent } = this.props

    const flag = listMyOrdersForEvent.some(order => {
      const status = renderOrderStatus(order.refund_requests, '')
      return status === 'pending'
    })

    return flag
  }

  _renderReplyStatus = (sumAdultCounts, sumChildCounts, sumTicketCount) => {
    const { isPaidEvent } = this.props

    const ticketTypesLabel = isPaidEvent ? 'Total Tickets:' : 'Total Attending:'

    if (sumChildCounts) {
      return `${ticketTypesLabel} ${sumAdultCounts} ${pluralize(sumAdultCounts, 'Adult')}, ${sumChildCounts} ${pluralize(sumChildCounts, 'Child')}`
    }
    return `${ticketTypesLabel} ${sumTicketCount}`
  }

  render () {
    const {
      ticketTypesCounts,
      className,
      inReplyForm
    } = this.props

    const adultTicketCounts = ticketTypesCounts.filter((item) => item.label === 'adult')
    const childTicketCounts = ticketTypesCounts.filter((item) => item.label === 'child')
    const sumAdultCounts = sumCount(adultTicketCounts)
    const sumChildCounts = sumCount(childTicketCounts)
    const sumTicketCount = sumCount(ticketTypesCounts)

    return (
      <div className={styles['wrapper']}>
        {
          inReplyForm ? <a className={styles['total-status-link']} onClick={this._onClick}>
            {this._renderReplyStatus(sumAdultCounts, sumChildCounts, sumTicketCount)}
          </a>
            : <div className={className} onClick={this._onClick}>
              {
                !!sumTicketCount && this._renderStatus(sumAdultCounts, sumChildCounts, sumTicketCount)
              }
            </div>
        }

      </div>
    )
  }
}

TicketAttending.defaultProps = {
  inReplyForm: false
}

TicketAttending.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  isPaidEvent: PropTypes.bool,
  ticketTypesCounts: PropTypes.array,
  listMyOrdersForEvent: PropTypes.array,
  invitation: PropTypes.object,
  inReplyForm: PropTypes.bool
}

function mapStateToProps (state) {
  const ticketTypesCounts = ticketTypesCountsSelector(state)

  return {
    listMyOrdersForEvent: listMyOrdersForEventSelector(state),
    isPaidEvent: hasPaidTicketsSelector(state),
    ticketTypesCounts
  }
}

export default connect(mapStateToProps)(TicketAttending)
