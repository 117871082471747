import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setReplyMessage } from 'src/actions/eventChat'
import CloseIcon from 'assets/icons/close-icon.png'
import { chatSelector, usersSelector } from 'src/selectors'
import MessageInner from '../MessageInner/MessageInner'
import InputMessage from '../../InputMessage/InputMessage'

import { Drawer } from 'antd'

import styles from './thread-drawer.scss'

const ThreadDrawer = () => {
  const dispatch = useDispatch()
  const { replyMessage, chatMessages } = useSelector(chatSelector)
  const users = useSelector(usersSelector)
  const replyMessageRef = useRef(null)
  const replyMessageCountRef = useRef(0)
  const chatParams = localStorage.getItem('chatParams')

  const message = chatMessages[replyMessage?.id]

  useEffect(() => {
    if (
      replyMessageRef.current &&
      message &&
      message?.replies.length > replyMessageCountRef.current
    ) {
      if (chatParams) {
        const { threadId, messageId } = JSON.parse(chatParams)
        const el = replyMessageRef.current.querySelector(`[data-id="${messageId}"]`)
        if (threadId && el) {
          el.scrollIntoView({ behavior: 'smooth' })
        }
        localStorage.removeItem('chatParams')
      } else {
        replyMessageRef.current.scrollTop = replyMessageRef.current.scrollHeight
        replyMessageCountRef.current = message.replies.length
      }
    }
  }, [message?.replies.length])

  const renderReplyMessage = () => {
    const messageBody = chatMessages[replyMessage.id]
    if (!messageBody || messageBody.destroyed_at) {
      dispatch(setReplyMessage(null))
      return
    }

    return (
      <div className={styles['reply-message-drawer']}>
        <div className={styles['reply-message-body']} ref={replyMessageRef}>
          <MessageInner
            inReply
            key={replyMessage.id}
            message={messageBody}
            user={users[replyMessage.user_id]}
          />
        </div>
        <InputMessage inReply message={replyMessage} />
      </div>
    )
  }

  return (
    <Drawer
      open={Boolean(replyMessage)}
      closable={false}
      mask={false}
      destroyOnClose
      drawerStyle={{ backgroundColor: '#f9f9f9' }}
      className={styles['drawer-container']}
      zIndex={1}
      title={
        <div className={styles['drawer-title']}>
          <span className={styles['title']}>Thread</span>
          <img src={CloseIcon} width={20} onClick={() => dispatch(setReplyMessage(null))} />
        </div>
      }
    >
      {replyMessage && renderReplyMessage()}
    </Drawer>
  )
}

export default ThreadDrawer
