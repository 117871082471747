import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import DashboardContainer from 'containers/DashboardContainer'
import Panel from '../components/Panel'
import Card from '../components/Card'
import SvgIcon from 'components/common/SvgIcon'
import poll_closed from 'assets/icons/poll_closed.png'
import star from 'assets/icons/star_icon.svg'

import styles from './closed-container.scss'

class ClosedPanel extends Component {
  _pollsSelected = (polls) => {
    if (polls) {
      return polls.filter(poll => poll.is_selected_by_creator === true)
    }
    return []
  }

  render () {
    const { poll } = this.props
    const message = 'No more responses can be submitted! '
    const pollClosed = {
      title: 'Poll Closed!',
      subtitle: message + poll.custom_creator_name + ' picked ' + (this._pollsSelected(poll.poll_options).length > 1 ? 'these' : 'this') + (this._pollsSelected(poll.poll_options).length > 1 ? ' options' : ' option') + ' for ' + poll.name
    }
    const pollClosedNoOption = {
      title: 'Poll Closed!',
      subtitle: message + poll.custom_creator_name + " hasn't picked any options for " + poll.name
    }

    return (
      <DashboardContainer>
        <Panel
          hideBackButton
          isStar
          notification={this._pollsSelected(poll.poll_options).length ? pollClosed : pollClosedNoOption}
        >
          {this._pollsSelected(poll.poll_options).length ? (
            poll.poll_options && this._pollsSelected(poll.poll_options).map(opt => (
              <Card
                key={opt.id}
                opt={opt}
                style={styles['card-star']}
                isStar
              >
                <SvgIcon icon={star} className={styles['card-star__icon']} />
              </Card>
            ))
          ) : (
            <img src={poll_closed} alt='closed' className={styles['iconProps']} />
          )}
        </Panel>
      </DashboardContainer>
    )
  }
}

ClosedPanel.propTypes = {
  poll: PropTypes.object
}

function mapStateToProps (state) {
  return {
    poll: state.frontend.timePoll
  }
}

export default connect(mapStateToProps)(ClosedPanel)
