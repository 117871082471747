import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import VideoFlyer from './VideoFlyer'
import styles from './flier.scss'

import { flyerSelector } from 'src/selectors'
import { updateFlyerZoom } from 'src/actions/flyer'

class Flier extends React.Component {
  componentDidMount () {
    this._hideTimer = setTimeout(this._unZoomFlyer, 7000)
  }

  componentWillUnmount () {
    this._cancelHideFlyerTimer()
  }

  _cancelHideFlyerTimer = () => {
    if (this._hideTimer) {
      clearTimeout(this._hideTimer)
    }
  }

  _unZoomFlyer = () => this.props.dispatch(updateFlyerZoom(false))

  _toggleZoom = () => this.props.dispatch(updateFlyerZoom(!this.props.flyerZoom))

  _renderLegacyFlyer (flier) {
    return (
      <img className={styles['flier']} src={flier.watermarked_url_2x} />
    )
  }

  _renderFlyer () {
    const { flyer, flier } = this.props.event

    if (flyer.type === 'VideoFlyer') {
      return <VideoFlyer
        className={styles['flier']}
        flyer={flyer}
        cancelHideFlyerTimer={this._cancelHideFlyerTimer}
        unZoomFlyer={this._unZoomFlyer}
      />
    } else {
      return this._renderLegacyFlyer(flier || flyer)
    }
  }

  render () {
    const { flyerZoom } = this.props
    const containerClassName = flyerZoom ? 'flier-container-zoom' : 'flier-container'

    return (
      <div className={styles[containerClassName]} id='rsvp' onClick={this._toggleZoom}>
        {this._renderFlyer()}
      </div>
    )
  }
}

Flier.propTypes = {
  dispatch: PropTypes.func,
  event: PropTypes.object,
  flyerZoom: PropTypes.bool
}

function mapStateToProps (state) {
  return {
    flyerZoom: flyerSelector(state).flyerZoom
  }
}

export default connect(mapStateToProps)(Flier)
