import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { hideLikersModal } from 'src/actions/media'

import {
  likersModalMediumIdSelector,
  likersModalVisibleSelector,
  likesSelector,
  mediaLikersLoadingStatusesSelector,
  namesByUserIdSelector,
  usersSelector
} from 'src/selectors'

import LikersModal from './LikersModal'

export class LikersModalContainer extends Component {
  render () {
    const {
      likes,
      likersModalMediumId,
      likersModalVisible,
      mediaLikersLoadingStatus,
      namesByUserId,
      onHideLikers,
      users
    } = this.props

    const likers = Object.values(likes).filter((like) => like.likeable_id === likersModalMediumId)
      .map((like) => users[like.user_id])

    return <LikersModal
      loadingLikers={mediaLikersLoadingStatus === 'loading'}
      open={likersModalVisible}
      onClose={onHideLikers}
      likers={likers}
      namesByUserId={namesByUserId}
    />
  }
}

LikersModalContainer.propTypes = {
  likes: PropTypes.object,
  likersModalMediumId: PropTypes.string,
  likersModalVisible: PropTypes.bool,
  onHideLikers: PropTypes.func.isRequired,
  mediaLikersLoadingStatus: PropTypes.string,
  namesByUserId: PropTypes.object,
  users: PropTypes.object
}

function mapStateToProps (state) {
  const likersModalMediumId = likersModalMediumIdSelector(state)
  return {
    likes: likesSelector(state),
    likersModalMediumId,
    likersModalVisible: likersModalVisibleSelector(state),
    mediaLikersLoadingStatus: mediaLikersLoadingStatusesSelector(state)[likersModalMediumId],
    namesByUserId: namesByUserIdSelector(state),
    users: usersSelector(state)
  }
}

export default connect(
  mapStateToProps,
  { onHideLikers: hideLikersModal }
)(LikersModalContainer)
