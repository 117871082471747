import React from 'react'
import PropTypes from 'prop-types'

import { displayCurrency } from '../../services/utils'
import RefundPolicyModal from '../modals/RefundPolicyModal'

import styles from './event-payment.scss'

class EventPayment extends React.Component {
  state = {
    showRefundPolicyModal: false
  }
  _renderColumn = (ticket, index) => {
    const { ticketTypes } = this.props
    return (
      <div className={styles['container']} key={ticket.id}>
        <div className={styles['ticket-title']}>{ticketTypes.length === 1 ? 'event tickets' : `${ticket.label} ticket`}</div>
        <div className={styles['price']}>{ticket.price ? displayCurrency(ticket.price, true) : 'Free'}</div>
        <div className={styles['description']}>{ticket.description}</div>
        {
          this._showRefundPolicy(index) && <a className={styles['refund-policy']} onClick={this._showRefundPolicyModal}>refund policy</a>
        }
      </div>
    )
  }

  _showRefundPolicy = (index) => {
    const { ticketTypes, event } = this.props
    if (index === ticketTypes.length - 1 && event.refund_policy) {
      return true
    }

    return false
  }

  _showRefundPolicyModal = () => {
    this.setState({
      showRefundPolicyModal: true
    })
  }

  _onCloseRefundPolicyModal = () => {
    this.setState({
      showRefundPolicyModal: false
    })
  }

  render () {
    const { ticketTypes, event } = this.props
    const { showRefundPolicyModal } = this.state
    return (
      <div>
        {
          ticketTypes.filter(item => !item.archived_at).map((item, index) => {
            return this._renderColumn(item, index)
          })
        }

        <RefundPolicyModal show={showRefundPolicyModal} onClose={this._onCloseRefundPolicyModal} refundPolicy={event.refund_policy} />
      </div>
    )
  }
}

EventPayment.propTypes = {
  ticketTypes: PropTypes.array,
  event: PropTypes.object
}

export default EventPayment
