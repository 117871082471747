import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import DashboardContainer from 'containers/DashboardContainer'
import Button from 'src/components/common/Button'
import Panel from '../components/Panel'
import { pollForm } from '../constants'
import { isValidEmail } from 'services/utils'

import styles from './email-container.scss'
import TextInputElement from '../../../components/forms/TextInputElement'

import { subscribeToMailchimp } from 'src/actions/timepoll'

import { signUpDetailSelector } from 'src/selectors'
import { DESTINATION_TYPES_CONSTANTS } from 'src/constants'
import { parseEventUrl } from 'services/url_helpers'

const initialState = {
  email: ''
}
export class EmailPanel extends React.Component {
  constructor(props) {
    super(props)

    this.state = initialState
  }

  getPollDetail = () => {
    const { signUpDetail, poll } = this.props
    const { destinationType } = parseEventUrl(window.location.pathname)
    switch (destinationType) {
      case DESTINATION_TYPES_CONSTANTS.signUp:
        return signUpDetail || {}
      case DESTINATION_TYPES_CONSTANTS.timepoll:
        return poll || {}
      default:
        return {}
    }
  }

  _formIsValid() {
    if (this.state.email && isValidEmail(this.state.email)) {
      return true
    }
  }

  _setEmail = event => {
    const email = event.target.value
    this.setState({ email })
  }
  _subscribeToPoll = e => {
    e.preventDefault()
    if (this.state.email !== '') {
      this.props.onSubscribeToMailchimp(this.state)
    }
  }
  render() {
    const { handleBackClick } = this.props
    const data = this.getPollDetail()

    const SUBSCRIBED_MESSAGES = {
      title: 'Thank you for participating!',
      subtitle: `Subscribe to updates for "${data.name}" to stay in the loop.`
    }
    const isSecondButton = true
    return (
      <DashboardContainer>
        <Panel
          isSecondButton={isSecondButton}
          notification={SUBSCRIBED_MESSAGES}
          handleBackClick={handleBackClick}
          showCreateButton
        >
          <div className={styles['container']}>
            <TextInputElement
              className={styles['input']}
              placeholder="Email"
              onChange={this._setEmail}
              valid={!this.state.email}
              value={this.state.email}
            />
            <div>
              <div className={styles['text']}>
                <a href="https://hobnob.app/privacy/">*Privacy Policy and </a>
                <a href="https://hobnob.app/terms/">Terms</a>
                <p>{pollForm.subtitle}</p>
              </div>
              <Button
                className={styles['button']}
                disabled={!this._formIsValid()}
                onClick={this._subscribeToPoll}
              >
                <p>submit</p>
              </Button>
            </div>
          </div>
        </Panel>
      </DashboardContainer>
    )
  }
}

EmailPanel.propTypes = {
  poll: PropTypes.object,
  handleBackClick: PropTypes.func,
  onSubscribeToMailchimp: PropTypes.func,
  signUpDetail: PropTypes.object
}

function mapStateToProps(state) {
  return {
    poll: state.frontend.timePoll,
    signUpDetail: signUpDetailSelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubscribeToMailchimp: params => dispatch(subscribeToMailchimp(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailPanel)
