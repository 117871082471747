import { combineReducers } from 'redux'

import {
  HIDE_OPEN_IN_APP_MODAL,
  SHOW_OPEN_IN_APP_MODAL
} from 'src/actions/frontend'

const visible = (state = false, action) => {
  switch (action.type) {
    case SHOW_OPEN_IN_APP_MODAL: return true
    case HIDE_OPEN_IN_APP_MODAL: return false
    default: return state
  }
}

export default combineReducers({
  visible
})
