import { put, spawn, take, select, call } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'react-router-redux'

import { showFloatingButton } from 'src/actions/frontend'
import {
  FETCH_ACTIVITY_FEED_LIST_SUCCESS,
  setActivityFeedList,
  setOldestTimestamp,
  setNewestTimestamp,
  setActivityFeedLoading,
  markReadActivity
} from 'src/actions/activityFeed'
import { setUnreadMessageList, FETCH_UNREAD_MESSAGE_SUCCESS } from 'src/actions/eventChat'
import { activityFeedSelector, chatSelector } from 'src/selectors'
import { createWatcher } from './utils'
import { FETCH_EVENT_V2_SUCCESS, cacheEvent } from 'src/actions/event'

export default function * frontendRootSaga() {
  yield spawn(createWatcher(LOCATION_CHANGE, floatingButtonWorker))
  yield spawn(watchFetchActivityFeedListSuccess)
  yield spawn(watchFetchEventSuccess)
  yield spawn(watchFetchUnreadMessageSuccess)
}

function * watchFetchEventSuccess() {
  while (true) {
    const action = yield take(FETCH_EVENT_V2_SUCCESS)
    const { event } = action.response
    yield put(cacheEvent(event))
  }
}

function * watchFetchActivityFeedListSuccess() {
  while (true) {
    const action = yield take(FETCH_ACTIVITY_FEED_LIST_SUCCESS)
    const { activityFeedList } = yield select(activityFeedSelector)
    let resultActivityFeedList = []

    if (action.payload.fromWS || action.payload.loadMore) {
      const idTracker = {}

      if (action.payload.fromWS) {
        const firstViewLineIndex = activityFeedList.findIndex(item => item.slug === 'viewed')
        if (firstViewLineIndex !== 0) {
          activityFeedList.splice(0, firstViewLineIndex)
        }
      }

      resultActivityFeedList = activityFeedList.map(item => {
        idTracker[item.id] = true
        return item
      })

      action.response.activities.forEach(item => {
        if (!idTracker[item.id]) {
          resultActivityFeedList.push(item)
        }
      })
    } else {
      resultActivityFeedList = action.response.activities
    }

    const sortedActivityFeedList = resultActivityFeedList.sort(
      (a, b) => new Date(b.oldest_timestamp) - new Date(a.oldest_timestamp)
    )

    yield call(setActivityFeedTimestamp, sortedActivityFeedList)
    yield put(setActivityFeedList(sortedActivityFeedList))
    yield put(setActivityFeedLoading(false))
  }
}

function * watchFetchUnreadMessageSuccess() {
  while (true) {
    const action = yield take(FETCH_UNREAD_MESSAGE_SUCCESS)
    const { mini_badges } = action.response
    const { channelName } = yield select(chatSelector)

    const currentUnread = []
    const otherUnread = []

    for (const item of mini_badges) {
      if (
        item.entities.find(entity => entity.type === 'Room' && channelName.indexOf(entity.id) > -1)
      ) {
        currentUnread.push(item)
      } else {
        const messageSlug = [
          'user_created_message',
          'user_replied_to_thread',
          'user_created_invitation_reply_message',
          'user_created_thread_from_your_message',
          'user_mentioned_you'
        ]
        if (messageSlug.includes(item.activity_slug)) {
          otherUnread.push(item)
        }
      }
    }

    if (currentUnread.length > 0) {
      const ids = currentUnread.map(item => item.activity_ids).flat()
      yield put(markReadActivity(ids))
    }

    yield put(setUnreadMessageList(otherUnread))
  }
}

function * setActivityFeedTimestamp(activityFeedList) {
  if (activityFeedList.length === 0) return
  const a = activityFeedList.map(item => item.activity_items).flat()
  const b = a.sort((a, b) => {
    return new Date(b.timestamp) - new Date(a.timestamp)
  })

  const newestTimestamp = b[0].timestamp
  const oldestTimestamp = b[b.length - 1].timestamp

  yield put(setNewestTimestamp(newestTimestamp))
  yield put(setOldestTimestamp(oldestTimestamp))
}

function * floatingButtonWorker() {
  yield put(showFloatingButton())
}
