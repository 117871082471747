import React from 'react'
import PropType from 'prop-types'
import { useDispatch } from 'react-redux'
import CommonFrame from '../../CommonFrame/CommonFrame'
import CancelMembershipImg from 'assets/icons/cancel-membership.png'
import Button from 'src/components/common/Button'
import { CloseOutlined } from '@ant-design/icons'
import { changeVisible, setContent } from 'src/actions/globalLayer'

import styles from './cancel-invitation-maker.scss'

const descLayerTexts = [
  <p>
    Credits to send trackable invites in one-tap. See Invite Delivery and View status on your guest
    list.
  </p>,
  <p>
    <b>UNLIMITED</b> Active Polls & Lists
  </p>,
  <p>Purchase additional credits anytime</p>,
  <p>Free sharing via email, social, and your own SMS</p>,
  <p>Unlock ALL Premium Designs</p>,
  <p>Send Instant Event Updates</p>,
  <p>Sell tickets</p>
]

const CancelMembership = ({ onCancelMembership }) => {
  const dispatch = useDispatch()

  const handleCloseLayer = () => {
    dispatch(changeVisible(false))
    dispatch(setContent(null))
  }

  const actionLayer = (
    <div className={styles['action-container']}>
      <h1>Downgrade Plan</h1>
      <p className={styles['p-section']}>
        <span>Are you sure you want to downgrade to the free plan?</span>
        <span>You’ll lose all the great features.</span>
      </p>

      <Button className={styles['button-inner']} onClick={handleCloseLayer}>
        NO, I’LL KEEP MY PLAN
      </Button>
      <span className={styles['downgrade-button']} onClick={onCancelMembership}>
        Yes, Downgrade to Free
      </span>
    </div>
  )

  const descLayer = (
    <div className={styles['desc-container']}>
      <h3>Downgrade Plan</h3>
      <img src={CancelMembershipImg} className={styles['img-inner']} alt="" />
      <p className={styles['p-section']}>
        <span>Are you sure you want to downgrade to the free plan?&nbsp;</span>
        <span>You’ll lose all the great features:</span>
      </p>
      <ul className={styles['description-ul']}>
        {descLayerTexts.map((text, index) => (
          <li key={index} className={styles['description-li']}>
            <CloseOutlined className={styles['close-icon']} />
            {text}
          </li>
        ))}
      </ul>
    </div>
  )

  return <CommonFrame actionLayer={actionLayer} descLayer={descLayer} />
}

CancelMembership.propTypes = {
  onCancelMembership: PropType.func
}

export default CancelMembership
