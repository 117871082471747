import { FlyerLayoutAbstract } from './flyer-layout.abstract'

export default class Flyer_layout_bottom_flourish_border_029LayoutModel extends FlyerLayoutAbstract {
  flourishes = [
    {
      name: 'fancy_flourish_frame',
      left: 0,
      top: 0,
      width: 320,
      height: 568
    },
    {
      name: 'fancy_line',
      left: 61,
      top: 360,
      width: 219,
      height: 14
    }
  ]

  textLayouts = {
    event_name_1: {
      top: 136,
      right: 40,
      size: 40,
      width: 220,
      height: 220,
      align: 'right',
      verticalAlign: 'bottom',
      rowGap: 30
    },
    location_1: {
      top: 450,
      right: 40,
      size: 16,
      width: 230,
      height: 20,
      align: 'right'
    },
    location_2: {
      top: 470,
      right: 40,
      size: 16,
      width: 230,
      height: 50,
      align: 'right'
    }
  }

  dateLayouts = {
    date_1: {
      top: 390,
      right: 40,
      daySize: 20,
      width: 300,
      align: 'right',
      lineHeight: 3
    }
  }

  constructor(layout, eventData) {
    super(layout, eventData)
    this.init()
  }

  getDateTexts() {
    const endDate = this.eventData.ends_at
    const { daySize, top, align, lineHeight, width, right } = this.dateLayouts.date_1

    const dateInformation = this.formatDates({
      day: 'MMM ∙ Do ∙ h:mmA',
      time: 'h:mmA'
    })

    const { formattedStart, formattedEnd } = dateInformation
    let dates = []
    let textTop = top

    // 开始时间
    dates.push({
      text: formattedStart,
      align,
      right,
      width,
      top: textTop,
      color: this.dateProps.date1Props.color.replace('0x', '#'),
      font: this.dateProps.date1Props.font.replace(/\s+/g, '-'),
      size: daySize
    })

    textTop += daySize + lineHeight

    if (!endDate) {
      return { type: 'date', dates }
    }

    // 结束时间
    dates.push({
      text: 'to ' + formattedEnd,
      top: textTop,
      width,
      align,
      right,
      color: this.dateProps.date2Props.color.replace('0x', '#'),
      font: this.dateProps.date2Props.font.replace(/\s+/g, '-'),
      size: daySize
    })
    return { type: 'date', dates }
  }
}
