import PropTypes from 'prop-types'
import React from 'react'
import Button from 'components/common/Button'

import TriangleDownIcon from 'src/assets/icons/triangle-down-icon.svg'
import styles from './guest-count-controls.scss'
import { isHobnobAndroidClient } from '../../services/utils'
import HobnobSelect from '../common/HobnobSelect'
import SvgIcon from 'src/components/common/SvgIcon'
import SelectElement from 'components/forms/SelectElement'

export default class GuestCountControls extends React.Component {
  _onSubmit = (e) => {
    e.preventDefault()
  }
  _renderRow (count, onChangeCount, countType) {
    const ChangeCount = (value) => {
      onChangeCount(parseInt(value))
    }

    const label = this._renderLabel(countType)

    return (
      <div className={styles['row']}>
        <form className={styles['select-wrapper']} data-label={label} onSubmit={this._onSubmit}>
          <div>{label}</div>

          {
            isHobnobAndroidClient() ? <HobnobSelect
              className={styles['count-select-android']}
              inputClassName={styles['count-input']}
              onChange={ChangeCount}
              value={count.toString()}
              options={this._countOptions(countType)} />
              : (
                <div className={styles['count-select-wrapper']}>
                  <div className={styles['triangle-down-wrapper']}>
                    {count.toString()}
                    <SvgIcon icon={TriangleDownIcon} className={styles['triangle-down-icon']} />
                  </div>
                  <SelectElement
                    className={styles['count-select']}
                    value={count.toString()}
                    onChange={(e) => ChangeCount(parseInt(e.target.value))}
                    options={this._countOptions(countType)}
                  />
                </div>
              )
          }
          <input style={{ display: 'none' }} type='submit' />
        </form>
      </div>
    )
  }
  _countOptions (countType) {
    let options = []
    let i = countType === 'guest' ? 1 : 0
    for (i; i <= 10; i++) {
      options.push(i)
    }
    return options.map((value) => {
      return {
        value: value.toString(),
        text: value.toString()
      }
    })
  }
  _renderLabel (countType) {
    const { childCountEnabled } = this.props

    if (countType === 'guest') {
      return childCountEnabled ? 'Adults' : 'Guests'
    } else {
      return 'Children'
    }
  }

  _validConfirm = () => {
    const {
      guestCount
    } = this.props

    return !guestCount
  }

  render () {
    const {
      guestCount,
      childCount,
      changeRsvp,
      guestCountEnabled,
      childCountEnabled,
      onChangeGuestCount,
      onChangeChildCount,
      onConfirm
    } = this.props

    return (
      <div className={styles['container']}>
        {guestCountEnabled
          ? this._renderRow(guestCount, onChangeGuestCount, 'guest')
          : null
        }
        {(guestCountEnabled && childCountEnabled)
          ? this._renderRow(childCount, onChangeChildCount, 'child')
          : null
        }
        <Button disabled={this._validConfirm()} className={styles['button']} onClick={onConfirm}>{changeRsvp ? 'save changes ' : 'confirm'}</Button>
      </div>
    )
  }
}

GuestCountControls.propTypes = {
  guestCountEnabled: PropTypes.bool.isRequired,
  childCountEnabled: PropTypes.bool.isRequired,
  guestCount: PropTypes.number,
  childCount: PropTypes.number,
  onChangeGuestCount: PropTypes.func,
  onChangeChildCount: PropTypes.func,
  onConfirm: PropTypes.func,
  changeRsvp: PropTypes.bool
}
