import moment from 'moment'

export class FlyerLayoutAbstract {
  flourishes = []
  textLayouts = {}
  dateLayouts = {}
  availableTextsProps = []
  availableDatesProps = []
  dateProps = {
    date1Props: null,
    date2Props: null,
    date3Props: null,
    dateSeparatorProps: null
  }
  datePropsConfigsMapping = {
    date1: 'date_1',
    date2: 'date_2',
    date3: 'date_3',
    dateSeparator: 'date_separator'
  }

  constructor(layout, eventData) {
    this.properties = layout.layout_properties
    this.slug = layout.layout_slug
    this.eventData = eventData
  }

  init() {
    const textFields = Object.keys(this.textLayouts)
    // this.availableTextsProps = this.properties.filter(prop => {
    //   return prop.font && textFields.includes(prop.slug)
    // })

    this.availableTextsProps = textFields.map(field => {
      let availableText = this.properties.find(prop => prop.slug === field)

      if (!availableText.font && (field === 'location_2' || field === 'location_3')) {
        availableText = this.properties.find(prop => prop.slug === 'location_1')
        availableText = { ...availableText, slug: field }
      }

      return availableText
    })

    const dateFields = ['date_1', 'date_2', 'date_3', 'date_separator']
    this.availableDatesProps = this.properties.filter(item => {
      return item.font && dateFields.indexOf(item.slug) > -1
    })

    this.dateProps.date1Props = this.getDateLayoutPropByKey('date1')
    this.dateProps.date2Props = this.getDateLayoutPropByKey('date2')
    this.dateProps.date3Props = this.getDateLayoutPropByKey('date3')
    this.dateProps.dateSeparatorProps = this.getDateLayoutPropByKey('dateSeparator')
  }

  getTexts() {
    let texts = this.availableTextsProps.map(prop => {
      return {
        slug: prop.slug,
        font: prop.font.replace(/\s+/g, '-'),
        ...this.textLayouts[prop.slug],
        color: prop.color.replace('0x', '#'),
        text: this.getTextContent(prop)
      }
    })
    if (this.eventData.starts_at) {
      texts = texts.concat(this.getDateTexts())
    }
    return texts
  }

  getDateTexts() {
    throw new Error('getDateTexts() method must be implemented')
  }

  getFlourishes() {
    return this.flourishes
  }

  getTextContent(layoutProp) {
    let text
    const shouldUseStreetAsName = this.eventData.venue?.name === ''

    switch (layoutProp.slug) {
      case 'event_name_1':
        text = this.eventData.name
        break
      case 'location_1':
        const locationText1 = this.getLocationText1?.()
        text =
          locationText1 ||
          (this.eventData.venue?.name
            ? this.eventData.venue.name
            : shouldUseStreetAsName
            ? this.eventData.venue?.street
            : this.eventData.custom_venue_name)

        break
      case 'location_2':
        const locationText2 = this.getLocationText2?.()
        text = locationText2 || (shouldUseStreetAsName ? '' : this.eventData.venue?.street)
        break
      case 'location_3':
        const locationText3 = this.getLocationText3?.()
        const str = [
          this.eventData.venue?.city,
          this.eventData.venue?.state,
          this.eventData.venue?.postal_code
        ]
          .filter(d => !!d)
          .join(', ')
        text = locationText3 || str

        break
    }

    return layoutProp.all_caps ? text?.toUpperCase() : text
  }

  getDateLayoutPropByKey(key) {
    return this.properties.find(prop => prop.slug === this.datePropsConfigsMapping[key])
  }

  formatDates(format) {
    const startDate = this.eventData.starts_at
    const endDate = this.eventData.ends_at

    const isSameDay = moment(startDate).isSame(endDate, 'day')
    const { date1Props, date2Props, date3Props } = this.dateProps

    const commonDateProps = date1Props || date2Props || date3Props

    const formattedStart = commonDateProps.all_caps
      ? moment(startDate).format(format.day).toUpperCase()
      : moment(startDate).format(format.day)

    const formattedEnd = isSameDay
      ? commonDateProps.all_caps
        ? moment(endDate).format(format.time).toUpperCase()
        : moment(endDate).format(format.time)
      : commonDateProps.all_caps
      ? moment(endDate).format(format.day).toUpperCase()
      : moment(endDate).format(format.day)

    return {
      formattedStart,
      formattedEnd,
      isSameDay
    }
  }
}
