import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import OverlayLoadingIndicator from 'components/common/OverlayLoadingIndicator'
import {
  eventSelector,
  hasPaidTicketsSelector,
  initialLoadingPaymentModalSelector,
  invitationSelector,
  isInvitationCredentialUserSelector,
  listMyOrdersForEventSelector,
  paymentMethodsListSelector,
  rsvpModalChangeRsvpSelector,
  rsvpModalHasChangedRsvpStatusSelector,
  showCreateNewCardModalSelector,
  showInvitationReplyFormModalSelector,
  showTicketCountControlModalSelector,
  showTotalTicketsCountsModalSelector,
  ticketTypesCountsSelector,
  receiptEmailSelector
} from '../../selectors'
import styles from './paid-tickets-container.scss'
import TotalTicketsCounts from './TotalTicketsCounts'
import Button from '../common/Button'
import {
  createPaymentMethodMutation,
  showCreateNewCardModal,
  showTicketCountControlModal,
  showTotalTicketCountsModal
} from '../../actions/paymentGraphql'
import TicketsCountsSelectorModal from './TicketsCountsSelectorModal'
import { changeTicketCounts, defaultPaymentMethod, isEmptyObject, pluralize, sumCount } from '../../services/utils'
import LeftChevronIcon from '../../assets/icons/left_chevron.svg'
import CreateNewCardForm from './CreateNewCardForm'

import SvgIcon from 'components/common/SvgIcon'
import InvitationReplyFormContainer from './InvitationReplyFormContainer'
import {
  getDisplayOrderTickets
} from '../../services/payment_utils'

class PaidTicketsContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ticketCounts: [],
      paymentMethod: {},
      orderLineItems: []
    }
  }

  componentWillMount () {
    this._setDefaultState()
  }

  componentDidMount () {
    this._presetGuestCounts()
  }

  componentWillReceiveProps (nextProps) {
    let tempTicketCounts = this.state.ticketCounts

    if (!tempTicketCounts.length) {
      tempTicketCounts = nextProps.ticketTypesCounts.map(item => {
        return {
          ...item,
          count: 0
        }
      })
      this.setState({
        ticketCounts: tempTicketCounts
      })
    }

    if (isEmptyObject(this.state.paymentMethod) && this.props.paymentMethodsList.length !== 0) {
      this.setState({
        paymentMethod: defaultPaymentMethod(this.props.paymentMethodsList)
      })
    }
  }

  // If request guest count is not enabled than the guest can only buy one ticket so pre-set the amount to 1 for them
  _presetGuestCounts = () => {
    const { guestCountEnabled, ticketTypesCounts } = this.props

    // Note: This has to happen after setDefaultState because _onChangeCount
    // relies on ticketCounts from state (that's why this is in componentDidMount)
    if (!guestCountEnabled) {
      const adultTicketType = ticketTypesCounts.filter(ticketType => ticketType.label === 'adult' && !ticketType.archived_at)[0]
      this._onChangeCount(adultTicketType.id, 1)
    }
  }

  _setDefaultState = () => {
    const { ticketTypesCounts, paymentMethodsList, onSetOrderLineItems } = this.props

    const ticketCounts = ticketTypesCounts.map(item => {
      return {
        ...item,
        count: 0
      }
    })

    const paymentMethod = paymentMethodsList.length ? defaultPaymentMethod(paymentMethodsList) : {}

    this.setState({
      ticketCounts,
      paymentMethod,
      orderLineItems: []
    })

    onSetOrderLineItems([])
  }

  _viewOrders = (ordersWithCounts) => {
    const { onChangeRouter, onSetCurrentOrderId } = this.props
    if (ordersWithCounts.length > 1) {
      onChangeRouter('orders')
    } else {
      onSetCurrentOrderId(ordersWithCounts[0].id)
      onChangeRouter('orderDetail')
    }
  }

  _buyMoreTickets = () => {
    const { onShowTicketCountControlModal } = this.props
    onShowTicketCountControlModal()
  }

  _confirmAndPay = (totalPrice, paymentMethod) => {
    const { invitation, isInvitationCredentialUser, onPurchaseTickets, email } = this.props
    const { orderLineItems } = this.state
    const invitationId = invitation.id
    let paymentMethodId = null
    if (totalPrice > 0) {
      paymentMethodId = paymentMethod.id
    }

    if (orderLineItems.length) {
      onPurchaseTickets(invitationId, orderLineItems, paymentMethodId, !isInvitationCredentialUser, email)
    }
  }

  _onChangeCount = (ticketTypeId, count) => {
    let { ticketCounts } = this.state
    ticketCounts = changeTicketCounts(ticketCounts, ticketTypeId, count)
    this.setState({
      ticketCounts
    })

    this._setTempTicketTypesCounts(ticketCounts)

    this._setOrderLineItems(ticketCounts)
  }

  _setTempTicketTypesCounts = (ticketCounts) => {
    const { onSetTempTicketTypesCounts, ticketTypesCounts } = this.props
    const tempTicketTypesCounts = ticketTypesCounts.map((item) => {
      const findTicket = ticketCounts.find((ticket) => ticket.id === item.id)
      return {
        ...item,
        count: item.count + findTicket.count
      }
    })
    onSetTempTicketTypesCounts(tempTicketTypesCounts)
  }

  _setOrderLineItems = (ticketCounts) => {
    const { onSetOrderLineItems } = this.props
    let queryVariables = []
    ticketCounts.forEach((item) => {
      if (item.count) {
        queryVariables.push({
          ticket_type_id: item.id,
          count: item.count
        })
      }
    })

    this.setState({
      orderLineItems: queryVariables
    })

    onSetOrderLineItems(queryVariables)
  }

  _setPaymentMethod = (paymentMethod) => {
    this.setState({
      paymentMethod
    })
  }

  _renderTicketsCountsSelector = (totalTicketsCounts) => {
    const {
      event,
      ticketTypesCounts,
      showTicketCounts,
      paymentMethodsList,
      onShowCreateNewCardModal
    } = this.props
    const { ticketCounts, paymentMethod } = this.state

    return <TicketsCountsSelectorModal
      totalTicketsCounts={totalTicketsCounts}
      ticketCounts={ticketCounts}
      paymentMethod={paymentMethod}
      onChangeCount={this._onChangeCount}
      setPaymentMethod={this._setPaymentMethod}
      event={event}
      ticketTypesCounts={ticketTypesCounts}
      paymentMethodsList={paymentMethodsList}
      showTicketCounts={showTicketCounts}
      showCreateNewCardModal={onShowCreateNewCardModal}
      onConfirm={this._confirmAndPay}
      applePay={this._addNewCard}
      onBackPage={this._backToTotalTicketCountsModal}
    />
  }

  _addNewCard = (tokenSid) => {
    const {
      invitation,
      isInvitationCredentialUser,
      onPurchaseTickets,
      onCreatePaymentMethodMutation,
      email
    } = this.props

    const { orderLineItems } = this.state

    if (isInvitationCredentialUser) {
      const invitationId = invitation.id
      onPurchaseTickets(invitationId, orderLineItems, tokenSid, false, email)
    } else {
      onCreatePaymentMethodMutation(tokenSid)
    }
  }

  _renderCreateNewCardForm = () => {
    const { onShowTicketCountControlModal } = this.props
    return (
      <div className={styles['create-new-card-form-wrapper']}>
        <SvgIcon className={styles['left-chevron-icon']} icon={LeftChevronIcon} padding='5px'
          onClick={onShowTicketCountControlModal} divClassName={styles['icon-wrapper']} />
        <h3 className={styles['title']}>Pay with a New Card</h3>
        <div>Enter credit or debit card</div>
        <CreateNewCardForm addNewCard={this._addNewCard} />
      </div>
    )
  }

  _backToTotalTicketCountsModal = () => {
    const { onShowTotalTicketCountsModal } = this.props

    onShowTotalTicketCountsModal()
  }

  _onClickAttending = () => {
    const { onShowTotalTicketCountsModal } = this.props

    this._setDefaultState()

    onShowTotalTicketCountsModal()
  }

  _renderInvitationReplyForm () {
    const {
      invitationReplyForm,
      invitation,
      guestCountEnabled,
      changeRsvp,
      hasChangedRsvpStatus,
      showInvitationReplyFormModal
    } = this.props

    return <InvitationReplyFormContainer
      hasChangedRsvpStatus={hasChangedRsvpStatus}
      invitation={invitation}
      guestCountEnabled={guestCountEnabled}
      onClickAttending={this._onClickAttending}
      changeRsvp={changeRsvp}
      showInvitationReplyFormModal={showInvitationReplyFormModal}
      invitationReplyForm={invitationReplyForm}
    />
  }

  _renderComponent = () => {
    const {
      changeRsvp,
      guestCountEnabled,
      ticketTypesCounts,
      event,
      listMyOrdersForEvent,
      showTicketCountControlModal,
      showCreateNewCardModal,
      showTotalTicketsCountsModal,
      showInvitationReplyFormModal,
      hasChangedRsvpStatus,
      onDeclineInvitation
    } = this.props
    const totalTicketsCounts = sumCount(ticketTypesCounts)

    if (showTicketCountControlModal) {
      return this._renderTicketsCountsSelector(totalTicketsCounts)
    }

    if (showCreateNewCardModal) {
      return this._renderCreateNewCardForm()
    }

    if (showInvitationReplyFormModal) {
      return this._renderInvitationReplyForm()
    }

    if (totalTicketsCounts || changeRsvp || showTotalTicketsCountsModal) {
      const ordersWithCounts = getDisplayOrderTickets(listMyOrdersForEvent, ticketTypesCounts)
      const label = pluralize(ordersWithCounts.length, 'order')

      return <div className={styles['wrapper']}>
        <h3 className={styles['title']}>Yes, I’m Going</h3>
        <div className={styles['event-name']}>{event.name}</div>
        <TotalTicketsCounts needPayTicket
          ticketCounts={ticketTypesCounts}
          listMyOrdersForEvent={listMyOrdersForEvent}
          className={styles['total-tickets-counts']}
        />
        {!!guestCountEnabled &&
          <Button onClick={this._buyMoreTickets} className={styles['button']}>buy more tickets</Button>
        }
        {
          !!ordersWithCounts.length &&
          <a className={styles['view-order-link']} onClick={() => this._viewOrders(ordersWithCounts)}>view {label}</a>
        }

        {
          changeRsvp && !hasChangedRsvpStatus &&
          <div className={styles['change-rsvp-label']}>Change RSVP: <a className={styles['link']}
            onClick={onDeclineInvitation}>No,
            Can't Go</a></div>
        }
      </div>
    } else {
      return this._renderTicketsCountsSelector(totalTicketsCounts)
    }
  }

  render () {
    const { initialLoadingPaymentModal } = this.props
    return (
      <div>
        {this._renderComponent()}
        <OverlayLoadingIndicator loading={initialLoadingPaymentModal} light={false} />
      </div>
    )
  }
}

PaidTicketsContainer.propTypes = {
  // Container props
  changeRsvp: PropTypes.bool,
  event: PropTypes.object,
  guestCountEnabled: PropTypes.bool,
  hasChangedRsvpStatus: PropTypes.bool,
  initialLoadingPaymentModal: PropTypes.bool,
  invitation: PropTypes.object,
  isInvitationCredentialUser: PropTypes.bool,
  listMyOrdersForEvent: PropTypes.array,
  paymentMethodsList: PropTypes.array,
  showCreateNewCardModal: PropTypes.bool,
  showInvitationReplyFormModal: PropTypes.bool,
  showTicketCountControlModal: PropTypes.bool,
  showTicketCounts: PropTypes.bool,
  showTotalTicketsCountsModal: PropTypes.bool,
  ticketTypesCounts: PropTypes.array,

  // Direct props
  invitationReplyForm: PropTypes.element,
  onChangeRouter: PropTypes.func,
  onCreatePaymentMethodMutation: PropTypes.func,
  onDeclineInvitation: PropTypes.func,
  onPurchaseTickets: PropTypes.func,
  onSetCurrentOrderId: PropTypes.func,
  onSetOrderLineItems: PropTypes.func,
  onSetTempTicketTypesCounts: PropTypes.func,
  onShowCreateNewCardModal: PropTypes.func,
  onShowTicketCountControlModal: PropTypes.func,
  onShowTotalTicketCountsModal: PropTypes.func,

  email: PropTypes.string
}

function mapStateToProps (state) {
  const event = eventSelector(state)
  const ticketTypesCounts = ticketTypesCountsSelector(state)

  return {
    changeRsvp: rsvpModalChangeRsvpSelector(state),
    event,
    guestCountEnabled: event.guest_count_enabled,
    hasChangedRsvpStatus: rsvpModalHasChangedRsvpStatusSelector(state),
    initialLoadingPaymentModal: initialLoadingPaymentModalSelector(state),
    invitation: invitationSelector(state),
    isInvitationCredentialUser: isInvitationCredentialUserSelector(state),
    listMyOrdersForEvent: listMyOrdersForEventSelector(state),
    paymentMethodsList: paymentMethodsListSelector(state),
    showCreateNewCardModal: showCreateNewCardModalSelector(state),
    showInvitationReplyFormModal: showInvitationReplyFormModalSelector(state),
    showTicketCountControlModal: showTicketCountControlModalSelector(state),
    showTicketCounts: hasPaidTicketsSelector(state),
    showTotalTicketsCountsModal: showTotalTicketsCountsModalSelector(state),
    ticketTypesCounts,
    email: receiptEmailSelector(state)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    onShowCreateNewCardModal: () => dispatch(showCreateNewCardModal()),
    onShowTicketCountControlModal: () => dispatch(showTicketCountControlModal()),
    onCreatePaymentMethodMutation: (tokenSid) => dispatch(createPaymentMethodMutation(tokenSid)),
    onShowTotalTicketCountsModal: () => dispatch(showTotalTicketCountsModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaidTicketsContainer)
