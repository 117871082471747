import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import moment from 'moment'
import { eventSelector, usersSelector, userIdSelector } from 'src/selectors'
import SendMessageIcon from 'src/assets/icons/send-message-icon.png'
import cn from 'classnames'
import { switchChatUser } from 'src/actions/eventChat'
import {
  HOBNOB_USER_CONTACT_METHOD,
  PHONE_NUMBER_CONTACT_METHOD
} from 'src/services/contact_methods'
import { hasUserAccessToken } from 'src/services/access_tokens'

import styles from './guest-groups-list.scss'

const GuestGroupsList = ({ guestsList, onClickGuest, onGetUser }) => {
  const dispatch = useDispatch()
  const event = useSelector(eventSelector)
  const users = useSelector(usersSelector)
  const userId = useSelector(userIdSelector)

  const sortGuestsList = guestsList => {
    const sortedLists = guestsList.sort((a, b) => {
      const displayName_A = onGetUser(a).displayName
      const displayName_B = onGetUser(b).displayName

      return displayName_A.localeCompare(displayName_B)
    })
    return sortedLists
  }

  const filterGroup = () => {
    const host = []
    const cohost = []
    const accepted = []
    const declined = []
    const guests = []

    guestsList.forEach(item => {
      if (item.guest === event.host) {
        host.push(item)
      } else if (item.cohost) {
        cohost.push(item)
      } else if (item.rsvp_state === 'accepted') {
        accepted.push(item)
      } else if (item.rsvp_state === 'declined') {
        declined.push(item)
      } else {
        guests.push(item)
      }
    })

    return (
      <>
        {renderGroup('host', sortGuestsList(host))}
        {renderGroup('co-hosts', sortGuestsList(cohost))}
        {renderGroup('attending', sortGuestsList(accepted))}
        {renderGroup('guests', sortGuestsList(guests))}
        {renderGroup('not attending', sortGuestsList(declined))}
      </>
    )
  }

  const renderStatusText = guest => {
    const { guest_count, child_count, rsvp_state, delivery_state, viewed_at, delivered_at } = guest

    const isCanChat =
      (guest.contact_method?.type === HOBNOB_USER_CONTACT_METHOD ||
        guest.contact_method?.type === PHONE_NUMBER_CONTACT_METHOD) &&
      hasUserAccessToken()

    if (event.host === userId && event.host === guest.guest) return

    const isAccepted = rsvp_state === 'accepted'
    const isDeclined = rsvp_state === 'declined'
    const isUndecided = rsvp_state === 'undecided'
    const isDelivered = delivery_state === 'delivered'
    const isViewed = delivery_state === 'viewed'
    const isFailed = delivery_state === 'delivery_failed' || delivery_state === 'delivery_aborted'
    const isQueued = delivery_state === 'queued'
    const isPending = delivery_state === 'pending'

    if (isCanChat && event.host === guest.guest && event.host !== userId) {
      return (
        <img
          onClick={e => {
            e.stopPropagation()
            const user = users[guest.guest]
            dispatch(switchChatUser(user))
          }}
          className={styles['chat-icon']}
          width={24}
          src={SendMessageIcon}
          alt="chat"
        />
      )
    } else if (isAccepted) {
      return (
        <div className={styles['accepted-text']}>
          <div className={styles['text']}>Yes</div>
          <div className={styles['guest-text']}>
            {guest_count > 0 && (
              <div className={styles['guest-count']}>
                {guest_count} Adult{guest_count > 1 ? 's' : ''}
              </div>
            )}
            {child_count > 0 && (
              <div className={styles['child-count']}>
                {child_count} {child_count > 1 ? 'Children' : 'Child'}
              </div>
            )}
          </div>
        </div>
      )
    } else if (isDeclined) {
      return <div className={styles['declined-text']}>No</div>
    } else if (isUndecided && isDelivered) {
      return (
        <div className={styles['delivered-text']}>
          <div className={styles['text']}>Delivered</div>
          <div className={styles['time']}>{moment(delivered_at).format('MM.DD.YYYY')}</div>
        </div>
      )
    } else if (isUndecided && isViewed) {
      return (
        <div className={styles['delivered-text']}>
          <div className={styles['text']}>Viewed</div>
          <div className={styles['time']}>{moment(viewed_at).format('MM.DD.YYYY')}</div>
        </div>
      )
    } else if (isFailed) {
      return <div className={styles['declined-text']}>Failed</div>
    } else if (isQueued) {
      return (
        <div className={styles['delivered-text']}>
          <div className={styles['text']}>Sending</div>
        </div>
      )
    } else if (isPending) {
      return (
        <div className={styles['delivered-text']}>
          <div className={styles['unsent']}>Unsent</div>
        </div>
      )
    }
  }

  const renderGroup = (title, list) => {
    if (list.length === 0) return null
    const isHost = title === 'host'
    const isCohost = title === 'co-hosts'
    const isNumberVisible = !isHost && !isCohost

    return (
      <div className={styles['group-wrapper']}>
        <div className={styles['group-title']}>
          {title} {isNumberVisible && `- ${list.length}`}
        </div>
        <ul className={styles['group-content']}>
          {list.map(item => {
            const user = onGetUser(item)
            const sendFailed =
              item.delivery_state === 'delivery_failed' ||
              item.delivery_state === 'delivery_aborted'

            return (
              <li
                className={cn(styles['list-item'], { [styles['list-item-failed']]: sendFailed })}
                key={item.id}
                onClick={() => onClickGuest(item)}
              >
                <UserAvatarContainer user={user} url={item.avatar_url} size={34} />
                <div className={styles['info-wrapper']}>
                  <span className={styles['name']}>{user.displayName}</span>
                  <span className={styles['contact-text']}>
                    {isHost ? 'Event Host' : item.contact_method_display_name}
                  </span>
                </div>

                <div className={styles['status-wrapper']}>{renderStatusText(item)}</div>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return <div className={styles['guest-groups-list-container']}>{filterGroup()}</div>
}

GuestGroupsList.propTypes = {
  guestsList: PropTypes.array,
  onClickGuest: PropTypes.func,
  onGetUser: PropTypes.func
}

export default GuestGroupsList
