import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fullName as userFullName } from 'src/services/user_helpers'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import PropTypes from 'prop-types'
import Button from 'src/components/common/Button'
import SvgIcon from 'src/components/common/SvgIcon'
import cn from 'classnames'
import LogoutIcon from 'assets/icons/ico-logout.svg'
import MembershipInfo from '../../MembershipInfo/MembershipInfo'
import { getPlanBySlug, PlanType, PlanSlugs } from 'src/services/stripe_checkout_configs'
import { fetchUserGQ } from 'src/actions/user'
import { logout } from 'src/actions/login'
import { Skeleton } from 'antd'
import PopConfirm from 'components/common/PopConfirm/PopConfirm'
import { userSelector, userLoadingSelector } from 'src/selectors'
import { changePlanModalVisible } from 'src/actions/plan'
import { changeVisible, setContent } from 'src/actions/globalLayer'
import { verifySubscriptionPlatform } from 'src/services/frontend_tips'
import { GLOBAL_LAYER_CONTENT } from 'src/constants'
import { CloseOutlined } from '@ant-design/icons'

import styles from './profile-panel.scss'

const ProfilePanel = props => {
  const { onResetMenuState } = props
  const dispatch = useDispatch()
  const [isLogoutConfirmVisible, setIsLogoutConfirmVisible] = useState(false)
  const user = useSelector(userSelector)
  const userLoading = useSelector(userLoadingSelector)
  const { plan, subscription_type } = user?.current_subscription || {}
  const planType = getPlanBySlug(plan?.slug, 'planType') || PlanType.Free

  const handleLogOut = () => {
    setIsLogoutConfirmVisible(false)
    dispatch(logout())
  }

  useEffect(() => {
    dispatch(fetchUserGQ())
  }, [])

  const handleClickSubscribe = () => {
    if (verifySubscriptionPlatform(subscription_type)) {
      onResetMenuState()
      dispatch(changePlanModalVisible(true))
    }
  }

  if (userLoading) {
    return (
      <div className={styles['skeleton-container']}>
        <Skeleton active avatar paragraph={{ rows: 6 }} title />
      </div>
    )
  }

  const renderSubscriptionButton = () => {
    if (plan.slug === PlanSlugs.GroupOrganizerAnnually) {
      return
    }

    if (planType === PlanType.Free) {
      return (
        <>
          <Button className={styles['purchase-button']} onClick={handleClickSubscribe}>
            {user.is_trial_available ? 'REDEEM YOUR FREE WEEK' : 'Subscribe'}
          </Button>

          <div
            className={styles['free-plan-link']}
            onClick={() => {
              dispatch(changeVisible(true))
              dispatch(setContent(GLOBAL_LAYER_CONTENT.freeMembership))
            }}
          >
            What do I get on Free Plan?
          </div>
        </>
      )
    }

    return (
      <Button
        className={cn(styles['purchase-button'], styles[planType])}
        onClick={handleClickSubscribe}
      >
        SEE UPGRADE OPTIONS
      </Button>
    )
  }

  return (
    <>
      <section className={cn(styles['profile-menu-panel'], styles[planType])}>
        <div className={styles['header-icon']}>
          <SvgIcon
            icon={LogoutIcon}
            width={24}
            height={24}
            onClick={() => setIsLogoutConfirmVisible(true)}
          />
          <CloseOutlined style={{ fontSize: 24 }} onClick={onResetMenuState} />
        </div>
        <div className={styles['menu-avatar-name']}>
          <UserAvatarContainer user={user} size={100} inProfile />
          <h2 className={styles['avatar-h2']}>{userFullName(user)}</h2>
        </div>
        <div className={styles['membership-info']}>
          <MembershipInfo />
        </div>

        {renderSubscriptionButton()}
      </section>
      <PopConfirm
        visible={isLogoutConfirmVisible}
        onClose={() => setIsLogoutConfirmVisible(false)}
        cancelText="No"
        confirmText="Yes"
        type="cancel"
        cancelType="normal"
        onConfirm={handleLogOut}
      >
        Are you sure you want to log out?
      </PopConfirm>
    </>
  )
}

ProfilePanel.propTypes = {
  onResetMenuState: PropTypes.func
}

export default ProfilePanel
