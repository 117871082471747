import { take, put, select, spawn } from 'redux-saga/effects'

import { normalizrResult } from 'services/utils'

import {
  invitationsSelector,
  mediaSelector,
  routeSelector,
  userIdSelector
} from 'src/selectors'

import {
  showToastMessage
} from 'src/actions/toastMessages'

import { pusherEventChannel } from '../pusher'

export default function * pusherRootSaga () {
  while (true) {
    const action = yield take(pusherEventChannel)
    switch (action.payload.eventType) {
      case 'comment-created':
        yield spawn(commentCreatedSaga, action)
        break
      case 'event-update-created':
        yield spawn(eventUpdateSaga, action)
        break
      case 'invitation-updated':
        yield spawn(invitationUpdatedSaga, action)
        break
      case 'like-created':
        yield spawn(likeCreatedSaga, action)
        break
      case 'photo-processed':
        yield spawn(photoProcessedSaga, action)
    }
  }
}

function * invitationUpdatedSaga (action) {
  const invitationId = action.response.result.invitation
  const invitations = yield select(invitationsSelector)
  const oldInvitation = invitations[invitationId]

  if (oldInvitation) {
    const nextInvitation = action.response.entities.invitations[invitationId]
    const user = action.response.entities.users[nextInvitation.guest]
    if (oldInvitation.rsvp_state !== 'accepted' && nextInvitation.rsvp_state === 'accepted') {
      yield put(showToastMessage({ user, messageType: 'rsvp-yes' }))
    }
  }
}

function * eventUpdateSaga (action) {
  const eventUpdate = normalizrResult(action.response, 'event_updates', 'event_update')
  const user = action.response.entities.users[eventUpdate.user]
  yield put(showToastMessage({ user, messageType: 'event-update', messageContent: eventUpdate.content }))
}

function * commentCreatedSaga (action) {
  const currentUserId = yield select(userIdSelector)

  if (action.response.entities.event_comments) {
    const comment = normalizrResult(action.response, 'event_comments', 'comment')
    const commentUser = action.response.entities.users[comment.user]
    const route = yield select(routeSelector)

    if (!comment.metadata.body_type) {
      if (comment.user !== currentUserId && route !== '/comments') {
        yield put(showToastMessage({ user: commentUser, messageType: 'event-comment', messageContent: comment.content }))
      }
    }
  } else if (action.response.entities.media_comments) {
    const comment = normalizrResult(action.response, 'media_comments', 'comment')
    const commentUser = action.response.entities.users[comment.user]
    const media = yield select(mediaSelector)
    const medium = media[comment.commentable_id]

    if (medium && currentUserId && comment.user !== currentUserId) {
      // Send notification if someone has commented on the current user's media
      if (medium.user === currentUserId) {
        yield put(showToastMessage({ user: commentUser, messageType: 'media-comment', messageContent: comment.content }))
      }
    }
  }
}

function * likeCreatedSaga (action) {
  const like = normalizrResult(action.response, 'likes', 'like')
  const likeUser = action.response.entities.users[like.user]
  const media = yield select(mediaSelector)
  const medium = media[like.likeable_id]
  const currentUserId = yield select(userIdSelector)

  if (medium && currentUserId && like.user !== currentUserId) {
    if (medium.user === currentUserId) {
      yield put(showToastMessage({ user: likeUser, messageType: 'media-like' }))
    }
  }
}

// Used to track if a specific user has already uploaded a media in this current session
let usersWithUploadedMedia = {}
// when someone adds photos
// “[Guest Name/Host Name] added a photo!”
// Show once per session per guest
function * photoProcessedSaga (action) {
  const medium = normalizrResult(action.response, 'media', 'photo')
  const mediumUser = action.response.entities.users[medium.user]

  if (!usersWithUploadedMedia[mediumUser.id]) {
    usersWithUploadedMedia[mediumUser.id] = true
    const currentUserId = yield select(userIdSelector)
    const uploadedByCurrentUser = mediumUser.id === currentUserId
    if (!currentUserId || !uploadedByCurrentUser) {
      yield put(showToastMessage({ user: mediumUser, messageType: 'media-upload' }))
    }
  }
}
