import React from 'react'
import DashboardContainer from 'containers/DashboardContainer'
import InputEventDetail from '../InputEventDetail/InputEventDetail'

const CreateEvent = () => {
  return (
    <DashboardContainer>
      <InputEventDetail />
    </DashboardContainer>
  )
}

export default CreateEvent
