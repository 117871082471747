import '@babel/polyfill'
import 'isomorphic-fetch'
import values from 'object.values'
import 'velocity-react'
import 'velocity-animate/velocity.ui'
import Honeybadger from 'honeybadger-js'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { browserHistory, Router } from 'react-router'
import { routerMiddleware, routerReducer, syncHistoryWithStore } from 'react-router-redux'

import reducers from './reducers'
import { cookieReducer, createCookieMiddleware } from './middleware'
import rootSaga from './sagas'
import { pusherInit } from './sagas/pusher'
import { watchGeolocationPermissionSaga } from './sagas/chat/location'
import routes from './routes'
import Stripe from './Stripe'
import { ErrorManager } from './components/error_manager/ErrorManager'
import 'containers/styles.scss'
import 'assets/styles/cropper.scss' // required for cropperJS in <ImageCropper> component
import PageContainer from './PageContainer'

// Shim Object.values if the browser does not support it.
if (!Object.values) {
  values.shim()
}

Honeybadger.configure({
  api_key: process.env.HONEYBADGER_FRONTEND_API_KEY,
  environment: process.env.HONEYBADGER_ENV,
  disabled: ['development', 'test'].indexOf(process.env.NODE_ENV) > -1
})

const initialState = window.__INITIAL_STATE__

const reducer = combineReducers({
  ...reducers,
  routing: routerReducer,
  cookies: cookieReducer
})

const sagaMiddleware = createSagaMiddleware()
const middleWare = [createCookieMiddleware(), routerMiddleware(browserHistory), sagaMiddleware]

let enhancers = [applyMiddleware(...middleWare)]

if (process.env.NODE_ENV === 'development' || process.env.HONEYBADGER_ENV === 'staging') {
  enhancers.push(
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  )
}

let store = createStore(reducer, initialState, compose(...enhancers))

const history = syncHistoryWithStore(browserHistory, store)

sagaMiddleware.run(rootSaga, store.dispatch)
sagaMiddleware.run(pusherInit, store.dispatch)
sagaMiddleware.run(watchGeolocationPermissionSaga, store.dispatch)
// eslint-disable-next-line import/first
// import debugSaga from './sagas/debugSaga'
// sagaMiddleware.run(debugSaga, store.dispatch)

ReactDOM.render(
  <Stripe>
    <Provider store={store}>
      <ErrorManager>
        <PageContainer>
          <Router history={history} routes={routes} />
        </PageContainer>
      </ErrorManager>
    </Provider>
  </Stripe>,
  document.getElementById('root')
)
