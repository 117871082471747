import {
  SHOW_IMAGE_MODAL,
  HIDE_IMAGE_MODAL
} from 'src/actions/frontend'

const imageModalDefaultState = {
  open: false,
  url: ''
}

export default function imageModal (state = imageModalDefaultState, action) {
  switch (action.type) {
    case SHOW_IMAGE_MODAL:
      return {
        ...state,
        open: true,
        url: action.url
      }
    case HIDE_IMAGE_MODAL:
      return {
        ...state,
        open: false,
        url: imageModalDefaultState.url
      }
    default:
      return state
  }
}
