import React, { Component } from 'react'

import CheckBoxInput from 'components/forms/CheckboxInput'
import Button from 'components/common/Button'
import PropTypes from 'prop-types'
import cn from 'classnames'
import RightIconWhite from 'assets/icons/right-icon-white.png'
import { connect } from 'react-redux'
import { changeSignUpForm } from 'src/actions/timepoll'
import ImageBox from './ImageBox/ImageBox'

import styles from './second-step.scss'

const requestHeader = {
  headers: {
    Authorization: '563492ad6f91700001000001b478983a1a644cc5880756858a4ef689'
  }
}

class SecondStep extends Component {
  constructor() {
    super()
    this.imgRefs = []
    this.imgViewRef = null
  }
  state = {
    pageCount: 60,
    pageNum: 0,
    total: 0,
    imageList: [],
    keywords: 'Generic List',
    collectionsId: null,
    checkedImage: null
  }

  _getImageList = () => {
    const { keywords } = this.state

    fetch(`https://api.pexels.com/v1/collections?per_page=60&page=1`, {
      headers: {
        Authorization: '563492ad6f91700001000001b478983a1a644cc5880756858a4ef689'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        const err = new Error(response.statusText)
        err.response = response
        throw err
      })
      .then(data => {
        if (data.collections.length) {
          const findCollections = data.collections.find(item => {
            let title = item.title === 'Generic List' ? item.title : item.title.toLowerCase()
            return title === keywords
          })

          if (findCollections) {
            this.setState({ collectionsId: findCollections.id }, () => this._getImageListWithId())
          } else {
            this._getImageListWithKeywords()
          }
        }
      })
  }

  _getImageListWithId = () => {
    const { pageCount, pageNum, imageList, collectionsId } = this.state
    fetch(
      `https://api.pexels.com/v1/collections/${collectionsId}?per_page=${pageCount}&page=${
        pageNum + 1
      }`,
      requestHeader
    )
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        const err = new Error(response.statusText)
        err.response = response
        throw err
      })
      .then(data => {
        if (data.media.length > 0) {
          const initialImageList = data.media.map(item => ({
            id: item.id,
            url: item.src.large2x
          }))

          this.setState({
            pageCount: 60,
            pageNum: data.page,
            total: data.total_results,
            imageList: imageList.concat(initialImageList)
          })
        }
      })
  }

  _getImageListWithKeywords = () => {
    const { pageCount, pageNum, imageList, keywords } = this.state

    fetch(
      `https://api.pexels.com/v1/search?query=${keywords}&per_page=${pageCount}&page=${
        pageNum + 1
      }`,
      requestHeader
    )
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        const err = new Error(response.statusText)
        err.response = response
        throw err
      })
      .then(data => {
        if (data.photos.length > 0) {
          const initialImageList = data.photos.map(item => ({
            id: item.id,
            url: item.src.large2x
          }))

          this.setState({
            pageCount: 60,
            pageNum: data.page,
            total: data.total_results,
            imageList: imageList.concat(initialImageList)
          })
        }
      })
  }

  validateKeywords = () => {
    const { signUpForm } = this.props
    const tagList = ['potluck', 'wishlist', 'volunteer']
    const name = signUpForm.name.toLowerCase()

    const keywords = tagList.find(keys => name.search(keys) > -1) || 'Generic List'

    this.setState({ keywords }, () => this._getImageList())
  }

  componentDidMount() {
    if (this.props.signUpForm.checkedImage) {
      this.setState({ checkedImage: this.props.signUpForm.checkedImage })
    }

    this.validateKeywords()
  }

  handleImageClick = item => {
    if (item.id === this.state.checkedImage?.id) {
      this.setState({ checkedImage: null })
    } else {
      this.setState({ checkedImage: item })
    }
  }

  handleButtonClick = () => {
    const { onChangeSignUpForm, onButtonClick, type, onClose, onChangeImage, userId } = this.props

    onChangeSignUpForm({ checkedImage: this.state.checkedImage })
    if (type === 'create') {
      window.mixpanel.track('SELECT AND CONTINUE Clicked', { user_id: userId })
      onButtonClick()
    } else {
      onChangeImage(this.state.checkedImage?.url)
      onClose()
    }
  }

  renderImageList = () => {
    const { imageList, checkedImage } = this.state

    return (
      <ul className={styles['image-wrapper']}>
        {imageList.map(item => {
          const isChecked = item.id === checkedImage?.id

          return (
            <li
              className={cn(styles['image-box'], isChecked && styles['image-box-checked'])}
              key={item.id}
              onClick={() => this.handleImageClick(item)}
            >
              <ImageBox imgSrc={item.url} />
              {isChecked && (
                <CheckBoxInput readOnly className={styles['check-icon']} checked size={20} />
              )}
            </li>
          )
        })}
      </ul>
    )
  }

  handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = this.imgViewRef
    const { collectionsId, pageCount, pageNum, total } = this.state
    if (scrollTop + clientHeight === scrollHeight) {
      const pageNumber = Math.ceil(total / pageCount)
      if (pageNum >= pageNumber) {
        return
      }
      if (collectionsId) {
        this._getImageListWithId()
      } else {
        this._getImageListWithKeywords()
      }
    }
  }

  render() {
    const { type } = this.props
    const { imageList, checkedImage } = this.state

    return (
      <div className={styles['second-step-container']}>
        <div
          onScroll={this.handleScroll}
          ref={ref => (this.imgViewRef = ref)}
          className={styles['image-view']}
        >
          {imageList.length > 0 && this.renderImageList()}
        </div>
        <div className={styles['button-wrapper']}>
          <Button
            className={styles['button-inner']}
            onClick={this.handleButtonClick}
            disabled={!checkedImage}
          >
            <span>{type === 'create' ? 'select and continue' : 'change'}</span>
            <img src={RightIconWhite} alt="" width="24" />
          </Button>
        </div>
      </div>
    )
  }
}

SecondStep.defaultProps = {
  type: 'create'
}

SecondStep.propTypes = {
  userId: PropTypes.string,
  onButtonClick: PropTypes.func,
  onChangeSignUpForm: PropTypes.func,
  signUpForm: PropTypes.object,
  type: PropTypes.string,
  onClose: PropTypes.func,
  onChangeImage: PropTypes.func
}

function mapStateToProps(state) {
  return {
    userId: state.userId,
    signUpForm: state.frontend.signUpForm
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeSignUpForm: params => dispatch(changeSignUpForm(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SecondStep)
