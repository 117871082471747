/* eslint-disable react/jsx-no-bind */
import React, { useState, useRef, useEffect } from 'react'
import SvgIcon from './SvgIcon'
import downArrowIcon from '../../assets/icons/down-arrow.svg'
import upArrorIcon from '../../assets/icons/up-arrow.svg'
import styles from './TextShowOneLine.scss'

function isOverflown(element) {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth
}

// eslint-disable-next-line react/prop-types
export default function TextShowOneLine({ text, style }) {
  const [isOverFlow, setIsOverFlow] = useState(false)
  const [textExpanded, setTextExpanded] = useState(false)
  const textWrapper = useRef(null)

  useEffect(() => {
    setIsOverFlow(isOverflown(textWrapper.current))
  }, [])

  function toggleText() {
    setTextExpanded(!textExpanded)
  }

  return (
    <div className={styles.root}>
      <div ref={textWrapper} style={style}
        className={`${styles['text-wrapper']} ${textExpanded ? styles.expanded : ''} ${(isOverFlow && !textExpanded) ? styles.ellipsis : ''}`}>
        {text}
      </div>
      {isOverFlow && <SvgIcon
        style={{ width: 10, height: 10 }}
        divStyle={{ marginTop: 6 }}
        icon={textExpanded ? upArrorIcon : downArrowIcon} onClick={toggleText}
      />}
    </div>
  )
}
