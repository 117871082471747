import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { sortByCreatedAtComparator } from 'src/services/utils'

import {
  likesSelector,
  makeMediaCommentsSelector,
  makeMediaLikesSelector,
  mediaCommentsLoadingStatusesSelector,
  namesByUserIdSelector,
  userSelector,
  usersSelector
} from 'src/selectors'

import { showImageModal } from 'src/actions/frontend'

import {
  createLike,
  destroyLike,
  fetchMediumComments,
  showLikersModal,
  showMediumMoreTooltip,
  showMediumShareTooltip,
  submitMediumComment
} from 'src/actions/media'

import MediumPhoto from './MediumPhoto'
import MediumComments from './MediumComments'
import MediumVideo from './MediumVideo/MediumVideo'

import styles from './medium.scss'

export class Medium extends Component {
  _liked() {
    const { like } = this.props
    if (like) {
      return !like.destroyed_at
    } else {
      return false
    }
  }
  _onDestroyLike = like => this.props.dispatch(destroyLike(like))
  _onFetchMediumComments = () => this.props.dispatch(fetchMediumComments(this.props.medium.id))
  _onLikesTapped = () => this.props.dispatch(showLikersModal(this.props.medium.id))
  _onMoreTapped = e => this.props.dispatch(showMediumMoreTooltip(e, this.props.medium.id))
  _onShowImageModal = url => this.props.dispatch(showImageModal(url))
  _onShareTapped = e => this.props.dispatch(showMediumShareTooltip(e, this.props.medium.id))
  _onSubmitComment = content =>
    this.props.dispatch(submitMediumComment(content, this.props.medium.id))
  _onCreateLike = () => this.props.dispatch(createLike(this.props.medium.id))

  _tapped = null

  _onPhotoTapped = () => {
    const { medium } = this.props
    if (this._tapped) {
      // Double-tap
      window.clearTimeout(this._tapped)
      this._tapped = null
      const liked = this._liked()
      this._onLikeMedium()
      return { runAnimation: !liked }
    } else {
      // Single-tap or first tap of a double-tap
      this._tapped = setTimeout(() => {
        this._tapped = null
        this._onShowImageModal(medium.file.url_2x)
      }, 300)
    }
  }

  _onLikeMedium = () => {
    const { like, medium } = this.props
    // Ignore taps on temp likes
    if (like && like.request_pending) return

    if (this._liked()) {
      this._onDestroyLike(like)
    } else {
      this._onCreateLike(medium.id)
      return { runAnimation: true }
    }
  }

  renderMedium() {
    const { medium, users, namesByUserId, user } = this.props

    switch (medium.type) {
      case 'Photo':
        return (
          <MediumPhoto
            liked={this._liked()}
            medium={medium}
            mediumUser={users[medium.user]}
            namesByUserId={namesByUserId}
            onLikeTapped={this._onLikeMedium}
            onMoreTapped={this._onMoreTapped}
            onPhotoTapped={this._onPhotoTapped}
            onShareTapped={this._onShareTapped}
            user={user}
          />
        )
      case 'Video':
        return (
          <MediumVideo medium={medium} />
        )
    }
  }

  render() {
    const { comments, commentLoadingStatus, namesByUserId, likes, medium, users } = this.props

    return (
      <div className={styles['container']}>
        {this.renderMedium()}
        <MediumComments
          comments={comments.sort(sortByCreatedAtComparator(false))}
          commentLoadingStatus={commentLoadingStatus}
          likes={likes}
          medium={medium}
          namesByUserId={namesByUserId}
          onFetchAllComments={this._onFetchMediumComments}
          onLikesTapped={this._onLikesTapped}
          onSubmitComment={this._onSubmitComment}
          users={users}
        />
      </div>
    )
  }
}

Medium.propTypes = {
  // Passed directly
  medium: PropTypes.object,

  dispatch: PropTypes.func.isRequired,
  comments: PropTypes.array,
  commentLoadingStatus: PropTypes.string,
  like: PropTypes.object,
  likes: PropTypes.array,
  namesByUserId: PropTypes.object,
  user: PropTypes.object,
  users: PropTypes.object
}
Medium.defaultProps = {
  comments: []
}

function makeMapStateToProps(_state, ownProps) {
  const { medium } = ownProps
  const mediaLikesSelector = makeMediaLikesSelector(medium.id)
  const mediaCommentsSelector = makeMediaCommentsSelector(medium.id)

  const mapStateToProps = (state, ownProps) => {
    // Warning this medium shadows the other one
    const { medium } = ownProps
    const likes = likesSelector(state)
    return {
      comments: mediaCommentsSelector(state),
      commentLoadingStatus: mediaCommentsLoadingStatusesSelector(state)[medium.id],
      like: likes[medium.like],
      likes: mediaLikesSelector(state),
      namesByUserId: namesByUserIdSelector(state),
      onSubmitComment: PropTypes.func.isRequired,
      user: userSelector(state),
      users: usersSelector(state)
    }
  }
  return mapStateToProps
}

export default connect(makeMapStateToProps)(Medium)
