import React, { useEffect, useContext } from 'react'

import CreditPurchaseBg from 'src/assets/credit-purchase-bg.png'
import CloseIcon from 'assets/icons/close-icon.png'
import CreditOptions from '../../GlobalLayer/PurchaseCredit/CreditOptions/CreditOptions'
import PageContainerContext from 'services/contexts'
import { message } from 'antd'
import styles from './purchase-credit.scss'

const PurchaseCredit = () => {
  const context = useContext(PageContainerContext)

  useEffect(() => {
    const metaTag = document.createElement('meta')
    metaTag.setAttribute('name', 'theme-color')
    metaTag.setAttribute('content', '#f0fffc')

    document.head.appendChild(metaTag)

    context.onSetStorageCallback(data => {
      if (data?.hasError) {
        message.error('Payment failed.')
      } else if (data && (data.planUpdated || data.creditsUpdated)) {
        history.back()
      }
    })

    return () => {
      document.head.removeChild(metaTag)
    }
  }, [])

  return (
    <div className={styles['purchase-credit-container']}>
      <div className={styles['header-container']}>
        <h1>
          Get more
          <br />
          Smart Invites!
        </h1>
        <img src={CreditPurchaseBg} className={styles['credit-purchase-bg']} alt="" />
        <img
          width={20}
          src={CloseIcon}
          className={styles['close-icon']}
          onClick={() => history.back()}
        />
      </div>

      <div className={styles['credit-options-container']}>
        <CreditOptions />
      </div>
    </div>
  )
}

export default PurchaseCredit
