import React, { Component } from 'react'
import DashboardContainer from 'containers/DashboardContainer'
import PropTypes from 'prop-types'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import { hasUserAccessToken } from 'services/access_tokens'
import styles from './create-time-poll.scss'
import CTPEntry from './inner-implement'

const containerId = 'create-time-poll-app-id'

class CreateTimePoll extends Component {
  componentDidMount() {
    if (!hasUserAccessToken()) {
      this.props.onNavigationLogin()
    }
  }

  render() {
    return (
      <DashboardContainer>
        <div className={styles['time-poll-container']}>
          <div id={containerId}>
            <CTPEntry />
          </div>
        </div>
      </DashboardContainer>
    )
  }
}

CreateTimePoll.propTypes = {
  onNavigationLogin: PropTypes.func
}

function mapDispatchToProps(dispatch) {
  return {
    onNavigationLogin: () =>
      dispatch(routerActions.push({ pathname: '/login', state: { prevRoute: '/tp/create' } }))
  }
}

export default connect(null, mapDispatchToProps)(CreateTimePoll)
