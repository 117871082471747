import { delay } from 'redux-saga'
import { call, fork, put, select, spawn, take } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'react-router-redux'
import jsCookie from 'js-cookie'

import { setEventCommentsCookie, setMediaCookie } from 'src/middleware'

import {
  EVENT_SUBSCRIBE_SUCCESS,
  FETCH_EVENT_COMMENTS_SUCCESS,
  getProcessedMediaCount,
  PUSHER_EVENT_RECEIVED,
  setUserId,
  setProcessedPhotoCount,
  setProcessedVideoCount
} from 'src/actions'

import { FETCH_CURRENT_USER_SUCCESS } from 'src/actions/user'

import {
  HIDE_IMAGE_MODAL,
  HIDE_MAP_MODAL,
  HIDE_MESSAGE_MODAL,
  HIDE_RSVP_MODAL,
  hideHud,
  SHOW_HUD,
  SHOW_IMAGE_MODAL,
  SHOW_MAP_MODAL,
  SHOW_MESSAGE_MODAL,
  SHOW_RSVP_MODAL,
  HIDE_MODAL_POLL_LOGIN,
  SHOW_MODAL_POLL_LOGIN
} from 'src/actions/frontend'

import { ADD_TEMP_CHAT_COMMENT } from 'src/actions/chat'

import { CREATE_REMOTE_MEDIUM, FETCH_MEDIA_SUCCESS } from 'src/actions/media'

import { setInvitationId } from 'src/actions/invitations'

import { EVENT_LOGOUT, LOGOUT } from 'src/actions/login'

import {
  eventIdSelector,
  eventSelector,
  destinationTypeSelector,
  eventTokenSelector,
  eventUpdatesCountSelector,
  hudShownSelector,
  mediaCountSelector,
  processedMediaCountSelector,
  routeSelector,
  visibleEventCommentsSelector,
  visibleMediaSelector,
  processedPhotoCountSelector,
  processedVideoCountSelector,
  mediaSelector
} from 'src/selectors'

import { updateEventUrl, parseEventUrl } from 'services/url_helpers'

import { createWatcher, dealWithShowSubscribeModal } from './utils'

import bootstrapRootSaga from './bootstrap'
import googleAnalyticsRootSaga from './google_analytics'
import apiRootSaga from './api'
import accessTokenRootSaga from './access_tokens'
import mediaRootSaga from './media'
import loginRootSaga from './login'
import honeybadgerRootSaga from './honeybadger'
import chatRootSaga from './chat'
import pusherRootSaga from './pusher'
import frontendRootSaga from './frontend'
import toastMessagesRootSaga from './toastMessages'
import invitationsRootSaga from './invitations'
import paymentRootSaga from './payment'
import branchLinkRootSaga from './branchLink'
import { eventTicketCountsRequest, ticketTypesCountsLoadedReset } from '../actions/paymentGraphql'

export default function * rootSaga () {
  yield spawn(watchForNavigationSaga)
  yield spawn(createWatcher(PUSHER_EVENT_RECEIVED, shortcutCountPusherWorker))
  yield spawn(watchHud)
  yield spawn(watchfetchCurrentUserSuccess)
  yield spawn(watchEventSubscribeSuccess)
  yield spawn(createWatcher(EVENT_LOGOUT, eventLogoutWorker))
  yield spawn(createWatcher(LOGOUT, logoutWorker))

  yield spawn(apiRootSaga)
  yield spawn(googleAnalyticsRootSaga)
  yield spawn(accessTokenRootSaga)
  yield spawn(mediaRootSaga)
  yield spawn(loginRootSaga)
  yield spawn(honeybadgerRootSaga)
  yield spawn(chatRootSaga)
  yield spawn(pusherRootSaga)
  yield spawn(toastMessagesRootSaga)
  yield spawn(frontendRootSaga)

  yield spawn(invitationsRootSaga)
  yield spawn(paymentRootSaga)

  yield spawn(watchShowModalSaga)
  yield spawn(watchHideModalSaga)

  // Start the bootstrap process last so that any events that it emits are captured
  yield spawn(bootstrapRootSaga)
  yield spawn(branchLinkRootSaga)
}

function * watchfetchCurrentUserSuccess () {
  while (true) {
    const action = yield take(FETCH_CURRENT_USER_SUCCESS)
    // This belongs in the bootstrap
    const userId = action.response.result.user

    yield call(dealWithShowSubscribeModal, userId)
    yield put(setUserId(userId))

    // mixpanel set user information.
    window.mixpanel?.identify(userId)
  }
}

function * watchEventSubscribeSuccess () {
  while (true) {
    const action = yield take(EVENT_SUBSCRIBE_SUCCESS)

    const invitationId = action.response.result.invitation

    // Reset ticket types count loading status because it needs to be loaded
    // again (because it is specific to the invitation, which changes on
    // subscribe because the user is gaining an invitation)
    yield put(ticketTypesCountsLoadedReset())

    yield put(setInvitationId(invitationId))
  }
}

function * logoutWorker () {
  jsCookie.remove('access_token')
  jsCookie.remove('refresh_token')
  const { destinationType, eventToken, invitationToken } = parseEventUrl(window.location.pathname)
  if (invitationToken) {
    updateEventUrl(destinationType, eventToken)
  }
  window.location.reload()
}

function * eventLogoutWorker () {
  const destinationType = yield select(destinationTypeSelector)
  const eventToken = yield select(eventTokenSelector)
  updateEventUrl(destinationType, eventToken)
}

function * watchHud () {
  while (true) {
    const action = yield take(SHOW_HUD)
    yield fork(hudWorker, action)
  }
}

function * hudWorker (action) {
  yield call(delay, 2500)
  const hudShown = yield select(hudShownSelector)
  if (hudShown) {
    yield put(hideHud())

    if (typeof action.callbackAction === 'function') {
      yield call(action.callbackAction)
    }
  }
}

// Prevent scrolling while the image modal is being viewed
function * watchShowModalSaga () {
  while (true) {
    yield take([SHOW_IMAGE_MODAL, SHOW_RSVP_MODAL, SHOW_MODAL_POLL_LOGIN, SHOW_MAP_MODAL, SHOW_MESSAGE_MODAL])
    document.body.style.overflow = 'hidden'
  }
}

function * watchHideModalSaga () {
  while (true) {
    yield take([HIDE_IMAGE_MODAL, HIDE_RSVP_MODAL, HIDE_MODAL_POLL_LOGIN, HIDE_MAP_MODAL, HIDE_MESSAGE_MODAL])
    document.body.style.overflow = ''
  }
}

function * watchForNavigationSaga () {
  while (true) {
    yield take([LOCATION_CHANGE, FETCH_EVENT_COMMENTS_SUCCESS, ADD_TEMP_CHAT_COMMENT, FETCH_MEDIA_SUCCESS, CREATE_REMOTE_MEDIUM])
    yield fork(updateShortcutCountWorker)
  }
}

function * updateShortcutCountWorker () {
  const route = yield select(routeSelector)

  if (route === '/comments') {
    const event = yield select(eventSelector)
    const eventComments = yield select(visibleEventCommentsSelector)
    const descriptionMessageCount = event.description && event.description.length > 0 ? 1 : 0
    const eventUpdatesCount = yield select(eventUpdatesCountSelector)
    const readCount = eventComments.length + descriptionMessageCount + eventUpdatesCount

    yield put(setEventCommentsCookie(readCount))
  } else if (route === '/media') {
    const media = yield select(visibleMediaSelector)
    const mediaCount = yield select(mediaCountSelector)
    if (media.length > mediaCount || mediaCount === null) {
      yield put(setMediaCookie(media.length))
    }
  }
}

function * shortcutCountPusherWorker (action) {
  const { eventType } = action.payload
  const { entities, result } = action.response

  switch (eventType) {
    case 'comment-created':
      yield call(updateShortcutCountWorker)
      break
    case 'medium-destroyed':
      yield call(mediumDestroyedSaga, result.medium)
      break
    case 'photo-processed':
      yield call(photoProcessedSaga, entities.media?.[result?.photo])
      break
    case 'invitation-created':
      yield call(invitationUpdatedSaga, entities.invitations[result.invitation])
      break
    case 'invitation-updated':
      yield call(invitationUpdatedSaga, entities.invitations[result.invitation])
      break
    case 'invitation-destroyed':
      yield call(invitationUpdatedSaga)
      break
  }
}

function * calculateMediumCountWorker (media, count) {
  const processedMediaCount = yield select(processedMediaCountSelector)
  const curProcessedMediaCount = processedMediaCount + count
  yield put(getProcessedMediaCount(curProcessedMediaCount))

  if (media.type === 'Photo') {
    const processPhotoCount = yield select(processedPhotoCountSelector)
    const curProcessedPhotoCount = processPhotoCount + count
    yield put(setProcessedPhotoCount(curProcessedPhotoCount))
  } else if (media.type === 'Video') {
    const processVideoCount = yield select(processedVideoCountSelector)
    const curProcessedVideoCount = processVideoCount + count
    yield put(setProcessedVideoCount(curProcessedVideoCount))
  }
}

function * mediumDestroyedSaga (mediaId) {
  const medias = yield select(mediaSelector)
  const media = medias[mediaId]
  yield calculateMediumCountWorker(media, -1)
}

function * photoProcessedSaga (media = {}) {
  yield call(updateShortcutCountWorker)
  yield calculateMediumCountWorker(media, 1)
}

function * invitationUpdatedSaga (invitation = {}) {
  if (invitation.rsvp_state !== 'undecided') {
    const eventId = yield select(eventIdSelector)

    yield put(eventTicketCountsRequest(eventId))
  }
}

export const _test = {
  watchfetchCurrentUserSuccess
}
