import {
  PurchaseWorkflowNode,
  STRIPE_CHECKOUT_HIDE_MODAL,
  STRIPE_CHECKOUT_SET_DEFAULT_WORKFLOW_NODE,
  STRIPE_CHECKOUT_SET_REQUIRED_CREDITS,
  STRIPE_CHECKOUT_SHOW_MODAL,
  STRIPE_CHECKOUT_SET_OPENED_TAB_REFERENCE,
  STRIPE_CHECKOUT_SET_TIMEOUT_HANDLE,
  STRIPE_CHECKOUT_CLEAR_TIMEOUT_HANDLE,
  STRIPE_CHECKOUT_SET_PENDING_RESULT_NODE,
  STRIPE_CHECKOUT_CLEAR_PENDING_RESULT_NODE
} from 'src/actions/stripeCheckout'
import { combineReducers } from 'redux'

const isCheckoutModalOpen = (state = false, action) => {
  switch (action.type) {
    case STRIPE_CHECKOUT_SHOW_MODAL:
      return true
    case STRIPE_CHECKOUT_HIDE_MODAL:
      return false
    default:
      return state
  }
}

const defaultWorkflowNode = (state = PurchaseWorkflowNode.Init, action) => {
  switch (action.type) {
    case STRIPE_CHECKOUT_SET_DEFAULT_WORKFLOW_NODE:
      return action.value
    default:
      return state
  }
}

const requiredCredits = (state = 0, action) => {
  switch (action.type) {
    case STRIPE_CHECKOUT_SET_REQUIRED_CREDITS:
      return action.value
    default:
      return state
  }
}

const openedTabRef = (state = null, action) => {
  switch (action.type) {
    case STRIPE_CHECKOUT_SET_OPENED_TAB_REFERENCE:
      return action.value
    default:
      return state
  }
}

const stripeCheckoutTimeoutHandle = (state = null, action) => {
  switch (action.type) {
    case STRIPE_CHECKOUT_SET_TIMEOUT_HANDLE:
      return action.value
    case STRIPE_CHECKOUT_CLEAR_TIMEOUT_HANDLE:
      if (state) {
        clearTimeout(state)
      }
      return null
    default:
      return state
  }
}

const checkoutPendingResultNode = (state = null, action) => {
  switch (action.type) {
    case STRIPE_CHECKOUT_SET_PENDING_RESULT_NODE:
      return action.value
    case STRIPE_CHECKOUT_CLEAR_PENDING_RESULT_NODE:
      return null
    default:
      return state
  }
}

export default combineReducers({
  isCheckoutModalOpen,
  defaultWorkflowNode,
  requiredCredits,
  openedTabRef,
  stripeCheckoutTimeoutHandle,
  checkoutPendingResultNode
})
