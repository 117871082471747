import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { eventUpdatesSelector, eventModalSelector } from 'src/selectors'
import { sortByCreatedAtComparator } from 'src/services/utils'
import dateformat from 'dateformat'
import HobnobSimpleFormat from 'components/common/HobnobSimpleFormat'

import styles from './event-updates.scss'

const EventUpdates = () => {
  const eventUpdates = useSelector(eventUpdatesSelector)
  const eventModal = useSelector(eventModalSelector)

  const filteredEventUpdates = Object.values(eventUpdates)
    .filter(u => !u.destroyed_at)
    .sort(sortByCreatedAtComparator(true))

  const eventUpdatesRef = useRef(null)

  useEffect(() => {
    if (eventModal === 'updates' && eventUpdatesRef.current) {
      eventUpdatesRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [eventUpdatesRef.current])

  const renderEventUpdates = () => {
    return filteredEventUpdates.map(eventUpdate => {
      return (
        <div className={styles['event-update-container']} key={eventUpdate.id}>
          <div className={styles['indicator-column']}>
            <div className={styles['event-update-indicator-circle']} />
            <div className={styles['event-update-indicator-line']} />
          </div>
          <div className={styles['event-update-content-wrapper']}>
            <HobnobSimpleFormat
              text={eventUpdate.content}
              paragraphClass={styles['event-update-paragraph']}
            />
            <div className={styles['event-update-timestamp']}>
              {dateformat(eventUpdate.updated_at, 'mmmm dS, h:MMtt')}
            </div>
          </div>
        </div>
      )
    })
  }

  if (filteredEventUpdates.length === 0) {
    return null
  }

  return (
    <div className={styles['container']} ref={eventUpdatesRef}>
      <h3 className={styles['header']}>Event Updates</h3>
      <div className={styles['updates-container']}>{renderEventUpdates()}</div>
      <div className={styles['bottom-spacer']} />
    </div>
  )
}

export default EventUpdates
