import React, { Component } from 'react'
import DashboardContainer from 'containers/DashboardContainer'
import Button from 'components/common/Button'
import archiveIcon from 'assets/icons/archive-icon.png'
import styles from './event-list-container.scss'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cn from 'classnames'
import EventPanel from 'containers/HomePage/BusinessPanel/EventPanel/EventPanel'
import { changeEventsTab } from 'src/actions/event'
import HobnobModal from 'components/common/HobnobModal'
import ArchivedEventModal from './ArchivedEventModal/ArchivedEventModal'
import CreateEventIcon from 'assets/icons/create-event-icon.png'
import { routerActions } from 'react-router-redux'

class EventListContainer extends Component {
  state = {
    showArchivedModal: false
  }

  handleChangeTab = type => {
    this.props.onChangeTab(type)
  }

  navigationCreateEvent = () => {
    const { onChangeRouter, userId } = this.props
    window.mixpanel.track('Create Event Clicked', {
      source: 'list',
      user_id: userId
    })

    onChangeRouter('/e/create')
  }

  render() {
    const { eventsTab, onChangeTab, userId } = this.props

    return (
      <DashboardContainer
        menuIcon
        right={
          <img
            onClick={() => this.setState({ showArchivedModal: true })}
            className={styles['archive-icon']}
            src={archiveIcon}
            width="18"
          />
        }
      >
        <div className={styles['events-container']}>
          <div className={styles['mobile-header']}>Events & Announcements</div>

          <div className={styles['events-header']}>
            <ul className={styles['events-tabs-wrapper']}>
              <li
                className={cn(styles['events-tabs-inner'], {
                  [styles['events-tabs-inner-active']]: eventsTab === 'active'
                })}
                onClick={() => onChangeTab('active')}
              >
                current
              </li>
              <li
                className={cn(styles['events-tabs-inner'], {
                  [styles['events-tabs-inner-active']]: eventsTab === 'draft'
                })}
                onClick={() => onChangeTab('draft')}
              >
                drafts
              </li>
            </ul>

            <div className={styles['button-wrapper']}>
              {userId && (
                <img
                  onClick={() => this.setState({ showArchivedModal: true })}
                  className={styles['archive-icon']}
                  src={archiveIcon}
                  width="18"
                />
              )}
              <Button
                className={styles['create-event-button']}
                onClick={this.navigationCreateEvent}
              >
                + Create Event
              </Button>
            </div>

            <img
              src={CreateEventIcon}
              className={styles['create-event-icon']}
              onClick={this.navigationCreateEvent}
            />
          </div>
          <div className={styles['event-component']}>
            <div
              className={cn(styles['panel-container'], {
                [styles['panel-container-hidden']]: eventsTab !== 'active'
              })}
            >
              <EventPanel />
            </div>
            <div
              className={cn(styles['panel-container'], {
                [styles['panel-container-hidden']]: eventsTab !== 'draft'
              })}
            >
              <EventPanel type="draft" />
            </div>
          </div>
        </div>

        <HobnobModal
          show={this.state.showArchivedModal}
          title="Archived Events"
          onClose={() => this.setState({ showArchivedModal: false })}
        >
          <ArchivedEventModal />
        </HobnobModal>
      </DashboardContainer>
    )
  }
}

EventListContainer.propTypes = {
  userId: PropTypes.string,
  eventsTab: PropTypes.string,
  onChangeTab: PropTypes.func,
  onChangeRouter: PropTypes.func
}

function mapStateToProps(state) {
  return {
    userId: state.userId,
    eventsTab: state.frontend.eventsTab
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeTab: type => dispatch(changeEventsTab(type)),
    onChangeRouter: params => dispatch(routerActions.push(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventListContainer)
