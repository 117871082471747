// import { impactRadiusHandlesLink } from './impact_stat.js'

export function buildRedirectUrl (url) {
  // if (impactRadiusHandlesLink(url)) {
  //   return url
  // } else {
  //   const hostName = process.env.LINK_REDIRECT_HOSTNAME

  //   return `${hostName}/redirect?url=${encodeURIComponent(url)}`
  // }
  return url
}
