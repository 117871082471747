import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import styles from './button.scss'

const Button = (props) => {
  const {
    children,
    className,
    disabled,
    filled,
    href,
    purple,
    wrapperElement,
    plain,
    ...otherProps
  } = props

  const buttonClass = disabled ? styles['button-disabled'] : (filled ? styles['button-filled'] : styles['button-outline'])
  const cnames = classnames(buttonClass, className, { [styles['purple']]: purple }, { [styles['plain']]: plain })

  return React.createElement(wrapperElement, { className: cnames, disabled: disabled, href: href, ...otherProps },
    children
  )
}

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  filled: PropTypes.bool,
  href: PropTypes.string,
  purple: PropTypes.bool,
  wrapperElement: PropTypes.any,
  plain: PropTypes.bool
}

Button.defaultProps = {
  plain: false,
  disabled: false,
  filled: true,
  purple: false,
  wrapperElement: 'button'
}

export default Button
