import React, { Component } from 'react'
import AddGuestsList from './AddGuestsList/AddGuestsList'
import AddGuests from './AddGuests/AddGuests'
import InputGuests from './InputGuests/InputGuests'
import PropTypes from 'prop-types'
import AddGuestsPageContext from './addGuestsContext'
import compWithAddGuestsList from '../HOC/WithAddGuestList'
import compWithCommunityGuests from '../HOC/WithCommunityGuests'
import compWithInputGuests from '../HOC/WithInputGuests'
import PageContainerContext from 'services/contexts'

const AddGuestsListHOC = compWithAddGuestsList(AddGuestsList)
const CommunityGuestsHOC = compWithCommunityGuests(AddGuests)
const InputGuestsHOC = compWithInputGuests(InputGuests)

class AddGuestsPageMobile extends Component {
  static contextType = PageContainerContext
  state = {
    componentName: 'AddGuestsList',
    isMounted: false
  }

  filterGuestListProps = () => {
    const { onSaveGuests, onRemoveNewGuests, onChangeEventsModal } = this.props
    return {
      onSaveGuests,
      onRemoveNewGuests,
      onChangeEventsModal
    }
  }

  handleChangeComponent = name => {
    const { componentName } = this.state

    if (
      (componentName === 'AddGuestsList' && name === 'AddGuests') ||
      (componentName === 'AddGuests' && name === 'InputGuests')
    ) {
      // push
      history.pushState(null, null, location.href)
    }

    this.setState({ componentName: name })
  }

  renderComponent = () => {
    const { componentName } = this.state
    const componentList = {
      AddGuestsList: <AddGuestsListHOC {...this.filterGuestListProps()} />,
      AddGuests: <CommunityGuestsHOC />,
      InputGuests: <InputGuestsHOC />
    }
    return componentList[componentName]
  }

  componentDidMount() {
    const { isMounted, componentName } = this.state
    if (!isMounted && componentName === 'AddGuestsList' && this.props.addGuestsList.length === 0) {
      this.handleChangeComponent('AddGuests')
    }

    history.pushState(null, null, location.href)

    this.setState({ isMounted: true })
  }

  render() {
    const { onChangePopupBox, onChangePopupBoxCallBack } = this.context

    const providerValue = {
      event: this.props.event,
      onChangeComponent: this.handleChangeComponent,
      onChangePopupBox,
      onChangePopupBoxCallBack
    }

    return (
      <AddGuestsPageContext.Provider value={providerValue}>
        {this.renderComponent()}
      </AddGuestsPageContext.Provider>
    )
  }
}

AddGuestsPageMobile.propTypes = {
  event: PropTypes.object,
  addGuestsList: PropTypes.array,
  onSaveGuests: PropTypes.func,
  onRemoveNewGuests: PropTypes.func,
  onChangeEventsModal: PropTypes.func
}

export default AddGuestsPageMobile
