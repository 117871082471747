import React from 'react'
import PropTypes from 'prop-types'

import { EMAIL_CONTACT_METHOD } from 'src/services/contact_methods'

import styles from './confirmation-code-message.scss'

const ConfirmationCodeMessage = ({ contactMethod, loginForm }) => {
  const formatPhoneNumber = phoneNumber => {
    const areaCode = phoneNumber.substr(1, 3)
    const prefix = phoneNumber.substr(4, 3)
    const suffix = phoneNumber.substr(7, 4)

    return `(${areaCode}) ${prefix}-${suffix}`
  }

  const renderDestination = () => {
    switch (contactMethod && contactMethod.type) {
      case EMAIL_CONTACT_METHOD:
        if (loginForm.useMaskedEmail) {
          return loginForm.maskedEmailAddress
        } else {
          return loginForm.emailAddress
        }
      default:
        return formatPhoneNumber(loginForm.phoneNumber)
    }
  }

  return (
    <div className={styles['description']}>
      <p>
        A code was sent to <span className={styles['phone-number']}>{renderDestination()}</span>.
      </p>
      <p>Enter it here to verify your number:</p>
    </div>
  )
}

ConfirmationCodeMessage.propTypes = {
  contactMethod: PropTypes.object,
  loginForm: PropTypes.object.isRequired
}

export default ConfirmationCodeMessage
