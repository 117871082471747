const queryString = require('query-string')

const tryParseJSON = require('./utils').tryParseJSON

const HOBNOB_API_ERROR = 'HobnobAPIError'

function apiDefaultHeaders(accessToken) {
  const clientName = __IN_SERVER_ENVIRONMENT__ ? 'hobnob-express-client' : 'hobnob-web-client'
  return {
    Accept: 'application/vnd.hobnob+json;version=2',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
    // 'Hobnob-Client': `${clientName}/${process.env.SOURCE_VERSION}`
    'Hobnob-Client': `${clientName}/${process.env.WEB_VERSION}`
    // 'Hobnob-Client': `hobnob-web-app/${process.env.WEB_VERSION}`
  }
}

function apiCustomHeaders(params) {
  return {
    Accept: 'application/vnd.hobnob+json;version=2',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${params.clientToken}`,
    'Hobnob-Client': `hobnob-web-app/${params.web_version}`
  }
}

function callApi(route, params = {}, options = {}, customHeaders) {
  const accessToken = options.accessToken
  delete options.accessToken
  const apiBaseUrl = options.baseUrl || process.env.API_BASE_URL
  delete options.baseUrl
  const defaultHeaders = customHeaders
    ? apiCustomHeaders(customHeaders)
    : apiDefaultHeaders(accessToken)

  const actualHeaders = Object.assign({}, defaultHeaders, options.headers)

  options.headers = actualHeaders
  options.method = options.method || 'get'

  // TODO: Better name...
  const fullBaseUrl = route.startsWith('http') ? route : `${apiBaseUrl}${route}`
  const paramsUrl = queryString.stringify(params)
  return fetch(`${fullBaseUrl}${paramsUrl ? '?' + paramsUrl : ''}`, options).then(response => {
    if (response.status >= 400) {
      return response.text().then(responseText => {
        const responseJson = tryParseJSON(responseText)
        const body = responseJson || {}
        const headers = response.headers
        let error = { type: HOBNOB_API_ERROR, response: { body, headers, status: response.status } }
        throw error
      })
    }

    if (response.status === 204) {
      return {
        body: null,
        status: response.status,
        headers: response.headers,
        requestParams: params
      }
    }

    return response.json().then(body => {
      return {
        body,
        status: response.status,
        headers: response.headers,
        requestParams: params
      }
    })
  })
}

module.exports = {
  HOBNOB_API_ERROR: HOBNOB_API_ERROR,
  apiDefaultHeaders: apiDefaultHeaders,
  callApi: callApi
}
