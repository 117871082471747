import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import EventCard from './EventCard/EventCard'
import LogoRectangle from 'assets/icons/logo-rectangle.png'
import appleIcon from 'assets/icons/apple-icon-white.png'
import Button from 'src/components/common/Button'
import { DOWNLOAD_APP_LINK } from 'src/constants'
import HobnobModal from 'components/common/HobnobModalV2/HobnobModal'
import { getRandomInt } from 'services/utils'
import FlyerCard from 'containers/event/CreateEvent/SelectFlyerStep/FlyerCard/FlyerCard'
import EmptyPanel from 'containers/HomePage/BusinessPanel/EmptyPanel/EmptyPanel'
import { routerActions } from 'react-router-redux'
import { fetchEventList, changeEventForm } from 'src/actions/event'
import { fetchFlyerDesigns } from 'src/actions/flyer'
import { flyerSelector } from 'src/selectors'

import styles from './event-panel.scss'

const EventPanel = ({ inHomePage, type }) => {
  const [eventList, setEventList] = useState([])
  const [templateFlyerList, setTemplateFlyerList] = useState([])
  const [pageInfo, setPageInfo] = useState(null)
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false)
  const [isLoadedEvent, setIsLoadedEvent] = useState(false)
  const dispatch = useDispatch()
  const userId = useSelector(state => state.userId)
  const { flyerList } = useSelector(flyerSelector)

  let _scrollLeft = 0
  let _isMounted = true

  useEffect(() => {
    if (inHomePage) {
      dispatch(fetchFlyerDesigns('1.0.0'))
    }

    return () => {
      _isMounted = false
    }
  }, [])

  useEffect(() => {
    if (userId) {
      onLoadEventList()
    }
  }, [userId])

  useEffect(() => {
    if (inHomePage && isLoadedEvent) {
      getTemplateFlyers(eventList.length)
    }
  }, [flyerList.length, eventList.length])

  const onLoadEventList = (pageInfo = {}, prevData = eventList) => {
    dispatch(
      fetchEventList({
        after: pageInfo.end_cursor,
        limit: 30,
        types: inHomePage
          ? 'hosted_by,invited_to,community_discoverable,draft'
          : type === 'active'
          ? 'hosted_by,invited_to,community_discoverable'
          : 'draft',
        onSuccessCallback: data => {
          setIsLoadedEvent(true)

          if (data.data.me?.events_connection && _isMounted) {
            const pageInfo = data.data.me?.events_connection.page_info
            let activeEvents

            activeEvents = data.data.me?.events_connection.events.filter(
              item =>
                ((!inHomePage && item.state === type) || inHomePage) &&
                !item.archived_at &&
                !item.destroyed_at
            )

            let new_eventList = prevData.concat(activeEvents)

            if (inHomePage && new_eventList.length > 6) {
              new_eventList = new_eventList.slice(0, 6)
            }

            const A = inHomePage && new_eventList.length < 6
            const B = !inHomePage && new_eventList.length < 8

            if ((A || B) && pageInfo.has_next_page) {
              onLoadEventList(pageInfo, new_eventList)
            } else {
              setEventList(new_eventList)
              setPageInfo(pageInfo)
            }
          }
        }
      })
    )
  }

  const handleScroll = e => {
    const { scrollTop, clientHeight, scrollHeight, scrollLeft } = e.target

    if (_scrollLeft !== scrollLeft) {
      _scrollLeft = scrollLeft
      return
    }

    if (pageInfo.has_next_page && scrollTop + clientHeight === scrollHeight) {
      onLoadEventList(pageInfo)
    }
  }

  const handleDelete = eventId => {
    const index = eventList.findIndex(item => item.id === eventId)
    eventList.splice(index, 1)
    setEventList([...eventList])
  }

  const getTemplateFlyers = eventCount => {
    const eventLimitCount = 6
    if (eventCount >= eventLimitCount || flyerList.length === 0) {
      setTemplateFlyerList([])
      return
    }
    const templateCount = eventLimitCount - eventCount

    const result = getRandomInt(templateCount, 0, flyerList.length - 1)
    const newTemplateFlyerList = result.map(item => flyerList[item])

    setTemplateFlyerList(newTemplateFlyerList)
  }

  const handleClickFlyer = item => {
    dispatch(
      changeEventForm({
        flyer_design_number: item.design_number,
        flyer_design_slug: item.design_slug
      })
    )
    window.mixpanel.track('Create Event Clicked', {
      user_id: userId,
      source: 'template',
      'flyer#': item.design_number
    })
    dispatch(routerActions.push('/e/createV2'))
  }
  if (!userId || (eventList.length === 0 && templateFlyerList.length === 0 && isLoadedEvent)) {
    return <EmptyPanel type="event" />
  }

  return (
    <div className={styles['event-panel']} onScroll={inHomePage ? undefined : handleScroll}>
      <ul className={styles['event-wrapper']}>
        {eventList.map(event => (
          <EventCard
            key={event.id}
            event={event}
            onDeleteEventCallback={handleDelete}
            onShowDownLoad={() => setIsDownloadModalVisible(true)}
          />
        ))}

        {inHomePage &&
          templateFlyerList.map(item => {
            if (!item.image_bg_slug) {
              return null
            }

            return (
              <li className={styles['template-flyer-container']} key={item.design_number}>
                <FlyerCard
                  isThumbnail
                  flyerDetail={item}
                  containerClassName={styles['flyer-box']}
                />
                <div className={styles['flyer-info']}>
                  <div className={styles['title']}>Template Flyer</div>
                  <div className={styles['button']} onClick={() => handleClickFlyer(item)}>
                    Create Event
                  </div>
                </div>
              </li>
            )
          })}
      </ul>

      <HobnobModal
        visible={isDownloadModalVisible}
        onClose={() => setIsDownloadModalVisible(false)}
        closable={false}
        fullModal
      >
        <div className={styles['download-wrapper']}>
          <img src={LogoRectangle} alt="" />
          <div className={styles['text']}>This feature is available when you use the app!</div>
          <a href={DOWNLOAD_APP_LINK} target="_blank" rel="noreferrer" className={styles['link']}>
            <Button onClick={() => {}} className={styles['button-inner']}>
              <img src={appleIcon} />
              <strong>Download Hobnob on App Store</strong>
            </Button>
          </a>
          <div className={styles['close-text']} onClick={() => setIsDownloadModalVisible(false)}>
            No, Thanks
          </div>
        </div>
      </HobnobModal>
    </div>
  )
}

EventPanel.defaultProps = {
  type: 'active'
}

EventPanel.propTypes = {
  inHomePage: PropTypes.bool,
  type: PropTypes.string
}

export default EventPanel
