/* eslint-disable react/prop-types */
import React from 'react'

import Button from '../common/button/Button'

import { isChrome, isFirefox, isSafari } from '../../utils/browser_detection'
import SvgIcon from 'components/common/SvgIcon'
import iosIcon from 'assets/icons/hobnob_iOS_icon.svg'
import browserIcon from 'assets/icons/browser_app_icon.svg'
import firefoxIcon from 'assets/icons/firefox_app_icon.png'
import chromeIcon from 'assets/icons/chrome_app_icon.png'
import safariIcon from 'assets/icons/safari_app_icon.png'

import styles from './open-app-modal.scss'

const OpenAppModal = props => {
  const { onClose } = props

  const _renderBrowserName = () => {
    if (isChrome()) {
      return 'Chrome'
    } else if (isFirefox()) {
      return 'Firefox'
    } else if (isSafari()) {
      return 'Safari'
    } else {
      return 'Browser'
    }
  }

  const _renderBrowserIcon = () => {
    if (isChrome()) {
      return <img src={chromeIcon} width="30" />
    } else if (isFirefox()) {
      return <img src={firefoxIcon} width="30" />
    } else if (isSafari()) {
      return <img src={safariIcon} width="30" />
    } else {
      return <SvgIcon icon={browserIcon} style={{ width: 30 }} />
    }
  }

  return (
    <div className={styles['open-app-modal-wrapper']}>
      <div className={styles['title']}>Open Hobnob App</div>
      <div className={styles['button-wrapper']}>
        <SvgIcon icon={iosIcon} style={{ width: 30 }} />
        <span className={styles['button-name']}>Hobnob App</span>
        <Button className={styles['button-inner']}>
          <a href='https://hobnob.app/download/'>Open</a>
        </Button>
      </div>
      <div className={styles['button-wrapper']}>
        {_renderBrowserIcon()}
        <span className={styles['button-name']}>{_renderBrowserName()}</span>
        <Button className={styles['button-browser']} filled={false} onClick={() => onClose?.()}>
          Continue
        </Button>
      </div>
    </div>
  )
}

export default OpenAppModal
