import xhr from 'xhr'
import queryString from 'query-string'
import throttle from 'lodash/throttle'

export function uploadToS3ByPresignedPost (filePresignedPost, file, callbackUrl) {
  const { url, fields } = filePresignedPost
  let formData = new FormData()
  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key])
  })
  // Amazon requires file to be last
  formData.append('file', file)

  return uploadToS3('POST', url, formData, 'multipart/form-data', callbackUrl)
}

export function uploadToS3ByUrl (url, file, mimeType, callbackUrl) {
  return uploadToS3('PUT', url, file, mimeType, callbackUrl)
}

export function uploadToS3 (method, url, body, mimeType, callbackUrl) {
  // Deferred pattern from:
  // https://github.com/yelouafi/redux-saga/issues/51#issuecomment-174205884
  let deferred

  const progressHandler = (e) => {
    if (deferred) {
      deferred.resolve(e.loaded / e.total)
      deferred = null
    }
  }
  let headers
  if (mimeType === 'multipart/form-data') {
    headers = null
  } else {
    headers = {
      'Content-Type': mimeType
    }
  }

  // Can't use fetch here since it doesn't provide a way to track progress :(
  // https://github.com/github/fetch/issues/89#issuecomment-189632075
  xhr({
    url,
    method,
    body,
    headers,
    withCredentials: true,
    beforeSend: function (xhrRequest) {
      if (xhrRequest.upload) {
        xhrRequest.upload.addEventListener('progress', throttle(progressHandler, 250), false)
      }
    }
  }, function (err, resp, body) {
    if (err) {
      if (deferred) {
        deferred.reject(err)
      } else {
        console.error(err)
      }
    } else {
      if (resp.statusCode >= 200 && resp.statusCode < 300) {
        if (callbackUrl && method === 'POST') {
          const key = body.match(/<Key>(.*)<\/Key>/)[1]
          fetch(`${callbackUrl}?${queryString.stringify({ key })}`)
        }
      } else {
        throw (resp.body)
      }
    }
  })

  return {
    nextProgressEvent () {
      if (!deferred) {
        deferred = {}
        deferred.promise = new Promise((resolve, reject) => {
          deferred.resolve = resolve
          deferred.reject = reject
        })
      }

      return deferred.promise
    }
  }
}
