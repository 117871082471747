import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import HobnobNavBar from 'components/common/HobnobNavBar'
import {
  eventSelector,
  listMyOrdersForEventLoadingSelector,
  listMyOrdersForEventSelector,
  ticketTypesSelector
} from '../selectors'
import OrdersForEventContainer from '../components/orders/OrdersForEventContainer'
import OverlayLoadingIndicator from '../components/common/OverlayLoadingIndicator'
import { setCurrentOrderId } from '../actions/paymentGraphql'
import { routerActions } from 'react-router-redux'
import { getDisplayOrderTickets } from '../services/payment_utils'

class OrdersPage extends Component {
  _onClickItem = (order) => {
    const { onSetCurrentOrderId, onShowOrderDetail } = this.props
    onSetCurrentOrderId(order.id)
    onShowOrderDetail()
  }

  render () {
    const { event, listMyOrdersForEvent, ticketTypes, listMyOrdersForEventLoading } = this.props

    const ordersWithCounts = getDisplayOrderTickets(listMyOrdersForEvent, ticketTypes)

    return <div>
      <HobnobNavBar title='Orders' subTitle={event.name} backPathName='details' close />
      <OrdersForEventContainer ordersWithCounts={ordersWithCounts} onClickItem={this._onClickItem} />
      <OverlayLoadingIndicator loading={listMyOrdersForEventLoading} light />
    </div>
  }
}

OrdersPage.propTypes = {
  event: PropTypes.object,
  listMyOrdersForEvent: PropTypes.array,
  ticketTypes: PropTypes.array,
  listMyOrdersForEventLoading: PropTypes.bool,
  onSetCurrentOrderId: PropTypes.func,
  onShowOrderDetail: PropTypes.func
}

function mapStateToProps (state) {
  return {
    event: eventSelector(state),
    listMyOrdersForEvent: listMyOrdersForEventSelector(state),
    ticketTypes: ticketTypesSelector(state),
    listMyOrdersForEventLoading: listMyOrdersForEventLoadingSelector(state)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    onSetCurrentOrderId: (orderId) => dispatch(setCurrentOrderId(orderId)),
    onShowOrderDetail: () => {
      dispatch(routerActions.push('orderDetail'))
      window.scrollTo(0, 0)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPage)
