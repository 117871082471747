import PropTypes from 'prop-types'
import React from 'react'
import { getPlanBySlug, PlanType } from '../../services/stripe_checkout_configs'

const getBorderFillColorByPlan = (plan, appUser) => {
  switch (plan?.planType) {
    case PlanType.Free:
      return '#E5E5E5'
    case PlanType.Basic:
    case PlanType.InvitationMaker:
      return '#6b87ff'
    case PlanType.Essentials:
    case PlanType.PartyHost:
      return '#2cd4ae'
    case PlanType.EventPlanner:
    case PlanType.Premium:
      return '#9013fe'
    case PlanType.GroupOrganizer:
      return '#ffa428'
    default:
      return appUser ? 'url(#hobnob-gradient)' : '#E5E5E5'
  }
}

export default function AvatarBorder (props) {
  const { user, appUser, borderWidth, includeBorder, children, size, inProfile = false } = props
  if (includeBorder) {
    const margin = { top: borderWidth / 2, right: borderWidth / 2, bottom: borderWidth / 2, left: borderWidth / 2 }
    const fullSize = size + margin.left + margin.right + borderWidth * 2
    const width = fullSize - margin.left - margin.right
    const height = fullSize - margin.top - margin.bottom
    let stroke

    const plan = getPlanBySlug(user?.current_subscription?.plan?.slug)

    if (plan && inProfile) {
      stroke = getBorderFillColorByPlan(plan, appUser)
    } else {
      stroke = appUser ? 'url(#hobnob-gradient)' : '#E5E5E5'
    }

    const style = {
      height: `${fullSize}px`,
      width: `${fullSize}px`,
      textAlign: 'initial'
    }

    return (
      <div style={style}>
        <svg style={style}>
          <g transform={`translate(${margin.left}, ${margin.top})`}>
            <circle cx={width / 2} cy={height / 2} r={width / 2} stroke={stroke} strokeWidth={borderWidth} fill={stroke} />
            <foreignObject x={borderWidth} y={borderWidth} width={width} height={height}>
              {children}
            </foreignObject>
          </g>
        </svg>
      </div>

    )
  } else {
    return children
  }
}

AvatarBorder.propTypes = {
  user: PropTypes.object,
  appUser: PropTypes.bool,
  borderWidth: PropTypes.number,
  children: PropTypes.any,
  includeBorder: PropTypes.bool,
  size: PropTypes.number,
  inProfile: PropTypes.bool
}
