import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import HobnobModal from 'src/components/common/HobnobModalV2/HobnobModal'
import ImageCropper from 'src/components/common/ImageCropper'
import { compressImage } from 'src/actions/utils'
import { setCustomFlyerURL } from 'src/actions/flyer'
import PropTypes from 'prop-types'
import UploadIcon from 'src/assets/icons/icon-upload.svg'
import SvgIcon from 'src/components/common/SvgIcon'
import ImagesIcon from 'src/assets/icons/icon_images.png'

import styles from './custom-flyer-picker.scss'

const ALLOWED_IMAGE_TYPES = ['.png', '.PNG', '.jpeg', '.JPEG', '.jpg', '.JPG']

const CustomFlyerPicker = ({ children }) => {
  const dispatch = useDispatch()
  const inputRef = useRef(null)

  const [isImageCropperVisible, setIsImageCropperVisible] = useState(false)
  const [selectedImageDataURL, setSelectedImageDataURL] = useState('')

  const handleClick = () => {
    setIsImageCropperVisible(true)
    inputRef.current.click()
  }

  const resetState = () => {
    setSelectedImageDataURL('')
    setIsImageCropperVisible(false)
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  return (
    <div className={styles['custom-flyer-container']}>
      <div onClick={handleClick}>
        {children || (
          <div className={styles['default-container']}>
            <section className={styles['section-container']}>
              <SvgIcon icon={UploadIcon} width={40} height={40} />
              <p className={styles['first-tip']}>Upload Your Own Image or Flyer</p>
            </section>
            <section className={styles['section-container']}>
              <p className={styles['second-tip']}>Suggested size:</p>
              <p className={styles['second-tip']}>1242*2208 px (9:16)</p>
            </section>
          </div>
        )}
      </div>

      <HobnobModal
        visible={isImageCropperVisible && !!selectedImageDataURL}
        onCancel={() => resetState()}
        destroyOnClose
      >
        <div className={styles['cropper-wrapper']}>
          <img
            className={styles['edit-icon']}
            src={ImagesIcon}
            width={'24px'}
            onClick={() => inputRef.current.click()}
          />
          <ImageCropper
            imageDataURL={selectedImageDataURL}
            onCancel={() => resetState()}
            onCrop={imageDataURL => {
              dispatch(setCustomFlyerURL(imageDataURL))
              resetState()
            }}
          />
        </div>
      </HobnobModal>

      <input
        accept="image/*"
        style={{ display: 'none' }}
        type="file"
        ref={inputRef}
        onChange={e => {
          const file = e.target.files[0]
          if (!file && !selectedImageDataURL) {
            alert('Please select an image')
            return
          }
          const fileName = file.name
          if (ALLOWED_IMAGE_TYPES.some(type => fileName.endsWith(type))) {
            const reader = new FileReader()
            reader.onload = async e => {
              let dataURL = e.target.result
              if (file.size > 10 * 1024 * 1024) {
                console.log('Compressing image larger than 10MB')
                dataURL = await compressImage(e.target.result, {
                  width: 5000
                })
              }
              setSelectedImageDataURL(dataURL)
            }
            reader.readAsDataURL(file)
          } else {
            alert('Please upload a valid image file, we support .png, .jpeg and .jpg image types')
          }
        }}
      />
    </div>
  )
}

CustomFlyerPicker.propTypes = {
  children: PropTypes.node
}

export default CustomFlyerPicker
