export function parseEventUrl(url) {
  const path_components = url.split('/')

  const destinationType = path_components[1]
  const eventToken = path_components[2]
  let invitationToken

  if (path_components[3]) {
    invitationToken = path_components[3]
  }

  return {
    destinationType,
    eventToken,
    invitationToken
  }
}

export function updateEventUrl(destinationType, eventToken, invitationToken) {
  let destinationUrl = `/${destinationType}/${eventToken}/`
  if (invitationToken && destinationType === 'e') {
    destinationUrl += `${invitationToken}/`
  }

  history.pushState({}, '', destinationUrl + window.location.search + window.location.hash)
}

export const redirectDomain = (url, newTag = false) => {
  const urls = ['localhost', '192.168.170.53']
  if (urls.includes(window.location.hostname)) {
    const urObj = new URL(url)
    urObj.host = window.location.hostname
    urObj.port = '8090'
    urObj.protocol = 'http'
    if (newTag) {
      window.open(urObj.href, '_blank')
    } else {
      window.location.href = urObj.href
    }
  } else {
    if (newTag) {
      window.open(url, '_blank')
    } else {
      window.location.href = url
    }
  }
}
