export const orderStates = {
  paid: 'Paid',
  pending: 'Refund Request Pending',
  processed: 'Refund Received'
}

export const orderColors = {
  paid: '#303030',
  pending: '#D0021B',
  processed: '#3DBD00'
}
