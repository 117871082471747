import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { userSelector } from 'src/selectors'
import CommonFrame from '../CommonFrame/CommonFrame'
import CancelMembershipImg from 'assets/icons/cancel-membership-2.png'
import Button from 'src/components/common/Button'
import { changePlanModalVisible, setIsPlanDowngrade } from 'src/actions/plan'
import { cancelSubscription, fetchUserGQ } from 'src/actions/user'
import { message } from 'antd'
import { changeLoading, changeVisible } from 'src/actions/globalLayer'
import { PlanSlugs } from 'src/services/stripe_checkout_configs'
import CancelInvitationMaker from './CancelInvitationMaker/CancelInvitationMaker'
import { verifySubscriptionPlatform } from 'src/services/frontend_tips'

import styles from './cancel-membership.scss'

const CancelMembership = () => {
  const dispatch = useDispatch()
  const [requestCount, setRequestCount] = useState(0)
  const [isCancelToFreeVisible, setIsCancelToFreeVisible] = useState(false)
  const user = useSelector(userSelector)
  const { subscription_type } = user.current_subscription
  const timeOutRef = useRef(null)
  const intervalRef = useRef(null)

  const handleClearState = () => {
    setRequestCount(0)
    clearTimeout(timeOutRef.current)
    clearInterval(intervalRef.current)
    timeOutRef.current = null
    intervalRef.current = null
  }

  const handleOpenPlan = () => {
    if (verifySubscriptionPlatform(subscription_type)) {
      dispatch(changeVisible(false))
      dispatch(setIsPlanDowngrade(true))
      dispatch(changePlanModalVisible(true))
    }
  }

  useEffect(() => {
    if (requestCount === 5) {
      message.error('We’re unable to process your cancellation request. Please try again later.')
      handleClearState()
    }
  }, [requestCount])

  useEffect(() => {
    if (user.current_subscription.cancel_at_period_end) {
      message.success('You have successfully cancelled your subscription.')
      handleClearState()
      dispatch(changeVisible(false))
    }

    return handleClearState
  }, [user])

  const handleCancelMembership = () => {
    dispatch(changeLoading(true))
    dispatch(
      cancelSubscription({
        onSuccessCallback: () => {
          intervalRef.current = setInterval(() => {
            setRequestCount(requestCount + 1)
            dispatch(fetchUserGQ())
          }, 1000)
        },
        onErrorCallback: () => {
          message.error(
            'We’re unable to process your cancellation request. Please try again later.'
          )
        }
      })
    )
  }

  const actionLayer = (
    <div className={styles['action-container']}>
      <h1>A Lower-Cost Plan </h1>

      <p>
        Try one of our lower-cost plans at different credit levels. Continue to enjoy{' '}
        <b>Premium Designs, Unlimited Polls & Lists,</b> and more so you can save time and look
        great.
      </p>

      <Button className={styles['button-inner']} onClick={handleOpenPlan}>
        Check out other plans
      </Button>

      <span className={styles['cancel-button']} onClick={setIsCancelToFreeVisible}>
        No, thanks
      </span>
    </div>
  )

  const descLayer = (
    <div className={styles['desc-container']}>
      <img src={CancelMembershipImg} className={styles['img-inner']} alt="" />
    </div>
  )

  if (
    user.current_subscription.plan.slug === PlanSlugs.InvitationMakerMonthly ||
    isCancelToFreeVisible
  ) {
    return <CancelInvitationMaker onCancelMembership={handleCancelMembership} />
  }

  return <CommonFrame actionLayer={actionLayer} descLayer={descLayer} />
}

export default CancelMembership
