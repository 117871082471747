import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styles from './refund-policy.scss'

export default class RefundPolicy extends Component {
  render () {
    const { refundPolicy } = this.props
    return (
      <div className={styles['wrapper']}>
        <div>Refund Policy:</div>
        <div>{refundPolicy}</div>
      </div>
    )
  }
}

RefundPolicy.propTypes = {
  refundPolicy: PropTypes.string
}
