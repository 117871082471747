import { FlyerLayoutAbstract } from './flyer-layout.abstract'
import moment from 'moment/moment'

export default class Flyer_layout_split_diagonal_lines_027LayoutModel extends FlyerLayoutAbstract {
  flourishes = [
    {
      name: 'diagonal_2_lines_overlay',
      left: 0,
      top: 0,
      width: 320,
      height: 568
    },
    {
      name: 'diagonal_circle',
      left: 10,
      top: 68,
      width: 112,
      height: 112
    }
  ]

  textLayouts = {
    event_name_1: {
      top: 270,
      left: 20,
      size: 42,
      width: 300,
      height: 140
    },
    location_1: {
      top: 480,
      right: 20,
      size: 20,
      width: 300,
      height: 20,
      align: 'right'
    },
    location_2: {
      top: 500,
      right: 20,
      size: 20,
      width: 300,
      height: 30,
      align: 'right'
    }
  }

  dateLayouts = {
    date_3: {
      top: 416,
      right: 20,
      daySize: 20,
      width: 300,
      height: 20,
      align: 'right'
    }
  }

  datePropsConfigsMapping = {
    date3: 'date_3',
    dateSeparator: 'date_separator'
  }

  constructor(layout, eventData) {
    super(layout, eventData)
    this.init()
  }

  getDateTexts() {
    const startDate = this.eventData.starts_at
    const endDate = this.eventData.ends_at
    const { daySize, top, align, width, right } = this.dateLayouts.date_3

    const dateInformation = this.formatDates({
      day: 'ddd MMM Do, h:mmA',
      time: 'h:mmA'
    })

    const { formattedStart, formattedEnd, isSameDay } = dateInformation
    let dates = []
    let textTop = top

    if (!endDate || (endDate && isSameDay)) {
      dates.push({
        text: moment(startDate).format('MMM').toUpperCase(),
        left: 10,
        width: 110,
        height: 110,
        top: 90,
        color: '#cccccc',
        widthAlign: 'center',
        font: this.dateProps.date3Props.font.replace(/\s+/g, '-'),
        size: 40
      })

      dates.push({
        text: moment(startDate).format('D'),
        left: 10,
        width: 110,
        height: 110,
        top: 130,
        widthAlign: 'center',
        color: '#cccccc',
        font: this.dateProps.date3Props.font.replace(/\s+/g, '-'),
        size: 40
      })
    } else {
      dates.push({
        text: moment(startDate).format('MMM D').toUpperCase(),
        left: 10,
        width: 110,
        height: 110,
        top: 90,
        color: '#cccccc',
        widthAlign: 'center',
        font: this.dateProps.date3Props.font.replace(/\s+/g, '-'),
        size: 30
      })

      dates.push({
        text: moment(endDate).format('MMM D').toUpperCase(),
        left: 10,
        width: 110,
        height: 110,
        top: 130,
        widthAlign: 'center',
        color: '#cccccc',
        font: this.dateProps.date3Props.font.replace(/\s+/g, '-'),
        size: 30
      })
    }

    dates.push({
      text: formattedStart,
      align,
      right,
      width,
      top: textTop,
      color: this.dateProps.date3Props.color.replace('0x', '#'),
      font: this.dateProps.date3Props.font.replace(/\s+/g, '-'),
      size: daySize
    })

    textTop += daySize
    if (!endDate) {
      return { type: 'date', dates }
    }

    dates.push({
      text: 'till',
      right: isSameDay ? 85 : 220,
      width: 50,
      size: 14,
      top: textTop + 4,
      align,
      color: this.dateProps.dateSeparatorProps.color.replace('0x', '#'),
      font: this.dateProps.dateSeparatorProps.font.replace(/\s+/g, '-'),
      transformOrigin: 'right top'
    })

    dates.push({
      text: formattedEnd,
      right,
      width,
      align,
      top: textTop,
      color: this.dateProps.date3Props.color.replace('0x', '#'),
      font: this.dateProps.date3Props.font.replace(/\s+/g, '-'),
      size: daySize
    })

    return { type: 'date', dates }
  }
}
