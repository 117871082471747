import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import DashboardContainer from 'containers/DashboardContainer'

import { locationShape } from 'react-router'
import LoginImage from 'assets/icons/empty_state_millenials_at_festival.png'
import { pollForm } from '../time-poll/constants'
import { requestSubmitLogin, updateLoginForm } from 'src/actions/login'
import { loginFormSelector } from 'src/selectors'
import { PHONE_NUMBER_CONTACT_METHOD, VALID_COUNTRY_CODES } from 'src/services/contact_methods'
import LoadingComponent from 'components/common/LoadingComponent/LoadingComponent'

import ContactMethodInputInner from 'components/LoginModal/ContactMethodInput/ContactMethodInputInner/ContactMethodInputInner'
import ConfirmationCodeInput from 'components/LoginModal/ConfirmationCodeInput/ConfirmationCodeInput'
import FullNameInput from 'components/LoginModal/FullNameInput/FullNameInput'
import Button from 'components/common/Button'
import LeftChevronIcon from 'assets/icons/left_chevron.svg'
import SvgIcon from 'components/common/SvgIcon'
import { Skeleton } from 'antd'

import styles from './login-page.scss'

const LoginPage = props => {
  const location = props.location
  const dispatch = useDispatch()
  const loginForm = useSelector(loginFormSelector)
  const [routePending, setRoutePending] = useState(false)

  const handleNextStep = () => {
    setRoutePending(true)
    const prevRoute = location.state?.prevRoute

    if (prevRoute) {
      window.location.href = window.location.origin + prevRoute
    } else {
      window.location.href = window.location.origin + '/home'
    }
  }

  const handleRequestSubmitLogin = () => {
    dispatch(requestSubmitLogin(loginForm.phoneNumber, PHONE_NUMBER_CONTACT_METHOD))
  }

  const formatPhoneNumber = phoneNumber => {
    const areaCode = phoneNumber.substr(1, 3)
    const prefix = phoneNumber.substr(4, 3)
    const suffix = phoneNumber.substr(7, 4)

    return `(${areaCode}) ${prefix}-${suffix}`
  }

  const getTitle = () => {
    if (loginForm.loginStep === 'contactMethod') {
      return {
        title: 'Login/Create an Account',
        subtitle:
          'A Free account on Hobnob unleashes a suite of tools to make coordinating people and events a breeze!',
        notes: 'Time Polls, Events, Sign Up Lists, Flyers... and more!'
      }
    } else if (loginForm.loginStep === 'confirmationCode') {
      return {
        title: 'Confirm Number',
        subtitle:
          'A code was sent to ' +
          formatPhoneNumber(loginForm.phoneNumber) +
          '. Enter it here to verify your number'
      }
    } else if (loginForm.loginStep === 'fullName') {
      return {
        title: loginForm.fullNameTitle,
        subtitle: loginForm.fullNameSubtitle
      }
    }
  }

  const isContactMethodValid = () => {
    const phoneNumberValid = loginForm.phoneNumber.length === 11
    const countryCodeValid = VALID_COUNTRY_CODES.find(c => c.code === loginForm.countryCode)

    return phoneNumberValid && Boolean(countryCodeValid)
  }

  const renderContactMethodStep = () => (
    <div className={styles['content-wrapper']}>
      <div className={styles['input-wrapper']}>
        <ContactMethodInputInner inputClassName={styles['input-inner']} valid />
      </div>

      <div className={styles['button-wrapper']}>
        <div className={styles['text']}>
          <a href="https://hobnob.app/privacy/">*Privacy Policy and </a>
          <a href="https://hobnob.app/terms/">Terms</a>
          <p>{pollForm.subtitle}</p>
        </div>

        <Button
          disabled={!isContactMethodValid()}
          className={styles['button-inner']}
          onClick={handleRequestSubmitLogin}
        >
          NEXT
        </Button>
      </div>
    </div>
  )

  const renderConfirmationCodeStep = () => (
    <div className={styles['content-wrapper']}>
      <ConfirmationCodeInput
        // onNextStep={loginForm.newUserId ? null : handleNextStep}
        onResendCode={handleRequestSubmitLogin}
        onlyInput
      />
    </div>
  )

  const renderFullNameStep = () => (
    <div className={styles['content-wrapper']}>
      <FullNameInput onNextStep={handleNextStep} />
    </div>
  )

  const handleClickBack = () => {
    let prevStep = 'contactMethod'

    if (loginForm.loginStep === 'fullName') {
      prevStep = 'confirmationCode'
    }

    dispatch(
      updateLoginForm({
        loginStep: prevStep,
        confirmationCodeValid: 'unknown',
        confirmationCode: ''
      })
    )
  }

  const titleParams = getTitle()

  if (routePending) {
    return (
      <DashboardContainer>
        <div className={styles['skeleton-container']}>
          <div className={styles['skeletons']}>
            <section>
              <Skeleton.Button active block />
              <Skeleton.Button active />
            </section>
            <section>
              <Skeleton.Button active block />
              <Skeleton.Button active block />
              <Skeleton.Button active block />
            </section>
          </div>
        </div>
      </DashboardContainer>
    )
  }

  return (
    <DashboardContainer>
      <div className={styles['login-page-container']}>
        {loginForm.loginStep !== 'contactMethod' && (
          <div className={styles['back-icon-wrapper']}>
            <SvgIcon
              className={styles['left-chevron-icon']}
              icon={LeftChevronIcon}
              padding="5px"
              onClick={handleClickBack}
            />
          </div>
        )}

        <div className={styles['title-container']}>
          <img src={LoginImage} width="240" />
          <h1>{titleParams.title}</h1>
          <p>{titleParams.subtitle}</p>
          {titleParams.notes && <p className={styles['notes']}>{titleParams.notes}</p>}
        </div>

        {loginForm.loginStep === 'contactMethod' && renderContactMethodStep()}
        {loginForm.loginStep === 'confirmationCode' && renderConfirmationCodeStep()}
        {loginForm.loginStep === 'fullName' && renderFullNameStep()}
      </div>

      {loginForm.confirmationCodeLoading && (
        <div className={styles['loading-container']}>
          <LoadingComponent loading type="partial" light />
        </div>
      )}
    </DashboardContainer>
  )
}

LoginPage.propTypes = {
  location: locationShape
}

export default LoginPage
