import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import BackIcon from 'assets/icons/back_button.png'
import QRCode from 'qrcode'
import { eventSelector } from 'src/selectors'
import CloneHostIcon from 'assets/icons/ico-clone-host.png'
import { message } from 'antd'

import styles from './manually-send.scss'

const ManuallySend = ({ onSetIsQrCodeVisible }) => {
  const event = useSelector(eventSelector)
  const [qrCodeUrl, setQrCodeUrl] = useState('')

  useEffect(() => {
    const qrParams = {
      width: 190,
      color: {
        dark: styles.shamrock,
        light: '#ffffff'
      }
    }

    QRCode.toDataURL(event.url, qrParams, (_, url) => {
      setQrCodeUrl(url)
    })
  }, [])

  const handleCopyLink = () => {
    navigator.clipboard.writeText(event.url.substring(8))
    message.success('Copied! 🎉')
  }

  return (
    <div className={styles['manually-send-container']}>
      <header>
        <img src={BackIcon} alt="" onClick={() => onSetIsQrCodeVisible(false)} />
        <span>Send Invites Manually</span>
      </header>

      <div className={styles['content']}>
        <p>Scan this code to finish sending invites on your phone</p>
        <img src={qrCodeUrl} alt="" />
      </div>

      <div className={styles['line']}>
        <i />
        <span>OR</span>
        <i />
      </div>

      <p className={styles['p-section']}>Click to copy the public event link</p>

      <div className={styles['url-wrapper']} onClick={handleCopyLink}>
        <span>{event.url.substring(8)}</span>
        <img src={CloneHostIcon} width="30" />
      </div>
    </div>
  )
}

ManuallySend.propTypes = {
  onSetIsQrCodeVisible: PropTypes.func
}

export default ManuallySend
