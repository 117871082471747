import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './business-panel.scss'
import { Tabs } from 'antd'
import EventPanel from './EventPanel/EventPanel'
import SULPanel from './SULPanel/SULPanel'
import TimePollPanel from './TimePollPanel/TimePollPanel'
import DoubleArrow from 'assets/double-arrow.png'
import PropTypes from 'prop-types'
import { routerActions } from 'react-router-redux'
import { changePollTab } from 'src/actions/timepoll'

const TabPanes = [
  {
    label: 'Events',
    key: 'e',
    children: <EventPanel inHomePage />
  },
  {
    label: 'Time Polls',
    key: 'tp',
    children: <TimePollPanel inHomePage />
  },
  {
    label: 'Sign Up Lists',
    key: 'sul',
    children: <SULPanel inHomePage />
  }
]

const BusinessPanel = ({ containerRef }) => {
  const dispatch = useDispatch()
  const userId = useSelector(state => state.userId)
  const [activeTab, setActiveTab] = useState('e')
  const tabsRef = useRef(null)

  const handleChangeTab = key => {
    setActiveTab(key)
    containerRef.current.scrollTo({
      top: tabsRef.current.offsetTop + 28,
      behavior: 'smooth'
    })
  }

  const handleSeeAll = () => {
    switch (activeTab) {
      case 'e':
        dispatch(routerActions.push({ pathname: '/e/list' }))
        window.mixpanel.track('See All Clicked', {
          user_id: userId
        })
        break
      case 'tp':
        dispatch(routerActions.push({ pathname: '/tp/timePoll' }))
        dispatch(changePollTab('tp'))
        break
      case 'sul':
        dispatch(routerActions.push({ pathname: '/tp/timePoll' }))
        dispatch(changePollTab('sul'))
        break
    }
  }

  return (
    <div className={styles['business-panel-container']}>
      <div className={styles['title']} ref={tabsRef}>
        Recent
      </div>
      <Tabs
        className={styles['tabs-container']}
        activeKey={activeTab}
        onChange={key => handleChangeTab(key)}
        items={TabPanes}
        tabBarStyle={{ color: '#959595' }}
      />
      <div className={styles['button']} onClick={handleSeeAll}>
        See All
        <img src={DoubleArrow} width={18} alt="Double Arrow" />
      </div>
    </div>
  )
}

BusinessPanel.propTypes = {
  containerRef: PropTypes.object
}

export default BusinessPanel
