import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { eventsSelector, usersSelector } from 'src/selectors'
import { fetchEvent, cacheEvent } from 'src/actions/event'
import { fetchUser } from 'src/actions/user'
import { Skeleton, Modal } from 'antd'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import { ActivitySlugs } from '../ActivityFeedInner'
import { fullName, firstName } from 'src/services/user_helpers'
import { pluralize } from 'src/services/utils'
import { redirectDomain } from 'services/url_helpers'

import styles from './activity-feed-item.scss'

const ChatNotification = ({ activitySlug, activityItems, onMarkRead }) => {
  const dispatch = useDispatch()
  const events = useSelector(eventsSelector)
  const users = useSelector(usersSelector)
  const [isLoading, setIsLoading] = useState(true)
  const { entities, timestamp, id } = activityItems[0]
  const eventId = entities.find(item => item.type === 'Event')?.id

  const containerType = entities.find(item => item.label === 'container')?.type
  const inEvent = containerType === 'Event'
  const event = eventId ? events[eventId] : null
  const isRead = activityItems.every(item => item.read)

  const formatIds = useCallback(() => {
    let uniqueIds = new Set()
    activityItems.forEach(item => {
      item.entities.forEach(entity => {
        if (entity.type === 'User') {
          uniqueIds.add(entity.id)
        }
      })
    })

    return Array.from(uniqueIds)
  }, [activityItems])

  const userIds = formatIds()
  const userList = userIds.map(id => users[id])

  useEffect(() => {
    if (eventId && !events.hasOwnProperty(eventId)) {
      dispatch(cacheEvent(eventId))
      dispatch(fetchEvent(eventId, null, false))
    }

    for (const userId of userIds) {
      if (!users.hasOwnProperty(userId)) {
        dispatch(fetchUser(userId))
      }
    }
  }, [])

  useEffect(() => {
    const loadedEvent = !eventId || (eventId && event)
    const isUserExist = userList.every(user => user)
    if (isUserExist && loadedEvent) {
      setIsLoading(false)
    }
  }, [users, events])

  if (isLoading) {
    return (
      <div style={{ padding: '0 16px' }}>
        <Skeleton active avatar paragraph={{ rows: 2 }} />
      </div>
    )
  }

  const calculateTime = () => {
    const currentTime = new Date().getTime()
    const eventTime = new Date(timestamp).getTime()
    const time = (currentTime - eventTime) / 1000
    if (time < 60) {
      return `${Math.floor(time)}s`
    } else if (time >= 60 && time < 3600) {
      return `${Math.floor(time / 60)}m`
    } else if (time >= 3600 && time < 86400) {
      return `${Math.floor(time / 3600)}h`
    } else if (time >= 86400 && time < 31536000) {
      return `${Math.floor(time / 86400)}d`
    } else {
      return `${Math.floor(time / 31536000)}y`
    }
  }

  const renderEmoji = () => {
    return activityItems.map((item, index) => (
      <span key={index}>
        <em-emoji id={item.metadata.reaction_slug} size="14px" />{' '}
      </span>
    ))
  }

  const renderContent = () => {
    let title = null
    let text = null
    let button = null

    const mergeNames = () => {
      if (userList.length === 1) {
        return <b>{fullName(userList[0])}</b>
      }

      return (
        <>
          {userList.map((user, index) => {
            if (index === userList.length - 1) {
              return <b key={index}>{firstName(user)}</b>
            }

            return (
              <span key={index}>
                <b>{firstName(user)}</b> and{' '}
              </span>
            )
          })}
        </>
      )
    }

    switch (activitySlug) {
      case ActivitySlugs.UserCreatedMessage:
        title = mergeNames()
        const count = activityItems.length
        if (inEvent) {
          text = (
            <>
              <b>Sent</b> a message in <b>{event.name}</b>
            </>
          )
        } else {
          text = (
            <>
              <b>Sent</b> you {count === 1 ? 'a' : <b>{count}</b>}{' '}
              <b>{pluralize(count, 'message')}</b>
            </>
          )
        }
        break
      case ActivitySlugs.UserMentionedYou:
        title = <b>{fullName(userList[0])}</b>
        if (inEvent) {
          text = (
            <>
              <b>Mentioned you</b> in <b>{event.name}</b>
            </>
          )
        } else {
          text = (
            <>
              <b>Mentioned you</b> in a message
            </>
          )
        }
        break
      case ActivitySlugs.UserCreatedInvitationReplyMessage:
        title = <b>{firstName(userList[0])}</b>
        text = (
          <>
            <b>Responded</b> to your invite to <b>{event.name}</b>
          </>
        )
        break
      case ActivitySlugs.UserCreatedThreadFromYourMessage:
        title = <b>{fullName(userList[0])}</b>
        if (inEvent) {
          text = (
            <>
              <b>Replied</b> to your message in <b>{event.name}</b>
            </>
          )
        } else {
          text = (
            <>
              <b>Replied</b> to your message
            </>
          )
        }
        break
      case ActivitySlugs.UserRepliedToThread:
        title = mergeNames()
        if (inEvent) {
          text = (
            <>
              <b>Replied</b> to a thread in <b>{event.name}</b>
            </>
          )
        } else {
          text = (
            <>
              <b>Replied</b> to a thread
            </>
          )
        }
        break
      case ActivitySlugs.UserMentionedYouInThread:
        title = <b>{fullName(userList[0])}</b>
        if (inEvent) {
          text = (
            <>
              <b>Mentioned you</b> in a thread in <b>{event.name}</b>
            </>
          )
        } else {
          text = (
            <>
              <b>Mentioned you</b> in a thread
            </>
          )
        }
        break
      case ActivitySlugs.UserReactedToMessage:
        title = mergeNames()
        if (inEvent) {
          text = (
            <>
              <b>Reacted with</b> {renderEmoji()}
              in <b>{event.name}</b>
            </>
          )
        } else {
          text = (
            <>
              <b>Reacted</b> {renderEmoji()}
              on a message
            </>
          )
        }
        break
      case ActivitySlugs.UserReactedToMessageInThread:
        title = mergeNames()
        if (inEvent) {
          text = (
            <>
              <b>Reacted with</b> {renderEmoji()}
              in a thread in <b>{event.name}</b>
            </>
          )
        } else {
          text = (
            <>
              <b>Reacted</b> {renderEmoji()}
              in a thread
            </>
          )
        }
        break
    }

    return (
      <>
        {title && <p>{title}</p>}
        {text && (
          <p className={styles['text']}>
            {text}
            <span className={styles['time-text']}> {calculateTime()}</span>
          </p>
        )}
        {button && <div className={styles['button-wrapper']}>{button}</div>}
      </>
    )
  }

  const renderAvatar = () => {
    if (userList.length === 1) {
      return <UserAvatarContainer user={userList[0]} size={43} />
    }

    return (
      <div className={styles['user-avatar-wrapper']}>
        {userList.map((user, index) => {
          return (
            <div key={index} className={styles[`user-avatar-${index + 1}`]}>
              <UserAvatarContainer key={index} user={user} size={26} />
            </div>
          )
        })}
      </div>
    )
  }

  const handleClickFlyer = e => {
    e.stopPropagation()
    redirectDomain(event.url)
  }

  const handleRedirectChat = () => {
    onMarkRead()

    if (inEvent) {
      const messageId = entities.find(
        item => item.type === 'Message' && item.label === 'message'
      )?.id
      switch (activitySlug) {
        case ActivitySlugs.UserCreatedThreadFromYourMessage:
        case ActivitySlugs.UserRepliedToThread:
        case ActivitySlugs.UserMentionedYouInThread:
        case ActivitySlugs.UserReactedToMessageInThread:
          const threadId = entities.find(
            item => item.type === 'Message' && item.label === 'thread'
          )?.id

          localStorage.setItem(
            'chatParams',
            JSON.stringify({ activityId: id, threadId, messageId })
          )
          break
        default:
          localStorage.setItem('chatParams', JSON.stringify({ activityId: id, messageId }))
      }
      redirectDomain(`${event.url}/#chat`, true)
    } else {
      Modal.info({
        title: 'To Chat, Get the App',
        zIndex: 10003,
        centered: true,
        maskClosable: true,
        onOk() {
          window.open('https://go.hbnb.io/timepoll', '_blank')
        }
      })
    }
  }

  return (
    <li className={styles['activity-feed-item-container']} onClick={handleRedirectChat}>
      {!isRead && <div className={styles['read-dot']} />}
      {renderAvatar()}
      <div className={styles['content']}>{renderContent()}</div>
      {event && (
        <img
          src={event.flyer?.thumbnail_url}
          className={styles['flyer-img']}
          onClick={handleClickFlyer}
        />
      )}
    </li>
  )
}

ChatNotification.propTypes = {
  activitySlug: PropTypes.string,
  activityItems: PropTypes.array,
  onMarkRead: PropTypes.func
}

export default ChatNotification
