export function totalCounts (invitations = [], guestCountEnabled, childCountEnabled) {
  let adultCount = 0
  let childCount = 0

  invitations.map((invitation) => {
    adultCount += 1
    if (invitation.rsvp_state !== 'accepted') return

    if (guestCountEnabled) {
      adultCount += invitation.guest_count
      if (childCountEnabled) {
        childCount += invitation.child_count
      }
    }
  })

  return {
    adultCount,
    childCount,
    totalCount: adultCount + childCount
  }
}

export function fullName (invitation, user) {
  let firstName = invitation.first_name
  let lastName = invitation.last_name

  if (user && user.verified) {
    firstName = user.first_name
    lastName = user.last_name
  } else if (invitation.display_name) {
    return invitation.display_name
  }

  let name
  if (firstName) {
    name = firstName
    if (lastName) {
      name += ` ${lastName}`
    }
  } else {
    name = lastName
  }

  return name
}

export function firstName (invitation, user) {
  let firstName = invitation.first_name
  if (user && user.verified) {
    firstName = user.first_name
  }

  return firstName
}

export function lastName (invitation, user) {
  let lastName = invitation.last_name
  if (user && user.verified) {
    lastName = user.last_name
  }

  return lastName
}

export function canSendInvitationReplyMessage (invitation) {
  return invitation && invitation.contact_method.type === 'PhoneNumberContactMethod'
}
