import { fetchApi } from './api'

import { MessageContainer } from 'src/schemas'

export const MESSAGE_REQUEST = 'MESSAGE_REQUEST'
export const MESSAGE_REQUEST_SUCCESS = 'MESSAGE_REQUEST_SUCCESS'
export const MESSAGE_REQUEST_ERROR = 'MESSAGE_REQUEST_ERROR'
export function messageContainerRequest (containerType, containerId) {
  return fetchApi({
    requestType: MESSAGE_REQUEST,
    successType: MESSAGE_REQUEST_SUCCESS,
    errorType: MESSAGE_REQUEST_ERROR,
    route: '/graphql2',
    schema: {
      data: {
        message_container: MessageContainer
      }
    },
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: messageContainerRequestQuery(containerType, containerId)
      })
    }
  })
}

function messageContainerRequestQuery (containerType, containerId) {
  const messageContainer = `
     message_container(container_type: ${containerType}, container_id: "${containerId}") {
        id
        channel
        is_read
        last_read_at
        room_id
        unread_mentions_count
        message_count 
      }
  `
  return `{
     ${messageContainer}
  }
  `
}
