import { combineReducers } from 'redux'

import {
  SHOW_PHONE_CODE_FIELD,
  HIDE_PHONE_CODE_FIELD,
  SHOW_EMAIL_FIELD,
  SHOW_PHONE_FIELD,
  HIDE_PHONE_FIELD,
  IS_SUBSCRIBE_POLL_CONTAINER,
  NOT_IN_SUBSCRIBE_POLL_CONTAINER,
  IS_LOGIN_CONTAINER,
  NOT_IS_LOGIN_CONTAINER,
  IS_UPDATE_SELECTIONS
} from 'src/actions/timepoll'

const showPhoneCodeField = (state = false, action) => {
  switch (action.type) {
    case SHOW_PHONE_CODE_FIELD: return true
    case HIDE_PHONE_CODE_FIELD: return false
    default: return state
  }
}
const showEmailField = (state = false, action) => {
  switch (action.type) {
    case SHOW_EMAIL_FIELD: return true
    default: return state
  }
}
const showPhoneField = (state = true, action) => {
  switch (action.type) {
    case SHOW_PHONE_FIELD: return true
    case HIDE_PHONE_FIELD: return false
    default: return state
  }
}

const isSubscribeContainer = (state = false, action) => {
  switch (action.type) {
    case IS_SUBSCRIBE_POLL_CONTAINER: return true
    case NOT_IN_SUBSCRIBE_POLL_CONTAINER: return false
    default: return state
  }
}

const isLoginContainer = (state = false, action) => {
  switch (action.type) {
    case IS_LOGIN_CONTAINER: return true
    case NOT_IS_LOGIN_CONTAINER: return false
    default: return state
  }
}

const isUpdateSelections = (state = false, action) => {
  switch (action.type) {
    case IS_UPDATE_SELECTIONS: return true
    default: return state
  }
}

export default combineReducers({
  showPhoneCodeField,
  showEmailField,
  showPhoneField,
  isSubscribeContainer,
  isLoginContainer,
  isUpdateSelections
})
