import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  activeInvitationsSelector,
  createUserForInvitationSelector,
  eventSelector,
  hostSelector,
  invitationSelector,
  isInvitationCredentialUserSelector,
  userSelector,
  usersSelector,
  invitationsAcceptedCountSelector,
  isHostSelector
} from 'src/selectors'

import { EMAIL_CONTACT_METHOD, HOBNOB_USER_CONTACT_METHOD } from 'services/contact_methods'
import { hasUserAccessToken } from 'services/access_tokens'

import { showMapModal, showShareModal, showHud } from 'src/actions/frontend'

import { eventLogout } from 'src/actions/login'

import { hostFullName } from 'src/services/event_helpers'

import FloatingRSVPBtnContainer from 'components/common/FloatingRSVPBtnContainer'
import SvgIcon from 'src/components/common/SvgIcon'
import AscendingAvatar from 'src/components/common/AscendingAvatar'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import EventDateTime from 'components/details/EventDateTime'
import EventVenue from 'components/details/EventVenue'
import PrivateInvitationInfo from 'components/details/PrivateInvitationInfo'
import Alert from 'components/details/Alert'

// import ShareEventContainer from 'src/components/details/ShareEventContainer'
import ShareIconHost from 'assets/icons/ico-share-host.png'
import DirectionsIcon from 'src/assets/icons/directions_icon.svg'
import CalendarIcon from 'src/assets/icons/calendar_icon.svg'
import { activateEvent } from 'src/actions/event'

import styles from './event-rsvp-section.scss'

const EventRSVPSection = () => {
  const dispatch = useDispatch()
  const event = useSelector(eventSelector)
  const host = useSelector(hostSelector)
  const user = useSelector(userSelector)
  const users = useSelector(usersSelector)
  const invitation = useSelector(invitationSelector)
  const invitations = useSelector(activeInvitationsSelector)
  const invitationUser = useSelector(createUserForInvitationSelector(invitation))
  const isInvitationCredentialUser = useSelector(isInvitationCredentialUserSelector)
  const invitationsAcceptedCount = useSelector(invitationsAcceptedCountSelector)
  const isHost = useSelector(isHostSelector)
  const isAnnouncement = event.event_type === 'announcement'

  const validateDate = () => {
    if (event.cutoff_date) {
      return new Date() > new Date(event.cutoff_date)
    }
    return false
  }

  const validateMaxCapacity = () => {
    if (event.max_capacity) {
      return invitationsAcceptedCount >= event.max_capacity
    }
    return false
  }

  const handleClickActiveAndShare = () => {
    let count = 0
    if (!event.grandfathered) {
      for (let invitation of invitations) {
        const user = users[invitation.guest]
        const {
          contact_method: { type },
          delivery_state,
          activated_at
        } = invitation
        const { app_user } = user

        if (
          !app_user &&
          type !== EMAIL_CONTACT_METHOD &&
          type !== HOBNOB_USER_CONTACT_METHOD &&
          delivery_state === 'pending' &&
          !!activated_at
        ) {
          count++
        }
      }
    }
    const successActions = [showShareModal(getShareEventParams())]
    dispatch(
      activateEvent({
        eventId: event.id,
        count,
        successActions,
        onErrorCallback: res => {
          const { errors } = res
          if (errors.host[0] === 'user does not have enough credit') {
            dispatch(
              showHud(
                'error',
                'Activation failed due to insufficeint invite credits.\n\nUpgrade your subscription or remove excess unsent invites from guest list.'
              )
            )
          }
        }
      })
    )
  }

  const handleClickShare = () => {
    window.mixpanel.track('Share Button Clicked', { user_id: user?.id })

    dispatch(showShareModal(getShareEventParams()))
  }

  const getShareEventParams = () => {
    const subject =
      event.event_type === 'event' ? `You're invited to ${event.name}` : `Announcing: ${name}`

    const params = {
      title: 'Share Your Event',
      subtitle: 'Guests will add themselves to the Guest List at no extra cost! 💬 💌 📣',
      shareUrl: event.url.substring(8),
      email: {
        event,
        host
      },
      facebook: {
        href: event.facebook_share_url,
        redirect_uri: event.url
      },
      twitter: {
        subject: subject + '!',
        url: event.twitter_share_url
      }
    }

    return params
  }

  const renderEventCalendarAndMap = () => {
    return (
      <div className={styles['event-calendar-and-map']}>
        {event.ics_url ? (
          <div className={styles['icon-wrapper']} onClick={() => window.open(event.ics_url)}>
            <SvgIcon icon={CalendarIcon} className={styles['map-icon']} />
            <span>Add to Calendar</span>
          </div>
        ) : null}
        {event.venue ? (
          <div className={styles['icon-wrapper']} onClick={() => dispatch(showMapModal())}>
            <SvgIcon icon={DirectionsIcon} className={styles['map-icon']} />
            <span>Get Directions</span>
          </div>
        ) : null}
      </div>
    )
  }

  const isOutOfDate = validateDate()
  const isOverMaxQuantity = validateMaxCapacity()
  const disableRSVP = isOutOfDate || isOverMaxQuantity
  const isLogin = hasUserAccessToken()

  return (
    <div className={styles['event-rsvp-section']}>
      <div className={styles['mobile-view']}>
        <AscendingAvatar user={host} size={60} />

        <div className={styles['share-icon-container']}>
          {host?.id === user?.id && event?.state === 'draft' ? (
            <div className={styles['activation-button']} onClick={handleClickActiveAndShare}>
              activate & share
            </div>
          ) : (
            <div className={styles['event-share']} onClick={handleClickShare}>
              <img src={ShareIconHost} width="20" />
              <span>share</span>
            </div>
          )}
        </div>

        <div className={styles['event-and-host-name']}>
          <div>
            <h2 className={styles['event-name']}>{event.name}</h2>
            <div className={styles['host-name']}>
              {isAnnouncement ? 'From' : 'Host'}: {hostFullName(event, host)}
            </div>
            {isOverMaxQuantity ? (
              <Alert message={event.cap_error_msg} type="warning" />
            ) : (
              isOutOfDate && <Alert message={event.rsvp_error_msg} type="warning" />
            )}
          </div>
          {invitation && !isAnnouncement ? (
            <PrivateInvitationInfo
              isInvitationCredentialUser={isInvitationCredentialUser}
              invitation={invitation}
              invitationUser={invitationUser}
              onNotYou={() => dispatch(eventLogout())}
              user={user}
            />
          ) : null}
        </div>
      </div>

      <div className={styles['web-view']}>
        <div className={styles['event-title-and-share']}>
          <h1 className={styles['event-name']}>{event.name}</h1>
          {/* <ShareEventContainer /> */}
          {host?.id === user?.id && event?.state === 'draft' ? (
            <div className={styles['activation-button']} onClick={handleClickActiveAndShare}>
              activate & share
            </div>
          ) : (
            <div className={styles['event-share']} onClick={handleClickShare}>
              <img src={ShareIconHost} width="20" />
              <span>share</span>
            </div>
          )}
        </div>

        <div className={styles['event-and-host-name']}>
          <div className={styles['event-and-host-name__container']}>
            <div className={styles['event-and-host-name__title']}>
              <UserAvatarContainer user={host} size={34} />
              <h3 className={styles['host-name']}>
                {isAnnouncement ? 'From' : 'Host'}: {hostFullName(event, host)}
              </h3>
            </div>
            {isOverMaxQuantity ? (
              <Alert message={event.cap_error_msg} type="warning" />
            ) : (
              isOutOfDate && <Alert message={event.rsvp_error_msg} type="warning" />
            )}
          </div>
          {invitation && !isAnnouncement ? (
            <PrivateInvitationInfo
              isInvitationCredentialUser={isInvitationCredentialUser}
              invitation={invitation}
              invitationUser={invitationUser}
              onNotYou={() => dispatch(eventLogout())}
              user={user}
            />
          ) : null}
        </div>
      </div>

      <div className={styles['date-and-venue']}>
        <div className={styles['event-date']}>
          <EventDateTime
            endsAt={event.ends_at}
            startsAt={event.starts_at}
            timezone={event.timezone}
          />
        </div>
        <EventVenue customVenueName={event.custom_venue_name} venue={event.venue} />
      </div>

      {event.venue || event.ics_url ? renderEventCalendarAndMap() : null}

      {!isAnnouncement && (
        <FloatingRSVPBtnContainer
          customClassName={isHost && isLogin ? styles['mobile-hidden'] : ''}
          disabled={disableRSVP}
        />
      )}
    </div>
  )
}

EventRSVPSection.propTypes = {}

export default EventRSVPSection
