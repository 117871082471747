import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { saveGalleries } from 'src/actions/eventChat'
import CloseIconBg from 'assets/icons/close-icon-bg.png'
import InputMessageContext from '../InputMessageContext'
import { Image } from 'antd'

import styles from './preview-media.scss'

const PreviewMedia = ({ mediaMessage, onSetMediaMessage }) => {
  const dispatch = useDispatch()
  const { inputRef } = useContext(InputMessageContext)

  const removeMedia = media => {
    let index = null
    let tempMedia = null

    /**
     * @description Remove media from gallery
     * @data {
     *  galleryId: string,
     *  type: string,
     *  files: Array<{}>
     * }
     */

    const newMediaMessage = mediaMessage.filter((m, i) => {
      if (m.type === 'gallery') {
        const isBelongTo = m.files.some(item => item.id === media.id)
        if (isBelongTo) {
          index = i
          tempMedia = {
            galleryId: m.galleryId,
            type: 'gallery',
            files: m.files.filter(item => item.id !== media.id)
          }
          if (tempMedia.files.length === 0) {
            tempMedia = null
            i = null
            return false
          }
        }
        return true
      } else {
        return m.id !== media.id
      }
    })

    if (tempMedia && index !== null) {
      newMediaMessage.splice(index, 1, tempMedia)
      dispatch(
        saveGalleries({
          id: tempMedia.galleryId,
          gallery_image_uploads: tempMedia.files.map(img => ({
            id: img.id,
            image_upload: {
              gallery_id: tempMedia.galleryId,
              medium_url: img.url
            }
          }))
        })
      )
    }

    onSetMediaMessage(newMediaMessage)
    inputRef.current.focus()
  }

  const medias = mediaMessage
    .map(media => {
      if (media.type === 'gallery') {
        return media.files
      }
      return media
    })
    .flat(Infinity)

  return (
    <ul className={styles['preview-media']}>
      {medias.map(media => (
        <li key={media.id} className={styles['media-item']}>
          <img
            src={CloseIconBg}
            className={styles['remove-icon']}
            onClick={() => removeMedia(media)}
          />
          <Image src={media.url} rootClassName={styles['img-inner']} />
        </li>
      ))}
    </ul>
  )
}

PreviewMedia.propTypes = {
  mediaMessage: PropTypes.array,
  onSetMediaMessage: PropTypes.func
}

export default PreviewMedia
