import { cancel, cancelled, take, put, spawn, call, select } from 'redux-saga/effects'

import { callApi, HOBNOB_API_ERROR } from 'universal/services/api_client'
import { delay } from 'services/utils'
import { message } from 'antd'

import { AccessTokenType, getOrRefreshAccessToken, getOrRefreshClientCredentialsAccessToken } from './access_tokens'
import { ourTakeEvery } from './utils'

import {
  FETCH_API,
  FETCH_API_DEBOUNCED,
  apiErrorResponse,
  apiRequestStarted,
  apiResponseReceived,
  apiSuccessResponse
} from '../actions/api'
import { showLoadingScreen, hideLoadingScreen, showLoadingIcon, hideLoadingIcon } from 'src/actions/frontend'
import { showLoadingScreenSelector, isLoadingVisibleSelector } from 'src/selectors'

import { autoRetrySaga } from './retry_api'
import { putAll } from './helpers'
import { FETCH_FLYER_DESIGNS } from 'src/actions/flyer'
import { CREATE_USER } from 'src/actions/user'
import { parseEventUrl } from 'services/url_helpers'

export default function * apiRootSaga () {
  yield spawn(watchFetchApi)
  yield spawn(watchFetchApiDebounced)
}

function * watchFetchApiDebounced () {
  let tasks = {}

  while (true) {
    // Possible to change this to a takeEvery?
    const action = yield take(FETCH_API_DEBOUNCED)
    let { key } = action
    let task = tasks[key]
    if (task) {
      yield cancel(task)
    }

    tasks[key] = yield spawn(debouncedApiWorker, action.fetchApiOptions)
  }
}

function * debouncedApiWorker (fetchApiOptions) {
  try {
    yield apiWorker(fetchApiOptions, 400)
  } finally {
    if (yield cancelled()) {
    }
  }
}

function * watchFetchApi () {
  yield * ourTakeEvery(FETCH_API, apiWorker)
}

// Worker saga
export function * apiWorker (action, delayInMs = 0) {
  let accessToken, tokenType, customHeaders

  if (!action.skipGetOrRefreshAccessToken) {
    const result = yield getOrRefreshAccessToken()
    tokenType = result.tokenType
    accessToken = result.token

    if (action.requestType === CREATE_USER) {
      tokenType = AccessTokenType.CLIENT
      accessToken = yield getOrRefreshClientCredentialsAccessToken()
    }
  }

  const requestTypes = [ FETCH_FLYER_DESIGNS ]
  if (requestTypes.includes(action.requestType)) {
    const clientToken = yield getOrRefreshClientCredentialsAccessToken()
    customHeaders = {
      requestType: action.requestType,
      clientToken,
      web_version: action.customHeaders?.web_version || process.env.WEB_VERSION
    }
  }

  let apiPayload = { tokenType }

  yield put(apiRequestStarted(action, apiPayload))
  yield processShowLoadingWithPage(action)
  yield processShowLoadingWithAPI(action)

  const { paginationParams } = action
  const isPaginated = paginationParams && paginationParams.page

  let params = { ...action.params }
  if (isPaginated) {
    params.page = paginationParams.page
    params.per_page = paginationParams.per_page || 25
  }

  if (delayInMs > 0) {
    yield delay(delayInMs)
  }

  try {
    let options = { ...action.options }
    options.accessToken = accessToken

    let response = yield callApi(action.route, params, options, customHeaders)
    yield processHideLoadingWithAPI(action)
    yield put(hideLoadingScreen())
    yield processApiSuccess(action, apiPayload, response)
  } catch (e) {
    if (e.type && e.type === HOBNOB_API_ERROR) {
      yield processApiError(action, apiPayload, e.response)
    } else {
      yield processApiFailure(action, apiPayload)
      message.error('Net connection error! Please try again later.')
      console.error('api error', e)
    }
    yield processHideLoadingWithAPI(action)
  }
}

function * processApiSuccess (action, apiPayload, response) {
  let { paginationParams } = action

  if (paginationParams) {
    const defaultPaginationParams = { fetchAll: true }
    paginationParams = { ...defaultPaginationParams, ...paginationParams }
  }

  const { requestParams, status, body, headers } = response
  const isPaginated = paginationParams && paginationParams.page
  const hasMoreEntities = isPaginated && paginationParams.entityKey &&
    body[paginationParams.entityKey].length === requestParams.per_page

  apiPayload.responseStatus = status
  apiPayload.responseHeaders = headers
  apiPayload.hasMoreEntities = hasMoreEntities

  yield put(apiSuccessResponse(action, body, apiPayload))
  yield putAll(action.successActions)

  if (hasMoreEntities && paginationParams.fetchAll) {
    const nextAction = {
      ...action,
      paginationParams: {
        ...paginationParams,
        page: paginationParams.page + 1
      }
    }

    // Maybe possible to change this back to a fork?
    yield spawn(apiWorker, nextAction)
  }
  if (action.onSuccessCallback) {
    yield call(() => action.onSuccessCallback(body))
  }

  if (action.onFailCallback) {
    yield call(() => action.onFailCallback(body))
  }
}

function * processApiError (action, apiPayload, response) {
  const { body, headers } = response
  const wwwAuthenticateHeader = headers.get('www-authenticate')
  apiPayload.wwwAuthenticateHeader = wwwAuthenticateHeader

  const retry = yield autoRetrySaga(action, apiPayload, response)
  if (retry) {
    yield put(retry)
  } else {
    yield put(apiResponseReceived({
      type: action.errorType,
      response: body,
      originalAction: action,
      schema: action.schema,
      apiPayload
    }))
    yield putAll(action.errorActions)
  }

  if (action.onErrorCallback) {
    yield call(() => action.onErrorCallback(body))
  } else {
    // TODO: General error information, but some API requests use status codes above 400 to indicate logical error information.
    // const actionTypeList = ['FETCH_ACCESS_TOKEN', 'VIEW_INVITATION', 'FETCH_INVITATION']

    // if (actionTypeList.includes(action.requestType)) {
    //   return
    // }

    // message.error('Net connection error! Please try again later.')
  }
}

function * processApiFailure (action, apiPayload) {
  yield put(apiErrorResponse(action, apiPayload))
}

function * processShowLoadingWithPage (action) {
  // If there are some pages that need to show the loading icon when loading
  const showLoading = yield select(showLoadingScreenSelector)
  const { destinationType } = parseEventUrl(window.location.pathname)
  const actionTypeList = ['FETCH_TIME_POLL', 'FETCH_SIGN_UP']
  if (!showLoading && actionTypeList.indexOf(action.requestType) > -1 && destinationType !== 'e') {
    yield put(showLoadingScreen())
  }
}

let commonAPI = new Map()

function * processShowLoadingWithAPI (action) {
  // If there are some api requests that need to display the loading icon
  const showLoading = yield select(isLoadingVisibleSelector)
  const actionTypeList = [
    'PARSE_EVENT_CSV',
    'DELETE_INVITATION',
    'DELETE_INVITATIONS',
    'UPDATE_INVITATION',
    'INVITATIONS_LARGE_BULK',
    'INVITATIONS_ACTIVATE',
    'FETCH_EVENT_LIST',
    'SUBMIT_LOGIN_CONFIRMATION',
    'UPDATE_USER',
    'DELETE_EVENT'
  ]

  if (!showLoading && actionTypeList.indexOf(action.requestType) > -1) {
    commonAPI.set(action.requestType, 1)
    yield put(showLoadingIcon())
  }
}

function * processHideLoadingWithAPI (action) {
  // If there are some api requests that need to hide the loading icon
  const showLoading = yield select(isLoadingVisibleSelector)
  if (showLoading && commonAPI.has(action.requestType)) {
    commonAPI.delete(action.requestType)
    yield put(hideLoadingIcon())
  }
}
