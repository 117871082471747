import React, { Component } from 'react'
import HobnobModal from 'components/common/HobnobModal'
import styles from './edit-modal.scss'
import TextInputElement from 'components/forms/TextInputElement'
import cn from 'classnames'
import CloseIcon from 'src/assets/icons/close-icon.png'
import PropTypes from 'prop-types'

const editModalContainerStyle = {
  width: 'auto',
  padding: 0,
  borderRadius: 0,
  maxWidth: 'none',
  bottom: 0,
  top: 'auto',
  left: 0,
  right: 0,
  transform: 'none'
}
class EditModal extends Component {
  render() {
    const {
      first_name,
      last_name,
      phoneOrEmail,
      visibleErrorText,
      isOpenEditModal,
      onClose,
      onCancel,
      onInputChange,
      onSave,
      cancelText,
      confirmText,
      title,
      closeIcon,
      visibleContactInput
    } = this.props

    return (
      <HobnobModal
        containerStyles={editModalContainerStyle}
        showCancelIcon={false}
        closeOnOuterClick={false}
        show={isOpenEditModal}
        onClose={onClose}
        visibleButton
        cancelText={cancelText}
        confirmText={confirmText}
        onConfirm={onSave}
        onCancel={onCancel}
        buttonContainerClassName={styles['button-container']}
        cancelClassName={styles['cancel-button']}
        confirmClassName={styles['confirm-button']}
      >
        <div className={styles['edit-modal-container']}>
          <div className={styles['title']}>
            <span>{title}</span>
            {closeIcon && <img src={CloseIcon} width="18" onClick={onClose} />}
          </div>

          <div className={styles['input-wrapper']}>
            <TextInputElement
              className={styles['input-inner']}
              placeholder="First Name"
              onChange={e => onInputChange('first_name', e.target.value)}
              valid
              value={first_name}
            />
            <span className={styles['interval']} />
            <TextInputElement
              className={styles['input-inner']}
              placeholder="Last Name"
              onChange={e => onInputChange('last_name', e.target.value)}
              valid
              value={last_name}
            />
          </div>

          {visibleContactInput && (
            <>
              <div className={styles['input-wrapper']}>
                <TextInputElement
                  className={cn(styles['input-inner'], styles['input-inner-contact'], {
                    [styles['input-inner-error']]: visibleErrorText
                  })}
                  placeholder="e.g. 9876543210 or example@hobnob.io"
                  onChange={e => onInputChange('phoneOrEmail', e.target.value)}
                  valid={!visibleErrorText || phoneOrEmail === ''}
                  value={phoneOrEmail}
                />
              </div>
              <div
                className={cn(styles['error-text'], {
                  [styles['error-text-hidden']]: !visibleErrorText || phoneOrEmail === ''
                })}
              >
                Invalid phone number or email!
              </div>
            </>
          )}
        </div>
      </HobnobModal>
    )
  }
}

EditModal.defaultProps = {
  confirmText: 'Save',
  cancelText: 'Cancel',
  title: 'Edit Contact',
  visibleContactInput: true
}

EditModal.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  phoneOrEmail: PropTypes.string,
  visibleErrorText: PropTypes.bool,
  isOpenEditModal: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onInputChange: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  title: PropTypes.string,
  closeIcon: PropTypes.bool,
  visibleContactInput: PropTypes.bool
}

export default EditModal
