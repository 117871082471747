import { fetchApi } from './api'
import { hideHostMessageModal, showHud, showMessageSent } from './frontend'

import { invitationsSelector } from 'src/selectors'

import { Invitation, Event } from 'src/schemas'
import { isHobnobAndroidClient } from '../services/utils'

// When adding a guest, the front end mocks the invitation data.
export const INSERT_MOCK_INVITATION = 'INSERT_MOCK_INVITATION'
export const UPDATE_INVITATION_DELIVERY = 'UPDATE_INVITATION_DELIVERY'

export const SET_INVITATION_TOKEN = 'SET_INVITATION_TOKEN'
export const SET_INVITATION_ID = 'SET_INVITATION_ID'
export const SET_INVITATION_LOADING_STATUS = 'SET_INVITATION_LOADING_STATUS'

export const UPDATE_INVITATION = 'UPDATE_INVITATION'
export const UPDATE_INVITATION_SUCCESS = 'UPDATE_INVITATION_SUCCESS'
export const UPDATE_INVITATION_ERROR = 'UPDATE_INVITATION_ERROR'

export const FETCH_INVITATION = 'FETCH_INVITATION'
export const FETCH_INVITATION_SUCCESS = 'FETCH_INVITATION_SUCCESS'
export const FETCH_INVITATION_ERROR = 'FETCH_INVITATION_ERROR'

export const REQUEST_ACCEPT_INVITATION = 'REQUEST_ACCEPT_INVITATION'
export const REQUEST_DECLINE_INVITATION = 'REQUEST_DECLINE_INVITATION'

export const ACCEPT_INVITATION = 'ACCEPT_INVITATION'
export const ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS'
export const ACCEPT_INVITATION_ERROR = 'ACCEPT_INVITATION_ERROR'

export const DECLINE_INVITATION = 'DECLINE_INVITATION'
export const DECLINE_INVITATION_SUCCESS = 'DECLINE_INVITATION_SUCCESS'
export const DECLINE_INVITATION_ERROR = 'DECLINE_INVITATION_ERROR'

export const VIEW_INVITATION = 'VIEW_INVITATION'
export const VIEW_INVITATION_SUCCESS = 'VIEW_INVITATION_SUCCESS'
export const VIEW_INVITATION_ERROR = 'VIEW_INVITATION_ERROR'

export const SET_INVITATION_REPLY_MESSAGE = 'SET_INVITATION_REPLY_MESSAGE'
export const REQUEST_SEND_INVITATION_REPLY_MESSAGE = 'REQUEST_SEND_INVITATION_REPLY_MESSAGE'
export const SEND_INVITATION_REPLY_MESSAGE = 'SEND_INVITATION_REPLY_MESSAGE'
export const SEND_INVITATION_REPLY_MESSAGE_SUCCESS = 'SEND_INVITATION_REPLY_MESSAGE_SUCCESS'
export const SEND_INVITATION_REPLY_MESSAGE_ERROR = 'SEND_INVITATION_REPLY_MESSAGE_ERROR'

export const ENABLED_INVITATION = 'ENABLED_INVITATION'

export const INVITATIONS_LARGE_BULK = 'INVITATIONS_LARGE_BULK'
export const INVITATIONS_LARGE_BULK_SUCCESS = 'INVITATIONS_LARGE_BULK_SUCCESS'
export const INVITATIONS_LARGE_BULK_ERROR = 'INVITATIONS_LARGE_BULK_ERROR'

export const INVITATIONS_ACTIVATE = 'INVITATIONS_ACTIVATE'
export const INVITATIONS_ACTIVATE_SUCCESS = 'INVITATIONS_ACTIVATE_SUCCESS'
export const INVITATIONS_ACTIVATE_ERROR = 'INVITATIONS_ACTIVATE_ERROR'

export const DELETE_INVITATION = 'DELETE_INVITATION'
export const DELETE_INVITATION_SUCCESS = 'DELETE_INVITATION_SUCCESS'
export const DELETE_INVITATION_ERROR = 'DELETE_INVITATION_ERROR'

export const DELETE_INVITATIONS = 'DELETE_INVITATIONS'
export const DELETE_INVITATIONS_SUCCESS = 'DELETE_INVITATIONS_SUCCESS'
export const DELETE_INVITATIONS_ERROR = 'DELETE_INVITATIONS_ERROR'

export function insertMockInvitation(invitations) {
  return {
    type: INSERT_MOCK_INVITATION,
    value: invitations
  }
}

export function updateInvitationDelivery(ids) {
  return {
    type: UPDATE_INVITATION_DELIVERY,
    value: ids
  }
}

export function fetchInvitation(invitationIdentifier, eventIdentifier) {
  return fetchApi({
    requestType: FETCH_INVITATION,
    successType: FETCH_INVITATION_SUCCESS,
    errorType: FETCH_INVITATION_ERROR,
    route: `/events/${eventIdentifier}/invitations/${invitationIdentifier}`,
    schema: {
      invitation: Invitation
    },
    autoRetry: 1
  })
}

export function updateInvitation(
  invitation,
  invitationIdentifier,
  eventIdentifier,
  { successActions, errorActions } = {},
  { onSuccessCallback }
) {
  invitation.last_updated_at = invitation.updated_at

  return fetchApi({
    requestType: UPDATE_INVITATION,
    successType: UPDATE_INVITATION_SUCCESS,
    errorType: UPDATE_INVITATION_ERROR,
    payload: {
      invitation,
      invitationIdentifier,
      eventIdentifier
    },
    route: `/events/${eventIdentifier}/invitations/${invitationIdentifier}`,
    schema: {
      invitation: Invitation
    },
    options: {
      method: 'PATCH',
      body: JSON.stringify({ invitation })
    },
    autoRetry: 1,
    successActions: successActions || [],
    errorActions: errorActions || [],
    onSuccessCallback
  })
}

export function setInvitationToken(invitationToken) {
  return {
    type: SET_INVITATION_TOKEN,
    invitationToken
  }
}

export function setInvitationId(invitationId) {
  return {
    type: SET_INVITATION_ID,
    invitationId
  }
}

export function setInvitationLoadingStatus(status) {
  return {
    type: SET_INVITATION_LOADING_STATUS,
    status
  }
}

export function requestAcceptInvitation() {
  return {
    type: REQUEST_ACCEPT_INVITATION
  }
}

export function requestDeclineInvitation() {
  return {
    type: REQUEST_DECLINE_INVITATION
  }
}

export const ACCEPT_RSVP_INITIATED = 'ACCEPT_RSVP_INITIATED'
export function acceptRsvpInitiated() {
  return {
    type: ACCEPT_RSVP_INITIATED
  }
}

export function acceptInvitation(invitation, invitationIdentifier, eventIdentifier) {
  const { updated_at } = invitation

  return fetchApi({
    requestType: ACCEPT_INVITATION,
    successType: ACCEPT_INVITATION_SUCCESS,
    errorType: ACCEPT_INVITATION_ERROR,
    payload: { invitation },
    route: `/events/${eventIdentifier}/invitations/${invitationIdentifier}/accept`,
    schema: {
      invitation: Invitation
    },
    options: {
      method: 'POST',
      body: JSON.stringify({ invitation: { last_updated_at: updated_at } })
    },
    autoRetry: 1
  })
}

export function declineInvitation(invitation, invitationIdentifier, eventIdentifier) {
  const { updated_at } = invitation

  return fetchApi({
    requestType: DECLINE_INVITATION,
    successType: DECLINE_INVITATION_SUCCESS,
    errorType: DECLINE_INVITATION_ERROR,
    payload: { invitation },
    route: `/events/${eventIdentifier}/invitations/${invitationIdentifier}/decline`,
    schema: {
      invitation: Invitation
    },
    options: {
      method: 'POST',
      body: JSON.stringify({ invitation: { last_updated_at: updated_at } })
    },
    autoRetry: 1
  })
}

export function retryAcceptOrDeclineInvitation(oldAction, state) {
  const invitations = invitationsSelector(state)
  const invitation = invitations[oldAction.payload.invitation.id]
  return {
    ...oldAction,
    options: {
      ...oldAction.options,
      body: JSON.stringify({ invitation: { last_updated_at: invitation.updated_at } })
    }
  }
}

export function viewInvitation(invitation, invitationIdentifier, eventIdentifier) {
  const { updated_at } = invitation

  return fetchApi({
    requestType: VIEW_INVITATION,
    successType: VIEW_INVITATION_SUCCESS,
    errorType: VIEW_INVITATION_ERROR,
    payload: { invitation },
    route: `/events/${eventIdentifier}/invitations/${invitationIdentifier}/view`,
    schema: {
      invitation: Invitation
    },
    options: {
      method: 'POST',
      body: JSON.stringify({ invitation: { last_updated_at: updated_at } })
    },
    autoRetry: 1
  })
}

export function retryViewInvitation(oldAction, state) {
  const invitations = invitationsSelector(state)
  const invitation = invitations[oldAction.payload.invitation.id]
  return {
    ...oldAction,
    options: {
      ...oldAction.options,
      body: JSON.stringify({ invitation: { last_updated_at: invitation.updated_at } })
    }
  }
}

export function setInvitationReplyMessage(content) {
  return {
    type: SET_INVITATION_REPLY_MESSAGE,
    content
  }
}
export function requestSendInvitationReplyMessage() {
  return { type: REQUEST_SEND_INVITATION_REPLY_MESSAGE }
}
export function sendInvitationReplyMessage(invitationId, content, receiverName) {
  const data = {
    title: 'Message Sent',
    subTitle: `We sent your message to ${receiverName}. To see the reply, get the app! (sorry, texting is expensive).`
  }
  let successActions = []
  if (isHobnobAndroidClient()) {
    successActions = [hideHostMessageModal()]
  } else {
    successActions = [showMessageSent(data)]
  }

  return fetchApi({
    requestType: SEND_INVITATION_REPLY_MESSAGE,
    successType: SEND_INVITATION_REPLY_MESSAGE_SUCCESS,
    errorType: SEND_INVITATION_REPLY_MESSAGE_ERROR,
    route: `/invitations/${invitationId}/invitation_reply_messages`,
    schema: {
      invitation: Invitation
    },
    options: {
      method: 'POST',
      body: JSON.stringify({ invitation_reply_message: { content } })
    },
    successActions: successActions,
    errorActions: [
      showHud(
        'error',
        'There was a problem sending your message. Please try again in a few moments'
      )
    ]
  })
}

export function enabledInvitation() {
  return {
    type: ENABLED_INVITATION
  }
}

export function invitations_large_bulk({ eventId, invitations, onSuccessCallback }) {
  return fetchApi({
    requestType: INVITATIONS_LARGE_BULK,
    successType: INVITATIONS_LARGE_BULK_SUCCESS,
    errorType: INVITATIONS_LARGE_BULK_ERROR,
    route: `/events/${eventId}/invitations/large_bulk`,
    options: {
      method: 'PUT',
      body: JSON.stringify({ invitations })
    },
    onSuccessCallback
  })
}

export function invitations_activate({
  eventId,
  invitation_ids = [],
  phone_numbers = [],
  onSuccessCallback
}) {
  return fetchApi({
    requestType: INVITATIONS_ACTIVATE,
    successType: INVITATIONS_ACTIVATE_SUCCESS,
    errorType: INVITATIONS_ACTIVATE_ERROR,
    route: `/events/${eventId}/invitations/activate`,
    payload: { eventId },
    options: {
      method: 'POST'
      // body: JSON.stringify({ invitation_ids, phone_numbers })
    },
    onSuccessCallback
  })
}

export function deleteInvitation({ eventId, invitationId, onSuccessCallback, onErrorCallback }) {
  return fetchApi({
    requestType: DELETE_INVITATION,
    successType: DELETE_INVITATION_SUCCESS,
    errorType: DELETE_INVITATION_ERROR,
    route: `/events/${eventId}/invitations/${invitationId}`,
    schema: {
      invitation: Invitation
    },
    options: {
      method: 'DELETE'
    },
    onSuccessCallback,
    onErrorCallback
  })
}

export function deleteInvitations({ eventId, invitationIds, onSuccessCallback }) {
  return fetchApi({
    requestType: DELETE_INVITATIONS,
    successType: DELETE_INVITATIONS_SUCCESS,
    errorType: DELETE_INVITATIONS_ERROR,
    route: `/events/${eventId}`,
    schema: {
      event: Event
    },
    options: {
      method: 'PATCH',
      body: JSON.stringify({
        invitations_attributes: invitationIds.map(id => ({ id, _destroy: 1 }))
      })
    },
    onSuccessCallback
  })
}
