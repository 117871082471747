import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { activityFeedSelector } from 'src/selectors'
import PropTypes from 'prop-types'
import EventNotification from './ActivityFeedItem/EventNotification'
import ChatNotification from './ActivityFeedItem/ChatNotification'
import { markReadActivity, setActivityFeedList } from 'src/actions/activityFeed'

export const ActivitySlugs = {
  Viewed: 'viewed',
  // Event
  InvitationHourBeforeReminder: 'invitation_hour_before_reminder',
  LegacyUserInvitationDelivered: 'legacy_user_invitation_delivered',
  LegacyUserInvitationAccepted: 'legacy_user_invitation_accepted',
  LegacyUserInvitationDeclined: 'legacy_user_invitation_declined',
  LegacyUserCohostInvitation: 'legacy_user_cohost_invitation',
  LegacyUserCreatedEventUpdate: 'legacy_user_created_event_update',
  FailedInvitationDelivery: 'failed_invitation_delivery',
  InvitationTwoDayReminder: 'invitation_two_day_reminder',
  invitationDayBeforeReminder: 'invitation_day_before_reminder',
  invitationDraftOneDayReminder: 'invitation_draft_one_day_reminder',
  invitationDraftTwoDayReminder: 'invitation_draft_two_day_reminder',
  // Chat
  UserCreatedMessage: 'user_created_message',
  UserMentionedYou: 'user_mentioned_you',
  UserCreatedInvitationReplyMessage: 'user_created_invitation_reply_message',
  UserCreatedThreadFromYourMessage: 'user_created_thread_from_your_message',
  UserRepliedToThread: 'user_replied_to_thread',
  UserMentionedYouInThread: 'user_mentioned_you_in_thread',
  UserReactedToMessage: 'user_reacted_to_message',
  UserReactedToMessageInThread: 'user_reacted_to_message_in_thread'
}

const ActivityFeedInner = ({ activityDetail }) => {
  const dispatch = useDispatch()
  const { slug, activity_items } = activityDetail
  const { activityFeedList } = useSelector(activityFeedSelector)

  const handleMarkAsRead = () => {
    const unreadIds = activity_items.filter(item => item.read === false).map(item => item.id)
    dispatch(
      markReadActivity(unreadIds, () => {
        for (const activity of activityFeedList) {
          for (const item of activity.activity_items) {
            if (unreadIds.includes(item.id)) {
              item.read = true
            }
          }
        }
        dispatch(setActivityFeedList([...activityFeedList]))
      })
    )
  }

  const renderActivitySlug = () => {
    switch (slug) {
      case ActivitySlugs.InvitationHourBeforeReminder:
      case ActivitySlugs.LegacyUserInvitationDelivered:
      case ActivitySlugs.LegacyUserInvitationAccepted:
      case ActivitySlugs.LegacyUserInvitationDeclined:
      case ActivitySlugs.LegacyUserCohostInvitation:
      case ActivitySlugs.LegacyUserCreatedEventUpdate:
      case ActivitySlugs.FailedInvitationDelivery:
      case ActivitySlugs.InvitationTwoDayReminder:
      case ActivitySlugs.invitationDayBeforeReminder:
      case ActivitySlugs.invitationDraftOneDayReminder:
      case ActivitySlugs.invitationDraftTwoDayReminder:
        return (
          <EventNotification
            activitySlug={slug}
            activityItems={activity_items}
            onMarkRead={handleMarkAsRead}
          />
        )
      case ActivitySlugs.UserCreatedMessage:
      case ActivitySlugs.UserMentionedYou:
      case ActivitySlugs.UserCreatedInvitationReplyMessage:
      case ActivitySlugs.UserCreatedThreadFromYourMessage:
      case ActivitySlugs.UserRepliedToThread:
      case ActivitySlugs.UserMentionedYouInThread:
      case ActivitySlugs.UserReactedToMessage:
      case ActivitySlugs.UserReactedToMessageInThread:
        return (
          <ChatNotification
            activitySlug={slug}
            activityItems={activity_items}
            onMarkRead={handleMarkAsRead}
          />
        )
      default:
        return null
    }
  }

  return renderActivitySlug()
}

ActivityFeedInner.propTypes = {
  activityDetail: PropTypes.object
}

export default ActivityFeedInner
