import PropTypes from 'prop-types'
import React from 'react'

import EventLink from './EventLink'

import styles from './event-links.scss'

export default class EventLinks extends React.Component {
  myRef = null

  componentDidMount() {
    const { scrollTo } = this.props
    if (scrollTo) {
      scrollTo(this.myRef)
    }
  }

  _saveRef = ref => {
    this.myRef = ref
  }

  _renderLink = (link, index) => {
    const { links } = this.props
    const isLastElement = index === Object.values(links).length - 1

    return (
      <div key={link.id} ref={this._saveRef}>
        <EventLink link={link} />
        {isLastElement ? null : <div className={styles['spacer']} />}
      </div>
    )
  }

  render() {
    const { links } = this.props

    return <div className={styles['container']}>{links.map(this._renderLink)}</div>
  }
}

EventLinks.propTypes = {
  links: PropTypes.array,
  scrollTo: PropTypes.func
}
