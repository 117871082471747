import { FlyerLayoutAbstract } from './flyer-layout.abstract'
import moment from 'moment/moment'

export default class Flyer_layout_split_three_corners_034LayoutModel extends FlyerLayoutAbstract {
  flourishes = [
    {
      name: 'classy_date_line',
      left: 240,
      top: 50,
      width: 60,
      height: 60
    },
    {
      name: 'classy_location_line',
      left: 25,
      top: 420,
      width: 90,
      height: 90
    }
  ]

  textLayouts = {
    event_name_1: {
      top: 65,
      left: 25,
      size: 34,
      width: 195,
      height: 130
    },
    location_1: {
      top: 390,
      left: 25,
      size: 24,
      width: 230,
      height: 70,
      verticalAlign: 'bottom'
    },
    location_2: {
      top: 470,
      left: 25,
      size: 16,
      width: 270,
      height: 20
    },
    location_3: {
      top: 495,
      left: 25,
      size: 16,
      width: 270,
      height: 20
    }
  }

  dateLayouts = {
    date_1: {
      top: 60,
      right: 0,
      daySize: 34,
      lineHeight: 8,
      width: 80,
      align: 'right'
    }
  }

  constructor(layout, eventData) {
    super(layout, eventData)
    this.init()
  }

  getDateTexts() {
    const startDate = this.eventData.starts_at
    const endDate = this.eventData.ends_at

    const { daySize, top, align, lineHeight, width, right } = this.dateLayouts.date_1

    const dateInformation = this.formatDates({
      day: 'D MMM h:mmA',
      time: 'h:mmA'
    })

    const { formattedStart, formattedEnd } = dateInformation
    let dates = []
    let textTop = top

    const startDay = moment(startDate).format('D')
    const endDay = moment(endDate).format('D')

    // 开始时间
    const startArray = formattedStart.split(' ').map(str => {
      const mapObj = {
        text: str,
        width,
        align,
        right,
        widthAlign: 'center',
        color: this.dateProps.date1Props.color.replace('0x', '#'),
        font: this.dateProps.date1Props.font.replace(/\s+/g, '-'),
        size: startDay === str ? daySize : 16
      }

      mapObj.top = textTop
      textTop += startDay === str ? daySize - 5 + lineHeight : 16 + lineHeight

      return mapObj
    })

    dates = dates.concat(startArray)

    if (!endDate) return { type: 'date', dates }

    // 时间间隔
    dates.push({
      text: 'till',
      width,
      size: 12,
      top: textTop,
      widthAlign: 'center',
      align,
      right,
      color: this.dateProps.dateSeparatorProps.color.replace('0x', '#'),
      font: this.dateProps.dateSeparatorProps.font.replace(/\s+/g, '-')
    })

    textTop = textTop + 12 + lineHeight

    // 结束时间
    const endArray = formattedEnd.split(' ').map(str => {
      const mapObj = {
        text: str,
        width,
        align,
        right,
        widthAlign: 'center',
        color: this.dateProps.date2Props.color.replace('0x', '#'),
        font: this.dateProps.date2Props.font.replace(/\s+/g, '-'),
        size: str === endDay ? daySize : 16
      }

      mapObj.top = textTop

      textTop += str === endDay ? daySize - 5 + lineHeight : 16 + lineHeight

      return mapObj
    })

    dates = dates.concat(endArray)

    return { type: 'date', dates }
  }
}
