import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { chatSelector } from 'src/selectors'
import InputEmoji from './InputEmoji/InputEmoji'
import { ROOM_CHANNEL_TYPES_CONSTANTS } from 'src/constants'
import { pushChannel } from 'src/services/websocket'
import cn from 'classnames'

import styles from './emoji-message.scss'

const EmojiMessage = props => {
  const { reactions, messageId } = props
  const userId = useSelector(state => state.userId)
  const { channelName } = useSelector(chatSelector)

  const handleDeleteReaction = reaction => {
    const isSelected = reaction.user_ids.includes(userId)

    let actionType = isSelected
      ? ROOM_CHANNEL_TYPES_CONSTANTS.reaction_destroy
      : ROOM_CHANNEL_TYPES_CONSTANTS.reaction_add

    pushChannel({
      channelName,
      action: actionType,
      payload: {
        message_id: messageId,
        slug: reaction.slug
      }
    })
  }

  return (
    <div className={styles['emoji-message-container']}>
      <InputEmoji reactions={reactions} messageId={messageId} />
      {reactions.map(reaction => {
        const { slug, user_ids } = reaction
        const isSelected = user_ids.includes(userId)
        return (
          <div
            key={slug}
            className={cn(styles['emoji-wrapper'], isSelected && styles['emoji-wrapper-selected'])}
            onClick={() => handleDeleteReaction(reaction)}
          >
            <em-emoji id={slug} size="14px" />
            {user_ids.length > 1 && <span>{user_ids.length}</span>}
          </div>
        )
      })}
    </div>
  )
}

EmojiMessage.defaultProps = {
  reactions: []
}

EmojiMessage.propTypes = {
  messageId: PropTypes.string.isRequired,
  reactions: PropTypes.array.isRequired
}

export default EmojiMessage
