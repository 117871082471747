import { combineReducers } from 'redux'

import {
  CLEAR_CUSTOM_FLYER_URL,
  SET_CUSTOM_FLYER_URL,
  UPDATE_FLYER_ZOOM,
  FETCH_FLYER_DESIGNS_SUCCESS
} from 'src/actions/flyer'

const flyerList = (state = [], action) => {
  switch (action.type) {
    case FETCH_FLYER_DESIGNS_SUCCESS:
      return action.response.data.flyer_designs.active_galleries[0].flyer_designs
    default:
      return state
  }
}

const flyerZoom = (state = true, action) => {
  switch (action.type) {
    case UPDATE_FLYER_ZOOM:
      return action.flyerZoom
    default:
      return state
  }
}

const customFlyerURL = (state = null, action) => {
  switch (action.type) {
    case SET_CUSTOM_FLYER_URL:
      return action.url
    case CLEAR_CUSTOM_FLYER_URL:
      return null
    default:
      return state
  }
}

export default combineReducers({
  flyerList,
  flyerZoom,
  customFlyerURL
})
