import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Button from 'src/components/common/Button'

import styles from './change-rsvp.scss'

export default class ChangeRSVP extends Component {
  render () {
    const { onAcceptInvitation, onDeclineInvitation, rsvpTextClass } = this.props

    return (
      <div className={styles['container']}>
        <div className={rsvpTextClass}>
          Change your RSVP?
        </div>

        <Button onClick={onAcceptInvitation} className={styles['button']}>Yes, I'm going</Button>
        <Button onClick={onDeclineInvitation} className={styles['button']}>No, can't go</Button>
      </div>
    )
  }
}

ChangeRSVP.propTypes = {
  rsvpTextClass: PropTypes.string,
  onAcceptInvitation: PropTypes.func,
  onDeclineInvitation: PropTypes.func
}
