import PropTypes from 'prop-types'
import React from 'react'

export default class EventVenue extends React.Component {
  _renderVenueAddress () {
    const { venue } = this.props
    return <div>
      <div>{venue.street}</div>
      <div>{venue.city}, {venue.state} {venue.postal_code}</div>
    </div>
  }

  render () {
    const { customVenueName, venue, className } = this.props
    const hasVenue = venue || customVenueName
    if (!hasVenue) return null

    return <div className={className}>
      <div>{customVenueName || venue.name}</div>
      {venue ? this._renderVenueAddress() : null}
    </div>
  }
}

EventVenue.propTypes = {
  customVenueName: PropTypes.string,
  venue: PropTypes.object,
  className: PropTypes.string
}
