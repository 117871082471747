import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { hideImageModal } from 'src/actions/frontend'

import {
  imageModalUrlSelector,
  imageModalOpenSelector
} from 'src/selectors'

import Modal from 'components/common/HobnobModal'

import styles from './image-modal-container.scss'

export class ImageModalContainer extends React.Component {
  _onClose = () => this.props.dispatch(hideImageModal())

  render () {
    const { imageModalOpen, imageModalUrl } = this.props
    const containerStyles = {
      background: '#000',
      padding: 0,
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      margin: 0
    }

    return (
      <Modal show={imageModalOpen} showCancelIcon={false} onClose={this._onClose} containerStyles={containerStyles}>
        <div className={styles['modal']} onClick={this._onClose}>
          <img src={imageModalUrl} className={styles['image']} />
        </div>
      </Modal>
    )
  }
}

ImageModalContainer.propTypes = {
  dispatch: PropTypes.func,
  imageModalOpen: PropTypes.bool.isRequired,
  imageModalUrl: PropTypes.string.isRequired
}

function mapStateToProps (state) {
  return {
    imageModalUrl: imageModalUrlSelector(state),
    imageModalOpen: imageModalOpenSelector(state)
  }
}

export default connect(mapStateToProps)(ImageModalContainer)
