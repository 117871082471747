import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  namesByUserIdSelector
} from 'src/selectors'

import Avatar from './Avatar'

class UserAvatarContainer extends Component {
  _getNames () {
    const { namesByUserId, user } = this.props
    const names = namesByUserId[user.id]
    if (names) {
      return names
    } else {
      return user
    }
  }

  render () {
    const { user, url, ...otherProps } = this.props
    const { first_name, last_name } = this._getNames()

    const avatarUrl = url || (user.avatar ? (user.avatar.medium_url_2x || user.avatar.url) : null)

    return <Avatar
      appUser={user.app_user}
      user={user}
      url={avatarUrl}
      firstName={first_name}
      lastName={last_name}
      isMasked={!first_name && !last_name}
      {...otherProps}
    />
  }
}

UserAvatarContainer.propTypes = {
  // Passed directly to component
  user: PropTypes.object.isRequired,
  // TODO: Can we remove this and just always require user directly?
  url: PropTypes.string,

  // From mapStateToProps
  dispatch: PropTypes.func,
  namesByUserId: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    namesByUserId: namesByUserIdSelector(state)
  }
}

export default connect(mapStateToProps)(UserAvatarContainer)
