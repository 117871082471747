import React, { Component } from 'react'
import PropTypes from 'prop-types'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import moment from 'moment'
import { fullName } from 'src/services/user_helpers'
import { Popover, Tooltip } from 'antd'
import MeatballLinedIcon from 'assets/icons/meatball-lined-icon.png'
import PopConfirm from 'components/common/PopConfirm/PopConfirm'
import { deleteSUL } from 'src/actions/timepoll'
import { connect } from 'react-redux'
import styles from './sul-card.scss'

class SULCard extends Component {
  state = {
    visibleOperateModal: false,
    visibleRemoveModal: false
  }

  renderOptions = () => {
    return (
      <ul className={styles['operate-list']}>
        <li
          className={styles['delete-text']}
          onClick={e => {
            e.stopPropagation()
            this.setState({ visibleRemoveModal: true, visibleOperateModal: false })
          }}
        >
          Delete
        </li>
      </ul>
    )
  }

  handleDeleteEvent = () => {
    const { onDeleteSUL, sul, onDeleteSULCallback } = this.props
    this.setState({ visibleRemoveModal: false })
    onDeleteSUL({
      id: sul.id,
      onSuccessCallback: () => {
        onDeleteSULCallback(sul.id)
      }
    })
  }

  goSULDetail = () => {
    window.location.href = `${window.location.origin}/su/${this.props.sul.token}`
  }

  render() {
    const { sul, userId } = this.props
    const isCreator = sul.creator.id === userId
    const creatorName = sul.custom_creator_name || fullName(sul.creator)

    return (
      <>
        <div className={styles['sign-up-list-inner']} onClick={this.goSULDetail}>
          <div className={styles['image-box']}>
            <img src={sul.image_upload?.image_url} alt="" />
          </div>
          <div className={styles['sul-information']}>
            <div className={styles['avatar-box']}>
              <UserAvatarContainer user={sul.creator} size={50} />
            </div>
            <Tooltip title={sul.name}>
              <div className={styles['name']}>{sul.name}</div>
            </Tooltip>
            <div className={styles['operate-wrapper']}>
              <div className={styles['host']}>
                <div className={styles['host-name']}>
                  {isCreator ? 'Host: Me' : `Creator: ${creatorName}`}
                </div>
                <div>{moment(sul.created_at).format('MMMM D, YYYY ∙ h:mm A')}</div>
              </div>

              {isCreator && (
                <Popover
                  trigger="click"
                  content={this.renderOptions()}
                  overlayClassName={styles['operate-container']}
                >
                  <img
                    onClick={e => e.stopPropagation()}
                    className={styles['operate-icon']}
                    src={MeatballLinedIcon}
                  />
                </Popover>
              )}
            </div>
          </div>
        </div>

        <PopConfirm
          visible={this.state.visibleRemoveModal}
          confirmText="DELETE"
          type="delete"
          onClose={() => this.setState({ visibleRemoveModal: false })}
          onConfirm={this.handleDeleteEvent}
        >
          Are you sure to delete the List, {sul.name}
          <br /> This action cannot be undone.
        </PopConfirm>
      </>
    )
  }
}

SULCard.propTypes = {
  userId: PropTypes.string,
  sul: PropTypes.object,
  onDeleteSUL: PropTypes.func,
  onDeleteSULCallback: PropTypes.func
}

function mapDispatchToProps(dispatch) {
  return {
    onDeleteSUL: params => dispatch(deleteSUL(params))
  }
}

export default connect(null, mapDispatchToProps)(SULCard)
