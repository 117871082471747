import { FlyerLayoutAbstract } from './flyer-layout.abstract'
import moment from 'moment/moment'

export default class Flyer_layout_split_burst_026LayoutModel extends FlyerLayoutAbstract {
  flourishes = [
    {
      name: 'burst_gradient_overlay',
      left: 0,
      top: 345,
      width: 320,
      height: 223
    },
    {
      name: 'burst_shape',
      left: 31,
      top: 54,
      width: 258,
      height: 260
    },
    {
      name: 'diagonal_bar',
      left: 59.5,
      top: 407,
      width: 201,
      height: 15
    }
  ]

  textLayouts = {
    event_name_1: {
      top: 110,
      left: 40,
      size: 70,
      width: 270,
      height: 160,
      align: 'center',
      verticalAlign: 'center'
    },
    location_1: {
      top: 430,
      left: 80,
      size: 18,
      width: 300,
      height: 30,
      align: 'center'
    },
    location_2: {
      top: 460,
      left: 80,
      size: 18,
      width: 300,
      height: 30,
      align: 'center'
    },
    location_3: {
      top: 490,
      left: 80,
      size: 18,
      width: 300,
      align: 'center'
    }
  }

  dateLayouts = {
    date_1: {
      top: 350,
      daySize: 24,
      width: 300,
      align: 'center',
      lineHeight: 3
    }
  }

  constructor(layout, eventData) {
    super(layout, eventData)
    this.init()
  }

  getDateTexts() {
    const startDate = this.eventData.starts_at
    const endDate = this.eventData.ends_at
    const { daySize, top, align, lineHeight, width } = this.dateLayouts.date_1

    const dateInformation = this.formatDates({
      day: 'ddd, MMM D, h:mmA',
      time: 'h:mmA'
    })

    const { formattedStart, formattedEnd, isSameDay } = dateInformation
    let dates = []
    let textTop = top

    if (endDate) {
      dates.push({
        text: isSameDay ? moment(startDate).format('ddd MMM D').toUpperCase() : formattedStart,
        width,
        align,
        top: textTop,
        color: this.dateProps.date1Props.color.replace('0x', '#'),
        font: this.dateProps.date1Props.font.replace(/\s+/g, '-'),
        size: daySize
      })

      textTop += daySize + lineHeight

      if (isSameDay) {
        dates.push({
          text: moment(startDate).format('h:mmA'),
          top: textTop,
          width,
          align: 'left',
          left: 70,
          color: this.dateProps.date1Props.color.replace('0x', '#'),
          font: this.dateProps.date1Props.font.replace(/\s+/g, '-'),
          size: daySize
        })
      }

      dates.push({
        text: 'TILL',
        top: textTop + 5,
        width: 30,
        align: isSameDay ? 'center' : 'left',
        left: isSameDay ? 0 : 40,
        color: this.dateProps.dateSeparatorProps.color.replace('0x', '#'),
        font: this.dateProps.dateSeparatorProps.font.replace(/\s+/g, '-'),
        size: 12
      })

      dates.push({
        text: formattedEnd,
        top: textTop,
        width,
        align: isSameDay ? 'left' : 'center',
        left: 180,
        color: this.dateProps.date2Props.color.replace('0x', '#'),
        font: this.dateProps.date2Props.font.replace(/\s+/g, '-'),
        size: daySize
      })
    } else {
      // 开始时间
      dates.push({
        text: formattedStart,
        width,
        align,
        top: textTop,
        color: this.dateProps.date1Props.color.replace('0x', '#'),
        font: this.dateProps.date1Props.font.replace(/\s+/g, '-'),
        size: daySize
      })
    }

    return { type: 'date', dates }
  }
}
