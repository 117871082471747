import { FlyerLayoutAbstract } from './flyer-layout.abstract'
import moment from 'moment/moment'

export default class Flyer_layout_center_thin_lines_007LayoutModel extends FlyerLayoutAbstract {
  flourishes = [
    {
      name: 'lines_balloon_flyer',
      left: 0,
      top: 0,
      width: 320,
      height: 568
    }
  ]

  textLayouts = {
    event_name_1: {
      top: 208,
      left: 12,
      size: 55,
      width: 296,
      height: 132,
      align: 'center'
    },
    location_1: {
      top: 360,
      left: 12,
      size: 24,
      width: 270,
      height: 30,
      align: 'center'
    },
    location_2: {
      top: 390,
      left: 12,
      size: 24,
      width: 270,
      height: 50,
      align: 'center'
    }
  }

  dateLayouts = {
    date_1: {
      top: 128,
      left: 12,
      daySize: 20,
      height: 30,
      width: 286,
      lineHeight: 10,
      align: 'center'
    }
  }

  constructor(flyerDesign, eventData) {
    super(flyerDesign, eventData)
    this.init()
  }

  getDateTexts() {
    const startDate = this.eventData.starts_at
    const endDate = this.eventData.ends_at

    const { daySize, top, align, width, height, lineHeight } = this.dateLayouts.date_1

    const dateInformation = this.formatDates({
      day: 'dddd, MMM D h:mmA',
      time: 'h:mmA'
    })

    const { formattedStart, formattedEnd, isSameDay } = dateInformation
    let dates = []
    let textTop = top

    if (endDate) {
      dates.push({
        text: isSameDay ? moment(startDate).format('dddd, MMM D').toUpperCase() : formattedStart,
        align,
        width,
        height,
        top: textTop,
        color: this.dateProps.date1Props.color.replace('0x', '#'),
        font: this.dateProps.date1Props.font.replace(/\s+/g, '-'),
        size: daySize
      })

      textTop += daySize + lineHeight

      if (isSameDay) {
        dates.push({
          text: moment(startDate).format('h:mmA'),
          align: 'left',
          left: 70,
          width,
          height,
          top: textTop,
          color: this.dateProps.date1Props.color.replace('0x', '#'),
          font: this.dateProps.date1Props.font.replace(/\s+/g, '-'),
          size: 20
        })

        dates.push({
          text: ' - ',
          align,
          width,
          height,
          top: textTop,
          color: this.dateProps.date1Props.color.replace('0x', '#'),
          font: this.dateProps.date1Props.font.replace(/\s+/g, '-'),
          size: 16
        })

        dates.push({
          text: formattedEnd,
          align: 'right',
          right: 70,
          width,
          height,
          top: textTop,
          color: this.dateProps.date2Props.color.replace('0x', '#'),
          font: this.dateProps.date2Props.font.replace(/\s+/g, '-'),
          size: 20
        })
      } else {
        dates.push({
          text: formattedEnd,
          align,
          width,
          height,
          top: textTop,
          color: this.dateProps.date2Props.color.replace('0x', '#'),
          font: this.dateProps.date2Props.font.replace(/\s+/g, '-'),
          size: daySize
        })
      }
    } else {
      dates.push({
        text: formattedStart,
        align,
        width,
        height,
        top: textTop,
        color: this.dateProps.date1Props.color.replace('0x', '#'),
        font: this.dateProps.date1Props.font.replace(/\s+/g, '-'),
        size: daySize
      })
    }

    return { type: 'date', dates }
  }
}
